export const DEFAULT_STATE = {
  url: '',
  company: '',
  companyDomain: '',
  companyLogo: '',
  title: '',
  jobDescription: '',
  notes: '',
}

export const CONFIRM_MORE_JOBS_REQUESTED =
  'Are you sure you want to submit this sourcing request with fewer jobs than requested?'

export const TOAST = {
  SUMBIT_CONTENT:
    'There is content detected in the form, you must either delete the content or add the job before submitting the sourcing request',
  DUP_URL: 'This job posting has already been added',
  JOB_ERROR: 'There was an error adding the job',
}

export const ERROR_CODE = {
  DUP_URL: 'duplicate_url',
}
