import { ApolloCache, gql, useMutation } from '@apollo/client'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { useAuthContext } from 'context/auth'
import { UNKNOWN } from 'utils/constants'
import { ApplicationRejectionReason } from 'utils/enums'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import getQueueLabel from 'utils/helpers/getQueueLabel'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import RejectionButton from 'components/RejectionButton/RejectionButton'
import { RejectionOption } from 'components/RejectionButton/RejectionButton.types'
import { getNextRouteUrl } from './FinalCopy.helpers'
import { Application, CompletionInfo, FinalCopyState } from './FinalCopy.types'
import FinalCopyYellowCard from './FinalCopyYellowCard'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  ApplicationStatus,
  RecordApplicationRejectionMutation,
  RecordApplicationRejectionMutationVariables,
  RecordApplicationSubmissionMutation,
  RecordApplicationSubmissionMutationVariables,
} from 'generated/graphql'

const FinalCopyQueueFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  application: Application
  setState: ReactSetState<FinalCopyState>
  queue: string[]
  applicationId: string
  applierId: string | undefined
  freeAgentId: string | undefined
  timeSpentSec: number
  setTimeSpentSec: ReactSetState<number>
}

export default function FinalCopyQueueFooter({
  application,
  queue,
  setState,
  applicationId,
  applierId,
  freeAgentId,
  timeSpentSec,
  setTimeSpentSec,
}: Props): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const history = useHistory()
  const nextApplicationId = getNextRequestIdInQueue(queue, applicationId)
  const nextRouteUrl = getNextRouteUrl(nextApplicationId, { applierId, freeAgentId })

  const [recordSubmission, { loading: loadingSubmission }] = useMutation<
    RecordApplicationSubmissionMutation,
    RecordApplicationSubmissionMutationVariables
  >(RECORD_APPLICATION_SUBMISSION, {
    update: updateCacheAfterSuccess,
  })

  const [recordRejection, { loading: loadingRejection }] = useMutation<
    RecordApplicationRejectionMutation,
    RecordApplicationRejectionMutationVariables
  >(RECORD_APPLICATION_REJECTION, {
    update: updateCacheAfterSuccess,
  })

  /**
   * TODO: (matthewalbrecht) expand this so that it can build strings for any query
   *
   * builds the query string for cache.modify enabling us to remove items from queues
   * @param assigneeId current user id
   * @param status application status
   */
  function buildQueryProperty(assigneeId: string, status: ApplicationStatus) {
    return `applications:{"assigneeId":"${assigneeId}","status":"${status}"}`
  }

  /**
   * removes current application from the query so ui can properly update
   * @param cache apollo's in memory cache
   */
  function updateCacheAfterSuccess<T>(cache: ApolloCache<T>) {
    const queryProperty = buildQueryProperty(
      assigneeId,
      ApplicationStatus.PENDING_APPLICATION
    )
    // removes the current application's edge from the query in cache
    cache.modify({
      fields: {
        [queryProperty]: (queryResult, { readField }) =>
          removeCacheEdgeById(application.id, queryResult, readField),
      },
    })
  }

  /**
   *
   * @param data data returned from mutation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCompletion({ action }: CompletionInfo) {
    const applicationInfo = `${application.jobTitle ?? UNKNOWN.JOB_TITLE} @ ${
      application.employerName ?? UNKNOWN.EMPLOYER_NAME
    } for ${application.client.name}`

    toast.success(`Application: ${applicationInfo} was ${action}`)

    setState({ isLocked: true, changes: {} })
    setTimeSpentSec(0)

    if (!nextApplicationId) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl)
  }

  async function handleSubmission() {
    try {
      await recordSubmission({
        variables: {
          RecordApplicationSubmissionInput: {
            timeSpentSec,
            applicationId,
          },
        },
      })
      handleCompletion({ action: 'submitted' })
    } catch {
      toast.error('There was an error submitting the application')
    }
  }

  async function handleRejection(rejectionReason: ApplicationRejectionReason) {
    try {
      await recordRejection({
        variables: {
          RecordApplicationRejectionInput: {
            timeSpentSec,
            applicationId,
            rejectionReason,
          },
        },
      })
      handleCompletion({ action: 'rejected' })
    } catch {
      toast.error('There was an error rejecting the application')
    }
  }

  function handleSkipClick() {
    if (!nextApplicationId) {
      toast('End of queue')
    }

    setState({ isLocked: true, changes: {} })
    setTimeSpentSec(0)

    history.push(nextRouteUrl)
  }

  const queueLabel = getQueueLabel(queue, applicationId)

  const rejectionOptions: RejectionOption<ApplicationRejectionReason>[] = [
    {
      rejectionReason: ApplicationRejectionReason.JOB_POSTING_CLOSED,
      callback: handleRejection,
      label: 'Job Posting Closed',
    },
    {
      rejectionReason: ApplicationRejectionReason.OTHER,
      callback: handleRejection,
      label: 'Other',
    },
  ]

  return (
    <FinalCopyQueueFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button
            onClick={handleSkipClick}
            $type="secondary"
            disabled={loadingSubmission || loadingRejection}
          >
            Skip
          </Button>
          <FinalCopyYellowCard
            application={application}
            timeSpentSec={timeSpentSec}
            handleCompletion={handleCompletion}
            updateCacheAfterSuccess={updateCacheAfterSuccess}
          />
          <RejectionButton<ApplicationRejectionReason>
            rejectionOptions={rejectionOptions}
          />
          {/* TODO: (mattthewalbrecht) disabled submit button if no changes have been made yet */}
          <Button
            $type="accept"
            onClick={handleSubmission}
            disabled={loadingSubmission || loadingRejection}
          >
            Submit
          </Button>
        </HList>
      </Flex>
    </FinalCopyQueueFooterBox>
  )
}

/**
 * record submission of application
 */
const RECORD_APPLICATION_SUBMISSION = gql`
  mutation RecordApplicationSubmission(
    $RecordApplicationSubmissionInput: RecordApplicationSubmissionInput!
  ) {
    recordApplicationSubmission(input: $RecordApplicationSubmissionInput) {
      applicationSubmission {
        id
        application {
          id
          status
        }
      }
    }
  }
`

/**
 * record a rejection of application
 */
const RECORD_APPLICATION_REJECTION = gql`
  mutation RecordApplicationRejection(
    $RecordApplicationRejectionInput: RecordApplicationRejectionInput!
  ) {
    recordApplicationRejection(input: $RecordApplicationRejectionInput) {
      applicationSubmission {
        id
        application {
          id
          status
        }
      }
    }
  }
`
