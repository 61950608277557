import React from 'react'
import {
  DashboardGrid,
  GridArea1,
  GridArea2,
  GridArea3,
  GridArea4,
  GridArea5,
} from 'components/ClientDashboardCard/ClientDashboardCard.styles'
import ClientDashboardCardPastInterviews from 'components/ClientDashboardCard/ClientDashboardCardPastInterviews'
import ClientDashboardCardUpcomingInterviews from 'components/ClientDashboardCard/ClientDashboardCardUpcomingInterviews'
import ClientDashboardInProgressWork from 'components/ClientDashboardCard/ClientDashboardInProgressWork'
import ClientDashboardInterviewCycleLogos from 'components/ClientDashboardCard/ClientDashboardInterviewCycleLogos'
import ClientDashboardStageChanges from 'components/ClientDashboardCard/ClientDashboardStageChanges'
import Container from 'components/Container'
import Padding from 'components/Padding'
import ViewBox from 'components/ViewBox'

export default function ClientDashboard(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding vertical={4}>
          <DashboardGrid>
            <GridArea1>
              <ClientDashboardInterviewCycleLogos />
            </GridArea1>
            <GridArea2>
              <ClientDashboardCardUpcomingInterviews />
            </GridArea2>
            <GridArea3>
              <ClientDashboardCardPastInterviews />
            </GridArea3>
            <GridArea4>
              <ClientDashboardInProgressWork />
            </GridArea4>
            <GridArea5>
              <ClientDashboardStageChanges />
            </GridArea5>
          </DashboardGrid>
        </Padding>
      </Container>
    </ViewBox>
  )
}
