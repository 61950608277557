export const COLUMNS = [
  { label: 'Date Created', id: 'dateCreated' },
  { label: 'Company', id: 'company' },
  { label: 'Title', id: 'title' },
  { label: 'Application Info', id: 'applicationInfo' },
  { label: 'Status', id: 'status' },
  { label: 'New Application', id: 'newApplication', collapse: true },
  { label: 'Important', id: 'isImportant', collapse: true },
]

export const FIRST = 25
