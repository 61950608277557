// TODO (matthewalbrecht) have this function return place in queue as well
/**
 *  retrieves the id of the next request after requestId in the queue or null if no more
 * @param queue array of string ids
 * @param requestId id of current request
 */
export function getNextRequestIdInQueue(
  queue: string[] | undefined,
  requestId: string
): string | null {
  if (!queue) {
    return null
  }
  const currentIndex = queue.indexOf(requestId)
  const nextItem = queue[currentIndex + 1]

  // this first return the next item in the queue if it exists,
  // then it will return the first element in the queue if for some reason there is a queue but couldn't find current place in it
  // then returns null as fallback
  return nextItem ?? null
}
