import dayjs from 'dayjs'
import React from 'react'
import { Colors, Maybe } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  DossierRequest,
  DossierStatus,
  Interview,
  InterviewCycle,
  InterviewStatus,
} from './InterviewCycleDetail.types'
import Txt from 'components/Txt'

export function normalizeInterviews(
  connection: InterviewCycle['interviews']
): Interview[] {
  const interviews = getNodes(connection)
    .map((interview) => ({
      ...interview,
      dateToSortBy: interview.scheduledFor
        ? dayjs(interview.scheduledFor.datetime)
        : dayjs().add(100, 'years'), // easy way to have unscheduled interview show up at the top of the list
    }))
    .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))

  return interviews
}

function hasDatePassed(date: Maybe<string>): boolean {
  if (date) {
    return dayjs(date) < dayjs()
  }
  return false
}

export function getInterviewStatus(
  interview: Interview
): [InterviewStatus, keyof Colors] {
  const isScheduled = Boolean(interview.scheduledFor)
  const isDatePast = hasDatePassed(interview.scheduledFor?.datetime)
  const hasDebrief = interview.debriefs[0]

  if (!interview.isActive) {
    return [InterviewStatus.Canceled, 'errorLight']
  } else if (!isScheduled) {
    return [InterviewStatus.Unscheduled, 'faGrey1']
  } else if (!isDatePast) {
    return [InterviewStatus.Scheduled, 'faGrey2']
  } else if (!hasDebrief) {
    return [InterviewStatus.PendingDebrief, 'yellow']
  } else {
    return [InterviewStatus.DebriefSubmitted, 'faGreen']
  }
}

type Header = {
  label: string
  id: string
}

export function renderHeaders(headers: Header[]): React.ReactElement[] {
  return headers.map(({ label, id }) =>
    label ? (
      <Txt size={13} bold key={id}>
        {label}
      </Txt>
    ) : (
      <div key={id} />
    )
  )
}

export function getDossierStatus(
  dossierRequest: DossierRequest
): [DossierStatus, keyof Colors] {
  const submission = dossierRequest.dossierSubmissions.edges[0]?.node
  const hasSubmission = submission && submission.fileUrl

  if (!submission) {
    return [DossierStatus.Requested, 'faGrey1']
  } else if (hasSubmission) {
    return [DossierStatus.Submitted, 'faGreen']
  } else {
    return [DossierStatus.Voided, 'errorLight']
  }
}
