import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { TOAST } from './SourcingStats.constants'
import { Client } from './SourcingStats.types'
import Button from 'components/Button'
import Txt from 'components/Txt'
import { RefileAutoSourcingRequestCountStyle } from 'generated/graphql'

type Props = {
  client: Client
}

export default function SourcingStatsRow({ client }: Props): React.ReactElement {
  // matches the countStyle variable in mutation
  const rerequestCount = client.autoSourcingsWeekly
    ? Math.ceil(client.autoSourcingsWeekly / 2)
    : null

  const [refileAutoSourcingRequest] = useMutation(REFILE_AUTO_SOURCING_REQUEST, {
    onCompleted() {
      toast.success(TOAST.rerequestSuccess)
    },
    onError() {
      toast.error(TOAST.rerequestFailure)
    },
  })

  function handleReRequest() {
    void refileAutoSourcingRequest({
      variables: {
        RefileAutoSourcingRequestInput: {
          clientId: client.id,
          countStyle: RefileAutoSourcingRequestCountStyle.HALF,
        },
      },
    })
  }

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {client.name}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.talentAgent}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.sourcingsRequested}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.sourcingsSubmitted}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.sourcingsApproved}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.sourcingsRejected}
        </Txt>
      </BodyData>
      <BodyData>
        <Button
          $type="accept"
          small
          onClick={handleReRequest}
          disabled={!rerequestCount}
        >
          Re-Request ({rerequestCount ?? 'n/a'})
        </Button>
      </BodyData>
    </BodyRow>
  )
}

/**
 * Used to create a new sourcing request from auto sourcing info
 */
const REFILE_AUTO_SOURCING_REQUEST = gql`
  mutation RefileAutoSourcingRequest(
    $RefileAutoSourcingRequestInput: RefileAutoSourcingRequestInput!
  ) {
    refileAutoSourcingRequest(input: $RefileAutoSourcingRequestInput) {
      sourcingRequest {
        id
      }
    }
  }
`
