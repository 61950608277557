import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Route } from 'react-router-dom'
import { useAuthContext } from 'context/auth'
import isTokenExpired from 'utils/helpers/isTokenExpired'
import ROUTE_PATHS from 'utils/routePaths'
import PrivateLayout from './Layouts/PrivateLayout'

export default function PrivateRoute({
  children,
  ...rest
}: {
  children?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}): JSX.Element {
  const { userSession } = useAuthContext()
  const history = useHistory()

  const wasLoggedIn = userSession.accessToken
  const isLoggedIn = wasLoggedIn && !isTokenExpired(userSession.expiresAt)

  useEffect(() => {
    if (!isLoggedIn) {
      if (wasLoggedIn) {
        history.push(ROUTE_PATHS.LOGOUT)
      } else {
        history.push(ROUTE_PATHS.LOGIN)
      }
    }
  }, [wasLoggedIn, isLoggedIn, history])

  return (
    <Route {...rest}>
      {isLoggedIn ? <PrivateLayout>{children}</PrivateLayout> : null}
    </Route>
  )
}
