import { KeyValue } from 'types'
import { sortByProp } from 'utils/sort'
import { ClientWithTaglineRequest, TaglineRequest } from './WriterTable.types'

/**
 * Normalizes the data so the table can consume it.
 * Groups the tagline requests by free agent.
 * @param taglineRequests the raw taglineRequests response from GQL query
 */
export function buildTableData(
  taglineRequests: TaglineRequest[]
): ClientWithTaglineRequest[] {
  const groupedRequests = taglineRequests.reduce(
    (finalArray: KeyValue<ClientWithTaglineRequest>, request) => {
      const groupValue = request.client.id
      const entry = finalArray[groupValue]

      if (entry) {
        entry.taglineRequests.push(request)
      } else {
        finalArray[groupValue] = {
          id: request.client.id,
          name: request.client.name,
          taglineRequests: [request],
        }
      }

      return finalArray
    },
    {}
  )

  return Object.values(groupedRequests).sort((a, b) => sortByProp(a, b, 'name'))
}
