import dayjs from 'dayjs'
import { Maybe } from 'types'
import { DATE_FORMAT, TIMEZONE_MAP } from 'utils/constants'
import { DEFUALT_STATE, TzType } from './AddAndEditInterview.constants'
import { Interview, State } from './AddAndEditInterview.types'
import { DatetimeWithTzInput, DatetimeWithTz } from 'generated/graphql'

type Params = {
  clientId: string | null
  interviewCycleId: string | null
}

export function getDefaultState(
  interview: Maybe<Interview>,
  { clientId, interviewCycleId }: Params
): State {
  if (interview) {
    return {
      clientId: interview.client.id,
      interviewCycleId: interview.interviewCycle.id,
      isScheduled: Boolean(interview.scheduledFor),
      interviewerNames: interview.interviewerNames,
      notes: interview.notes,
      datetime: interview.scheduledFor
        ? formatDatetimeWithTz(interview.scheduledFor)
        : '',
      timezone: interview.scheduledFor?.timezone ?? null,
    }
  }

  return {
    ...DEFUALT_STATE,
    clientId: clientId ?? '',
    interviewCycleId: interviewCycleId ?? '',
  }
}

type ScheduledForState = Pick<State, 'datetime' | 'timezone'>
/**
 * used before executing report time mutation, formats the scheduledFor input correct
 * @param state datetime + tz
 * @returns DatetimeWithTzInput or null
 */
export function getScheduledFor(
  state: ScheduledForState
): DatetimeWithTzInput | null {
  if (state.datetime && state.timezone) {
    const mappedTimezone = TIMEZONE_MAP[state.timezone]
    const datetime = dayjs(state.datetime).tz(mappedTimezone, true).local().format()
    return {
      datetime: datetime,
      timezone: state.timezone,
    }
  }
  return null
}

export function formatDatetimeWithTz(
  datetimeWithTz: DatetimeWithTz,
  formatStr: string = DATE_FORMAT.DATETIME_LOCAL_INPUT,
  tzType: TzType = TzType.TzTypeDefault
): string {
  const tz =
    tzType === TzType.TzTypeDefault
      ? undefined
      : TIMEZONE_MAP[datetimeWithTz.timezone]
  return dayjs(datetimeWithTz.datetime).tz(tz).format(formatStr)
}
