import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import { EMPTY_INPUT } from 'utils/constants'
import useFormState from 'views/ClientTracker/useFormState'
import { InterviewCycle } from './InterviewCycleDetail.types'
import Button from 'components/Button'
import HList from 'components/HList'
import { TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import {
  ModifyCycleMetaMutation,
  ModifyCycleMetaMutationVariables,
} from 'generated/graphql'

type Props = {
  interviewCycle: InterviewCycle
  closeForm(): void
}

export default function InterviewCycleMetaSection({
  interviewCycle,
  closeForm,
}: Props): React.ReactElement {
  const { formState, setFormField } = useFormState<FormState>({
    employerName: interviewCycle.employerName,
    jobTitle: interviewCycle.jobTitle,
    jobUrl: interviewCycle.jobUrl,
  })

  const [modifyCycleMeta] = useMutation<
    ModifyCycleMetaMutation,
    ModifyCycleMetaMutationVariables
  >(MODIFY_CYCLE_META, {
    onCompleted() {
      toast.success(COPY.modifySuccess)
      closeForm()
    },
    onError() {
      toast.error(COPY.modifyError)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()

    void modifyCycleMeta({
      variables: {
        input: {
          interviewCycleId: interviewCycle.id,
          jobTitle: formState.jobTitle,
          jobUrl: formState.jobUrl ?? '',
        },
      },
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <HList size={2}>
          <TextInput
            name="jobTitle"
            label="Job Title"
            value={formState.jobTitle ?? EMPTY_INPUT}
            onValueChange={(value) => setFormField('jobTitle', value)}
            required
          />
          <TextInput
            name="employerName"
            label="Employer Name"
            value={formState.employerName ?? EMPTY_INPUT}
            onValueChange={(value) => setFormField('employerName', value)}
            disabled
          />
          <TextInput
            name="jobUrl"
            label="Job Description URL"
            value={formState.jobUrl ?? EMPTY_INPUT}
            onValueChange={(value) => setFormField('jobUrl', value)}
            type="url"
            placeholder="https://"
            autoComplete="off"
          />
        </HList>
        <Padding top={3}>
          <HList size={1}>
            <Button $type="accept" type="submit">
              Save
            </Button>
            <Button $type="secondary" type="button" onClick={closeForm}>
              Cancel
            </Button>
          </HList>
        </Padding>
      </form>
    </div>
  )
}

InterviewCycleMetaSection.fragments = {
  metaSection: gql`
    fragment MetaSectionInfo on InterviewCycle {
      id
      jobTitle
      employerName
      jobUrl
    }
  `,
}

const MODIFY_CYCLE_META = gql`
  mutation ModifyCycleMeta($input: ModifyInterviewCycleMetaInput!) {
    modifyInterviewCycleMeta(input: $input) {
      interviewCycle {
        ...MetaSectionInfo
      }
    }
  }
  ${InterviewCycleMetaSection.fragments.metaSection}
`

type FormState = {
  jobTitle?: string | null
  employerName?: string | null
  jobUrl?: string | null
}

const COPY = {
  modifySuccess: 'Interview cycle modified.',
  modifyError: 'There was and error modifying the interview cycle.',
}
