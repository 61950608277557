import dayjs from 'dayjs'
import { toast } from 'react-toastify'
import { DATE_FORMAT, HUNTR_REGEX, QS } from 'utils/constants'
import { TOAST } from './NewInterviewCycle.constants'
import { State } from './NewInterviewCycle.types'

/**
 * returns true if state is valid
 * @param state form date
 */
export function validateForm(state: State): boolean {
  if (!state.clientId) {
    toast.error(TOAST.MISSING_CLIENT_ID)
    return false
  }
  if (!state.huntrUrl) {
    toast.error(TOAST.MISSING_HUNTR_URL)
    return false
  }
  if (!HUNTR_REGEX.test(state.huntrUrl)) {
    toast.error(TOAST.HUNTR_MATCH)
    return false
  }
  if (!state.source) {
    toast.error(TOAST.MISSING_SOURCE)
    return false
  }
  if (!state.startDate) {
    toast.error(TOAST.MISSING_DATE)
    return false
  }

  return true
}

export function getDefaultState(queryString: URLSearchParams): State {
  const defaultClientId = queryString.get(QS.CLIENT_ID) || ''
  return {
    clientId: defaultClientId,
    huntrUrl: '',
    source: null,
    startDate: dayjs().format(DATE_FORMAT.ONLY_DATE),
  }
}
