import dayjs from 'dayjs'
import { KeyValue, Rating, Maybe } from 'types'
import getNodes from 'utils/helpers/getNodes'
import parseRating from 'utils/helpers/parseRating'
import { getAvgTimeSpentSec, getAvgRating } from 'utils/helpers/stats'
import { GROUP_BY_TYPES } from './ReviewedTaglines.constants'
import {
  Filter,
  GroupByOptions,
  TableRowData,
  TaglineSubmission,
  TaglineSubmissions,
} from './ReviewedTaglines.types'

/**
 * adds / flattens properties needed to sort/filter to our raw tagline submission objects
 * @param connection taglineSubmissions connection
 */
export function buildExtendedSubmissions(
  connection: Maybe<TaglineSubmissions>
): TaglineSubmission[] | undefined {
  if (!connection) {
    return undefined
  }
  const rawTaglineSubmissions = getNodes(connection)

  return rawTaglineSubmissions.reduce((final: TaglineSubmission[], submission) => {
    const talentAgentReview = submission.talentAgentReviews[0]
    const talentPartnerReview = submission.talentPartnerReviews[0]
    if (!talentAgentReview || !talentPartnerReview) {
      return final
    }

    const newTaglineSubmission = {
      talentAgentReview,
      talentPartnerReview,
      id: submission.id,
      timeSpentSec: submission.timeSpentSec,
      writerId: submission.createdBy.id,
      writerName: submission.createdBy.name,
      clientId: submission.taglineRequest.client.id,
      clientName: submission.taglineRequest.client.name,
      talentAgentId: submission.taglineRequest.client.talentAgent.id,
      talentAgentName: submission.taglineRequest.client.talentAgent.name,
      dateToFilterBy: dayjs(submission.createdAt),
    }

    final.push(newTaglineSubmission)
    return final
  }, [])
}

/**
 * returns the value that tagline requests should be grouped by
 * to get the right breakdown of data
 *
 * @param groupBy groupby filter value
 * @param submission tagline submission record
 */
function getGroupValue(groupBy: GroupByOptions, submission: TaglineSubmission) {
  switch (groupBy) {
    case GROUP_BY_TYPES.CLIENT_ID:
      return `${submission.writerId}:${submission.clientId}`
    case GROUP_BY_TYPES.TALENT_AGENT_ID:
      return `${submission.talentAgentId}:${submission.writerId}`
    default:
      return submission.writerId
  }
}

/**
 * separate tagline submissions into groups given a `groupBy` prop name
 * @param groups accumulating value of groups of tagline submissions
 * @param submission single tagline submission
 * @param groupBy groupby filter value
 */
export function groupRequests(
  groups: KeyValue<TaglineSubmission[]>,
  submission: TaglineSubmission,
  groupBy: GroupByOptions
): KeyValue<TaglineSubmission[]> {
  const groupValue = getGroupValue(groupBy, submission)
  const entries = groups[groupValue]
  if (entries) {
    entries.push(submission)
  } else {
    groups[groupValue] = [submission]
  }
  return groups
}

/**
 * filter out any unwanted tagline requests
 * @param request a tagline request
 * @param filter filter object with dropdown values
 */
export function filterRequests(request: TaglineSubmission, filter: Filter): boolean {
  if (filter.talentAgentId && request.talentAgentId !== filter.talentAgentId) {
    return false
  }
  if (filter.clientId && request.clientId !== filter.clientId) {
    return false
  }
  if (filter.writerId && request.writerId !== filter.writerId) {
    return false
  }
  if (request.dateToFilterBy < filter.fromDate) {
    return false
  }
  return true
}

/**
 * this is used within a `reduce` HOF
 * calculate all the data needed for a table row and accumulate them in an array
 * @param tableRowData accumulative results
 * @param group array of Tagline Requests
 */
export function buildTableData(
  tableRowData: TableRowData[],
  submissions: TaglineSubmission[]
): TableRowData[] {
  const firstSubmission = submissions[0]

  if (!firstSubmission) {
    return tableRowData
  }

  const total = submissions.length
  const times: number[] = []
  const talentAgentRatings: Rating[] = []
  const talentPartnerRatings: Rating[] = []
  let rejectedCount = 0

  submissions.forEach((submission) => {
    const talentAgentReview = submission.talentAgentReview
    const talentPartnerReview = submission.talentPartnerReview

    talentAgentRatings.push(parseRating(talentAgentReview.rating))
    talentPartnerRatings.push(parseRating(talentPartnerReview.ratingWriter))
    times.push(submission.timeSpentSec)

    if (talentPartnerReview.taglineRejectionReason) {
      rejectedCount += 1
    }
  })

  const [avgTimeSpentSec, avgTimeSpentSecTotal] = getAvgTimeSpentSec(times)
  const rejectionRate = rejectedCount / total
  const [
    avgTalentAgentRating,
    avgTalentAgentRatingTotal,
    talentAgentOnesAndTwosRate,
  ] = getAvgRating(talentAgentRatings)
  const [
    avgTalentPartnerRating,
    avgTalentPartnerRatingTotal,
    talentPartnerOnesAndTwosRate,
  ] = getAvgRating(talentPartnerRatings)

  tableRowData.push({
    total,
    avgTimeSpentSec,
    avgTimeSpentSecTotal,
    avgTalentAgentRating,
    avgTalentAgentRatingTotal,
    talentAgentOnesAndTwosRate,
    avgTalentPartnerRating,
    avgTalentPartnerRatingTotal,
    talentPartnerOnesAndTwosRate,
    rejectionRate,
    talentAgentName: firstSubmission.talentAgentName,
    clientName: firstSubmission.clientName,
    writerName: firstSubmission.writerName,
    talentAgentId: firstSubmission.talentAgentId,
    clientId: firstSubmission.clientId,
    writerId: firstSubmission.writerId,
  })

  return tableRowData
}
