import { gql } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { ReactSetState } from 'types'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { MimeType } from 'utils/enums'
import convertHtmlToText from 'utils/helpers/convertHtmlToText'
import {
  ClipboardCopyValues,
  LocalPreferences,
} from 'views/Preferences/Preferences.constants'
import Editor from 'components/Editor/Editor'
import EditorLabelWithAction from 'components/EditorLabelWithAction/EditorLabelWithAction'
import Icon from 'components/Icon/Icon'
import JobHeading from 'components/JobHeading/JobHeading'
import { Application, FinalCopyState } from './FinalCopy.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Label from 'components/Label'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  application: Application
  state: FinalCopyState
  setState: ReactSetState<FinalCopyState>
  canEdit: boolean
}

function FinalCopyRight({
  application,
  state,
  setState,
  canEdit,
}: Props): JSX.Element {
  function handleEditorChange(value: string, id: string) {
    const newChanges = { ...state.changes, [id]: value }

    setState({ ...state, changes: newChanges })
  }

  /**
   * use the local preference to determine if they want to have HTML or plaintext
   */
  function determineShouldConvert() {
    const copyClipboardPreferenceRaw = localStorage.getItem(
      LocalPreferences.ClipboardCopyType
    )

    /* TODO (matthewalbrecht): find a good solution to type guard JSON.parsed values */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const copyClipboardPreference = copyClipboardPreferenceRaw
      ? JSON.parse(copyClipboardPreferenceRaw)
      : copyClipboardPreferenceRaw

    // this allows us to defualt to converting if there is no preference set
    return copyClipboardPreference !== ClipboardCopyValues.HTML
  }

  const clipboard = useCopyClipboard({
    onSuccess() {
      toast.success('Copied to clipboard!')
    },
  })

  const shouldConvert = determineShouldConvert()

  return (
    <>
      {canEdit && (
        <Flex justify="flex-end">
          {state.isLocked ? (
            <button
              onClick={() => setState({ ...state, isLocked: false })}
              disabled={!state.isLocked}
            >
              <Icon name={'Lock'} height={16} width={16} primaryFill="text" />
            </button>
          ) : (
            <Icon name={'OpenLock'} height={16} width={18} primaryFill="text" />
          )}
        </Flex>
      )}
      <Padding bottom={4}>
        <Txt bold size={20} lineHeight={1.3} color="text">
          <JobHeading
            jobTitle={application.jobTitle}
            company={application.company ?? { name: application.employerName }}
            huntrUrl={application.huntrUrl}
          />
        </Txt>
        <Padding top={1}>
          {application.jobUrl && (
            <ExternalLink url={application.jobUrl} label="Job Description" />
          )}
        </Padding>
      </Padding>
      <VList size={6}>
        {application.items.map((item) => {
          const plainTextContent = convertHtmlToText(item.copy)
          return (
            <div key={item.id}>
              <EditorLabelWithAction
                label={<Label padding={2} content={`Final ${item.name}`} />}
                ActionIconName="Clipboard"
                buttonTitle="Copy to clipboard"
                showActionButton={state.isLocked}
                onActionClick={() => {
                  clipboard.copy(
                    shouldConvert ? plainTextContent : item.copy,
                    shouldConvert ? MimeType.Plain : MimeType.Html
                  )
                }}
              />
              {state.isLocked && shouldConvert ? (
                <TextArea
                  key={item.id}
                  defaultValue={plainTextContent}
                  height={360}
                  disabled
                />
              ) : (
                <Editor
                  key={item.id}
                  disabled={state.isLocked}
                  defaultValue={item.copy}
                  handleChange={(value: string) =>
                    handleEditorChange(value, item.id)
                  }
                  height={160}
                />
              )}
            </div>
          )
        })}
      </VList>
    </>
  )
}

FinalCopyRight.fragments = {
  applicationInfo: gql`
    fragment FinalCopyRightInfo on Application {
      id
      huntrUrl
      jobUrl
      createdAt
      items {
        id
        copy
        name
      }
      ...JobHeadingInfoApplication
    }
    ${JobHeading.fragments.jobHeadingInfoApplication}
  `,
}

export default FinalCopyRight
