import { Context, createContext, useContext } from 'react'
import { UserSession } from 'types'
import { EMPTY_USER_SESSION } from 'utils/constants'

type AuthContextType = {
  userSession: UserSession
  setUserSession: (userSession: UserSession) => void
}

export const AuthContext: Context<AuthContextType> = createContext({
  // initiating the authcontext with dummy data to appease typescript
  userSession: EMPTY_USER_SESSION,
  // eslint-disable-next-line @typescript-eslint/no-empty-function, @typescript-eslint/no-unused-vars
  setUserSession: (userSession: UserSession) => {},
})

export function useAuthContext(): AuthContextType {
  const context = useContext(AuthContext)
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthProvider')
  }
  return context
}
