import React from 'react'
import { generatePath } from 'react-router'
import useTalentAgentQueue from 'hooks/useTalentAgentQueue'
import { INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import TableBody from 'components/Table/TableBody'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS } from './InitialReviewPodsTable.constants'
import { RowData } from './InitialReviewPodsTable.types'

export default function InitialReviewPodsTable(): JSX.Element {
  const { queueByPod, loading } = useTalentAgentQueue({}, true)

  const rowData: RowData[] = Object.entries(queueByPod).map(([podId, queue]) => ({
    podId,
    queue,
  }))

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData.map(({ podId, queue }) => (
          <tr key={podId}>
            <TableBodyItem first content={`${parseInt(podId) + 1}`} />
            <TableBodyActionList>
              {queue[0] && (
                <TableBodyAction
                  to={generatePath(ROUTE_PATHS.AGENT_QUEUE_BY_POD, {
                    podId,
                    requestId: INITIAL,
                  })}
                  content={`Queue (${queue.length})`}
                />
              )}
            </TableBodyActionList>
          </tr>
        ))}
      </TableBody>
      {loading && <TableEmpty message="Loading..." />}
      {!loading && !rowData.length && (
        <TableEmpty message="No Initial Reviews Found" />
      )}
    </table>
  )
}
