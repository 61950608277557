import { gql } from '@apollo/client'
import React, { useState } from 'react'
import Icon from 'components/Icon/Icon'
import { MissingApplicationInfo } from './ClientApplications.types'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import { TextArea } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  missingApplicationInfo: MissingApplicationInfo
}

export default function ClientApplicationsYellowCardDetails({
  missingApplicationInfo,
}: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function handleClose() {
    setIsOpen(false)
  }

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <Icon
          name="Info"
          height={16}
          width={16}
          primaryFill="text"
          primaryFillHover="black"
          display="block"
        />
      </button>
      <Dialog
        isOpen={isOpen}
        onDismiss={handleClose}
        aria-label="Client application yellow card details"
      >
        <Padding vertical={4} horizontal={4}>
          <DialogExitButton onClick={() => setIsOpen(false)} />
          <VList size={4}>
            <Txt as="h2" bold size={24}>
              Missing Application Info
            </Txt>
            {missingApplicationInfo.answers ? (
              <TextArea
                label="Answers"
                value={missingApplicationInfo.answers}
                height={250}
                disabled
              />
            ) : (
              <>
                <TextArea
                  label="Questions"
                  value={missingApplicationInfo.questions}
                  height={250}
                  disabled
                />
                <Message message="Missing application info has not been provided." />
              </>
            )}
          </VList>
        </Padding>
      </Dialog>
    </>
  )
}

ClientApplicationsYellowCardDetails.fragments = {
  yellowCardDetailInfo: gql`
    fragment YellowCardDetails on Application {
      missingApplicationInfos {
        id
        questions
        answers
      }
    }
  `,
}
