import dayjs from 'dayjs'
import React, { useState } from 'react'
import { ConnectionNode, Rating } from 'types'
import { AccountPersonaMap, DATE_FORMAT } from 'utils/constants'
import StarRating from 'components/StarRating/StarRating'
import { getTagColorFromPersona } from './ClientTracker.helpers'
import { Client } from './ClientTracker.types'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Label from 'components/Label'
import Message from 'components/Message'
import { Line, Tag } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import { AccountPersona } from 'generated/graphql'

type Props = {
  client: Client
}

export default function ClientTrackerStatusUpdateHistory({
  client,
}: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  function handleClose() {
    setIsOpen(false)
  }
  return (
    <div>
      <button type="button" onClick={() => setIsOpen(true)}>
        <Txt link>View History</Txt>
      </button>
      <Dialog
        isOpen={isOpen}
        onDismiss={handleClose}
        aria-label={`Client status update history`}
        widthVW={[90, { sm: 80, md: 55, lg: 45, xl: 35 }]}
      >
        <Padding horizontal={4}>
          <DialogExitButton onClick={handleClose} />
        </Padding>
        <Padding vertical={4} horizontal={4}>
          <Padding bottom={2}>
            <Txt as="h2" bold size={24}>
              Status Update History
            </Txt>
          </Padding>
          <Line />
          {client.statusUpdates.edges.map(({ node: statusUpdate }) => (
            <div key={statusUpdate.id}>
              <Padding vertical={3}>
                <StatusUpdate statusUpdate={statusUpdate} />
              </Padding>
              <Line />
            </div>
          ))}
          {client.statusUpdates.edges.length === 0 && (
            <Message message="No status updates found." />
          )}
        </Padding>
      </Dialog>
    </div>
  )
}

type StatusUpdateProps = {
  statusUpdate: ConnectionNode<Client['statusUpdates']>
}

function StatusUpdate({ statusUpdate }: StatusUpdateProps): React.ReactElement {
  return (
    <>
      <Padding bottom={2}>
        <Flex justify="space-between">
          <HList size={4}>
            <div>
              <Label content="Relationship Strength" size={14} padding={1} />
              <StarRating
                rating={statusUpdate.relationshipStrength as Rating}
                iconSize={14}
                disabled
              />
            </div>
            <div>
              <Label content="Client Satisfaction" size={14} padding={1} />
              <StarRating
                rating={statusUpdate.satisfactionRating as Rating}
                iconSize={14}
                disabled
              />
            </div>
          </HList>
          <Padding left={4}>
            <Txt noWrap>
              {dayjs(statusUpdate.createdAt).format(DATE_FORMAT.FRIENDLY_DATE)}
            </Txt>
          </Padding>
        </Flex>
      </Padding>
      <Padding bottom={2}>
        <HList size={2}>
          {statusUpdate.accountPersonas.map((persona) => (
            <PersonaTag key={persona} persona={persona} />
          ))}
        </HList>
      </Padding>
      <Txt lineHeight={1.3}>{statusUpdate.statusMessage}</Txt>
    </>
  )
}

function PersonaTag({ persona }: { persona: AccountPersona }) {
  const { tagColor, textColor } = getTagColorFromPersona(persona)
  return (
    <Tag color={tagColor} small>
      <Txt size={12} color={textColor}>
        {AccountPersonaMap[persona]}
      </Txt>
    </Tag>
  )
}
