import { DossierType } from 'generated/graphql'

export const DEFAULT_STATE = {
  clientId: '',
  interviewCycleId: '',
  notes: '',
  dueDate: '',
  dueHour: null,
}

export const DOSSIER_OPTIONS = [
  {
    value: DossierType.INTRO,
    label: 'Intro / First Call',
  },
  {
    value: DossierType.ADVANCED,
    label: 'Advanced',
  },
  {
    value: DossierType.CHEAT_SHEET,
    label: 'Cheat Sheet',
  },
]

export const TOAST = {
  CREATE_SUCCESS: 'Dossier request submitted',
  CREATE_ERROR: 'There was an error submitting the dossier request',
  MODIFY_SUCCESS: 'Dossier request updated',
  MODIFY_ERROR: 'There was an error updating the dossier request',
}

export const DUE_TIME_OPTIONS = [
  {
    value: 9,
    label: '9am',
  },
  {
    value: 11,
    label: '11am',
  },
  {
    value: 13,
    label: '1pm',
  },
  {
    value: 15,
    label: '3pm',
  },
  {
    value: 17,
    label: '5pm',
  },
  {
    value: 19,
    label: '7pm',
  },
  {
    value: 21,
    label: '9pm',
  },
]
