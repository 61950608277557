import { gql } from '@apollo/client'
import { ColumnHeader } from 'types'

export const HEADERS: ColumnHeader[] = [
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'Email',
    id: 'email',
  },
  {
    label: 'Talent Agent',
    id: 'talentAgent',
  },
  {
    label: 'Active',
    id: 'active',
  },
]

export const ID = {
  FORM: 'clientForm',
}

export const TOAST = {
  CREATE_SUCCESS: 'Client created',
  CREATE_ERROR: 'There was an error creating the client.',
}

export const ClientFragment = gql`
  fragment ClientInfo on Client {
    id
    name
    email
    isInactive
    talentAgent {
      id
      name
    }
  }
`
