import { createSearchParams } from 'utils/urls'
import { Suggestion } from './CompanySuggestion.types'

// function to grab value of suggestion once selected given the suggestion object
export function getSuggestionValue(suggestion: Suggestion): string {
  return suggestion.name
}

// uses Fetch API to hit https://clearbit.com/docs?javascript#autocomplete-api
export async function getCompanies(
  value: string
): Promise<Suggestion[] | { message: string }> {
  const qs = createSearchParams({ query: value })
  const res = await fetch(
    `https://autocomplete.clearbit.com/v1/companies/suggest?${qs}`
  )
  /* TODO (matthewalbrecht): find a better solution to ensure type */
  return res.json() as Promise<Suggestion[] | { message: string }>
}

// used to conditionally render the suggestions
export function shouldRenderSuggestions(value: string): boolean {
  return value.trim().length > 2
}
