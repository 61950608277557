import dayjs from 'dayjs'
import { DropdownOption, Maybe } from 'types'
import { DATE_FORMAT, DOSSIER_DUE_TZ } from 'utils/constants'
import { sortByProp } from 'utils/sort'
import {
  DEFAULT_STATE,
  DUE_TIME_OPTIONS,
} from './AddAndEditDossierRequest.constants'
import {
  DossierRequest,
  DossierSubmission,
  State,
} from './AddAndEditDossierRequest.types'

export function getDue(date: string, hour: number): string {
  return dayjs(date).hour(hour).tz(DOSSIER_DUE_TZ, true).format()
}

type QueryParams = {
  clientId: string | null
  interviewCycleId: string | null
}

export function getDefaultState(
  dossierRequest: Maybe<DossierRequest>,
  dossierSubmission: Maybe<DossierSubmission>,
  { clientId, interviewCycleId }: QueryParams
): State {
  if (dossierRequest) {
    const dueDayjs = dayjs(dossierRequest.due).tz(DOSSIER_DUE_TZ)
    return {
      clientId: dossierRequest.client.id,
      interviewCycleId: dossierRequest.interviewCycle.id,
      notes: dossierRequest.notes,
      dossierType: dossierRequest.dossierType,
      dueDate: dueDayjs.format(DATE_FORMAT.ONLY_DATE),
      dueHour: dueDayjs.hour(),
    }
  }
  if (dossierSubmission) {
    return {
      ...DEFAULT_STATE,
      clientId: dossierSubmission.client.id,
      interviewCycleId: dossierSubmission.interviewCycle.id,
      dossierType: dossierSubmission.dossierRequest.dossierType,
    }
  }
  return {
    ...DEFAULT_STATE,
    clientId: clientId ?? '',
    interviewCycleId: interviewCycleId ?? '',
  }
}

export function getHourOptions(hour: number | null): DropdownOption<number>[] {
  const existingHourValues = DUE_TIME_OPTIONS.map((option) => option.value)
  if (hour && !existingHourValues.includes(hour)) {
    const label = dayjs().hour(hour).format(DATE_FORMAT.HOUR_12)
    const timeOptions = [...DUE_TIME_OPTIONS, { label: label, value: hour }].sort(
      (a, b) => sortByProp(a, b, 'value')
    )
    return timeOptions
  }
  return DUE_TIME_OPTIONS
}

export function getFormHeading(
  isEditing: boolean,
  isUpdateRequest: boolean
): string {
  if (isEditing) {
    return 'Edit Dossier Request'
  } else if (isUpdateRequest) {
    return 'Dossier Update Request'
  } else {
    return 'New Dossier Request'
  }
}
