import ReachTooltip, { TooltipProps } from '@reach/tooltip'
import React from 'react'
import styled from 'styled-components/macro'
import Icon from 'components/Icon/Icon'

type Props = {
  content: string
}

export default function WrappedTooltip({ content }: Props): React.ReactElement {
  return (
    <Tooltip label={content}>
      <div style={{ display: 'inline-block' }}>
        <Icon name="Info" width={12} height={12} primaryFill="text" />
      </div>
    </Tooltip>
  )
}

type NewTooltipProps = {
  children: React.ReactNode
} & TooltipProps

export function Tooltip({
  children,
  ...tooltipProps
}: NewTooltipProps): React.ReactElement {
  return <TooltipStyled {...tooltipProps}>{children}</TooltipStyled>
}

const TooltipStyled = styled(ReachTooltip)`
  background: ${({ theme }) => theme.color.white};
  border: none;
  border-radius: ${({ theme }) => theme.borderRadius};
  font-family: ${({ theme }) => theme.font.primary};
  padding: 0.5rem 1rem;
  white-space: normal;
  max-width: 25rem;
  overflow-wrap: break-word;
`
