import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import { Params } from './AddAndEditInterview.types'
import AddAndEditInterviewFooter from './AddAndEditInterviewFooter'
import AddAndEditInterviewForm from './AddAndEditInterviewForm'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'
import { GetInterviewQuery, GetInterviewQueryVariables } from 'generated/graphql'

type Props = {
  isEditing: boolean
}

export default function AddAndEditInterview({
  isEditing,
}: Props): React.ReactElement {
  const { interviewId } = useParams<Params>()
  const { data } = useQuery<GetInterviewQuery, GetInterviewQueryVariables>(
    GET_INTERVIEW,
    {
      variables: {
        id: interviewId,
      },
      skip: !isEditing,
    }
  )
  const interview = data?.interview
  const showForm = !isEditing || interview

  return (
    <ViewBox footer={<AddAndEditInterviewFooter />}>
      <Container noMax>
        <Padding top={6}>
          {showForm && (
            <AddAndEditInterviewForm interview={interview} isEditing={isEditing} />
          )}
        </Padding>
      </Container>
    </ViewBox>
  )
}

AddAndEditInterview.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ADD_INTERIVEW_FORM}
    key={ROUTE_PATHS.ADD_INTERIVEW_FORM}
  >
    <AddAndEditInterview isEditing={false} />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EDIT_INTERIVEW_FORM}
    key={ROUTE_PATHS.EDIT_INTERIVEW_FORM}
  >
    <AddAndEditInterview isEditing />
  </PrivateRoute>,
]

const GET_INTERVIEW = gql`
  query GetInterview($id: ID!) {
    interview(id: $id) {
      id
      interviewCycle {
        id
      }
      client {
        id
      }
      scheduledFor {
        datetime
        timezone
      }
      interviewerNames
      notes
    }
  }
`
