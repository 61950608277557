export const GROUP_BY_OPTIONS = [
  {
    value: 'sourcerId',
    label: 'Sourcer',
  },
  {
    value: 'clientId',
    label: 'Free Agent',
  },
  {
    value: 'talentAgentId',
    label: 'Talent Agent',
  },
]

export enum FILTER_TYPES {
  GROUP_BY = 'groupBy',
  SOURCER_ID = 'sourcerId',
  CLIENT_ID = 'clientId',
  START_DATE = 'startDate',
  TALENT_AGENT_ID = 'talentAgentId',
}

export enum GROUP_BY_TYPES {
  CLIENT_ID = 'clientId',
  SOURCER_ID = 'sourcerId',
  TALENT_AGENT_ID = 'talentAgentId',
}

const COMMON_COLUMNS = [
  { id: 'avgRating', label: 'AVG Rating' },
  { id: 'onesAndTwosRate', label: '1&2s Rate' },
  { id: 'rejectionRate', label: 'Rejection Rate' },
]

export const TALENT_AGENT_COLUMNS = [
  { id: 'talentAgent', label: 'Talent Agent' },
  { id: 'sourcer', label: 'Sourcer' },
  ...COMMON_COLUMNS,
]

export const SOURCER_COLUMNS = [
  { id: 'sourcer', label: 'Sourcer' },
  ...COMMON_COLUMNS,
]

export const CLIENT_COLUMNS = [
  { id: 'freeAgent', label: 'Free Agent' },
  { id: 'sourcer', label: 'Sourcer' },
  ...COMMON_COLUMNS,
]
