/* eslint-disable @typescript-eslint/no-unsafe-return */
// built from https://github.com/jameslnewell/styled-components-spacing

import { css, DefaultTheme } from 'styled-components/macro'
import { map } from 'styled-components-breakpoint'

type Values =
  | number
  | {
      [mult: string]: number
    }

export type Props = {
  all?: Values
  horizontal?: Values
  vertical?: Values
  top?: Values
  bottom?: Values
  left?: Values
  right?: Values
  inline?: boolean
}

function getSpacingFromMultiplier(mult: number, theme: DefaultTheme): string {
  return theme.rhythm(mult)
}

export function _p(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}

export function _px(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding-left: ${getSpacingFromMultiplier(mult, theme)};
        padding-right: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}

export function _py(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding-top: ${getSpacingFromMultiplier(mult, theme)};
        padding-bottom: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}

export function _pt(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding-top: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}

export function _pr(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding-right: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}

export function _pb(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding-bottom: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}

export function _pl(values: Values, theme: DefaultTheme): string {
  return map(values, (mult: number) => {
    if (typeof mult === 'number') {
      return css`
        padding-left: ${getSpacingFromMultiplier(mult, theme)};
      `
    } else {
      return ''
    }
  })
}
