import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { SourcingRequest, SourcedJob, State } from './SourcingSubmission.types'
import SourcingSubmissionForm from './SourcingSubmissionForm'
import Padding from 'components/Padding'
import Txt, { Bold } from 'components/Txt'

const SubmittedSource = styled(Txt)`
  display: inline-block;
  padding: ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(1)};
  background-color: ${({ theme }) => theme.color.faGrey2};
  border-radius: ${({ theme }) => theme.borderRadius};
  font-size: ${({ theme }) => theme.toRems(12)};
  margin-right: ${({ theme }) => theme.rhythm(1)};
  margin-bottom: ${({ theme }) => theme.rhythm(1)};
`

type Props = {
  sourcingRequest: SourcingRequest
  state: State
  setState: ReactSetState<State>
}

function SourcingSubmissionRight({
  sourcingRequest,
  state,
  setState,
}: Props): React.ReactElement {
  return (
    <>
      <Padding bottom={8}>
        <Txt alignment="right">
          Jobs Requested: <Bold>{sourcingRequest.numJobs}</Bold>
        </Txt>
        <Padding top={3}>
          <Txt>
            Submitted Jobs: <Bold>{sourcingRequest.sourcedJobs.length}</Bold>
          </Txt>
          <Padding top={2}>
            {sourcingRequest.sourcedJobs.map((submission: SourcedJob) => (
              <SubmittedSource key={submission.id}>
                {submission.title} @ {submission.company.name}
              </SubmittedSource>
            ))}
          </Padding>
        </Padding>
      </Padding>
      <SourcingSubmissionForm
        state={state}
        setState={setState}
        sourcingRequest={sourcingRequest}
      />
    </>
  )
}

SourcingSubmissionRight.fragments = {
  sourcingRequestInfo: gql`
    fragment SourcingSubmissionRightInfo on SourcingRequest {
      numJobs
      sourcedJobs {
        id
        company: companyEntity {
          name
        }
        title
      }
    }
  `,
}

export default SourcingSubmissionRight
