import { FormState, InterviewPrepRequest } from './InterviewPrepRequests.types'

export function getDefaultFormState(
  interviewPrepRequest: InterviewPrepRequest
): FormState {
  return {
    assignees: interviewPrepRequest.assignees,
    isScheduled: Boolean(interviewPrepRequest.scheduledAt),
  }
}
