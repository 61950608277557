type Connection<TNode> = {
  edges: Edge<TNode>[]
}

type Edge<TNode> = {
  node: TNode
}

type MaybeConnection<TNode> = {
  edges?: Edge<TNode>[] | null
}

export default function getNodes<TNode>(connection: Connection<TNode>): TNode[] {
  return connection.edges.map((edge) => edge.node)
}

export function maybeGetNodes<TNode>(
  connection: MaybeConnection<TNode>
): TNode[] | undefined {
  return connection.edges?.map((edge) => edge.node)
}
