import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath, Link, useLocation } from 'react-router-dom'
import { Rating } from 'types'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER, QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import StarRating from 'components/StarRating/StarRating'
import { columnItems } from './TaglineWorkLog.constants'
import { TaglineSubmission } from './TaglineWorkLog.types'
import Button from 'components/Button'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'
import VList from 'components/VList'
import { TaglineRequestStatus, TaglineWorkRole } from 'generated/graphql'

type Props = {
  taglineSubmission: TaglineSubmission
  userRole: TaglineWorkRole
}

function TaglineWorkLogRow({
  taglineSubmission,
  userRole,
}: Props): JSX.Element | null {
  const { pathname } = useLocation()
  const talentAgentReview = taglineSubmission.talentAgentReviews[0]
  const talentPartnerReview = taglineSubmission.talentPartnerReviews[0]
  const isTaglineRequestRejected =
    taglineSubmission.taglineRequest.status === TaglineRequestStatus.REJECTED
  const isTaglineRequestReviewed =
    taglineSubmission.taglineRequest.status === TaglineRequestStatus.REVIEWED
  const isTaglineRejected = Boolean(
    talentPartnerReview?.taglineRejectionReason ||
      talentAgentReview?.taglineRejectionReason
  )
  const showRejectedTag = isTaglineRejected || isTaglineRequestRejected
  const taglineSubmissionDate = dayjs(taglineSubmission.createdAt)
    .tz()
    .format(DATE_FORMAT.DATE_AND_TIME)

  const talentAgentReviewDate =
    talentAgentReview &&
    dayjs(talentAgentReview.createdAt).tz().format(DATE_FORMAT.DATE_AND_TIME)

  const talentPartnerReviewDate =
    talentPartnerReview &&
    dayjs(talentPartnerReview.createdAt).tz().format(DATE_FORMAT.DATE_AND_TIME)

  const taRatingWriter = talentAgentReview && (
    <div>
      <Txt size={14} lineHeight={1.3}>
        IR for Writer:
      </Txt>
      <StarRating
        rating={talentAgentReview.rating as Rating}
        disabled
        iconSize={12}
      />
    </div>
  )

  const frRatingWriter = talentPartnerReview?.ratingWriter && (
    <div>
      <Txt size={14} lineHeight={1.3}>
        FR for Writer:
      </Txt>
      <StarRating
        rating={talentPartnerReview.ratingWriter as Rating}
        disabled
        iconSize={12}
      />
    </div>
  )

  const frRatingTA = talentPartnerReview?.ratingTalentAgent && (
    <div>
      <Txt size={14} lineHeight={1.3}>
        FR for IR:
      </Txt>
      <StarRating
        rating={talentPartnerReview.ratingTalentAgent as Rating}
        disabled
        iconSize={12}
      />
    </div>
  )

  const feedbackPath = generatePath(
    `${ROUTE_PATHS.REVIEW_FEEDBACK}/?${createSearchParams({
      [QS.CALLBACK]: pathname,
    })}`,
    {
      submissionId: taglineSubmission.id,
    }
  )

  const showWriterName = isTaglineRequestReviewed || isTaglineRequestRejected

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14} lineHeight={1.3}>
          {taglineSubmission.taglineRequest.client.name}
        </Txt>
      </BodyData>
      {!columnItems.talentAgent?.userRoleHide?.includes(userRole) && (
        <BodyData>
          <Txt as="span" size={14} lineHeight={1.3}>
            {taglineSubmission.taglineRequest.client.talentAgent.name}
          </Txt>
        </BodyData>
      )}
      <BodyData>
        <Txt as="span" size={14} lineHeight={1.3}>
          {taglineSubmission.taglineRequest.employerName}
        </Txt>
      </BodyData>
      {!columnItems.writer?.userRoleHide?.includes(userRole) && (
        <BodyData>
          <Txt as="span" size={14} lineHeight={1.3}>
            {showWriterName ? taglineSubmission.createdBy.name : '–'}
          </Txt>
        </BodyData>
      )}
      {!columnItems.writerSubmitDate?.userRoleHide?.includes(userRole) && (
        <BodyData>
          <Txt as="span" size={14} lineHeight={1.3}>
            {taglineSubmissionDate}
          </Txt>
        </BodyData>
      )}
      <BodyData>
        <Txt as="span" size={14} lineHeight={1.3}>
          {taglineSubmission.talentAgentReviews[0]?.createdBy.name ??
            EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14} lineHeight={1.3}>
          {talentAgentReviewDate ?? EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14} lineHeight={1.3}>
          {taglineSubmission.talentPartnerReviews[0]?.createdBy.name ??
            EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14} lineHeight={1.3}>
          {talentPartnerReviewDate ?? EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <VList size={2}>
          {taRatingWriter}
          {frRatingWriter}
          {frRatingTA}
          {isTaglineRequestReviewed && (
            <div>
              <Link to={feedbackPath}>
                <Button small as="span">
                  Review Feedback
                </Button>
              </Link>
            </div>
          )}
        </VList>
      </BodyData>
      <BodyData collapse>
        {showRejectedTag && (
          <Tag color="errorLight" small>
            <Txt size={12} color="white">
              Rejected
            </Txt>
          </Tag>
        )}
      </BodyData>
    </BodyRow>
  )
}

TaglineWorkLogRow.fragments = {
  taglineWorkLogRowInfo: gql`
    fragment TaglineWorkLogRowInfo on TaglineSubmission {
      taglineRequest {
        id
        employerName
        status
        client {
          id
          name
          talentAgent {
            id
            name
          }
        }
      }
      id
      createdAt
      createdBy {
        id
        name
      }
      talentAgentReviews {
        id
        createdAt
        createdBy {
          id
          name
        }
        feedback
        rating
        taglineRejectionReason
      }
      talentPartnerReviews {
        id
        createdAt
        createdBy {
          id
          name
        }
        feedbackTalentAgent
        ratingTalentAgent
        feedbackWriter
        ratingWriter
        taglineRejectionReason
      }
    }
  `,
}

export default TaglineWorkLogRow
