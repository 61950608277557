import { gql } from '@apollo/client'
import React, { useState } from 'react'
import {
  EMPTY_CELL_PLACEHOLEDER,
  ExpertFunctionalAreaMap,
  ExpertSeniorityLevelMap,
} from 'utils/constants'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import AddAndEditExpert from './AddAndEditExpert'
import { ExpertFragment } from './ExpertNetwork.constants'
import { Expert } from './ExpertNetwork.types'
import ExternalLink from 'components/ExternalLink'
import HList from 'components/HList'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'

type Props = {
  expert: Expert
}

export default function ExpertNetworkRow({ expert }: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData collapse>
        <EditExpertButton expert={expert} />
      </BodyData>
      <BodyData>
        <Txt size={14}>{expert.name}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} color="link">
          <ExternalLink inheritStyles url={expert.linkedinUrl}>
            <Icon name="ExternalLink" height={14} width={14} primaryFill="link" />
          </ExternalLink>
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {expert.currentTitle ? expert.currentTitle : EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>

      <BodyData>
        {expert.employers.edges.length ? (
          <HList size={1} rowGapSize={1}>
            {expert.employers.edges.map((employer) => (
              <Tag small color="faLightBlue" key={employer.node.id}>
                <Txt size={12}>{employer.node.name}</Txt>
              </Tag>
            ))}
          </HList>
        ) : (
          <Txt size={14}>{EMPTY_CELL_PLACEHOLEDER}</Txt>
        )}
      </BodyData>
      <BodyData>
        {expert.seniorityLevels.length ? (
          <HList size={1} rowGapSize={1}>
            {expert.seniorityLevels.map((seniorityLevel) => (
              <Tag small color="purple" key={seniorityLevel}>
                <Txt size={12} color="white">
                  {ExpertSeniorityLevelMap[seniorityLevel]}
                </Txt>
              </Tag>
            ))}
          </HList>
        ) : (
          <Txt size={14}>{EMPTY_CELL_PLACEHOLEDER}</Txt>
        )}
      </BodyData>
      <BodyData>
        {expert.functionalAreas.length ? (
          <HList size={1} rowGapSize={1}>
            {expert.functionalAreas.map((functionalArea) => (
              <Tag small color="faYellow" key={functionalArea}>
                <Txt size={12}>{ExpertFunctionalAreaMap[functionalArea]}</Txt>
              </Tag>
            ))}
          </HList>
        ) : (
          <Txt size={14}>{EMPTY_CELL_PLACEHOLEDER}</Txt>
        )}
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {expert.hourlyRate ? `$${expert.hourlyRate}` : EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        {/* TODO (matthewalbrecht): calculate YTD payments and conditionally show success */}
        {expert.is1099ed && (
          <Icon width={14} height={14} name="Check" primaryFill="success" />
        )}
      </BodyData>
    </BodyRow>
  )
}

ExpertNetworkRow.fragments = {
  ExpertNetworkRowInfo: gql`
    fragment ExpertNetworkRowInfo on Expert {
      ...ExpertInfo
    }
    ${ExpertFragment}
  `,
}

type EditExpertButtonProps = {
  expert: Expert
}

function EditExpertButton({ expert }: EditExpertButtonProps): React.ReactElement {
  const [isOpen, setisOpen] = useState(false)

  function handleClose() {
    setisOpen(false)
  }

  return (
    <>
      <button onClick={() => setisOpen(true)}>
        <Icon
          name="Expand"
          height={14}
          width={14}
          primaryFill="text"
          primaryFillHover="black"
        />
      </button>
      <AddAndEditExpert handleClose={handleClose} isOpen={isOpen} expert={expert} />
    </>
  )
}
