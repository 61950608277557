import React, { useState } from 'react'
import { Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import AddClient from 'views/Clients/AddClient'
import ClientsTable from './ClientsTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function Clients(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Flex justify="space-between" align="center">
            <Txt size={24} bold as="h2">
              Clients
            </Txt>
            {checkRole([Role.Coordinator]) && <NewClientButton />}
          </Flex>
        </Padding>
      </Container>
      <ClientsTable />
    </ViewBox>
  )
}

Clients.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.CLIENTS} key={ROUTE_PATHS.CLIENTS}>
    <Clients />
  </PrivateRoute>,
]

function NewClientButton(): React.ReactElement {
  const [isOpen, setisOpen] = useState(false)

  function handleClose() {
    setisOpen(false)
  }

  return (
    <>
      <Button $type="highlight" onClick={() => setisOpen(true)}>
        New Client
      </Button>
      <AddClient handleClose={handleClose} isOpen={isOpen} />
    </>
  )
}
