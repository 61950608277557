import { lighten } from 'polished'
import * as React from 'react'
import styled, { css } from 'styled-components/macro'
import { Font, Colors } from 'types'

type ButtonTypeValues =
  | 'primary'
  | 'secondary'
  | 'accept'
  | 'reject'
  | 'rejectAlt'
  | 'link'
  | 'yellow-card'
  | 'highlight'

export type ButtonProps = {
  $type?: ButtonTypeValues
  size?: number
  family?: keyof Font
  active?: boolean
  to?: string
  noWrap?: boolean
  fullWidth?: boolean
  small?: boolean
  large?: boolean
  as?: React.ElementType
  children?: React.ReactNode
  title?: string
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void
  color?: keyof Colors
  borderColor?: keyof Colors
}

const Button = styled.button<ButtonProps>`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 0.1rem solid
    ${({ theme, borderColor }) =>
      borderColor ? theme.color[borderColor] : theme.color.faGrey5};
  color: ${({ theme, color }) => (color ? theme.color[color] : theme.color.text)};
  cursor: pointer;
  display: inline-block;
  font-family: ${({ theme, family = 'primary' }) => theme.font[family]};
  font-size: ${({ size = 14, theme }) => theme.toRems(size)};
  line-height: normal;
  padding: ${({ theme }) =>
    css`
      ${theme.rhythm(0.75)} ${theme.rhythm(2)}
    `};
  user-select: none;

  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}

  &:hover:enabled {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }

  :disabled {
    cursor: unset;
    background-color: ${({ theme }) => theme.color.faGrey2};
    color: ${({ theme }) => theme.color.text};
  }

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}

  ${({ $type, theme }) =>
    $type === 'secondary' &&
    css`
      background-color: ${theme.color.faGrey5};
      border: none;
      color: ${theme.color.white};

      &:hover:enabled {
        background-color: ${theme.color.faGrey4};
      }
    `}

  ${({ $type, theme }) =>
    $type === 'highlight' &&
    css`
      background-color: ${theme.color.faBlue};
      border: none;
      color: ${theme.color.white};

      &:hover:enabled {
        background-color: ${({ theme }) => lighten(0.1, theme.color.faBlue)};
      }
    `}

  ${({ $type, theme }) =>
    $type === 'accept' &&
    css`
      background-color: ${theme.color.success};
      border: none;
      color: ${theme.color.white};

      &:hover:enabled {
        background-color: ${theme.color.successLight};
      }
    `}

  ${({ $type, theme }) =>
    $type === 'reject' &&
    css`
      background-color: ${theme.color.error};
      border: none;
      color: ${theme.color.white};

      &:hover:enabled {
        background-color: ${theme.color.errorLight};
      }
    `}

  ${({ $type, theme }) =>
    $type === 'yellow-card' &&
    css`
      background-color: ${theme.color.yellow};
      border: none;
      color: ${theme.color.textDark};

      &:hover:enabled {
        background-color: ${theme.color.yellowDark};
      }
    `}

  ${({ $type, theme }) =>
    $type === 'rejectAlt' &&
    css`
      color: ${theme.color.error};
    `}

  ${({ size = 12, theme, small }) =>
    small &&
    css`
      font-size: ${theme.toRems(size)};
      padding: ${theme.rhythm(0.5)} ${theme.rhythm(1)};
    `}

  ${({ size = 16, theme, large }) =>
    large &&
    css`
      font-size: ${theme.toRems(size)};
      padding: ${theme.rhythm(1)} ${theme.rhythm(2.25)};
    `}

  ${({ $type, size = 14, theme, active }) =>
    $type === 'link' &&
    css`
      background-color: transparent;
      color: ${theme.color.faGrey3};
      font-size: ${theme.toRems(size)};
      padding: 0;
      border: none;
      border-radius: 0;

      &:hover:enabled {
        background-color: ${theme.color.white};
        color: ${theme.color.text};
      }

      ${active &&
      css`
        color: ${theme.color.text};
        border-bottom: 0.1rem solid ${theme.color.faGrey4};
      `}
    `}
`

Button.defaultProps = {
  $type: 'primary',
}

export default Button
