import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { Rating } from 'types'
import { useAuthContext } from 'context/auth'
import { CACHE_STRING } from 'utils/cacheString'
import { EMPTY_INPUT } from 'utils/constants'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import StarRating from 'components/StarRating/StarRating'
import { COPY } from './ReviewDossierSubmission.constants'
import { DossierSubmission } from './ReviewDossierSubmission.types'
import Button from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import { TextArea } from 'components/Inputs'
import Txt from 'components/Txt'
import {
  ReviewDossierSubmissionMutation,
  ReviewDossierSubmissionMutationVariables,
} from 'generated/graphql'

type Props = {
  rowData: DossierSubmission
}

export default function ReviewDossierSubmissionRow({
  rowData,
}: Props): React.ReactElement {
  // state
  const [rating, setRating] = useState<Rating>(null)
  const [feedback, setFeedback] = useState<string>(EMPTY_INPUT)
  const {
    userSession: {
      user: { id: viewerId },
    },
  } = useAuthContext()

  // mutations
  const [recordReview] = useMutation<
    ReviewDossierSubmissionMutation,
    ReviewDossierSubmissionMutationVariables
  >(RECORD_REVIEW, {
    onCompleted() {
      toast.success(COPY.REVIEW_SUCCESS)
    },
    onError() {
      toast.error(COPY.REVIEW_ERROR)
    },
    update(cache) {
      removeItemFromCacheConnections<ReviewDossierSubmissionMutation>(
        cache,
        rowData.id,
        [CACHE_STRING.DOSSIER_SUBMISSIONS_UNREVIEWED(viewerId)]
      )
    },
  })

  // functions
  function handleSubmit() {
    const variables = {
      ReviewDossierSubmissionInput: {
        dossierSubmissionId: rowData.id,
        // submit button is disabled unless rating exists
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        rating: rating!,
        feedback: feedback,
      },
    }

    void recordReview({ variables })
  }

  // derived state
  const submitDisabled = rating == null || feedback === EMPTY_INPUT

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.jobTitle}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.employerName}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14} underline hoverColor="black">
          {rowData.fileUrl && (
            <ExternalLink url={rowData.fileUrl} inheritStyles label="Click Here" />
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <TextArea
          rows={1}
          minWidth={180}
          onValueChange={setFeedback}
          value={feedback}
          required
        />
      </BodyData>
      <BodyData>
        <StarRating iconSize={16} onValueChange={setRating} rating={rating} />
      </BodyData>
      <BodyData collapse>
        <Button
          $type="accept"
          small
          disabled={submitDisabled}
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </BodyData>
    </BodyRow>
  )
}

export const RECORD_REVIEW = gql`
  mutation ReviewDossierSubmission(
    $ReviewDossierSubmissionInput: ReviewDossierSubmissionInput!
  ) {
    reviewDossierSubmission(input: $ReviewDossierSubmissionInput) {
      dossierSubmission {
        id
        reviews {
          id
          rating
          feedback
        }
      }
    }
  }
`
