import React from 'react'
import { useLocation } from 'react-router'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { NavOption } from './Assign.types'
import Button from 'components/Button'
import HList from 'components/HList'

const StyledNavLink = styled(Button)<{ activeClassName?: string }>`
  &.active {
    background-color: blue;
  }
`

type Props = {
  assignData: NavOption
  reassignData: NavOption
}

export default function AssignNav({ assignData, reassignData }: Props): JSX.Element {
  const { pathname } = useLocation()
  const getButtonType = (routePath: string) =>
    pathname === routePath ? 'secondary' : 'primary'

  function renderStyledLink(option: NavOption) {
    return (
      <StyledNavLink as={Link} to={option.route} $type={getButtonType(option.route)}>
        {option.header}
      </StyledNavLink>
    )
  }

  return (
    <HList size={2}>
      {renderStyledLink(assignData)}
      {renderStyledLink(reassignData)}
    </HList>
  )
}
