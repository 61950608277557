import React from 'react'
import {
  avgRatingCopy,
  avgTimeSpentSecCopy,
  oneAndTwosRateCopy,
  rateAsPctCopy,
} from 'utils/helpers/stats'
import { SHOW_TALENT_AGENT_REVIEW } from 'utils/settings'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { GROUP_BY_TYPES } from './ReviewedTaglines.constants'
import { GroupByOptions, TableRowData } from './ReviewedTaglines.types'
import Txt from 'components/Txt'

type Props = {
  rowData: TableRowData
  groupBy: GroupByOptions
}

export default function ReviewedTaglinesRow({
  rowData: {
    total,
    avgTimeSpentSec,
    avgTimeSpentSecTotal,
    avgTalentAgentRating,
    avgTalentAgentRatingTotal,
    talentAgentOnesAndTwosRate,
    avgTalentPartnerRating,
    avgTalentPartnerRatingTotal,
    talentPartnerOnesAndTwosRate,
    rejectionRate,
    clientName,
    writerName,
    talentAgentName,
  },
  groupBy,
}: Props): JSX.Element {
  return (
    <BodyRow>
      {groupBy === GROUP_BY_TYPES.CLIENT_ID && (
        <BodyData>
          <Txt as="span" size={14}>
            {clientName}
          </Txt>
        </BodyData>
      )}
      {groupBy === GROUP_BY_TYPES.TALENT_AGENT_ID && (
        <BodyData>
          <Txt as="span" size={14}>
            {talentAgentName}
          </Txt>
        </BodyData>
      )}
      <BodyData>
        <Txt as="span" size={14}>
          {writerName}
        </Txt>
      </BodyData>
      {SHOW_TALENT_AGENT_REVIEW && (
        <>
          <BodyData>
            <Txt as="span" size={14}>
              {avgRatingCopy(avgTalentAgentRating, avgTalentAgentRatingTotal)}
            </Txt>
          </BodyData>
          <BodyData>
            <Txt as="span" size={14}>
              {oneAndTwosRateCopy(
                talentAgentOnesAndTwosRate,
                avgTalentAgentRatingTotal
              )}
            </Txt>
          </BodyData>
        </>
      )}
      <BodyData>
        <Txt as="span" size={14}>
          {avgRatingCopy(avgTalentPartnerRating, avgTalentPartnerRatingTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {oneAndTwosRateCopy(
            talentPartnerOnesAndTwosRate,
            avgTalentPartnerRatingTotal
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {avgTimeSpentSecCopy(avgTimeSpentSec, avgTimeSpentSecTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rateAsPctCopy(rejectionRate, total)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
