import React from 'react'
import { IndustrySuggestion } from './IndustrySuggestion.types'
import Txt from 'components/Txt'

type Props = {
  suggestion: IndustrySuggestion
}

export function IndustrySuggestionItem({ suggestion }: Props): React.ReactElement {
  return <Txt>{suggestion.name}</Txt>
}
