import ROUTE_PATHS from 'utils/routePaths'

export const ASSIGN_DATA = {
  header: 'Available Applications',
  route: ROUTE_PATHS.AVAILABLE_APPLICATIONS,
}
export const REASSIGN_DATA = {
  header: 'Assigned Applications',
  route: ROUTE_PATHS.ASSIGNED_APPLICATIONS,
}

export const COLUMNS = [
  {
    name: '',
    id: 'checkbox',
    collapse: true,
  },
  {
    name: 'Free Agent',
    id: 'freeAgent',
    hideOnAvailable: true,
  },
  {
    name: 'Job Title',
    id: 'jobTitle',
  },
  {
    name: 'Company',
    id: 'company',
  },
  {
    name: 'Due Date',
    id: 'due',
    collapse: true,
  },
  {
    name: 'Submission Date',
    id: 'submittedDate',
    hideOnAssigned: true,
  },
  {
    name: 'Assignment',
    id: 'assignment',
    collapse: true,
  },
]
