import React from 'react'
import { NumberCardContainer } from './ClientDashboardCard.styles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  value: number
  label: string
}

export default function ClientDashboardNumberCard({
  value,
  label,
}: Props): React.ReactElement {
  return (
    <NumberCardContainer>
      <Padding bottom={0.5}>
        <Txt bold size={20} color="numberDarkGray">
          {value}
        </Txt>
      </Padding>
      <Txt size={10} color="numberDarkGray">
        {label}
      </Txt>
    </NumberCardContainer>
  )
}
