import { useQuery } from '@apollo/client'
import React from 'react'
import { ReactSetState } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { GET_CLIENTS } from 'gql/queries'
import { sourceOptions } from './NewInterviewCycle.constants'
import { NewInterviewCycleFormBox } from './NewInterviewCycle.styles'
import { ClientQueryData, State } from './NewInterviewCycle.types'
import { DatePicker, Dropdown, RadioGroup, TextInput } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import RefreshMessage from 'components/RefreshMessage'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  state: State
  setState: ReactSetState<State>
}

export default function NewInterviewCycleForm({
  state,
  setState,
}: Props): React.ReactElement {
  const { data, loading } = useQuery<ClientQueryData>(GET_CLIENTS)
  const clientOptions = data?.clients && buildDropdownOptions(data.clients)

  if (loading) {
    return <Message vertical message="Loading Free Agent options..." />
  }

  if (!clientOptions) {
    return <RefreshMessage message="Could not fetch Free Agents." />
  }

  function handleStateChange(value: string, prop: string) {
    setState({ ...state, [prop]: value })
  }

  return (
    <NewInterviewCycleFormBox
      // prevent enter reloading the page
      onSubmit={(e) => {
        e.preventDefault()
      }}
    >
      <Padding bottom={4}>
        <Txt as="h2" size={24} bold>
          New Interview Cycle
        </Txt>
      </Padding>
      <VList size={4}>
        <Dropdown
          name="clientId"
          value={state.clientId}
          label="Free Agent"
          options={clientOptions}
          onValueChange={(value) => handleStateChange(value, 'clientId')}
          placeholder="Select a Free Agent"
          withEmptyOption={false}
        />
        <TextInput
          name="huntrUrl"
          label="Huntr URL"
          value={state.huntrUrl}
          onValueChange={(value) => handleStateChange(value, 'huntrUrl')}
          width={300}
          placeholder="https://huntr.co/boards/"
          type="url"
          autoComplete="off"
        />
        <RadioGroup
          name="source"
          label="Source"
          value={state.source}
          options={sourceOptions}
          onValueChange={(value) => handleStateChange(value, 'source')}
        />
        <DatePicker
          name="startDate"
          label="Start Date"
          value={state.startDate}
          onValueChange={(value) => handleStateChange(value, 'startDate')}
        />
      </VList>
    </NewInterviewCycleFormBox>
  )
}
