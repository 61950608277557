import dayjs from 'dayjs'
import React from 'react'
import { CSVLink } from 'react-csv'
import { DATE_FORMAT } from 'utils/constants'
import { DatetimeTzRangeFilter } from 'utils/helpers/getCreatedAtRangeFilterTz'
import Icon from 'components/Icon/Icon'
import { CSV_HEADERS } from './SourcingReview.constants'
import { SourcedJob } from './SourcingReview.types'

type Props = {
  sourcedJobs: SourcedJob[] | null | undefined
  filter: DatetimeTzRangeFilter
}

export default function SourcingReviewExport({
  filter,
  sourcedJobs,
}: Props): React.ReactElement {
  const fileName = `sourced-jobs-${dayjs(filter.start).format(
    DATE_FORMAT.ONLY_DATE
  )}_${dayjs(filter.end).format(DATE_FORMAT.ONLY_DATE)}.csv`

  const primaryFill = sourcedJobs ? 'text' : 'faGrey2'
  const primaryFillHover = sourcedJobs ? 'textDark' : undefined
  const exportIcon = (
    <Icon
      name="ExportAsCsv"
      width={32}
      height={32}
      primaryFill={primaryFill}
      primaryFillHover={primaryFillHover}
    />
  )

  return sourcedJobs ? (
    <CSVLink filename={fileName} data={sourcedJobs} headers={CSV_HEADERS}>
      {exportIcon}
    </CSVLink>
  ) : (
    exportIcon
  )
}
