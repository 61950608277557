import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import { EMPTY_INPUT, QS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { buildInterviewCycleOptions } from 'utils/helpers/buildInterviewCycleOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { GET_CLIENTS, GET_CLIENTS_INTERVIEW_CYCLES } from 'gql/queries'
import {
  COPY,
  ID,
  interviewPrepTypeOptions,
} from './AddAndEditInterviewPrepRequest.constants'
import { getDefaultState } from './AddAndEditInterviewPrepRequest.helpers'
import { State } from './AddAndEditInterviewPrepRequest.types'
import { DatePicker, Dropdown, RadioGroup, TextArea } from 'components/Inputs'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CreateInterviewPrepRequestMutation,
  CreateInterviewPrepRequestMutationVariables,
  GetClientsInterviewCyclesQuery,
  GetClientsInterviewCyclesQueryVariables,
  GetClientsQuery,
} from 'generated/graphql'

export default function AddAndEditInterviewPrepRequestForm(): React.ReactElement {
  // prefills
  const queryString = useQueryString()
  const clientIdParam = queryString.get(QS.CLIENT_ID)
  const interviewCycleIdParam = queryString.get(QS.INTERVIEW_CYCLE_ID)
  const defaultState = getDefaultState({
    clientId: clientIdParam,
    interviewCycleId: interviewCycleIdParam,
  })

  //state
  const [state, setState] = useState<State>(defaultState)
  const history = useHistory()

  // derived state
  const noClientId = state.clientId === EMPTY_INPUT

  // GQL queries
  const { data: clientData } = useQuery<GetClientsQuery>(GET_CLIENTS)
  const { data: interviewCycleData, refetch: refetchInterviewCycles } = useQuery<
    GetClientsInterviewCyclesQuery,
    GetClientsInterviewCyclesQueryVariables
  >(GET_CLIENTS_INTERVIEW_CYCLES, {
    variables: { clientId: state.clientId },
    skip: noClientId,
  })

  // GQL muations
  const [createInterviewPrepRequest] = useMutation<
    CreateInterviewPrepRequestMutation,
    CreateInterviewPrepRequestMutationVariables
  >(CREATE_INTERVIEW_PREP_REQUEST, {
    onCompleted() {
      toast.success(COPY.CREATE_SUCCESS)
      history.push(queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME)
    },
    onError() {
      toast.error(COPY.CREATE_ERROR)
    },
  })

  // normalizing data
  const clientOptions = buildDropdownOptions(clientData?.clients ?? [])
  const interviewCycleOptions = buildInterviewCycleOptions(
    interviewCycleData?.interviewCycles
  )

  // derived state
  const showLoadingMessage = noClientId || !interviewCycleData

  // functions
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    submitCreateInterviewPrepRequest()
  }

  function submitCreateInterviewPrepRequest() {
    if (!state.interviewPrepType) {
      toast.error(COPY.TYPE_REQURIED)
      return
    }

    const variables = {
      CreateInterviewPrepRequestInput: {
        ...state,
        due: state.due,
        interviewCycleId:
          state.interviewCycleId === EMPTY_INPUT
            ? undefined
            : state.interviewCycleId,
        interviewPrepType: state.interviewPrepType,
      },
    }

    void createInterviewPrepRequest({ variables })
  }

  /**
   * updates state with new client Id, resets interviewCycleId state, and fetches client's interview cycles
   * @param value input value
   */
  function handleClientIdValueChange(value: string) {
    const newState = { ...state, clientId: value, interviewCycleId: EMPTY_INPUT }
    setState(newState)

    void refetchInterviewCycles({ clientId: value })
  }

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<T = string>(value: T, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  return (
    <CenteredBox>
      <form id={ID.FORM} onSubmit={handleSubmit}>
        <Padding bottom={4}>
          <Txt size={24} bold as="h2">
            Interview Prep Request
          </Txt>
        </Padding>
        <VList size={4}>
          <Dropdown
            label="Free Agent *"
            options={clientOptions}
            value={state.clientId}
            onValueChange={handleClientIdValueChange}
            minWidth={100}
            placeholder="Select Free Agent"
            withEmptyOption={false}
            required
          />
          <RadioGroup
            label="Interview Cycle"
            name="interviewCycle"
            options={interviewCycleOptions}
            value={state.interviewCycleId}
            onValueChange={(value) => handleInputChange(value, 'interviewCycleId')}
            loadingOptions={showLoadingMessage}
            loadingMessage={
              'Please select a Free Agent to see their interview cycles'
            }
            canDeselect
          />
          <RadioGroup
            label="Interview Prep Type *"
            name="interviewPrepType"
            options={interviewPrepTypeOptions}
            value={state.interviewPrepType ?? EMPTY_INPUT}
            onValueChange={(value) => handleInputChange(value, 'interviewPrepType')}
            required
          />
          <DatePicker
            label="Due *"
            value={state.due}
            onValueChange={(value) => handleInputChange(value, 'due')}
            required
          />
          <TextArea
            label="Notes *"
            value={state.notes}
            description="TA concerns. What would you like to be evaluated/addressed?"
            onValueChange={(value) => handleInputChange(value, 'notes')}
            required
          />
        </VList>
      </form>
    </CenteredBox>
  )
}

const CREATE_INTERVIEW_PREP_REQUEST = gql`
  mutation CreateInterviewPrepRequest(
    $CreateInterviewPrepRequestInput: CreateInterviewPrepRequestInput!
  ) {
    createInterviewPrepRequest(input: $CreateInterviewPrepRequestInput) {
      interviewPrepRequest {
        id
      }
    }
  }
`
