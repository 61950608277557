import { State } from './DossierSubmission.types'

export const TOAST = {
  SUBMISSION_ERROR: 'There was an error submitting your dossier work',
}

export const DEFAULT_STATE: State = {
  fileUrl: '',
  timeSpent: '',
}

export const ID = {
  FORM: 'dossierSumbissionForm',
}
