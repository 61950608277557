import React from 'react'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

export default function ReportApplicationInfoSuccess(): React.ReactElement {
  return (
    <>
      <Txt size={18} bold alignment="center">
        Success!
      </Txt>
      <Padding top={2}>
        <Txt alignment="center">
          Thank you for completing the form, you may now close the window.
        </Txt>
      </Padding>
    </>
  )
}
