import React, { useState } from 'react'
import { EMPTY_INPUT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'
import DossierLibraryTable from './DossierLibraryTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { Maybe } from 'generated/graphql'

export default function DossierLibrary(): React.ReactElement {
  const [searchTerm, setSearchTerm] = useState<Maybe<string>>(null)
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            Dossier Library
          </Txt>
        </Padding>
        <Padding bottom={4}>
          <HList size={2} rowGapSize={2}>
            {/* TODO (matthewalbrecht): Debounce this */}
            <TextInput
              name="search"
              type="search"
              value={searchTerm || EMPTY_INPUT}
              placeholder="Search free agent, writer, company, title"
              onValueChange={(value) => setSearchTerm(value || null)}
              icon={<Icon name="Search" height={12} width={12} />}
              width={350}
            />
          </HList>
        </Padding>
      </Container>
      <DossierLibraryTable searchTerm={searchTerm} />
    </ViewBox>
  )
}

DossierLibrary.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.DOSSIER_LIBRARY}
    key={ROUTE_PATHS.DOSSIER_LIBRARY}
  >
    <DossierLibrary />
  </PrivateRoute>,
]
