import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DropdownOption, ReactChangeEvent, ReactFormEvent } from 'types'
import { Role } from 'utils/enums'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import checkRole from 'utils/helpers/checkRole'
import { Client } from 'views/ClientSettings/ClientSettings.types'
import { Input } from 'components/Inputs/Input'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import { TOAST } from './AutoSourcing.constants'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import Label from 'components/Label'
import Padding from 'components/Padding'
import { GetSourcersForAutoSourcingQuery } from 'generated/graphql'

type Props = {
  client: Client
  formId: string
}

function AutoSourcing({ client, formId }: Props): React.ReactElement {
  const showAssigneeDropdown = checkRole([Role.Coordinator])
  const [autoSourcingsWeekly, setAutoSourcingsWeekly] = useState<number>(
    client.autoSourcingsWeekly ?? 0
  )
  const [autoSourcingAssigneeId, setAutoSourcingAssigneeId] = useState<string>(
    client.autoSourcingAssignee?.id ?? ''
  )
  const { data } = useQuery<GetSourcersForAutoSourcingQuery>(GET_SOURCERS, {
    skip: !showAssigneeDropdown,
  })
  const sourcerOptions: DropdownOption[] = buildDropdownOptions(data?.sourcers || [])
  const [updateAutoSourcing] = useMutation(UPDATE_AUTO_SOURCING, {
    onCompleted() {
      toast.success(TOAST.UPDATE_SUCCESS)
    },
    onError() {
      toast.error(TOAST.UPDATE_ERROR)
    },
  })

  /**
   * Value must not be negative
   * @param event change event from input
   */
  function handleSourcingPerWeekChange(event: ReactChangeEvent) {
    const value = Number(event.target.value)
    if (value >= 0) {
      setAutoSourcingsWeekly(value)
    }
  }

  /**
   * no validation needed for this submit
   */
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()

    const variables = {
      ModifyClientAutoSourcingInput: {
        clientId: client.id,
        autoSourcingsWeekly,
        autoSourcingAssigneeId: autoSourcingAssigneeId || null,
      },
    }

    void updateAutoSourcing({ variables })
  }

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <Label htmlFor="autoSourcingsWeekly" content="Sourcing Per Week" />
      <HList size={0.5} rowGapSize={1}>
        <Input
          type="number"
          value={autoSourcingsWeekly}
          name="autoSourcingsWeekly"
          width={60}
          onChange={handleSourcingPerWeekChange}
        />
        {showAssigneeDropdown && (
          <Dropdown
            options={sourcerOptions}
            value={autoSourcingAssigneeId}
            onValueChange={(value) => setAutoSourcingAssigneeId(value)}
            placeholder="Select Assignee"
          />
        )}
      </HList>
    </form>
  )
}

AutoSourcing.fragments = {
  autoSourcingInfo: gql`
    fragment AutoSourcingInfo on Client {
      autoSourcingsWeekly
      autoSourcingAssignee {
        id
      }
    }
  `,
}

AutoSourcing.Skeleton = (
  <>
    <Padding bottom={1}>
      <RectangleSkeleton height={16} width={140} />
    </Padding>
    <RectangleSkeleton height={30} width={240} />
  </>
)

const GET_SOURCERS = gql`
  query GetSourcersForAutoSourcing {
    sourcers: users(anyRole: { isSourcer: true }) {
      id
      name
    }
  }
`

const UPDATE_AUTO_SOURCING = gql`
  mutation UpdateAutoSourcing(
    $ModifyClientAutoSourcingInput: ModifyClientAutoSourcingInput!
  ) {
    modifyClientAutoSourcing(input: $ModifyClientAutoSourcingInput) {
      client {
        id
        ...AutoSourcingInfo
      }
    }
  }
  ${AutoSourcing.fragments.autoSourcingInfo}
`

export default AutoSourcing
