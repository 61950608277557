import { DossierSubmission } from './DossierLibrary.types'
import { Maybe } from 'generated/graphql'

export function filterDossierSubmission(
  dossierSubmissions: DossierSubmission[],
  searchTerm: Maybe<string>
): DossierSubmission[] {
  if (!searchTerm) {
    return dossierSubmissions
  }

  const searchTermLower = searchTerm.toLowerCase()
  return dossierSubmissions.filter((submission) => {
    if (submission.employerName?.toLowerCase().includes(searchTermLower)) {
      return true
    }
    if (submission.jobTitle?.toLowerCase().includes(searchTermLower)) {
      return true
    }
    if (submission.client.name.toLowerCase().includes(searchTermLower)) {
      return true
    }
    if (submission.createdBy.name.toLowerCase().includes(searchTermLower)) {
      return true
    }
    return false
  })
}
