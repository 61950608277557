export enum TrialClientApplicationStatus {
  APPS_SENT = 'Applications sent',
  APPS_IN_PROGESS = 'Applications in progress',
  NO_APPS_SENT = 'No applications sent',
}

export enum StageChangeType {
  INTERVIEW_CYCLE_REJECTED = 'Cycle closed',
  NEW_INTERVIEW_CYCLE = 'New cycle',
  NEW_INTERVIEW = 'New interview',
}

export enum ClientActivityType {
  DEBRIEF_SUBMITTED = 'Debrief submitted',
}
