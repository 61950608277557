export enum ClipboardCopyValues {
  HTML = 'html',
  TEXT = 'text',
}
export const clipboardCopyOptions = [
  { value: 'html', label: 'HTML' },
  { value: 'text', label: 'Plain Text' },
]
export enum LocalPreferences {
  ClipboardCopyType = 'ClipboardCopyType',
}
export const TOAST = {
  CALENDAR_ERROR: 'There was an error publishing the calendar',
}
