import { Dayjs } from 'dayjs'
import { DATE_FORMAT } from 'utils/constants'
import { DateRangeFilter } from 'generated/graphql'

export type DueDateRangeFilter = {
  start: Dayjs
  end: Dayjs
}

export default function getDueDateRangeFilter({
  start,
  end,
}: DueDateRangeFilter): DateRangeFilter {
  return {
    start: start.format(DATE_FORMAT.ONLY_DATE),
    end: end.format(DATE_FORMAT.ONLY_DATE),
  }
}
