import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { KeyValue, ReactFormEvent } from 'types'
import { EMPTY_INPUT } from 'utils/constants'
import { CheckboxGroup } from 'components/Inputs/CheckboxGroup'
import { ERROR_CODE, ID, roleOptions, TexFragment, TOAST } from './Users.constants'
import {
  addNewUserToCache,
  getCreateUserVariables,
  getDefaultState,
  getModifyUserVariables,
} from './Users.helpers'
import { State, User } from './Users.types'
import Button from 'components/Button'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import Flex from 'components/Flex'
import { TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CreateTexUserMutation,
  CreateTexUserMutationVariables,
  ModifyTexUserMutation,
  ModifyTexUserMutationVariables,
} from 'generated/graphql'

type Props = {
  handleClose(): void
  user?: User
  isOpen: boolean
}

export default function AddAndEdituser({
  handleClose,
  user,
  isOpen,
}: Props): React.ReactElement {
  const [state, setState] = useState<State>(getDefaultState(user))
  const isEditing = Boolean(user)
  const title = isEditing ? 'Edit User' : 'Add User'

  const [createTexUser] = useMutation<
    CreateTexUserMutation,
    CreateTexUserMutationVariables
  >(CREATE_TEX_USER, {
    onCompleted(data) {
      toast.success(TOAST.createSuccess(data.createTexUser.user.name))
      setState(getDefaultState(user))
      handleClose()
    },
    onError() {
      toast.error(TOAST.CREATE_ERROR)
    },
    update: addNewUserToCache,
  })

  const [modifyTexUser] = useMutation<
    ModifyTexUserMutation,
    ModifyTexUserMutationVariables
  >(MODIFY_TEX_USER, {
    onCompleted(data) {
      if (data.modifyTexUser.errorCode === ERROR_CODE.PERMISSION_DENIED) {
        toast.error(TOAST.CREATE_PERMISSION_DENIED)
      } else if (data.modifyTexUser.errorCode) {
        toast.error(TOAST.MODIFY_ERROR)
      } else {
        toast.success(TOAST.modifySuccess(data.modifyTexUser.user.name))
      }
      setState(getDefaultState(user))
      handleClose()
    },
    onError() {
      toast.error(TOAST.MODIFY_ERROR)
    },
  })

  function handleInputChange<T>(value: T, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    if (isEditing) {
      modifyUser()
    } else {
      createUser()
    }
  }

  function createUser() {
    void createTexUser({
      variables: getCreateUserVariables(state),
    })
  }

  function modifyUser() {
    void modifyTexUser({
      // if we hit modifyUser then we are user exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      variables: getModifyUserVariables(state, user!.id),
    })
  }

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={handleClose}
      aria-label={`${isEditing ? 'Edit' : 'Add'} user form`}
      widthVW={[90, { sm: 80, md: 55, lg: 45, xl: 35 }]}
    >
      <Padding vertical={4} horizontal={4}>
        <DialogExitButton onClick={handleClose} />
        <Txt as="h2" bold size={24}>
          {title}
        </Txt>
        <Padding top={4}>
          <form id={ID.FORM} onSubmit={handleSubmit}>
            <VList size={4}>
              <TextInput
                fullWidth
                label="Name *"
                name="name"
                value={state.name || EMPTY_INPUT}
                onValueChange={(value) => handleInputChange<string>(value, 'name')}
                disabled={isEditing}
                required
              />
              <TextInput
                fullWidth
                label="Email *"
                name="email"
                value={state.email || EMPTY_INPUT}
                onValueChange={(value) => handleInputChange<string>(value, 'email')}
                disabled={isEditing}
                required
              />
              <CheckboxGroup
                label="Roles"
                name="roles"
                options={roleOptions}
                value={state.roles || {}}
                onValueChange={(value) =>
                  handleInputChange<KeyValue<boolean>>(value, 'roles')
                }
              />
              {/* TODO (matthewalbrecht): create and use a checkbox component  */}
              <CheckboxGroup
                label="Active Status"
                name="activeStatus"
                options={[{ label: 'Active', value: 'isActive' }]}
                value={{ isActive: Boolean(state.isActive) }}
                onValueChange={(value) =>
                  handleInputChange<boolean>(Boolean(value.isActive), 'isActive')
                }
              />
            </VList>
            <Padding top={6}>
              <Flex justify="flex-end">
                <Button type="submit" $type="accept">
                  {isEditing ? 'Save' : 'Submit'}
                </Button>
              </Flex>
            </Padding>
          </form>
        </Padding>
      </Padding>
    </Dialog>
  )
}

const CREATE_TEX_USER = gql`
  mutation CreateTexUser($CreateTexUserInput: CreateTexUserInput!) {
    createTexUser(input: $CreateTexUserInput) {
      user {
        ...TexInfo
      }
    }
  }
  ${TexFragment}
`

const MODIFY_TEX_USER = gql`
  mutation ModifyTexUser($ModifyTexUserInput: ModifyTexUserInput!) {
    modifyTexUser(input: $ModifyTexUserInput) {
      errorCode
      user {
        ...TexInfo
      }
    }
  }
  ${TexFragment}
`
