import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { getWorkStats } from './ClientConfig.helpers'
import ClientConfigStatBox from './ClientConfigStatBox'
import HList from 'components/HList'
import { GetBasicClientWorkInfoQuery } from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientConfigWorkData({
  clientId,
}: Props): React.ReactElement {
  const { data: workData } = useQuery<GetBasicClientWorkInfoQuery>(GET_CLIENT_WORK, {
    variables: { id: clientId },
  })
  const workStats = workData && getWorkStats(workData)

  return (
    <div>
      <HList rowGapSize={1} size={1}>
        <ClientConfigStatBox
          header="Sourcing"
          subHeader="For TA review"
          value={workStats && `${workStats.numPendingSourcedJobsReview}`}
        />
        <ClientConfigStatBox
          header="Taglines"
          subHeader="Requested/IR/FR"
          value={
            workStats &&
            `${workStats.numPendingWritingTaglines} / ${workStats.numPendingIrReviewTaglines} / ${workStats.numPendingFrReviewTaglines}`
          }
        />
        <ClientConfigStatBox
          header="Applications"
          subHeader="Pending"
          value={workStats && `${workStats.numPendingApplications}`}
        />
      </HList>
    </div>
  )
}

const GET_CLIENT_WORK = gql`
  query GetBasicClientWorkInfo($id: ID!) {
    client(id: $id) {
      id
      unassigned: taglineRequests(status: UNASSIGNED) {
        totalCount
      }
      pendingWriting: taglineRequests(status: PENDING_WRITING) {
        totalCount
      }
      pendingAgentReview: taglineRequests(status: PENDING_TA_REVIEW) {
        totalCount
      }
      pendingPartnerReview: taglineRequests(status: PENDING_TP_REVIEW) {
        totalCount
      }
      pendingSourcings: sourcingRequests(isOpen: true) {
        edges {
          node {
            id
            numJobs
            sourcedJobs {
              id
            }
          }
        }
      }
      pendingSourcedJobReviews: sourcedJobs(isReviewed: false, isRedacted: false) {
        totalCount
      }
    }
    unnasignedApplications: applications(
      clientId: $id
      inAirtable: false
      status: UNASSIGNED
    ) {
      totalCount
    }
    pendingApplications: applications(
      clientId: $id
      inAirtable: false
      status: PENDING_APPLICATION
    ) {
      totalCount
    }
  }
`
