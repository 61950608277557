import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DATE_FORMAT } from 'utils/constants'
import getDueDateRangeFilter, {
  DueDateRangeFilter,
} from 'utils/helpers/getDueDateRangeFilter'
import ROUTE_PATHS from 'utils/routePaths'
import { SOURCING_REVIEW_MAX_DAYS } from 'utils/settings'
import { TOAST } from './SourcerReview.constants'
import { getGroupedSourcerData } from './SourcerReview.helpers'
import SourcerReviewTable from './SourcerReviewTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { DatePicker } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  SourcerReviewDataQuery,
  SourcerReviewDataQueryVariables,
} from 'generated/graphql'

export default function SourcerReview(): React.ReactElement {
  // state
  const [filter, setFilter] = useState<DueDateRangeFilter>({
    start: dayjs(),
    end: dayjs(),
  })

  // query
  const { data, loading, error, refetch } = useQuery<
    SourcerReviewDataQuery,
    SourcerReviewDataQueryVariables
  >(SOURCER_REVIEW_DATA, {
    variables: {
      dueDateRangeFilter: getDueDateRangeFilter(filter),
    },
  })

  // derived state
  const tableData =
    (data?.sourcingRequests && getGroupedSourcerData(data.sourcingRequests)) ?? []

  // functions
  /**
   *  sets the new filter
   * @param value value of DatePicker change
   */
  function handleDateFilterChange(value: string, prop: keyof DueDateRangeFilter) {
    if (!value) {
      return
    }
    let newFilter = { ...filter, [prop]: dayjs(value) }
    const dayDiff = newFilter.end.diff(newFilter.start, 'days')

    if (dayDiff > SOURCING_REVIEW_MAX_DAYS || dayDiff < 0) {
      if (prop === 'start') {
        toast(TOAST.UPDATE_END)
        newFilter = {
          ...newFilter,
          end: newFilter.start.add(SOURCING_REVIEW_MAX_DAYS, 'days'),
        }
      }
      if (prop === 'end') {
        toast(TOAST.UPDATE_START)
        newFilter = {
          ...newFilter,
          start: newFilter.end.subtract(SOURCING_REVIEW_MAX_DAYS, 'days'),
        }
      }
    }
    setFilter(newFilter)
    void refetch({
      dueDateRangeFilter: getDueDateRangeFilter(filter),
    })
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Txt as="h2" size={24} bold>
            Sourcer Allocation
          </Txt>
        </Padding>
        <Padding vertical={4}>
          <HList size={2} rowGapSize={2}>
            <DatePicker
              label="Start Date"
              value={filter.start.format(DATE_FORMAT.ONLY_DATE)}
              onValueChange={(value) => handleDateFilterChange(value, 'start')}
            />
            <DatePicker
              label="End Date"
              value={filter.end.format(DATE_FORMAT.ONLY_DATE)}
              onValueChange={(value) => handleDateFilterChange(value, 'end')}
            />
          </HList>
        </Padding>
      </Container>
      <SourcerReviewTable
        tableData={tableData}
        isEmpty={!loading && !tableData.length}
        isLoading={loading}
        isError={Boolean(error)}
      />
    </ViewBox>
  )
}

SourcerReview.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.SOURCER_REVIEW}
    key={ROUTE_PATHS.SOURCER_REVIEW}
  >
    <SourcerReview />
  </PrivateRoute>,
]

const SOURCER_REVIEW_DATA = gql`
  query SourcerReviewData($dueDateRangeFilter: DateRangeFilter!) {
    sourcingRequests(dueDateRangeFilter: $dueDateRangeFilter, isAssigned: true) {
      edges {
        node {
          ...SourcerReviewTableData
        }
      }
    }
  }
  ${getGroupedSourcerData.fragments.tableData}
`
