import { gql, useQuery } from '@apollo/client'
import { Dayjs } from 'dayjs'
import React from 'react'
import { useAuthContext } from 'context/auth'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { HEADERS } from './SourcerWorkStats.constants'
import { getRowData } from './SourcerWorkStats.helpers'
import SourcerWorkStatsRow from './SourcerWorkStatsRow'
import {
  GetSourcerWorkStatsQuery,
  GetSourcerWorkStatsQueryVariables,
  Maybe,
} from 'generated/graphql'

type Props = {
  dateSince: Maybe<Dayjs>
}

export default function SourcerWorkStatsTable({
  dateSince,
}: Props): React.ReactElement {
  // state
  const {
    userSession: {
      user: { id: createdById },
    },
  } = useAuthContext()

  // queries
  const { data, loading, error } = useQuery<
    GetSourcerWorkStatsQuery,
    GetSourcerWorkStatsQueryVariables
  >(GET_SOURCER_WORK_STATS, {
    variables: {
      createdById,
    },
  })

  const rowData = data?.sourcedJobs && getRowData(data.sourcedJobs, dateSince)

  return (
    <FullWidthTable
      headers={Object.values(HEADERS)}
      isEmpty={!loading && !data?.sourcedJobs?.edges.length}
      isLoading={loading}
      isError={Boolean(error)}
      errorMessage="There was an error fetching your sourcing stats."
      loadingMessage="Loading sourcing stats..."
      emptyMessage="No sourcing stats found."
    >
      {rowData && <SourcerWorkStatsRow rowData={rowData} />}
    </FullWidthTable>
  )
}

const GET_SOURCER_WORK_STATS = gql`
  query GetSourcerWorkStats($createdById: ID!) {
    sourcedJobs(isRedacted: false, createdById: $createdById) {
      edges {
        node {
          id
          createdAt
          reviews {
            id
            rating
            rejectionReason
          }
        }
      }
    }
  }
`
