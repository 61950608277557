import { gql, useQuery } from '@apollo/client'
import React from 'react'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { HEADERS } from './ExpertNetwork.constants'
import { buildTableData } from './ExpertNetwork.helpers'
import ExpertNetworkRow from './ExpertNetworkRow'
import { GetExpertsQuery, Maybe } from 'generated/graphql'

type Props = {
  searchTerm: Maybe<string>
}

export default function ExpertNetworkTable({
  searchTerm,
}: Props): React.ReactElement {
  const { data, loading, error } = useQuery<GetExpertsQuery>(GET_EXPERTS)

  const tableData = data?.experts
    ? buildTableData(data.experts, searchTerm)
    : undefined

  return (
    <FullWidthTable
      headers={HEADERS}
      isLoading={loading}
      isError={Boolean(error)}
      isEmpty={!loading && tableData != null && !tableData.length}
      emptyMessage="Could not find any experts."
      loadingMessage="Loading experts..."
      errorMessage="There was an error fetching experts."
    >
      {tableData?.map((expert) => (
        <ExpertNetworkRow key={expert.id} expert={expert} />
      ))}
    </FullWidthTable>
  )
}

const GET_EXPERTS = gql`
  query GetExperts {
    experts {
      edges {
        node {
          ...ExpertNetworkRowInfo
        }
      }
    }
  }
  ${ExpertNetworkRow.fragments.ExpertNetworkRowInfo}
`
