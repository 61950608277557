import { KeyValue } from 'types'

export type Client = {
  id: string
  name: string
  roleCategory: string
  roleExperience: string
}

export type CheckedItems = KeyValue<boolean>

export enum InputTypes {
  Assignee = 'assigneeId',
  Due = 'due',
}

export type NavOption = { header: string; route: string }

export type ColumnItem = {
  name: string
  id: string
  collapse?: boolean
  hideOnAvailable?: boolean
  hideOnAssigned?: boolean
}
