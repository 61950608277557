import dayjs from 'dayjs'
import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  SortableSourcedJob,
  SourcedJobConnection,
  TableRowData,
} from './ReviewSourcedJobs.types'

/**
 * Sorts the jobs by createdAt date with most recent first
 * Groups them by Free agent and returns
 * @param connection SourcedJob Connection object
 */
export function groupJobs(
  connection: SourcedJobConnection
): TableRowData[] | undefined | null {
  const rawSourcedJobs = getNodes(connection)
  const sortedSourcedJobs: SortableSourcedJob[] = rawSourcedJobs
    .map((job) => ({
      ...job,
      dateToSortBy: dayjs(job.createdAt),
    }))
    .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))

  const groupedJobs = sortedSourcedJobs.reduce(
    (finalObject: KeyValue<TableRowData>, job) => {
      const groupId = job.sourcingRequest.client.id
      const group = finalObject[groupId]
      if (group) {
        group.sourcedJobs.push(job)
      } else {
        finalObject[groupId] = {
          clientId: groupId,
          clientName: job.sourcingRequest.client.name,
          talentAgentId: job.sourcingRequest.client.talentAgent.id,
          sourcedJobs: [job],
        }
      }
      return finalObject
    },
    {}
  )

  const tableData = Object.values(groupedJobs).sort((a, b) =>
    sortByProp(a, b, 'clientName')
  )
  return tableData
}
