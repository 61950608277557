import dayjs from 'dayjs'
import React from 'react'
import { useLocation } from 'react-router'
import { DropdownOption } from 'types'
import { DATE_FORMAT } from 'utils/constants'
import { GenericAssignItem } from 'utils/helpers/groupAssigItems'
import {
  BodyData,
  BodyRow,
  CheckboxLabel,
  EditLink,
} from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { useChanges } from './Assign.context'
import { InputTypes } from './Assign.types'
import { DatePicker, Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  item: GenericAssignItem
  handleItemCheck: (event: React.FormEvent<HTMLInputElement>) => void
  checked: boolean
  isReassigning: boolean
  assigneeOptions: DropdownOption[]
  handleInputChange: (value: string, itemId: string, type: InputTypes) => void
  getEditPath?: (id: string, pathname: string) => string
  DeleteButtonComponent?: React.ElementType
  OverrideColumns?: React.ElementType
  DueDatePicker?: React.ElementType
  groupId: string
  // undefined format will result in ISO8601 timestamp w/o fraction seconds
  dueDateFormat: string | undefined
  hideDatePicker?: boolean
  isFinalReview?: boolean
}

export default function AssignRow({
  item,
  handleItemCheck,
  checked,
  isReassigning,
  assigneeOptions,
  handleInputChange,
  OverrideColumns,
  getEditPath,
  DeleteButtonComponent,
  DueDatePicker,
  dueDateFormat,
  groupId,
  hideDatePicker,
  isFinalReview,
}: Props): JSX.Element {
  const { pathname } = useLocation()
  const { state } = useChanges()

  /**
   * we return originalValue if stateValue is undefined, empty string if it is null otherwise we use stateValue
   *
   * @param stateValue current updated value in state
   * @param originalValue original value before any updates
   */
  function determineInputValue(
    stateValue: string | null | undefined,
    originalValue: string
  ) {
    const newStateValue = stateValue === null ? '' : stateValue
    return newStateValue ?? originalValue
  }

  const dueStateValue = state.updates[item.id]?.due
  const dueInitial = dayjs(item.due).format(dueDateFormat)
  const assigneeStateValue = state.updates[item.id]?.assigneeId
  const assigneeOriginal =
    (isFinalReview ? item.finalReviewer?.id : item.assignee?.id) ?? ''

  const date = determineInputValue(dueStateValue, dueInitial)
  const assignee = determineInputValue(assigneeStateValue, assigneeOriginal)

  return (
    <BodyRow
      aria-label="assign row"
      // added for testing because theres no connection between non-highlighted rows and the highlighted rows (in the DOM structure their siblings - not nested).
      data-groupid={groupId}
    >
      <BodyData collapse>
        <CheckboxLabel htmlFor={item.id}>
          <input
            id={item.id}
            type="checkbox"
            value={item.id}
            onChange={handleItemCheck}
            checked={checked}
            aria-label="assign row checkbox"
          />
        </CheckboxLabel>
      </BodyData>
      {isReassigning && (
        <BodyData>
          <Txt size={14}>{item.clientName}</Txt>
        </BodyData>
      )}
      {/* TODO: (matthewalbrecht) replace this solve for difference in columns between types of assigning. 
       New solve should give complete control of columns to child component (which lives in /Assign[Type] directories) while a generic parent component handles all interaction w/ AssignContext in this directory*/}
      {OverrideColumns ? (
        <OverrideColumns item={item} />
      ) : (
        <>
          <BodyData>
            <Txt size={14}>{item.jobTitle}</Txt>
          </BodyData>
          <BodyData>
            <Txt size={14}>{item.employerName}</Txt>
          </BodyData>
        </>
      )}
      {!hideDatePicker && (
        <BodyData collapse>
          {DueDatePicker ? (
            <DueDatePicker
              onInputChange={handleInputChange}
              date={date}
              id={item.id}
              ariaLabel="assign row date picker"
            />
          ) : (
            <DatePicker
              value={date}
              onValueChange={(value) =>
                handleInputChange(value, item.id, InputTypes.Due)
              }
              ariaLabel="assign row date picker"
            />
          )}
        </BodyData>
      )}
      {!isReassigning && (
        <BodyData collapse>
          <Txt size={14} lineHeight={1.3}>
            {dayjs(item.createdAt).format(DATE_FORMAT.DATE_AND_TIME)}
          </Txt>
        </BodyData>
      )}
      <BodyData>
        <Dropdown
          fullWidth
          value={assignee}
          options={assigneeOptions}
          onValueChange={(value) =>
            handleInputChange(value, item.id, InputTypes.Assignee)
          }
          ariaLabel="assignee dropdown"
        />
      </BodyData>
      {getEditPath && (
        <BodyData collapse>
          <EditLink to={getEditPath(item.id, pathname)}>
            <Padding left={1}>
              <Icon name="Pen" width={16} height={16} primaryFill="faGrey3" />
            </Padding>
          </EditLink>
        </BodyData>
      )}
      {DeleteButtonComponent && (
        <BodyData collapse centered>
          <DeleteButtonComponent itemId={item.id} />
        </BodyData>
      )}
    </BodyRow>
  )
}
