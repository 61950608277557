import {
  ApolloClient,
  InMemoryCache,
  createHttpLink,
  InMemoryCacheConfig,
  ApolloLink,
  NormalizedCacheObject,
} from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { SentryLink } from 'apollo-link-sentry'
import { History } from 'history'
import { UserSession } from 'types'
import ROUTE_PATHS from 'utils/routePaths'
import resolvers from './mockResolvers'
import typeDefs from './typeDefs'
import wrappedRelayStylePagination from './wrappedRelayStylePagination'

const NO_AUTH_HEADER_REQUESTS = ['RequestMagicLink', 'LoginWithMagicLink']

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_API_URL,
})

const getAuthLink = (userSession: UserSession): ApolloLink => {
  const authLink = setContext(({ operationName = '' }, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = userSession.accessToken

    const extraHeaders: { authorization?: string } = {}
    if (token && !NO_AUTH_HEADER_REQUESTS.includes(operationName)) {
      extraHeaders.authorization = `Bearer ${token}`
    }

    // return the headers to the context so httpLink can read them
    return {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      headers: {
        ...headers,
        ...extraHeaders,
      },
    }
  })
  return authLink
}

const getErrorLink = (history: History) => {
  const errorLink = onError(({ operation }) => {
    const { response } = operation.getContext()

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (response?.status === 401) {
      history.push(ROUTE_PATHS.LOGOUT)
    }
  })
  return errorLink
}

const cacheOptions: InMemoryCacheConfig = {
  typePolicies: {
    User: {
      fields: {
        activityLogs: wrappedRelayStylePagination(),
        pastRelevantWorkConn: wrappedRelayStylePagination(['isEdited', 'userRole']),
      },
    },
    Client: {
      fields: {
        activityLogs: wrappedRelayStylePagination(),
      },
    },
    Query: {
      fields: {
        taglineRequests: {
          /**
           * Array merging required a custom merge function:
           * https://spectrum.chat/apollo/react-apollo/client-cache-update-doesnt-work-as-expected-cache-data-may-be-lost~f05688b3-4647-4acc-b219-1910bcc54786
           * https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-arrays
           */
          merge: false,
        },
        applications: wrappedRelayStylePagination([
          'clientId',
          'assigneeId',
          'status',
        ]),
        companies: wrappedRelayStylePagination(['search']),
        interviewCycles: wrappedRelayStylePagination(['clientId', 'active']),
        dossierSubmissions: wrappedRelayStylePagination([
          'isReviewed',
          'isRejected',
          'talentAgentId',
          'clientId',
          'interviewCycleId',
        ]),
      },
    },
  },
}

const sentryLink = new SentryLink()

const cache = new InMemoryCache(cacheOptions)

const getClient = (
  userSession: UserSession,
  history: History
): ApolloClient<NormalizedCacheObject> => {
  const client = new ApolloClient({
    cache,
    defaultOptions: {
      // watchQuery for useQuery hooks
      watchQuery: {
        // all to pass through any GQL errors to the components and let them handle
        errorPolicy: 'all',
      },
    },
    link: ApolloLink.from([
      sentryLink,
      getErrorLink(history),
      getAuthLink(userSession),
      httpLink,
    ]),
    typeDefs,
    resolvers,
  })
  return client
}

export default getClient
