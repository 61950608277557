import { gql, useQuery } from '@apollo/client'
import React, { useCallback, useMemo, useState } from 'react'
import AutoSuggest, {
  SuggestionsFetchRequestedParams,
  InputProps,
  SuggestionSelectedEventData,
} from 'react-autosuggest'
import debounce from 'utils/helpers/debounce'
import { sortByProp } from 'utils/sort'
import { AutoSuggestStyles } from './UserSuggestion.styles'
import {
  DebounceFunctionType,
  UserSuggestion as UserSuggestionType,
} from './UserSuggestion.types'
import Label from 'components/Label'
import Txt from 'components/Txt'
import {
  GetUserSuggestionOptionsQuery,
  GetUserSuggestionOptionsQueryVariables,
  RoleFilter,
} from 'generated/graphql'

type Props = {
  value: string
  onValueChange: (value: string) => void
  label?: string
  onSelectionValueChange(value: UserSuggestionType | null): void
  roleFilter?: RoleFilter
  placeholder?: string
}

const DEBOUNCE_TIME_MS = 500

export default function UserSuggestion({
  value,
  onValueChange,
  onSelectionValueChange,
  label,
  roleFilter,
  placeholder,
}: Props): React.ReactElement {
  const [suggestions, setSuggestions] = useState<UserSuggestionType[]>([])
  const { data } = useQuery<
    GetUserSuggestionOptionsQuery,
    GetUserSuggestionOptionsQueryVariables
  >(GET_USER_SUGGESTIONS_OPTIONS, {
    variables: {
      anyRole: roleFilter,
    },
  })
  const users = useMemo(
    () =>
      data?.users ? [...data.users].sort((a, b) => sortByProp(a, b, 'name')) : [],
    [data?.users]
  )
  /**
   * filters users based on search input
   */
  const onSuggestionsFetchRequested = useCallback(
    ({ value }: SuggestionsFetchRequestedParams) => {
      const lowerValue = value.toLowerCase()
      const filteredUsers = users.filter((user) =>
        user.name.toLowerCase().includes(lowerValue)
      )

      setSuggestions(filteredUsers)
    },
    [setSuggestions, users]
  )

  /**
   * debounced onSuggestionsFetchRequested function
   */
  /* TODO (matthewalbrecht): rewrite this to pass linting */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce<DebounceFunctionType>(onSuggestionsFetchRequested, DEBOUNCE_TIME_MS),
    [onSuggestionsFetchRequested]
  )

  /**
   * clear suggestions
   */
  function onSuggestionsClearRequested() {
    setSuggestions([])
  }

  /**
   *
   */
  function handleSuggestionSelected(
    e: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<UserSuggestionType>
  ) {
    onSelectionValueChange(suggestion)
  }

  const allInputProps: InputProps<UserSuggestionType> = {
    value,
    onChange(_, { newValue }) {
      onValueChange(newValue)

      if (!newValue) {
        onSelectionValueChange(null)
      }
    },
    type: 'search',
    placeholder: placeholder ?? 'Enter user',
  }

  // render individual element in suggestion list
  const renderSuggestion = (suggestion: UserSuggestionType) => (
    <UserSuggestionItem suggestion={suggestion} />
  )

  return (
    <AutoSuggestStyles>
      {label && <Label content={label} />}
      <AutoSuggest<UserSuggestionType>
        suggestions={suggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionsFetchRequested={debouncedFetch}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        inputProps={allInputProps}
        onSuggestionSelected={handleSuggestionSelected}
        shouldRenderSuggestions={() => true}
      />
    </AutoSuggestStyles>
  )
}

type ItemProps = {
  suggestion: UserSuggestionType
}

export function UserSuggestionItem({ suggestion }: ItemProps): React.ReactElement {
  return <Txt>{suggestion.name}</Txt>
}

const GET_USER_SUGGESTIONS_OPTIONS = gql`
  query GetUserSuggestionOptions($anyRole: RoleFilter) {
    users(anyRole: $anyRole) {
      id
      name
    }
  }
`

function getSuggestionValue(suggestion: UserSuggestionType): string {
  return suggestion.name
}
