import styled, { css } from 'styled-components/macro'
import { Colors } from 'types'
import { showScrollbar } from 'components/MiscStyles'
import Txt from 'components/Txt'

type TxtProps = {
  display?: 'inline' | 'block'
}

export const StyledTxt = styled(Txt)<TxtProps>`
  ${({ display = 'block' }) =>
    css`
      display: ${display};
    `};
  &:hover {
    text-decoration: underline ${({ theme }) => theme.color.text};
    color: ${({ theme }) => theme.color.black};
  }
`

export const DashboardCard = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.toRems(10)};
  border: 1px solid ${({ theme }) => theme.color.lightGray};
`

export const DashboardContent = styled.div`
  position: relative;
  border: 0;
  padding: 0;
  resize: none;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.toRems(266)};
  max-height: ${({ theme }) => theme.toRems(266)};
  overflow-y: scroll;
  ${showScrollbar}
`

type LogoContainerProps = {
  color?: keyof Colors
}

export const LogoContainer = styled.div<LogoContainerProps>`
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.toRems(30)};
  width: ${({ theme }) => theme.toRems(30)};
  background-color: ${({ color = 'faGrey2', theme }) => theme.color[color]};
  left: ${({ theme }) => theme.rhythm(3)};
  border-radius: ${({ theme }) => theme.toRems(4)};
  z-index: 1;
`

export const StageChangeDashboardContent = styled.div`
  position: relative;
  border: 0;
  padding: 0;
  resize: none;
  width: 100%;
  background-color: ${({ theme }) => theme.color.white};
  height: ${({ theme }) => theme.toRems(641)};
  max-height: ${({ theme }) => theme.toRems(641)};
  overflow-y: scroll;
  ${showScrollbar}
`

export const NameTextContainer = styled.div`
  display: inline-block;
`

export const DashboardContentRow = styled.div`
  & :first-child {
    padding-top: ${({ theme }) => theme.rhythm(2.5)};
  }
  & :last-child {
    padding-bottom: ${({ theme }) => theme.rhythm(2.5)};
  }
  padding: ${({ theme }) => theme.rhythm(1.25)} ${({ theme }) => theme.rhythm(3)};
`

export const StageChangeDashboardContentRow = styled.div`
  & :first-child {
    padding-top: ${({ theme }) => theme.rhythm(2.5)};
  }
  & :last-child {
    padding-bottom: ${({ theme }) => theme.rhythm(2.5)};
  }

  position: relative;
  padding-top: ${({ theme }) => theme.rhythm(2.5)};
  padding-bottom: ${({ theme }) => theme.rhythm(2.5)};
  padding-left: ${({ theme }) => theme.rhythm(10)};
  padding-right: ${({ theme }) => theme.rhythm(3)};
`

export const ActivityDashboardContentRow = styled.div`
  & :first-child {
    padding-top: ${({ theme }) => theme.rhythm(2.5)};
  }
  & :last-child {
    padding-bottom: ${({ theme }) => theme.rhythm(2.5)};
  }
  padding: ${({ theme }) => theme.rhythm(2.5)} ${({ theme }) => theme.rhythm(3)};
`

type InterviewCardBackgroundProps = {
  isInterviewOver?: boolean
}

export const InterviewCardBackground = styled.div<InterviewCardBackgroundProps>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${({ isInterviewOver }) =>
    isInterviewOver &&
    css`
      opacity: 0.5;
    `};
`

export const ClientCompanyContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-around;
  position: relative;
  padding-right: ${({ theme }) => theme.rhythm(2)};
`
export const InterviewTimeContainer = styled.div`
  background-color: ${({ theme }) => theme.color.lightBlue};
  padding: ${({ theme }) => theme.rhythm(1)} ${({ theme }) => theme.rhythm(1.5)};
  border-radius: ${({ theme }) => theme.rhythm(0.5)};
  margin-left: ${({ theme }) => theme.rhythm(2)};
`
export const ClientCompanyLogoContainer = styled.div`
  position: absolute;
  bottom: ${({ theme }) => theme.rhythm(-1)};
  right: ${({ theme }) => theme.rhythm(1)};
`

export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  row-gap: ${({ theme }) => theme.rhythm(5)};
  column-gap: ${({ theme }) => theme.rhythm(5)};
  grid-template-areas:
    'grid-area-1 grid-area-1 grid-area-4 grid-area-4'
    'grid-area-2 grid-area-2 grid-area-5 grid-area-5'
    'grid-area-3 grid-area-3 grid-area-5 grid-area-5'
    'grid-area-6 grid-area-6 grid-area-7 grid-area-7';
`

export const GridArea1 = styled.div`
  grid-area: grid-area-1;
`
export const GridArea2 = styled.div`
  grid-area: grid-area-2;
`
export const GridArea3 = styled.div`
  grid-area: grid-area-3;
`
export const GridArea4 = styled.div`
  grid-area: grid-area-4;
`
export const GridArea5 = styled.div`
  grid-area: grid-area-5;
`
export const GridArea6 = styled.div`
  grid-area: grid-area-6;
`
export const GridArea7 = styled.div`
  grid-area: grid-area-7;
`

export const StageChangeLine = styled.div`
  position: absolute;
  width: ${({ theme }) => theme.toRems(1)};
  background-color: ${({ theme }) => theme.color.lightGray};
  height: 100%;
  left: ${({ theme }) => theme.toRems(39)};
`
export const IconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${({ theme }) => theme.toRems(30)};
  width: ${({ theme }) => theme.toRems(30)};
  background-color: ${({ theme }) => theme.color.iconLightGray};
  border-radius: ${({ theme }) => theme.toRems(4)};
`
