import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useMemo } from 'react'
import { generatePath, Redirect, useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import useTalentAgentQueue from 'hooks/useTalentAgentQueue'
import useTimeTracking from 'hooks/useTimeTracking'
import { GLOBAL_COPY, INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import useFormState from 'views/ClientTracker/useFormState'
import InitialReviewFooter from 'views/InitialReview/InitialReviewFooter'
import InitialReviewLeft from 'views/InitialReview/InitialReviewLeft'
import InitialReviewRight from 'views/InitialReview/InitialReviewRight'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import { FormState, Params } from './InitialReview.types'
import { DEFAULT_FORM_STATE } from './initialReview.constants'
import Message from 'components/Message'
import PrivateRoute from 'components/PrivateRoute'
import {
  TaglineRequestAgentReviewQuery,
  TaglineRequestAgentReviewQueryVariables,
} from 'generated/graphql'

export default function InitialReview(): JSX.Element {
  const history = useHistory()
  const { requestId, freeAgentId, talentAgentId, podId } = useParams<Params>()
  const redirectToInitial = requestId === INITIAL
  const { queueByViewer, queueByClient, queueByPod, loading } = useTalentAgentQueue(
    undefined,
    Boolean(freeAgentId || podId)
  )

  const { formState, setFormField, setFormState } =
    useFormState<FormState>(DEFAULT_FORM_STATE)

  const { getTimeSpentSec } = useTimeTracking()
  const { data: taglineRequestData } = useQuery<
    TaglineRequestAgentReviewQuery,
    TaglineRequestAgentReviewQueryVariables
  >(GET_TAGLINE_REQUEST_BY_ID, {
    variables: {
      id: requestId,
    },
    skip: redirectToInitial,
  })

  const taglineRequest = taglineRequestData?.taglineRequest
  const queue = useMemo(
    () =>
      podId
        ? queueByPod[podId] || []
        : freeAgentId
        ? queueByClient[freeAgentId] || []
        : queueByViewer,
    [freeAgentId, podId, queueByClient, queueByPod, queueByViewer]
  )
  const firstQueueId = queue[0]

  useEffect(() => {
    if (!loading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, queue, loading])

  if (redirectToInitial && firstQueueId) {
    if (podId) {
      return (
        <Redirect
          to={generatePath(ROUTE_PATHS.AGENT_QUEUE_BY_POD, {
            podId,
            requestId: firstQueueId,
          })}
        />
      )
    }
    const queueType = talentAgentId ? 'talent-agent' : 'free-agent'
    const typeId = talentAgentId ? talentAgentId : freeAgentId

    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.AGENT_REVIEW_QUEUES, {
          requestId: firstQueueId,
          typeId: typeId,
          type: queueType,
        })}
      />
    )
  }

  if (!queue.length || !taglineRequest) {
    return (
      <Message vertical message="Loading tagline request initial review form..." />
    )
  }

  return (
    <DualScrollContainer key={requestId}>
      <DualScroll
        leftContent={<InitialReviewLeft taglineRequest={taglineRequest} />}
        rightContent={
          <InitialReviewRight
            taglineRequest={taglineRequest}
            formState={formState}
            setFormField={setFormField}
          />
        }
      />
      <InitialReviewFooter
        taglineRequest={taglineRequest}
        queue={queue}
        formState={formState}
        setFormState={setFormState}
        getTimeSpentSec={getTimeSpentSec}
        requestId={requestId}
        freeAgentId={freeAgentId}
        talentAgentId={talentAgentId}
        podId={podId}
      />
    </DualScrollContainer>
  )
}

InitialReview.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.AGENT_QUEUE} key={ROUTE_PATHS.AGENT_QUEUE}>
    <InitialReview />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AGENT_QUEUE_BY_FREE_AGENT}
    key={ROUTE_PATHS.AGENT_QUEUE_BY_FREE_AGENT}
  >
    <InitialReview />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AGENT_QUEUE_BY_POD}
    key={ROUTE_PATHS.AGENT_QUEUE_BY_POD}
  >
    <InitialReview />
  </PrivateRoute>,
]

const GET_TAGLINE_REQUEST_BY_ID = gql`
  query TaglineRequestAgentReview($id: ID!) {
    taglineRequest(id: $id) {
      id
      client {
        id
      }
      ...AgentReviewLeftInfo
      ...AgentReviewRightInfo
      ...AgentReviewFooterInfo
    }
  }
  ${InitialReviewLeft.fragments.taglineRequestInfo}
  ${InitialReviewRight.fragments.taglineRequestInfo}
  ${InitialReviewFooter.fragments.taglineRequestInfo}
`
