import { gql, useLazyQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { ColumnMap, ConnectionNode } from 'types'
import { useDebounceState } from 'hooks/useDebounceState'
import { EMPTY_CELL_PLACEHOLEDER, EMPTY_INPUT } from 'utils/constants'
import { Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  Maybe,
  SearchCompanyQuery,
  SearchCompanyQueryVariables,
} from 'generated/graphql'

type Company = ConnectionNode<NonNullable<SearchCompanyQuery['companies']>>
const FIRST = 100

export default function CompanySearch(): React.ReactElement {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebounceState<
    Maybe<string>
  >(null, 1000)

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            Companies
          </Txt>
        </Padding>
        <Padding bottom={4}>
          <HList size={2} rowGapSize={2}>
            <TextInput
              name="search"
              type="search"
              value={searchTerm || EMPTY_INPUT}
              placeholder="Search company..."
              onValueChange={(value) => setSearchTerm(value || null)}
              icon={<Icon name="Search" height={12} width={12} />}
              width={350}
              autoFocus
            />
          </HList>
        </Padding>
      </Container>
      <CompanySearchTable searchTerm={debouncedSearchTerm} />
    </ViewBox>
  )
}

type TableProps = {
  searchTerm: Maybe<string>
}

function CompanySearchTable({ searchTerm }: TableProps) {
  const isDataCleaner = checkRole([Role.DataCleaner])
  const [searchCompany, { data, error, loading, fetchMore }] = useLazyQuery<
    SearchCompanyQuery,
    SearchCompanyQueryVariables
  >(SEARCH_COMPANIES)

  useEffect(() => {
    if (searchTerm && searchTerm.length >= 3) {
      void searchCompany({
        variables: {
          first: FIRST,
          searchTerm,
        },
      })
    }
  }, [searchTerm, searchCompany])

  const companies = data?.companies ? getNodes(data.companies) : null
  const showLoadMoreButton = data?.companies?.pageInfo.hasNextPage
  const endCursor = data?.companies?.pageInfo.endCursor

  function handleMoreClick() {
    void fetchMore({
      variables: {
        searchTerm,
        first: FIRST,
        after: endCursor,
      },
    })
  }

  return (
    <>
      <FullWidthTable
        headers={Object.values(HEADERS).filter((item) =>
          // if showOnDataCleaner is not set, show the column, else show it value is true and user has role
          item.showOnDataCleaner == null
            ? true
            : item.showOnDataCleaner && isDataCleaner
        )}
        isEmpty={Boolean(!companies || !companies.length)}
        isLoading={loading}
        isError={Boolean(error)}
        emptyMessage={
          searchTerm && !loading
            ? 'Could not find any companies.'
            : 'Companies appear here after searching.'
        }
        loadingMessage="Loading companies..."
        errorMessage="There was an error fetching companies."
      >
        {companies?.map((company) => (
          <CompanySearchRow
            key={company.id}
            company={company}
            showMergeButton={isDataCleaner}
          />
        ))}
      </FullWidthTable>
      {showLoadMoreButton && (
        <Padding vertical={2}>
          <Flex justify="center" align="center">
            <Button $type="secondary" onClick={handleMoreClick}>
              Load More
            </Button>
          </Flex>
        </Padding>
      )}
    </>
  )
}

type RowProps = {
  company: Company
  showMergeButton: boolean
}

export function CompanySearchRow({
  company,
  showMergeButton,
}: RowProps): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Link
          to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, { companyId: company.id })}
        >
          <Txt as="span" size={14} underline hoverColor="black">
            {company.name}
          </Txt>
        </Link>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {company.domain ?? EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {company.applications.totalCount}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {company.sourcedJobs.totalCount}
        </Txt>
      </BodyData>
      <BodyData collapse>
        {showMergeButton && company.domain && (
          <Link
            to={generatePath(ROUTE_PATHS.COMPANY_MERGE, { companyId: company.id })}
          >
            <Button small noWrap>
              Start Merge
            </Button>
          </Link>
        )}
      </BodyData>
    </BodyRow>
  )
}

CompanySearchRow.fragments = {
  searchResultInfo: gql`
    fragment SearchResultInfo on Company {
      id
      name
      domain
      logoUrl
      applications {
        totalCount
      }
      sourcedJobs {
        totalCount
      }
    }
  `,
}

const SEARCH_COMPANIES = gql`
  query SearchCompany($searchTerm: String!, $first: Int!, $after: String) {
    companies(search: $searchTerm, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...SearchResultInfo
        }
      }
    }
  }
  ${CompanySearchRow.fragments.searchResultInfo}
`

export const HEADERS: ColumnMap<{ showOnDataCleaner?: boolean }> = {
  name: {
    label: 'Name',
    id: 'name',
  },
  domain: {
    label: 'Domain',
    id: 'domain',
  },
  applications: {
    label: 'Apps',
    id: 'applications',
  },
  sourcedJobs: {
    label: 'Sourced Jobs',
    id: 'sourcedJobs',
  },
  merge: {
    label: '',
    id: 'merge',
    collapse: true,
    showOnDataCleaner: true,
  },
}

CompanySearch.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.COMPANY_SEARCH}
    key={ROUTE_PATHS.COMPANY_SEARCH}
  >
    <CompanySearch />
  </PrivateRoute>,
]
