import styled from 'styled-components/macro'

export const CreateNewList = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  /* border: solid 0.1rem ${({ theme }) => theme.color.faBlue}; */
  left: 0;
  position: absolute;
  top: 0;
  transform: translateX(-100%);
  display: none;
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.rhythm(1)};
  z-index: 1;
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
`

export const CreateNewButton = styled.div`
  align-items: center;
  background-color: ${({ theme }) => theme.color.faBlue};
  border-radius: 100%;
  display: flex;
  height: ${({ theme }) => theme.rhythm(6)};
  justify-content: center;
  position: relative;
  width: ${({ theme }) => theme.rhythm(6)};
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
`

export const CreateNewButtonContainer = styled.button`
  padding: ${({ theme }) => theme.rhythm(2)};

  &:hover ${CreateNewButton} {
    border-top-left-radius: unset;
    border-bottom-left-radius: unset;

    & ${CreateNewList} {
      display: block;
    }
  }
`

export const CreateNewOption = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  padding: ${({ theme }) => theme.rhythm(1)};
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
`

export const StatBoxContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.color.faGrey1};
`
