import { gql, useQuery, NetworkStatus } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { useHistory } from 'react-router'
import { useAuthContext } from 'context/auth'
import { EMPTY_INPUT, QS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import { createSearchParams, useQueryString } from 'utils/urls'
import { GET_CLIENTS } from 'gql/queries'
import { anyRoleOption, FIRST, userRoleOptions } from './TaglineWorkLog.constants'
import TaglineWorkLogRow from './TaglineWorkLogRow'
import TaglineWorkLogTable from './TaglineWorkLogTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import { Line } from 'components/MiscStyles'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import { Tab } from 'components/StyledTabs'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetClientsQuery,
  GetClientsQueryVariables,
  GetTaglineWorkLogQuery,
  GetTaglineWorkLogQueryVariables,
  GetUsersQuery,
  GetUsersQueryVariables,
  TaglineWorkRole,
} from 'generated/graphql'

type Filter = {
  userId: string
  userRole: TaglineWorkRole
  clientId: string | null
}

type SearchParams = {
  userId: string
  userRole: TaglineWorkRole
  clientId?: string
}

export default function TaglineWorkLog(): JSX.Element {
  /**
   * request the next set of past work
   */
  function handleMoreClick() {
    void fetchMore({
      variables: {
        clientId,
        first: FIRST,
        after: pageInfo?.endCursor,
        userRole,
      },
    })
  }

  // state
  const {
    userSession: {
      user: { id: viewerId, isCoordinator, isTalentPartner },
    },
  } = useAuthContext()
  const history = useHistory()
  const queryString = useQueryString()
  const isCoordOrPartner = isCoordinator || isTalentPartner
  const qsUserId = queryString.get(QS.USER_ID)
  const qsUserRole = queryString.get(QS.USER_ROLE) as TaglineWorkRole | null
  const qsClientId = queryString.get(QS.CLIENT_ID)
  const defaultUserRole = isCoordOrPartner
    ? TaglineWorkRole.ANY
    : TaglineWorkRole.WRITER

  const [clientId, setClientId] = useState<string | null>(qsClientId)
  const [userId, setUserId] = useState<string>(qsUserId ?? viewerId)
  const [userRole, setUserRole] = useState<TaglineWorkRole>(
    qsUserRole ?? defaultUserRole
  )

  // queries
  const { data, loading, error, refetch, networkStatus, fetchMore } = useQuery<
    GetTaglineWorkLogQuery,
    GetTaglineWorkLogQueryVariables
  >(GET_TAGLINE_WORK_LOG, {
    notifyOnNetworkStatusChange: true,
    variables: {
      userId,
      first: FIRST,
      userRole,
    },
  })

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery<GetUsersQuery, GetUsersQueryVariables>(GET_USERS)

  const {
    data: clientsData,
    loading: clientsLoading,
    error: clientsError,
  } = useQuery<GetClientsQuery, GetClientsQueryVariables>(GET_CLIENTS)

  // derived state
  const clientOptions = buildDropdownOptions(clientsData?.clients || [])
  const userOptions = buildDropdownOptions(usersData?.users || [])

  const { pageInfo } = data?.user?.pastRelevantWorkConn || {}
  const showLoadMoreButton = Boolean(pageInfo?.hasNextPage)
  const pastRelevantWork =
    data?.user?.pastRelevantWorkConn && getNodes(data.user.pastRelevantWorkConn)

  const work = useMemo(
    () =>
      pastRelevantWork
        ?.map((taglineSubmission) => ({
          ...taglineSubmission,
          dateToSortBy: dayjs(taglineSubmission.createdAt),
        }))
        .filter(
          (taglineSubmission) =>
            !clientId || taglineSubmission.taglineRequest.client.id === clientId
        )
        .sort((a, b) =>
          // We want the newest work on top
          sortByProp(a, b, '-dateToSortBy')
        ) || [],
    [pastRelevantWork, clientId]
  )

  function updateQueryParams({ userId, userRole, clientId }: Filter) {
    const filters: SearchParams = {
      userId,
      userRole,
    }
    if (clientId) {
      filters.clientId = clientId
    }
    const search = createSearchParams(filters)
    history.push({ search })
  }

  /* TODO (matthewalbrecht): troubleshoot why networkStatus doesn't change on refetch({}) */
  const isRefetch = networkStatus === NetworkStatus.refetch
  const isFetchMore = networkStatus === NetworkStatus.fetchMore
  const isLoadingData =
    (loading && !isFetchMore) || usersLoading || clientsLoading || isRefetch

  const hasError = error || usersError || clientsError

  if (isLoadingData) {
    return (
      <Message
        vertical
        message="Loading past work, this could take up to 30 seconds..."
      />
    )
  }

  if (hasError) {
    return <RefreshMessage message="There was an error loading past work" />
  }

  /* TODO (matthewalbrecht): create a single filter state */
  /**
   *  refetches new set of past work
   * @param value value of user id to refetch with
   */
  function handleUserIdChange(value: string) {
    setUserId(value)
    updateQueryParams({ userId: value, userRole, clientId })
    void refetch({ userId: value || viewerId, userRole })
  }

  /**
   *  sets the clientid
   * @param value value of client
   */
  function handleClientIdChange(value: string) {
    updateQueryParams({ userId, userRole, clientId: value || null })
    setClientId(value || null)
  }

  /**
   *  sets the userRole
   * @param value value of client
   */
  function handleUserRoleChange(value: TaglineWorkRole) {
    if (value !== userRole) {
      setUserRole(value)
      updateQueryParams({ userId, userRole: value, clientId })
      void refetch({ userId: userId || viewerId, userRole: value })
    }
  }

  const userRoleOptionsFull = isCoordOrPartner
    ? [anyRoleOption, ...userRoleOptions]
    : userRoleOptions

  return (
    <ViewBox>
      <Padding top={6}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            Tagline Work Log
          </Txt>
          <Padding vertical={4}>
            <HList size={2}>
              {isCoordOrPartner && (
                <Dropdown
                  label="User"
                  defaultValue={userId}
                  onValueChange={handleUserIdChange}
                  options={userOptions}
                  withEmptyOption={false}
                />
              )}
              <Dropdown
                label="Free Agent"
                defaultValue={clientId ?? EMPTY_INPUT}
                onValueChange={handleClientIdChange}
                options={clientOptions}
              />
            </HList>
          </Padding>
          <HList size={1}>
            {userRoleOptionsFull.map((option) => (
              <Tab
                key={option.label}
                onClick={() => handleUserRoleChange(option.value)}
                className={
                  option.value === userRole ? 'react-tabs__tab--selected' : ''
                }
              >
                <Txt as="span" size={14}>
                  {option.label}
                </Txt>
              </Tab>
            ))}
          </HList>
        </Container>
        <Line />
        <Padding top={2}>
          <TaglineWorkLogTable work={work} userRole={userRole} />
          {!work.length && (
            <Flex justify="center" align="center">
              <Padding vertical={6}>
                <Txt>No work to show</Txt>
              </Padding>
            </Flex>
          )}
          {showLoadMoreButton && (
            <Flex justify="center">
              <Padding vertical={3}>
                <Button onClick={handleMoreClick}>Load More</Button>
              </Padding>
            </Flex>
          )}
        </Padding>
      </Padding>
    </ViewBox>
  )
}

TaglineWorkLog.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.TAGLINE_WORK_LOG}
    key={ROUTE_PATHS.TAGLINE_WORK_LOG}
  >
    <TaglineWorkLog />
  </PrivateRoute>,
]

const GET_TAGLINE_WORK_LOG = gql`
  query GetTaglineWorkLog(
    $userId: ID!
    $first: Int!
    $after: String
    $userRole: TaglineWorkRole!
  ) {
    user(id: $userId) {
      id
      pastRelevantWorkConn(first: $first, after: $after, userRole: $userRole) {
        edges {
          node {
            id
            createdAt
            ...TaglineWorkLogRowInfo
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${TaglineWorkLogRow.fragments.taglineWorkLogRowInfo}
`

const GET_USERS = gql`
  query GetUsers {
    users {
      id
      name
    }
  }
`
