import { CacheConnection } from 'types'

/**
 * used to get next total count in a cached connection
 * @param connection existing cached connection returned by field
 * @param toAdd amount to add to the field (-1 or 1 probably)
 * @returns new total count
 */
export default function getNextTotalCount(
  connection: CacheConnection,
  toAdd: number
): number {
  return connection.totalCount
    ? connection.totalCount + toAdd
    : connection.edges?.length
    ? connection.edges.length + toAdd
    : 1
}
