import {
  Tab as UnstyledTab,
  Tabs as UnstyledTabs,
  TabList as UnstyledTabList,
  TabPanel as UnstyledTabPanel,
} from 'react-tabs'
import styled from 'styled-components/macro'
import { hideScrollbar } from './MiscStyles'
import Txt from './Txt'

export const Tab = styled(UnstyledTab)`
  cursor: pointer;
  padding: ${({ theme }) => theme.rhythm(1)} ${({ theme }) => theme.rhythm(1.5)};
  border-top-left-radius: ${({ theme }) => theme.borderRadius};
  border-top-right-radius: ${({ theme }) => theme.borderRadius};

  &:hover {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }

  &.react-tabs__tab--selected {
    & ${Txt} {
      color: ${({ theme }) => theme.color.textDark};
    }
    background-color: ${({ theme }) => theme.color.faGrey2};
  }
`

export const Tabs = styled(UnstyledTabs)`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

export const TabList = styled(UnstyledTabList)`
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
`

export const TabPanel = styled(UnstyledTabPanel)`
  overflow-y: scroll;
  display: flex;
  flex-direction: column;

  ${hideScrollbar}

  &.react-tabs__tab-panel--selected {
    width: 100%;
    flex: 1;
  }
`
