import dayjs from 'dayjs'
import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  ApplicationsConnection,
  ApplicationsDueConnection,
  ClientWithApplications,
  DueWithApplications,
} from './ApplierTable.types'

export function buildRowData(
  connection: ApplicationsConnection
): ClientWithApplications[] {
  const nodes = getNodes(connection)

  // Grouping the applicatinos by client: {clientId: {id, name, applications}}
  const groupedData = nodes.reduce(
    (groups: KeyValue<ClientWithApplications>, application) => {
      const { id: clientId, name: clientName } = application.client
      const group = groups[clientId]

      if (group) {
        group.applications.push(application)
      } else {
        groups[clientId] = {
          id: clientId,
          name: clientName,
          applications: [application],
        }
      }
      return groups
    },
    {}
  )

  // sorting the groups by client name
  const rowData = Object.values(groupedData).sort((a, b) =>
    sortByProp(a, b, 'clientName')
  )

  return rowData
}

export function buildRowDataByDueDate(
  connection: ApplicationsDueConnection
): DueWithApplications[] {
  const nodes = getNodes(connection)

  // Grouping the applicatinos by due date -> {due: {due, applications}
  const groupedData = nodes.reduce(
    (groups: KeyValue<DueWithApplications>, application) => {
      const due = application.due
      if (!due) {
        return groups
      }
      const group = groups[due]

      if (group) {
        group.applications.push(application)
      } else {
        groups[due] = {
          due: due,
          sortableDue: dayjs(due),
          applications: [application],
        }
      }
      return groups
    },
    {}
  )

  // sorting the groups by client name
  const rowData = Object.values(groupedData).sort((a, b) =>
    sortByProp(a, b, 'sortableDue')
  )

  return rowData
}
