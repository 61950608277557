import { Dialog as UnstyledDialog } from '@reach/dialog'
import { VisuallyHidden } from '@reach/visually-hidden'
import React from 'react'
import styled from 'styled-components/macro'
import { ReactClickHandler } from 'types'
import { ResponsiveRuleValue, buildResponsiveRules } from 'theme/breakpoint'
import Icon from './Icon/Icon'

type DialogProps = {
  widthVW?: ResponsiveRuleValue<number>
}

export const Dialog = styled(UnstyledDialog)<DialogProps>`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.large};
  padding: 0;

  ${({ widthVW }) =>
    widthVW && buildResponsiveRules('width', widthVW, (value) => `${value}vw`)}
`

export const SearchDialog = styled(UnstyledDialog)<DialogProps>`
  position: relative;
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.large};
  padding: 0;

  width: min-content;
`

export const DialogFooter = styled.footer`
  border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
  border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  onClick: ReactClickHandler
}

const DialogExitButtonStyled = styled.button<Props>`
  position: absolute;
  padding: ${({ theme }) => theme.rhythm(2)};
  top: 0;
  right: 0;
  transition: 0.2s ease-in-out transform;
`

export function DialogExitButton({ onClick }: Props): React.ReactElement {
  return (
    <DialogExitButtonStyled onClick={onClick}>
      <VisuallyHidden>Close</VisuallyHidden>
      <Icon name="Exit" height={16} width={16} />
    </DialogExitButtonStyled>
  )
}
