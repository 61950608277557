import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { useParams } from 'react-router'
import { DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import Container from 'components/Container'
import { TextArea } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import {
  GetClientApplicationAnswersQuery,
  GetClientApplicationAnswersQueryVariables,
} from 'generated/graphql'

type Params = {
  clientId: string
}

export default function ClientApplicationAnswers(): React.ReactElement {
  const { clientId } = useParams<Params>()

  const { data, loading } = useQuery<
    GetClientApplicationAnswersQuery,
    GetClientApplicationAnswersQueryVariables
  >(GET_CLIENT_APP_ANSWERS, {
    variables: {
      id: clientId,
    },
  })

  if (loading) {
    return <Message vertical message="Loading client answer bank..." />
  }

  if (!data?.client) {
    return <Message vertical message="Could not find client." />
  }

  const answeredMissingApplicationInfos =
    data.client.missingApplicationInfos.edges.filter(
      (missingInfo) => missingInfo.node.answers
    )

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h1">
            {data.client.name}
          </Txt>
          <Padding top={1}>
            <Txt>Missing Application Info Answers</Txt>
          </Padding>
        </Padding>
        <VList size={6}>
          {answeredMissingApplicationInfos.map(({ node: missingInfo }) => (
            <div key={missingInfo.id}>
              <TextArea
                autosize
                label={`Questions answered on ${dayjs(missingInfo.answeredAt).format(
                  DATE_FORMAT.DATE_AND_TIME
                )}`}
                disabled
                defaultValue={missingInfo.questions}
                height={150}
                rows={5}
              />
              <Padding top={3}>
                <TextArea
                  autosize
                  disabled
                  // we filter out missing answers
                  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                  value={missingInfo.answers!}
                />
              </Padding>
            </div>
          ))}
          {!answeredMissingApplicationInfos.length && (
            <Message message="No answered questions found." />
          )}
        </VList>
      </Container>
    </ViewBox>
  )
}

ClientApplicationAnswers.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_APPLICATION_ANSWERS}
    key={ROUTE_PATHS.CLIENT_APPLICATION_ANSWERS}
  >
    <ClientApplicationAnswers />
  </PrivateRoute>,
]

const GET_CLIENT_APP_ANSWERS = gql`
  query GetClientApplicationAnswers($id: ID!) {
    client(id: $id) {
      id
      name
      missingApplicationInfos {
        edges {
          node {
            id
            questions
            answers
            answeredAt
          }
        }
      }
    }
  }
`
