import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useAuthContext } from 'context/auth'
import { Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import AutoSourcing from 'components/AutoSourcing/AutoSourcing'
import ClientActiveStatus from 'components/ClientActiveStatus/ClientActiveStatus'
import ClientTalentAgent from 'components/ClientTalentAgent/ClientTalentAgent'
import { FORM_ID } from './ClientSettings.constants'
import ClientSettingsSection from './ClientSettingsSection'
import Container from 'components/Container'
import { BackgroundColor } from 'components/MiscStyles'
import Padding from 'components/Padding'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import {
  GetClientSettingsDataQuery,
  GetClientSettingsDataQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientSettings({ clientId }: Props): React.ReactElement {
  const {
    userSession: {
      user: { id: viewerId },
    },
  } = useAuthContext()
  const { data } = useQuery<
    GetClientSettingsDataQuery,
    GetClientSettingsDataQueryVariables
  >(GET_CLIENT_SETTINGS_DATA, {
    variables: { id: clientId },
  })

  const client = data?.client
  const isClientsTalentAgent = client?.talentAgent.id === viewerId
  const showProtectedContent = isClientsTalentAgent || checkRole([Role.Coordinator])
  return (
    <ViewBox>
      <BackgroundColor color="faGrey1" style={{ minHeight: '100%' }}>
        <Padding top={5} bottom={5}>
          <Container align="center">
            <VList size={3}>
              <ClientSettingsSection
                headerCopy="Auto Sourcing"
                formId={FORM_ID.AUTO_SOURCING}
              >
                {client ? (
                  <AutoSourcing client={client} formId={FORM_ID.AUTO_SOURCING} />
                ) : (
                  AutoSourcing.Skeleton
                )}
              </ClientSettingsSection>
              {showProtectedContent && (
                <ClientSettingsSection
                  headerCopy="Active Status"
                  formId={FORM_ID.ACTIVE_STATUS}
                >
                  {client ? (
                    <ClientActiveStatus
                      client={client}
                      formId={FORM_ID.ACTIVE_STATUS}
                    />
                  ) : (
                    ClientActiveStatus.Skeleton
                  )}
                </ClientSettingsSection>
              )}
              {checkRole([Role.Coordinator]) && (
                <ClientSettingsSection
                  headerCopy="Talent Agent"
                  formId={FORM_ID.TALENT_AGENT}
                >
                  {client ? (
                    <ClientTalentAgent
                      client={client}
                      formId={FORM_ID.TALENT_AGENT}
                    />
                  ) : (
                    ClientTalentAgent.Skeleton
                  )}
                </ClientSettingsSection>
              )}
            </VList>
          </Container>
        </Padding>
      </BackgroundColor>
    </ViewBox>
  )
}

const GET_CLIENT_SETTINGS_DATA = gql`
  query GetClientSettingsData($id: ID!) {
    client(id: $id) {
      id
      name
      ...AutoSourcingInfo
      ...ActiveStatusInfo
      ...ClientTalentAgentInfo
    }
  }
  ${AutoSourcing.fragments.autoSourcingInfo}
  ${ClientActiveStatus.fragments.ActiveStatusInfo}
  ${ClientTalentAgent.fragments.ClientTalentAgentInfo}
`
