import { gql, useQuery } from '@apollo/client'
import React, { useState, useMemo } from 'react'
import { useParams, generatePath, useHistory } from 'react-router'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { GET_TALENT_AGENTS } from 'gql/queries'
import {
  STATS_OPTIONS,
  DATE_FILTERING_OPTIONS,
  DateFilterOptionsMap,
  StatOptionsMap,
} from './TalentAgentStats.constants'
import {
  buildTableData,
  buildChartData,
  getDateFilter,
} from './TalentAgentStats.helpers'
import {
  Params,
  ClientsUnselectedMap,
  DateFilterPeriod,
  StatType,
} from './TalentAgentStats.types'
import TalentAgentStatsChart from './TalentAgentStatsChart'
import TalentAgentStatsTable from './TalentAgentStatsTable'
import Container from 'components/Container'
import Flex from 'components/Flex'
import { Dropdown, RadioGroup } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetTalentAgentStatsInfoQuery,
  GetTalentAgentStatsInfoQueryVariables,
  GetTalentAgentStatsSourcedJobsQuery,
  GetTalentAgentStatsSourcedJobsQueryVariables,
  GetTalentAgentsQuery,
} from 'generated/graphql'

export default function TalentAgentStats(): React.ReactElement {
  const { talentAgentId } = useParams<Params>()
  const history = useHistory()
  const [dateFilteringOption, setDateFilteringOption] = useState<DateFilterPeriod>(
    DateFilterPeriod.LAST_30_DAYS
  )
  const [taStatOption, setTaStatOption] = useState<StatType>(StatType.APPS_SUBMITTED)
  const [clientsUnselectedMap, setClientsUnselectedMap] =
    useState<ClientsUnselectedMap>({})
  const dateFilter = useMemo(
    () => getDateFilter(dateFilteringOption),
    [dateFilteringOption]
  )
  const { data, loading, error } = useQuery<
    GetTalentAgentStatsInfoQuery,
    GetTalentAgentStatsInfoQueryVariables
  >(GET_TALENT_AGENT_STATS_INFO, {
    variables: {
      talentAgentId: talentAgentId ?? '',
      dateFilter,
    },
  })
  const {
    data: sourcedJobData,
    loading: sourcedJobLoading,
    error: sourcedJobError,
  } = useQuery<
    GetTalentAgentStatsSourcedJobsQuery,
    GetTalentAgentStatsSourcedJobsQueryVariables
  >(GET_TALENT_AGENT_STATS_SOURCED_JOBS, {
    variables: {
      talentAgentId: talentAgentId ?? '',
      dateFilter,
    },
    skip: taStatOption !== StatType.SOURCED_JOBS_AVERAGE_RATINGS,
  })
  const {
    data: taData,
    loading: taLoading,
    error: taError,
  } = useQuery<GetTalentAgentsQuery>(GET_TALENT_AGENTS)

  if (loading || taLoading || sourcedJobLoading) {
    return <Message vertical message="Loading TA stats..." />
  }

  if (error || taError || sourcedJobError) {
    return <Message vertical message="There was an error loading TA stats" />
  }

  if (!data?.clients) {
    return <Message vertical message="Could not find clients." />
  }

  function handleTalentAgentChange(value: string) {
    const path = generatePath(ROUTE_PATHS.TALENT_AGENT_STATS, {
      talentAgentId: value,
    })
    history.push(path)
  }

  const clients = getNodes(data.clients)
  const sourcedJobs =
    sourcedJobData && sourcedJobData.sourcedJobs
      ? getNodes(sourcedJobData.sourcedJobs)
      : []
  const tableData = buildTableData(clients, sourcedJobs)
  const chartData = buildChartData(
    clients,
    clientsUnselectedMap,
    taStatOption,
    dateFilteringOption,
    sourcedJobs
  )
  const taOptions = buildDropdownOptions(taData?.talentAgents ?? [])
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            {data.user?.name} Stats
          </Txt>
          <Padding top={2}>
            <Flex justify="space-between" align="center">
              <Dropdown
                options={taOptions}
                value={talentAgentId}
                onValueChange={handleTalentAgentChange}
                minWidth={100}
                placeholder="Select TA"
                withEmptyOption={false}
                required
              />
              <Dropdown
                options={STATS_OPTIONS}
                value={taStatOption}
                minWidth={100}
                placeholder="Select a TA stat"
                withEmptyOption={false}
                onValueChange={(value) => setTaStatOption(value as StatType)}
                required
              />
            </Flex>
          </Padding>
          <Padding top={2}>
            <RadioGroup
              name="dateFiltering"
              value={dateFilteringOption}
              options={DATE_FILTERING_OPTIONS}
              onValueChange={(value) =>
                setDateFilteringOption(value as DateFilterPeriod)
              }
            />
          </Padding>
        </Padding>
      </Container>
      <Flex justify="center">
        <Padding bottom={2}>
          <Txt size={18} bold as="h3">
            {StatOptionsMap[taStatOption]} Over{' '}
            {DateFilterOptionsMap[dateFilteringOption]}
          </Txt>
        </Padding>
      </Flex>
      <TalentAgentStatsChart chartData={chartData} />
      <Padding top={4}>
        <TalentAgentStatsTable
          tableData={tableData}
          loading={loading}
          error={error}
          clientsUnselectedMap={clientsUnselectedMap}
          setClientsUnselectedMap={setClientsUnselectedMap}
        />
      </Padding>
    </ViewBox>
  )
}

TalentAgentStats.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.TALENT_AGENT_STATS}
    key={ROUTE_PATHS.TALENT_AGENT_STATS}
  >
    <TalentAgentStats />
  </PrivateRoute>,
]

const GET_TALENT_AGENT_STATS_INFO = gql`
  query GetTalentAgentStatsInfo(
    $talentAgentId: ID!
    $dateFilter: DatetimeRangeFilter!
  ) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          id
          name
          signDateInitial
          applications(
            createdAtRangeFilter: $dateFilter
            hasApplicationSubmissionSubmission: true
          ) {
            edges {
              node {
                id
                applicationSubmissions {
                  id
                  createdAt
                }
              }
            }
          }
          interviewCycles(createdAtRangeFilter: $dateFilter) {
            edges {
              node {
                id
                createdAt
              }
            }
          }
          interviewCyclesActive: interviewCycles(active: true) {
            totalCount
          }
        }
      }
    }
    user(id: $talentAgentId) {
      name
    }
  }
`

const GET_TALENT_AGENT_STATS_SOURCED_JOBS = gql`
  query GetTalentAgentStatsSourcedJobs(
    $talentAgentId: ID!
    $dateFilter: DatetimeRangeFilter!
  ) {
    sourcedJobs(
      talentAgentId: $talentAgentId
      createdAtRangeFilter: $dateFilter
      isReviewed: true
    ) {
      edges {
        node {
          createdAt
          client {
            id
          }
          reviews {
            rating
          }
        }
      }
    }
  }
`
