import React from 'react'
import { ReactSetState } from 'types'
import Button from 'components/Button'
import HList from 'components/HList'

type Props = {
  labels: string[]
  currentTab: number
  setCurrentTab: ReactSetState<number>
}

export default function TabbedTableNav({
  labels,
  currentTab,
  setCurrentTab,
}: Props): JSX.Element {
  return (
    <HList size={2}>
      {labels.map((label, index) => (
        <Button
          key={label}
          $type="link"
          active={index === currentTab}
          onClick={() => setCurrentTab(index)}
        >
          {label}
        </Button>
      ))}
    </HList>
  )
}
