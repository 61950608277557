import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { maybeGetNodes } from 'utils/helpers/getNodes'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import { FeedbackQueueQuery, FeedbackQueueQueryVariables } from 'generated/graphql'

type QueueReturn = {
  queueByViewer: string[]
  loading: boolean
}

type CountObject = {
  totalCount: Undefinable<number>
  loading: boolean
}

export default function useFeedbackQueue(
  queryOptions: QueryHookOptions<
    FeedbackQueueQuery,
    FeedbackQueueQueryVariables
  > = {}
): QueueReturn {
  const {
    userSession: {
      user: { id: createdById },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<
    FeedbackQueueQuery,
    FeedbackQueueQueryVariables
  >(GET_FEEDBACK_QUEUE, {
    ...queryOptions,
    variables: {
      createdById,
      onlyCount: false,
    },
  })

  if (!data?.taglineSubmissions) {
    return { queueByViewer: [], loading }
  }

  const taglineSubmissions = maybeGetNodes(data.taglineSubmissions) ?? []

  const queueByViewer = taglineSubmissions
    .map((taglineSubmission) => ({
      ...taglineSubmission,
      dateToSortBy: dayjs(taglineSubmission.createdAt),
    }))
    .sort((a, b) => sortByProp(a, b, 'dateToSortBy'))
    .map(reduceObjectToId)

  return { queueByViewer, loading }
}

export function useFeedbackQueueCount(
  queryOptions: QueryHookOptions<
    FeedbackQueueQuery,
    FeedbackQueueQueryVariables
  > = {}
): CountObject {
  const {
    userSession: {
      user: { id: createdById },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<
    FeedbackQueueQuery,
    FeedbackQueueQueryVariables
  >(GET_FEEDBACK_QUEUE, {
    ...queryOptions,
    variables: {
      createdById,
      onlyCount: true,
    },
  })

  return {
    totalCount: data?.taglineSubmissions?.totalCount ?? undefined,
    loading,
  }
}

/**
 * used by writers to queue through all of their feedback
 */
const GET_FEEDBACK_QUEUE = gql`
  query FeedbackQueue($createdById: ID!, $onlyCount: Boolean!) {
    taglineSubmissions: taglineSubmissionsConn(
      createdById: $createdById
      hasWriterFeedbackAcknowledgements: false
      hasTalentPartnerReviews: true
    ) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          createdAt
        }
      }
    }
  }
`
