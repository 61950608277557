import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { KeyValue } from 'types'
import { useAuthContext } from 'context/auth'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import {
  ApplicationQueueQuery,
  ApplicationQueueQueryVariables,
} from 'generated/graphql'

type QueueReturn = {
  queueByViewer: string[]
  loading: boolean
  queueByClient: KeyValue<string[]>
}
type CountReturn = {
  loading: boolean
  totalCount?: number
}

export default function useApplierQueue(
  queryOptions: QueryHookOptions<
    ApplicationQueueQuery,
    ApplicationQueueQueryVariables
  > = {}
): QueueReturn {
  // state
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()

  // query
  const { data, loading } = useQuery<
    ApplicationQueueQuery,
    ApplicationQueueQueryVariables
  >(GET_APPLICATION_QUEUE, {
    variables: {
      assigneeId,
      onlyCount: false,
    },
    ...queryOptions,
  })

  const applications = data?.applications?.edges
    ? data.applications.edges
        .map((edge) => ({
          ...edge.node,
          sortableDue: dayjs(edge.node.due || undefined),
          clientName: edge.node.client.name,
        }))
        .sort((a, b) => sortByProp(a, b, 'sortableDue', 'clientName'))
    : []

  // map client ids to array of tagline requests
  const queueByClient = applications.reduce(
    (clientMap: KeyValue<string[]>, application) => {
      const clientArray = clientMap[application.client.id]
      if (clientArray) {
        clientArray.push(application.id)
      } else {
        clientMap[application.client.id] = [application.id]
      }
      return clientMap
    },
    {}
  )
  const queueByViewer = applications.map(reduceObjectToId)

  return { queueByViewer, queueByClient, loading }
}

export function useApplierQueueCount(
  queryOptions: QueryHookOptions<
    ApplicationQueueQuery,
    ApplicationQueueQueryVariables
  > = {}
): CountReturn {
  // state
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()

  // query
  const { data, loading } = useQuery<
    ApplicationQueueQuery,
    ApplicationQueueQueryVariables
  >(GET_APPLICATION_QUEUE, {
    variables: {
      assigneeId,
      onlyCount: true,
    },
    ...queryOptions,
  })

  return {
    totalCount:
      data?.applications?.totalCount == null
        ? undefined
        : data.applications.totalCount,
    loading,
  }
}

/**
 * used by appleirs to queue through all of their work
 */
const GET_APPLICATION_QUEUE = gql`
  query ApplicationQueue($assigneeId: ID!, $onlyCount: Boolean!) {
    applications(assigneeId: $assigneeId, status: PENDING_APPLICATION) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          due
          client {
            id
            name
          }
        }
      }
    }
  }
`
