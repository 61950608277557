import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { Client } from './AutoSourcingDashboard.types'
import Txt from 'components/Txt'

type Props = {
  client: Client
}

export default function AutoSourcingDashboardRow({
  client,
}: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Link
          to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
            clientId: client.id,
          })}
        >
          <Txt as="span" underline hoverColor="black" size={14}>
            {client.name}
          </Txt>
        </Link>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.talentAgent.name}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.autoSourcingAssignmentGroup}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.autoSourcingsWeekly ?? '–'}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {client.autoSourcingAssignee?.name || '–'}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
