import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ROUTE_PATHS from 'utils/routePaths'
import { resetState, useChanges } from 'views/Assign/Assign.context'
import AssignFooter from 'views/Assign/AssignFooter'
import Button from 'components/Button'

export default function AssignApplicationFooter(): React.ReactElement {
  const { state, dispatch } = useChanges()

  const [assignSourcings, { loading }] = useMutation(UPDATE_SOURCING_REQUESTS, {
    // TODO (matthewalbrecht): use update function to handle this
    refetchQueries: ['GetSourcingForAssigning'],
    onCompleted() {
      toast.success(`Assigned sourcing request(s)`)
      resetState(dispatch)
    },
    onError() {
      // TODO: (matthewalbrecht) handle error better
      toast.error('There was an error assigning the tasks')
    },
  })

  const shouldMutate = Object.keys(state.updates).length

  function handleSubmit() {
    const updates = Object.entries(state.updates).map(
      ([sourcingRequestId, { assigneeId, due }]) => ({
        sourcingRequestId,
        assigneeId,
        due,
      })
    )

    void assignSourcings({ variables: { UpdateSourcingRequestsInput: { updates } } })
  }

  const disableSubmit = !shouldMutate || loading

  return (
    <AssignFooter>
      <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
        Cancel
      </Button>
      <Button $type="accept" onClick={handleSubmit} disabled={disableSubmit}>
        Submit
      </Button>
    </AssignFooter>
  )
}

const UPDATE_SOURCING_REQUESTS = gql`
  mutation UpdateSourcingRequests(
    $UpdateSourcingRequestsInput: UpdateSourcingRequestsInput!
  ) {
    updateSourcingRequests(input: $UpdateSourcingRequestsInput) {
      sourcingRequests {
        id
        assignee {
          id
          name
        }
        due
      }
    }
  }
`
