import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import AddAndEditInterviewPrepRequestFooter from './AddAndEditInterviewPrepRequestFooter'
import AddAndEditInterviewPrepRequestForm from './AddAndEditInterviewPrepRequestForm'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function AddAndEditInterviewPrepRequest(): React.ReactElement {
  return (
    <ViewBox footer={<AddAndEditInterviewPrepRequestFooter />}>
      <Container noMax>
        <Padding top={6}>
          <AddAndEditInterviewPrepRequestForm />
        </Padding>
      </Container>
    </ViewBox>
  )
}

AddAndEditInterviewPrepRequest.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_INTERVIEW_PREP_REQUEST}
    key={ROUTE_PATHS.NEW_INTERVIEW_PREP_REQUEST}
  >
    <AddAndEditInterviewPrepRequest />
  </PrivateRoute>,
]
