// Adapted from: https://github.com/tolotrasmile/use-debounce-state/blob/master/index.js

import { useState, useEffect } from 'react'
import { ReactSetState } from 'types'

function useDebounce<T>(initialValue: T, delay: number): T {
  const [value, setValue] = useState(initialValue)
  useEffect(() => {
    const handler = setTimeout(() => setValue(initialValue), delay)
    return () => clearTimeout(handler)
  }, [delay, initialValue])
  return value
}

function useDebounceState<T>(
  initialValue: T,
  delay: number
): [T, ReactSetState<T>, T] {
  const [value, setValue] = useState(initialValue)
  const debounceValue = useDebounce(value, delay)
  return [value, setValue, debounceValue]
}

export { useDebounce, useDebounceState }
