import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import NewTemplateButton from 'components/NewTemplateButton/NewTemplateButton'
import NewTemplateSequenceButton from 'components/NewTemplateSequenceButton/NewTemplateSequenceButton'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import TemplateEditor from '../../components/TemplateEditor/TemplateEditor'
import { Client, ConfigPageEditorChanges } from './ClientTemplates.types'
import ClientTemplatesSequenceHeader from './ClientTemplatesSequenceHeader'
import ClientTemplatesTemplateLabel from './ClientTemplatesTemplateLabel'
import Button from 'components/Button'
import HList from 'components/HList'
import Padding from 'components/Padding'
import VList from 'components/VList'

type Props = {
  client: Client | null | undefined
  clientId: string
  activeSequence: number
  setActiveSequence: ReactSetState<number>
  setEditorChanges: ReactSetState<ConfigPageEditorChanges>
  editorChanges: ConfigPageEditorChanges
}

const EditorList = styled.div<{ active?: boolean }>`
  ${({ active }) => (active ? 'display: block' : 'display: none')};
`

function ClientTemplatesRight({
  client,
  clientId,
  activeSequence,
  setActiveSequence,
  setEditorChanges,
  editorChanges,
}: Props): JSX.Element {
  function handleEditorChange(
    content: string,
    templateId: string,
    sequenceId: string
  ) {
    setEditorChanges({
      ...editorChanges,
      [sequenceId]: {
        ...editorChanges[sequenceId],
        [templateId]: content,
      },
    })
  }

  function removeTemplateChanges(templateId: string, sequenceId: string) {
    const newSequence = { ...editorChanges[sequenceId] }
    delete newSequence[templateId]
    setEditorChanges({
      ...editorChanges,
      [sequenceId]: newSequence,
    })
  }

  const sequences = client?.templateSequences
  const sequence = sequences?.[activeSequence]

  return (
    <>
      <Padding bottom={4}>
        <HList size={2}>
          <RectangleSkeletonWrap
            height={16}
            width={120}
            showContent={Boolean(sequences)}
          >
            {sequences?.map((sequence, index) => (
              <Button
                $type="link"
                active={index === activeSequence}
                key={sequence.id}
                onClick={() => setActiveSequence(index)}
              >
                {sequence.name}
              </Button>
            ))}
          </RectangleSkeletonWrap>
          <NewTemplateSequenceButton clientId={clientId} />
        </HList>
      </Padding>
      {sequence && (
        <Padding bottom={2}>
          <ClientTemplatesSequenceHeader
            sequence={sequence}
            setActiveSequence={setActiveSequence}
          />
        </Padding>
      )}
      {sequences?.map((sequence, index) => (
        <EditorList active={activeSequence === index} key={sequence.id}>
          <VList size={4}>
            {sequence.templates.map((template) => (
              <div key={template.id}>
                <Padding bottom={0.5}>
                  <ClientTemplatesTemplateLabel
                    template={template}
                    sequence={sequence}
                    onDelete={() => removeTemplateChanges(template.id, sequence.id)}
                  />
                </Padding>
                <TemplateEditor
                  hideLabel
                  template={template}
                  sequence={sequence}
                  handleEditorChange={handleEditorChange}
                />
              </div>
            ))}
            <NewTemplateButton sequenceId={sequence.id} />
          </VList>
        </EditorList>
      ))}
    </>
  )
}

ClientTemplatesRight.fragments = {
  freeAgentConfigRightInfo: gql`
    fragment ClientTemplatesRightInfo on Client {
      id
      templateSequences {
        id
        name
        templates {
          id
          # TODO (matthewalbrecht) autogen not mergeing dup fields and causing ts to mis-type things
          name
          copy
        }
        ...TemplateEditorSequenceInfo
      }
    }
    ${TemplateEditor.fragments.templateEditorSequenceInfo}
  `,
}

export default ClientTemplatesRight
