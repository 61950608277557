import { generatePath } from 'react-router'
import { QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import { GenericSourcingItem } from './AssignSourcing.types'

/**
 * generates a path to the edit page for application
 *
 * @param requestId id of item to edit
 * @param pathname current pathname
 */
export default function getEditPath(requestId: string, pathname: string): string {
  return generatePath(
    `${ROUTE_PATHS.EDIT_SOURCING_REQUEST}?${createSearchParams({
      [QS.CALLBACK]: pathname,
    })}`,
    {
      requestId,
    }
  )
}

export const sumNumJobs = (items: GenericSourcingItem[]): number =>
  items.reduce((total: number, item) => total + item.numJobs, 0)
