import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import {
  ENRICHMENT_DUE_HOUR,
  ENRICHMENT_DUE_MINUTE,
  ENRICHMENT_DUE_TZ,
} from 'utils/constants'
import { getDueTime } from 'utils/helpers/time'
import Editor from 'components/Editor/Editor'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { EnrichmentRequest } from './EnrichmentSubmission.types'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt, { Bold } from 'components/Txt'
import VList from 'components/VList'

type Props = {
  enrichmentRequest: EnrichmentRequest
}

function EnrichmentSubmissionLeft({ enrichmentRequest }: Props): JSX.Element {
  const dueLabel = `Due ${getDueTime(
    dayjs(enrichmentRequest.due),
    ENRICHMENT_DUE_HOUR,
    ENRICHMENT_DUE_MINUTE,
    ENRICHMENT_DUE_TZ
  )}`
  return (
    <>
      <Padding bottom={4}>
        <Flex justify="space-between">
          <Txt as="h2" size={24} bold>
            {enrichmentRequest.client.name}
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            {dueLabel}
          </Txt>
        </Flex>
        <Padding top={2.5}>
          <VList size={1.5}>
            <Txt size={14} lineHeight={1.3}>
              Talent Agent: <Bold>{enrichmentRequest.client.talentAgent.name}</Bold>
            </Txt>
          </VList>
        </Padding>
      </Padding>
      <VList size={4}>
        {enrichmentRequest.client.resumeUrl && (
          <PDFViewer url={enrichmentRequest.client.resumeUrl} />
        )}
        <WorkInfoLinks
          resumeUrl={enrichmentRequest.client.resumeUrl}
          linkedinUrl={enrichmentRequest.client.linkedinUrl}
        />
        {enrichmentRequest.huntrHtmlDescription && (
          <Editor
            defaultValue={enrichmentRequest.huntrHtmlDescription}
            label="Notes from Huntr"
            height={80}
            disabled
          />
        )}
        {enrichmentRequest.client.bio && (
          <TextArea
            defaultValue={enrichmentRequest.client.bio}
            label={`${enrichmentRequest.client.name} Bio`}
            height={80}
            disabled
          />
        )}
      </VList>
    </>
  )
}

EnrichmentSubmissionLeft.fragments = {
  enrichmentRequestInfo: gql`
    fragment EnrichmentSubmissionLeftInfo on EnrichmentRequest {
      id
      huntrHtmlDescription
      due
      client {
        name
        resumeUrl
        linkedinUrl
        bio
        talentAgent {
          name
        }
      }
    }
  `,
}

export default EnrichmentSubmissionLeft
