import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Redirect, useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import useApplierQueue from 'hooks/useApplierQueue'
import { GLOBAL_COPY, INITIAL, ROLE_GROUPS } from 'utils/constants'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import TimeTracker from 'components/TimeTracker/TimeTracker'
import { FinalCopyState, Params } from './FinalCopy.types'
import FinalCopyFooter from './FinalCopyFooter'
import FinalCopyLeft from './FinalCopyLeft'
import FinalCopyQueueFooter from './FinalCopyQueueFooter'
import FinalCopyRight from './FinalCopyRight'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import { GetApplicationQuery, GetApplicationQueryVariables } from 'generated/graphql'

export default function FinalCopy(): JSX.Element {
  const history = useHistory()
  const { applicationId, applierId, freeAgentId } = useParams<Params>()
  const redirectToInitial = applicationId === INITIAL
  const isQueuePage = Boolean(applierId || freeAgentId)
  const [timeSpentSec, setTimeSpentSec] = useState(0)
  const {
    queueByViewer,
    queueByClient,
    loading: queueLoading,
  } = useApplierQueue({ skip: !isQueuePage })
  const queue = useMemo(
    () => (freeAgentId ? queueByClient[freeAgentId] || [] : queueByViewer),
    [freeAgentId, queueByClient, queueByViewer]
  )
  const [state, setState] = useState<FinalCopyState>({
    isLocked: true,
    changes: {},
  })
  const { data, loading, error } = useQuery<
    GetApplicationQuery,
    GetApplicationQueryVariables
  >(GET_APPLICATION, {
    variables: {
      id: applicationId,
      skipStatus: isQueuePage,
    },
    skip: redirectToInitial,
  })
  const canEdit = checkRole(ROLE_GROUPS.NON_WRITER)
  const application = data?.application

  const leftContent = useMemo(
    () =>
      application && (
        <FinalCopyLeft application={application} isQueuePage={isQueuePage} />
      ),
    [application, isQueuePage]
  )
  const rightContent = useMemo(
    () =>
      application && (
        <FinalCopyRight
          application={application}
          state={state}
          setState={setState}
          canEdit={canEdit}
        />
      ),
    [application, state, setState, canEdit]
  )

  useEffect(() => {
    if (isQueuePage && !queueLoading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, isQueuePage, queue, queueLoading])

  if (redirectToInitial && queue[0]) {
    const queueType = applierId ? 'applier' : 'free-agent'
    const typeId = applierId ? applierId : freeAgentId

    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.APPLIER_ASSIGNMENT_QUEUES, {
          applicationId: queue[0],
          typeId: typeId,
          type: queueType,
        })}
      />
    )
  }

  if (loading) {
    return <Message vertical message="Loading application copy..." />
  }

  if (error) {
    return <RefreshMessage message="There was an error loading application copy" />
  }

  if (!data?.application) {
    return <Message vertical message="There was no final copy found" />
  }

  const showEditCopyFooter = canEdit && !state.isLocked
  const showFooter = isQueuePage || showEditCopyFooter
  const Footer = showEditCopyFooter ? (
    <FinalCopyFooter
      application={data.application}
      state={state}
      setState={setState}
    />
  ) : (
    <FinalCopyQueueFooter
      application={data.application}
      setState={setState}
      queue={queue}
      applicationId={applicationId}
      applierId={applierId}
      freeAgentId={freeAgentId}
      timeSpentSec={timeSpentSec}
      setTimeSpentSec={setTimeSpentSec}
    />
  )

  return (
    <DualScrollContainer key={applicationId}>
      {isQueuePage && (
        <Container noMax>
          <Padding top={2} bottom={2}>
            <Flex justify="flex-end" align="center">
              <Padding left={3}>
                <TimeTracker onValueChange={setTimeSpentSec} />
              </Padding>
            </Flex>
          </Padding>
        </Container>
      )}
      <DualScroll leftContent={leftContent} rightContent={rightContent} />
      {showFooter && Footer}
    </DualScrollContainer>
  )
}

FinalCopy.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.APPLICATION_INFO}
    key={ROUTE_PATHS.APPLICATION_INFO}
  >
    <FinalCopy />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.APPLIER_QUEUE}
    key={ROUTE_PATHS.APPLIER_QUEUE}
  >
    <FinalCopy />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.APPLIER_QUEUE_BY_FREE_AGENT}
    key={ROUTE_PATHS.APPLIER_QUEUE_BY_FREE_AGENT}
  >
    <FinalCopy />
  </PrivateRoute>,
]

const GET_APPLICATION = gql`
  query GetApplication($id: ID!, $skipStatus: Boolean!) {
    application(id: $id) {
      id
      ...FinalCopyLeftInfo
      ...FinalCopyRightInfo
    }
  }
  ${FinalCopyLeft.fragments.applicationInfo}
  ${FinalCopyRight.fragments.applicationInfo}
`
