import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { useParams } from 'react-router'
import { Rating } from 'types'
import { DATE_FORMAT, TIMEZONE_MAP, UNKNOWN } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { DEBRIEF_FORM_COPY } from 'views/AddInterviewDebrief/AddInterviewDebrief.constants'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import StarRating from 'components/StarRating/StarRating'
import { Params } from './InterviewDebrief.types'
import { TextArea } from 'components/Inputs'
import Message from 'components/Message'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import VList from 'components/VList'
import { GetDebriefByIdQuery, GetDebriefByIdQueryVariables } from 'generated/graphql'

export default function InterviewDebrief(): React.ReactElement {
  const { interviewDebriefId } = useParams<Params>()
  const { data, loading } = useQuery<
    GetDebriefByIdQuery,
    GetDebriefByIdQueryVariables
  >(GET_DEBRIEF, { variables: { id: interviewDebriefId } })

  const interviewDebrief = data?.interviewDebrief

  if (loading) {
    return <Message vertical message="Loading..." />
  }
  if (!interviewDebrief) {
    return <Message vertical message="Unable to find the interview debrief" />
  }

  return (
    <CenteredBox>
      <Padding vertical={6}>
        <Padding bottom={1.5}>
          <Txt size={24} bold as="h2">
            {getJobInfoLabel(interviewDebrief.interview.interviewCycle)}
          </Txt>
        </Padding>
        <Txt>Interview Debrief for {interviewDebrief.client.name}</Txt>
        <Padding top={4} bottom={2}>
          <VList size={0.5}>
            <InlineValueProp
              value={
                interviewDebrief.interview.scheduledFor
                  ? dayjs(interviewDebrief.interview.scheduledFor.datetime)
                      .tz(
                        TIMEZONE_MAP[
                          interviewDebrief.interview.scheduledFor.timezone
                        ]
                      )
                      .format(DATE_FORMAT.DATE_AND_TIME_AND_TZ)
                  : UNKNOWN.UNKNOWN
              }
              label="Interview Time"
            />
            <InlineValueProp
              value={dayjs(interviewDebrief.createdAt).format(
                DATE_FORMAT.DATE_AND_TIME_AND_TZ
              )}
              label="Debrief Submitted"
            />
          </VList>
        </Padding>
        {interviewDebrief.rating && (
          <StarRating rating={interviewDebrief.rating as Rating} iconSize={20} />
        )}
        <Padding top={6}>
          <VList size={4}>
            <QASection
              prompt={DEBRIEF_FORM_COPY.LIST_QUESTIONS}
              response={interviewDebrief.openEndedInterviewQuestions ?? ''}
            />
            <QASection
              prompt={DEBRIEF_FORM_COPY.WHAT_WENT_WELL}
              response={interviewDebrief.summaryPositives ?? ''}
            />
            <QASection
              prompt={DEBRIEF_FORM_COPY.WHAT_COULD_GO_BETTER}
              response={interviewDebrief.summaryNegatives ?? ''}
            />
            <QASection
              prompt={DEBRIEF_FORM_COPY.THANK_YOU_LABEL}
              response={interviewDebrief.thankYouCopy ?? ''}
            />
            {interviewDebrief.interviewers && (
              <QASection
                prompt={DEBRIEF_FORM_COPY.INTERVIEWERS_LABEL}
                response={interviewDebrief.interviewers}
              />
            )}
            {interviewDebrief.notesOnInterviewers && (
              <QASection
                prompt={DEBRIEF_FORM_COPY.NOTES_ON_INTERVIEWERS_LABEL}
                response={interviewDebrief.notesOnInterviewers}
              />
            )}
            {interviewDebrief.summary && (
              <QASection
                prompt={DEBRIEF_FORM_COPY.SUMMARY_LABEL}
                response={interviewDebrief.summary}
              />
            )}
            {interviewDebrief.caseStudy && (
              <QASection
                prompt={DEBRIEF_FORM_COPY.CASE_STUDY_LABEL}
                response={interviewDebrief.caseStudy}
              />
            )}
          </VList>
        </Padding>
      </Padding>
    </CenteredBox>
  )
}

InterviewDebrief.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.INTERVIEW_DEBRIEF}
    key={ROUTE_PATHS.INTERVIEW_DEBRIEF}
  >
    <InterviewDebrief />
  </PrivateRoute>,
]

type QASectionProps = {
  prompt: string
  response: string
}

function QASection({ prompt, response }: QASectionProps) {
  return (
    <section>
      <TextArea defaultValue={response} disabled rows={5} label={prompt} />
    </section>
  )
}

const GET_DEBRIEF = gql`
  query GetDebriefById($id: ID!) {
    interviewDebrief(id: $id) {
      interviewers
      notesOnInterviewers
      thankYouCopy
      summary
      caseStudy
      rating
      createdAt
      summaryPositives
      summaryNegatives
      openEndedInterviewQuestions
      client {
        id
        name
      }
      interview {
        id
        scheduledFor {
          datetime
          timezone
        }
        interviewCycle {
          id
          employerName
          jobTitle
        }
      }
    }
  }
`
