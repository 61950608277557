/**
 * creates a label for any queue in form of "3 of 10"
 *
 * @param queue array of string ids
 * @param id id of item in queue
 */
export default function getQueueLabel(queue: string[], id: string): string {
  const placeInQueue = queue.indexOf(id) + 1
  return `${placeInQueue} of ${queue.length}`
}
