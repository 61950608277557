export const PAGE_SIZE = 50

export const columns = [
  {
    label: 'Submitted',
    id: 'submitted',
  },
  {
    label: 'Due',
    id: 'due',
  },
  {
    label: 'Type',
    id: 'type',
  },
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Interview Cycle',
    id: 'interviewCycle',
  },
]
