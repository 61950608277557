import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'

type Props = {
  interviewCycleId: string
  clientId: string
}

export default function ClientInterviewCycleExpandButton({
  interviewCycleId,
  clientId,
}: Props): React.ReactElement {
  const detailLink = generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
    clientId,
    interviewCycleId,
  })

  return (
    <>
      <Link to={detailLink}>
        <Icon
          name="Expand"
          height={16}
          width={16}
          primaryFill="text"
          display="block"
        />
      </Link>
    </>
  )
}
