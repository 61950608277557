import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ROUTE_PATHS from 'utils/routePaths'
import { resetState, useChanges } from 'views/Assign/Assign.context'
import AssignFooter from 'views/Assign/AssignFooter'
import Button from 'components/Button'

export default function AssignDossiersFooter(): React.ReactElement {
  const { state, dispatch } = useChanges()

  const [assignTaglines, { loading }] = useMutation(UPDATE_TAGLINE_REQUESTS, {
    // TODO (matthewalbrecht): use update function to handle this
    refetchQueries: ['GetDossierRequestsForAssigning'],
    onCompleted() {
      toast.success(`Assigned dossier request(s)`)
      resetState(dispatch)
    },
    onError() {
      // TODO: (matthewalbrecht) handle error better
      toast.error('There was an error assigning the dossier requests')
    },
  })

  const shouldMutate = Object.keys(state.updates).length

  function handleSubmit() {
    const updates = Object.entries(state.updates).map(
      ([dossierRequestId, { assigneeId, due }]) => ({
        dossierRequestId,
        assigneeId,
        due,
      })
    )

    void assignTaglines({ variables: { UpdateDossierRequestsInput: { updates } } })
  }

  const disableSubmit = !shouldMutate || loading

  return (
    <AssignFooter>
      <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
        Cancel
      </Button>
      <Button $type="accept" onClick={handleSubmit} disabled={disableSubmit}>
        Submit
      </Button>
    </AssignFooter>
  )
}
const UPDATE_TAGLINE_REQUESTS = gql`
  mutation UpdateDossierRequests(
    $UpdateDossierRequestsInput: UpdateDossierRequestsInput!
  ) {
    updateDossierRequests(input: $UpdateDossierRequestsInput) {
      dossierRequests {
        id
        assignee {
          id
          name
        }
        due
      }
    }
  }
`
