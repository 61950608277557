import styled, { css } from 'styled-components/macro'
import { RejectionPositions } from './RejectionButton.types'

export const RejectButtonBox = styled.div`
  position: relative;
`

type OptionsParams = {
  active: boolean
  position: RejectionPositions
}

export const Options = styled.div<OptionsParams>`
  ${({ active }) => !active && 'display: none;'}
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
  padding: ${({ theme }) => theme.rhythm(0.5)};
  position: absolute;
  z-index: 1000;
  ${({ position }) => {
    switch (position) {
      case 'top':
        return css`
          left: 50%;
          top: ${({ theme }) => theme.rhythm(-2)};
          transform: translateY(-100%) translateX(-50%);
        `
      case 'left':
        return css`
          top: 50%;
          left: ${({ theme }) => theme.rhythm(-2)};
          transform: translateX(-100%) translateY(-50%);
        `
    }
  }}
`

export const Option = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  cursor: pointer;
  padding: ${({ theme }) => theme.rhythm(1)};
  white-space: nowrap;
  &:hover {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
`
