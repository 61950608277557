import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactFormEvent, ReactSetState } from 'types'
import { DATE_FORMAT, TIMEZONE_MAP, UNKNOWN } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { ButtonRow } from 'views/ReportApplicationInfo/ReportApplicationInfoStyles'
import {
  COPY,
  DEBRIEF_FORM_COPY,
  DEFAULT_STATE,
  ID,
} from './AddInterviewDebrief.constants'
import { Interview, State, Status } from './AddInterviewDebrief.types'
import Button from 'components/Button'
import { TextArea } from 'components/Inputs'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  RecordInterviewDebriefPublicMutation,
  RecordInterviewDebriefPublicMutationVariables,
} from 'generated/graphql'

type Props = {
  interview: Interview
  setStatus: ReactSetState<Status>
  interviewPublicId: string
}

export default function AddInterviewDebriefForm({
  setStatus,
  interview,
  interviewPublicId,
}: Props): React.ReactElement {
  // state
  const [state, setState] = useState<State>(DEFAULT_STATE)

  // mutations
  const [recordDebrief] = useMutation<
    RecordInterviewDebriefPublicMutation,
    RecordInterviewDebriefPublicMutationVariables
  >(RECORD_DEBRIEF, {
    onCompleted() {
      /* TODO (matthewalbrecht): check success value */
      toast.success(COPY.SUBMIT_SUCCESS)
      setStatus(Status.Success)
    },
    onError() {
      toast.error(COPY.SUBMIT_ERROR)
    },
  })

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<T = string>(value: T, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  /**
   * submits the interview debrief
   * @param event form submission event
   * @returns void
   */
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()

    const variables = {
      RecordInterviewDebriefPublicInput: {
        interviewPublicId,
        ...state,
      },
    }

    void recordDebrief({ variables })
  }

  // derived state
  const reportTimeLink = generatePath(ROUTE_PATHS.REPORT_INTERVIEW_TIME, {
    interviewPublicId,
  })

  const formattedTime = interview.scheduledFor
    ? dayjs(interview.scheduledFor.datetime)
        .tz(TIMEZONE_MAP[interview.scheduledFor.timezone])
        .format(DATE_FORMAT.DATE_AND_TIME_AND_TZ)
    : UNKNOWN.TIME

  return (
    <CenteredBox>
      <form id={ID.FORM} onSubmit={handleSubmit}>
        <Padding bottom={4}>
          <Txt as="h2" bold size={30}>
            Interview Debrief
          </Txt>
          <Padding top={1}>
            <Txt size={18}>{getJobInfoLabel(interview)}</Txt>
          </Padding>
          <Padding top={1}>
            <Txt size={16} color="faGrey4">
              {formattedTime}
            </Txt>
          </Padding>
        </Padding>
        <VList size={4}>
          <TextArea
            label={DEBRIEF_FORM_COPY.LIST_QUESTIONS}
            value={state.openEndedInterviewQuestions}
            onValueChange={(value) =>
              handleInputChange(value, 'openEndedInterviewQuestions')
            }
            required
          />
          <TextArea
            label={DEBRIEF_FORM_COPY.WHAT_WENT_WELL}
            value={state.summaryPositives}
            onValueChange={(value) => handleInputChange(value, 'summaryPositives')}
            required
          />
          <TextArea
            label={DEBRIEF_FORM_COPY.WHAT_COULD_GO_BETTER}
            value={state.summaryNegatives}
            onValueChange={(value) => handleInputChange(value, 'summaryNegatives')}
            required
          />
          <TextArea
            label={DEBRIEF_FORM_COPY.THANK_YOU_LABEL}
            description={DEBRIEF_FORM_COPY.THANK_YOU_DESCRIPTION}
            value={state.thankYouCopy}
            onValueChange={(value) => handleInputChange(value, 'thankYouCopy')}
            required
          />
        </VList>
        <Padding top={6}>
          <ButtonRow>
            <Link to={reportTimeLink}>
              <Button type="button" $type="secondary" fullWidth>
                Rescheduled Interview
              </Button>
            </Link>
            <Padding top={{ mobile: 1, tablet: 0 }}>
              <Button $type="accept" fullWidth>
                Submit
              </Button>
            </Padding>
          </ButtonRow>
        </Padding>
      </form>
    </CenteredBox>
  )
}

const RECORD_DEBRIEF = gql`
  mutation RecordInterviewDebriefPublic(
    $RecordInterviewDebriefPublicInput: RecordInterviewDebriefPublicInput!
  ) {
    recordInterviewDebriefPublic(input: $RecordInterviewDebriefPublicInput) {
      success
    }
  }
`
