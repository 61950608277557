import React from 'react'
import {
  GenericItem,
  GroupedRequests,
  DateSortableObject,
} from './CurrentAssignments.types'
import CurrentAssignmentsByAssignee from './CurrentAssignmentsByAssignee'
import CurrentAssignmentsByDueDate from './CurrentAssignmentsByDueDate'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props<T> = {
  title: string
  requestsByDueDate: GroupedRequests<T>
  requestsByAssignee?: GroupedRequests<T>
}

export default function CurrentAssignmentSection<
  T extends GenericItem & DateSortableObject
>({ title, requestsByDueDate, requestsByAssignee }: Props<T>): React.ReactElement {
  return (
    <section>
      <Padding bottom={3}>
        <Txt as="h2" size={24} bold>
          {title}
        </Txt>
      </Padding>
      <Flex>
        <Padding right={6}>
          <Padding bottom={2}>
            <Txt size={18}>By Due Date</Txt>
          </Padding>
          <CurrentAssignmentsByDueDate<T> groupedRequests={requestsByDueDate} />
        </Padding>
        {requestsByAssignee && (
          <div>
            <Padding bottom={2}>
              <Txt size={18}>By Assignee</Txt>
            </Padding>
            <CurrentAssignmentsByAssignee<T> groupedRequests={requestsByAssignee} />
          </div>
        )}
      </Flex>
    </section>
  )
}
