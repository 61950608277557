import { useQuery, gql } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import { Params } from './AddAndEditSourcingRequest.types'
import AddAndEditSourcingRequestFooter from './AddAndEditSourcingRequestFooter'
import AddAndEditSourcingRequestForm from './AddAndEditSourcingRequestForm'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'
import {
  FreeAgentsForSourcingQuery,
  FreeAgentsForSourcingQueryVariables,
  GetSourcingRequestByIdQuery,
} from 'generated/graphql'

type Props = {
  isEditPage: boolean
}

export default function AddAndEditSourcingRequest({
  isEditPage,
}: Props): JSX.Element {
  const { requestId } = useParams<Params>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { data, loading } = useQuery<
    FreeAgentsForSourcingQuery,
    FreeAgentsForSourcingQueryVariables
  >(GET_FREE_AGENTS_WITH_SEQUENCES)
  const { data: sourcingRequestData, loading: sourcingRequestLoading } =
    useQuery<GetSourcingRequestByIdQuery>(GET_SOURCING_REQUEST, {
      variables: { id: requestId },
      skip: !isEditPage,
    })
  const freeAgents = data?.clients
  const sourcingRequest = sourcingRequestData?.sourcingRequest

  if (loading || sourcingRequestLoading) {
    return <Message vertical message="Loading sourcing request submission form..." />
  }

  // we want to block the render if we havent recieved
  // the freeAgents data for the dropdown or if we are
  // on the edit page and we haven't recieved request data
  if (!freeAgents) {
    return <Message vertical message="Loading sourcing request submission form..." />
  }

  if (isEditPage && !sourcingRequest) {
    return <Message vertical message="No Sourcing request found..." />
  }

  return (
    <ViewBox
      footer={
        <AddAndEditSourcingRequestFooter
          isEditing={isEditPage}
          isSubmitting={isSubmitting}
        />
      }
    >
      <Container noMax>
        <Padding top={6}>
          <AddAndEditSourcingRequestForm
            isEditPage={isEditPage}
            freeAgents={freeAgents}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
            sourcingRequest={sourcingRequest}
          />
        </Padding>
      </Container>
    </ViewBox>
  )
}

AddAndEditSourcingRequest.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_SOURCING_REQUEST}
    key={ROUTE_PATHS.NEW_SOURCING_REQUEST}
  >
    <AddAndEditSourcingRequest isEditPage={false} />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EDIT_SOURCING_REQUEST}
    key={ROUTE_PATHS.EDIT_SOURCING_REQUEST}
  >
    <AddAndEditSourcingRequest isEditPage />
  </PrivateRoute>,
]

// used to fetch all free agents for the new request form
const GET_FREE_AGENTS_WITH_SEQUENCES = gql`
  query FreeAgentsForSourcing {
    clients: allClients {
      ...ClientInfoSourcingRequestForm
    }
  }

  ${AddAndEditSourcingRequestForm.fragments.clientInfo}
`

const GET_SOURCING_REQUEST = gql`
  query GetSourcingRequestById($id: ID!) {
    sourcingRequest(id: $id) {
      id
      numJobs
      client {
        id
      }
      notes
      due
    }
  }
`
