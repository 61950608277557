import React from 'react'
import styled from 'styled-components/macro'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  headerCopy: string
  children: React.ReactElement
  formId: string
}

const SettingsCard = styled.section`
  border-radius: ${({ theme }) => theme.borderRadius};
  box-shadow: ${({ theme }) => theme.boxShadow.primary};
  padding: ${({ theme }) => theme.rhythm(3)};
  background-color: ${({ theme }) => theme.color.white};
`

export default function ClientSettingsSection({
  headerCopy,
  children,
  formId,
}: Props): React.ReactElement {
  return (
    <SettingsCard>
      <Padding bottom={2}>
        <Flex align="center" justify="space-between">
          <Txt as="h2" size={22}>
            {headerCopy}
          </Txt>
          <Button
            as="input"
            type="submit"
            $type="accept"
            value="Update"
            form={formId}
          />
        </Flex>
      </Padding>
      {children}
    </SettingsCard>
  )
}
