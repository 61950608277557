import React from 'react'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { SourcingRequestByClient } from './SourcerReview.types'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  clientRowData: SourcingRequestByClient
}

export default function SourcerReviewRow({
  clientRowData,
}: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Padding left={2}>
          <Txt as="span" size={14}>
            {clientRowData.clientName}
          </Txt>
        </Padding>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {clientRowData.sourcingsRequested}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {clientRowData.sourcingsSubmitted}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {clientRowData.sourcingsApproved}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {clientRowData.sourcingsRejected}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
