import { gql, useQuery } from '@apollo/client'
import React, { useMemo } from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import { getAutoSourcingStats } from './AutoSourcingDashboard.helpers'
import AutoSourcingDashboardTable from './AutoSourcingDashboardTable'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import { GetClientsAutoSourcingQuery } from 'generated/graphql'

export default function AutoSourcingDashboard(): React.ReactElement {
  const { data, loading } = useQuery<GetClientsAutoSourcingQuery>(
    GET_CLIENTS_AUTO_SOURCING
  )
  const clients = data?.clients
  const stats = useMemo(() => clients && getAutoSourcingStats(clients), [clients])

  return (
    <ViewBox>
      <Padding top={6} bottom={4}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            Auto Sourcing
          </Txt>
          <Padding top={2}>
            <VList>
              <InlineValueProp
                value={stats?.a}
                label="Group A Total Jobs"
                maxWidth={80}
              />
              <InlineValueProp
                value={stats?.b}
                label="Group B Total Jobs"
                maxWidth={80}
              />
            </VList>
          </Padding>
        </Container>
      </Padding>
      <AutoSourcingDashboardTable clients={clients} loading={loading} />
    </ViewBox>
  )
}

AutoSourcingDashboard.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AUTO_SOURCING_DASHBOARD}
    key={ROUTE_PATHS.AUTO_SOURCING_DASHBOARD}
  >
    <AutoSourcingDashboard />
  </PrivateRoute>,
]

const GET_CLIENTS_AUTO_SOURCING = gql`
  query GetClientsAutoSourcing {
    clients: allClients {
      id
      name
      autoSourcingsWeekly
      autoSourcingAssignee {
        id
        name
      }
      talentAgent {
        id
        name
      }
      autoSourcingAssignmentGroup
    }
  }
`
