import React from 'react'
import Button from 'components/Button'

export default function AgentActivitiesNav(): JSX.Element {
  return (
    <>
      <Button $type="link" active>
        Activity Log
      </Button>
      {/* TODO: add these buttons back when filtering is available */}
      {/* <Button $type="link">All Candidates</Button>
      <Button $type="link">Filter by TA</Button> */}
    </>
  )
}
