import React from 'react'
import { Link } from 'react-router-dom'
import { QS, ROLE_GROUPS } from 'utils/constants'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import { ShelfButton } from 'components/Shelf/Shelf.styles'

type Props = {
  clientId?: string
}

export default function NewTaglineRequestButton({
  clientId,
}: Props): JSX.Element | null {
  const showButton = checkRole(ROLE_GROUPS.CORE_TEAM)
  const searchParams = clientId
    ? `?${createSearchParams({
        [QS.CLIENT_ID]: clientId,
      })}`
    : ''

  if (showButton) {
    return (
      <Link to={`${ROUTE_PATHS.NEW_TAGLINE_REQUEST}${searchParams}`}>
        <ShelfButton>New Tagline Request</ShelfButton>
      </Link>
    )
  }

  return null
}
