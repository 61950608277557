import { ColumnMap, DropdownOption } from 'types'
import { DateFilterPeriod, StatType } from './TalentAgentStats.types'

export const columns: ColumnMap = {
  checkBox: {
    label: '',
    id: 'checkBox',
    collapse: true,
  },
  freeAgent: {
    label: 'Free Agent',
    id: 'freeAgent',
    sortable: true,
  },
  signDate: {
    label: 'Sign Date',
    id: 'signDate',
    sortable: true,
  },
  appsSubmitted: {
    label: 'Apps Submitted',
    id: 'appsSubmitted',
    sortable: true,
  },
  interviewCyclesActive: {
    label: 'Active Interview Cycles',
    id: 'interviewCyclesActive',
    sortable: true,
  },
  sourcedJobsAvgRating: {
    label: 'Sourced Jobs Avg Ratings',
    id: 'sourcedJobsAvgRating',
    sortable: true,
  },
}

export const DATE_FILTERING_OPTIONS = [
  {
    label: 'Last 7 Days',
    value: DateFilterPeriod.LAST_7_DAYS,
  },
  {
    label: 'Last 30 Days',
    value: DateFilterPeriod.LAST_30_DAYS,
  },
  {
    label: 'Last 90 Days',
    value: DateFilterPeriod.LAST_90_DAYS,
  },
]

export const STATS_OPTIONS: DropdownOption[] = [
  { label: 'Apps Submitted', value: StatType.APPS_SUBMITTED },
  { label: 'Interview Cycles Started', value: StatType.INTERVIEW_CYCLES_STARTED },
  {
    label: 'Sourced Jobs Avg Ratings',
    value: StatType.SOURCED_JOBS_AVERAGE_RATINGS,
  },
]

export const DATE_OPTION_TO_COUNT = {
  [DateFilterPeriod.LAST_7_DAYS]: 7,
  [DateFilterPeriod.LAST_30_DAYS]: 30,
  [DateFilterPeriod.LAST_90_DAYS]: 90,
}

export const DateFilterOptionsMap = {
  [DateFilterPeriod.LAST_7_DAYS]: 'Last 7 Days',
  [DateFilterPeriod.LAST_30_DAYS]: 'Last 30 Days',
  [DateFilterPeriod.LAST_90_DAYS]: 'Last 90 Days',
}

export const StatOptionsMap = {
  [StatType.APPS_SUBMITTED]: 'Apps Submitted',
  [StatType.INTERVIEW_CYCLES_STARTED]: 'Interview Cycles Started',
  [StatType.SOURCED_JOBS_AVERAGE_RATINGS]: 'Sourced Jobs Avg Ratings',
}
