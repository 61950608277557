import * as React from 'react'
import styled from 'styled-components/macro'

type Props = {
  size?: number
  rowGapSize?: number
  as?: React.ElementType
  children?: React.ReactNode
}

const HList = styled.ul<Props>`
  display: flex;
  flex-wrap: wrap;
  column-gap: ${({ theme, size = 1 }) => theme.rhythm(size)};
  row-gap: ${({ theme, rowGapSize = 1 }) => theme.rhythm(rowGapSize)};

  & > * {
    display: inline-block;
  }
`

export default HList
