import React from 'react'
import { Line } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  title: string
}

export default function DashboardCardTitle({ title }: Props): React.ReactElement {
  return (
    <>
      <Padding vertical={2.5} horizontal={3}>
        <Txt color="darkGray" size={18} bold>
          {title}
        </Txt>
      </Padding>
      <Padding horizontal={3}>
        <Line color="lightGray" />
      </Padding>
    </>
  )
}
