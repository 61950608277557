import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import TableBody from 'components/Table/TableBody'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS_BY_WORKER } from './SourcerTable.constants'
import { buildRowDataByAssignee, getNumJobsSum } from './SourcerTable.helpers'
import { GetAssignedSourcingRequestsQuery } from 'generated/graphql'

export default function SourcerTableByAssignee(): JSX.Element {
  const { data } = useQuery<GetAssignedSourcingRequestsQuery>(
    GET_ASSIGNED_APPLICATIONS
  )
  const rowData =
    data?.sourcingRequests && buildRowDataByAssignee(data.sourcingRequests)

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS_BY_WORKER.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData?.map(({ assigneeId, assigneeName, sourcingRequests }) => (
          <tr key={assigneeId}>
            <TableBodyItem first content={assigneeName} />
            <TableBodyItem content={getNumJobsSum(sourcingRequests)} />
            <TableBodyActionList>
              {sourcingRequests.length && (
                <TableBodyAction
                  to={generatePath(ROUTE_PATHS.SOURCING_QUEUE, {
                    sourcerId: assigneeId,
                    requestId: INITIAL,
                  })}
                  content="Queue"
                />
              )}
            </TableBodyActionList>
          </tr>
        ))}
      </TableBody>
      {rowData && !rowData.length && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}

/**
 * used by sourcers to get all of their sourcingRequests
 */
const GET_ASSIGNED_APPLICATIONS = gql`
  query getAssignedSourcingRequests {
    sourcingRequests(isAssigned: true, isOpen: true) {
      edges {
        node {
          id
          numJobs
          assignee {
            id
            name
          }
        }
      }
    }
  }
`
