import { AutoSourcingStats, Clients } from './AutoSourcingDashboard.types'
import { AutoSourcingAssignmentGroup } from 'generated/graphql'

/**
 * returns number of jobs to be sourced for each group
 * @param clients array of clients with autosourcing information
 */
export function getAutoSourcingStats(clients: Clients): AutoSourcingStats {
  const DEFAULT_STATS = {
    a: 0,
    b: 0,
  }

  return clients.reduce((stats, client) => {
    const numJobs = Number(client.autoSourcingsWeekly ?? 0)
    if (client.autoSourcingAssignmentGroup === AutoSourcingAssignmentGroup.A) {
      stats.a += numJobs
    } else {
      stats.b += numJobs
    }

    return stats
  }, DEFAULT_STATS)
}
