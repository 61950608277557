import { gql } from '@apollo/client'
import React from 'react'
import { ReactSetState } from 'types'
import { DOSSIER_TYPE_MAP } from 'utils/constants'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import { JobDescriptionDialogButton } from 'components/JobDescriptionDialog/JobDescriptionDialog'
import JobHeading from 'components/JobHeading/JobHeading'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import { DossierRequest, State } from './DossierSubmission.types'
import DossierSubmissionForm from './DossierSubmissionForm'
import ExternalLink from 'components/ExternalLink'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  dossierRequest: DossierRequest | null | undefined
  state: State
  setState: ReactSetState<State>
  nextRouteUrl: string | null
}

function DossierSubmissionRight({
  dossierRequest,
  state,
  setState,
  nextRouteUrl,
}: Props): React.ReactElement {
  // This is used by the skeleton wrappers as whether to show the loading SVG
  // or to show the component, if dossierRequest is undefined then we haven't
  // recieved a response yet
  const dossierRequestExists = dossierRequest !== undefined
  const isDossierUpdate = Boolean(dossierRequest?.priorDossierSubmission?.fileUrl)
  return (
    <VList size={4}>
      <RectangleSkeletonWrap
        height={40}
        fullWidth
        width="100%"
        withViewBox={false}
        showContent={dossierRequestExists}
      >
        {dossierRequest && (
          <Txt bold size={20} lineHeight={1.3}>
            <JobHeading
              jobTitle={dossierRequest.interviewCycle.jobTitle}
              company={{ name: dossierRequest.interviewCycle.employerName }}
              huntrUrl={dossierRequest.interviewCycle.huntrUrl}
            />
          </Txt>
        )}
      </RectangleSkeletonWrap>
      {dossierRequest?.interviewCycle.sourcedJob && (
        <JobDescriptionDialogButton
          sourcedJobId={dossierRequest.interviewCycle.sourcedJob.id}
        />
      )}
      <div>
        <InlineValueProp
          label="Dossier Type"
          value={
            dossierRequest &&
            `${DOSSIER_TYPE_MAP[dossierRequest.dossierType]}${
              isDossierUpdate ? ' (Update)' : ''
            }`
          }
        />

        {dossierRequest?.priorDossierSubmission?.fileUrl && (
          <Padding top={1}>
            <ExternalLink url={dossierRequest.priorDossierSubmission.fileUrl}>
              Previous Dossier
            </ExternalLink>
          </Padding>
        )}
      </div>
      <RectangleSkeletonWrap
        width={180}
        height={16}
        showContent={dossierRequestExists}
      >
        <TextArea
          defaultValue={dossierRequest?.notes ?? ''}
          label="Assignment Notes"
          height={80}
          disabled
        />
      </RectangleSkeletonWrap>

      {dossierRequest && (
        <DossierSubmissionForm
          state={state}
          setState={setState}
          dossierRequest={dossierRequest}
          nextRouteUrl={nextRouteUrl}
        />
      )}
    </VList>
  )
}

DossierSubmissionRight.fragments = {
  DossierSubmissionRightInfo: gql`
    fragment DossierSubmissionRightInfo on DossierRequest {
      id
      dossierType
      notes
      priorDossierSubmission {
        id
        fileUrl
      }
      interviewCycle {
        id
        huntrUrl
        jobTitle
        employerName
        sourcedJob {
          id
        }
      }
    }
  `,
}

export default DossierSubmissionRight
