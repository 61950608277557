import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { Rating } from 'types'
import { UNKNOWN } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import StarRating from 'components/StarRating/StarRating'
import { CompanyDossierSubmissionColumns } from '../CompanyDetail.constants'
import { Company, DossierSubmission } from '../CompanyDetail.types'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'
import {
  GetCompanyDossierSubmissionsQuery,
  GetCompanyDossierSubmissionsQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

const normalizeData = (
  data: NonNullable<GetCompanyDossierSubmissionsQuery['company']>
): DossierSubmission[] => {
  return getNodes<DossierSubmission>(data.dossierSubmissions)
    .map((item) => ({ ...item, createdAtDayjs: dayjs(item.createdAt) }))
    .sort((a, b) => sortByProp(a, b, 'createdAtDayjs'))
}

export default function CompanyDossierSubmissions({
  company,
}: Props): React.ReactElement {
  const { data, error, loading } = useQuery<
    GetCompanyDossierSubmissionsQuery,
    GetCompanyDossierSubmissionsQueryVariables
  >(GET_COMPANY_DOSSIER_SUBMISSIONS, {
    variables: { id: company.id },
  })

  const items = data?.company ? normalizeData(data.company) : null

  return (
    <FullWidthTable
      headers={Object.values(CompanyDossierSubmissionColumns)}
      isError={Boolean(error)}
      isLoading={loading}
      isEmpty={Boolean(items && !items.length)}
      emptyMessage={`Could not find any dossier submissions`}
      loadingMessage={`Loading dossier submissions...`}
      errorMessage={`There was an error fetching the dossier submissions`}
    >
      {items?.map((item) => (
        <CompanyDetailDossierSubmissionRow key={item.id} item={item} />
      ))}
    </FullWidthTable>
  )
}

type DossierSubmissionProps = { item: DossierSubmission }

function CompanyDetailDossierSubmissionRow({
  item,
}: DossierSubmissionProps): React.ReactElement {
  const review = item.reviews[0]
  return (
    <BodyRow>
      <BodyData>
        <Txt size={14} as="span">
          {item.jobTitle ?? UNKNOWN.JOB_TITLE}
        </Txt>
      </BodyData>
      <BodyData>
        {review ? (
          <StarRating disabled rating={review.rating as Rating} iconSize={16} />
        ) : (
          <Txt size={14} as="span">
            No Review
          </Txt>
        )}
      </BodyData>
      <BodyData>
        <Txt size={14} as="span" noWrap>
          {item.fileUrl ? (
            <ExternalLink url={item.fileUrl}>Dossier Link</ExternalLink>
          ) : (
            'No Link'
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {item.rejectionReason ? 'Yes' : 'No'}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_COMPANY_DOSSIER_SUBMISSIONS = gql`
  query GetCompanyDossierSubmissions($id: ID!) {
    company(id: $id) {
      id
      dossierSubmissions {
        edges {
          node {
            id
            createdAt
            fileUrl
            jobTitle
            rejectionReason
            reviews {
              id
              rating
            }
          }
        }
      }
    }
  }
`
