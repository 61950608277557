import React from 'react'
import {
  DashboardGrid,
  GridArea1,
  GridArea2,
  GridArea3,
  GridArea4,
  GridArea5,
  GridArea6,
} from 'components/DashboardCard/DashboardCard.styles'
import DashboardCardClientsActivity from 'components/DashboardCard/DashboardCardClientsActivity'
import DashboardCardInterviewsNextWeek from 'components/DashboardCard/DashboardCardInterviewsNextWeek'
import DashboardCardInterviewsThisWeek from 'components/DashboardCard/DashboardCardInterviewsThisWeek'
import DashboardCardInterviewsToday from 'components/DashboardCard/DashboardCardInterviewsToday'
import DashboardCardStageChanges from 'components/DashboardCard/DashboardCardStageChanges'
import DashboardCardTrialClients from 'components/DashboardCard/DashboardCardTrialClients'
import Container from 'components/Container'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

type Props = {
  talentAgentId: string
  userName?: string | null
}

export default function TalentAgentDashboard({
  talentAgentId,
  userName,
}: Props): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={1}>
          <Txt as="h2" color="darkGray" size={28} bold>
            {userName ? `${userName} Dashboard` : `Clients Overview`}
          </Txt>
        </Padding>
        <Padding vertical={4}>
          <DashboardGrid>
            <GridArea1>
              <DashboardCardInterviewsToday talentAgentId={talentAgentId} />
            </GridArea1>
            <GridArea2>
              <DashboardCardInterviewsNextWeek talentAgentId={talentAgentId} />
            </GridArea2>
            <GridArea3>
              <DashboardCardTrialClients talentAgentId={talentAgentId} />
            </GridArea3>
            <GridArea4>
              <DashboardCardInterviewsThisWeek talentAgentId={talentAgentId} />
            </GridArea4>
            <GridArea5>
              <DashboardCardStageChanges talentAgentId={talentAgentId} />
            </GridArea5>
            <GridArea6>
              <DashboardCardClientsActivity talentAgentId={talentAgentId} />
            </GridArea6>
          </DashboardGrid>
        </Padding>
      </Container>
    </ViewBox>
  )
}
