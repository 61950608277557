import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { FIRST } from './ClientInterviewCycles.constants'
import { buildTableData } from './ClientInterviewCycles.helpers'
import InterviewCyclesTable from './ClientInterviewCyclesTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import ViewBox from 'components/ViewBox'
import {
  GetInterviewCyclesQuery,
  GetInterviewCyclesQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientInterviewCycles({
  clientId,
}: Props): React.ReactElement {
  const { data, fetchMore, loading } = useQuery<
    GetInterviewCyclesQuery,
    GetInterviewCyclesQueryVariables
  >(GET_INTERVIEW_CYCLES, {
    variables: {
      clientId: clientId,
      first: FIRST,
    },
  })

  if (loading) {
    return <Message vertical message="Loading data..." />
  }

  const tableData = data?.interviewCycles ? buildTableData(data.interviewCycles) : []

  /**
   * fetches additional interview cycles when load more button is clicked
   */
  function handleLoadMore() {
    void fetchMore({
      variables: {
        first: FIRST,
        after: endCursor || undefined,
      },
    })
  }

  const { pageInfo } = data?.interviewCycles || {}
  const { hasNextPage, endCursor } = pageInfo || {}
  const showLoadMoreButton = hasNextPage

  return (
    <ViewBox unsetHeight>
      <Padding top={1}>
        <InterviewCyclesTable tableData={tableData} clientId={clientId} />
        {!tableData.length && !hasNextPage && (
          <Message message="No Interview Cycles to show" vertical />
        )}
      </Padding>
      {showLoadMoreButton && (
        <Padding top={4}>
          <Container noMax>
            <Flex justify="center">
              <Button onClick={handleLoadMore}>Load More</Button>
            </Flex>
          </Container>
        </Padding>
      )}
    </ViewBox>
  )
}

const GET_INTERVIEW_CYCLES = gql`
  query GetInterviewCycles($clientId: ID!, $first: Int, $after: String) {
    interviewCycles(clientId: $clientId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        cursor
        node {
          id
          huntrUrl
          employerName
          jobTitle
          source
          createdAt
          startDate
          inactiveAt
        }
      }
    }
  }
`
