import { lighten, darken } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { DropdownOption, KeyValue, ReactChangeEvent } from 'types'
import Button from 'components/Button'
import HList from 'components/HList'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'
import Txt from 'components/Txt'

type Props = {
  name: string
  label?: string
  defaultValue?: KeyValue<boolean>
  options: DropdownOption[]
  required?: boolean
  value?: KeyValue<boolean>
  onValueChange?: (value: KeyValue<boolean>) => void
  loadingOptions?: boolean
  loadingMessage?: string
  emptyOptionsMessage?: string
  description?: string
  disabled?: boolean
}

const Checkbox = styled.input`
  position: absolute;
  opacity: 0 !important;
  cursor: pointer;
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  z-index: -1;

  &:disabled ~ div {
    cursor: default;
    opacity: 0.7;
  }

  &:checked {
    ~ div {
      background-color: ${({ theme }) => theme.color.faBlue};
      border: 0.1rem solid ${({ theme }) => theme.color.faBlue};
      color: ${({ theme }) => theme.color.white};
    }
  }
  &:hover:not(:disabled) {
    ~ div {
      background-color: ${({ theme }) => darken(0.1, theme.color.white)};
    }
    &:checked ~ div {
      background-color: ${({ theme }) => lighten(0.1, theme.color.faBlue)};
      border: 0.1rem solid ${({ theme }) => lighten(0.1, theme.color.faBlue)};
    }
  }
`

const CheckboxWrapper = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
`

export function CheckboxGroup({
  name,
  label,
  options,
  value,
  defaultValue,
  description,
  required,
  onValueChange,
  loadingMessage,
  emptyOptionsMessage,
  loadingOptions = false,
  disabled = false,
}: Props): JSX.Element {
  function handleChange(event: ReactChangeEvent) {
    const selectedValue = event.currentTarget.value
    const selectedChecked = event.currentTarget.checked
    const newValue = { ...value, [selectedValue]: selectedChecked }

    onValueChange?.(newValue)
  }

  return (
    <div>
      {label && <Label content={label} />}
      {description && <InputDescription description={description} />}
      <HList size={1} rowGapSize={1}>
        {options.map((option) => (
          <CheckboxWrapper key={option.value}>
            <Checkbox
              onChange={handleChange}
              type="checkbox"
              name={`${name}-${option.value}`}
              value={option.value}
              defaultChecked={
                defaultValue !== undefined
                  ? Boolean(defaultValue[option.value])
                  : undefined
              }
              checked={
                value !== undefined ? Boolean(value[option.value]) : undefined
              }
              required={required}
              disabled={disabled}
            />
            <Button as="div" small>
              {option.label}
            </Button>
          </CheckboxWrapper>
        ))}
        {!options.length && !loadingOptions && (
          <Txt color="error" italic size={14} lineHeight={1.3}>
            {emptyOptionsMessage || 'No options found.'}
          </Txt>
        )}
        {loadingOptions && loadingMessage && (
          <Txt color="faGrey4" italic size={14} lineHeight={1.3}>
            {loadingMessage}
          </Txt>
        )}
      </HList>
    </div>
  )
}
