import { useMutation, gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { QS, TIMEZONE_MAP, DATE_FORMAT } from 'utils/constants'
import { InterviewRejectionReason } from 'utils/enums'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import Icon from 'components/Icon/Icon'
import { COPY } from './InterviewCycleDetail.constants'
import { getInterviewStatus } from './InterviewCycleDetail.helpers'
import {
  Interview,
  InterviewDebrief,
  InterviewStatus,
} from './InterviewCycleDetail.types'
import Button from 'components/Button'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'
import {
  DatetimeWithTz,
  DeclineInterviewPublicMutation,
  DeclineInterviewPublicMutationVariables,
} from 'generated/graphql'

type Props = {
  interview: Interview
  pathname: string
  interviewNumber: number
}

export default function InterviewCycleDetailInterview({
  interview,
  pathname,
  interviewNumber,
}: Props): React.ReactElement {
  const [status, color] = getInterviewStatus(interview)
  const showCancelButton =
    status !== InterviewStatus.Canceled &&
    status !== InterviewStatus.DebriefSubmitted
  const editInterviewPath = generatePath(
    `${ROUTE_PATHS.EDIT_INTERIVEW_FORM}?${createSearchParams({
      [QS.CALLBACK]: pathname,
    })}`,
    {
      interviewId: interview.id,
    }
  )
  return (
    <React.Fragment key={interview.id}>
      <Txt size={13}>{interviewNumber}</Txt>
      <Link to={editInterviewPath}>
        {interview.scheduledFor ? (
          <ScheduledTime scheduledFor={interview.scheduledFor} />
        ) : (
          <Button small $type="primary" noWrap>
            Report Time
          </Button>
        )}
      </Link>
      <Tag color={color} small>
        <Txt size={13} noWrap alignment="center">
          {status}
        </Txt>
      </Tag>
      <div />
      {interview.debriefs[0] ? (
        <Debrief debrief={interview.debriefs[0]} />
      ) : (
        <Link
          to={generatePath(ROUTE_PATHS.ADD_INTERVIEW_DEBRIEF, {
            interviewPublicId: interview.publicId,
          })}
        >
          <Button small $type="primary" noWrap>
            Add Debrief
          </Button>
        </Link>
      )}
      <div>{showCancelButton && <CancelInterview interview={interview} />}</div>
    </React.Fragment>
  )
}

type DebriefProps = {
  debrief: InterviewDebrief
}

function Debrief({ debrief }: DebriefProps) {
  return (
    <Link
      to={generatePath(ROUTE_PATHS.INTERVIEW_DEBRIEF, {
        interviewDebriefId: debrief.id,
      })}
      key={debrief.id}
    >
      <Txt size={13} hoverColor="black" underline>
        Debrief
      </Txt>
    </Link>
  )
}

type ScheduledForProps = {
  scheduledFor: DatetimeWithTz
}

function ScheduledTime({ scheduledFor }: ScheduledForProps): React.ReactElement {
  const localizedDatetime = dayjs(scheduledFor.datetime)
    .tz(TIMEZONE_MAP[scheduledFor.timezone])
    .format(DATE_FORMAT.DATE_AND_TIME_AND_TZ)
  return (
    <Txt size={13} lineHeight={1.3} underline hoverColor="black" noWrap>
      {localizedDatetime}
    </Txt>
  )
}

type CancelInterviewProps = {
  interview: Interview
}

function CancelInterview({ interview }: CancelInterviewProps) {
  const [declineInterviewPublic] = useMutation<
    DeclineInterviewPublicMutation,
    DeclineInterviewPublicMutationVariables
  >(DECLINE_INTERVIEW, {
    onCompleted() {
      /* TODO (matthewalbrecht): check success value */
      toast.success(COPY.DECLINE_SUCCESS)
    },
    onError() {
      toast.error(COPY.DECLINE_ERROR)
    },
  })
  function handleCancelInterview() {
    if (window.confirm(COPY.CONFIRM_CANCEL)) {
      void declineInterviewPublic({
        variables: {
          DeclineInterviewPublicInput: {
            interviewPublicId: interview.publicId,
            rejectionReason: InterviewRejectionReason.STAFF_CANCELED,
          },
        },
      })
    }
  }

  return (
    <button onClick={handleCancelInterview}>
      <Icon
        name="Cancel"
        width={16}
        height={16}
        primaryFill="text"
        primaryFillHover="black"
      />
    </button>
  )
}

const DECLINE_INTERVIEW = gql`
  mutation DeclineInterviewPublic(
    $DeclineInterviewPublicInput: DeclineInterviewPublicInput!
  ) {
    declineInterviewPublic(input: $DeclineInterviewPublicInput) {
      success
    }
  }
`
