import React from 'react'
import {
  avgRatingCopy,
  oneAndTwosRateCopy,
  rateAsPctCopy,
} from 'utils/helpers/stats'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { GROUP_BY_TYPES } from './SourcerStats.constants'
import { GroupByOptions, SourcerStatsValues } from './SourcerStats.types'
import Txt from 'components/Txt'

type Props = {
  rowData: SourcerStatsValues
  groupBy: GroupByOptions
}

export default function SourcerStatsRow({
  rowData,
  groupBy,
}: Props): React.ReactElement {
  return (
    <BodyRow>
      {groupBy === GROUP_BY_TYPES.CLIENT_ID && (
        <BodyData>
          <Txt as="span" size={14}>
            {rowData.clientName}
          </Txt>
        </BodyData>
      )}
      {groupBy === GROUP_BY_TYPES.TALENT_AGENT_ID && (
        <BodyData>
          <Txt as="span" size={14}>
            {rowData.talentAgentName}
          </Txt>
        </BodyData>
      )}
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.sourcerName}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {avgRatingCopy(rowData.avgRating, rowData.avgRatingTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {oneAndTwosRateCopy(rowData.onesAndTwosRate, rowData.avgRatingTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rateAsPctCopy(rowData.rejectionRate, rowData.total)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
