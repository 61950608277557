import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { DropdownOption } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import groupAssignItems, {
  GenericGroupedRowData,
} from 'utils/helpers/groupAssigItems'
import { sortByProp } from 'utils/sort'
import {
  GetTaglineRequestsByStatusQuery,
  GetTaglineRequestsByStatusQueryVariables,
  GetWritersQuery,
  TaglineRequestStatus,
} from 'generated/graphql'

type ReturnType = {
  groupedRowData: GenericGroupedRowData[]
  assigneeOptions: DropdownOption[]
}

export default function useAssignApplicationData(
  isReassigning: boolean
): ReturnType {
  const status = isReassigning
    ? TaglineRequestStatus.PENDING_WRITING
    : TaglineRequestStatus.UNASSIGNED

  // TODO: (matthewalbrecht) use update function to avoid making multiple network requests on status changes
  const { data: taglineRequestsData } = useQuery<
    GetTaglineRequestsByStatusQuery,
    GetTaglineRequestsByStatusQueryVariables
  >(GET_TAGLINE_REQUESTS_BY_STATUS, {
    variables: {
      status,
    },
  })

  const taglineRequestsRaw =
    taglineRequestsData?.taglineRequests &&
    getNodes(taglineRequestsData.taglineRequests)

  // TODO: (matthewalbrecht) only get writers
  const { data: writerData } = useQuery<GetWritersQuery>(GET_WRITERS)

  const taglineRequests = taglineRequestsRaw?.map((taglineRequest) => ({
    ...taglineRequest,
    sortableDue: dayjs(taglineRequest.due),
    sortableCreatedAt: dayjs(taglineRequest.createdAt),
    clientName: taglineRequest.client.name,
    assigneeId: taglineRequest.assignee?.id,
  }))

  // this restructures the data to fit our nested table row structure
  // we show client or writer and then all of their tagline requests
  const groupedRowData = taglineRequests
    ? groupAssignItems(taglineRequests, isReassigning)
    : []

  // sort the requests by clientName-due
  groupedRowData.forEach((group) => {
    group.items.sort((a, b) => sortByProp(a, b, 'clientName', 'sortableDue'))
  })

  // sort groups by soonest due
  groupedRowData.sort((a, b) => sortByProp(a.items[0], b.items[0], 'sortableDue'))

  const assigneeOptions: DropdownOption[] = buildDropdownOptions(
    writerData?.users || []
  )

  return { groupedRowData, assigneeOptions }
}

/**
 * Use to query all tagline request by request
 */
export const GET_TAGLINE_REQUESTS_BY_STATUS = gql`
  query GetTaglineRequestsByStatus($status: TaglineRequestStatus!) {
    taglineRequests(status: $status) {
      edges {
        node {
          id
          jobTitle
          employerName
          due
          createdAt
          isImportant
          client {
            id
            name
            roleCategory
            roleExperience
            talentAgent {
              id
              name
            }
          }
          # assignee's name is only applicable for re-assigning
          assignee {
            id
            name
          }
        }
      }
    }
  }
`

/**
 * used to get all writers
 */
export const GET_WRITERS = gql`
  query GetWriters {
    users(anyRole: { isWriter: true }) {
      id
      name
    }
  }
`
