import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { DropdownOption } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import groupAssignItems, {
  GenericGroupedRowData,
} from 'utils/helpers/groupAssigItems'
import { sortByProp } from 'utils/sort'
import {
  GetFinalReviewsForAssigningQuery,
  GetFinalReviewsForAssigningQueryVariables,
  GetWritersQuery,
} from 'generated/graphql'

type ReturnType = {
  groupedRowData: GenericGroupedRowData[]
  assigneeOptions: DropdownOption[]
}

const TYPE_FINAL_REVIEWER = 'finalReviewer'

export default function useAssignFinalReviewsData(
  isReassigning: boolean
): ReturnType {
  const { data } = useQuery<
    GetFinalReviewsForAssigningQuery,
    GetFinalReviewsForAssigningQueryVariables
  >(GET_FINAL_REVIEWS_FOR_ASSIGNING)

  const { data: writerData } = useQuery<GetWritersQuery>(GET_WRITERS)

  const taglineRequests =
    data?.taglineRequests &&
    getNodes(data.taglineRequests).filter((tr) =>
      isReassigning ? true : !tr.finalReviewer
    )

  const finalReviews = taglineRequests?.map((taglineRequest) => ({
    ...taglineRequest,
    sortableDue: dayjs(taglineRequest.due),
    sortableCreatedAt: dayjs(taglineRequest.createdAt),
    clientName: taglineRequest.client.name,
  }))

  // this restructures the data to fit our nested table row structure
  // we show client or writer and then all of their tagline requests
  const groupedRowData = finalReviews
    ? groupAssignItems(finalReviews, isReassigning, TYPE_FINAL_REVIEWER)
    : []

  // sort the requests by clientName-due
  groupedRowData.forEach((group) => {
    group.items.sort((a, b) => sortByProp(a, b, 'clientName', 'sortableDue'))
  })

  // sort groups by soonest due
  groupedRowData.sort((a, b) => sortByProp(a.items[0], b.items[0], 'sortableDue'))

  const assigneeOptions: DropdownOption[] = buildDropdownOptions(
    writerData?.users || []
  )

  return { groupedRowData, assigneeOptions }
}

const GET_FINAL_REVIEWS_FOR_ASSIGNING = gql`
  query GetFinalReviewsForAssigning($finalReviewerId: ID) {
    taglineRequests(finalReviewerId: $finalReviewerId, status: PENDING_TP_REVIEW) {
      edges {
        node {
          id
          due
          jobTitle
          employerName
          createdAt
          isImportant
          client {
            id
            name
            roleCategory
            roleExperience
            talentAgent {
              id
              name
            }
          }
          finalReviewer {
            id
            name
          }
        }
      }
    }
  }
`

export const GET_WRITERS = gql`
  query GetWriters {
    users(anyRole: { isWriter: true }) {
      id
      name
    }
  }
`
