import { gql } from '@apollo/client'
import { InterviewCycleInactiveReasonMap } from 'utils/constants'
import { InactiveCycleReasons } from 'utils/enums'
import { InterviewCycleStatusCopyFragment } from 'generated/graphql'

export function getInterviewCycleStatusCopy(
  item: InterviewCycleStatusCopyFragment
): string {
  return item.airtableId
    ? 'In Airtable'
    : item.inactiveReason
    ? `Rejected - ${
        InterviewCycleInactiveReasonMap[item.inactiveReason as InactiveCycleReasons]
      }`
    : 'Open'
}

getInterviewCycleStatusCopy.fragments = {
  interviewCycleStatusCopy: gql`
    fragment InterviewCycleStatusCopy on InterviewCycle {
      airtableId
      inactiveReason
    }
  `,
}
