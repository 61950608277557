import { useQuery, gql } from '@apollo/client'
import React, { useState } from 'react'
import { EMPTY_INPUT } from 'utils/constants'
import { Role } from 'utils/enums'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import UserAddButton from './UserAddButton'
import UserAddCalanderButton from './UserAddCalanderButton'
import UserRow from './UserRow'
import { extendedRoleOptions } from './Users.constants'
import UsersTable from './UsersTable'
import Container from 'components/Container'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { GetAllUsersQuery, Maybe } from 'generated/graphql'

export default function Users(): React.ReactElement {
  const [roleFilter, setRoleFilter] = useState<Maybe<Role>>(null)
  const { data, loading, error } = useQuery<GetAllUsersQuery>(GET_ALL_USERS)

  let nonAdminUsers = data?.users
    ? data.users
        .slice()
        // for the purposes of the list of users we allow coordintaors to modify, we don't want to show admins
        .filter((user) => !user.isAdmin)
        .sort((a, b) => sortByProp(a, b, 'name'))
    : []

  if (roleFilter) {
    nonAdminUsers = nonAdminUsers.filter((user) => user[roleFilter])
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Flex justify="space-between" align="center">
            <Txt bold size={24} as="h2">
              Users
            </Txt>

            <HList size={2}>
              <UserAddCalanderButton users={data?.users ?? []} />
              <UserAddButton />
            </HList>
          </Flex>
        </Padding>
        <Dropdown
          label="Role"
          name="Role"
          value={roleFilter || EMPTY_INPUT}
          onValueChange={(value) => setRoleFilter(value ? (value as Role) : null)}
          placeholder="Select Role"
          options={extendedRoleOptions}
        />
      </Container>
      <Padding top={4}>
        <UsersTable users={nonAdminUsers} loading={loading} error={error} />
      </Padding>
    </ViewBox>
  )
}

Users.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.USERS} key={ROUTE_PATHS.USERS}>
    <Users />
  </PrivateRoute>,
]

const GET_ALL_USERS = gql`
  query GetAllUsers {
    users(withInactive: true) {
      ...UserRowInfo
    }
  }
  ${UserRow.fragments.userRowInfo}
`
