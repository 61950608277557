import React from 'react'
import countWords from 'utils/helpers/countWords'
import Padding from './Padding'
import Txt, { TxtProps } from './Txt'

type Props = {
  label: string
  content: string
  maxWordCount: number
  padding?: number
  txtProps?: TxtProps
}

export default function WordCountLabel({
  label,
  padding,
  content,
  txtProps,
  maxWordCount,
}: Props): React.ReactElement {
  const wordCount = countWords(content)
  return (
    <Padding bottom={padding}>
      <Txt
        as="label"
        color={wordCount > maxWordCount ? 'error' : 'faGrey3'}
        {...txtProps}
      >
        {`${label}: ${wordCount}`}
      </Txt>
    </Padding>
  )
}
