import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'
import { StageChangeType } from './DashboardCard.constants'
import {
  StageChangeDashboardContentRow,
  ClientCompanyContainer,
  LogoContainer,
  StageChangeLine,
} from './DashboardCard.styles'
import { StageChangeInfo } from './DashboardCard.types'
import DashboardCardClientCompanyLogo from './DashboardCardClientCompanyLogo'
import DashboardCardClientHeadshot from './DashboardCardClientHeadshot'
import DashboardCardCompanyLogo from './DashboardCardCompanyLogo'
import DashboardCardJobInfoLabel from './DashboardCardJobInfoLabel'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  stageChangeInfo: StageChangeInfo
  hasMore?: boolean
  isClientDashboard?: boolean
}

export default function DashboardStageChangeCard({
  stageChangeInfo,
  hasMore,
  isClientDashboard,
}: Props): React.ReactElement {
  function renderStageChangeIcon() {
    if (stageChangeInfo.type === StageChangeType.NEW_INTERVIEW_CYCLE) {
      return (
        <LogoContainer color="iconLightBlue">
          <Icon name="FolderPlus" height={20} width={20} primaryFill="iconBlue" />
        </LogoContainer>
      )
    }
    if (stageChangeInfo.type === StageChangeType.NEW_INTERVIEW) {
      return (
        <LogoContainer color="iconLightPurple">
          <Icon name="Calendar" height={18} width={18} primaryFill="iconPurple" />
        </LogoContainer>
      )
    }
    return (
      <LogoContainer color="iconLightGray">
        <Icon name="TimesCircle" height={20} width={20} primaryFill="iconGray" />
      </LogoContainer>
    )
  }

  function getJobInfoLabel() {
    let actionText
    let phraseText
    if (stageChangeInfo.type === StageChangeType.NEW_INTERVIEW_CYCLE) {
      actionText = 'has begun a'
      phraseText = 'new cycle'
    } else if (stageChangeInfo.type === StageChangeType.NEW_INTERVIEW) {
      actionText = 'has advanced to a'
      phraseText = 'new interview'
    } else {
      actionText = 'has had a'
      phraseText = 'cycle closed'
    }
    return (
      <DashboardCardJobInfoLabel
        jobInfo={stageChangeInfo}
        actionText={actionText}
        phraseText={phraseText}
      />
    )
  }

  return (
    <StageChangeDashboardContentRow>
      {hasMore && <StageChangeLine />}
      {renderStageChangeIcon()}
      <Flex align="flex-start">
        {isClientDashboard ? (
          <Padding right={2}>
            <Link
              to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
                clientId: stageChangeInfo.clientId,
                interviewCycleId: stageChangeInfo.interviewCycleId,
              })}
            >
              <DashboardCardCompanyLogo
                height={58}
                width={58}
                src={stageChangeInfo.companyLogo}
                alt={stageChangeInfo.companyName}
              />
            </Link>
          </Padding>
        ) : (
          <ClientCompanyContainer>
            <DashboardCardClientHeadshot
              src={stageChangeInfo.clientHeadshot}
              alt={stageChangeInfo.clientName}
            />
            <DashboardCardClientCompanyLogo
              src={stageChangeInfo.companyLogo}
              alt={stageChangeInfo.companyName}
            />
          </ClientCompanyContainer>
        )}
        <div>
          {getJobInfoLabel()}
          <Padding top={0.75}>
            <Txt color="textLight" size={12}>
              {stageChangeInfo.dateFromNow}
            </Txt>
          </Padding>
        </div>
      </Flex>
    </StageChangeDashboardContentRow>
  )
}
