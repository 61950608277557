import copyToClipboard from 'copy-to-clipboard'
import { MimeType } from 'utils/enums'

export type ClipboardAPI = {
  copy(content: string | undefined, format: MimeType): void
}

type Props = {
  onSuccess?: () => void
}

export default function useCopyClipboard({ onSuccess }: Props): ClipboardAPI {
  return {
    copy(content = '', format) {
      copyToClipboard(content, { format })
      onSuccess?.()
    },
  }
}
