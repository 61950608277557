import { State } from './AddInterviewDebrief.types'

export const ID = {
  FORM: 'interviewDebriefForm',
}

export const DEFAULT_STATE: State = {
  interviewers: '',
  thankYouCopy: '',
  summaryNegatives: '',
  summaryPositives: '',
  openEndedInterviewQuestions: '',
}

export const DEBRIEF_FORM_COPY = {
  INTERVIEWERS_LABEL: 'Name of interviewers',
  INTERVIEWERS_DESCRIPTION: 'Please provide the full names and titles, if possible.',
  NOTES_ON_INTERVIEWERS_LABEL: 'Notes on interviewers',
  NOTES_ON_INTERVIEWERS_DESCRIPTION: 'Please provide notes on the interviewers.',
  THANK_YOU_LABEL:
    '“Thank you” copy (please discuss & coordinate “thank you” strategy with your TA)',
  THANK_YOU_DESCRIPTION:
    'Please provide us some copy to use for thank you messages to the interviewers. This can be an exciting upcoming initiative, a shared interest with the interviewer, or anything in-between.',
  SUMMARY_LABEL: 'Summary',
  SUMMARY_DESCRIPTION:
    'Please provide a summary of the interview: What topics were discussed, tough questions, what went well, etc.',
  RATING_LABEL: 'How did it go?',
  CASE_STUDY_LABEL: 'Case Study',
  CASE_STUDY_DESCRIPTION:
    'If there was a case study, please copy-and-paste the prompt below! If the case study was given in a document or presentation, please provide a link to it as shared Google doc.',
  WHAT_WENT_WELL: 'What went well?',
  WHAT_COULD_GO_BETTER: 'What could have gone better?',
  LIST_QUESTIONS:
    'Please list all the questions asked in the interview, each one on its own line.',
}

export const COPY = {
  SUBMIT_SUCCESS: 'Interview debrief submitted',
  SUBMIT_ERROR: 'There was an error submitting the interview debrief',
  DECLINE_SUCCESS: 'Interview Declined',
  DECLINE_ERROR: 'There was an error declining the interview',
}
