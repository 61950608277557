import { formatDatetimeWithTz } from 'views/AddAndEditInterview/AddAndEditInterview.helpers'
import { DEFAULT_STATE } from './ReportInterviewTime.constants'
import { Interview, State } from './ReportInterviewTime.types'
/**
 * normalizes the scheduledFor data for our component
 * @param interview gql data returned from public query
 * @returns defualt state
 */
export function getDefaultState(interview: Interview): State {
  if (interview.scheduledFor) {
    return {
      datetime: formatDatetimeWithTz(interview.scheduledFor),
      timezone: interview.scheduledFor.timezone,
    }
  }

  return DEFAULT_STATE
}
