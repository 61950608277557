import ROUTE_PATHS from 'utils/routePaths'

export const ASSIGN_DATA = {
  header: 'Available Final Reviews',
  route: ROUTE_PATHS.AVAILABLE_FINAL_REVIEWS,
}
export const REASSIGN_DATA = {
  header: 'Assigned Final Reviews',
  route: ROUTE_PATHS.ASSIGNED_FINAL_REVIEWS,
}

export const COLUMNS = [
  {
    name: '',
    id: 'checkbox',
    collapse: true,
  },
  {
    name: 'Free Agent',
    id: 'freeAgent',
    hideOnAvailable: true,
  },
  {
    name: 'Job Title',
    id: 'jobTitle',
  },
  {
    name: 'Company',
    id: 'company',
  },
  {
    name: 'Submission Date',
    id: 'submittedDate',
    hideOnAssigned: true,
  },
  {
    name: 'Assignment',
    id: 'assignment',
    collapse: true,
  },
]
