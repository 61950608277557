import dayjs from 'dayjs'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  ExpertRequestConnection,
  ExpertRequestNode,
  ExpertRequestSortable,
} from './ClientExpertRequests.types'

export function buildTableData(
  connection: ExpertRequestConnection
): ExpertRequestSortable[] {
  const rawExpertRequests = getNodes<ExpertRequestNode>(connection)

  const expertRequests = rawExpertRequests
    .map((expertRequest) => ({
      ...expertRequest,
      dateToSortBy: dayjs(expertRequest.createdAt),
    }))
    .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))

  return expertRequests
}
