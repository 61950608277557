import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactSetState } from 'types'
import { TOAST } from './NewInterviewCycle.constants'
import { getDefaultState, validateForm } from './NewInterviewCycle.helpers'
import { State } from './NewInterviewCycle.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import { GreyBackground } from 'components/MiscStyles'
import Padding from 'components/Padding'

type Props = {
  isSubmitting: boolean
  state: State
  setState: ReactSetState<State>
  setIsSubmitting: ReactSetState<boolean>
  queryString: URLSearchParams
}

// TODO (matthewalbrecht) Add Button on nav or dashboard for this view

export default function NewInterviewCycleFoooter({
  setState,
  setIsSubmitting,
  state,
  isSubmitting,
  queryString,
}: Props): React.ReactElement {
  const history = useHistory()
  const [createInterviewCycle] = useMutation(CREATE_INTERVIEW_CYCLE, {
    onCompleted() {
      setState(getDefaultState(queryString))
      setIsSubmitting(false)
      toast.success(TOAST.CREATE_SUCCESS)
    },
    onError() {
      setIsSubmitting(false)
      toast.error(TOAST.CREATE_ERROR)
    },
  })

  /**
   * validate and submit new interview cycle
   */
  function handleSubmit() {
    if (!validateForm(state)) {
      return
    }

    setIsSubmitting(true)
    const variables = {
      CreateInterviewCycleInput: state,
    }

    void createInterviewCycle({ variables })
  }

  return (
    <GreyBackground as={Padding} vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button onClick={() => history.go(-1)} $type="secondary">
          Cancel
        </Button>
        <Button $type="accept" disabled={isSubmitting} onClick={handleSubmit}>
          Save
        </Button>
      </Flex>
    </GreyBackground>
  )
}

/**
 * Used to create a new interview cycle
 */
const CREATE_INTERVIEW_CYCLE = gql`
  mutation CreateInterviewCycle(
    $CreateInterviewCycleInput: CreateInterviewCycleInput!
  ) {
    createInterviewCycle(input: $CreateInterviewCycleInput) {
      interviewCycle {
        id
      }
    }
  }
`
