import * as Sentry from '@sentry/react'
import { useEffect } from 'react'
import { useAuthContext } from 'context/auth'

export default function useSentryConfig(): void {
  const { userSession } = useAuthContext()

  useEffect(() => {
    const userId = userSession.user.id
    if (userId) {
      Sentry.setUser({ id: userId, email: userSession.user.email })
    } else {
      Sentry.configureScope((scope) => scope.setUser(null))
    }
  }, [userSession])
}
