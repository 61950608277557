import React from 'react'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'

type Props = {
  TableComponent: React.ElementType
  NavComponent: React.ElementType
  hideQueueButton?: boolean
}

export default function DashboardTableWrapper({
  TableComponent,
  NavComponent,
  hideQueueButton,
}: Props): JSX.Element {
  return (
    <div>
      <Padding bottom={2}>
        <HList size={2}>
          <NavComponent />
        </HList>
      </Padding>
      <Flex align="flex-start" justify="flex-start">
        <TableComponent hideQueueButton={hideQueueButton} />
      </Flex>
    </div>
  )
}
