import { ApolloCache } from '@apollo/client'
import { CacheConnection, DropdownOption, MutationResult } from 'types'
import { CACHE_STRING } from 'utils/cacheString'
import { sortByProp } from 'utils/sort'
import { ClientFragment } from './Clients.constants'
import { ClientFormState } from './Clients.types'
import {
  CreateClientByEmailMutation,
  CreateClientByEmailMutationVariables,
} from 'generated/graphql'

export function buildCreateClientVariables(
  state: ClientFormState
): CreateClientByEmailMutationVariables {
  return {
    CreateClientByEmailInput: {
      // HTML validation assures name exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: state.name!,
      // HTML validation assures email exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: state.email!,
      // HTML validation assures ta email exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      talentAgentEmail: state.talentAgentEmail!,
    },
  }
}

export function addNewClientToCache(
  cache: ApolloCache<CreateClientByEmailMutation>,
  { data }: MutationResult<CreateClientByEmailMutation>
): void {
  if (data?.createClientByEmail) {
    cache.modify({
      fields: {
        [CACHE_STRING.CLIENT_LIST]: (
          existingClients: CacheConnection = { edges: [] }
        ) => {
          const newClientRef = cache.writeFragment({
            data: data.createClientByEmail.client,
            fragmentName: 'ClientInfo',
            fragment: ClientFragment,
          })

          return {
            ...existingClients,
            edges: [...(existingClients.edges || []), { node: newClientRef }],
          }
        },
      },
    })
  }
}

type OptionsItem = {
  name: string
  email: string
}

export function buildTalentAgentEmailOptions(
  items: OptionsItem[]
): DropdownOption[] {
  return items
    .map(mapNameToOption)
    .sort((a: DropdownOption, b: DropdownOption) => sortByProp(a, b, 'label'))
}

function mapNameToOption(item: OptionsItem): DropdownOption {
  return { value: item.email, label: item.name }
}
