import { ColumnHeader } from 'types'

export const COLUMNS: Record<string, ColumnHeader> = {
  freeAgent: {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  hasStatusUpdateThisWeek: {
    label: 'Last Status Update',
    id: 'lastStatusUpdate',
    collapse: true,
  },
}
