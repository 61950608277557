import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from 'context/auth'
import { useNavContext } from 'context/nav'
import { useApplierQueueCount } from 'hooks/useApplierQueue'
import { useDossierQueueCount } from 'hooks/useDossierQueue'
import { useEnrichmentQueueCount } from 'hooks/useEnrichmentQueue'
import { useFeedbackQueueCount } from 'hooks/useFeedbackQueue'
import { useSourcedJobsReviewQueueCount } from 'hooks/useSourcedJobsReviewQueue'
import { useSourcerQueueCount } from 'hooks/useSourcerQueue'
import { useTalentAgentQueueCount } from 'hooks/useTalentAgentQueue'
import { useTalentPartnerQueueCount } from 'hooks/useTalentPartnerQueue'
import { useWriterQueueCount } from 'hooks/useWriterQueue'
import { DEFUALT_ENRICHMENT_POD_COUNT, ROLE_GROUPS } from 'utils/constants'
import { NavStatus } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import NewSourcingRequestButton from 'components/Buttons/NewSourcingRequestButton'
import NewTaglineRequestButton from 'components/Buttons/NewTaglineRequestButton'
import CollapsedNav from 'components/CollapsedNav/CollapsedNav'
import Icon from 'components/Icon/Icon'
import OpenNav from 'components/OpenNav/OpenNav'
import { SearchNavButton } from 'components/Search/Search'
import { getInitialsFromName } from './Shelf.helpers'
import {
  LogoutButton,
  Name,
  OpenNavContainer,
  ShelfArrowButton,
  ShelfArrowCircle,
  ShelfButton,
  ShelfContent,
  StyledShelf,
  SearchButtonContainer,
  ShelfButtonContainer,
} from './Shelf.styles'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  GetMiscWorkCountQuery,
  GetMiscWorkCountQueryVariables,
} from 'generated/graphql'

export default function Shelf(): JSX.Element {
  const { status, setStatus } = useNavContext()
  const isCollapsed = status === NavStatus.Collapsed
  const {
    userSession: {
      user: {
        id: viewerId,
        name,
        isApplier,
        isWriter,
        isTalentAgent,
        isTee,
        isTalentPartner,
        isEnricher,
        isDossierWriter,
        isSourcer,
      },
    },
  } = useAuthContext()

  const { data } = useQuery<GetMiscWorkCountQuery, GetMiscWorkCountQueryVariables>(
    GET_MISC_WORK_COUNT,
    {
      variables: {
        talentAgentId: viewerId,
      },
      skip: !isTalentAgent,
    }
  )
  const dossierSubmissionCount = data?.dossierSubmissions?.totalCount

  const { totalCount: applierQueueCount } = useApplierQueueCount({
    skip: !isApplier,
  })
  const { totalCount: sourcerQueueCount } = useSourcerQueueCount(
    {
      skip: !isSourcer,
    },
    viewerId
  )
  const { totalCount: writerQueueCount } = useWriterQueueCount({
    skip: !isWriter,
  })
  const { totalCount: reviewFeedbackQueueCount } = useFeedbackQueueCount({
    skip: !isWriter,
  })
  const { totalCount: talentAgentReviewQueueCount } = useTalentAgentQueueCount({
    skip: !isTalentAgent,
  })
  const { totalCount: talentPartnerReviewQueueCount } = useTalentPartnerQueueCount({
    skip: !isTalentPartner && !isTee,
  })
  const { totalCount: dossierQueueCount } = useDossierQueueCount({
    skip: !isDossierWriter,
  })
  const { totalCount: enrichmentQueueCount } = useEnrichmentQueueCount(
    DEFUALT_ENRICHMENT_POD_COUNT,
    {
      skip: !isEnricher,
    }
  )
  const { totalCount: sourcedJobsReviewQueueCount } = useSourcedJobsReviewQueueCount(
    {
      skip: !isTalentAgent,
    }
  )

  return (
    <StyledShelf isCollapsed={isCollapsed}>
      <ShelfContent isCollapsed={isCollapsed}>
        <Link to={ROUTE_PATHS.HOME}>
          <Flex align="center">
            <Icon
              name="FreeAgencyFlag"
              height={40}
              width={40}
              primaryFill="faBlue"
            />
            {!isCollapsed && (
              <Padding left={2} inline>
                <Txt as="h1" size={24} color="faBlue" bold>
                  Deskjockey
                </Txt>
              </Padding>
            )}
          </Flex>
        </Link>
        {!isCollapsed && (
          <SearchButtonContainer vertical={3}>
            <SearchNavButton collapsed={isCollapsed} />
          </SearchButtonContainer>
        )}
        {!isCollapsed && checkRole(ROLE_GROUPS.CORE_TEAM) && (
          <ShelfButtonContainer left={3} right={3}>
            <VList size={1.25}>
              <NewTaglineRequestButton />
              <div>
                <NewSourcingRequestButton />
              </div>
              <div>
                <Link to={ROUTE_PATHS.NEW_INTERVIEW_CYCLE}>
                  <ShelfButton>New Interview Cycle</ShelfButton>
                </Link>
              </div>
            </VList>
          </ShelfButtonContainer>
        )}
        {!isCollapsed ? (
          <OpenNavContainer>
            <OpenNav
              sourcerQueueCount={sourcerQueueCount}
              applierQueueCount={applierQueueCount}
              writerQueueCount={writerQueueCount}
              reviewFeedbackQueueCount={reviewFeedbackQueueCount}
              talentAgentReviewQueueCount={talentAgentReviewQueueCount}
              talentPartnerReviewQueueCount={talentPartnerReviewQueueCount}
              dossierQueueCount={dossierQueueCount}
              dossierSubmissionCount={dossierSubmissionCount}
              enrichmentQueueCount={enrichmentQueueCount}
              sourcedJobsReviewQueueCount={sourcedJobsReviewQueueCount}
            />
          </OpenNavContainer>
        ) : (
          <Padding>
            <CollapsedNav
              sourcerQueueCount={sourcerQueueCount}
              applierQueueCount={applierQueueCount}
              writerQueueCount={writerQueueCount}
              reviewFeedbackQueueCount={reviewFeedbackQueueCount}
              talentAgentReviewQueueCount={talentAgentReviewQueueCount}
              talentPartnerReviewQueueCount={talentPartnerReviewQueueCount}
              dossierQueueCount={dossierQueueCount}
              sourcedJobsReviewQueueCount={sourcedJobsReviewQueueCount}
              enrichmentQueueCount={enrichmentQueueCount}
            />
          </Padding>
        )}

        <Name>
          {!isCollapsed && (
            <Padding bottom={1}>
              <Flex justify="center" align="center">
                <LogoutButton to={ROUTE_PATHS.LOGOUT}>
                  <Txt
                    color="faGrey4"
                    alignment="center"
                    uppercase
                    size={12}
                    spacing={1}
                  >
                    Logout
                  </Txt>
                </LogoutButton>
              </Flex>
            </Padding>
          )}
          <Link to={ROUTE_PATHS.PREFERENCES}>
            <Flex justify="center" align="center">
              <Txt bold as="span">
                {isCollapsed ? getInitialsFromName(name) : name}
              </Txt>
              {!isCollapsed && (
                <Padding inline left={1}>
                  <Icon name="Cog" primaryFill="text" height={16} width={16} />
                </Padding>
              )}
            </Flex>
          </Link>
        </Name>
      </ShelfContent>
      <ShelfArrowButton
        isCollapsed={isCollapsed}
        onClick={() =>
          setStatus?.(isCollapsed ? NavStatus.Open : NavStatus.Collapsed)
        }
      >
        <ShelfArrowCircle isCollapsed={isCollapsed}>
          <Icon name="ArrowRight" width={20} height={20} primaryFill="text" />
        </ShelfArrowCircle>
      </ShelfArrowButton>
    </StyledShelf>
  )
}

const GET_MISC_WORK_COUNT = gql`
  query getMiscWorkCount($talentAgentId: ID!) {
    sourcedJobs(
      isRedacted: false
      isReviewed: false
      onlyActiveClients: true
      talentAgentId: $talentAgentId
    ) {
      totalCount
    }
    dossierSubmissions(isReviewed: false, talentAgentId: $talentAgentId) {
      totalCount
    }
  }
`
