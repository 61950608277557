import { InterviewCycleSource } from 'generated/graphql'

export const sourceOptions = [
  {
    value: InterviewCycleSource.STANDARD,
    label: 'Standard',
  },
  {
    value: InterviewCycleSource.REVERSE_RECRUIT,
    label: 'Reverse Recruit',
  },
  {
    value: InterviewCycleSource.INVESTOR_BLAST,
    label: 'Investor Blast',
  },
  {
    value: InterviewCycleSource.COLD_COVER,
    label: 'Cold Cover',
  },
  {
    value: InterviewCycleSource.FREE_AGENT_SOURCED,
    label: 'Free Agent Sourced',
  },
  {
    value: InterviewCycleSource.FREE_AGENT_REFERRAL,
    label: 'Free Agent Referral',
  },
  {
    value: InterviewCycleSource.RECRUITER_INBOUNDED,
    label: 'Recruiter Inbounded',
  },
]

export const TOAST = {
  CREATE_SUCCESS: 'Successfully created interview cycle',
  CREATE_ERROR: 'There was an error creating the interview cycle',
  MISSING_CLIENT_ID: 'Must select a Free Agent',
  MISSING_HUNTR_URL: 'Must provide a Huntr URL',
  MISSING_SOURCE: 'Must select a source',
  MISSING_DATE: 'Must select a start date',
  HUNTR_MATCH: `Huntr URL must match pattern 'https://huntr.co/boards/'`,
}
