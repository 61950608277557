import { useMutation } from '@apollo/client'
import React, { useEffect, useCallback, useState } from 'react'
import { useParams } from 'react-router'
import { Redirect } from 'react-router-dom'
import { useAuthContext } from 'context/auth'
import ROUTE_PATHS from 'utils/routePaths'
import { LOGIN_WITH_MAGIC_LINK } from 'gql/mutations'
import Message from 'components/Message'
import PublicRoute from 'components/PublicRoute'
import {
  LoginWithMagicLinkMutation,
  LoginWithMagicLinkMutationVariables,
} from 'generated/graphql'

export default function Magic(): JSX.Element {
  const [isLoggedIn, setIsLoggedIn] = useState(false)
  const [loginWithMagicLink, { data }] = useMutation<
    LoginWithMagicLinkMutation,
    LoginWithMagicLinkMutationVariables
  >(LOGIN_WITH_MAGIC_LINK)
  const { token } = useParams<{ token: string }>()
  const { setUserSession } = useAuthContext()

  const requestLogin = useCallback(
    () => loginWithMagicLink({ variables: { LoginWithMagicLinkInput: { token } } }),
    [loginWithMagicLink, token]
  )

  useEffect(() => {
    void requestLogin()
  }, [requestLogin])

  useEffect(() => {
    if (
      data?.loginWithMagicLink.accessToken &&
      data.loginWithMagicLink.expiresIn &&
      data.loginWithMagicLink.viewer
    ) {
      const now = new Date()
      const expiresAt = now.getTime() + data.loginWithMagicLink.expiresIn * 1000
      setUserSession({
        accessToken: data.loginWithMagicLink.accessToken,
        expiresAt: expiresAt,
        user: data.loginWithMagicLink.viewer,
      })
      setIsLoggedIn(true)
    }
  }, [data, setUserSession])

  if (isLoggedIn) {
    return <Redirect to={ROUTE_PATHS.HOME} />
  } else if (data?.loginWithMagicLink.errorCode) {
    // TODD: show error reason
    return <Message vertical message="The magic link you used does not work" />
  } else {
    return <Message vertical message="Authenticating your token..." />
  }
}

Magic.Routes = [
  <PublicRoute exact path={ROUTE_PATHS.MAGIC_LINK} key={ROUTE_PATHS.MAGIC_LINK}>
    <Magic />
  </PublicRoute>,
]
