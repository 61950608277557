import { Context, createContext, useContext } from 'react'
import { NavStatus } from 'utils/enums'

type NavContextType = {
  status?: NavStatus
  setStatus?: (value: NavStatus) => void
}

export const NavContext: Context<NavContextType> = createContext({})

export function useNavContext(): NavContextType {
  return useContext(NavContext)
}
