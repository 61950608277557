import { gql, useQuery, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import { EMPTY_INPUT } from 'utils/constants'
import useFormState from 'views/ClientTracker/useFormState'
import {
  Grid,
  GridItem,
} from 'views/InterviewCycleDetail/interviewCycleDetail.styles'
import { ClientFragment, ID, TOAST } from './Clients.constants'
import {
  buildCreateClientVariables,
  addNewClientToCache,
  buildTalentAgentEmailOptions,
} from './Clients.helpers'
import { ClientFormState } from './Clients.types'
import Button from 'components/Button'
import { Dialog, DialogExitButton, DialogFooter } from 'components/DialogMisc'
import Flex from 'components/Flex'
import { TextInput, Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  CreateClientByEmailMutation,
  CreateClientByEmailMutationVariables,
  GetTalentAgentsEmailQuery,
  GetTalentAgentsEmailQueryVariables,
} from 'generated/graphql'

type Props = {
  handleClose: () => void
  isOpen: boolean
}

export default function AddClient({
  isOpen,
  handleClose,
}: Props): React.ReactElement {
  const { formState, setFormField, setFormState } = useFormState<ClientFormState>({})

  const { data } = useQuery<
    GetTalentAgentsEmailQuery,
    GetTalentAgentsEmailQueryVariables
  >(GET_TALENT_AGENTS)
  const talentAgentOptions = buildTalentAgentEmailOptions(data?.talentAgents || [])

  const [createClientByEmail] = useMutation<
    CreateClientByEmailMutation,
    CreateClientByEmailMutationVariables
  >(CREATE_CLIENT_BY_EMAIL, {
    onCompleted() {
      toast.success(TOAST.CREATE_SUCCESS)
      setFormState({})
      handleClose()
    },
    onError() {
      toast.error(TOAST.CREATE_ERROR)
    },
    update: addNewClientToCache,
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    const variables = buildCreateClientVariables(formState)
    void createClientByEmail({ variables })
  }
  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={handleClose}
      aria-label={'Add client form'}
      widthVW={[90, { sm: 80, md: 55, lg: 45, xl: 35 }]}
    >
      <Padding vertical={4} horizontal={4}>
        <DialogExitButton onClick={handleClose} />
        <Txt as="h2" bold size={24}>
          New Client
        </Txt>
        <Padding top={4}>
          <form onSubmit={handleSubmit} id={ID.FORM}>
            <Grid
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              rowGapSize={4}
              colGapSize={2}
            >
              <GridItem column={1} span={2}>
                <TextInput
                  name="name"
                  label="Name *"
                  fullWidth
                  required
                  autoComplete="off"
                  value={formState.name ?? EMPTY_INPUT}
                  onValueChange={(value) => setFormField('name', value)}
                />
              </GridItem>
              <GridItem column={3} span={2}>
                <TextInput
                  name="email"
                  label="Email *"
                  fullWidth
                  required
                  autoComplete="off"
                  value={formState.email ?? EMPTY_INPUT}
                  onValueChange={(value) => setFormField('email', value)}
                />
              </GridItem>
              <GridItem column={1} span={2}>
                <Dropdown
                  label="Talent Agent"
                  options={talentAgentOptions}
                  value={formState.talentAgentEmail ?? EMPTY_INPUT}
                  onValueChange={(value) => setFormField('talentAgentEmail', value)}
                  placeholder="Select Talent Agent"
                  required
                />
              </GridItem>
            </Grid>
          </form>
        </Padding>
      </Padding>
      <DialogFooter>
        <Padding vertical={1} horizontal={4}>
          <Flex justify="flex-end">
            <Button
              $type="accept"
              as="input"
              type="submit"
              form={ID.FORM}
              value="Submit"
            />
          </Flex>
        </Padding>
      </DialogFooter>
    </Dialog>
  )
}

const CREATE_CLIENT_BY_EMAIL = gql`
  mutation CreateClientByEmail(
    $CreateClientByEmailInput: CreateClientByEmailInput!
  ) {
    createClientByEmail(input: $CreateClientByEmailInput) {
      client {
        ...ClientInfo
      }
    }
  }
  ${ClientFragment}
`
const GET_TALENT_AGENTS = gql`
  query GetTalentAgentsEmail {
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
      email
    }
  }
`
