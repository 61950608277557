import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ReactChangeEvent, ReactFormEvent } from 'types'
import { Client } from 'views/ClientSettings/ClientSettings.types'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import { TOAST } from './ClientActiveStatus.constants'
import Flex from 'components/Flex'
import Label from 'components/Label'
import Padding from 'components/Padding'

type Props = {
  client: Client
  formId: string
}

function ClientActiveStatus({ client, formId }: Props): React.ReactElement {
  const [isActive, setIsActive] = useState(!client.isInactive)

  const [updateClientActiveStatus] = useMutation(UPDATE_CLIENT_ACTIVE_STATUS, {
    onCompleted() {
      toast.success(TOAST.UPDATE_SUCCESS)
    },
    onError() {
      toast.error(TOAST.UPDATE_ERROR)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    const activeAdj = isActive ? 'active' : 'inactive'
    const message = `Are you sure you want to change the status of ${client.name} to ${activeAdj}?`

    if (window.confirm(message)) {
      const variables = {
        ModifyClientActiveStatusInput: {
          clientId: client.id,
          isActive: isActive,
        },
      }

      void updateClientActiveStatus({ variables })
    }
  }

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <Flex align="center">
        <input
          id="isActive"
          type="checkbox"
          value="isActive"
          onChange={(event: ReactChangeEvent) =>
            setIsActive(event.currentTarget.checked)
          }
          checked={isActive}
          aria-label="highlighted row checkbox"
        />
        <Padding left={1} right={2} inline>
          <Label htmlFor="isActive" content="Active" padding={0} />
        </Padding>
      </Flex>
    </form>
  )
}

ClientActiveStatus.fragments = {
  ActiveStatusInfo: gql`
    fragment ActiveStatusInfo on Client {
      isInactive
    }
  `,
}

ClientActiveStatus.Skeleton = <RectangleSkeleton height={30} width={100} />

export default ClientActiveStatus

const UPDATE_CLIENT_ACTIVE_STATUS = gql`
  mutation UpdateClientActiveStatus(
    $ModifyClientActiveStatusInput: ModifyClientActiveStatusInput!
  ) {
    modifyClientActiveStatus(input: $ModifyClientActiveStatusInput) {
      client {
        id
        ...ActiveStatusInfo
      }
    }
  }
  ${ClientActiveStatus.fragments.ActiveStatusInfo}
`
