import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import useFormState from 'views/ClientTracker/useFormState'
import { sourceOptions } from '../NewInterviewCycle/NewInterviewCycle.constants'
import { InterviewCycle } from './InterviewCycleDetail.types'
import Button from 'components/Button'
import HList from 'components/HList'
import { RadioGroup } from 'components/Inputs'
import Padding from 'components/Padding'
import {
  ModifyCycleDetailMetaMutation,
  ModifyCycleDetailMetaMutationVariables,
  InterviewCycleSource,
} from 'generated/graphql'

type Props = {
  interviewCycle: InterviewCycle
  closeForm(): void
}

export default function InterviewCycleMetaSection({
  interviewCycle,
  closeForm,
}: Props): React.ReactElement {
  const { formState, setFormField } = useFormState<FormState>({
    source: interviewCycle.source,
  })

  const [modifyCycleDetailMeta] = useMutation<
    ModifyCycleDetailMetaMutation,
    ModifyCycleDetailMetaMutationVariables
  >(MODIFY_CYCLE_DETAIL_META, {
    onCompleted() {
      toast.success(COPY.modifySuccess)
      closeForm()
    },
    onError() {
      toast.error(COPY.modifyError)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    void modifyCycleDetailMeta({
      variables: {
        input: {
          interviewCycleId: interviewCycle.id,
          source: formState.source,
        },
      },
    })
  }

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <HList size={2}>
          <RadioGroup
            name="source"
            label="Source"
            value={formState.source}
            options={sourceOptions}
            onValueChange={(value) =>
              setFormField('source', value as InterviewCycleSource)
            }
          />
        </HList>
        <Padding top={3}>
          <HList size={1}>
            <Button $type="accept" type="submit">
              Save
            </Button>
            <Button $type="secondary" type="button" onClick={closeForm}>
              Cancel
            </Button>
          </HList>
        </Padding>
      </form>
    </div>
  )
}

const MODIFY_CYCLE_DETAIL_META = gql`
  mutation ModifyCycleDetailMeta($input: ModifyInterviewCycleMetaInput!) {
    modifyInterviewCycleMeta(input: $input) {
      interviewCycle {
        id
        source
      }
    }
  }
`

type FormState = {
  source?: InterviewCycleSource
}

const COPY = {
  modifySuccess: 'Interview cycle details modified.',
  modifyError: 'There was an error modifying the interview cycle details.',
}
