import { gql, useMutation, useQuery } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { useAuthContext } from 'context/auth'
import useCopyClipboard from 'hooks/useCopyClipboard'
import useLocalStorage from 'hooks/useLocalStorage'
import { MimeType } from 'utils/enums'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'
import {
  clipboardCopyOptions,
  ClipboardCopyValues,
  LocalPreferences,
  TOAST,
} from './Preferences.constants'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import { Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import {
  PreferencesInfoQuery,
  PublishTaCalendarMutation,
  PublishTaCalendarMutationVariables,
} from 'generated/graphql'

export default function Preferences(): React.ReactElement {
  const {
    userSession: {
      user: { isTalentAgent, id: viewerId },
    },
  } = useAuthContext()

  const clipboard = useCopyClipboard({
    onSuccess() {
      toast.success('Calendar link copied to clipboard')
    },
  })

  const { data } = useQuery<PreferencesInfoQuery>(PREFERENCES_INFO)

  const [createCalendar] = useMutation<
    PublishTaCalendarMutation,
    PublishTaCalendarMutationVariables
  >(PUBLISH_TA_CALENDAR, {
    onCompleted(data) {
      clipboard.copy(
        data.publishTACalendar.calendarPublishTa.publishUrl,
        MimeType.Plain
      )
    },
    onError() {
      toast.error(TOAST.CALENDAR_ERROR)
    },
  })

  const [clipboardSetting, setClipboardSetting] =
    useLocalStorage<ClipboardCopyValues>(
      LocalPreferences.ClipboardCopyType,
      ClipboardCopyValues.TEXT
    )

  function handleCreateCalendar() {
    void createCalendar({
      variables: {
        input: {
          sharedWithId: viewerId,
          talentAgentId: viewerId,
        },
      },
    })
  }

  const hasOwnCalendar = data?.viewer?.calendarPublishTasSharedWith.find(
    (calendar) => calendar.talentAgent.id === viewerId
  )

  return (
    <ViewBox>
      <Padding top={6}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            Preferences
          </Txt>
          <Padding top={4}>
            <VList size={4}>
              <Flex justify="space-between" align="center">
                <Txt as="label" lineHeight={1.3}>
                  When using the Copy to Clipboard button would you like the convert
                  the content to text or keep it as HTML?
                </Txt>
                <Padding left={6}>
                  <Dropdown
                    value={clipboardSetting}
                    onValueChange={(value) =>
                      setClipboardSetting(value as ClipboardCopyValues)
                    }
                    options={clipboardCopyOptions}
                    withEmptyOption={false}
                  />
                </Padding>
              </Flex>
              <div>
                <Padding bottom={2}>
                  <Txt size={18} bold>
                    Calendars
                  </Txt>
                </Padding>
                {isTalentAgent && !hasOwnCalendar && (
                  <Padding bottom={2}>
                    <Button $type="highlight" onClick={handleCreateCalendar}>
                      Generate My Calendar
                    </Button>
                  </Padding>
                )}
                <VList size={1}>
                  {data?.viewer?.calendarPublishTasSharedWith.length
                    ? data.viewer.calendarPublishTasSharedWith.map((calendar) => (
                        <button
                          style={{ display: 'block' }}
                          key={calendar.id}
                          onClick={() =>
                            clipboard.copy(calendar.publishUrl, MimeType.Plain)
                          }
                        >
                          <Padding right={1} inline>
                            <Icon
                              name="Clipboard"
                              height={16}
                              width={16}
                              primaryFill="text"
                              primaryFillHover="black"
                            />
                          </Padding>
                          <Txt as="span" hoverColor="black">
                            Calendar for {calendar.talentAgent.name}
                          </Txt>
                        </button>
                      ))
                    : null}
                </VList>
              </div>
            </VList>
          </Padding>
        </Container>
      </Padding>
    </ViewBox>
  )
}

Preferences.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.PREFERENCES} key={ROUTE_PATHS.PREFERENCES}>
    <Preferences />
  </PrivateRoute>,
]

const PUBLISH_TA_CALENDAR = gql`
  mutation PublishTACalendar($input: PublishTACalendarInput!) {
    publishTACalendar(input: $input) {
      calendarPublishTa {
        id
        publishUrl
      }
    }
  }
`

const PREFERENCES_INFO = gql`
  query PreferencesInfo {
    viewer {
      calendarPublishTasSharedWith {
        id
        publishUrl
        talentAgent {
          id
          name
        }
      }
    }
  }
`
