import React, { useState } from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import TalentAgentSection from './TalentAgentSection'
import { SearchSuggestionType } from './TalentAgents.types'
import TalentAgentSearch from './TalentAgentsSearch'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { Maybe } from 'generated/graphql'

export default function TalentAgents(): React.ReactElement {
  const [selectedTalentAgent, setSelectedTalentAgent] =
    useState<Maybe<SearchSuggestionType>>(null)
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            Talent Agents
          </Txt>
        </Padding>
        <Padding bottom={1}>
          <TalentAgentSearch setSelectedTalentAgent={setSelectedTalentAgent} />
        </Padding>
      </Container>
      {selectedTalentAgent && (
        <Container noMax>
          <Padding vertical={2}>
            <TalentAgentSection talentAgent={selectedTalentAgent} />
          </Padding>
        </Container>
      )}
    </ViewBox>
  )
}

TalentAgents.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.TALENT_AGENTS}
    key={ROUTE_PATHS.TALENT_AGENTS}
  >
    <TalentAgents />
  </PrivateRoute>,
]
