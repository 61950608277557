import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { Maybe, ReactFormEvent } from 'types'
import { INTERVIEW_TIMEZONE_OPTIONS, QS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { buildInterviewCycleOptions } from 'utils/helpers/buildInterviewCycleOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { GET_CLIENTS, GET_CLIENTS_INTERVIEW_CYCLES } from 'gql/queries'
import { COPY, ID, SCHEDULED_OPTIONS } from './AddAndEditInterview.constants'
import { getDefaultState, getScheduledFor } from './AddAndEditInterview.helpers'
import { Interview, State } from './AddAndEditInterview.types'
import { DatePicker, Dropdown, RadioGroup, TextArea } from 'components/Inputs'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CreateInterviewMutation,
  CreateInterviewMutationVariables,
  GetClientsInterviewCyclesQuery,
  GetClientsInterviewCyclesQueryVariables,
  GetClientsQuery,
  UpdateInterviewMutation,
  UpdateInterviewMutationVariables,
} from 'generated/graphql'

type Props = {
  isEditing: boolean
  interview: Maybe<Interview>
}

export default function AddAndEditInterviewForm({
  isEditing,
  interview,
}: Props): React.ReactElement {
  // prefills
  const queryString = useQueryString()
  const clientIdParam = queryString.get(QS.CLIENT_ID)
  const interviewCycleIdParam = queryString.get(QS.INTERVIEW_CYCLE_ID)
  const defaultState = getDefaultState(interview, {
    clientId: clientIdParam,
    interviewCycleId: interviewCycleIdParam,
  })

  // state
  const [state, setState] = useState<State>(defaultState)
  const history = useHistory()

  // GQL queries
  const { data: clientData } = useQuery<GetClientsQuery>(GET_CLIENTS)
  const { data: interviewCycleData, refetch: refetchInterviewCycles } = useQuery<
    GetClientsInterviewCyclesQuery,
    GetClientsInterviewCyclesQueryVariables
  >(GET_CLIENTS_INTERVIEW_CYCLES, {
    variables: { clientId: state.clientId },
    skip: !state.clientId,
  })

  // GQL muations
  const [createInterview] = useMutation<
    CreateInterviewMutation,
    CreateInterviewMutationVariables
  >(CREATE_INTERVIEW, {
    onCompleted() {
      toast.success(COPY.CREATE_SUCCESS)
      setState(defaultState)
    },
    onError() {
      toast.error(COPY.CREATE_ERROR)
    },
  })
  const [updateInterview] = useMutation<
    UpdateInterviewMutation,
    UpdateInterviewMutationVariables
  >(UPDATE_INTERVIEW, {
    onCompleted() {
      toast.success(COPY.UPDATE_SUCCESS)
      history.push(queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME)
    },
    onError() {
      toast.error(COPY.UPDATE_ERROR)
    },
  })

  // normalizing data
  const clientOptions = buildDropdownOptions(clientData?.clients || [])
  const interviewCycleOptions = buildInterviewCycleOptions(
    interviewCycleData?.interviewCycles
  )

  // derived state
  const showLoadingMessage = !state.clientId || !interviewCycleData

  // functions
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    if (isEditing) {
      submitModifyInterview()
    } else {
      submitCreateInterview()
    }
  }

  function submitCreateInterview() {
    const scheduledFor = getScheduledFor(state)

    const variables = {
      CreateInterviewInput: {
        interviewCycleId: state.interviewCycleId,
        interviewerNames: state.interviewerNames,
        notes: state.notes,
        scheduledFor,
      },
    }

    void createInterview({ variables })
  }
  function submitModifyInterview() {
    if (!interview) {
      return
    }
    const variables = {
      UpdateInterviewInput: {
        interviewId: interview.id,
        interviewerNames: state.interviewerNames,
        notes: state.notes,
        scheduledFor: state.isScheduled ? getScheduledFor(state) : null,
      },
    }
    /* TODO (matthewalbrecht): use submitModifyInterview mutation */
    void updateInterview({ variables })
  }

  /**
   * updates state with new client Id, resets interviewCycleId state, and fetches client's interview cycles
   * @param value input value
   */
  function handleClientIdValueChange(value: string) {
    const newState = { ...state, clientId: value, interviewCycleId: '' }
    setState(newState)

    void refetchInterviewCycles({ clientId: value })
  }

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<T = string>(value: T, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  return (
    <CenteredBox>
      <form id={ID.FORM} onSubmit={handleSubmit}>
        <Padding bottom={4}>
          <Txt size={24} bold as="h2">
            Report Interview Request
          </Txt>
        </Padding>
        <VList size={4}>
          <Dropdown
            label="Free Agent *"
            options={clientOptions}
            value={state.clientId}
            onValueChange={handleClientIdValueChange}
            minWidth={100}
            placeholder="Select Free Agent"
            withEmptyOption={false}
            required
            disabled={isEditing}
          />

          <RadioGroup
            label="Interview Cycle *"
            name="interviewCycle"
            options={interviewCycleOptions}
            value={state.interviewCycleId}
            onValueChange={(value) => handleInputChange(value, 'interviewCycleId')}
            loadingOptions={showLoadingMessage}
            loadingMessage={
              'Please select a Free Agent to see their interview cycles'
            }
            required
            disabled={isEditing}
          />
          {/* TODO (matthewalbrecht): build a styled checkbox component */}
          <RadioGroup
            label="Has it been scheduled?"
            name="isScheduled"
            options={SCHEDULED_OPTIONS}
            value={String(state.isScheduled)}
            onValueChange={(value) =>
              handleInputChange(value === 'true', 'isScheduled')
            }
          />
          {state.isScheduled && (
            <>
              <RadioGroup
                label="Timezone"
                name="timezone"
                options={INTERVIEW_TIMEZONE_OPTIONS}
                value={state.timezone || ''}
                onValueChange={(value) => handleInputChange(value, 'timezone')}
                required
              />
              <DatePicker
                label="Scheduled at"
                type="datetime-local"
                value={state.datetime}
                onValueChange={(value) => handleInputChange(value, 'datetime')}
                required
              />
            </>
          )}
          <TextArea
            label="Interviewers"
            value={state.interviewerNames}
            onValueChange={(value) => handleInputChange(value, 'interviewerNames')}
          />
          <TextArea
            label="Notes"
            value={state.notes}
            onValueChange={(value) => handleInputChange(value, 'notes')}
          />
        </VList>
      </form>
    </CenteredBox>
  )
}

const CREATE_INTERVIEW = gql`
  mutation CreateInterview($CreateInterviewInput: CreateInterviewInput!) {
    createInterview(input: $CreateInterviewInput) {
      interview {
        id
      }
    }
  }
`

const UPDATE_INTERVIEW = gql`
  mutation UpdateInterview($UpdateInterviewInput: UpdateInterviewInput!) {
    updateInterview(input: $UpdateInterviewInput) {
      interview {
        id
        interviewerNames
        notes
        scheduledFor {
          datetime
          timezone
        }
      }
    }
  }
`
