import * as React from 'react'
import styled from 'styled-components/macro'

type Props = {
  size?: number
  as?: React.ElementType
  children?: React.ReactNode
}

const VList = styled.ul<Props>`
  display: block;

  & > * + * {
    margin-top: ${({ theme, size = 1 }) => theme.rhythm(size)};
  }
`

export default VList
