import dayjs, { Dayjs } from 'dayjs'
import { TaglineRequestStatus } from 'generated/graphql'

type PropObject = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any
}

type StatusObject = {
  status: TaglineRequestStatus
  lastStatusChangeDate: Dayjs
}

const TaglineRequestStatusValues = {
  [TaglineRequestStatus.UNASSIGNED]: 1,
  [TaglineRequestStatus.PENDING_WRITING]: 2,
  [TaglineRequestStatus.PENDING_TA_REVIEW]: 3,
  [TaglineRequestStatus.PENDING_TP_REVIEW]: 4,
  [TaglineRequestStatus.REVIEWED]: 5,
  [TaglineRequestStatus.REJECTED]: 6,
}

// using keyof PropObject was giving us type "string | number" this forces it to be a string https://stackoverflow.com/a/51808262
type PropObjectKeys = Extract<keyof PropObject, string>

/**
 *  Sorts two objects given a prop that they share
 * @param a an object with properties
 * @param b an object with properties
 * @param primarySort name of prop in object (can be prefixed with a "-")
 * @param secondarySort name of prop in object (can be prefixed with  a "-")
 */
export const sortByProp = (
  a: PropObject | undefined,
  b: PropObject | undefined,
  primarySort: PropObjectKeys,
  secondarySort?: PropObjectKeys
): number => {
  /**
   * Running into situations where a and b were 0th element in array, typescript will automatically
   * type that as T | undefined. rather than checking they exist at time of function call
   * we allow undefined to be passed so that the usage of sortByProp stays simple.
   */
  if (a == null && b == null) {
    return 0
  } else if (a == null) {
    return 1 // could be -1 if we want to flip where in list nulls go
  } else if (b == null) {
    return -1
  }

  const isPrimaryDescending = primarySort.charAt(0) === '-'
  const isSecondaryDescending = secondarySort?.charAt(0) === '-'
  const firstProp = isPrimaryDescending ? primarySort.slice(1) : primarySort
  const secondaryProp = isSecondaryDescending
    ? secondarySort.slice(1)
    : secondarySort

  if (a[firstProp] < b[firstProp]) {
    return isPrimaryDescending ? 1 : -1
  }
  if (a[firstProp] > b[firstProp]) {
    return isPrimaryDescending ? -1 : 1
  }

  if (secondaryProp && a[secondaryProp] < b[secondaryProp]) {
    return isSecondaryDescending ? 1 : -1
  }
  if (secondaryProp && a[secondaryProp] > b[secondaryProp]) {
    return isSecondaryDescending ? -1 : 1
  }
  return 0
}

export const sortByTaglineRequestStatus = (
  a: StatusObject,
  b: StatusObject
): number => {
  if (TaglineRequestStatusValues[a.status] < TaglineRequestStatusValues[b.status]) {
    return -1
  }
  if (TaglineRequestStatusValues[a.status] > TaglineRequestStatusValues[b.status]) {
    return 1
  }
  if (a.lastStatusChangeDate < b.lastStatusChangeDate) {
    return -1
  }
  if (a.lastStatusChangeDate > b.lastStatusChangeDate) {
    return 1
  }
  return 0
}

export const sortByPropAlt = (
  a: PropObject | undefined,
  b: PropObject | undefined,
  primarySort: PropObjectKeys,
  isPrimaryDescending: boolean
): number => {
  if (a == null && b == null) {
    return 0
  } else if (a == null) {
    return 1 // could be -1 if we want to flip where in list nulls go
  } else if (b == null) {
    return -1
  }

  if (a[primarySort] < b[primarySort]) {
    return isPrimaryDescending ? 1 : -1
  }
  if (a[primarySort] > b[primarySort]) {
    return isPrimaryDescending ? -1 : 1
  }
  return 0
}

export const sortByDatetime = (dateTimeA: string, dateTimeB: string): number => {
  if (dayjs(dateTimeA).isAfter(dayjs(dateTimeB))) {
    return 1
  }
  return -1
}
