import { ApolloCache, Reference } from '@apollo/client'
import { generatePath } from 'react-router'
import { CacheConnection, KeyValue } from 'types'
import { CACHE_STRING } from 'utils/cacheString'
import { Typename } from 'utils/enums'
import removeCacheEdgeById, { Connection } from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import { SourcedJob } from './SourcedJobsReview.types'

type RouteValues = {
  freeAgentId: string | undefined
}

/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextRequestId: string | null,
  { freeAgentId }: RouteValues
): string {
  if (nextRequestId) {
    return generatePath(ROUTE_PATHS.REVIEW_SOURCED_JOBS_QUEUE, {
      freeAgentId,
      sourcedJobId: nextRequestId,
    })
  }

  return ROUTE_PATHS.HOME
}

/**
 * this will remove the current sourced job from the query in cache so that ui will properly update
 * @param cache Apollo's cache
 */
export function updateCacheAfterSuccess(
  cache: ApolloCache<unknown>,
  sourcedJob: SourcedJob
): void {
  const clientCacheId = cache.identify({
    id: sourcedJob.client.id,
    __typename: Typename.Client,
  })
  clientCacheId &&
    cache.modify({
      id: clientCacheId,
      fields: {
        [CACHE_STRING.SOURCED_JOB_REVIEW_QUEUE](
          existing: CacheConnection = { edges: [] }
        ) {
          const newTotalCount = existing.totalCount
            ? existing.totalCount - 1
            : existing.edges?.length
            ? existing.edges.length - 1
            : 0
          return {
            ...existing,
            totalCount: newTotalCount,
          }
        },
      },
    })
  cache.modify({
    fields: {
      [`client({"id":"${sourcedJob.client.id}"})`]: (
        existing: KeyValue<CacheConnection>,
        { readField }
      ) => {
        return {
          ...removeCacheEdgeById(
            sourcedJob.id,
            existing[CACHE_STRING.SOURCED_JOB_REVIEW_QUEUE] as Connection<Reference>,
            readField
          ),
        }
      },
    },
  })
}
