import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { TableRowData } from './ReviewSourcedJobs.types'
import Txt from 'components/Txt'

type Props = {
  rowData: TableRowData
}

export default function ReviewSourcedJobsHighlightedRow({
  rowData,
}: Props): React.ReactElement {
  return (
    <BodyRow color="blueHighlight">
      <BodyData colSpan={100}>
        <Link
          to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
            clientId: rowData.clientId,
          })}
        >
          <Txt as="span" size={14} bold underline hoverColor="black">
            {rowData.clientName}
          </Txt>
        </Link>
      </BodyData>
    </BodyRow>
  )
}
