import styled from 'styled-components/macro'
import Flex from 'components/Flex'

export const OpenNavSearch = styled.button`
  display: flex;
  align-items: center;
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(0.5)}
    ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(1)};
  background-color: ${({ theme }) => theme.color.white};
  cursor: text;
  width: 100%;
  border: 0.1rem solid transparent;

  &:hover {
    border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  }
`

export const SearchKeyboardShortcutIcon = styled(Flex)`
  height: ${({ theme }) => theme.toRems(20)};
  width: ${({ theme }) => theme.toRems(20)};
  border-radius: 0.3rem;
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  border-bottom: 0.2rem solid ${({ theme }) => theme.color.faGrey2};
`
