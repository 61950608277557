import { gql, useQuery } from '@apollo/client'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import { FIRST } from './ClientApplications.constants'
import ClientApplicationsTable from './ClientApplicationsTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import RefreshMessage from 'components/RefreshMessage'
import ViewBox from 'components/ViewBox'
import {
  GetClientApplicationsQuery,
  GetClientApplicationsQueryVariables,
  GetClientQuery,
  GetClientQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientApplications({ clientId }: Props): JSX.Element {
  /**
   * request the next set of activity logs
   */
  function handleMoreClick() {
    void fetchMore({
      variables: {
        clientId,
        first: FIRST,
        after: pageInfo?.endCursor,
      },
    })
  }

  const { data, loading, error, fetchMore } = useQuery<
    GetClientApplicationsQuery,
    GetClientApplicationsQueryVariables
  >(GET_CLIENT_APPLICATIONS, {
    variables: {
      clientId,
      first: FIRST,
    },
  })
  const {
    data: clientData,
    loading: clientLoading,
    error: errorLoading,
  } = useQuery<GetClientQuery, GetClientQueryVariables>(GET_CLIENT, {
    variables: {
      clientId,
    },
  })

  const { pageInfo } = data?.applications || {}
  const showLoadMoreButton = pageInfo?.hasNextPage
  const applications = data?.applications && getNodes(data.applications)

  if (loading || clientLoading) {
    return <Message vertical message="Loading applications..." />
  }

  if (error || errorLoading || !applications || !clientData) {
    return <RefreshMessage message="There was an error loading the applications" />
  }

  return (
    <ViewBox unsetHeight>
      <Padding top={1}>
        <ClientApplicationsTable applications={applications} />
      </Padding>
      {showLoadMoreButton && (
        <Container>
          <Padding top={3}>
            <Button onClick={handleMoreClick}>Load More</Button>
          </Padding>
        </Container>
      )}
    </ViewBox>
  )
}

const GET_CLIENT_APPLICATIONS = gql`
  query GetClientApplications($clientId: ID!, $first: Int!, $after: String) {
    applications(clientId: $clientId, first: $first, after: $after) {
      edges {
        node {
          id
          ...ClientApplicationsTableInfo
        }
        cursor
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
  ${ClientApplicationsTable.fragments.tableInfo}
`

const GET_CLIENT = gql`
  query GetClient($clientId: ID!) {
    client(id: $clientId) {
      id
      name
    }
  }
`
