import { GroupedRequests } from './CurrentAssignments.types'

/**
 * group the given objects by the `prop` value
 * @param items the sortable requests
 * @param prop what prop are we looking to group by
 */

export function groupDataByProp<
  T extends Record<K, string>,
  K extends string | keyof T
>(items: T[], prop: K): GroupedRequests<T> {
  return items.reduce((groupedObjects: GroupedRequests<T>, request) => {
    const groupValue = request[prop]
    const entries = groupedObjects[groupValue]

    if (entries) {
      entries.push(request)
    } else {
      groupedObjects[groupValue] = [request]
    }

    return groupedObjects
  }, {})
}
