import { gql } from '@apollo/client'
import React from 'react'
import { generatePath, Link, useHistory, useParams } from 'react-router-dom'
import { DropdownOption } from 'types'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import EditExpertRequestStatus from './EditExpertRequestStatus'
import { getStatusInfo } from './ExpertRequests.helpers'
import { ExpertRequest, Params } from './ExpertRequests.types'
import ExternalLink from 'components/ExternalLink'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'

type Props = {
  expertRequest: ExpertRequest
  expertOptions: DropdownOption[]
}

export default function ExpertRequestsRow({
  expertRequest,
  expertOptions,
}: Props): React.ReactElement {
  const [statusCopy, statusColor] = getStatusInfo(expertRequest)

  return (
    <BodyRow>
      <BodyData collapse>
        <ExpertRequestExpandButton
          expertOptions={expertOptions}
          expertRequest={expertRequest}
        />
      </BodyData>
      <BodyData>
        <Txt size={14}>{expertRequest.dueDayjs.format(DATE_FORMAT.ONLY_DATE)}</Txt>
      </BodyData>
      <BodyData>
        <Link
          to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
            clientId: expertRequest.client.id,
          })}
        >
          <Txt size={14} link>
            {expertRequest.client.name}
          </Txt>
        </Link>
      </BodyData>
      <BodyData>
        <Txt size={14}>{expertRequest.client.talentAgent.name}</Txt>
      </BodyData>
      <BodyData>
        <Tag color={statusColor} small>
          <Txt size={14}>{statusCopy}</Txt>
        </Tag>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {expertRequest.createdAtDayjs.format(DATE_FORMAT.ONLY_DATE)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {expertRequest.expert ? (
            <ExternalLink
              url={expertRequest.expert.linkedinUrl}
              inheritStyles
              label={expertRequest.expert.name}
            />
          ) : (
            EMPTY_CELL_PLACEHOLEDER
          )}
        </Txt>
      </BodyData>
      <BodyData>
        {expertRequest.interviewCycle ? (
          <Link
            to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
              clientId: expertRequest.client.id,
              interviewCycleId: expertRequest.interviewCycle.id,
            })}
          >
            <Txt size={14} link>
              {getJobInfoLabel(expertRequest.interviewCycle)}
            </Txt>
          </Link>
        ) : (
          EMPTY_CELL_PLACEHOLEDER
        )}
      </BodyData>
    </BodyRow>
  )
}

ExpertRequestsRow.fragments = {
  ExpertRequestsRowInfo: gql`
    fragment ExpertRequestsRowInfo on ExpertRequest {
      id
      client {
        id
        name
        talentAgent {
          id
          name
        }
      }
      interviewCycle {
        id
        jobTitle
        employerName
      }
      createdAt
      due
      ...StatusInfo
      expert {
        id
        name
        linkedinUrl
      }
    }
    ${getStatusInfo.fragments.statusInfo}
  `,
}

type ExpertRequestExpandButtonProps = {
  expertRequest: ExpertRequest
  expertOptions: DropdownOption[]
}

function ExpertRequestExpandButton({
  expertRequest,
  expertOptions,
}: ExpertRequestExpandButtonProps) {
  const { expertRequestId: openExpertRequestId } = useParams<Params>()
  const history = useHistory()

  function handleClose() {
    history.push(ROUTE_PATHS.EXPERT_REQUESTS)
  }
  function handleOpen() {
    history.push(
      generatePath(ROUTE_PATHS.EXPERT_REQUEST_DETAIL, {
        expertRequestId: expertRequest.id,
      })
    )
  }
  return (
    <button onClick={handleOpen}>
      <Icon
        name="Expand"
        primaryFill="text"
        primaryFillHover="black"
        height={14}
        width={14}
      />
      <EditExpertRequestStatus
        handleClose={handleClose}
        isOpen={openExpertRequestId === expertRequest.id}
        expertRequestId={expertRequest.id}
        expertOptions={expertOptions}
      />
    </button>
  )
}
