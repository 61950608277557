import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { EMPTY_CELL_PLACEHOLEDER, NOT_AVAILABLE, UNKNOWN } from 'utils/constants'
import ClientConfigWorkData from 'views/ClientConfig/ClientConfigWorkData'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import WrappedTooltip from 'components/WrappedTooltip/WrappedTooltip'
import {
  getAppsData,
  getCyclesData,
  getIncomeShareRateValue,
  getSignDateValue,
  getSourcingNotInterestedRateValue,
  getThisWeekAndLastWeekDates,
  getOutstandingOffers,
  AggregatedStats,
  getCallOneDate,
  getInterviewRate,
  isInTrialPeriod,
} from './ClientTracker.helpers'
import { Client } from './ClientTracker.types'
import ClientTrackerActivityLog from './ClientTrackerActivityLog'
import ClientTrackerStatusUpdateForm from './ClientTrackerStatusUpdateForm'
import Container from 'components/Container'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { GreyBackground, Tag } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import {
  GetClientTrackerInfoQuery,
  GetClientTrackerInfoQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}
export default function ClientTracker({ clientId }: Props): React.ReactElement {
  const { startOfWeek, endOfWeek, startOfLastWeek, endOfLastWeek } =
    getThisWeekAndLastWeekDates()
  const { data } = useQuery<
    GetClientTrackerInfoQuery,
    GetClientTrackerInfoQueryVariables
  >(GET_CLIENT_TRACKER_INFO, {
    variables: {
      clientId,
      thisWeekDateFilter: {
        start: startOfWeek,
        end: endOfWeek,
      },
      lastWeekDateFilter: {
        start: startOfLastWeek,
        end: endOfLastWeek,
      },
    },
  })

  const client = data?.client

  return (
    <ViewBox>
      <Padding top={2} bottom={6}>
        <Container noMax>
          <Flex justify="space-between">
            <ClientTrackerAccountInfo client={client} />
            <ClientTrackerTags client={client} />
          </Flex>
          <Padding top={5}>
            {client ? (
              <ClientTrackerStatusUpdateForm client={client} clientId={clientId} />
            ) : (
              <RectangleSkeleton
                height={284}
                fullWidth
                width="100%"
                withViewBox={false}
              />
            )}
          </Padding>
          <Padding top={5}>
            <Padding bottom={3}>
              <Txt as="h2" size={22}>
                In Progress Work
              </Txt>
            </Padding>
            <ClientConfigWorkData clientId={clientId} />
          </Padding>
          <Padding top={5}>
            <Padding bottom={3}>
              <Txt as="h2" size={22}>
                Historical Stats
              </Txt>
            </Padding>
            <ClientTrackerAggregatedStats client={client} />
          </Padding>
          <Padding top={5}>
            <ClientTrackerMetrics client={client} />
          </Padding>
          <Padding top={5}>
            <Padding bottom={3}>
              <Txt as="h2" size={22}>
                Activity Log
              </Txt>
            </Padding>
            <ClientTrackerActivityLog clientId={clientId} />
          </Padding>
        </Container>
      </Padding>
    </ViewBox>
  )
}

type SectionProps = {
  client: Client | undefined | null
}

function ClientTrackerAccountInfo({ client }: SectionProps): React.ReactElement {
  const clientExists = Boolean(client)
  const incomeShareRateValue = getIncomeShareRateValue(client)
  const signDateValue = getSignDateValue(client)
  const callOneDate = getCallOneDate(client)
  return (
    <VList size={1}>
      <InlineValueProp
        value={signDateValue}
        label="Signed"
        showContent={clientExists}
      />
      <InlineValueProp
        value={client?.contractTypeDescriptionCurrent ?? UNKNOWN.UNKNOWN}
        label="SKU"
        showContent={clientExists}
      />
      <InlineValueProp
        value={incomeShareRateValue ?? UNKNOWN.RATE}
        label="Rate"
        showContent={clientExists}
      />
      <InlineValueProp
        value={callOneDate ?? UNKNOWN.UNKNOWN}
        label="Call 1 Date"
        showContent={clientExists}
      />
    </VList>
  )
}

function ClientTrackerTags({ client }: SectionProps): React.ReactElement {
  return (
    <div>
      <HList size={1} rowGapSize={1}>
        {isInTrialPeriod(client) && (
          <Tag small color="successLight">
            <Txt size={12} bold color="white" uppercase spacing={0.5}>
              In Trial
            </Txt>
          </Tag>
        )}
        {client?.isPaused && (
          <Tag small color="warning">
            <Txt size={12} bold color="white" uppercase spacing={0.5}>
              Paused
            </Txt>
          </Tag>
        )}
        {client?.isFakePaused && (
          <Tag small color="warning">
            <Txt size={12} bold color="white" uppercase spacing={0.5}>
              Fake Paused
            </Txt>
          </Tag>
        )}
        {client?.isCanceledCurrent && (
          <Tag small color="errorLight">
            <Txt size={12} bold color="white" uppercase spacing={0.5}>
              Canceled
            </Txt>
          </Tag>
        )}
      </HList>
    </div>
  )
}

function ClientTrackerAggregatedStats({ client }: SectionProps): React.ReactElement {
  const clientExists = Boolean(client)
  const appStats = getAppsData(client)
  const cycleStats = getCyclesData(client)

  return (
    <VList size={2}>
      <AggregatedStatsSection
        stats={appStats}
        statsType="apps"
        clientExists={clientExists}
      />
      <AggregatedStatsSection
        stats={cycleStats}
        statsType="cycles"
        clientExists={clientExists}
      />
    </VList>
  )
}

type AggregatedStatsSectionProps = {
  stats: AggregatedStats
  statsType: string
  clientExists: boolean
}

function AggregatedStatsSection({
  stats: { active, thisWeek, lastWeek, lifetime },
  statsType,
  clientExists,
}: AggregatedStatsSectionProps): React.ReactElement {
  return (
    <GreyBackground>
      <Padding vertical={3}>
        <Flex justify="space-between" align="center">
          <Padding left={4} style={{ width: '14rem' }}>
            <Txt size={18} bold uppercase>
              {statsType}
            </Txt>
          </Padding>
          {active != null && (
            <AggregatedStatsItem
              value={active}
              label="Active"
              clientExists={clientExists}
            />
          )}
          <AggregatedStatsItem
            value={thisWeek ?? UNKNOWN.UNKNOWN}
            label="This week"
            clientExists={clientExists}
            tooltipContent="Week starting on Sunday, ending on Saturday"
          />
          <AggregatedStatsItem
            value={lastWeek ?? UNKNOWN.UNKNOWN}
            label="Last week"
            clientExists={clientExists}
            tooltipContent="Week starting on Sunday, ending on Saturday"
          />
          <AggregatedStatsItem
            value={lifetime ?? UNKNOWN.UNKNOWN}
            label="Lifetime"
            clientExists={clientExists}
          />
        </Flex>
      </Padding>
    </GreyBackground>
  )
}

type AggregatedStatsItemProps = {
  label: string
  value: string | number
  clientExists: boolean
  tooltipContent?: string
}

function AggregatedStatsItem({
  label,
  value,
  clientExists,
  tooltipContent,
}: AggregatedStatsItemProps): React.ReactElement {
  return (
    <Flex column align="center" auto>
      <Padding bottom={2}>
        <Txt as="span">{label}</Txt>
        {tooltipContent && (
          <Padding left={0.5} inline>
            <WrappedTooltip content={tooltipContent} />
          </Padding>
        )}
      </Padding>
      <Txt size={28} bold>
        {clientExists ? value : EMPTY_CELL_PLACEHOLEDER}
      </Txt>
    </Flex>
  )
}

function ClientTrackerMetrics({ client }: SectionProps): React.ReactElement {
  const clientExists = Boolean(client)
  const sourcingNotInterestedRateValue = getSourcingNotInterestedRateValue(client)
  const outstandingOffers = getOutstandingOffers(client)
  const interviewRate = getInterviewRate(client)
  return (
    <div>
      <Padding bottom={3}>
        <Txt as="h2" size={22}>
          Metrics
        </Txt>
      </Padding>
      <VList size={1}>
        <InlineValueProp
          value={client?.autoSourcingsWeekly ?? NOT_AVAILABLE}
          label="Weekly auto sourcing"
          showContent={clientExists}
        />
        <InlineValueProp
          value={sourcingNotInterestedRateValue}
          label="Sourcing not-interested rate"
          showContent={clientExists}
        />
        {/* interview cycles / applications submitted */}
        <InlineValueProp
          value={interviewRate}
          label="Interview rate"
          showContent={clientExists}
        />
        <InlineValueProp
          value={outstandingOffers ?? NOT_AVAILABLE}
          label="Outstanding offers"
          showContent={clientExists}
        />
        <InlineValueProp
          value={client?.totalPreps.totalCount ?? NOT_AVAILABLE}
          label="Total interview preps"
          showContent={clientExists}
        />
        <InlineValueProp
          value={client?.totalExpertRequests.totalCount ?? NOT_AVAILABLE}
          label="Total expert requests"
          showContent={clientExists}
        />
      </VList>
    </div>
  )
}

const GET_CLIENT_TRACKER_INFO = gql`
  query GetClientTrackerInfo(
    $clientId: ID!
    $thisWeekDateFilter: DatetimeRangeFilter!
    $lastWeekDateFilter: DatetimeRangeFilter!
  ) {
    client(id: $clientId) {
      id
      signDateInitial
      incomeShareRateCurrent
      contractTypeDescriptionCurrent
      callOneDate
      isPaused
      isFakePaused
      isCanceledCurrent
      autoSourcingsWeekly
      huntrJobActions {
        edges {
          node {
            jobActionType
            toListName
            fromListName
          }
        }
      }
      jobOffers {
        edges {
          node {
            isPending
          }
        }
      }
      statusUpdates {
        edges {
          node {
            id
            ...StatusUpdateFormFragment
          }
        }
      }
      totalApps: applications(hasApplicationSubmissionSubmission: true) {
        totalCount
      }
      thisWeekApps: applications(
        submittedAtRangeFilter: $thisWeekDateFilter
        hasApplicationSubmissionSubmission: true
      ) {
        totalCount
      }
      lastWeekApps: applications(
        submittedAtRangeFilter: $lastWeekDateFilter
        hasApplicationSubmissionSubmission: true
      ) {
        totalCount
      }
      totalCycles: interviewCycles {
        totalCount
      }
      activeCycles: interviewCycles(active: true) {
        totalCount
      }
      thisWeekCycles: interviewCycles(createdAtRangeFilter: $thisWeekDateFilter) {
        totalCount
      }
      lastWeekCycles: interviewCycles(createdAtRangeFilter: $lastWeekDateFilter) {
        totalCount
      }
      totalPreps: interviewPrepRequests {
        totalCount
      }
      totalExpertRequests: expertRequests {
        totalCount
      }
    }
  }
  ${ClientTrackerStatusUpdateForm.fragments.statusUpdate}
`
