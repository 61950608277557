import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { KeyValue, Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import { DossierQueueQuery, DossierQueueQueryVariables } from 'generated/graphql'

type QueueReturn = {
  queueByViewer: string[]
  loading: boolean
  queueByClient: KeyValue<string[]>
}
type CountReturn = {
  loading: boolean
  totalCount: Undefinable<number>
}

export default function useDossierQueue(
  queryOptions: QueryHookOptions<DossierQueueQuery, DossierQueueQueryVariables> = {}
): QueueReturn {
  const {
    userSession: {
      user: { id: userId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<DossierQueueQuery, DossierQueueQueryVariables>(
    GET_DOSSIER_QUEUE,
    {
      variables: {
        assigneeId: userId,
        onlyCount: false,
      },
      ...queryOptions,
    }
  )

  const dossierRequests =
    data?.dossierRequests?.edges
      ?.map((edge) => ({
        ...edge.node,
        sortableDue: dayjs(edge.node.due),
        clientName: edge.node.client.name,
      }))
      .sort((a, b) => sortByProp(a, b, 'sortableDue', 'clientName')) || []

  // map client ids to array of tagline requests
  const queueByClient = dossierRequests.reduce(
    (clientMap: KeyValue<string[]>, dossierRequest) => {
      const clientArray = clientMap[dossierRequest.client.id]
      if (clientArray) {
        clientArray.push(dossierRequest.id)
      } else {
        clientMap[dossierRequest.client.id] = [dossierRequest.id]
      }
      return clientMap
    },
    {}
  )

  const queueByViewer = dossierRequests.map(reduceObjectToId)

  return { queueByViewer, queueByClient, loading }
}

export function useDossierQueueCount(
  queryOptions: QueryHookOptions<DossierQueueQuery, DossierQueueQueryVariables> = {}
): CountReturn {
  const {
    userSession: {
      user: { id: userId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<DossierQueueQuery, DossierQueueQueryVariables>(
    GET_DOSSIER_QUEUE,
    {
      variables: {
        assigneeId: userId,
        onlyCount: true,
      },
      ...queryOptions,
    }
  )

  return { loading, totalCount: data?.dossierRequests?.totalCount ?? undefined }
}

/**
 * used by dossier writers to queue through their work
 */
const GET_DOSSIER_QUEUE = gql`
  query DossierQueue($assigneeId: ID!, $onlyCount: Boolean!) {
    dossierRequests(assigneeId: $assigneeId, isOpen: true) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          due
          client {
            id
            name
          }
        }
      }
    }
  }
`
