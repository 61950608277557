import { ApolloCache } from '@apollo/client'
import { CacheConnection, KeyValue, Undefinable, MutationResult } from 'types'
import { CACHE_STRING } from 'utils/cacheString'
import getNodes from 'utils/helpers/getNodes'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import { ExpertFragment } from './ExpertNetwork.constants'
import { Expert, ExpertConnection, ExpertFormState } from './ExpertNetwork.types'
import {
  CreateExpertMutation,
  CreateExpertMutationVariables,
  ExpertFunctionalArea,
  ExpertSeniorityLevel,
  Maybe,
  ModifyExpertMutationVariables,
} from 'generated/graphql'

export function buildTableData(
  connection: ExpertConnection,
  searchTerm: Maybe<string>
): Expert[] {
  let experts = getNodes<Expert>(connection)

  if (searchTerm) {
    experts = experts.filter((expert) => filterExpert(expert, searchTerm))
  }

  experts.sort((a, b) => sortByProp(a, b, 'name'))

  return experts
}

// filter the list of experts by search term
function filterExpert(
  { name, employers, industries, currentTitle }: Expert,
  searchTerm: string
): boolean {
  const searchTermLower = searchTerm.toLowerCase()
  if (name && name.toLowerCase().includes(searchTermLower)) {
    return true
  }
  if (currentTitle && currentTitle.toLowerCase().includes(searchTermLower)) {
    return true
  }
  if (
    employers.edges.some((employer) =>
      employer.node.name.toLowerCase().includes(searchTermLower)
    )
  ) {
    return true
  }
  if (
    industries.edges.some((industry) =>
      industry.node.name.toLowerCase().includes(searchTermLower)
    )
  ) {
    return true
  }

  return false
}

// build the variable object for create mutation
export function getCreateVariables(
  state: ExpertFormState
): CreateExpertMutationVariables {
  const seniorityLevels = getMaybeArrayFromMap<ExpertSeniorityLevel>(
    state.seniorityLevels
  )
  const functionalAreas = getMaybeArrayFromMap<ExpertFunctionalArea>(
    state.functionalAreas
  )

  return {
    CreateExpertInput: {
      // HTML validation assures name exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: state.name!,
      // HTML validation assures name exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      linkedinUrl: state.linkedinUrl!,
      email: state.email,
      currentTitle: state.currentTitle,
      seniorityLevels: seniorityLevels,
      functionalAreas: functionalAreas,
      is1099ed: state.is1099ed ?? undefined,
      isProBono: state.isProBono ?? undefined,
      hourlyRate: state.hourlyRate != null ? String(state.hourlyRate) : undefined,
      paymentType: state.paymentType ?? undefined,
      additionalPaymentDetails: state.additionalPaymentDetails ?? undefined,
      employers: state.employers ?? undefined,
      industryIds: state.industries ? state.industries.map(reduceObjectToId) : [],
    },
  }
}

// build the variable object for modify mutation
export function getModifyVariables(
  state: ExpertFormState,
  id: string
): ModifyExpertMutationVariables {
  const seniorityLevels = getMaybeArrayFromMap<ExpertSeniorityLevel>(
    state.seniorityLevels
  )
  const functionalAreas = getMaybeArrayFromMap<ExpertFunctionalArea>(
    state.functionalAreas
  )

  return {
    ModifyExpertInput: {
      expertId: id,
      // HTML validation assures name exists
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      linkedinUrl: state.linkedinUrl!,
      email: state.email,
      currentTitle: state.currentTitle,
      seniorityLevels: seniorityLevels,
      functionalAreas: functionalAreas,
      is1099ed: state.is1099ed ?? undefined,
      isProBono: state.isProBono ?? undefined,
      hourlyRate: state.hourlyRate != null ? String(state.hourlyRate) : undefined,
      paymentType: state.paymentType ?? undefined,
      additionalPaymentDetails: state.additionalPaymentDetails ?? undefined,
      employers: state.employers ?? [],
      industryIds: state.industries ? state.industries.map(reduceObjectToId) : [],
    },
  }
}

function getMaybeArrayFromMap<T extends ExpertSeniorityLevel | ExpertFunctionalArea>(
  map: Maybe<KeyValue<boolean>> | undefined
): T[] | undefined {
  return map
    ? Object.entries(map).reduce((items: T[], [item, checked]) => {
        if (checked) {
          items.push(item as T)
        }
        return items
      }, [])
    : undefined
}

// adds newly created expert to the current list of experts
export function addNewExpertToCache(
  cache: ApolloCache<CreateExpertMutation>,
  { data }: MutationResult<CreateExpertMutation>
): void {
  if (data?.createExpert) {
    cache.modify({
      fields: {
        [CACHE_STRING.EXPERT_LIST]: (
          existingExperts: CacheConnection = { edges: [] }
        ) => {
          const newExpertRef = cache.writeFragment({
            data: data.createExpert.expert,
            fragmentName: 'ExpertInfo',
            fragment: ExpertFragment,
          })

          return {
            ...existingExperts,
            edges: [...(existingExperts.edges || []), { node: newExpertRef }],
          }
        },
      },
    })
  }
}

// returns the default state of the form, differs if create vs update
export function getDefaultState(expert: Undefinable<Expert>): ExpertFormState {
  if (!expert) {
    return {}
  }
  return {
    name: expert.name,
    linkedinUrl: expert.linkedinUrl,
    email: expert.email,
    currentTitle: expert.currentTitle,
    seniorityLevels: expert.seniorityLevels.reduce(
      (map: KeyValue<boolean>, level) => {
        map[level] = true
        return map
      },
      {}
    ),
    functionalAreas: expert.functionalAreas.reduce(
      (map: KeyValue<boolean>, area) => {
        map[area] = true
        return map
      },
      {}
    ),
    is1099ed: expert.is1099ed,
    isProBono: expert.isProBono,
    hourlyRate: expert.hourlyRate,
    paymentType: expert.paymentType,
    additionalPaymentDetails: expert.additionalPaymentDetails,
    employers: expert.employers.edges.map(({ node: { name, domain, logoUrl } }) => ({
      name,
      domain,
      logoUrl,
    })),
    industries: expert.industries.edges.map(({ node }) => node),
  }
}
