import { useMutation, gql } from '@apollo/client'
import React, { useState, useRef } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactFormEvent } from 'types'
import { QS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { buildFormData } from './AddAndEditSourcingRequest.helpers'
import { FormData, SourcingRequest } from './AddAndEditSourcingRequest.types'
import { TextArea, DatePicker, Dropdown, TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CreateSourcingRequestMutation,
  CreateSourcingRequestMutationVariables,
  ModifySourcingRequestMutation,
  ModifySourcingRequestMutationVariables,
} from 'generated/graphql'

type Props = {
  freeAgents: {
    id: string
    name: string
  }[]
  isSubmitting: boolean
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
  sourcingRequest: SourcingRequest | null | undefined
  isEditPage: boolean
}

const AddAndEditSourcingRequestFormBox = styled.form`
  margin: 0 auto;
  width: 50%;
  padding-bottom: ${({ theme }) => theme.rhythm(4)};
`

function AddAndEditSourcingRequestForm({
  freeAgents,
  isSubmitting,
  setIsSubmitting,
  sourcingRequest,
  isEditPage,
}: Props): JSX.Element {
  const history = useHistory()
  const queryString = useQueryString()
  const submissionRoute = queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME
  const defaultCurrentFreeAgentId =
    sourcingRequest?.client.id || queryString.get(QS.CLIENT_ID) || undefined
  const [currentFreeAgentId, setCurrentFreeAgentId] = useState(
    defaultCurrentFreeAgentId
  )
  const formRef = useRef<HTMLFormElement>(null)

  const [createRequest] = useMutation<
    CreateSourcingRequestMutation,
    CreateSourcingRequestMutationVariables
  >(CREATE_SOURCING_REQUEST, {
    onCompleted() {
      toast.success(`New sourcing request created`)
      formRef.current?.reset()
      setCurrentFreeAgentId(defaultCurrentFreeAgentId)
      setIsSubmitting(false)
    },
    onError() {
      toast.error(`There was an error submitting your request`)
      setIsSubmitting(false)
    },
  })

  const [modifyRequest] = useMutation<
    ModifySourcingRequestMutation,
    ModifySourcingRequestMutationVariables
  >(MODIFY_SOURCING_REQUEST, {
    onCompleted() {
      toast.success(`Sourcing request created`)
      history.push(submissionRoute)
    },
    onError() {
      toast.error(`There was an error modifying your request`)
      setIsSubmitting(false)
    },
  })

  const freeAgentDropdownOptions = buildDropdownOptions(freeAgents)

  /**
   * handle validating and executing submitting/updating mutation
   * @param event submission event
   */
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    if (isSubmitting) {
      return
    }
    const formData = buildFormData(event)
    setIsSubmitting(true)
    if (isEditPage) {
      modifySourcingRequest(formData)
    } else {
      createSourcingRequest(formData)
    }
  }

  function createSourcingRequest(formData: FormData) {
    const variables = { CreateSourcingRequestInput: { ...formData } }
    void createRequest({ variables })
  }

  function modifySourcingRequest(formData: FormData) {
    if (!sourcingRequest) {
      return toast.error('Cannot find sourcing request to update')
    }
    const variables = {
      ModifySourcingRequestInput: {
        ...formData,
        sourcingRequestId: sourcingRequest.id,
      },
    }
    void modifyRequest({ variables })
  }

  return (
    <AddAndEditSourcingRequestFormBox
      onSubmit={handleSubmit}
      ref={formRef}
      id="newSourcingRequest"
    >
      <Padding bottom={4}>
        <Txt as="h2" size={24} bold>
          New Sourcing Request
        </Txt>
      </Padding>
      <VList size={4}>
        <Dropdown
          name="clientId"
          label="Free Agent"
          options={freeAgentDropdownOptions}
          onValueChange={setCurrentFreeAgentId}
          disabled={isEditPage}
          value={currentFreeAgentId || ''}
          required
        />
        <TextInput
          name="numJobs"
          label="Number of Jobs"
          type="number"
          defaultValue={sourcingRequest?.numJobs ?? 1}
          min={0}
          required
        />
        <TextArea
          name="notes"
          label="Override Notes/Other"
          height={80}
          defaultValue={sourcingRequest?.notes}
        />
        <DatePicker
          name="due"
          label="Due Date"
          required
          defaultValue={sourcingRequest?.due}
        />
      </VList>
    </AddAndEditSourcingRequestFormBox>
  )
}

AddAndEditSourcingRequestForm.fragments = {
  clientInfo: gql`
    fragment ClientInfoSourcingRequestForm on Client {
      id
      name
    }
  `,
}

/**
 * Used to create a new sourcing request
 */
const CREATE_SOURCING_REQUEST = gql`
  mutation CreateSourcingRequest(
    $CreateSourcingRequestInput: CreateSourcingRequestInput!
  ) {
    createSourcingRequest(input: $CreateSourcingRequestInput) {
      sourcingRequest {
        id
        client {
          name
        }
      }
    }
  }
`

/**
 * Used to modify a sourcing request
 */
const MODIFY_SOURCING_REQUEST = gql`
  mutation ModifySourcingRequest(
    $ModifySourcingRequestInput: ModifySourcingRequestInput!
  ) {
    modifySourcingRequest(input: $ModifySourcingRequestInput) {
      # selecting all fields so that cache updates automatically
      sourcingRequest {
        id
        notes
        due
        numJobs
        client {
          id
          name
        }
      }
    }
  }
`

export default AddAndEditSourcingRequestForm
