import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { EOD_HOUR, EOD_MINUTE, SOURCING_DUE_TZ } from 'utils/constants'
import { getDueTime } from 'utils/helpers/time'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { SourcingRequest } from './SourcingSubmission.types'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt, { Bold } from 'components/Txt'
import VList from 'components/VList'

type Props = {
  sourcingRequest: SourcingRequest
}

function SourcingSubmissionLeft({ sourcingRequest }: Props): JSX.Element {
  const dueLabel = `Due ${getDueTime(
    dayjs(sourcingRequest.due),
    EOD_HOUR,
    EOD_MINUTE,
    SOURCING_DUE_TZ
  )}`

  return (
    <>
      <Padding bottom={4}>
        <Flex justify="space-between" align="center">
          <Txt as="h2" size={24} bold>
            {sourcingRequest.client.name}
          </Txt>
          <Txt>{dueLabel}</Txt>
        </Flex>
        <div>
          <Padding top={2.5}>
            <VList size={1.5}>
              <Txt size={14} lineHeight={1.3}>
                Talent Agent: <Bold>{sourcingRequest.client.talentAgent.name}</Bold>
              </Txt>
              <Txt size={14} lineHeight={1.3}>
                Experience Level:&nbsp;
                <Bold>{sourcingRequest.client.roleExperience || 'Unknown'}</Bold>
              </Txt>
              <Txt size={14} lineHeight={1.3}>
                Role Category:&nbsp;
                <Bold>{sourcingRequest.client.roleCategory || 'Unknown'}</Bold>
              </Txt>
              <Txt size={14} lineHeight={1.3}>
                Remote Prefrences:&nbsp;
                <Bold>{sourcingRequest.client.remotePrefs || 'Unknown'}</Bold>
              </Txt>
              <Txt size={14} lineHeight={1.3}>
                Desired Salary:&nbsp;
                <Bold>{sourcingRequest.client.desiredSalary || 'Unknown'}</Bold>
              </Txt>
              <Txt size={14} lineHeight={1.3}>
                Desired Company Size:&nbsp;
                <Bold>{sourcingRequest.client.desiredCompanySize || 'Unknown'}</Bold>
              </Txt>
              <Txt size={14} lineHeight={1.3}>
                Desired Industries:&nbsp;
                <Bold>{sourcingRequest.client.desiredIndustries || 'Unknown'}</Bold>
              </Txt>
            </VList>
          </Padding>
        </div>
      </Padding>
      <VList size={4}>
        <WorkInfoLinks
          questionnaireUrl={sourcingRequest.client.questionnaireUrl}
          resumeUrl={sourcingRequest.client.resumeUrl}
          linkedinUrl={sourcingRequest.client.linkedinUrl}
          huntrUrl={sourcingRequest.client.huntrUrl}
        />
        <TextArea
          defaultValue={sourcingRequest.notes}
          label="Notes from TA"
          disabled
        />
        <TextArea
          defaultValue={sourcingRequest.client.jobPrefs || ''}
          label="Job Preferences"
          disabled
        />
        <TextArea
          defaultValue={sourcingRequest.client.locationPrefs || ''}
          label="Location Preferences"
          disabled
        />
        {sourcingRequest.client.bio && (
          <TextArea
            defaultValue={sourcingRequest.client.bio}
            label={`${sourcingRequest.client.name} Bio`}
            height={80}
            disabled
          />
        )}
        {sourcingRequest.client.resumeUrl && (
          <PDFViewer url={sourcingRequest.client.resumeUrl} />
        )}
      </VList>
    </>
  )
}

SourcingSubmissionLeft.fragments = {
  sourcingRequestInfo: gql`
    fragment SourcingSubmissionLeftInfo on SourcingRequest {
      notes
      due
      client {
        name
        resumeUrl
        linkedinUrl
        locationPrefs
        jobPrefs
        roleExperience
        roleCategory
        bio
        talentAgent {
          name
        }
        remotePrefs
        huntrUrl
        desiredSalary
        desiredCompanySize
        desiredIndustries
        questionnaireUrl
      }
    }
  `,
}

export default SourcingSubmissionLeft
