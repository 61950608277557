import { DropdownOption } from 'types'
import { EMPTY_INPUT } from 'utils/constants'
import { InterviewPrepType } from 'generated/graphql'

export const DEFUALT_STATE = {
  clientId: EMPTY_INPUT,
  interviewCycleId: EMPTY_INPUT,
  interviewPrepType: null,
  notes: EMPTY_INPUT,
  due: EMPTY_INPUT,
}

export const ID = {
  FORM: 'addEditInterviewPrepRequestForm',
}

export const COPY = {
  CREATE_SUCCESS: 'Interview prep request added',
  CREATE_ERROR: 'There was an error adding the interview prep request',
  TYPE_REQURIED: 'Interview Prep Type is required',
}

export const interviewPrepTypeOptions: DropdownOption<InterviewPrepType>[] = [
  {
    value: InterviewPrepType.MOCK_INTERVIEW,
    label: 'Mock Interview (30 Minutes)',
  },
  {
    value: InterviewPrepType.DEEP_DIVE_INTERVIEW_PREP,
    label: 'Deep Dive Interview Prep (30-60 Minutes)',
  },
]
