import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/Button'

type Props = {
  to: string
  content: React.ReactNode
}

function TableBodyAction({ to, content }: Props): JSX.Element {
  return (
    <Link to={to}>
      <Button small>{content}</Button>
    </Link>
  )
}

export default TableBodyAction
