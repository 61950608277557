import { FormData } from './AddAndEditSourcingRequest.types'

/**
 * creates a formData object given inputs from the form
 * @param event submission event
 */
export function buildFormData(event: React.FormEvent<HTMLFormElement>): FormData {
  return {
    clientId: event.currentTarget.clientId.value, // eslint-disable-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
    numJobs: Number(event.currentTarget.numJobs.value), // eslint-disable-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
    notes: event.currentTarget.notes.value, // eslint-disable-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
    due: event.currentTarget.due.value, // eslint-disable-line @typescript-eslint/no-unsafe-assignment,  @typescript-eslint/no-unsafe-member-access
  }
}
