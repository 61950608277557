import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'
import getNodes from 'utils/helpers/getNodes'
import { LAST_30_DAYS } from 'components/TalentAgentClientCards/TalentAgentClientCards.constants'
import { getClientsActivity } from './DashboardCard.helpers'
import { DashboardCard, DashboardContent } from './DashboardCard.styles'
import { DateFilter } from './DashboardCard.types'
import DashboardCardTitle from './DashboardCardTitle'
import DashboardClientsActivityCard from './DashboardClientsActivityCard'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientsActivityQuery,
  GetClientsActivityQueryVariables,
} from 'generated/graphql'

type Props = {
  talentAgentId: string
}

export default function DashboardCardClientsActivity({
  talentAgentId,
}: Props): React.ReactElement {
  const [dateFilter, setDateFilter] = useState<DateFilter | null>(null)
  const clientActivityOptions = dateFilter
    ? { variables: { talentAgentId, dateFilter } }
    : { skip: true }
  useEffect(() => {
    setDateFilter({
      start: dayjs().subtract(LAST_30_DAYS, 'days').format(),
      end: dayjs().format(),
    })
  }, [])
  const { data, loading } = useQuery<
    GetClientsActivityQuery,
    GetClientsActivityQueryVariables
  >(GET_CLIENTS_ACTIVITY, clientActivityOptions)
  const clients = data?.clients && getNodes(data.clients)
  const activity = clients ? getClientsActivity(clients) : []
  return (
    <DashboardCard>
      <DashboardCardTitle title="Clients Activity" />
      <DashboardContent>
        {loading && <LoadingIndicator />}
        {!activity.length && !loading && (
          <Padding all={2}>
            <Txt>There are no activities made by clients.</Txt>
          </Padding>
        )}
        {activity.map((activityInfo) => (
          <DashboardClientsActivityCard
            key={activityInfo.id}
            activityInfo={activityInfo}
          />
        ))}
      </DashboardContent>
      <Padding vertical={0.5} />
    </DashboardCard>
  )
}

const GET_CLIENTS_ACTIVITY = gql`
  query GetClientsActivity($talentAgentId: ID!, $dateFilter: DatetimeRangeFilter!) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          ...ClientsActivity
        }
      }
    }
  }
  ${getClientsActivity.fragments.clientsActivity}
`
