import { DropdownOption } from 'types'
import { sortByProp } from 'utils/sort'

type GenericOptionsItem = {
  id: string
  name: string
}

/**
 * sorts and builds an array of options for a dropdown
 * @param items list of objects with name and id properties
 */
export default function buildDropdownOptions(
  items: GenericOptionsItem[]
): DropdownOption[] {
  return items
    .map(mapNameToOption)
    .sort((a: DropdownOption, b: DropdownOption) => sortByProp(a, b, 'label'))
}

/**
 * constructs an option for the given item so that it can be mapped over in Dropdown
 * @param item item object with id and name
 */
function mapNameToOption(item: GenericOptionsItem): DropdownOption {
  return { value: item.id, label: item.name }
}

type JobOptionsItem = {
  id: string
  jobTitle?: string | null
  employerName?: string | null
}

/**
 * sorts and builds an array of options for a dropdown
 * @param items list of objects with name and id properties
 */
export function buildJobDropdownOptions(items: JobOptionsItem[]): DropdownOption[] {
  return items
    .map(mapJobToOption)
    .sort((a: DropdownOption, b: DropdownOption) => sortByProp(a, b, 'label'))
}

/**
 * constructs an option for the given item so that it can be mapped over in Dropdown
 * @param item item object with id jobtitle and employerName
 */
function mapJobToOption(item: JobOptionsItem): DropdownOption {
  return {
    value: item.id,
    label: `${item.jobTitle || 'Unknown'} @ ${item.employerName || 'Unknown'}`,
  }
}
