import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import 'sentry'
import 'react-toastify/dist/ReactToastify.css'
import Routes from 'Routes'
import AuthProvider from 'components/AuthProvider'
import NavProvider from 'components/NavProvider'

export default function App(): JSX.Element {
  return (
    <AuthProvider>
      <NavProvider>
        <Router>
          <Routes />
        </Router>
      </NavProvider>
    </AuthProvider>
  )
}
