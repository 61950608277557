import React from 'react'
import { DropdownOption } from 'types'
import Button from './Button'
import HList from './HList'

type Props = {
  options: DropdownOption[]
  onClick: (value: string) => void
}
export default function ButtonList({ options, onClick }: Props): React.ReactElement {
  return (
    <HList size={1}>
      {options.map((option) => (
        <Button key={option.label} onClick={() => onClick(option.value)} small>
          {option.label}
        </Button>
      ))}
    </HList>
  )
}
