import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import styled from 'styled-components/macro'
import ROUTE_PATHS from 'utils/routePaths'
import { REQUEST_MAGIC_LINK } from 'gql/mutations'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Label from 'components/Label'
import Padding from 'components/Padding'
import PublicRoute from 'components/PublicRoute'
import Txt from 'components/Txt'
import {
  RequestMagicLinkMutation,
  RequestMagicLinkMutationVariables,
} from 'generated/graphql'

const LoginForm = styled.form`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
`

const EmailInput = styled.input.attrs((props) => ({
  type: 'email',
  id: props.id || 'email',
  name: props.name || 'email',
  value: props.value,
  onChange: props.onChange,
}))`
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  padding: ${({ theme }) => theme.rhythm(1)};
  width: ${({ theme }) => theme.toRems(200)};
`

export default function Login(): JSX.Element {
  const [email, setEmail] = useState<string>('')
  const [requestMagicLink, { data }] = useMutation<
    RequestMagicLinkMutation,
    RequestMagicLinkMutationVariables
  >(REQUEST_MAGIC_LINK)

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    if (!email) {
      return
    }
    void requestMagicLink({
      variables: { RequestMagicLinkInput: { email: email.trim().toLowerCase() } },
    })
  }

  return (
    <Padding top={4}>
      <Flex justify="center" align="center">
        <LoginForm id="login-form" onSubmit={handleSubmit}>
          <Padding all={4}>
            {data?.requestMagicLink.success ? (
              <Txt size={20}>Magic link sent!</Txt>
            ) : (
              <>
                <Padding bottom={4}>
                  <Txt size={20}>Login to Deskjockey</Txt>
                </Padding>
                <Label htmlFor="email" padding={1} content="Email" />
                <EmailInput
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  required
                />
                <Padding top={4}>
                  <Button
                    as="input"
                    form="login-form"
                    type="submit"
                    $type="secondary"
                    value="Login"
                  />
                </Padding>
              </>
            )}
          </Padding>
        </LoginForm>
      </Flex>
    </Padding>
  )
}

Login.Routes = [
  <PublicRoute exact path={ROUTE_PATHS.LOGIN} key={ROUTE_PATHS.LOGIN}>
    <Login />
  </PublicRoute>,
]
