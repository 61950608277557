import React, { useState } from 'react'
import Icon from 'components/Icon/Icon'
import { ColumnSortState, ColumnSortType } from './FullWidthTable.types'
import Txt from 'components/Txt'

type HeaderLabelSortableProps = {
  id: string
  label: string
  columnSortState: ColumnSortState
  onColumnSortChange: (id: string, value: ColumnSortState) => void
}

export default function HeaderLabelSortable({
  id,
  label,
  columnSortState,
  onColumnSortChange,
}: HeaderLabelSortableProps): React.ReactElement {
  const [hovered, setHovered] = useState<boolean>(false)

  function renderSortArrow() {
    if (columnSortState.orderBy === id) {
      return <SortArrow direction={columnSortState.direction} />
    }
    return <SortArrow hovered={hovered} />
  }

  return (
    <button
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
      onClick={() => onColumnSortChange(id, columnSortState)}
    >
      <Txt size={12} bold as="span">
        {label}
      </Txt>
      {renderSortArrow()}
    </button>
  )
}

type SortArrowProps = {
  direction?: ColumnSortType
  hovered?: boolean
}

function SortArrow({ direction, hovered }: SortArrowProps): React.ReactElement {
  if (direction === ColumnSortType.DESC) {
    return (
      <Icon
        name="CaretArrowDown"
        height={14}
        width={14}
        primaryFill="black"
        nudge={{ down: 0.35, right: 0.7 }}
      />
    )
  }
  if (direction === ColumnSortType.ASC) {
    return (
      <Icon
        name="CaretArrowDown"
        height={14}
        width={14}
        primaryFill="text"
        nudge={{ down: 0.35, right: 0.7 }}
        rotateDegrees={180}
      />
    )
  }
  return (
    <Icon
      name="Minus"
      height={14}
      width={14}
      primaryFill={hovered ? 'text' : 'faGrey3'}
      nudge={{ down: 0.35, right: 0.9 }}
    />
  )
}
