import styled from 'styled-components/macro'
import { showScrollbar } from 'components/MiscStyles'

export const ClientCardStyle = styled.div`
  position: relative;
  border-radius: ${({ theme }) => theme.toRems(10)};
  border: 1px solid ${({ theme }) => theme.color.lightGray};
  width: ${({ theme }) => theme.toRems(389)};
`
export const ClientCardHeader = styled.div`
  position: relative;
  width: 100%;
`

export const TrialBadge = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.toRems(82)};
  height: ${({ theme }) => theme.toRems(26)};
  border-radius: ${({ theme }) => theme.toRems(10)};
  background-color: ${({ theme }) => theme.color.iconLightBlue};
  position: absolute;
  top: 0;
  right: 0;
`

export const LoadingIndicatorContainer = styled.div`
  position: relative;
  height: calc(100vh - ${({ theme }) => theme.toRems(270)});
  margin-bottom: ${({ theme }) => theme.rhythm(3)};
`

export const ClientCardRowLabel = styled.div`
  position: absolute;
  top: ${({ theme }) => theme.rhythm(-2)};
  white-space: nowrap;
`

export const Divider = styled.div`
  width: ${({ theme }) => theme.toRems(1)};
  height: auto;
  background-color: ${({ theme }) => theme.color.lightGray};
`
type ColumnProps = {
  align?: React.CSSProperties['alignItems']
}

export const ClientCardColumn = styled.div<ColumnProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: ${({ align = 'center' }) => align};
  width: ${({ theme }) => theme.toRems(65)};
  padding-top: ${({ theme }) => theme.rhythm(1)};
  padding-bottom: ${({ theme }) => theme.rhythm(1)};
`
export const UpcomingInterviewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-height: ${({ theme }) => theme.toRems(62)};
  overflow-y: scroll;
  ${showScrollbar}
`

export const ClientCardContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: ${({ theme }) => theme.toRems(32)};
`
