export const TYPE = {
  APPLICATION_REQUESTED: 'Application Requested',
  APPLICATION_REQUEST_UPDATED: 'Application Request Updated',
  APPLICATION_SUBMITTED: 'Application Submitted',
  APPLICATION_REJECTED: 'Application Rejected',
  APPLICATION_YELLOW_CARDED: 'Application Yellow Carded',
  DOSSIER_REJECTED: 'Dossier Rejected',
  DOSSIER_REQUESTED: 'Dossier Requested',
  DOSSIER_SUBMITTED: 'Dossier Submitted',
  ENRICHMENT_REQUESTED: 'Enrichment Requested',
  ENRICHMENT_SUBMITTED: 'Enrichment Submitted',
  ENRICHMENT_REJECTED: 'Enrichment Rejected',
  EXPERT_REQUESTED: 'Expert Requested',
  HUNTR_CARD_MOVED: 'Huntr Card Moved',
  HUNTR_CARD_CREATED: 'Huntr Card Created',
  MISSING_APPLICATION_INFO_ANSWERED: 'Missing Application Info Answered',
  MISSING_APPLICATION_INFO_DECLINED: 'Missing Application Info Declined',
  MISSING_APPLICATION_INFO_REQUESTED: 'Missing Application Info Requested',
  INTERVIEW_ADDED: 'Interview Added',
  INTERVIEW_CYCLE_CLOSED: 'Interview Cycle Closed',
  INTERVIEW_CYCLE_STARTED: 'Interview Cycle Started',
  INTERVIEW_DEBRIEF_ADDED: 'Interview Debrief Added',
  INTERVIEW_PREP_REQUESTED: 'Interview Prep Requested',
  JOB_SOURCED: 'Job Sourced',
  SOURCED_JOB_APPROVED: 'Sourced Job Approved',
  SOURCED_JOB_REJECTED: 'Sourced Job Rejected',
  SOURCED_JOB_REDACTED: 'Sourced Job Redacted',
  TAGLINE_REQUESTED: 'Tagline Requested',
  TAGLINE_REQUEST_REJECTED: 'Tagline Request Rejected',
  TAGLINE_SUBMITTED: 'Tagline Submitted',
  INITIAL_REVIEW_APPROVED: 'Initial Review Approved',
  INITIAL_REVIEW_REJECTED: 'Initial Review Rejected',
  FINAL_REVIEW_APPROVED: 'Final Review Approved',
  FINAL_REVIEW_REJECTED: 'Final Review Rejected',
}

export enum HuntrActionType {
  Created = 'CREATED',
  Moved = 'MOVED',
  Updated = 'UPDATED',
}
