import { TZ } from 'utils/constants'
import { SOURCING_REVIEW_MAX_DAYS } from 'utils/settings'

export const INITIAL_RANGE = 7
export const DEFAULT_TZ = TZ.MANILA

export const COLUMNS = [
  {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  {
    label: 'Sourcer',
    id: 'sourcer',
  },
  {
    label: 'Rating',
    id: 'rating',
  },
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Reject/Redact Reason',
    id: 'rejectionReason',
  },
  {
    label: 'Job Description',
    id: 'jobDescription',
  },
  {
    label: 'Company',
    id: 'company',
  },
  {
    label: 'Job Title',
    id: 'jobTitle',
  },
  {
    label: 'Notes',
    id: 'notes',
  },
]

export const CSV_HEADERS = [
  { label: 'Client', key: 'clientName' },
  { label: 'Sourcer', key: 'sourcerName' },
  { label: 'Rating', key: 'rating' },
  { label: 'Status', key: 'status' },
  { label: 'Rejection Reason', key: 'rejectionReason' },
  { label: 'Job Description Link', key: 'url' },
  { label: 'Company', key: 'company' },
  { label: 'Job Title', key: 'title' },
  { label: 'Notes', key: 'notes' },
  { label: 'Date Sourced', key: 'createdAt' },
]

export const TOAST = {
  UPDATE_END: `End Date Updated: there is a ${SOURCING_REVIEW_MAX_DAYS} day maximum date range`,
  UPDATE_START: `Start Date Updated: there is a ${SOURCING_REVIEW_MAX_DAYS} day maximum date range`,
}
