import styled, { css } from 'styled-components/macro'

export type InputProps = {
  fullWidth?: boolean
  width?: number
  height?: number
  disabled?: boolean
  activeIndicator?: boolean
  minWidth?: number
  withIcon?: boolean
}

export const inputStyles = css<InputProps>`
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  border-radius: ${({ theme }) => theme.borderRadius};
  padding: ${({ theme }) => theme.rhythm(1)};
  color: ${({ theme }) => theme.color.text};
  ${({ fullWidth }) =>
    fullWidth &&
    css`
      width: 100%;
    `}
  ${({ theme, height }) =>
    height &&
    css`
      height: ${theme.toRems(height)};
    `}
  ${({ theme, minWidth }) =>
    minWidth &&
    css`
      min-width: ${theme.toRems(minWidth)};
    `}
  ${({ theme, width }) =>
    width &&
    css`
      width: ${theme.toRems(width)};
    `}
    ${({ activeIndicator }) =>
    activeIndicator &&
    css`
      border-left: 0.3rem solid ${({ theme }) => theme.color.purple};
    `}
  ${({ withIcon = false, theme }) =>
    withIcon &&
    css`
      padding-left: ${theme.rhythm(3.5)};
    `}
`

export const Input = styled.input<InputProps>`
  ${inputStyles}
`

export const Select = styled.select<InputProps>`
  ${inputStyles}
`

export const StyledTextArea = styled.textarea<InputProps>`
  ${inputStyles}
`
