import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactFormEvent } from 'types'
import {
  DATE_FORMAT,
  EMPTY_INPUT,
  LINKEDIN_VALIDATION_STRING,
} from 'utils/constants'
import { sortByProp } from 'utils/sort'
import {
  Grid,
  GridHeaders,
  GridItem,
} from 'views/InterviewCycleDetail/interviewCycleDetail.styles'
import CompanySuggestion from 'components/CompanySuggestion/CompanySuggestion'
import { SuggestionRowLogo } from 'components/CompanySuggestion/CompanySuggestion.styles'
import { Suggestion } from 'components/CompanySuggestion/CompanySuggestion.types'
import Icon from 'components/Icon/Icon'
import IndustrySuggestion from 'components/IndustrySuggestion/IndustrySuggestion'
import { IndustrySuggestion as IndustrySuggestionType } from 'components/IndustrySuggestion/IndustrySuggestion.types'
import Checkbox from 'components/Inputs/Checkbox'
import {
  ExpertFragment,
  EXPERT_REQUEST_HEADERS,
  FUNCTIONAL_AREA_OPTIONS,
  ID,
  PAYMENT_TYPE_OPTIONS,
  SENIORITY_LEVEL_OPTIONS,
  TOAST,
} from './ExpertNetwork.constants'
import {
  addNewExpertToCache,
  getCreateVariables,
  getDefaultState,
  getModifyVariables,
} from './ExpertNetwork.helpers'
import {
  CompanyInput,
  Expert,
  ExpertFormState,
  Industry,
} from './ExpertNetwork.types'
import Button from 'components/Button'
import { Dialog, DialogExitButton, DialogFooter } from 'components/DialogMisc'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { CheckboxGroup, RadioGroup, TextArea, TextInput } from 'components/Inputs'
import { SectionHeader } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  CreateExpertMutation,
  CreateExpertMutationVariables,
  ExpertPaymentType,
  Maybe,
  ModifyExpertMutation,
  ModifyExpertMutationVariables,
} from 'generated/graphql'

type Props = {
  handleClose: () => void
  isOpen: boolean
  expert?: Expert
}

export default function AddAndEditExpert({
  isOpen,
  handleClose,
  expert,
}: Props): React.ReactElement {
  const isEditing = Boolean(expert)
  const title = isEditing ? expert?.name : 'New Expert'
  const [expertFormState, setExpertFormState] = useState<ExpertFormState>(
    getDefaultState(expert)
  )

  const [createExpert] = useMutation<
    CreateExpertMutation,
    CreateExpertMutationVariables
  >(CREATE_EXPERT, {
    onCompleted() {
      toast.success(TOAST.CREATE_SUCCESS)
      setExpertFormState({})
      handleClose()
    },
    onError() {
      toast.error(TOAST.CREATE_ERROR)
    },
    update: addNewExpertToCache,
  })

  const [modifyExpert] = useMutation<
    ModifyExpertMutation,
    ModifyExpertMutationVariables
  >(MODIFY_EXPERT, {
    onCompleted() {
      toast.success(TOAST.MODIFY_SUCCESS)
      setExpertFormState(getDefaultState(expert))
      handleClose()
    },
    onError() {
      toast.error(TOAST.MODIFY_ERROR)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    if (isEditing) {
      handleModify()
    } else {
      handleCreate()
    }
  }

  function handleCreate() {
    const variables = getCreateVariables(expertFormState)

    void createExpert({
      variables,
    })
  }

  function handleModify() {
    // if we are in the modify flow then expert must exist
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const variables = getModifyVariables(expertFormState, expert!.id)

    void modifyExpert({
      variables,
    })
  }

  function handleIndustrySelection(value: Maybe<IndustrySuggestionType>) {
    const currentIndustries = expertFormState.industries ?? []
    if (
      value &&
      !currentIndustries.find((suggestion) => value.name === suggestion.name)
    ) {
      setExpertFormState({
        ...expertFormState,
        industries: [...currentIndustries, value],
        industryInput: null,
      })
    }
  }

  function handleRemoveIndustryClick(id: string) {
    const newIndustries = expertFormState.industries?.filter((industry) => {
      return industry.id !== id
    })

    setExpertFormState({
      ...expertFormState,
      industries: newIndustries?.length ? newIndustries : null,
    })
  }

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<
    P extends keyof ExpertFormState,
    T extends ExpertFormState[P]
  >(value: T, prop: P) {
    setExpertFormState({ ...expertFormState, [prop]: value })
  }

  function handleEmployerSelection(value: Maybe<Suggestion>) {
    const currentEmployers = expertFormState.employers ?? []
    if (
      value &&
      !currentEmployers.find((suggestion) => value.name === suggestion.name)
    ) {
      const normalizedValue = {
        name: value.name,
        domain: value.domain,
        logoUrl: value.logo,
      }
      setExpertFormState({
        ...expertFormState,
        employers: [...currentEmployers, normalizedValue],
        companyInput: null,
      })
    }
  }

  function handleRemoveCompanyClick(
    name: string,
    domain: Maybe<string> | undefined
  ) {
    const newEmployers = expertFormState.employers?.filter((company) => {
      return company.name !== name || company.domain !== domain
    })

    setExpertFormState({
      ...expertFormState,
      employers: newEmployers?.length ? newEmployers : null,
    })
  }

  const expertRequests = expert?.expertRequests.edges
    .map(({ node }) => ({ ...node, dueDayjs: dayjs(node.due) }))
    .sort((a, b) => sortByProp(a, b, 'dueDayjs'))

  /* TODO (matthewalbrecht): add company selector and industry selector */
  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={handleClose}
      aria-label={`${isEditing ? 'Edit' : 'Add'} expert form`}
      widthVW={[90, { sm: 80, md: 55, lg: 45, xl: 35 }]}
    >
      <Padding vertical={4} horizontal={4}>
        <DialogExitButton onClick={handleClose} />
        <Txt as="h2" bold size={24}>
          {title}
        </Txt>
        <Padding top={4}>
          <form onSubmit={handleSubmit} id={ID.FORM}>
            <SectionHeader headerCopy="Basic Information" />
            <Grid
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              rowGapSize={4}
              colGapSize={2}
            >
              <GridItem column={1} span={2}>
                <TextInput
                  name="name"
                  label="Name *"
                  fullWidth
                  required
                  value={expertFormState.name || EMPTY_INPUT}
                  onValueChange={(value) => handleInputChange(value || null, 'name')}
                  disabled={isEditing}
                />
              </GridItem>
              <GridItem column={3} span={2}>
                <TextInput
                  name="linkedinUrl"
                  label="LinkedIn URL *"
                  placeholder="https://linkedin.com/in/"
                  fullWidth
                  required
                  value={expertFormState.linkedinUrl || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value || null, 'linkedinUrl')
                  }
                  pattern={LINKEDIN_VALIDATION_STRING}
                />
              </GridItem>
              <GridItem column={1} span={2}>
                <TextInput
                  name="email"
                  label="Contact Email"
                  fullWidth
                  value={expertFormState.email || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value || null, 'email')
                  }
                />
              </GridItem>
              <GridItem column={3} span={2}>
                <TextInput
                  name="currentTitle"
                  label="Current Title"
                  fullWidth
                  value={expertFormState.currentTitle || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value || null, 'currentTitle')
                  }
                />
              </GridItem>
              <GridItem column={1} span={2}>
                <Checkbox
                  label="Employee?"
                  name="is1099ed"
                  option={{ label: 'Is Contractor', value: 'is1099ed' }}
                  checked={expertFormState.is1099ed || false}
                  onValueChange={(value) => handleInputChange(value, 'is1099ed')}
                />
              </GridItem>
              <GridItem column={3} span={2}>
                <Checkbox
                  label="Pro Bono?"
                  name="isProBono"
                  option={{ label: 'Is Pro Bono', value: 'isProBono' }}
                  checked={expertFormState.isProBono || false}
                  onValueChange={(value) => handleInputChange(value, 'isProBono')}
                />
              </GridItem>
              <GridItem column={1} span={4}>
                <CheckboxGroup
                  label="Seniority Levels"
                  name="seniorityLevels"
                  options={SENIORITY_LEVEL_OPTIONS}
                  value={expertFormState.seniorityLevels || {}}
                  onValueChange={(value) =>
                    handleInputChange(value, 'seniorityLevels')
                  }
                />
              </GridItem>
              <GridItem column={1} span={4}>
                <CheckboxGroup
                  label="Functional Areas"
                  name="functionalAreas"
                  options={FUNCTIONAL_AREA_OPTIONS}
                  value={expertFormState.functionalAreas || {}}
                  onValueChange={(value) =>
                    handleInputChange(value, 'functionalAreas')
                  }
                />
              </GridItem>
              <GridItem column={1} span={4}>
                <CompanySuggestion
                  value={expertFormState.companyInput ?? EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value || null, 'companyInput')
                  }
                  selection={null}
                  label="Employers"
                  onSelectionValueChange={handleEmployerSelection}
                  required={false}
                />
                {expertFormState.employers && (
                  <Padding top={2}>
                    <HList size={2} rowGapSize={1.5}>
                      {expertFormState.employers.map((company) => (
                        <CompanyTag
                          company={company}
                          key={`${company.name}-${company.domain ?? ''}`}
                          handleRemoveClick={handleRemoveCompanyClick}
                        />
                      ))}
                    </HList>
                  </Padding>
                )}
              </GridItem>
              <GridItem column={1} span={4}>
                <IndustrySuggestion
                  value={expertFormState.industryInput ?? EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value || null, 'industryInput')
                  }
                  label="Industries"
                  onSelectionValueChange={handleIndustrySelection}
                />
                {expertFormState.industries && (
                  <Padding top={2}>
                    <HList size={2} rowGapSize={1.5}>
                      {expertFormState.industries.map((industry) => (
                        <IndustryTag
                          industry={industry}
                          key={industry.id}
                          handleRemoveClick={handleRemoveIndustryClick}
                        />
                      ))}
                    </HList>
                  </Padding>
                )}
              </GridItem>
            </Grid>
            <Padding top={6}>
              <SectionHeader headerCopy="Payment Information" />
            </Padding>
            <Grid
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              rowGapSize={4}
              colGapSize={2}
            >
              <GridItem column={1} span={2}>
                <TextInput
                  name="hourlyRate"
                  label="Hourly Rate"
                  type="number"
                  fullWidth
                  min={0}
                  value={expertFormState.hourlyRate || EMPTY_INPUT}
                  onValueChange={(value) => handleInputChange(value, 'hourlyRate')}
                />
              </GridItem>
              <GridItem column={3} span={2}>
                <RadioGroup
                  label="Payment Type"
                  name="paymentType"
                  options={PAYMENT_TYPE_OPTIONS}
                  value={expertFormState.paymentType || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value as ExpertPaymentType, 'paymentType')
                  }
                />
              </GridItem>
              <GridItem column={1} span={4}>
                <TextArea
                  name="additionalPaymentDetails"
                  label="Additional Payment Details"
                  fullWidth
                  value={expertFormState.additionalPaymentDetails || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value, 'additionalPaymentDetails')
                  }
                />
              </GridItem>
            </Grid>
          </form>
        </Padding>
        {isEditing && (
          <Padding top={6}>
            <SectionHeader headerCopy="Expert Requests" />
            {!expertRequests?.length && (
              <Txt alignment="center">No Expert Requests Found.</Txt>
            )}
            <Grid
              gridTemplateColumns="min-content 1rem min-content min-content min-content"
              rowGapSize={2}
              colGapSize={1.5}
            >
              {Boolean(expertRequests?.length) && (
                <GridHeaders headers={EXPERT_REQUEST_HEADERS} />
              )}
              {expertRequests?.map((expertRequest, index) => (
                <React.Fragment key={expertRequest.id}>
                  <Txt size={13}>{expertRequests.length - index}</Txt>
                  <div />
                  <Txt size={13} noWrap>
                    {expertRequest.client.name}
                  </Txt>
                  <Txt size={13} noWrap>
                    {expertRequest.client.talentAgent.name}
                  </Txt>
                  <Txt size={13} noWrap>
                    {dayjs(expertRequest.due).format(DATE_FORMAT.ONLY_DATE)}
                  </Txt>
                </React.Fragment>
              ))}
            </Grid>
          </Padding>
        )}
      </Padding>
      <DialogFooter>
        <Padding vertical={1} horizontal={4}>
          <Flex justify="flex-end">
            <Button
              $type="accept"
              as="input"
              type="submit"
              form={ID.FORM}
              value={isEditing ? 'Save' : 'Submit'}
            />
          </Flex>
        </Padding>
      </DialogFooter>
    </Dialog>
  )
}

const CREATE_EXPERT = gql`
  mutation CreateExpert($CreateExpertInput: CreateExpertInput!) {
    createExpert(input: $CreateExpertInput) {
      expert {
        ...ExpertInfo
      }
    }
  }
  ${ExpertFragment}
`

const MODIFY_EXPERT = gql`
  mutation ModifyExpert($ModifyExpertInput: ModifyExpertInput!) {
    modifyExpert(input: $ModifyExpertInput) {
      expert {
        ...ExpertInfo
      }
    }
  }
  ${ExpertFragment}
`

type CompanyTagProps = {
  company: CompanyInput
  handleRemoveClick(name: string, domain: Maybe<string> | undefined): void
}

const TagStyled = styled.div`
  background-color: ${({ theme }) => theme.color.faGrey1};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(1)};
  border-radius: ${({ theme }) => theme.borderRadius};
`

function CompanyTag({ company, handleRemoveClick }: CompanyTagProps) {
  return (
    <TagStyled>
      {company.logoUrl && (
        <Padding right={1}>
          <SuggestionRowLogo src={company.logoUrl} />
        </Padding>
      )}
      <Padding right={1}>
        <Txt>{company.name}</Txt>
      </Padding>
      <button onClick={() => handleRemoveClick(company.name, company.domain)}>
        <Icon
          height={16}
          width={16}
          name="Exit"
          primaryFill="text"
          primaryFillHover="black"
          display="block"
        />
      </button>
    </TagStyled>
  )
}

type IndustryTagProps = {
  industry: Industry
  handleRemoveClick(id: string): void
}

function IndustryTag({ industry, handleRemoveClick }: IndustryTagProps) {
  return (
    <TagStyled>
      <Padding right={1}>
        <Txt>{industry.name}</Txt>
      </Padding>
      <button onClick={() => handleRemoveClick(industry.id)}>
        <Icon
          height={16}
          width={16}
          name="Exit"
          primaryFill="text"
          primaryFillHover="black"
          display="block"
        />
      </button>
    </TagStyled>
  )
}
