import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import {
  TAGLINE_DUE_HOUR,
  TAGLINE_DUE_MINUTE,
  TAGLINE_DUE_TZ,
} from 'utils/constants'
import { getDueTime } from 'utils/helpers/time'
import Editor from 'components/Editor/Editor'
import ImportantIndicator from 'components/ImportantIndicator/ImportantIndicator'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { TaglineRequest } from './WriterSubmission.types'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  taglineRequest: TaglineRequest
}

function WriterSubmissionLeft({ taglineRequest }: Props): JSX.Element {
  const dueLabel = `Due ${getDueTime(
    dayjs(taglineRequest.due),
    TAGLINE_DUE_HOUR,
    TAGLINE_DUE_MINUTE,
    TAGLINE_DUE_TZ
  )}`

  return (
    <>
      <Padding bottom={4}>
        {taglineRequest.isImportant && (
          <Padding bottom={2.5}>
            <ImportantIndicator />
          </Padding>
        )}
        <Flex justify="space-between" align="center">
          <div>
            <Txt as="h2" size={24} bold>
              {taglineRequest.client.name}
            </Txt>
          </div>
          <Txt>{dueLabel}</Txt>
        </Flex>
        <Padding top={1.5} left={0.5}>
          <Txt size={14}>Talent Agent: {taglineRequest.client.talentAgent.name}</Txt>
        </Padding>
      </Padding>
      <VList size={4}>
        <TextArea
          defaultValue={taglineRequest.notes}
          label="Assignment notes"
          disabled
          rows={4}
        />
        {taglineRequest.client.resumeUrl && (
          <PDFViewer url={taglineRequest.client.resumeUrl} />
        )}
        <WorkInfoLinks
          resumeUrl={taglineRequest.client.resumeUrl}
          linkedinUrl={taglineRequest.client.linkedinUrl}
          resumes={taglineRequest.client.resumes}
        />
        {taglineRequest.huntrHtmlDescription && (
          <Editor
            defaultValue={taglineRequest.huntrHtmlDescription}
            label="Notes from Huntr"
            height={80}
            disabled
          />
        )}
        {taglineRequest.client.bio && (
          <TextArea
            defaultValue={taglineRequest.client.bio}
            label={`${taglineRequest.client.name} Bio`}
            height={80}
            disabled
          />
        )}
      </VList>
    </>
  )
}

WriterSubmissionLeft.fragments = {
  taglineRequestInfo: gql`
    fragment WriterSubmissionLeftInfo on TaglineRequest {
      id
      huntrHtmlDescription
      notes
      due
      isImportant
      client {
        name
        resumeUrl
        linkedinUrl
        bio
        talentAgent {
          name
        }
        resumes {
          url
          filename
        }
      }
    }
  `,
}

export default WriterSubmissionLeft
