import React, { useState } from 'react'
import { NavContext } from 'context/nav'
import { NavStatus } from 'utils/enums'
import { Maybe } from 'generated/graphql'

type Props = { [key: string]: unknown; children: React.ReactNode }

export default function NavProvider({ children, ...props }: Props): JSX.Element {
  const existingNavStatus = localStorage.getItem('navStatus')

  const [navStatus, setNavStatus] = useState<NavStatus>(
    (existingNavStatus as Maybe<NavStatus>) || NavStatus.Collapsed
  )

  const saveStatus = (status: NavStatus) => {
    localStorage.setItem('navStatus', status)
    setNavStatus(status)
  }

  return (
    <NavContext.Provider
      value={{ status: navStatus, setStatus: saveStatus }}
      {...props}
    >
      {children}
    </NavContext.Provider>
  )
}
