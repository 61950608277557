import React from 'react'
import { DropdownGroup, DropdownOption } from 'types'
import { Select } from 'components/Inputs/Input'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'

type ChangeEvent = React.ChangeEvent<HTMLSelectElement>

type Props = {
  disabled?: boolean
  fullWidth?: boolean
  label?: string
  name?: string
  description?: string
  onValueChange?: (value: string) => void
  options: DropdownOption[] | DropdownGroup[]
  required?: boolean
  value?: string
  placeholder?: string
  width?: number
  minWidth?: number
  defaultValue?: string
  withEmptyOption?: boolean
  ariaLabel?: string
}

export function Dropdown({
  defaultValue,
  disabled,
  fullWidth,
  label,
  name,
  placeholder,
  onValueChange,
  options,
  description,
  required,
  value,
  width,
  minWidth,
  withEmptyOption = true,
  ariaLabel = 'dropdown',
}: Props): JSX.Element {
  return (
    <div>
      {label && <Label htmlFor={name} content={label} />}
      {description && <InputDescription description={description} />}
      <Select
        name={name}
        id={name}
        required={required}
        width={width}
        minWidth={minWidth}
        value={value}
        defaultValue={defaultValue}
        disabled={disabled}
        fullWidth={fullWidth}
        onChange={(event: ChangeEvent) => {
          onValueChange?.(event.currentTarget.value)
        }}
        aria-label={ariaLabel}
      >
        {placeholder && <option label={placeholder} disabled />}
        {withEmptyOption && <option />}
        {isGrouped(options) ? (
          options.map((group) => (
            <optgroup label={group.groupLabel} key={group.groupLabel}>
              <DropdownList options={group.options} />
            </optgroup>
          ))
        ) : (
          <DropdownList options={options} />
        )}
      </Select>
    </div>
  )
}

type DropdownListProps = {
  options: DropdownOption[]
}

function DropdownList({ options }: DropdownListProps): React.ReactElement {
  return (
    <>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </>
  )
}

/**
 * custom type check to determine if we are using grouped dropdown or not
 * @param options the options passed into the component
 * @returns true if options are of type DropdownGroup
 */
function isGrouped(
  options: DropdownOption[] | DropdownGroup[]
): options is DropdownGroup[] {
  const firstItem = options[0]
  if (!firstItem) {
    return false
  }
  if ('groupLabel' in firstItem) {
    return true
  }
  return false
}
