import ROUTE_PATHS from 'utils/routePaths'

export const ASSIGN_DATA = {
  header: 'Available Sourcing',
  route: ROUTE_PATHS.AVAILABLE_SOURCING,
}
export const REASSIGN_DATA = {
  header: 'Assigned Sourcing',
  route: ROUTE_PATHS.ASSIGNED_SOURCING,
}

export const COLUMNS = [
  {
    name: '',
    id: 'checkbox',
    collapse: true,
  },
  {
    name: 'Free Agent',
    id: 'freeAgent',
    hideOnAvailable: true,
  },
  {
    name: 'Number of Jobs',
    id: 'numJobs',
  },
  {
    name: 'Due Date',
    id: 'due',
    collapse: true,
  },
  {
    name: 'Submission Date',
    id: 'submittedDate',
    hideOnAssigned: true,
  },
  {
    name: 'Assignment',
    id: 'assignment',
    collapse: true,
  },
  {
    name: 'Edit',
    id: 'edit',
    collapse: true,
  },
  {
    name: 'Remove',
    id: 'remove',
    collapse: true,
  },
]
