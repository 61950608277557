import React from 'react'
import Icon from 'components/Icon/Icon'
import { IconNames } from 'components/Icon/Icon.types'
import Flex from 'components/Flex'

type Props = {
  onActionClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  ActionIconName: IconNames
  label: React.ReactNode
  showActionButton?: boolean
  buttonTitle?: string
}

export default function EditorLabelWithAction({
  onActionClick,
  ActionIconName,
  showActionButton = true,
  buttonTitle,
  label,
}: Props): JSX.Element {
  return (
    <Flex justify="space-between" align="center">
      {label}
      {showActionButton && (
        <button onClick={onActionClick} title={buttonTitle}>
          <Icon name={ActionIconName} height={16} width={16} primaryFill="text" />
        </button>
      )}
    </Flex>
  )
}
