import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { DATE_FORMAT } from 'utils/constants'
import { getApplicationStatusCopy } from 'utils/helpers/applications'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { Application } from './ClientApplications.types'
import ClientApplicationsYellowCardDetails from './ClientApplicationsYellowCardDetails'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  application: Application
}

function ClientApplicationsTableRow({ application }: Props): JSX.Element {
  const formattedDateCreated = dayjs(application.createdAt).format(
    DATE_FORMAT.DATE_AND_TIME
  )
  const missingApplicationInfo = application.missingApplicationInfos[0]
  const statusCopy = getApplicationStatusCopy(application)

  return (
    <BodyRow>
      <BodyData>
        <Txt size={14} lineHeight={1.3}>
          {formattedDateCreated}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{application.employerName}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{application.jobTitle}</Txt>
      </BodyData>
      <BodyData>
        <Link
          to={generatePath(ROUTE_PATHS.APPLICATION_INFO, {
            applicationId: application.id,
          })}
        >
          <Button small noWrap>
            Final Copy
          </Button>
        </Link>
      </BodyData>
      <BodyData>
        <Flex justify="flex-start" align="center">
          <Txt size={14} lineHeight={1.3}>
            {statusCopy}
          </Txt>
          {missingApplicationInfo && (
            <Padding left={1} inline>
              <ClientApplicationsYellowCardDetails
                missingApplicationInfo={missingApplicationInfo}
              />
            </Padding>
          )}
        </Flex>
      </BodyData>
      <BodyData collapse>
        {missingApplicationInfo?.applicationNew?.id && (
          <Link
            to={generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: missingApplicationInfo.applicationNew.id,
            })}
          >
            <Button small noWrap>
              New Application
            </Button>
          </Link>
        )}
      </BodyData>
      <BodyData collapse>
        {application.isImportant && (
          <Flex justify="center">
            <Icon
              name="Star"
              height={14}
              width={14}
              primaryFill="faBlue"
              display="block"
            />
          </Flex>
        )}
      </BodyData>
    </BodyRow>
  )
}

ClientApplicationsTableRow.fragments = {
  rowInfo: gql`
    fragment ClientApplicationsTableRowInfo on Application {
      id
      createdAt
      employerName
      jobTitle
      isImportant
      missingApplicationInfos {
        applicationNew {
          id
        }
      }
      ...YellowCardDetails
      ...ApplicationStatusCopy
    }
    ${getApplicationStatusCopy.fragments.applicationStatusCopyInfo}
    ${ClientApplicationsYellowCardDetails.fragments.yellowCardDetailInfo}
  `,
}

export default ClientApplicationsTableRow
