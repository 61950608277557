import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import ROUTE_PATHS from 'utils/routePaths'
import { resetState, useChanges } from 'views/Assign/Assign.context'
import AssignFooter from 'views/Assign/AssignFooter'
import Button from 'components/Button'

export default function AssignApplicationFooter(): React.ReactElement {
  const { state, dispatch } = useChanges()

  const [assignTaglines, { loading }] = useMutation(UPDATE_TAGLINE_REQUESTS, {
    // TODO (matthewalbrecht): use update function to handle this
    refetchQueries: [
      'GetTaglineRequestsByStatus',
      'WritersPendingTaglineRequests',
      'WritersPendingTaglineRequestsWithClient',
    ],
    onCompleted() {
      toast.success(`Assigned tagline request(s)`)
      resetState(dispatch)
    },
    onError() {
      // TODO: (matthewalbrecht) handle error better
      toast.error('There was an error assigning the tasks')
    },
  })

  const shouldMutate = Object.keys(state.updates).length

  function handleSubmit() {
    const updates = Object.entries(state.updates).map(
      ([taglineRequestId, { assigneeId, due }]) => ({
        taglineRequestId,
        assigneeId,
        due,
      })
    )

    void assignTaglines({ variables: { UpdateTaglineRequestsInput: { updates } } })
  }

  const disableSubmit = !shouldMutate || loading

  return (
    <AssignFooter>
      <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
        Cancel
      </Button>
      <Button $type="accept" onClick={handleSubmit} disabled={disableSubmit}>
        Submit
      </Button>
    </AssignFooter>
  )
}

const UPDATE_TAGLINE_REQUESTS = gql`
  mutation UpdateTaglineRequests(
    $UpdateTaglineRequestsInput: UpdateTaglineRequestsInput!
  ) {
    updateTaglineRequests(input: $UpdateTaglineRequestsInput) {
      taglineRequests {
        id
        status
        assignee {
          id
          name
        }
        due
      }
    }
  }
`
