import React from 'react'
import {
  avgRatingCopy,
  oneAndTwosRateCopy,
  rateAsPctCopy,
} from 'utils/helpers/stats'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { RowData } from './SourcerWorkStats.types'
import Txt from 'components/Txt'

type Props = {
  rowData: RowData
}

export default function SourcerWorkStatsRow({ rowData }: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {avgRatingCopy(rowData.avgRating, rowData.avgRatingTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {oneAndTwosRateCopy(rowData.onesAndTwosRate, rowData.avgRatingTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rateAsPctCopy(rowData.rejectionRate, rowData.total)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
