import { Dayjs } from 'dayjs'
import { Rating, ConnectionNode, KeyValue } from 'types'
import {
  GetTalentAgentStatsInfoQuery,
  GetTalentAgentStatsSourcedJobsQuery,
} from 'generated/graphql'

export type Params = {
  talentAgentId?: string
}

export type Clients = ConnectionNode<
  NonNullable<GetTalentAgentStatsInfoQuery['clients']>
>

export type SourcedJobs = ConnectionNode<
  NonNullable<GetTalentAgentStatsSourcedJobsQuery['sourcedJobs']>
>

export type TaStats = {
  id: string
  freeAgent: string
  appsSubmitted: number
  interviewCyclesActive: number
  signDate?: Dayjs | null
  sourcedJobsAvgRating?: number
}

export type DatesAndCountMap = Record<string, number>

export type DateRangeInfo = {
  label: string
  unix: number
}

export type AvgRatingAndUnix = {
  avgRatings: number
  unix: number
}

export type GroupedDatesMap = Record<number, Rating[]>

export type GroupedDatesAvgRating = Record<string, AvgRatingAndUnix>

export type ChartData = {
  date: string
  count: number
  unix: number
}

export type ClientsUnselectedMap = KeyValue<boolean>

export enum DateFilterPeriod {
  LAST_7_DAYS = 'LAST_7_DAYS',
  LAST_30_DAYS = 'LAST_30_DAYS',
  LAST_90_DAYS = 'LAST_90_DAYS',
}

export enum StatType {
  APPS_SUBMITTED = 'APPS_SUBMITTED',
  INTERVIEW_CYCLES_STARTED = 'INTERVIEW_CYCLES_STARTED',
  SOURCED_JOBS_AVERAGE_RATINGS = 'SOURCED_JOBS_AVERAGE_RATINGS',
}

export enum TimePeriod {
  WEEK = 7,
}

export type GroupedSourcedJobs = Record<string, Rating[]>

export type ClientIdToAvgRatingMap = Record<string, number>

// Pulled from @types/recharts
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type RechartsFunction = (...args: any[]) => void
