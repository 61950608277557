import React, { useState } from 'react'
import { EMPTY_INPUT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'
import AddAndEditExpert from './AddAndEditExpert'
import ExpertNetworkTable from './ExpertNetworkTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import { TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { Maybe } from 'generated/graphql'

export default function ExpertNetwork(): React.ReactElement {
  const [searchTerm, setSearchTerm] = useState<Maybe<string>>(null)
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Flex justify="space-between" align="center">
            <Txt bold size={24} as="h2">
              Expert Network
            </Txt>
            <NewExpertButton />
          </Flex>
        </Padding>
        <Padding vertical={4}>
          <TextInput
            name="search"
            type="search"
            value={searchTerm || EMPTY_INPUT}
            placeholder="Search name, company, industry, title"
            onValueChange={(value) => setSearchTerm(value || null)}
            icon={<Icon name="Search" height={12} width={12} />}
            width={350}
          />
        </Padding>
      </Container>
      <ExpertNetworkTable searchTerm={searchTerm} />
    </ViewBox>
  )
}

function NewExpertButton(): React.ReactElement {
  const [isOpen, setisOpen] = useState(false)

  function handleClose() {
    setisOpen(false)
  }

  return (
    <>
      <Button $type="highlight" onClick={() => setisOpen(true)}>
        New Expert
      </Button>
      <AddAndEditExpert handleClose={handleClose} isOpen={isOpen} />
    </>
  )
}

ExpertNetwork.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EXPERT_NETWORK}
    key={ROUTE_PATHS.EXPERT_NETWORK}
  >
    <ExpertNetwork />
  </PrivateRoute>,
]
