import { htmlToText } from 'html-to-text'

export default function convertHtmlToText(copy: string): string {
  const plainText = htmlToText(copy, {
    wordwrap: null,
    formatters: {
      // Create a formatter.
      pBlockFormatter: function (elem, walk, builder, { trailingLineBreaks }) {
        builder.openBlock({ leadingLineBreaks: 0 })
        walk(elem.children, builder)
        // Add trailing line break
        builder.closeBlock({ trailingLineBreaks })
      },
      brBlockFormatter() {
        /**
         * https://github.com/html-to-text/node-html-to-text/blob/67d6859ca198c1e731d690e91e74acc3e1225f2d/lib/formatter.js#L18
         * do nothing to skip br elements, quill for space between paragraphs will use <p><br/></pr> structure
         * ignoring br fixes issue where each line break in quill turns into 4 lines in plain text
         */
      },
    },
    tags: {
      // Assign it to `foo` tags.
      p: {
        format: 'pBlockFormatter',
        options: {
          trailingLineBreaks: 1,
        },
      },
      br: {
        format: 'brBlockFormatter',
      },
    },
  })
  return plainText
}
