import { ApolloCache, gql, useMutation } from '@apollo/client'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { useAuthContext } from 'context/auth'
import { SourcingRejectionReason } from 'utils/enums'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import RejectionButton from 'components/RejectionButton/RejectionButton'
import {
  CONFIRM_MORE_JOBS_REQUESTED,
  DEFAULT_STATE,
  TOAST,
} from './SourcingSubmission.constants'
import { getNextRouteUrl } from './SourcingSubmission.helpers'
import { SourcingRequest, State } from './SourcingSubmission.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

const WriterSubmissionFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  sourcingRequest: SourcingRequest
  state: State
  setState: ReactSetState<State>
  timeSpentSec: number
  setTimeSpentSec: ReactSetState<number>
  queue: string[]
  requestId: string
  freeAgentId: string | undefined
  sourcerId: string | undefined
}

export default function SourcingSubmissionFooter({
  sourcingRequest,
  setState,
  state,
  timeSpentSec,
  setTimeSpentSec,
  queue,
  requestId,
  freeAgentId,
  sourcerId,
}: Props): React.ReactElement {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()

  async function handleSubmission() {
    // TODO: (matthewalbrecht) if job description is entered and then manually deleted jobDescription will still have a value of <p><br/></p> maybe consider a clear button for the form or editor
    if (
      state.company ||
      state.jobDescription ||
      state.notes ||
      state.url ||
      state.title
    ) {
      return toast.error(TOAST.SUMBIT_CONTENT)
    }

    const moreJobsRequested =
      sourcingRequest.numJobs > sourcingRequest.sourcedJobs.length
    const userConfirmed =
      moreJobsRequested && window.confirm(CONFIRM_MORE_JOBS_REQUESTED)

    if (!moreJobsRequested || userConfirmed) {
      const variables = {
        RecordSourcingSubmissionInput: {
          sourcingRequestId: sourcingRequest.id,
          timeSpentSec,
        },
      }

      try {
        await recordSourcingSubmission({ variables })
        handleCompletion(false)
      } catch {
        toast.error('There was an error submitting the sourcing request')
      }
    }
  }

  async function handleRejection(rejectionReason: SourcingRejectionReason) {
    const variables = {
      RecordSourcingRejectionInput: {
        sourcingRequestId: sourcingRequest.id,
        rejectionReason,
        timeSpentSec,
      },
    }

    try {
      await recordSourcingRejection({ variables })
      handleCompletion(true)
    } catch {
      toast.error('There was an error rejecting the sourcing request')
    }
  }

  /**
   *
   * @param data data returned from mutation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCompletion(isRejected: boolean) {
    const toastContent = `Sourcing Request ${isRejected ? 'rejected' : 'submitted'}`

    toast.success(toastContent)

    resetState()

    if (!nextRequestId) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl)
  }

  /**
   * handles skip button bein clicked, reset state and push to next item or home
   */
  function handleSkipClick() {
    if (!nextRequestId) {
      toast('End of queue')
    }

    resetState()

    history.push(nextRouteUrl)
  }

  /**
   * Resets the state of the component
   */
  function resetState() {
    setTimeSpentSec(0)
    setState(DEFAULT_STATE)
  }

  /**
   * this will remove the current request from the query in cache so that ui will properly update
   * @param cache Apollo's cache
   */
  function updateCacheAfterSuccess(cache: ApolloCache<unknown>) {
    const cacheQuery = `sourcingRequests({"assigneeId":"${assigneeId}","isAssigned":true,"isOpen":true})`
    cache.modify({
      fields: {
        [cacheQuery]: (queryResult, { readField }) =>
          removeCacheEdgeById(requestId, queryResult, readField),
      },
    })
  }

  const [recordSourcingSubmission] = useMutation(RECORD_SOURCING_SUBMISSION, {
    update: updateCacheAfterSuccess,
  })
  const [recordSourcingRejection] = useMutation(RECORD_SOURCING_REJECTION, {
    update: updateCacheAfterSuccess,
  })
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, requestId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, {
    freeAgentId,
    sourcerId,
  })

  const rejectionOptions = [
    {
      rejectionReason: SourcingRejectionReason.INCOMPLETE_CLIENT_INFORMATION,
      callback: handleRejection,
      label: 'Incomplete Client Information',
    },
    {
      rejectionReason: SourcingRejectionReason.OTHER,
      callback: handleRejection,
      label: 'Other',
    },
  ]

  const placeInQueue = queue.indexOf(requestId) + 1
  const queueLabel = `${placeInQueue} of ${queue.length}`
  const hasSubmission = Boolean(sourcingRequest.sourcedJobs.length)

  return (
    <WriterSubmissionFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
          <RejectionButton<SourcingRejectionReason>
            rejectionOptions={rejectionOptions}
            disabled={hasSubmission}
          />
          <Button
            $type="accept"
            onClick={handleSubmission}
            disabled={!hasSubmission}
          >
            Submit
          </Button>
        </HList>
      </Flex>
    </WriterSubmissionFooterBox>
  )
}

/**
 * Used by Sourcer to submit their sourcing request
 */
const RECORD_SOURCING_SUBMISSION = gql`
  mutation RecordSourcingSubmission(
    $RecordSourcingSubmissionInput: RecordSourcingSubmissionInput!
  ) {
    recordSourcingSubmission(input: $RecordSourcingSubmissionInput) {
      sourcingSubmission {
        id
      }
    }
  }
`
/**
 * Used by Sourcer to reject their sourcing request
 */
const RECORD_SOURCING_REJECTION = gql`
  mutation RecordSourcingRejection(
    $RecordSourcingRejectionInput: RecordSourcingRejectionInput!
  ) {
    recordSourcingRejection(input: $RecordSourcingRejectionInput) {
      sourcingSubmission {
        id
      }
    }
  }
`
