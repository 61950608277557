import { gql, useQuery } from '@apollo/client'
import React, { useEffect } from 'react'
import { useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import useTalentPartnerQueue from 'hooks/useTalentPartnerQueue'
import useTimeTracking from 'hooks/useTimeTracking'
import { GLOBAL_COPY, INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import useFormState from 'views/ClientTracker/useFormState'
import FinalReviewFooter from 'views/FinalReview/FinalReviewFooter'
import FinalReviewLeft from 'views/FinalReview/FinalReviewLeft'
import FinalReviewRight from 'views/FinalReview/FinalReviewRight'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import { DEFAULT_FORM_DATA } from './FinalReview.constants'
import { getQueueInfo } from './FinalReview.helpers'
import { FormState, Params, QueueType } from './FinalReview.types'
import Message from 'components/Message'
import PrivateRoute from 'components/PrivateRoute'
import {
  TaglineRequestPartnerReviewQuery,
  TaglineRequestPartnerReviewQueryVariables,
} from 'generated/graphql'

type Props = {
  queueType: QueueType
}

export default function FinalReview({ queueType }: Props): JSX.Element {
  const params = useParams<Params>()
  const history = useHistory()
  const { requestId } = params
  const redirectToInitial = requestId === INITIAL
  const talentPartnerQueues = useTalentPartnerQueue()
  const { getTimeSpentSec } = useTimeTracking()
  const { formState, setFormField, setFormState } =
    useFormState<FormState>(DEFAULT_FORM_DATA)
  const { data: taglineRequestData } = useQuery<
    TaglineRequestPartnerReviewQuery,
    TaglineRequestPartnerReviewQueryVariables
  >(GET_TAGLINE_REQUEST_BY_ID, {
    variables: {
      id: requestId,
    },
    skip: redirectToInitial,
  })
  const taglineRequest = taglineRequestData?.taglineRequest
  const { queue, initialPath, nextRouteUrl } = getQueueInfo(
    queueType,
    talentPartnerQueues,
    params
  )

  // using an if statement would allow for the toast to render twice
  useEffect(() => {
    if (!talentPartnerQueues.loading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, queue, talentPartnerQueues.loading])

  useEffect(() => {
    if (redirectToInitial && initialPath) {
      history.replace(initialPath)
    }
  }, [history, initialPath, redirectToInitial])

  if (!queue.length || !taglineRequest) {
    return <Message vertical message="Loading tagline request submission form..." />
  }

  return (
    <DualScrollContainer key={requestId}>
      <DualScroll
        leftContent={<FinalReviewLeft taglineRequest={taglineRequest} />}
        rightContent={
          <FinalReviewRight
            taglineRequest={taglineRequest}
            formState={formState}
            setFormField={setFormField}
          />
        }
      />
      <FinalReviewFooter
        taglineRequest={taglineRequest}
        queue={queue}
        formState={formState}
        setFormState={setFormState}
        getTimeSpentSec={getTimeSpentSec}
        requestId={requestId}
        nextRouteUrl={nextRouteUrl}
      />
    </DualScrollContainer>
  )
}

FinalReview.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.PARTNER_QUEUE}
    key={ROUTE_PATHS.PARTNER_QUEUE}
  >
    <FinalReview queueType={QueueType.Viewer} />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.PARTNER_QUEUE_BY_FREE_AGENT}
    key={ROUTE_PATHS.PARTNER_QUEUE_BY_FREE_AGENT}
  >
    <FinalReview queueType={QueueType.FreeAgent} />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.PARTNER_QUEUE_BY_POD}
    key={ROUTE_PATHS.PARTNER_QUEUE_BY_POD}
  >
    <FinalReview queueType={QueueType.Pod} />
  </PrivateRoute>,
]

const GET_TAGLINE_REQUEST_BY_ID = gql`
  query TaglineRequestPartnerReview($id: ID!) {
    taglineRequest(id: $id) {
      id
      client {
        id
      }
      ...PartnerReviewLeftInfo
      ...PartnerReviewRightInfo
      ...PartnerReviewFooterInfo
    }
  }
  ${FinalReviewLeft.fragments.taglineRequestInfo}
  ${FinalReviewRight.fragments.taglineRequestInfo}
  ${FinalReviewFooter.fragments.taglineRequestInfo}
`
