import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { Application, FinalCopyState } from './FinalCopy.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'

const FinalCopyFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  application: Application
  state: FinalCopyState
  setState: ReactSetState<FinalCopyState>
}

export default function FinalCopyFooter({
  application,
  state,
  setState,
}: Props): JSX.Element {
  const [updateApplicationCopy, { loading }] = useMutation(UPDATE_APPLICATION_COPY, {
    onError() {
      toast.error('There was an error updating application copy')
    },
    onCompleted() {
      toast.success('Updated application copy successful')
      setState({ changes: {}, isLocked: true })
    },
  })

  function handleSubmit() {
    // restructure item data from map {id: value} to array of item objects
    const items = Object.entries(state.changes).map(([id, copy]) => ({ id, copy }))

    const variables = {
      UpdateApplicationCopyInput: {
        id: application.id,
        items,
      },
    }

    void updateApplicationCopy({ variables })
  }

  function handleDiscard() {
    setState({ changes: {}, isLocked: true })
  }

  return (
    <FinalCopyFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        {/* TODO: (matthewalbrecht) Prompt user before discarding  */}
        <Button $type="secondary" onClick={handleDiscard} disabled={loading}>
          Cancel
        </Button>
        <Button $type="accept" onClick={handleSubmit} disabled={loading}>
          Submit Changes
        </Button>
      </Flex>
    </FinalCopyFooterBox>
  )
}

/**
 * used to update application copy
 */
const UPDATE_APPLICATION_COPY = gql`
  mutation UpdateApplicationCopy(
    $UpdateApplicationCopyInput: UpdateApplicationCopyInput!
  ) {
    updateApplicationCopy(input: $UpdateApplicationCopyInput) {
      application {
        id
        items {
          id
          copy
          name
        }
      }
    }
  }
`
