import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { DATE_FORMAT, EMPTY_INPUT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import SourcerWorkStatsTable from './SourcerWorkStatsTable'
import Container from 'components/Container'
import { DatePicker } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { Maybe } from 'generated/graphql'

export default function SourcerWorkStats(): React.ReactElement {
  const [dateSince, setDateSince] = useState<Maybe<Dayjs>>(null)

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            My Sourcing Stats
          </Txt>
        </Padding>
        <DatePicker
          label="Date Since"
          name="dateSince"
          value={dateSince ? dateSince.format(DATE_FORMAT.ONLY_DATE) : EMPTY_INPUT}
          onValueChange={(value) => setDateSince(dayjs(value))}
        />
      </Container>
      <Padding top={4}>
        <SourcerWorkStatsTable dateSince={dateSince} />
      </Padding>
    </ViewBox>
  )
}

SourcerWorkStats.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.MY_SOURCING_STATS}
    key={ROUTE_PATHS.MY_SOURCING_STATS}
  >
    <SourcerWorkStats />
  </PrivateRoute>,
]
