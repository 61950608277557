import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { Prompt } from 'react-router'
import { toast } from 'react-toastify'
import { ReactFormEvent, ReactSetState } from 'types'
import { COPY, ID } from './ReportApplicationInfo.constants'
import { Application, Status } from './ReportApplicationInfo.types'
import { ButtonRow, RejectButtonBox } from './ReportApplicationInfoStyles'
import Button from 'components/Button'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import {
  DeclineMissingApplicationInfoByPublicMutation,
  DeclineMissingApplicationInfoByPublicMutationVariables,
  MissingApplicationInfoDeclineReason,
  ProvideMissingApplicationInfoByPublicMutation,
  ProvideMissingApplicationInfoByPublicMutationVariables,
} from 'generated/graphql'

type Props = {
  application: Application
  setStatus: ReactSetState<Status>
}

export default function ReportApplicationInfoForm({
  application,
  setStatus,
}: Props): React.ReactElement {
  const [answers, setAnswers] = useState<string>(application.questions)
  const [reportMissingInfo] = useMutation<
    ProvideMissingApplicationInfoByPublicMutation,
    ProvideMissingApplicationInfoByPublicMutationVariables
  >(REPORT_MISSING_APPLICATION_INFO, {
    onCompleted() {
      toast.success(COPY.REPORT_SUCCESS)
      setStatus(Status.Success)
    },
    onError() {
      toast.error(COPY.REPORT_ERROR)
    },
  })
  const [declineApplication] = useMutation<
    DeclineMissingApplicationInfoByPublicMutation,
    DeclineMissingApplicationInfoByPublicMutationVariables
  >(DECLINE_APPLICATION, {
    onCompleted() {
      toast.success(COPY.REJECT_SUCCESS)
      setStatus(Status.Success)
    },
    onError() {
      toast.error(COPY.REJECT_ERROR)
    },
  })

  const hasChanges = application.questions !== answers

  /**
   * handles
   * @param event form submission event
   */
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    const variables = {
      ProvideMissingApplicationInfoByPublicInput: {
        missingApplicationInfoPublicId: application.publicId,
        answers,
      },
    }

    void reportMissingInfo({ variables })
  }

  function handleRejectionClick(declineReason: MissingApplicationInfoDeclineReason) {
    if (window.confirm(COPY.REJECTION_PROMPT)) {
      const variables = {
        DeclineMissingApplicationInfoByPublicInput: {
          missingApplicationInfoPublicId: application.publicId,
          declineReason,
        },
      }
      void declineApplication({ variables })
    }
  }

  return (
    <form id={ID.SUBMIT_FORM} onSubmit={handleSubmit}>
      <TextArea
        label="Additional Questions Asked on Application"
        description="Please answer the following questions inline"
        onValueChange={(value) => setAnswers(value)}
        value={answers}
        required
        height={250}
      />
      <Padding top={2}>
        <ButtonRow>
          <Button
            $type="secondary"
            onClick={() =>
              handleRejectionClick(
                MissingApplicationInfoDeclineReason.ALREADY_RESPONDED
              )
            }
            type="button"
          >
            Already Responded
          </Button>
          <RejectButtonBox>
            <Button
              $type="rejectAlt"
              onClick={() =>
                handleRejectionClick(
                  MissingApplicationInfoDeclineReason.NO_LONGER_INTERESTED_IN_JOB
                )
              }
              type="button"
              fullWidth
            >
              No longer interested in job
            </Button>
          </RejectButtonBox>
          <Padding left={{ mobile: 0, tablet: 1 }} top={{ mobile: 1, tablet: 0 }}>
            <Button $type="accept" disabled={!hasChanges} fullWidth>
              Submit
            </Button>
          </Padding>
        </ButtonRow>
      </Padding>
      <Prompt
        when={hasChanges}
        message="You have unsubmitted work, are you sure you want to leave?"
      />
    </form>
  )
}

const REPORT_MISSING_APPLICATION_INFO = gql`
  mutation ProvideMissingApplicationInfoByPublic(
    $ProvideMissingApplicationInfoByPublicInput: ProvideMissingApplicationInfoByPublicInput!
  ) {
    provideMissingApplicationInfoByPublic(
      input: $ProvideMissingApplicationInfoByPublicInput
    ) {
      success
    }
  }
`

const DECLINE_APPLICATION = gql`
  mutation DeclineMissingApplicationInfoByPublic(
    $DeclineMissingApplicationInfoByPublicInput: DeclineMissingApplicationInfoByPublicInput!
  ) {
    declineMissingApplicationInfoByPublic(
      input: $DeclineMissingApplicationInfoByPublicInput
    ) {
      success
    }
  }
`
