import dayjs from 'dayjs'
import React from 'react'
import { DATE_FORMAT } from 'utils/constants'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { ContractSend } from './Contracts.types'
import Button from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'

type Props = {
  contractSend: ContractSend
}

export default function ContractsRow({ contractSend }: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Txt size={14}>{`${contractSend.firstName} ${contractSend.lastName}`}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{contractSend.personalEmail}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {dayjs(contractSend.createdAt).format(DATE_FORMAT.DATE_AND_TIME)}
        </Txt>
      </BodyData>
      <BodyData collapse>
        <ExternalLink
          url={`https://freeagency.com/agreement/${contractSend.publicShortId}`}
          inheritStyles
        >
          <Button small noWrap>
            View Contract
          </Button>
        </ExternalLink>
      </BodyData>
    </BodyRow>
  )
}
