import {
  ConfigPageEditorChanges,
  UpdateTemplateSequenceCopyInput,
} from './ClientTemplates.types'

/**
 * Builds an array of UpdateTemplateSequenceCopyInput objects
 */
export function buildUpdateSequenceCopyVariables(
  editorChanges: ConfigPageEditorChanges
): UpdateTemplateSequenceCopyInput[] {
  const sequenceCopyVariables: UpdateTemplateSequenceCopyInput[] = []
  for (const [sequenceId, sequence] of Object.entries(editorChanges)) {
    const templatesVariables = []

    for (const [templateId, copy] of Object.entries(sequence)) {
      templatesVariables.push({
        id: templateId,
        copy,
      })
    }

    sequenceCopyVariables.push({
      UpdateTemplateSequenceCopyInput: {
        id: sequenceId,
        templates: templatesVariables,
      },
    })
  }

  return sequenceCopyVariables
}
