import React from 'react'
import styled from 'styled-components/macro'
import { EMPTY_INPUT } from 'utils/constants'
import {
  getPrefillMap,
  extractUniqueTemplateVariablesFromItems,
  TemplateVariableInfo,
} from 'utils/helpers/templateVariables'
import useFormState from 'views/ClientTracker/useFormState'
import { TaglineRequest } from 'views/WriterSubmission/WriterSubmission.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { TextInput, TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import WordCountLabel from 'components/WordCountLabel'

const TEMPLATE_TEXT_AREA_ROWS = 10
const MAX_WORD_COUNT = 85
const TEMPLATE_NAME_TAGLINE = 'tagline'

const TaglineContainer = styled.div`
  margin-top: ${({ theme }) => theme.rhythm(-2)};
`

type TemplatingProps = {
  /* TODO (matthewalbrecht): use a fragment */
  taglineRequest: TaglineRequest
  handleTemplateFill<
    TemplateFields extends Record<string, string | null | undefined>
  >(
    templateFields: TemplateFields
  ): void
}

function sortByTemplateVariables(
  a: TemplateVariableInfo,
  b: TemplateVariableInfo
): number {
  if (b.name === 'role') {
    return 1
  }
  if (b.name === 'company') {
    return 1
  }
  if (a.name !== 'tagline') {
    return -1
  }
  return 0
}

export default function Templating({
  taglineRequest,
  handleTemplateFill,
}: TemplatingProps): React.ReactElement {
  const prefillMap = getPrefillMap(taglineRequest)
  const templateVariables = extractUniqueTemplateVariablesFromItems(
    taglineRequest.templateSequence.templates.map((template) => template.copy)
  )
  type TemplateFields = Record<
    keyof typeof templateVariables,
    string | null | undefined
  >

  const defaultState = Object.values(templateVariables).reduce(
    (final: TemplateFields, templateVariable) => {
      if (templateVariable.prefill && prefillMap[templateVariable.prefill]) {
        final[templateVariable.name] = prefillMap[templateVariable.prefill]
      }
      return final
    },
    {}
  )

  const { formState, setFormField } = useFormState<TemplateFields>(defaultState)
  const hasTemplateVariables = Object.keys(templateVariables).length > 0

  return (
    <>
      {!hasTemplateVariables && (
        <Txt as="p" alignment="center" italic color="faGrey3">
          No template variables found.
        </Txt>
      )}
      <HList size={2} rowGapSize={3}>
        {Object.values(templateVariables)
          .filter((templateVariable) => !templateVariable.exclude)
          .sort((a, b) => sortByTemplateVariables(a, b))
          .map((templateVariable) =>
            templateVariable.inputType === 'textInput' ? (
              <TextInput
                key={templateVariable.name}
                label={templateVariable.friendlyName}
                name={templateVariable.name}
                autoComplete="off"
                value={formState[templateVariable.name] ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  setFormField(templateVariable.name, value || null)
                }
              />
            ) : (
              <React.Fragment key={templateVariable.name}>
                <TextArea
                  label={templateVariable.friendlyName}
                  name={templateVariable.name}
                  rows={TEMPLATE_TEXT_AREA_ROWS}
                  value={formState[templateVariable.name] ?? EMPTY_INPUT}
                  onValueChange={(value) =>
                    setFormField(templateVariable.name, value || null)
                  }
                />
                {templateVariable.name === TEMPLATE_NAME_TAGLINE && (
                  <TaglineContainer>
                    <WordCountLabel
                      txtProps={{ size: 12 }}
                      label="Words"
                      content={formState[templateVariable.name] ?? ''}
                      maxWordCount={MAX_WORD_COUNT}
                    />
                  </TaglineContainer>
                )}
              </React.Fragment>
            )
          )}
      </HList>
      {hasTemplateVariables && (
        <Padding top={2}>
          <Flex justify="flex-end">
            <Button
              $type="highlight"
              onClick={() => handleTemplateFill<TemplateFields>(formState)}
            >
              Fill Templates
            </Button>
          </Flex>
        </Padding>
      )}
    </>
  )
}
