import dayjs from 'dayjs'
import { ColumnHeader, Rating } from 'types'
import getNodes from 'utils/helpers/getNodes'
import parseRating from 'utils/helpers/parseRating'
import { getAvgRating } from 'utils/helpers/stats'
import { sortByProp } from 'utils/sort'
import {
  CLIENT_COLUMNS,
  GROUP_BY_TYPES,
  SOURCER_COLUMNS,
  TALENT_AGENT_COLUMNS,
} from './SourcerStats.constants'
import {
  Filter,
  GroupByOptions,
  GroupedJobs,
  SortProps,
  SourcedJob,
  SourcedJobConnection,
  SourcerStatsValues,
} from './SourcerStats.types'

/**
 * normalize the raw data from gql
 * @param connection sourced job connection
 */
export function normalizeSourcedJobs(
  connection: SourcedJobConnection
): SourcedJob[] {
  const rawSourcedJobs = getNodes(connection)

  return rawSourcedJobs.map((job) => ({
    sourcerId: job.createdBy.id,
    sourcerName: job.createdBy.name,
    rating: parseRating(job.reviews[0]?.rating),
    rejectionReason: job.reviews[0]?.rejectionReason,
    clientId: job.client.id,
    clientName: job.client.name,
    talentAgentId: job.client.talentAgent.id,
    talentAgentName: job.client.talentAgent.name,
    dateToSortBy: dayjs(job.createdAt),
  }))
}

/**
 * filters out any unwanted jobs
 * @param sourcedJobs list of normalized sourced jobs
 * @param filter filter state
 */
export function filterSourcedJobs(
  sourcedJobs: SourcedJob[],
  { clientId, sourcerId, talentAgentId, startDate }: Filter
): SourcedJob[] {
  return sourcedJobs.filter((job) => {
    if (clientId && clientId !== job.clientId) {
      return false
    }
    if (sourcerId && sourcerId !== job.sourcerId) {
      return false
    }
    if (talentAgentId && talentAgentId !== job.talentAgentId) {
      return false
    }
    if (startDate > job.dateToSortBy) {
      return false
    }
    return true
  })
}

/**
 * returns the value that jobs should be grouped by
 * to get the right breakdown of data
 *
 * @param groupBy groupby filter value
 * @param submission sourcing submission record
 */
function getGroupValue(groupBy: GroupByOptions, sourcedJob: SourcedJob) {
  switch (groupBy) {
    case GROUP_BY_TYPES.CLIENT_ID:
      return `${sourcedJob.sourcerId}:${sourcedJob.clientId}`
    case GROUP_BY_TYPES.TALENT_AGENT_ID:
      return `${sourcedJob.sourcerId}:${sourcedJob.talentAgentId}`
    default:
      return sourcedJob.sourcerId
  }
}

/**
 * Group our jobs together based on our groupBy filter
 * @param sourcedJobs normalized + filtered jobs
 * @param groupBy our groupBy filter
 */
export function groupSourcedJobs(
  sourcedJobs: SourcedJob[],
  groupBy: GroupByOptions
): GroupedJobs {
  return sourcedJobs.reduce((groups: GroupedJobs, job) => {
    const groupValue = getGroupValue(groupBy, job)
    const entries = groups[groupValue]
    if (entries) {
      entries.push(job)
    } else {
      groups[groupValue] = [job]
    }
    return groups
  }, {})
}

/**
 * returns the stats information for each group in an array
 * @param groupedJobs jobs grouped by the chosen filter
 */
export function calculateGroupStats(groupedJobs: GroupedJobs): SourcerStatsValues[] {
  const groups = Object.values(groupedJobs)

  return groups.reduce(calculateStatsRow, [])
}

/**
 * calculates the relevant stats for a row and adds it to the rowData array
 * @param tableData accumulating row data
 * @param group individual group
 */
function calculateStatsRow(
  tableData: SourcerStatsValues[],
  group: SourcedJob[]
): SourcerStatsValues[] {
  const firstJob = group[0]
  if (!firstJob) {
    return tableData
  }

  const total = group.length
  const ratings: Rating[] = []
  let rejectedCount = 0

  group.forEach((job) => {
    if (job.rating !== undefined) {
      ratings.push(job.rating)
    }
    if (job.rejectionReason) {
      rejectedCount += 1
    }
  })

  const [avgRating, avgRatingTotal, onesAndTwosRate] = getAvgRating(ratings)
  const rejectionRate = rejectedCount / total

  tableData.push({
    sourcerId: firstJob.sourcerId,
    sourcerName: firstJob.sourcerName,
    clientId: firstJob.clientId,
    clientName: firstJob.clientName,
    talentAgentId: firstJob.talentAgentId,
    talentAgentName: firstJob.talentAgentName,
    avgRating,
    avgRatingTotal,
    onesAndTwosRate,
    rejectionRate,
    total,
  })

  return tableData
}

/**
 * sorts the rows
 * @param tableData all of our stat rows
 * @param groupBy our group by filter value
 */
export function sortTableData(
  tableData: SourcerStatsValues[],
  groupBy: GroupByOptions
): SourcerStatsValues[] {
  const { primarySort, secondarySort } = getSortProps(groupBy)
  return tableData.sort((a, b) => sortByProp(a, b, primarySort, secondarySort))
}

/**
 * returns primary and secondary sort props
 * @param groupBy our group by value
 */
function getSortProps(groupBy: GroupByOptions): SortProps {
  switch (groupBy) {
    case GROUP_BY_TYPES.SOURCER_ID:
      return {
        primarySort: 'sourcerName',
        secondarySort: undefined,
      }
    case GROUP_BY_TYPES.TALENT_AGENT_ID:
      return {
        primarySort: 'talentAgentName',
        secondarySort: 'sourcerName',
      }
    default:
      return {
        primarySort: 'clientName',
        secondarySort: '-total',
      }
  }
}

/**
 * return correct column header array
 * @param groupBy our group by filter value
 */
export function getColumns(groupBy: GroupByOptions): ColumnHeader[] {
  switch (groupBy) {
    case GROUP_BY_TYPES.SOURCER_ID:
      return SOURCER_COLUMNS
    case GROUP_BY_TYPES.TALENT_AGENT_ID:
      return TALENT_AGENT_COLUMNS
    default:
      return CLIENT_COLUMNS
  }
}
