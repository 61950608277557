import { lighten, darken } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { DropdownOption } from 'types'
import Button from 'components/Button'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'

type Props = {
  name: string
  label?: string
  defaultChecked?: boolean
  option: DropdownOption
  required?: boolean
  checked?: boolean
  onValueChange?: (checked: boolean) => void
  description?: string
  disabled?: boolean
}

export const CheckboxInput = styled.input`
  position: absolute;
  opacity: 0 !important;
  cursor: pointer;
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  z-index: -1;

  &:disabled ~ div {
    cursor: default;
    opacity: 0.7;
  }

  &:checked {
    ~ div {
      background-color: ${({ theme }) => theme.color.faBlue};
      border: 0.1rem solid ${({ theme }) => theme.color.faBlue};
      color: ${({ theme }) => theme.color.white};
    }
  }
  &:hover:not(:disabled) {
    ~ div {
      background-color: ${({ theme }) => darken(0.1, theme.color.white)};
    }
    &:checked ~ div {
      background-color: ${({ theme }) => lighten(0.1, theme.color.faBlue)};
      border: 0.1rem solid ${({ theme }) => lighten(0.1, theme.color.faBlue)};
    }
  }
`

const CheckboxWrapper = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
`

export default function Checkbox({
  name,
  label,
  option,
  checked,
  defaultChecked,
  description,
  required,
  onValueChange,
  disabled = false,
}: Props): React.ReactElement {
  return (
    <div>
      {label && <Label content={label} />}
      {description && <InputDescription description={description} />}

      <CheckboxWrapper>
        <CheckboxInput
          id={name}
          onChange={(event) => onValueChange?.(event.currentTarget.checked)}
          type="checkbox"
          name={name}
          value={option.value}
          defaultChecked={defaultChecked !== undefined ? defaultChecked : undefined}
          checked={checked !== undefined ? checked : undefined}
          required={required}
          disabled={disabled}
        />
        <Button as="div" small>
          {option.label}
        </Button>
      </CheckboxWrapper>
    </div>
  )
}
