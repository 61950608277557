import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useEffect } from 'react'
import {
  generatePath,
  Redirect,
  useHistory,
  useParams,
  Link,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import useSourcedJobsReviewQueue from 'hooks/useSourcedJobsReviewQueue'
import { GLOBAL_COPY, INITIAL, DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import useFormState from 'views/ClientTracker/useFormState'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import Icon from 'components/Icon/Icon'
import { DEFAULT_FORM_STATE } from './SourcedJobsReview.constants'
import { FormState, Params } from './SourcedJobsReview.types'
import SourcedJobsReviewFooter from './SourcedJobsReviewFooter'
import SourcedJobsReviewLeft from './SourcedJobsReviewLeft'
import SourcedJobsReviewRight from './SourcedJobsReviewRight'
import Container from 'components/Container'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import {
  ClientSourcedJobsReviewQueueQuery,
  ClientSourcedJobsReviewQueueQueryVariables,
} from 'generated/graphql'

const GOOGLE_SEARCH_URL = 'https://google.com/search'
const CRUNCHBASE_QUERY = 'site:crunchbase.com'

export default function SourcedJobsReview(): JSX.Element {
  const history = useHistory()
  const { sourcedJobId, freeAgentId } = useParams<Params>()
  const redirectToInitial = sourcedJobId === INITIAL
  const { fullQueue, loading } = useSourcedJobsReviewQueue(undefined, freeAgentId)
  const { formState, setFormField, setFormState } =
    useFormState<FormState>(DEFAULT_FORM_STATE)
  const noQueue = fullQueue === undefined || fullQueue.length === 0
  const { data } = useQuery<
    ClientSourcedJobsReviewQueueQuery,
    ClientSourcedJobsReviewQueueQueryVariables
  >(GET_SOURCED_JOBS_REVIEW_BY_ID, {
    variables: {
      id: sourcedJobId,
    },
    skip: redirectToInitial,
  })

  // history push is used here instead of the render method to call the toast
  useEffect(() => {
    if (!loading && noQueue) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, noQueue, loading])

  if (redirectToInitial && fullQueue?.[0]) {
    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.REVIEW_SOURCED_JOBS_QUEUE, {
          sourcedJobId: fullQueue[0],
          freeAgentId,
        })}
      />
    )
  }

  if (noQueue || !data?.sourcedJob) {
    return <Message vertical message="Loading sourced jobs review..." />
  }

  const sourcedJob = data.sourcedJob
  const datePosted = sourcedJob.datePosted && dayjs(sourcedJob.datePosted).fromNow()
  const dateSourced = dayjs(sourcedJob.createdAt).format(DATE_FORMAT.FRIENDLY_DATE)
  const company = sourcedJob.company

  return (
    <DualScrollContainer key={sourcedJobId}>
      <Container noMax>
        <Padding top={6} bottom={2}>
          <Flex justify="space-between" align="center">
            <div>
              <Flex align="center">
                <Txt size={24} bold>
                  {company.name}
                </Txt>
                <Padding inline left={1}>
                  <ExternalLink
                    url={
                      company.crunchbaseUrl
                        ? company.crunchbaseUrl
                        : `${GOOGLE_SEARCH_URL}?${createSearchParams({
                            q: `${CRUNCHBASE_QUERY} ${company.name}`,
                          })}`
                    }
                  >
                    <Icon
                      name="Crunchbase"
                      height={24}
                      width={24}
                      primaryFill="faGrey2"
                      primaryFillHover="black"
                      nudge={{ down: 0.5 }}
                    />
                  </ExternalLink>
                </Padding>
                {company.domain && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.domain}>
                      <Icon
                        name="Globe"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="black"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.linkedinUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.linkedinUrl}>
                      <Icon
                        name="Linkedin"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="linkedinBlue"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.glassdoorUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.glassdoorUrl}>
                      <Icon
                        name="Glassdoor"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="glassdoorGreen"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.angellistUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.angellistUrl}>
                      <Icon
                        name="AngelList"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="black"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.id && (
                  <Padding inline left={1}>
                    <Link
                      to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, {
                        companyId: company.id,
                      })}
                    >
                      <Icon
                        name="Info"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="black"
                        nudge={{ down: 0.5 }}
                      />
                    </Link>
                  </Padding>
                )}
              </Flex>
              <Padding top={1}>
                <ExternalLink
                  txtProps={{
                    color: 'text',
                    hoverColor: 'black',
                    lineHeight: 1.3,
                    size: 18,
                  }}
                  url={sourcedJob.url}
                >
                  {sourcedJob.title}
                </ExternalLink>
              </Padding>
            </div>
            <Flex column align="flex-start" justify="center">
              <Txt>{`Date Sourced: ${dateSourced}`}</Txt>
              {datePosted && (
                <Padding top={1}>
                  <Txt>{`Date Posted: ${datePosted}`}</Txt>
                </Padding>
              )}
            </Flex>
          </Flex>
        </Padding>
      </Container>
      <DualScroll
        leftContent={<SourcedJobsReviewLeft sourcedJob={sourcedJob} />}
        rightContent={
          <SourcedJobsReviewRight
            queue={fullQueue}
            sourcedJobId={sourcedJobId}
            freeAgentId={freeAgentId}
            formState={formState}
            sourcedJob={sourcedJob}
            setFormState={setFormState}
            setFormField={setFormField}
          />
        }
      />
      <SourcedJobsReviewFooter
        queue={fullQueue}
        sourcedJobId={sourcedJobId}
        freeAgentId={freeAgentId}
        setFormState={setFormState}
      />
    </DualScrollContainer>
  )
}

SourcedJobsReview.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REVIEW_SOURCED_JOBS_QUEUE}
    key={ROUTE_PATHS.REVIEW_SOURCED_JOBS_QUEUE}
  >
    <SourcedJobsReview />
  </PrivateRoute>,
]

const GET_SOURCED_JOBS_REVIEW_BY_ID = gql`
  query ClientSourcedJobsReviewQueue($id: ID!) {
    sourcedJob(id: $id) {
      id
      title
      url
      createdAt
      datePosted
      notes
      jobDescription
      client {
        id
        name
        roleExperience
        roleCategory
        remotePrefs
        roleCategory
        desiredCompanySize
        desiredIndustries
        desiredSalary
        talentAgent {
          id
          name
        }
      }
      company: companyEntity {
        id
        name
        domain
        linkedinUrl
        glassdoorUrl
        crunchbaseUrl
        angellistUrl
        employeeCount
        size
        yearFounded
        location
        companyType
        companyDescription
      }
    }
  }
`
