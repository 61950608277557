import React, { Fragment } from 'react'
import { DropdownOption, KeyValue, Undefinable } from 'types'
import {
  GenericAssignItem,
  GenericGroupedRowData,
} from 'utils/helpers/groupAssigItems'
import {
  TableBox,
  HeaderData,
  HeaderRow,
  Table,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import {
  useChanges,
  modifyCheckedItems,
  updateData,
  ItemsUpdateMapValue,
} from './Assign.context'
import { CheckedItems, InputTypes, ColumnItem } from './Assign.types'
import AssignHighlightedClientRow from './AssignHighlightedClientRow'
import AssignHighlightedWorkerRow from './AssignHighlightedWorkerRow'
import AssignRow from './AssignRow'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  isReassigning: boolean
  groupedRowData: Undefinable<GenericGroupedRowData[]>
  assigneeOptions: DropdownOption[]
  columns: ColumnItem[]
  workerType: string
  getEditPath?: (id: string, pathname: string) => string
  DeleteButtonComponent?: React.ElementType
  OverrideColumns?: React.ElementType
  ClientRowComponent?: React.ElementType
  WorkerRowComponent?: React.ElementType
  DueDatePicker?: React.ElementType
  dueDateFormat?: string
  hideDatePicker?: boolean
  isFinalReview?: boolean
}

export default function AssignTable({
  isReassigning,
  groupedRowData,
  assigneeOptions,
  columns,
  workerType,
  isFinalReview,
  getEditPath,
  DeleteButtonComponent,
  OverrideColumns,
  ClientRowComponent,
  WorkerRowComponent,
  DueDatePicker,
  hideDatePicker,
  dueDateFormat,
}: Props): JSX.Element {
  const { state, dispatch } = useChanges()

  if (!groupedRowData || !assigneeOptions.length) {
    return (
      <Message
        message={`Loading ${isReassigning ? 'assigned' : 'unassigned'} items...`}
      />
    )
  }

  /**
   * if a highlighted row checkbox is updated we update checkedItem state with
   * either all of the selected person's items set to true or false
   *
   * @param event checkbox change event
   */
  function handleGroupCheck(event: React.FormEvent<HTMLInputElement>) {
    const groupId = event.currentTarget.value
    const newCheckedItems: CheckedItems = {}
    let valueToSet = false

    // check if all of this groups items are checked
    const itemIds = getItemIdsFromGroup(groupId)
    const allItemsChecked = getAllGroupItemsChecked(itemIds)

    // if any of the persons checkboxes aren't checked we will set them all to true
    // otherwise if they are all checked we will uncheck them all
    if (!allItemsChecked) {
      valueToSet = true
    }

    // this builds the new property and values we will spread when setting state
    itemIds.forEach((itemId: string) => {
      newCheckedItems[itemId] = valueToSet
    })
    modifyCheckedItems(dispatch, newCheckedItems)
  }

  /**
   * used to build an object of all items that are checked
   * and their values set to the assignee id selected
   *
   * @param value an assigneeId
   */
  function buildUpdateMap(value: string, type: InputTypes, itemId: string) {
    const idsToAssign = [itemId]
    const newMap: KeyValue<ItemsUpdateMapValue> = {}

    // get all checked items from state and push ids onto array
    for (const id in state.checkedItems) {
      if (state.checkedItems[id]) {
        idsToAssign.push(id)
      }
    }

    // loop and create new properties for newMap
    idsToAssign.forEach((id) => {
      // mutation is expecting null if input value is an empty, not ''
      newMap[id] = { [type]: value || null }
    })

    return newMap
  }

  /**
   * maps over the group with the given id and returns an array
   * of their items' ids
   *
   * @param groupId
   */
  function getItemIdsFromGroup(groupId: string) {
    return (
      groupedRowData
        ?.find((group: GenericGroupedRowData) => group.id === groupId)
        ?.items.map((item: GenericAssignItem) => item.id) || []
    )
  }

  /**
   * used to determine if all ids in the array's values are true (meaning they are checked)
   * @param itemIds array of item ids
   */
  function getAllGroupItemsChecked(itemIds: string[]): boolean {
    return itemIds.reduce(
      (allChecked: boolean, itemId) =>
        state.checkedItems[itemId] === true && allChecked,
      true
    )
  }

  /**
   * used to set a item to checked value to true or false
   * @param event checkbox change event
   */
  function handleItemCheck(event: React.FormEvent<HTMLInputElement>) {
    const itemId = event.currentTarget.value

    // if `state.checkedItems[itemId]` doesn't exist yet we just !undefined
    // which will return true
    modifyCheckedItems(dispatch, {
      [itemId]: !state.checkedItems[itemId],
    })
  }

  /**
   * used to handle either bulk updating item's due date value or
   * just change item due date for that row (depends on if any checkboxes are actively checked)
   *
   * @param value value of dropdown item selected
   * @param itemId id of the item where the dropdown was changed
   * @param type string representation of type of data being updated
   */
  function handleInputChange(value: string, itemId: string, type: InputTypes) {
    // creates a map -> {itemId: {due, assignee}} where either due or assignee will exist
    // for all items that have checked checkboxes
    const newItemDataMap = buildUpdateMap(value, type, itemId)

    updateData(dispatch, newItemDataMap)
  }

  /**
   * returns true if all groups items are checked
   * @param groupId
   */
  const isGroupChecked = (groupId: string): boolean => {
    const itemIds = getItemIdsFromGroup(groupId)
    return getAllGroupItemsChecked(itemIds)
  }

  const columnItems = isReassigning
    ? columns.filter((column) => !column.hideOnAssigned)
    : columns.filter((column) => !column.hideOnAvailable)

  const HighlightedClientRowComponent =
    ClientRowComponent || AssignHighlightedClientRow
  const HighlightedWorkerRowComponent =
    WorkerRowComponent || AssignHighlightedWorkerRow

  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {columnItems.map((column) => (
                <HeaderData collapse={column.collapse} key={column.id}>
                  <Txt size={12} bold>
                    {column.name}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {groupedRowData.map((group) => {
              return (
                <Fragment key={group.id}>
                  {isReassigning ? (
                    <HighlightedWorkerRowComponent
                      group={group}
                      handleGroupCheck={handleGroupCheck}
                      checked={isGroupChecked(group.id)}
                      key={group.id}
                      workerType={workerType}
                    />
                  ) : (
                    <HighlightedClientRowComponent
                      group={group}
                      handleGroupCheck={handleGroupCheck}
                      checked={isGroupChecked(group.id)}
                      key={group.id}
                    />
                  )}
                  {group.items.map((item) => (
                    <AssignRow
                      key={item.id}
                      item={item}
                      handleInputChange={handleInputChange}
                      checked={state.checkedItems[item.id] ?? false} // cannot be undefined
                      handleItemCheck={handleItemCheck}
                      assigneeOptions={assigneeOptions}
                      isReassigning={isReassigning}
                      isFinalReview={isFinalReview}
                      getEditPath={getEditPath}
                      DeleteButtonComponent={DeleteButtonComponent}
                      OverrideColumns={OverrideColumns}
                      groupId={group.id}
                      DueDatePicker={DueDatePicker}
                      hideDatePicker={hideDatePicker}
                      dueDateFormat={dueDateFormat}
                    />
                  ))}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
        {!groupedRowData.length && (
          <Flex justify="center" align="center">
            <Padding vertical={6}>
              <Txt>No {isReassigning ? 'Assigned' : 'Available'} Items</Txt>
            </Padding>
          </Flex>
        )}
      </TableWrapper>
    </TableBox>
  )
}
