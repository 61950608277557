import React from 'react'
import { sortByProp } from 'utils/sort'
import DueDateTableItem from 'components/DueDateTableItem/DueDateTableItem'
import TableBody from 'components/Table/TableBody'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { GenericItem, GroupedRequests } from './CurrentAssignments.types'

type Props<T> = {
  groupedRequests: GroupedRequests<T>
}

const COLUMNS = [
  { id: 'assignee', name: 'Assignee' },
  { id: 'dueDates', name: 'Due Dates' },
]

export default function CurrentAssignmentsByAssignee<T extends GenericItem>({
  groupedRequests,
}: Props<T>): JSX.Element {
  /**
   * Convert our grouped requests into a sorted array
   * Object.entries takes `prop: value` converts it into [prop, value]
   */
  const sortedTableRows = Object.entries(groupedRequests).sort((a, b) =>
    sortByProp(a[1][0], b[1][0], 'assigneeName')
  )

  const isEmptyTable = !sortedTableRows.length

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COLUMNS.map((header) => (
            <TableHeaderItem key={header.name} content={header.name} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {sortedTableRows.map(([name, requests]) => (
          <tr key={name}>
            <TableBodyItem first content={name} />
            <TableBodyItem>
              <DueDateTableItem items={requests} />
            </TableBodyItem>
          </tr>
        ))}
      </TableBody>
      {isEmptyTable && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}
