import { gql } from '@apollo/client'
import React from 'react'
import Editor from 'components/Editor/Editor'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import { TaglineSubmission } from './ReviewFeedback.types'
import ExternalLink from 'components/ExternalLink'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  taglineSubmission: TaglineSubmission
}

function ReviewFeedbackLeft({ taglineSubmission }: Props): JSX.Element {
  const { taglineRequest } = taglineSubmission

  return (
    <VList size={4}>
      <Txt as="h2" size={24} bold>
        {taglineRequest.client.name}
      </Txt>
      {taglineRequest.client.resumeUrl && (
        <PDFViewer url={taglineRequest.client.resumeUrl} />
      )}
      {taglineRequest.client.linkedinUrl && (
        <Padding top={4}>
          <ExternalLink url={taglineRequest.client.linkedinUrl} label="LinkedIn" />
        </Padding>
      )}
      {taglineRequest.huntrHtmlDescription && (
        <Editor
          defaultValue={taglineRequest.huntrHtmlDescription}
          label="Notes from Huntr"
          height={80}
          disabled
        />
      )}
      {taglineRequest.notes && (
        <TextArea
          defaultValue={taglineRequest.notes}
          label="Assignment notes"
          disabled
        />
      )}
      {taglineRequest.client.bio && (
        <TextArea
          defaultValue={taglineRequest.client.bio}
          label={`${taglineRequest.client.name} Bio`}
          height={80}
          disabled
        />
      )}
    </VList>
  )
}

ReviewFeedbackLeft.fragments = {
  taglineRequestInfo: gql`
    fragment ReviewFeedbackLeftInfo on TaglineRequest {
      id
      huntrHtmlDescription
      notes
      client {
        id
        name
        linkedinUrl
        resumeUrl
        bio
      }
    }
  `,
}

export default ReviewFeedbackLeft
