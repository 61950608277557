import { TABS_INFO } from './ClientConfig.constants'
import { WorkStats } from './ClientConfig.types'
import { GetBasicClientWorkInfoQuery } from 'generated/graphql'

/**
 * returns object with counts of different types of work
 * @param workData stats returned from gql
 */
export function getWorkStats(
  workData: GetBasicClientWorkInfoQuery
): WorkStats | undefined {
  if (
    !workData.client ||
    !workData.pendingApplications ||
    !workData.unnasignedApplications
  ) {
    return
  }

  const numPendingApplications =
    workData.unnasignedApplications.totalCount +
    workData.pendingApplications.totalCount

  const numPendingWritingTaglines =
    workData.client.pendingWriting.totalCount + workData.client.unassigned.totalCount
  const numPendingIrReviewTaglines = workData.client.pendingAgentReview.totalCount
  const numPendingFrReviewTaglines = workData.client.pendingPartnerReview.totalCount

  const numPendingSourcedJobsReview =
    workData.client.pendingSourcedJobReviews.totalCount

  return {
    numPendingApplications,
    numPendingWritingTaglines,
    numPendingIrReviewTaglines,
    numPendingFrReviewTaglines,
    numPendingSourcedJobsReview,
  }
}

/**
 * taking the current route and find the matching tab's index for a default
 * @param path return path from useMatchRoute hook
 */
export function getSelectedTabIndex(path: string): number {
  return TABS_INFO.reduce((final, tab, index) => {
    if (tab.route === path || tab.matchingRoutes.find((route) => route === path)) {
      return index
    }
    return final
  }, 0)
}
