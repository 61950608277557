// built from https://github.com/outline/outline/blob/develop/app/components/Flex.js

import * as React from 'react'
import styled from 'styled-components/macro'

type JustifyValues =
  | 'center'
  | 'space-around'
  | 'space-between'
  | 'flex-start'
  | 'flex-end'

type AlignValues = 'stretch' | 'center' | 'baseline' | 'flex-start' | 'flex-end'

type WrapValues = 'nowrap' | 'wrap' | 'wrap-reverse'

type Props = {
  column?: boolean
  shrink?: boolean
  align?: AlignValues
  justify?: JustifyValues
  auto?: boolean
  className?: string
  children?: React.ReactNode
  as?: React.ElementType
  wrap?: WrapValues
}

const Flex = styled.div<Props>`
  display: flex;
  flex: ${({ auto }) => (auto ? '1 1 auto' : 'initial')};
  flex-direction: ${({ column }) => (column ? 'column' : 'row')};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
  flex-shrink: ${({ shrink }) => (shrink ? 1 : 'initial')};
  flex-wrap: ${({ wrap }) => wrap};
  min-height: 0;
  min-width: 0;
`

export default Flex
