export const ID = {
  SUBMIT_FORM: 'reportApplicationInfoForm',
  REJECT_FORM: 'reportApplicationRejectionForm',
}

export const COPY = {
  REJECTION_PROMPT: 'Are you sure you want to close this application?',
  REPORT_SUCCESS: 'Additional application information submitted',
  REPORT_ERROR: 'There was an error reporting the missing application information',
  REJECT_SUCCESS: 'The application has been closed',
  REJECT_ERROR: 'There was an error closing the application',
}
