import React from 'react'
import { useLocation } from 'react-router'
import { DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { ChangesProvider } from 'views/Assign/Assign.context'
import AssignHeader from 'views/Assign/AssignHeader'
import AssignTable from 'views/Assign/AssignTable'
import AssignSourcingDeleteButton from 'views/AssignSourcing/AssignSourcingDeleteButton'
import { ASSIGN_DATA, COLUMNS, REASSIGN_DATA } from './AssignSourcing.constants'
import getEditPath from './AssignSourcing.helpers'
import AssignSourcingFooter from './AssignSourcingFooter'
import AssignSourcingHighlightedClientRow from './AssignSourcingHighlightedClientRow'
import AssignSourcingHighlightedWorkerRow from './AssignSourcingHighlightedWorkerRow'
import AssignSourcingRowItems from './AssignSourcingRowItems'
import useAssignSourcingData from './useAssignSourcingData'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function AssignSourcing(): JSX.Element {
  const { pathname } = useLocation()
  const isReassigning = pathname === ROUTE_PATHS.ASSIGNED_SOURCING
  const { groupedRowData, assigneeOptions } = useAssignSourcingData(isReassigning)
  const totalSourcing = groupedRowData?.reduce(
    (total, groupedRow) => (total += groupedRow.items.length),
    0
  )
  return (
    <ChangesProvider>
      <ViewBox footer={<AssignSourcingFooter />}>
        <Padding top={6}>
          <Container noMax>
            <AssignHeader
              isReassigning={isReassigning}
              assignData={ASSIGN_DATA}
              reassignData={REASSIGN_DATA}
              appendedHeaderCopy={`(${totalSourcing ?? '0'})`}
            />
          </Container>
          <Padding top={6}>
            <AssignTable
              isReassigning={isReassigning}
              groupedRowData={groupedRowData}
              assigneeOptions={assigneeOptions}
              columns={COLUMNS}
              workerType="Sourcer"
              DeleteButtonComponent={AssignSourcingDeleteButton}
              OverrideColumns={AssignSourcingRowItems}
              getEditPath={getEditPath}
              ClientRowComponent={AssignSourcingHighlightedClientRow}
              WorkerRowComponent={AssignSourcingHighlightedWorkerRow}
              dueDateFormat={DATE_FORMAT.ONLY_DATE}
            />
          </Padding>
        </Padding>
      </ViewBox>
    </ChangesProvider>
  )
}

AssignSourcing.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ASSIGNED_SOURCING}
    key={ROUTE_PATHS.ASSIGNED_SOURCING}
  >
    <AssignSourcing />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AVAILABLE_SOURCING}
    key={ROUTE_PATHS.AVAILABLE_SOURCING}
  >
    <AssignSourcing />
  </PrivateRoute>,
]
