import dayjs, { Dayjs } from 'dayjs'

// get time from date at default due hour
export const getDueTime = (
  date: Dayjs,
  dueHour: number,
  dueMinute: number,
  tz: string
): string => date.tz(tz).hour(dueHour).minute(dueMinute).fromNow()

// get time from exact date
export const getTimeFromNow = (date: Dayjs): string => date.tz().fromNow()

// convert seconds into minutes and seconds
export function getMinutesSecondsFromSeconds(timeInSec: number): {
  minutes: number
  seconds: number
} {
  const minutes = Math.floor(timeInSec / 60)
  const seconds = timeInSec - 60 * minutes

  return { minutes, seconds }
}

/**
 * This helper function is used for datetime range filters in gql
 * @param relativeWeek number representing weeks before or after current week (-1 = last week; 1 = next week)
 * @returns returns beginning of the day sunday and end of the day saturday for a given week
 */
export function getWeekRange(relativeWeek = 0): {
  start: string
  end: string
} {
  // starts on sunday
  const start = dayjs().startOf('week').add(relativeWeek, 'weeks').format()
  const end = dayjs().endOf('week').add(relativeWeek, 'weeks').format()

  return {
    start,
    end,
  }
}
