import React from 'react'
import { GenericAssignItem } from 'utils/helpers/groupAssigItems'
import {
  BodyData,
  BodyRow,
  CheckboxLabel,
} from 'components/FullWidthTable/FullWidthTableStyles'
import Txt from 'components/Txt'

type Props = {
  group: GenericAssignItem
  handleGroupCheck: (event: React.FormEvent<HTMLInputElement>) => void
  checked: boolean
  children?: React.ReactNode
}

export default function AssignHighlightedRow({
  group,
  handleGroupCheck,
  checked,
  children,
}: Props): JSX.Element {
  return (
    <BodyRow
      color="blueHighlight"
      role="rowgroup"
      aria-label="highlighted row"
      // added for testing because theres no connection between non-highlighted rows and the highlighted rows (in the DOM structure their siblings - not nested).
      data-groupid={group.id}
    >
      <BodyData collapse>
        <CheckboxLabel htmlFor={group.id}>
          <input
            id={group.id}
            type="checkbox"
            value={group.id}
            onChange={handleGroupCheck}
            checked={checked}
            aria-label="highlighted row checkbox"
          />
        </CheckboxLabel>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14} bold>
          {group.name}
        </Txt>
      </BodyData>
      {children}
    </BodyRow>
  )
}
