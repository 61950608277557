import { gql } from '@apollo/client'
import { TemplateMap } from 'types'
import { getTemplateVariableRegex, TEMPLATE_VARIABLE_REGEX } from 'utils/constants'
import { GetPrefillMapInfoFragment } from 'generated/graphql'

type PrefillMap = {
  companyName:
    | NonNullable<GetPrefillMapInfoFragment['company']>['name']
    | null
    | undefined
  jobTitle: GetPrefillMapInfoFragment['jobTitle']
  enrichmentName?: null
}

type PrefillKeys = keyof PrefillMap

export function getPrefillMap(
  taglineRequest: GetPrefillMapInfoFragment
): PrefillMap {
  return {
    companyName: taglineRequest.company?.name ?? taglineRequest.employerName,
    jobTitle: taglineRequest.jobTitle,
  }
}

getPrefillMap.fragments = {
  info: gql`
    fragment GetPrefillMapInfo on TaglineRequest {
      company {
        name
      }
      employerName
      jobTitle
    }
  `,
}

export function extractUniqueTemplateVariablesFromItems(
  items: string[]
): Record<string, TemplateVariableInfo> {
  const variables: string[] = []
  const variableObject: Record<string, TemplateVariableInfo> = {}
  items.forEach((item) =>
    [...item.matchAll(TEMPLATE_VARIABLE_REGEX)]
      .map((m) => m[0])
      .forEach((templateVariable) => {
        if (templateVariable) {
          variables.push(templateVariable)
        }
      })
  )
  const uniques = [...new Set(variables)]
  uniques
    .map((templateVariable) =>
      // replace all underscores w/ nothing
      // this allows us to properly dedupe keys since writers aren't perfect at using the same amount of underscores
      templateVariable.toLowerCase().replace(RegExp('^_+|_+$', 'gi'), '')
    )
    .forEach((templateVariable) => {
      const currentTemplateVariable = TemplateVariableMap[templateVariable]
      if (currentTemplateVariable) {
        variableObject[currentTemplateVariable.name] = currentTemplateVariable
      } else {
        variableObject[templateVariable] = {
          name: templateVariable,
          // uppercase the template variable and replace underscore w/ spaces
          friendlyName: (
            templateVariable.charAt(0).toUpperCase() + templateVariable.slice(1)
          ).replace('_', ' '),
          inputType: 'textInput',
          prefill: null,
        }
      }
    })

  return variableObject
}

export type TemplateVariableInfo = {
  name: string
  friendlyName: string
  inputType: 'textInput' | 'textArea'
  prefill: PrefillKeys | null
  exclude?: boolean
}

/* TODO (matthewalbrecht): group similar keywords together */
/* freindlyName is for the input labels */
/* name (and object key) is for the matching extracted template variables */
export const TemplateVariableMap: Record<string, TemplateVariableInfo> = {
  company: {
    name: 'company',
    friendlyName: 'Company',
    inputType: 'textInput',
    prefill: null,
  },
  hiringmanager: {
    name: 'hiringmanager',
    friendlyName: 'Hiring Manager',
    inputType: 'textInput',
    prefill: null,
    exclude: true,
  },
  hiring_manager: {
    name: 'hiring_manager',
    friendlyName: 'Hiring Manager',
    inputType: 'textInput',
    prefill: null,
    exclude: true,
  },
  name: {
    name: 'name',
    friendlyName: 'Name',
    inputType: 'textInput',
    prefill: null,
    exclude: true,
  },
  position: {
    name: 'position',
    friendlyName: 'Position',
    inputType: 'textInput',
    prefill: null,
  },
  role: {
    name: 'role',
    friendlyName: 'Role',
    inputType: 'textInput',
    prefill: null,
  },
  tagline: {
    name: 'tagline',
    friendlyName: 'Tagline',
    inputType: 'textArea',
    prefill: null,
  },
  title: {
    name: 'title',
    friendlyName: 'Title',
    inputType: 'textInput',
    prefill: null,
  },
}

/* TODO (matthewalbrecht): use fragment */
export function fillTemplate<F extends TemplateMap>(
  template: string,
  formState: F
): string {
  let newCopy = template
  Object.entries(formState).forEach(([templateVariable, value]) => {
    // replace all instances of template variable w/ value of input
    newCopy = value
      ? newCopy.replace(getTemplateVariableRegex(templateVariable), value)
      : newCopy
  })
  return newCopy
}
