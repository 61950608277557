import { useQuery, gql } from '@apollo/client'
import { Dayjs } from 'dayjs'
import React, { useMemo } from 'react'
import { useAuthContext } from 'context/auth'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { columns } from './WriterTaglineStats.constants'
import { buildTableData } from './WriterTaglineStats.helpers'
import WriterTaglineStatsRow from './WriterTaglineStatsRow'
import { GetWritersTaglineSubmissionsQuery } from 'generated/graphql'

type Props = { fromDate: Dayjs | null }

export default function WriterTaglineStatsTable({
  fromDate,
}: Props): React.ReactElement {
  const {
    userSession: {
      user: { id: createdById },
    },
  } = useAuthContext()
  const { data, loading, error } = useQuery<GetWritersTaglineSubmissionsQuery>(
    GET_TAGLINE_SUBMISSIONS,
    { variables: { createdById } }
  )
  const rowData = useMemo(
    () =>
      data?.taglineSubmissions && buildTableData(data.taglineSubmissions, fromDate),
    [data, fromDate]
  )
  return (
    <FullWidthTable
      headers={Object.values(columns)}
      isEmpty={!loading && !data?.taglineSubmissions?.edges.length}
      isLoading={loading}
      isError={Boolean(error)}
      errorMessage="There was an error fetching your tagline stats."
      loadingMessage="Loading tagline stats..."
      emptyMessage="No tagline stats found."
    >
      {rowData && <WriterTaglineStatsRow rowData={rowData} />}
    </FullWidthTable>
  )
}

export const GET_TAGLINE_SUBMISSIONS = gql`
  query GetWritersTaglineSubmissions($createdById: ID!) {
    taglineSubmissions: taglineSubmissionsConn(createdById: $createdById) {
      edges {
        node {
          id
          createdAt
          timeSpentSec
          talentAgentReviews {
            id
            rating
          }
          talentPartnerReviews {
            id
            ratingTalentAgent
            ratingWriter
            taglineRejectionReason
          }
        }
      }
    }
  }
`
