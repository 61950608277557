import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import Editor from 'components/Editor/Editor'
import Icon from 'components/Icon/Icon'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import ExternalLink from 'components/ExternalLink'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  GetJobDescriptionQuery,
  GetJobDescriptionQueryVariables,
} from 'generated/graphql'

type ButtonProps = {
  sourcedJobId: string
  label?: string
}

export function JobDescriptionDialogButton({
  sourcedJobId,
  label,
}: ButtonProps): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)

  return (
    <div>
      <Txt color="link" as="button" onClick={() => setIsOpen(true)} underline>
        {label || 'View Job Description'}
      </Txt>
      {isOpen && (
        <JobDescriptionDialog
          sourcedJobId={sourcedJobId}
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
        />
      )}
    </div>
  )
}

type DialogProps = {
  isOpen: boolean
  onClose(): void
  sourcedJobId: string
}

function JobDescriptionDialog({ onClose, isOpen, sourcedJobId }: DialogProps) {
  const { data, loading, error } = useQuery<
    GetJobDescriptionQuery,
    GetJobDescriptionQueryVariables
  >(GET_JOB_DESCRIPTION, {
    variables: {
      id: sourcedJobId,
    },
  })

  const isError = error || !data?.sourcedJob

  return (
    <Dialog isOpen={isOpen} onDismiss={onClose} aria-label="Job description details">
      <Padding vertical={4} horizontal={4}>
        <DialogExitButton onClick={onClose} />
        {loading && <Message message="Loading job description" vertical />}
        {isError && (
          <Message
            message="There was an error loading the job description"
            vertical
          />
        )}
        {data?.sourcedJob && (
          <VList size={3}>
            <Txt size={18} hoverColor="black">
              <ExternalLink url={data.sourcedJob.url} inheritStyles>
                Job Description
              </ExternalLink>
              <Padding inline left={1}>
                <Icon
                  name="ExternalLink"
                  height={14}
                  width={14}
                  primaryFill="text"
                />
              </Padding>
            </Txt>
            <Editor defaultValue={data.sourcedJob.jobDescription} disabled />
          </VList>
        )}
      </Padding>
    </Dialog>
  )
}

const GET_JOB_DESCRIPTION = gql`
  query GetJobDescription($id: ID!) {
    sourcedJob(id: $id) {
      id
      jobDescription
      url
    }
  }
`
