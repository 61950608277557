import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import {
  generatePath,
  Prompt,
  Redirect,
  useHistory,
  useParams,
} from 'react-router-dom'
import { toast } from 'react-toastify'
import { KeyValue, TemplateMap } from 'types'
import useWriterQueue from 'hooks/useWriterQueue'
import { GLOBAL_COPY, INITIAL } from 'utils/constants'
import { getRandomNumFromUUID } from 'utils/helpers/uuid'
import ROUTE_PATHS from 'utils/routePaths'
import {
  SHOW_TEMPLATE_VARIABLES,
  TEMPLATE_VARIABLES_TEST_RATE,
} from 'utils/settings'
import WriterSubmissionFooter from 'views/WriterSubmission/WriterSubmissionFooter'
import WriterSubmissionLeft from 'views/WriterSubmission/WriterSubmissionLeft'
import WriterSubmissionRight from 'views/WriterSubmission/WriterSubmissionRight'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import TimeTracker from 'components/TimeTracker/TimeTracker'
import { Params } from './WriterSubmission.types'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import {
  TaglineRequestWriterSubmissionQuery,
  TaglineRequestWriterSubmissionQueryVariables,
} from 'generated/graphql'

export default function WriterSubmission(): JSX.Element {
  const history = useHistory()
  const { requestId, freeAgentId, writerId } = useParams<Params>()
  const redirectToInitial = requestId === INITIAL
  const { queueByViewer, queueByClient, loading } = useWriterQueue()
  const [editorChanges, setEditorChanges] = useState<KeyValue>({})
  const shouldBlockNavigation = !!Object.keys(editorChanges).length
  const [timeSpentSec, setTimeSpentSec] = useState(0)
  const { data: taglineRequestData } = useQuery<
    TaglineRequestWriterSubmissionQuery,
    TaglineRequestWriterSubmissionQueryVariables
  >(GET_TAGLINE_REQUEST_BY_ID, {
    variables: {
      id: requestId,
    },
    skip: redirectToInitial,
  })
  const taglineRequest = taglineRequestData?.taglineRequest
  const [templateMap, setTemplateMap] = useState<TemplateMap | null>(null)

  // we want isTemplateFilled to recalculate everytime the we update the route
  const [areTemplatesFilled, setAreTemplatesFilled] = useState(false)
  const isTemplateFillingTest =
    SHOW_TEMPLATE_VARIABLES &&
    getRandomNumFromUUID(requestId) < TEMPLATE_VARIABLES_TEST_RATE

  const queue = useMemo(
    () => (freeAgentId ? queueByClient[freeAgentId] || [] : queueByViewer),
    [freeAgentId, queueByClient, queueByViewer]
  )

  const leftContent = useMemo(
    () => taglineRequest && <WriterSubmissionLeft taglineRequest={taglineRequest} />,
    [taglineRequest]
  )

  const rightContent = useMemo(
    () =>
      taglineRequest && (
        <WriterSubmissionRight
          taglineRequest={taglineRequest}
          setEditorChanges={setEditorChanges}
          isTemplateFillingTest={isTemplateFillingTest}
          templateMap={templateMap}
          setTemplateMap={setTemplateMap}
          setAreTemplatesFilled={setAreTemplatesFilled}
        />
      ),
    [
      taglineRequest,
      setEditorChanges,
      isTemplateFillingTest,
      templateMap,
      setTemplateMap,
    ]
  )

  useEffect(() => {
    if (!loading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, queue, loading])

  if (redirectToInitial && queue[0]) {
    const queueType = writerId ? 'writer' : 'free-agent'
    const typeId = writerId ? writerId : freeAgentId

    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.WRITER_ASSIGNMENT_QUEUES, {
          requestId: queue[0],
          typeId: typeId,
          type: queueType,
        })}
      />
    )
  }

  if (!taglineRequest || !queue.length) {
    return <Message vertical message="Loading tagline request information..." />
  }

  return (
    <DualScrollContainer key={requestId}>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsubmitted work, are you sure you want to leave?"
      />
      <Container noMax>
        <Padding top={2} bottom={2}>
          <Flex justify="flex-end" align="center">
            <Flex align="center">
              <Padding left={3}>
                <TimeTracker onValueChange={setTimeSpentSec} />
              </Padding>
            </Flex>
          </Flex>
        </Padding>
      </Container>
      <DualScroll leftContent={leftContent} rightContent={rightContent} />
      <WriterSubmissionFooter
        editorChanges={editorChanges}
        setEditorChanges={setEditorChanges}
        taglineRequest={taglineRequest}
        timeSpentSec={timeSpentSec}
        queue={queue}
        requestId={requestId}
        freeAgentId={freeAgentId}
        writerId={writerId}
        templateMap={templateMap}
        setTemplateMap={setTemplateMap}
        areTemplatesFilled={areTemplatesFilled}
        isTemplateFillingTest={isTemplateFillingTest}
      />
    </DualScrollContainer>
  )
}

WriterSubmission.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.WRITER_QUEUE} key={ROUTE_PATHS.WRITER_QUEUE}>
    <WriterSubmission />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.WRITER_QUEUE_BY_FREE_AGENT}
    key={ROUTE_PATHS.WRITER_QUEUE_BY_FREE_AGENT}
  >
    <WriterSubmission />
  </PrivateRoute>,
]

const GET_TAGLINE_REQUEST_BY_ID = gql`
  query TaglineRequestWriterSubmission($id: ID!) {
    taglineRequest(id: $id) {
      id
      client {
        id
      }
      ...WriterSubmissionLeftInfo
      ...WriterSubmissionRightInfo
      ...WriterSubmissionFooterInfo
    }
  }
  ${WriterSubmissionLeft.fragments.taglineRequestInfo}
  ${WriterSubmissionRight.fragments.taglineRequestInfo}
  ${WriterSubmissionFooter.fragments.taglineRequestInfo}
`
