import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath, Redirect, useParams } from 'react-router'
import useFeedbackQueue from 'hooks/useFeedbackQueue'
import { INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import ReviewFeedbackFooter from 'views/ReviewFeedback/ReviewFeedbackFooter'
import ReviewFeedbackLeft from 'views/ReviewFeedback/ReviewFeedbackLeft'
import ReviewFeedbackRight from 'views/ReviewFeedback/ReviewFeedbackRight'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import { Params } from './ReviewFeedback.types'
import Message from 'components/Message'
import PrivateRoute from 'components/PrivateRoute'
import {
  TaglineSubmissionReviewFeedbackQuery,
  TaglineSubmissionReviewFeedbackQueryVariables,
} from 'generated/graphql'

export default function ReviewFeedback(): JSX.Element {
  const { submissionId, writerId } = useParams<Params>()
  const redirectToInitial = submissionId === INITIAL
  const isReadOnly = !writerId
  const { queueByViewer: queue } = useFeedbackQueue({ skip: isReadOnly })
  const { data: taglineSubmissionData } = useQuery<
    TaglineSubmissionReviewFeedbackQuery,
    TaglineSubmissionReviewFeedbackQueryVariables
  >(GET_TAGLINE_SUBMISSION_BY_ID, {
    variables: {
      id: submissionId,
    },
    // skip querying the submission if we are on the placeholder "/initial" route
    skip: redirectToInitial,
  })
  const taglineSubmission = taglineSubmissionData?.taglineSubmission

  if (redirectToInitial && queue[0]) {
    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.REVIEW_FEEDBACK_QUEUE, {
          submissionId: queue[0],
          writerId,
        })}
      />
    )
  }

  if (!taglineSubmission || (!queue.length && !isReadOnly)) {
    return <Message vertical message="Loading tagline submission information..." />
  }

  return (
    <DualScrollContainer key={submissionId}>
      <DualScroll
        leftContent={<ReviewFeedbackLeft taglineSubmission={taglineSubmission} />}
        rightContent={<ReviewFeedbackRight taglineSubmission={taglineSubmission} />}
      />
      <ReviewFeedbackFooter
        queue={queue}
        isReadOnly={isReadOnly}
        submissionId={submissionId}
        writerId={writerId}
      />
    </DualScrollContainer>
  )
}

ReviewFeedback.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REVIEW_FEEDBACK_QUEUE}
    key={ROUTE_PATHS.REVIEW_FEEDBACK_QUEUE}
  >
    <ReviewFeedback />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REVIEW_FEEDBACK}
    key={ROUTE_PATHS.REVIEW_FEEDBACK}
  >
    <ReviewFeedback />
  </PrivateRoute>,
]

const GET_TAGLINE_SUBMISSION_BY_ID = gql`
  query TaglineSubmissionReviewFeedback($id: ID!) {
    taglineSubmission(id: $id) {
      id
      ...ReviewFeedbackRightInfo
      taglineRequest {
        id
        ...ReviewFeedbackLeftInfo
      }
    }
  }
  ${ReviewFeedbackLeft.fragments.taglineRequestInfo}
  ${ReviewFeedbackRight.fragments.taglineSubmissionInfo}
`
