import { ApolloError } from '@apollo/client'
import React, { useState } from 'react'
import { generatePath, Link } from 'react-router-dom'
import { GLOBAL_COPY } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import {
  ColumnSortState,
  ColumnSortType,
} from 'components/FullWidthTable/FullWidthTable.types'
import {
  BodyData,
  BodyRow,
  CheckboxLabel,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { columns } from './TalentAgentStats.constants'
import { TaStats, ClientsUnselectedMap } from './TalentAgentStats.types'
import Txt from 'components/Txt'

type Props = {
  tableData: TaStats[]
  loading: boolean
  error: ApolloError | undefined
  clientsUnselectedMap: ClientsUnselectedMap
  setClientsUnselectedMap: React.Dispatch<React.SetStateAction<ClientsUnselectedMap>>
}

export default function TalentAgentStatsTable({
  tableData,
  loading,
  error,
  clientsUnselectedMap,
  setClientsUnselectedMap,
}: Props): React.ReactElement {
  const [columnSortState, setColumnSortState] = useState<ColumnSortState>({
    orderBy: 'freeAgent',
    direction: ColumnSortType.ASC,
  })
  const sortedTableData = tableData.sort((a, b) =>
    sortByProp(
      a,
      b,
      `${columnSortState.direction === ColumnSortType.ASC ? '' : '-'}${
        columnSortState.orderBy
      }`
    )
  )

  function handleClientCheck(event: React.FormEvent<HTMLInputElement>) {
    const clientId = event.currentTarget.value
    if (!clientsUnselectedMap[clientId]) {
      setClientsUnselectedMap({
        ...clientsUnselectedMap,
        [clientId]: true,
      })
    } else {
      setClientsUnselectedMap({
        ...clientsUnselectedMap,
        [clientId]: !clientsUnselectedMap[clientId],
      })
    }
  }

  function handleGroupCheck() {
    const checked = isGroupChecked()
    const groupUnchecked: ClientsUnselectedMap = {}
    if (checked) {
      tableData.forEach((client) => {
        groupUnchecked[client.id] = true
      })
      setClientsUnselectedMap(groupUnchecked)
    } else {
      setClientsUnselectedMap({})
    }
  }

  function isGroupChecked(): boolean {
    for (const clientId of Object.keys(clientsUnselectedMap)) {
      if (clientsUnselectedMap[clientId]) {
        return false
      }
    }
    return true
  }

  function handleColumnSortChange(columnId: string, value: ColumnSortState) {
    if (value.direction === ColumnSortType.ASC || value.orderBy !== columnId) {
      setColumnSortState({ orderBy: columnId, direction: ColumnSortType.DESC })
    } else {
      setColumnSortState({ orderBy: columnId, direction: ColumnSortType.ASC })
    }
  }

  return (
    <FullWidthTable
      headers={Object.values(columns)}
      isEmpty={false}
      emptyMessage={'Could not find any clients'}
      isLoading={loading}
      loadingMessage={'Loading TA client stats...'}
      isError={Boolean(error)}
      errorMessage={'There was an error loading TA client stats.'}
      columnSortState={columnSortState}
      onColumnSortChange={handleColumnSortChange}
    >
      <BodyRow color="blueHighlight" role="rowgroup" aria-label="highlighted row">
        <BodyData collapse>
          <CheckboxLabel>
            <input
              type="checkbox"
              onChange={handleGroupCheck}
              checked={isGroupChecked()}
              aria-label="highlighted row checkbox"
            />
          </CheckboxLabel>
        </BodyData>
        <BodyData colSpan={100}>
          <Txt size={14} bold>
            Select/Deselect All
          </Txt>
        </BodyData>
      </BodyRow>
      {sortedTableData.map((client) => (
        <BodyRow key={client.id}>
          <BodyData collapse>
            <CheckboxLabel htmlFor={client.id}>
              <input
                id={client.id}
                type="checkbox"
                value={client.id}
                onChange={handleClientCheck}
                checked={!clientsUnselectedMap[client.id]}
                aria-label="TA client checkbox"
              />
            </CheckboxLabel>
          </BodyData>
          <BodyData>
            <Txt
              as={Link}
              to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
                clientId: client.id,
              })}
              size={14}
              underline
              hoverColor="black"
            >
              {client.freeAgent}
            </Txt>
          </BodyData>
          <BodyData>
            <Txt size={14} as="span">
              {client.signDate ? client.signDate.fromNow() : GLOBAL_COPY.EN_DASH}
            </Txt>
          </BodyData>
          <BodyData>
            <Txt size={14} as="span">
              {client.appsSubmitted}
            </Txt>
          </BodyData>
          <BodyData>
            <Txt size={14} as="span">
              {client.interviewCyclesActive}
            </Txt>
          </BodyData>
          <BodyData>
            <Txt size={14} as="span">
              {client.sourcedJobsAvgRating}
            </Txt>
          </BodyData>
        </BodyRow>
      ))}
    </FullWidthTable>
  )
}
