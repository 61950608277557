import dayjs from 'dayjs'
import { Rating } from 'types'
import { DATE_FORMAT } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { getAvgRating } from 'utils/helpers/stats'
import { sortByProp } from 'utils/sort'
import { DATE_OPTION_TO_COUNT } from './TalentAgentStats.constants'
import {
  Clients,
  TaStats,
  ChartData,
  DatesAndCountMap,
  GroupedDatesMap,
  GroupedDatesAvgRating,
  DateRangeInfo,
  ClientsUnselectedMap,
  DateFilterPeriod,
  StatType,
  SourcedJobs,
  GroupedSourcedJobs,
  ClientIdToAvgRatingMap,
  TimePeriod,
} from './TalentAgentStats.types'

export function getDaysFromToday(days: number): {
  start: string
  end: string
} {
  const today = dayjs()
  const startOfDaysFromToday = today.subtract(days, 'days')

  return {
    start: startOfDaysFromToday.format(),
    end: today.format(),
  }
}

function createDatesAndCountMap(days: number) {
  const datesAndCountMap: DatesAndCountMap = {}
  const today = dayjs()
  for (let i = days; i >= 0; i--) {
    const startOfDaysFromToday = today
      .subtract(i, 'days')
      .format(DATE_FORMAT.FRIENDLY_DATE_NO_YEAR)
    datesAndCountMap[startOfDaysFromToday] = 0
  }
  return datesAndCountMap
}

function getDateRangeInfo(daysPerGroup: number, groupNumber: string): DateRangeInfo {
  const today = dayjs()
  const date = today.subtract(daysPerGroup * (parseInt(groupNumber) - 1), 'days')
  const unix = date.unix()
  return {
    label: `${date
      .subtract(daysPerGroup - 1, 'days')
      .format(DATE_FORMAT.FRIENDLY_DATE_NO_YEAR)}-${date.format(
      DATE_FORMAT.FRIENDLY_DATE_NO_YEAR
    )}`,
    unix,
  }
}

export function buildChartData(
  clients: Clients[],
  clientsUnselectedMap: ClientsUnselectedMap,
  taStatOption: StatType,
  dateFilteringOption: DateFilterPeriod,
  sourcedJobs: SourcedJobs[]
): ChartData[] {
  const chartData: ChartData[] = []
  const datesAndCountMap = createDatesAndCountMap(
    DATE_OPTION_TO_COUNT[dateFilteringOption]
  )
  if (taStatOption === StatType.SOURCED_JOBS_AVERAGE_RATINGS) {
    const groupedSourcedJobsByTime = groupSourcedJobsByTime(
      clients,
      sourcedJobs,
      TimePeriod.WEEK,
      clientsUnselectedMap
    )
    for (const [dateRange, { avgRatings, unix }] of Object.entries(
      groupedSourcedJobsByTime
    )) {
      chartData.push({
        date: dateRange,
        count: avgRatings,
        unix,
      })
    }
    return chartData.sort((a, b) => sortByProp(a, b, 'unix'))
  }
  clients
    .filter((client) => !clientsUnselectedMap[client.id])
    .forEach((client) => {
      if (taStatOption === StatType.APPS_SUBMITTED) {
        const applications = getNodes(client.applications)
        applications.forEach((application) => {
          const formattedDate = dayjs(
            // application submission will always exist because of hasApplicationSubmissionSubmission set to true in the query
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            application.applicationSubmissions[0]!.createdAt
          ).format(DATE_FORMAT.FRIENDLY_DATE_NO_YEAR)
          datesAndCountMap[formattedDate] += 1
        })
      }
      if (taStatOption === StatType.INTERVIEW_CYCLES_STARTED) {
        const interviewCycles = getNodes(client.interviewCycles)
        interviewCycles.forEach((interviewCycle) => {
          const formattedDate = dayjs(interviewCycle.createdAt).format(
            DATE_FORMAT.FRIENDLY_DATE_NO_YEAR
          )
          datesAndCountMap[formattedDate] += 1
        })
      }
    })
  for (const date in datesAndCountMap) {
    chartData.push({
      date: date,
      count: datesAndCountMap[date] ?? 0,
      unix: dayjs(date).unix(),
    })
  }

  return chartData.sort((a, b) => sortByProp(a, b, 'unix'))
}

export function buildTableData(
  clients: Clients[],
  sourcedJob: SourcedJobs[]
): TaStats[] {
  const clientIdToAvgRatingMap = createClientIdToAvgRatingMap(sourcedJob)
  return clients
    .map((client) => {
      const applications = getNodes(client.applications)
      return {
        id: client.id,
        freeAgent: client.name,
        appsSubmitted: applications.length,
        signDate: client.signDateInitial ? dayjs(client.signDateInitial) : undefined,
        interviewCyclesActive: client.interviewCyclesActive.totalCount,
        sourcedJobsAvgRating: clientIdToAvgRatingMap[client.id] ?? 0,
      }
    })
    .sort((a, b) => sortByProp(a, b, 'name'))
}

export function getDateFilter(dateOption: DateFilterPeriod): {
  start: string
  end: string
} {
  switch (dateOption) {
    case DateFilterPeriod.LAST_7_DAYS:
      return getDaysFromToday(7)
    case DateFilterPeriod.LAST_30_DAYS:
      return getDaysFromToday(30)
    case DateFilterPeriod.LAST_90_DAYS:
      return getDaysFromToday(90)
    default:
      return getDaysFromToday(30)
  }
}
// create a map of client id to average of sourced jobs ratings e.g.
// {
//  [clientId]: 3.5
// }
export function createClientIdToAvgRatingMap(
  sourcedJobs: SourcedJobs[]
): ClientIdToAvgRatingMap {
  const grouped = sourcedJobs.reduce((groups: GroupedSourcedJobs, sourcedJob) => {
    const { id: clientId } = sourcedJob.client
    const group = groups[clientId]

    if (!sourcedJob.reviews[0]?.rating) {
      return groups
    }

    if (group) {
      group.push(sourcedJob.reviews[0].rating as Rating)
    } else {
      groups[clientId] = [sourcedJob.reviews[0].rating as Rating]
    }
    return groups
  }, {})

  const clientIdToAvgRatingMap: ClientIdToAvgRatingMap = {}

  for (const [clientId, ratings] of Object.entries(grouped)) {
    const [avgRating] = getAvgRating(ratings)
    clientIdToAvgRatingMap[clientId] = Math.round(avgRating * 10) / 10
  }
  return clientIdToAvgRatingMap
}

// create a group of date ranges with average ratings and unix formatted time e.g.
// {
//  Oct 25-Oct 31: {
//    avgRatings: 2.2
//    unix: 1667409475
//  },
//  Nov 01-Nov 07: {
//    avgRatings: 3.5
//    unix: 1668017875
//  }
// }
export function groupSourcedJobsByTime(
  clients: Clients[],
  sourcedJobs: SourcedJobs[],
  daysPerGroup: number,
  clientsUnselectedMap: ClientsUnselectedMap
): GroupedDatesAvgRating {
  const groupedDatesMap: GroupedDatesMap = {}
  sourcedJobs.forEach((sourcedJob) => {
    const isTalentAgentsClient = clients.some(
      (client) => client.id === sourcedJob.client.id
    )
    if (
      clientsUnselectedMap[sourcedJob.client.id] ||
      !isTalentAgentsClient ||
      !sourcedJob.reviews[0]
    ) {
      return
    }
    const today = dayjs()
    const createdAt = dayjs(sourcedJob.createdAt)
    const groupNumber = Math.ceil(today.diff(createdAt, 'day') / daysPerGroup)
    const groupedDate = groupedDatesMap[groupNumber]
    const rating = sourcedJob.reviews[0].rating as Rating
    if (groupedDate) {
      groupedDate.push(rating)
    } else {
      groupedDatesMap[groupNumber] = [rating]
    }
  })

  const groupedWithAvgRating: GroupedDatesAvgRating = {}

  for (const [groupNumber, ratings] of Object.entries(groupedDatesMap)) {
    const [avgRating] = getAvgRating(ratings)
    const { label, unix } = getDateRangeInfo(daysPerGroup, groupNumber)
    groupedWithAvgRating[label] = {
      avgRatings: Math.round(avgRating * 10) / 10,
      unix: unix,
    }
  }
  return groupedWithAvgRating
}
