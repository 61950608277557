import React from 'react'
import {
  avgRatingCopy,
  avgTimeSpentSecCopy,
  oneAndTwosRateCopy,
  rateAsPctCopy,
} from 'utils/helpers/stats'
import { SHOW_TALENT_AGENT_REVIEW } from 'utils/settings'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { TableRowData } from './WriterTaglineStats.types'
import Txt from 'components/Txt'

type Props = {
  rowData: TableRowData
}

export default function WriterTaglineStatsRow({
  rowData: {
    total,
    avgTimeSpentSec,
    avgTimeSpentSecTotal,
    avgTalentAgentRating,
    avgTalentAgentRatingTotal,
    talentAgentOnesAndTwosRate,
    avgTalentPartnerRating,
    avgTalentPartnerRatingTotal,
    talentPartnerOnesAndTwosRate,
    rejectionRate,
  },
}: Props): React.ReactElement {
  return (
    <BodyRow aria-label="statistic row">
      {SHOW_TALENT_AGENT_REVIEW && (
        <>
          <BodyData>
            <Txt as="span" size={14}>
              {avgRatingCopy(avgTalentAgentRating, avgTalentAgentRatingTotal)}
            </Txt>
          </BodyData>
          <BodyData>
            <Txt as="span" size={14}>
              {oneAndTwosRateCopy(
                talentAgentOnesAndTwosRate,
                avgTalentAgentRatingTotal
              )}
            </Txt>
          </BodyData>
        </>
      )}

      <BodyData>
        <Txt as="span" size={14}>
          {avgRatingCopy(avgTalentPartnerRating, avgTalentPartnerRatingTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {oneAndTwosRateCopy(
            talentPartnerOnesAndTwosRate,
            avgTalentPartnerRatingTotal
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {avgTimeSpentSecCopy(avgTimeSpentSec, avgTimeSpentSecTotal)}
        </Txt>
      </BodyData>
      <BodyData aria-label="rejection rate">
        <Txt as="span" size={14}>
          {rateAsPctCopy(rejectionRate, total)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
