import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useMemo } from 'react'
import { useAuthContext } from 'context/auth'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import TableBody from 'components/Table/TableBody'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { GroupedDueTaglineRequests } from './WriterTable.types'
import {
  WritersPendingTaglineRequestsQuery,
  WritersPendingTaglineRequestsQueryVariables,
} from 'generated/graphql'

const COL_HEADERS = ['Due Date', 'Taglines Due']

export default function WriterDueDateTable(): JSX.Element {
  const { userSession } = useAuthContext()
  // TODO: consider using the same source of data for this and CurrentAssignmentsByDueDate
  const { data } = useQuery<
    WritersPendingTaglineRequestsQuery,
    WritersPendingTaglineRequestsQueryVariables
  >(GET_WRITERS_TAGLINE_REQUESTS, {
    variables: { assigneeId: userSession.user.id },
  })

  const taglineRequests = data?.taglineRequests && getNodes(data.taglineRequests)

  const groupedTaglineRequests = useMemo(
    () =>
      taglineRequests
        ?.map((request) => ({
          ...request,
          dateToSortBy: dayjs(request.due),
        }))
        ?.reduce((groups: GroupedDueTaglineRequests, request) => {
          const groupValue = request.due
          const group = groups[groupValue]
          if (group) {
            group.requests.push(request)
          } else {
            groups[groupValue] = {
              date: groupValue,
              requests: [request],
            }
          }
          return groups
        }, {}) || {},
    [taglineRequests]
  )

  const sortedTaglineRequests = useMemo(() => {
    return Object.values(groupedTaglineRequests).sort((a, b) =>
      sortByProp(a.requests[0], b.requests[0], 'dateToSortBy')
    )
  }, [groupedTaglineRequests])

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {sortedTaglineRequests.map(({ date, requests }) => (
          <tr key={date}>
            <TableBodyItem first content={date} />
            <TableBodyItem content={requests.length} />
          </tr>
        ))}
      </TableBody>
      {!sortedTaglineRequests.length && (
        <TableEmpty message="No Assignments Found" />
      )}
    </table>
  )
}

/**
 * used by writers to get all of their taglineRequests
 */
const GET_WRITERS_TAGLINE_REQUESTS = gql`
  query WritersPendingTaglineRequests($assigneeId: ID) {
    taglineRequests(assigneeId: $assigneeId, status: PENDING_WRITING) {
      edges {
        node {
          id
          due
        }
      }
    }
  }
`
