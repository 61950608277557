import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useAuthContext } from 'context/auth'
import TableBody from 'components/Table/TableBody'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS_BY_DUE } from './ApplierTable.constants'
import { buildRowDataByDueDate } from './ApplierTable.helpers'
import {
  ApplicationStatus,
  GetViewerApplicationsDueDatesQuery,
} from 'generated/graphql'

export default function ApplierDueDateTable(): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const { data } = useQuery<GetViewerApplicationsDueDatesQuery>(
    GET_VIEWER_APPLICATIONS,
    {
      variables: {
        assigneeId,
        status: ApplicationStatus.PENDING_APPLICATION,
      },
    }
  )
  const rowData =
    data && data.applications && buildRowDataByDueDate(data.applications)

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS_BY_DUE.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData?.map(({ due, applications }) => (
          <tr key={due}>
            <TableBodyItem first content={due} />
            <TableBodyItem content={applications.length} />
          </tr>
        ))}
      </TableBody>
      {rowData && !rowData.length && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}

/**
 * used by appliers to get all of their applications
 */
const GET_VIEWER_APPLICATIONS = gql`
  query getViewerApplicationsDueDates(
    $assigneeId: ID!
    $status: ApplicationStatus!
  ) {
    applications(assigneeId: $assigneeId, status: $status) {
      edges {
        node {
          id
          due
        }
      }
    }
  }
`
