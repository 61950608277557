import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import PublicRoute from 'components/PublicRoute'
import Txt from 'components/Txt'

export default function CloseMessage(): React.ReactElement {
  return (
    <Padding top={6}>
      <CenteredBox>
        <Txt alignment="center">You may now close this tab.</Txt>
      </CenteredBox>
    </Padding>
  )
}

CloseMessage.Routes = [
  <PublicRoute path={ROUTE_PATHS.CLOSE_MESSAGE} key={ROUTE_PATHS.CLOSE_MESSAGE}>
    <CloseMessage />
  </PublicRoute>,
]
