import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { DATE_FORMAT } from 'utils/constants'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { DossierRequest } from './DossierSubmission.types'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  dossierRequest: DossierRequest | null | undefined
}

function DossierSubmissionLeft({ dossierRequest }: Props): React.ReactElement {
  const dueLabel = `Due: ${dayjs(dossierRequest?.due).format(
    DATE_FORMAT.DATE_AND_TIME
  )}`

  // This is used by the skeleton wrappers as whether to show the loading SVG
  // or to show the component, if dossierRequest is undefined then we haven't
  // recieved a response yet
  const dossierRequestExists = dossierRequest !== undefined

  return (
    <VList size={4}>
      <Flex justify="space-between" align="center">
        <RectangleSkeletonWrap
          height={32}
          width={180}
          showContent={dossierRequestExists}
        >
          <Txt as="h2" size={24} bold>
            {dossierRequest?.client.name}
          </Txt>
        </RectangleSkeletonWrap>
        <RectangleSkeletonWrap
          height={18}
          width={140}
          showContent={dossierRequestExists}
        >
          <Txt>{dueLabel}</Txt>
        </RectangleSkeletonWrap>
      </Flex>
      <VList size={1.5}>
        <InlineValueProp
          value={dossierRequest?.client.talentAgent.name}
          label="Talent Agent"
        />
        <InlineValueProp
          value={dossierRequest?.client.roleExperience}
          label="Experience Level"
        />
        <InlineValueProp
          value={dossierRequest?.client.roleCategory}
          label="Role Category"
        />
        <InlineValueProp
          value={dossierRequest?.client.remotePrefs}
          label="Remote Preferences"
        />
        <InlineValueProp
          value={dossierRequest?.client.desiredSalary}
          label="Desired Salary"
        />
        <InlineValueProp
          value={dossierRequest?.client.desiredCompanySize}
          label="Desired Company Size"
        />
        <InlineValueProp
          value={dossierRequest?.client.desiredIndustries}
          label="Desired Industries"
        />
      </VList>
      <RectangleSkeletonWrap
        height={450}
        width="100%"
        withViewBox={false}
        showContent={dossierRequestExists}
      >
        {dossierRequest?.client.resumeUrl && (
          <PDFViewer url={dossierRequest.client.resumeUrl} />
        )}
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        width={180}
        height={16}
        showContent={dossierRequestExists}
      >
        <WorkInfoLinks
          questionnaireUrl={dossierRequest?.client.questionnaireUrl}
          resumeUrl={dossierRequest?.client.resumeUrl}
          linkedinUrl={dossierRequest?.client.linkedinUrl}
          huntrUrl={dossierRequest?.client.huntrUrl}
        />
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        fullWidth
        width="100%"
        height={80}
        withViewBox={false}
        showContent={dossierRequestExists}
      >
        <TextArea
          defaultValue={dossierRequest?.client.bio || ''}
          label={`${dossierRequest?.client.name ?? ''} Bio`}
          height={80}
          disabled
        />
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        fullWidth
        width="100%"
        height={120}
        withViewBox={false}
        showContent={dossierRequestExists}
      >
        <TextArea
          label="Location Preferences"
          defaultValue={dossierRequest?.client.locationPrefs || ''}
          height={80}
          disabled
        />
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        fullWidth
        width="100%"
        height={120}
        withViewBox={false}
        showContent={dossierRequestExists}
      >
        <TextArea
          label="Job Preferences"
          defaultValue={dossierRequest?.client.jobPrefs || ''}
          height={120}
          disabled
        />
      </RectangleSkeletonWrap>
    </VList>
  )
}

DossierSubmissionLeft.fragments = {
  DossierSubmissionLeftInfo: gql`
    fragment DossierSubmissionLeftInfo on DossierRequest {
      id
      due
      client {
        id
        name
        talentAgent {
          name
        }
        resumeUrl
        linkedinUrl
        locationPrefs
        jobPrefs
        roleExperience
        roleCategory
        bio
        questionnaireUrl
        remotePrefs
        huntrUrl
        desiredSalary
        desiredCompanySize
        desiredIndustries
      }
      interviewCycle {
        id
      }
    }
  `,
}

export default DossierSubmissionLeft
