import React from 'react'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import { DossierSubmissionGroup } from './ReviewDossierSubmission.types'
import Txt from 'components/Txt'

type Props = {
  rowData: DossierSubmissionGroup
}

export default function ReviewDossierSubmissionHighlightedRow({
  rowData,
}: Props): React.ReactElement {
  return (
    <BodyRow color="blueHighlight">
      <BodyData colSpan={100}>
        <Txt as="span" size={14} bold>
          {rowData.clientName}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
