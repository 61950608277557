import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { DropdownOption } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import groupAssignItems, {
  GenericGroupedRowData,
} from 'utils/helpers/groupAssigItems'
import { sortByProp } from 'utils/sort'
import {
  GetDossierRequestsForAssigningQuery,
  GetDossierWritersQuery,
} from 'generated/graphql'

type ReturnType = {
  groupedRowData: GenericGroupedRowData[]
  assigneeOptions: DropdownOption[]
}

export default function useAssignDossierRequestsData(
  isReassigning: boolean
): ReturnType {
  const { data: dossierRequestData } = useQuery<GetDossierRequestsForAssigningQuery>(
    GET_DOSSIER_REQUESTS_FOR_ASSIGNING,
    {
      variables: {
        isAssigned: isReassigning,
      },
    }
  )

  const { data: dossierWriterData } =
    useQuery<GetDossierWritersQuery>(GET_DOSSIER_WRITERS)

  const dossierRequests =
    dossierRequestData?.dossierRequests?.edges.map((edge) => ({
      ...edge.node,
      sortableDue: dayjs(edge.node.due),
      sortableCreatedAt: dayjs(edge.node.createdAt),
      clientName: edge.node.client.name,
      assigneeId: edge.node.assignee?.id,
      jobTitle: edge.node.interviewCycle.jobTitle,
      employerName: edge.node.interviewCycle.employerName,
    })) || []

  // this restructures the data to fit our nested table row structure
  // we show client or Dossier Writer and then all of their dossierRequests
  const groupedRowData = groupAssignItems(dossierRequests, isReassigning)

  // sort the requests by clientName-due
  groupedRowData.forEach((group) => {
    group.items.sort((a, b) => sortByProp(a, b, 'clientName', 'sortableDue'))
  })

  // sort groups by soonest due
  groupedRowData.sort((a, b) => sortByProp(a.items[0], b.items[0], 'sortableDue'))

  const assigneeOptions: DropdownOption[] = buildDropdownOptions(
    dossierWriterData?.users || []
  )
  return { groupedRowData, assigneeOptions }
}

/**
 * Use to query all dossierRequests
 */
const GET_DOSSIER_REQUESTS_FOR_ASSIGNING = gql`
  query GetDossierRequestsForAssigning($isAssigned: Boolean!) {
    dossierRequests(isAssigned: $isAssigned, isOpen: true) {
      edges {
        node {
          id
          priorDossierSubmission {
            id
            fileUrl
            createdBy {
              id
              name
            }
          }
          client {
            id
            name
            roleCategory
            roleExperience
          }
          interviewCycle {
            id
            employerName
            jobTitle
          }
          due
          assignee {
            id
            name
          }
          assignedAt
          createdAt
          dossierType
        }
      }
    }
  }
`

/**
 * used to get all dossierWriters
 */
const GET_DOSSIER_WRITERS = gql`
  query GetDossierWriters {
    users(anyRole: { isDossierWriter: true }) {
      id
      name
    }
  }
`
