import { TZ } from 'utils/constants'
import { SOURCING_REVIEW_MAX_DAYS } from 'utils/settings'

export const DEFAULT_TZ = TZ.MANILA
export const TOAST = {
  UPDATE_END: `End Date Updated: there is a ${SOURCING_REVIEW_MAX_DAYS} day maximum date range`,
  UPDATE_START: `Start Date Updated: there is a ${SOURCING_REVIEW_MAX_DAYS} day maximum date range`,
}

export const HEADERS = [
  {
    label: 'Sourcer',
    id: 'sourcer',
  },
  {
    label: 'Requested',
    id: 'requested',
  },
  {
    label: 'Submitted',
    id: 'submitted',
  },
  {
    label: 'Approved',
    id: 'approved',
  },
  {
    label: 'Rejected',
    id: 'rejected',
  },
]
