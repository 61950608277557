import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import DualScroll from 'components/DualScroll/DualScroll'
import { Company } from './CompanyMerge.types'
import CompanyMergeDetail from './CompanyMergeDetail'
import CompanyMergeSearch from './CompanyMergeSearch'
import LoadingIndicator from 'components/LoadingIndicator'
import Message from 'components/Message'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'
import {
  MergeCompanyPageQueryQuery,
  MergeCompanyPageQueryQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

export default function CompanyMerge({ company }: Props): React.ReactElement {
  return (
    <DualScroll
      leftContent={<CompanyMergeDetail company={company} />}
      rightContent={<CompanyMergeSearch company={company} />}
    />
  )
}

function CompanyMergeWrapper(): React.ReactElement {
  const { companyId } = useParams<{ companyId: string }>()
  const { data, loading, error } = useQuery<
    MergeCompanyPageQueryQuery,
    MergeCompanyPageQueryQueryVariables
  >(MERGE_COMPANY_PAGE_QUERY, {
    variables: {
      id: companyId,
    },
  })

  return (
    <ViewBox>
      {loading && <LoadingIndicator />}
      {error && (
        <Message
          vertical
          message="There was an error retrieving the company data."
        />
      )}
      {!error && !loading && data?.company && (
        <CompanyMerge company={data.company} />
      )}
    </ViewBox>
  )
}

const MERGE_COMPANY_PAGE_QUERY = gql`
  query MergeCompanyPageQuery($id: ID!) {
    company(id: $id) {
      id
      name
      domain
      logoUrl
    }
  }
`

CompanyMerge.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.COMPANY_MERGE}
    key={ROUTE_PATHS.COMPANY_MERGE}
  >
    <CompanyMergeWrapper />
  </PrivateRoute>,
]
