import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Maybe, Rating, ReactFormEvent } from 'types'
import { DATE_FORMAT, DOSSIER_TYPE_MAP, EMPTY_INPUT } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import useFormState from 'views/ClientTracker/useFormState'
import { RECORD_REVIEW } from 'views/ReviewDossierSubmissions/ReviewDossierSubmissionRow'
import Icon from 'components/Icon/Icon'
import StarRating from 'components/StarRating/StarRating'
import { COPY, DOSSIER_HEADERS } from './InterviewCycleDetail.constants'
import { getDossierStatus, renderHeaders } from './InterviewCycleDetail.helpers'
import {
  DossierRequest,
  DossierSubmission,
  InterviewCycle,
  Params,
} from './InterviewCycleDetail.types'
import { Grid } from './interviewCycleDetail.styles'
import Button from 'components/Button'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import { SectionHeader, Tag } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  ReviewDossierSubmissionMutation,
  ReviewDossierSubmissionMutationVariables,
} from 'generated/graphql'

type Props = {
  interviewCycle: Maybe<InterviewCycle>
  loading: boolean
}

export default function InterviewCycleDetailDossiers({
  interviewCycle,
  loading,
}: Props): React.ReactElement {
  const dossierRequests = interviewCycle
    ? getNodes<DossierRequest>(interviewCycle.dossierRequests)
        .map((request) => ({ ...request, dateToSortBy: dayjs(request.createdAt) }))
        .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))
    : []

  const hasDossierRequests = Boolean(dossierRequests.length)
  return (
    <div>
      <SectionHeader headerCopy="Dossier Requests" />
      {loading && <Txt alignment="center">Loading...</Txt>}
      {!hasDossierRequests && !loading && (
        <Txt alignment="center">No Dossier Requests Found.</Txt>
      )}
      <Grid
        gridTemplateColumns="min-content min-content min-content min-content min-content auto min-content min-content min-content"
        rowGapSize={2}
        colGapSize={1.5}
      >
        {hasDossierRequests && renderHeaders(DOSSIER_HEADERS)}
        {dossierRequests.map((dossierRequest, index) => (
          <Dossier
            dossierRequest={dossierRequest}
            key={dossierRequest.id}
            interviewNumber={dossierRequests.length - index}
          />
        ))}
      </Grid>
    </div>
  )
}

type DossierProps = {
  dossierRequest: DossierRequest
  interviewNumber: number
}

function Dossier({
  dossierRequest,
  interviewNumber,
}: DossierProps): React.ReactElement {
  const [status, statusColor] = getDossierStatus(dossierRequest)

  const dossierSubmissions = getNodes<DossierSubmission>(
    dossierRequest.dossierSubmissions
    // NOTE: if this filter is removed make sure to remove non-null assertions
  ).filter((submission) => submission.fileUrl)
  const submission = dossierSubmissions[0]

  return (
    <>
      <Txt size={13}>{interviewNumber}</Txt>
      <Txt size={13} noWrap>
        {dayjs(dossierRequest.createdAt).format(DATE_FORMAT.ONLY_DATE)}
      </Txt>
      <Txt size={13} noWrap>
        {dayjs(dossierRequest.due).format(DATE_FORMAT.DATE_AND_TIME)}
      </Txt>
      <Txt size={13} noWrap>
        {DOSSIER_TYPE_MAP[dossierRequest.dossierType]}
      </Txt>
      <Tag color={statusColor} small>
        <Txt size={13} noWrap alignment="center">
          {status}
        </Txt>
      </Tag>
      <div />
      {submission ? <FeedbackButton submission={submission} /> : <div />}
      <UpdateDossierLink submission={submission} />
      <DossierLink submission={submission} />
    </>
  )
}

type FeedbackButtonProps = {
  submission: DossierSubmission
}

function FeedbackButton({ submission }: FeedbackButtonProps): React.ReactElement {
  const { interviewCycleId, clientId, dossierSubmissionId } = useParams<Params>()
  const history = useHistory()
  function handleOpen() {
    history.push(
      generatePath(ROUTE_PATHS.CLIENT_CONFIG_DOSSIER_FEEDBACK, {
        clientId,
        interviewCycleId,
        dossierSubmissionId: submission.id,
      })
    )
  }

  function handleClose() {
    history.push(
      generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
        clientId,
        interviewCycleId,
      })
    )
  }
  return !submission.reviews[0] ? (
    <>
      <Txt
        as="button"
        size={13}
        noWrap
        hoverColor="black"
        underline
        onClick={handleOpen}
      >
        Give Feedback
      </Txt>

      <CollectFeedbackDialog
        isOpen={dossierSubmissionId === submission.id}
        handleClose={handleClose}
        submission={submission}
      />
    </>
  ) : (
    <StarRating
      iconSize={14}
      disabled
      rating={submission.reviews[0].rating as Rating}
    />
  )
}

type CollectFeedbackDialogProps = {
  submission: DossierSubmission
  isOpen: boolean
  handleClose(): void
}

function CollectFeedbackDialog({
  submission,
  isOpen,
  handleClose,
}: CollectFeedbackDialogProps) {
  const { formState, setFormField } = useFormState<{
    rating?: Maybe<Rating>
    feedback?: Maybe<string>
  }>({})
  const [recordReview] = useMutation<
    ReviewDossierSubmissionMutation,
    ReviewDossierSubmissionMutationVariables
  >(RECORD_REVIEW, {
    onCompleted() {
      toast.success(COPY.REVIEW_DOSSIER_SUCCESS)
      handleClose()
    },
    onError() {
      toast.error(COPY.REVIEW_DOSSIER_ERROR)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    const { rating, feedback } = formState
    if (!rating) {
      toast.warning(COPY.REQUIRE_RATING)
      return
    }
    if (!feedback) {
      toast.warning(COPY.REQUIRE_FEEDBACK)
      return
    }
    void recordReview({
      variables: {
        ReviewDossierSubmissionInput: {
          dossierSubmissionId: submission.id,
          feedback: feedback,
          rating: rating,
        },
      },
    })
  }

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={handleClose}
      aria-label="Collect dossier feedback"
      widthVW={[90, { sm: 80, md: 55, lg: 45, xl: 35 }]}
    >
      <Padding top={4} horizontal={4}>
        <DialogExitButton onClick={handleClose} />
        <Txt as="h2" bold size={24}>
          Dossier Feedback
        </Txt>
        <Padding top={2}>
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          <ExternalLink url={submission.fileUrl!}>
            <Txt underline>
              Draft Document
              <Padding left={1} inline>
                <Icon
                  name="ExternalLink"
                  height={13}
                  width={13}
                  primaryFill="text"
                  primaryFillHover="black"
                />
              </Padding>
            </Txt>
          </ExternalLink>
        </Padding>
        <Padding top={4}>
          <form onSubmit={handleSubmit}>
            <TextArea
              label="Feedback"
              value={formState.feedback ?? EMPTY_INPUT}
              onValueChange={(value) => setFormField('feedback', value)}
              required
            />
            <Flex justify="flex-end">
              <Padding top={1.5}>
                <StarRating
                  rating={formState.rating ?? null}
                  onValueChange={(value) => setFormField('rating', value)}
                />
              </Padding>
            </Flex>
            <Padding top={4} bottom={2}>
              <Flex justify="flex-end">
                <Button $type="accept">Submit</Button>
              </Flex>
            </Padding>
          </form>
        </Padding>
      </Padding>
    </Dialog>
  )
}

type DossierLinkProps = {
  submission: Maybe<DossierSubmission>
}

function DossierLink({ submission }: DossierLinkProps): React.ReactElement {
  return submission ? (
    // nullish fileUrl's are filtered out
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    <ExternalLink url={submission.fileUrl!}>
      <Icon
        name="ExternalLink"
        height={16}
        width={16}
        primaryFill="text"
        primaryFillHover="black"
      />
    </ExternalLink>
  ) : (
    <div />
  )
}

function UpdateDossierLink({ submission }: DossierLinkProps): React.ReactElement {
  return submission ? (
    <Link
      to={generatePath(ROUTE_PATHS.NEW_DOSSIER_UPDATE_REQUEST, {
        dossierSubmissionId: submission.id,
      })}
    >
      <Txt size={13} noWrap hoverColor="black" underline>
        Update
      </Txt>
    </Link>
  ) : (
    <div />
  )
}

InterviewCycleDetailDossiers.fragments = {
  dossierRequestInfo: gql`
    fragment DossierRequestInfo on InterviewCycle {
      dossierRequests {
        edges {
          node {
            id
            createdAt
            due
            dossierType
            dossierSubmissions {
              edges {
                node {
                  id
                  fileUrl
                  reviews {
                    id
                    rating
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}
