import { ColumnMap } from 'types'

export const COLUMN_HEADERS_DASHBOARD = [
  'Free Agent',
  'Sourced Jobs',
  'Writer Queue',
  'Initial Review',
  'Final Review',
  'Applications',
  'Cycles',
]

export const columns: ColumnMap = {
  freeAgent: {
    label: 'Free Agent',
    id: 'clientName',
  },
  sourcedJobs: {
    label: 'Sourced Jobs',
    id: 'sourcedJobsCount',
  },
  writerQueue: {
    label: 'Writer Queue',
    id: 'pendingWritingTaglinesCount',
  },
  initialReview: {
    label: 'Initial Review',
    id: 'pendingTaReviewTaglinesCount',
  },
  finalReview: {
    label: 'Final Review',
    id: 'pendingFrReviewTaglinesCount',
  },
  applications: {
    label: 'Applications',
    id: 'applicationsCount',
  },
  cycles: {
    label: 'Cycles',
    id: 'interviewCyclesCount',
  },
}

export enum TabLabel {
  MY_CANDIDATES = 'My Candidates',
  ALL_CANDIDATES = 'All Candidates',
  BY_TALENT_AGENT = 'By Talent Agent',
}
