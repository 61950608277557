import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import { Params } from './AddAndEditDossierRequest.types'
import AddAndEditDossierRequestFooter from './AddAndEditDossierRequestFooter'
import AddAndEditDossierRequestForm from './AddAndEditDossierRequestForm'
import Container from 'components/Container'
import Message from 'components/Message'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'
import {
  GetDossierRequestByIdQuery,
  GetDossierSubmissionByIdQuery,
} from 'generated/graphql'

function AddAndEditDossierRequest(): React.ReactElement {
  const { dossierRequestId, dossierSubmissionId } = useParams<Params>()
  const isEditing = Boolean(dossierRequestId)
  const isUpdateRequest = Boolean(dossierSubmissionId)
  const isNewRequest = !dossierRequestId && !dossierSubmissionId
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const { data: requestData, loading: requestLoading } =
    useQuery<GetDossierRequestByIdQuery>(GET_DOSSIER_REQUEST_BY_ID, {
      variables: { id: dossierRequestId },
      skip: !isEditing,
    })
  const { data: submissionData, loading: submissionLoading } =
    useQuery<GetDossierSubmissionByIdQuery>(GET_DOSSIER_SUBMISSION_BY_ID, {
      variables: { id: dossierSubmissionId },
      skip: !isUpdateRequest,
    })
  const dossierRequest = requestData?.dossierRequest
  const priorDossierSubmission = submissionData?.dossierSubmission

  return (
    <ViewBox
      footer={
        <AddAndEditDossierRequestFooter
          isSubmitting={isSubmitting}
          isEditing={isEditing}
        />
      }
    >
      <Container noMax>
        {(requestLoading || submissionLoading) && (
          <Message vertical message="Loading..." />
        )}
        {(isNewRequest ||
          (isEditing && dossierRequest) ||
          (isUpdateRequest && priorDossierSubmission)) && (
          <AddAndEditDossierRequestForm
            setIsSubmitting={setIsSubmitting}
            dossierRequest={dossierRequest}
            priorDossierSubmission={priorDossierSubmission}
            isEditing={isEditing}
          />
        )}
      </Container>
    </ViewBox>
  )
}

AddAndEditDossierRequest.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_DOSSIER_REQUEST}
    key={ROUTE_PATHS.NEW_DOSSIER_REQUEST}
  >
    <AddAndEditDossierRequest />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EDIT_DOSSIER_REQUEST}
    key={ROUTE_PATHS.EDIT_DOSSIER_REQUEST}
  >
    <AddAndEditDossierRequest />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_DOSSIER_UPDATE_REQUEST}
    key={ROUTE_PATHS.NEW_DOSSIER_UPDATE_REQUEST}
  >
    <AddAndEditDossierRequest />
  </PrivateRoute>,
]

export default AddAndEditDossierRequest

const GET_DOSSIER_REQUEST_BY_ID = gql`
  query GetDossierRequestById($id: ID!) {
    dossierRequest(id: $id) {
      id
      due
      dossierType
      priorDossierSubmission {
        id
        fileUrl
      }
      client {
        id
      }
      notes
      interviewCycle {
        id
      }
    }
  }
`

const GET_DOSSIER_SUBMISSION_BY_ID = gql`
  query GetDossierSubmissionById($id: ID!) {
    dossierSubmission(id: $id) {
      id
      fileUrl
      client {
        id
      }
      interviewCycle {
        id
      }
      dossierRequest {
        dossierType
      }
    }
  }
`
