import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { reviewColumns } from './ReviewSourcedJobs.constants'
import { SourcedJob, TableRowData } from './ReviewSourcedJobs.types'
import ReviewSourcedJobsHighlightedRow from './ReviewSourcedJobsHighlightedRow'
import ReviewSourcedJobsRow from './ReviewSourcedJobsRow'
import Message from 'components/Message'
import Txt from 'components/Txt'

type Props = {
  tableData: TableRowData[]
  talentAgentId: string
  clientId: string
}

export default function ReviewSourcedJobsTable({
  tableData,
  talentAgentId,
  clientId,
}: Props): React.ReactElement {
  return (
    <>
      <TableBox>
        <TableWrapper>
          <Table>
            <thead>
              <HeaderRow>
                {reviewColumns.map((column) => (
                  <HeaderData key={column.id} collapse={column.collapse}>
                    <Txt size={12} bold>
                      {column.label}
                    </Txt>
                  </HeaderData>
                ))}
              </HeaderRow>
            </thead>
            <tbody>
              {tableData.map((rowData: TableRowData) => {
                return (
                  <React.Fragment key={rowData.clientId}>
                    <ReviewSourcedJobsHighlightedRow
                      key={rowData.clientId}
                      rowData={rowData}
                    />
                    {rowData.sourcedJobs.map((job: SourcedJob) => (
                      <ReviewSourcedJobsRow
                        key={job.id}
                        rowData={job}
                        talentAgentId={talentAgentId}
                        clientId={clientId}
                      />
                    ))}
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </TableBox>
      {!tableData.length && <Message message="No jobs to show" vertical />}
    </>
  )
}
