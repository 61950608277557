import React from 'react'
import HList from 'components/HList'
import Padding from 'components/Padding'

type Props = {
  children?: React.ReactNode
}

function TableBodyActionList({ children }: Props): JSX.Element {
  return (
    <Padding as="td" right={2}>
      <HList size={1}>{children}</HList>
    </Padding>
  )
}

export default TableBodyActionList
