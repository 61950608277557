import React from 'react'
import ContentLoader from 'react-content-loader'

type Props = {
  height?: string | number
  width?: string | number
  fullWidth?: boolean
  withViewBox?: boolean
}

// either want none of these or both of these props
interface WrapperProps extends Props {
  showContent: boolean
  children: React.ReactNode
}

export const RectangleSkeleton: React.FC<Props> = ({
  height = 0,
  width = 0,
  fullWidth = false,
  withViewBox = true,
}: Props) => (
  <ContentLoader
    viewBox={withViewBox ? `0 0 ${width} ${height}` : undefined}
    height={height}
    width={width}
    style={fullWidth ? { width: '100%' } : undefined}
  >
    <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
  </ContentLoader>
)

/* TODO (matthewalbrecht): 
research if there is a way to enforce type security to the children, or maybe there is a better pattern here
  as an example: 
  if request type is Maybe<Request>  AND
  if Child.request prop type is Request 

  then this should NOT throw an error (but it does)

  <Skeleton showContent={Boolean(request)}>
    <Child request={request} />
  </Skeleton>

 */
export const RectangleSkeletonWrap: React.FC<WrapperProps> = ({
  height = 0,
  width = 0,
  fullWidth = false,
  withViewBox = true,
  showContent = false,
  children,
}: WrapperProps) => (
  <>
    {showContent ? (
      children
    ) : (
      <ContentLoader
        viewBox={withViewBox ? `0 0 ${width} ${height}` : undefined}
        height={height}
        width={width}
        style={fullWidth ? { width: '100%' } : undefined}
      >
        <rect x="0" y="0" rx="4" ry="4" width={width} height={height} />
      </ContentLoader>
    )}
  </>
)
