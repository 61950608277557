import { useQuery, gql } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import AddAndEditTaglineRequestFooter from './AddAndEditTaglineRequestFooter'
import AddAndEditTaglineRequestForm from './AddAndEditTaglineRequestForm'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'
import {
  FreeAgentsAndSequencesQuery,
  TaglineRequestByIdQuery,
  TaglineRequestByIdQueryVariables,
} from 'generated/graphql'

export default function AddAndEditTaglineRequest(): JSX.Element {
  const { requestId } = useParams<{ requestId: string }>()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const isEditPage = !!requestId

  const { data } = useQuery<FreeAgentsAndSequencesQuery>(
    GET_FREE_AGENTS_WITH_SEQUENCES
  )
  const { data: taglineRequestData } = useQuery<
    TaglineRequestByIdQuery,
    TaglineRequestByIdQueryVariables
  >(GET_TAGLINE_REQUEST, {
    skip: !isEditPage,
    variables: {
      id: requestId,
    },
  })

  const freeAgents = data?.clients
  const taglineRequest = taglineRequestData?.taglineRequest
  const isEditPageWithoutData = isEditPage && !taglineRequest

  // we want to block the render if we havent recieved
  // the freeAgents data for the dropdown or if we are
  // on the edit page and we haven't recieved request data
  if (!freeAgents || isEditPageWithoutData) {
    return <Message vertical message="Loading tagline request submission form..." />
  }

  return (
    <ViewBox
      footer={
        <AddAndEditTaglineRequestFooter
          isEditing={isEditPage}
          isSubmitting={isSubmitting}
        />
      }
    >
      <Container noMax>
        <Padding top={6}>
          <AddAndEditTaglineRequestForm
            freeAgents={freeAgents}
            taglineRequest={taglineRequest}
            isSubmitting={isSubmitting}
            setIsSubmitting={setIsSubmitting}
          />
        </Padding>
      </Container>
    </ViewBox>
  )
}

AddAndEditTaglineRequest.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_TAGLINE_REQUEST}
    key={ROUTE_PATHS.NEW_TAGLINE_REQUEST}
  >
    <AddAndEditTaglineRequest />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EDIT_TAGLINE_REQUEST}
    key={ROUTE_PATHS.EDIT_TAGLINE_REQUEST}
  >
    <AddAndEditTaglineRequest />
  </PrivateRoute>,
]

// used to fetch all free agents and their template names for new request form
const GET_FREE_AGENTS_WITH_SEQUENCES = gql`
  query FreeAgentsAndSequences {
    clients: allClients {
      ...ClientInfoAddAndEditTaglineRequestForm
    }
  }

  ${AddAndEditTaglineRequestForm.fragments.clientInfo}
`

const GET_TAGLINE_REQUEST = gql`
  query TaglineRequestById($id: ID!) {
    taglineRequest(id: $id) {
      ...TaglineRequestInfo
    }
  }
  ${AddAndEditTaglineRequestForm.fragments.taglineRequestInfo}
`
