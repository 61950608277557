import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useAuthContext } from 'context/auth'
import { EMPTY_INPUT } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { GET_TALENT_AGENTS } from 'gql/queries'
import { normalizeDossierSubmissions } from './ReviewDossierSubmissions.helpers'
import ReviewDossierSubmissionsTable from './ReviewDossierSubmissionsTable'
import Container from 'components/Container'
import { Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetDossiersForReviewQuery,
  GetDossiersForReviewQueryVariables,
  GetTalentAgentsQuery,
  GetTalentAgentsQueryVariables,
} from 'generated/graphql'

export default function ReviewDossierSubmissions(): React.ReactElement {
  const {
    userSession: {
      user: { id: viewerId, isTalentAgent },
    },
  } = useAuthContext()
  const [talentAgentId, setTalentAgentId] = useState<string>(
    isTalentAgent ? viewerId : EMPTY_INPUT
  )

  // query
  const { data, loading, refetch } = useQuery<
    GetDossiersForReviewQuery,
    GetDossiersForReviewQueryVariables
  >(GET_DOSSIER_SUBMISSIONS, {
    variables: {
      talentAgentId,
    },
    skip: !talentAgentId,
  })

  const { data: talentAgentData } = useQuery<
    GetTalentAgentsQuery,
    GetTalentAgentsQueryVariables
  >(GET_TALENT_AGENTS)
  const talentAgentOptions = buildDropdownOptions(
    talentAgentData?.talentAgents || []
  )

  const connection = data?.dossierSubmissions
  const tableData = connection && normalizeDossierSubmissions(connection)

  /**
   * update talentAgentIdState & refetch query
   * @param value input value
   */
  function handleTalentAgentIdChange(value: string) {
    setTalentAgentId(value)

    void refetch({ talentAgentId: value })
  }

  function renderTableContent() {
    if (loading) {
      return <Message message="Loading..." />
    }
    if (tableData && !tableData.length) {
      return <Message message="No Dossier Submissions Found." />
    }
    if (tableData?.length) {
      return <ReviewDossierSubmissionsTable tableData={tableData} />
    }
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Txt as="h2" size={24} bold>
            Review Dossiers
          </Txt>
        </Padding>
        <Padding top={4}>
          <Dropdown
            label="Talent Agent"
            options={talentAgentOptions}
            value={talentAgentId}
            onValueChange={handleTalentAgentIdChange}
            placeholder="Select Talent Agent"
            withEmptyOption={false}
          />
        </Padding>
      </Container>
      <Padding top={4}>{renderTableContent()}</Padding>
    </ViewBox>
  )
}

const GET_DOSSIER_SUBMISSIONS = gql`
  query GetDossiersForReview($talentAgentId: ID!) {
    dossierSubmissions(
      isReviewed: false
      isRejected: false
      talentAgentId: $talentAgentId
    ) {
      edges {
        node {
          id
          jobTitle
          employerName
          fileUrl
          client {
            id
            name
          }
        }
      }
    }
  }
`

ReviewDossierSubmissions.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REVIEW_DOSSIERS}
    key={ROUTE_PATHS.REVIEW_DOSSIERS}
  >
    <ReviewDossierSubmissions />
  </PrivateRoute>,
]
