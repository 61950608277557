import { useMutation, gql } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactChangeEvent } from 'types'
import { ERRORS } from 'utils/constants'
import { CREATE_SEQUENCE } from 'gql/mutations'
import Button from 'components/Button'
import Padding from 'components/Padding'
import {
  CreateTemplateSequenceMutation,
  CreateTemplateSequenceMutationVariables,
  TemplateSequence,
} from 'generated/graphql'

const StyledInput = styled.input`
  border: none;
  width: ${({ theme }) => theme.toRems(100)};
`

type Props = { clientId: string }

export default function NewTemplateSequenceButton({ clientId }: Props): JSX.Element {
  const [isActive, setIsActive] = useState(false)
  const [name, setName] = useState('')
  const [createNewSequence, { error }] = useMutation<
    CreateTemplateSequenceMutation,
    CreateTemplateSequenceMutationVariables
  >(CREATE_SEQUENCE, {
    errorPolicy: 'all',
    // This updates the local cache for our client, adding the newly created sequence to the record
    update(cache, { data, errors }) {
      const templateSequence = data?.createTemplateSequence?.templateSequence
      if (templateSequence && !errors?.length) {
        cache.modify({
          id: cache.identify({ id: clientId, __typename: 'Client' }),
          fields: {
            templateSequences(templateSequences: TemplateSequence[]) {
              const newTemplateSequenceRef = cache.writeFragment({
                data: templateSequence,
                fragment: gql`
                  fragment NewTemplateSequence on TemplateSequence {
                    id
                    name
                    templates {
                      id
                      copy
                      name
                    }
                  }
                `,
              })

              return [...templateSequences, newTemplateSequenceRef]
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (error?.message.includes(ERRORS.UNIQUE_VIOLATION)) {
      toast.error('Template sequence names must be unique')
    } else if (error) {
      toast.error('There was an error creating the template sequence')
    }
  }, [error])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    // mutation for creating new sequence
    void createNewSequence({
      variables: {
        CreateTemplateSequenceInput: {
          clientId,
          name,
        },
      },
    })
    // This will flip what is rendered by this component back to a button
    setIsActive(false)
    // resets the inputs value to empty
    setName('')
    event.currentTarget.reset()
  }

  return isActive ? (
    <form onSubmit={handleSubmit} name="newTemplateSequenceForm">
      <StyledInput
        autoFocus
        value={name}
        onChange={(e: ReactChangeEvent) => setName(e.currentTarget.value)}
        placeholder="Type name..."
        name="sequenceName"
      />
      <Padding inline left={1}>
        <Button small $type="accept">
          Add
        </Button>
      </Padding>
    </form>
  ) : (
    <Button $type="link" onClick={() => setIsActive(true)}>
      + Add Sequence
    </Button>
  )
}
