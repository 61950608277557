import React from 'react'
import Message from 'components/Message'
import VList from 'components/VList'

type Props = {
  children: React.ReactNode
  isEmpty: boolean
  emptyMessage: string
  isLoading: boolean
  loadingMessage: string
  isError: boolean
  errorMessage: string
  spacing?: number
}

export default function FullWidthItemList({
  children,
  isEmpty,
  emptyMessage,
  isLoading,
  loadingMessage,
  isError,
  errorMessage,
  spacing = 2,
}: Props): React.ReactElement {
  function renderMessage() {
    if (isEmpty && emptyMessage) {
      return <Message message={emptyMessage} vertical />
    }
    if (isLoading && loadingMessage) {
      return <Message message={loadingMessage} vertical />
    }
    if (isError && errorMessage) {
      return <Message message={errorMessage} vertical />
    }
  }

  return (
    <>
      <VList size={spacing}>{children}</VList>
      {renderMessage()}
    </>
  )
}
