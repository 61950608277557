import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { PAGE_SIZE } from './ClientExpertRequests.constants'
import { buildTableData } from './ClientExpertRequests.helpers'
import ClientExpertRequestsRow from './ClientExpertRequestsRow'
import ClientExpertRequestsTable from './ClientExpertRequestsTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import ViewBox from 'components/ViewBox'
import {
  GetExpertRequestsByClientQuery,
  GetExpertRequestsByClientQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientExpertRequests({
  clientId,
}: Props): React.ReactElement {
  const { data, fetchMore, loading } = useQuery<
    GetExpertRequestsByClientQuery,
    GetExpertRequestsByClientQueryVariables
  >(GET_EXPERT_REQUESTS, {
    variables: {
      clientId: clientId,
      first: PAGE_SIZE,
    },
  })

  if (loading) {
    return <Message vertical message="Loading data..." />
  }

  const tableData = data?.expertRequests ? buildTableData(data.expertRequests) : []

  /**
   * fetches additional expert requests when load more button is clicked
   */
  function handleLoadMore() {
    void fetchMore({
      variables: {
        first: PAGE_SIZE,
        after: endCursor || undefined,
      },
    })
  }

  const { pageInfo } = data?.expertRequests ?? {}
  const { hasNextPage, endCursor } = pageInfo ?? {}
  const showLoadMoreButton = hasNextPage

  return (
    <ViewBox unsetHeight>
      <Padding top={1}>
        <ClientExpertRequestsTable tableData={tableData} clientId={clientId} />
        {!tableData.length && (
          <Message message="No expert requests found" vertical />
        )}
      </Padding>
      {showLoadMoreButton && (
        <Padding top={4}>
          <Container noMax>
            <Flex justify="center">
              <Button onClick={handleLoadMore}>Load More</Button>
            </Flex>
          </Container>
        </Padding>
      )}
    </ViewBox>
  )
}

const GET_EXPERT_REQUESTS = gql`
  query GetExpertRequestsByClient($clientId: ID!, $first: Int, $after: String) {
    expertRequests(clientId: $clientId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...ClientExpertRequestRowInfo
        }
      }
    }
  }
  ${ClientExpertRequestsRow.fragments.clientExpertRequestRowInfo}
`
