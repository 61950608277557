import { generatePath } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'

/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextSubmissionId: string | null,
  writerId: string
): string {
  if (nextSubmissionId) {
    return generatePath(ROUTE_PATHS.REVIEW_FEEDBACK_QUEUE, {
      submissionId: nextSubmissionId,
      writerId,
    })
  }

  return ROUTE_PATHS.HOME
}
