import { Dayjs } from 'dayjs'
import { ArrayElement, ConnectionNode } from 'types'
import { GetFreeAgentsQuery } from 'generated/graphql'

export type RawFreeAgent = ArrayElement<
  NonNullable<GetFreeAgentsQuery['freeAgents']>
>
export type RawTaglineRequest = ConnectionNode<RawFreeAgent['taglineRequests']>
export type TaglineRequest = RawTaglineRequest & {
  lastStatusChangeDate: Dayjs
}
export type FreeAgent = Omit<RawFreeAgent, 'taglineRequests'> & {
  taglineRequests: TaglineRequest[]
}

export enum WorkTypes {
  CURRENT = 'current',
  REVIEWED_REJECTED = 'reviewedRejected',
}

export type Filter = { clientId: string; talentAgentId: string; workType: string }
