import React from 'react'
import { SOURCED_JOB_REJECTION_MAP } from 'utils/constants'
import { SourcedJobRejectionReason } from 'utils/enums'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import StarRating from 'components/StarRating/StarRating'
import { SourcedJob } from './SourcingReview.types'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'

type Props = { sourcedJob: SourcedJob }

export default function SourcingReviewRow({
  sourcedJob,
}: Props): React.ReactElement {
  const rejectionReason = sourcedJob.rejectionReason
    ? SOURCED_JOB_REJECTION_MAP[
        sourcedJob.rejectionReason as SourcedJobRejectionReason
      ] || sourcedJob.rejectionReason
    : '–'

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {sourcedJob.clientName}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {sourcedJob.sourcerName}
        </Txt>
      </BodyData>
      <BodyData>
        <StarRating rating={sourcedJob.rating} disabled iconSize={16} />
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {sourcedJob.status}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rejectionReason}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          <ExternalLink url={sourcedJob.url} inheritStyles label="Link" />
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {sourcedJob.company}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {sourcedJob.title}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {sourcedJob.notes}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
