import React from 'react'
import {
  FakeAvatar,
  LogoContainer,
  LogoImage,
} from 'views/ClientJobs/ClientJobs.styles'
import Icon from 'components/Icon/Icon'

type Props = {
  src?: string | null
  alt?: string
  height?: number
  width?: number
}

export default function DashboardCardCompanyLogo({
  src,
  alt,
  height = 30,
  width = 30,
}: Props): React.ReactElement {
  // TODO: (ericlus) handle missing src and alts
  return (
    <>
      {src ? (
        <LogoContainer>
          <LogoImage height={height} width={width} src={src} alt={alt} />
        </LogoContainer>
      ) : (
        <FakeAvatar
          display="flex"
          justify="center"
          align="center"
          width={width}
          height={height}
        >
          <Icon
            name="FreeAgencyFlag"
            height={height}
            width={width}
            primaryFill="darkGray"
          />
        </FakeAvatar>
      )}
    </>
  )
}
