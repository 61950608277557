import React from 'react'
import { useLocation } from 'react-router'
import { DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { ChangesProvider } from 'views/Assign/Assign.context'
import AssignHeader from 'views/Assign/AssignHeader'
import AssignTable from 'views/Assign/AssignTable'
import AssignTaglinesDeleteButton from 'views/AssignTaglines/AssignTaglinesDeleteButton'
import AssignTaglinesHighlightedClientRow from 'views/AssignTaglines/AssignTaglinesHighlightedClientRow'
import { ASSIGN_DATA, COLUMNS, REASSIGN_DATA } from './AssignTaglines.constants'
import getEditPath from './AssignTaglines.helpers'
import AssignTaglinesFooter from './AssignTaglinesFooter'
import AssignTaglinesOverrideColumns from './AssignTaglinesOverrideColumns'
import useAssignTaglinesData from './useAssignTaglinesData'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function AssignTaglines(): JSX.Element {
  const { pathname } = useLocation()
  const isReassigning = pathname === ROUTE_PATHS.ASSIGNED_TAGLINES
  const { groupedRowData, assigneeOptions } = useAssignTaglinesData(isReassigning)
  const totalTaglines = groupedRowData.reduce(
    (total, groupedRow) => (total += groupedRow.items.length),
    0
  )
  return (
    <ChangesProvider>
      <ViewBox footer={<AssignTaglinesFooter />}>
        <Padding top={6}>
          <Container noMax>
            <AssignHeader
              isReassigning={isReassigning}
              assignData={ASSIGN_DATA}
              reassignData={REASSIGN_DATA}
              appendedHeaderCopy={`(${totalTaglines})`}
            />
          </Container>
          <Padding top={6}>
            <AssignTable
              isReassigning={isReassigning}
              groupedRowData={groupedRowData}
              assigneeOptions={assigneeOptions}
              columns={COLUMNS}
              workerType="Writer"
              DeleteButtonComponent={AssignTaglinesDeleteButton}
              getEditPath={getEditPath}
              dueDateFormat={DATE_FORMAT.ONLY_DATE}
              ClientRowComponent={AssignTaglinesHighlightedClientRow}
              OverrideColumns={AssignTaglinesOverrideColumns}
            />
          </Padding>
        </Padding>
      </ViewBox>
    </ChangesProvider>
  )
}

AssignTaglines.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AVAILABLE_TAGLINES}
    key={ROUTE_PATHS.AVAILABLE_TAGLINES}
  >
    <AssignTaglines />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ASSIGNED_TAGLINES}
    key={ROUTE_PATHS.ASSIGNED_TAGLINES}
  >
    <AssignTaglines />
  </PrivateRoute>,
]
