export const COLUMNS = [
  {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  {
    label: 'Talent Agent',
    id: 'talentAgent',
  },
  {
    label: 'Requested',
    id: 'requested',
  },
  {
    label: 'Submitted',
    id: 'submitted',
  },
  {
    label: 'Approved',
    id: 'approved',
  },
  {
    label: 'Rejected',
    id: 'rejected',
  },
  {
    label: '',
    id: 'actions',
  },
]

export const TOAST = {
  rerequestSuccess: 'Sourcing re-request successful',
  rerequestFailure: 'There was an error re-requesting sourcing',
}
