import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { ReactFormEvent, ReactSetState } from 'types'
import { useAuthContext } from 'context/auth'
import { CACHE_STRING } from 'utils/cacheString'
import getTimeSpentSec from 'utils/helpers/getTimeSpentSec'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import ROUTE_PATHS from 'utils/routePaths'
import { DEFAULT_STATE, ID, TOAST } from './DossierSubmission.constants'
import { validateState } from './DossierSubmission.helpers'
import { DossierRequest, State } from './DossierSubmission.types'
import { TextInput } from 'components/Inputs'
import VList from 'components/VList'
import {
  SubmitDossierWorkMutation,
  SubmitDossierWorkMutationVariables,
} from 'generated/graphql'

type Props = {
  state: State
  setState: ReactSetState<State>
  dossierRequest: DossierRequest
  nextRouteUrl: string | null
}

export default function DossierSubmissionForm({
  state,
  setState,
  dossierRequest,
  nextRouteUrl,
}: Props): React.ReactElement {
  const history = useHistory()
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()

  const [createDossierSubmission] = useMutation<
    SubmitDossierWorkMutation,
    SubmitDossierWorkMutationVariables
  >(SUBMIT_DOSSIER_WORK)

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()

    if (validateState(state)) {
      void submitDossierWork()
    }
  }

  async function submitDossierWork() {
    const timeSpentSec = getTimeSpentSec(state.timeSpent)
    const variables = {
      CreateDossierSubmissionInput: {
        dossierRequestId: dossierRequest.id,
        fileUrl: state.fileUrl,
        timeSpentSec,
      },
    }

    try {
      await createDossierSubmission({
        variables,
        update(cache) {
          removeItemFromCacheConnections<SubmitDossierWorkMutation>(
            cache,
            dossierRequest.id,
            [CACHE_STRING.DOSSIER_REQUEST_OPEN_ASSIGNED(assigneeId)]
          )
        },
      })
      handleCompletion({ verb: 'submitted' })
    } catch (error) {
      toast.error(TOAST.SUBMISSION_ERROR)
    }
  }

  /**
   *
   * @param data data returned from mutation
   */
  function handleCompletion({ verb }: { verb: string }) {
    const toastContent = `Dossier Submission ${verb}`

    toast.success(toastContent)

    setState(DEFAULT_STATE)

    if (!nextRouteUrl) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl || ROUTE_PATHS.HOME)
  }

  return (
    <form id={ID.FORM} onSubmit={handleSubmit}>
      <VList size={4}>
        <TextInput
          label="Dossier Link *"
          description="Have you made this slide deck editable by 'anyone with the link'?"
          name="fileUrl"
          value={state.fileUrl}
          type="url"
          required
          fullWidth
          onValueChange={(value) => setState({ ...state, fileUrl: value })}
          autoComplete="off"
        />
        <TextInput
          label="Time Spent on Assignment *"
          name="timeSpent"
          value={state.timeSpent}
          required
          placeholder="h:mm"
          width={60}
          onValueChange={(value) => setState({ ...state, timeSpent: value })}
        />
      </VList>
    </form>
  )
}

const SUBMIT_DOSSIER_WORK = gql`
  mutation SubmitDossierWork(
    $CreateDossierSubmissionInput: CreateDossierSubmissionInput!
  ) {
    createDossierSubmission(input: $CreateDossierSubmissionInput) {
      dossierSubmission {
        id
      }
    }
  }
`
