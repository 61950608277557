import { ApolloProvider } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router'
import { Switch } from 'react-router-dom'
import { useAuthContext } from 'context/auth'
import useSentryConfig from 'hooks/useSentryConfig'
import getClient from 'gql/client'
import ActivityLog from 'views/ActivityLog/ActivityLog'
import AddAndEditDossierRequest from 'views/AddAndEditDossierRequest/AddAndEditDossierRequest'
import AddAndEditExpertRequest from 'views/AddAndEditExpertRequest/AddAndEditExpertRequest'
import AddAndEditInterview from 'views/AddAndEditInterview/AddAndEditInterview'
import AddAndEditInterviewPrepRequest from 'views/AddAndEditInterviewPrepRequest/AddAndEditInterviewPrepRequest'
import AddAndEditSourcingRequest from 'views/AddAndEditSourcingRequest/AddAndEditSourcingRequest'
import AddAndEditTaglineRequest from 'views/AddAndEditTaglineRequest/AddAndEditTaglineRequest'
import AddInterviewDebrief from 'views/AddInterviewDebrief/AddInterviewDebrief'
import AssignApplications from 'views/AssignApplications/AssignApplications'
import AssignDossiers from 'views/AssignDossiers/AssignDossiers'
import AssignFinalReviews from 'views/AssignFinalReviews/AssignFinalReviews'
import AssignSourcing from 'views/AssignSourcing/AssignSourcing'
import AssignTaglines from 'views/AssignTaglines/AssignTaglines'
import AutoSourcingDashboard from 'views/AutoSourcingDashboard/AutoSourcingDashboard'
import ClientApplicationAnswers from 'views/ClientApplicationAnswers/ClientApplicationAnswers'
import ClientConfig from 'views/ClientConfig/ClientConfig'
import ClientJobHistory from 'views/ClientJobHistory/ClientJobHistory'
import ClientStatus from 'views/ClientStatus/ClientStatus'
import Clients from 'views/Clients/Clients'
import CloseMessage from 'views/CloseMessage/CloseMessage'
import CompanyDetail from 'views/CompanyDetail/CompanyDetail'
import CompanyMerge from 'views/CompanyMerge/CompanyMerge'
import CompanySearch from 'views/CompanySearch/CompanySearch'
import Contracts from 'views/Contracts/Contracts'
import CurrentAssignments from 'views/CurrentAssignments/CurrentAssignments'
import DossierFeedback from 'views/DossierFeedback/DossierFeedback'
import DossierLibrary from 'views/DossierLibrary/DossierLibrary'
import DossierSubmission from 'views/DossierSubmission/DossierSubmission'
import EnrichmentSubmission from 'views/EnrichmentSubmission/EnrichmentSubmission'
import ExpertNetwork from 'views/ExpertNetwork/ExpertNetwork'
import ExpertRequests from 'views/ExpertRequests/ExpertRequests'
import FinalCopy from 'views/FinalCopy/FinalCopy'
import FinalReview from 'views/FinalReview/FinalReview'
import Home from 'views/Home/Home'
import InitialReview from 'views/InitialReview/InitialReview'
import InterviewCycleDetail from 'views/InterviewCycleDetail/InterviewCycleDetail'
import InterviewDebrief from 'views/InterviewDebrief/InterviewDebrief'
import InterviewPrepRequests from 'views/InterviewPrepRequests/InterviewPrepRequests'
import Login from 'views/Login/Login'
import Logout from 'views/Logout/Logout'
import Magic from 'views/Magic/Magic'
import NewInterviewCycle from 'views/NewInterviewCycle/NewInterviewCycle'
import Preferences from 'views/Preferences/Preferences'
import RedactSourcedJobs from 'views/RedactSourcedJobs/RedactSourcedJobs'
import ReportApplicationInfo from 'views/ReportApplicationInfo/ReportApplicationInfo'
import ReportInterviewTime from 'views/ReportInterviewTime/ReportInterviewTime'
import ReviewDossierSubmissions from 'views/ReviewDossierSubmissions/ReviewDossierSubmissions'
import ReviewEnrichment from 'views/ReviewEnrichment/ReviewEnrichment'
import ReviewFeedback from 'views/ReviewFeedback/ReviewFeedback'
import ReviewSourcedJobs from 'views/ReviewSourcedJobs/ReviewSourcedJobs'
import ReviewedTaglines from 'views/ReviewedTaglines/ReviewedTaglines'
import SourcedJobsReview from 'views/SourcedJobsReview/SourcedJobsReview'
import SourcerReview from 'views/SourcerReview/SourcerReview'
import SourcerStats from 'views/SourcerStats/SourcerStats'
import SourcerWorkStats from 'views/SourcerWorkStats/SourcerWorkStats'
import SourcingReview from 'views/SourcingReview/SourcingReview'
import SourcingStats from 'views/SourcingStats/SourcingStats'
import SourcingSubmission from 'views/SourcingSubmission/SourcingSubmission'
import TaglineStatus from 'views/TaglineStatus/TaglineStatus'
import TaglineWorkLog from 'views/TaglineWorkLog/TaglineWorkLog'
import TalentAgentDashboardPage from 'views/TalentAgentDashboard/TalentAgentDashboardPage'
import TalentAgentStats from 'views/TalentAgentStats/TalentAgentStats'
import TalentAgents from 'views/TalentAgents/TalentAgents'
import TalentEnablementStats from 'views/TalentEnablementEditorStats/TalentEnablementEditorStats'
import Users from 'views/Users/Users'
import WorkDashboard from 'views/WorkDashboard/WorkDashboard'
import WriterSubmission from 'views/WriterSubmission/WriterSubmission'
import WriterTaglineStats from 'views/WriterTaglineStats/WriterTaglineStats'
import WrappedToastContainer from 'components/WrappedToastContainer'
import 'sentry'
import 'react-toastify/dist/ReactToastify.css'

export default function Routes(): JSX.Element {
  const { userSession } = useAuthContext()
  const history = useHistory()
  const client = getClient(userSession, history)
  useSentryConfig()

  return (
    <ApolloProvider client={client}>
      <div>
        <Switch>
          {/* PUBLIC ROUTES */}
          {AddInterviewDebrief.Routes}
          {CloseMessage.Routes}
          {Login.Routes}
          {Logout.Routes}
          {Magic.Routes}
          {ReportApplicationInfo.Routes}
          {ReportInterviewTime.Routes}
          {/* PRIVATE ROUTES */}
          {ActivityLog.Routes}
          {AddAndEditDossierRequest.Routes}
          {AddAndEditExpertRequest.Routes}
          {AddAndEditInterview.Routes}
          {AddAndEditInterviewPrepRequest.Routes}
          {AddAndEditSourcingRequest.Routes}
          {AddAndEditTaglineRequest.Routes}
          {AssignApplications.Routes}
          {AssignDossiers.Routes}
          {AssignFinalReviews.Routes}
          {AssignSourcing.Routes}
          {AssignTaglines.Routes}
          {AutoSourcingDashboard.Routes}
          {ClientApplicationAnswers.Routes}
          {ClientConfig.Routes}
          {ClientJobHistory.Routes}
          {Clients.Routes}
          {ClientStatus.Routes}
          {CompanyDetail.Routes}
          {Contracts.Routes}
          {CurrentAssignments.Routes}
          {DossierLibrary.Routes}
          {DossierFeedback.Routes}
          {DossierSubmission.Routes}
          {EnrichmentSubmission.Routes}
          {ExpertNetwork.Routes}
          {ExpertRequests.Routes}
          {FinalCopy.Routes}
          {FinalReview.Routes}
          {FinalReview.Routes}
          {Home.Routes}
          {InitialReview.Routes}
          {InterviewDebrief.Routes}
          {InterviewPrepRequests.Routes}
          {InterviewCycleDetail.Routes}
          {NewInterviewCycle.Routes}
          {Preferences.Routes}
          {ReviewDossierSubmissions.Routes}
          {ReviewedTaglines.Routes}
          {ReviewEnrichment.Routes}
          {ReviewFeedback.Routes}
          {ReviewSourcedJobs.Routes}
          {RedactSourcedJobs.Routes}
          {SourcedJobsReview.Routes}
          {SourcerReview.Routes}
          {SourcerStats.Routes}
          {SourcerWorkStats.Routes}
          {SourcingReview.Routes}
          {SourcingStats.Routes}
          {SourcingSubmission.Routes}
          {TaglineStatus.Routes}
          {TaglineWorkLog.Routes}
          {TalentAgentDashboardPage.Routes}
          {TalentAgentStats.Routes}
          {TalentAgents.Routes}
          {TalentEnablementStats.Routes}
          {Users.Routes}
          {WorkDashboard.Routes}
          {WriterSubmission.Routes}
          {WriterTaglineStats.Routes}
          {CompanySearch.Routes}
          {CompanyMerge.Routes}
        </Switch>
        <WrappedToastContainer draggable={false} position="bottom-center" />
      </div>
    </ApolloProvider>
  )
}
