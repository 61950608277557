import styled from 'styled-components/macro'
import { showScrollbar } from 'components/MiscStyles'

export const DashboardContent = styled.div`
  position: relative;
  border: 0;
  padding: 0;
  resize: none;
  width: 100%;
  height: ${({ theme }) => theme.toRems(108)};
  max-height: ${({ theme }) => theme.toRems(108)};
  overflow-y: scroll;
  ${showScrollbar}
`

export const DashboardGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  row-gap: ${({ theme }) => theme.rhythm(5)};
  column-gap: ${({ theme }) => theme.rhythm(5)};
  grid-template-areas:
    'grid-area-1 grid-area-1 grid-area-4 grid-area-4'
    'grid-area-2 grid-area-2 grid-area-5 grid-area-5'
    'grid-area-3 grid-area-3 grid-area-5 grid-area-5';
`

export const GridArea1 = styled.div`
  grid-area: grid-area-1;
`
export const GridArea2 = styled.div`
  grid-area: grid-area-2;
`
export const GridArea3 = styled.div`
  grid-area: grid-area-3;
`
export const GridArea4 = styled.div`
  grid-area: grid-area-4;
`
export const GridArea5 = styled.div`
  grid-area: grid-area-5;
`

export const NumberCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: ${({ theme }) => theme.toRems(50)};
  width: ${({ theme }) => theme.toRems(80)};
  background: ${({ theme }) => theme.color.numberCardGray};
  border-radius: ${({ theme }) => theme.borderRadius};
`
