export const COLUMNS = [
  {
    label: 'Company',
    id: 'company',
  },
  {
    label: 'Job Title',
    id: 'title',
  },
  {
    label: 'Dossier Link',
    id: 'link',
  },
  {
    label: 'Feedback',
    id: 'feedback',
  },
  {
    label: 'Rating',
    id: 'rating',
  },
  {
    label: '',
    id: 'submit',
    collapse: true,
  },
]

export const COPY = {
  REVIEW_SUCCESS: 'Dossier submission review successful',
  REVIEW_ERROR: 'There was an error reviewing the dossier submission',
}
