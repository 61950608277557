import dayjs from 'dayjs'
import React from 'react'
import { Link } from 'react-router-dom'
import { DATE_FORMAT } from 'utils/constants'
import Icon from 'components/Icon/Icon'
import StarRating from 'components/StarRating/StarRating'
import {
  TimelineContainer,
  TimelineEventContainer,
  TimelineEventCard,
} from './ClientJobHistory.styles'
import { TimelineEvent } from './ClientJobHistory.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { BackgroundColor } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type TimelineWrapperProps = {
  children: React.ReactElement
  internalLinkRoute?: string
  externalLinkUrl?: string
}

function TimelineWrapper({
  children,
  internalLinkRoute,
  externalLinkUrl,
}: TimelineWrapperProps): React.ReactElement {
  if (internalLinkRoute) {
    return (
      <TimelineEventCard>
        <Link to={internalLinkRoute}>{children}</Link>
      </TimelineEventCard>
    )
  }
  if (externalLinkUrl) {
    return (
      <TimelineEventCard>
        <ExternalLink url={externalLinkUrl} txtProps={{ underline: false }}>
          {children}
        </ExternalLink>
      </TimelineEventCard>
    )
  }
  return children
}

type Props = {
  jobEvents: TimelineEvent[]
}

export default function ClientJobTimeline({
  jobEvents,
}: Props): React.ReactElement | null {
  return (
    <TimelineContainer>
      {jobEvents.map(
        ({
          id,
          type,
          createdAt,
          reason,
          rating,
          internalLinkRoute,
          externalLinkUrl,
        }) => {
          const isTimelineCardALink = Boolean(internalLinkRoute || externalLinkUrl)
          return (
            <TimelineEventContainer key={id}>
              <TimelineWrapper
                internalLinkRoute={internalLinkRoute}
                externalLinkUrl={externalLinkUrl}
              >
                <BackgroundColor rounded color="faGrey1">
                  <Padding vertical={2} horizontal={2.5}>
                    <Flex justify="space-between">
                      <div>
                        <Padding bottom={reason ? 1 : 2}>
                          <Txt bold>{type}</Txt>
                        </Padding>
                        {reason && (
                          <Padding bottom={2}>
                            <Txt size={14} color="faGrey4">
                              {reason}
                            </Txt>
                          </Padding>
                        )}
                        {rating && (
                          <Padding bottom={2}>
                            <StarRating rating={rating} iconSize={18} disabled />
                          </Padding>
                        )}
                        <Txt>
                          {dayjs(createdAt).format(
                            DATE_FORMAT.FRIENDLY_DATE_AND_TIME
                          )}
                        </Txt>
                      </div>
                      {isTimelineCardALink && (
                        <Flex align="center" justify="center">
                          <Icon
                            name="ArrowRight"
                            width={20}
                            height={20}
                            primaryFill="text"
                          />
                        </Flex>
                      )}
                    </Flex>
                  </Padding>
                </BackgroundColor>
              </TimelineWrapper>
            </TimelineEventContainer>
          )
        }
      )}
    </TimelineContainer>
  )
}
