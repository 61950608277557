import { DateFilter, TaglineRequestStatus } from 'generated/graphql'

/**
 * when updateding cached queries we must match the field name (query ) in the cache exactly
 * this file will be used organize all the different query signatures that we use in the project
 */
export const CACHE_STRING = {
  DOSSIER_REQUEST_ASSIGNED: `dossierRequests({"isAssigned":true,"isOpen":true})`,
  DOSSIER_REQUEST_UNASSIGNED: `dossierRequests({"isAssigned":false,"isOpen":true})`,
  DOSSIER_SUBMISSIONS_UNREVIEWED: (viewerId: string): string =>
    `dossierSubmissions:{"isReviewed":false,"isRejected":false,"talentAgentId":"${viewerId}"}`,
  DOSSIER_REQUEST_OPEN_ASSIGNED: (assigneeId: string): string =>
    `dossierRequests({"assigneeId":"${assigneeId}","isOpen":true})`,
  FEEDBACK_QUEUE: (createdById: string): string =>
    `taglineSubmissionsConn({"createdById":"${createdById}","hasTalentPartnerReviews":true,"hasWriterFeedbackAcknowledgements":false})`,
  TEX_USER_LIST: 'users({"anyRole":{"isAdmin":false}})',
  EXPERT_LIST: 'experts',
  CLIENT_LIST: 'clients({"withInactive":true})',
  CLIENT_PENDING_WRITING: `taglineRequests({"status":"${TaglineRequestStatus.PENDING_WRITING}"})`,
  CLIENT_PENDING_TA: `taglineRequests({"status":"${TaglineRequestStatus.PENDING_TA_REVIEW}"})`,
  CLIENT_PENDING_FR: `taglineRequests({"status":"${TaglineRequestStatus.PENDING_TP_REVIEW}"})`,
  TA_REVIEW_QUEUE: (talentAgentId: string): string =>
    `taglineRequests({"status":"${TaglineRequestStatus.PENDING_TA_REVIEW}","talentAgentId":"${talentAgentId}"})`,
  FR_REVIEW_QUEUE: `taglineRequests({"status":"${TaglineRequestStatus.PENDING_TP_REVIEW}"})`,
  SOURCED_JOB_REVIEW: (talentAgentId: string): string =>
    `sourcedJobs({"isRedacted":false,"isReviewed":false,"onlyActiveClients":true,"talentAgentId":"${talentAgentId}"})`,
  SOURCED_JOB_REVIEW_FREE_AGENT: (talentAgentId: string, clientId: string): string =>
    `sourcedJobs({"clientId":"${clientId}","isRedacted":false,"isReviewed":false,"onlyActiveClients":true,"talentAgentId":"${talentAgentId}"})`,
  SOURCED_JOB_REVIEW_QUEUE: `sourcedJobs({"isRedacted":false,"isReviewed":false})`,
  SOURCED_JOB_REDACTION: (dateFilter: DateFilter): string =>
    `sourcedJobs({"dateFilter":${JSON.stringify(
      dateFilter
    )},"isRedacted":false,"isReviewed":false,"onlyActiveClients":true})`,
  INTERVIEW_PREP_REQUESTS: 'interviewPrepRequests',
  TAGLINE_WRITING_QUEUE: (assigneeId: string): string =>
    `taglineRequests({"assigneeId":"${assigneeId}","status":"${TaglineRequestStatus.PENDING_WRITING}"})`,
}
