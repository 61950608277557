import React from 'react'
import { Link } from 'react-router-dom'
import { QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { ID } from './AddAndEditInterviewPrepRequest.constants'
import Button from 'components/Button'
import Flex from 'components/Flex'
import { FooterBar } from 'components/MiscStyles'

export default function AddAndEditInterviewPrepRequestFooter(): React.ReactElement {
  const queryString = useQueryString()
  const cancelRoute = queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME

  return (
    <FooterBar>
      <Flex align="center" justify="space-between">
        <Button $type="secondary" as={Link} to={cancelRoute}>
          Cancel
        </Button>
        <Button $type="accept" form={ID.FORM}>
          Submit
        </Button>
      </Flex>
    </FooterBar>
  )
}
