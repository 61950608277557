export type DateFilter = {
  date: string
  timezone: string
}

export const redactColumns = [
  { label: 'Company', id: 'employerName' },
  { label: 'Job Title', id: 'jobTitle' },
  { label: 'Contact Name', id: 'targetName' },
  { label: 'Contact Email', id: 'targetEmail' },
  { label: 'Contact Title', id: 'targetTitle' },
  { label: 'Contact LinkedIn', id: 'targetLinkedinLink' },
  { label: 'Huntr Link', id: 'huntrLink' },
  { label: 'Enrichement By', id: 'enricher' },
]
