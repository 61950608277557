import React from 'react'
import Message from './Message'

type Props = {
  message: React.ReactNode
}

export default function RefreshMessage({ message }: Props): JSX.Element {
  return (
    <Message
      vertical
      message={
        <>
          {message}, please&nbsp;
          <a href={window.location.href}>refresh</a>
        </>
      }
    />
  )
}
