import { gql } from '@apollo/client'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactSetState } from 'types'
import ROUTE_PATHS from 'utils/routePaths'
import { DEFAULT_STATE, ID } from './DossierSubmission.constants'
import { State } from './DossierSubmission.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { GreyBackground } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  setState: ReactSetState<State>
  requestId: string
  queue: string[]
  nextRouteUrl: string | null
}

function DossierSubmissionFooter({
  setState,
  requestId,
  nextRouteUrl,
  queue,
}: Props): React.ReactElement {
  const history = useHistory()

  // derived state
  const placeInQueue = queue.indexOf(requestId) + 1
  const queueLabel = `${placeInQueue} of ${queue.length}`

  /**
   * handles skip button bein clicked, reset state and push to next item or home
   */
  function handleSkipClick() {
    if (!nextRouteUrl) {
      toast('End of queue')
    }

    setState(DEFAULT_STATE)

    history.push(nextRouteUrl || ROUTE_PATHS.HOME)
  }

  return (
    <GreyBackground as={Padding} vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
          {/* <RejectionButton<SourcingRejectionReason>
            rejectionOptions={rejectionOptions}
            disabled={hasSubmission}
          /> */}
          <Button $type="accept" form={ID.FORM}>
            Submit
          </Button>
        </HList>
      </Flex>
    </GreyBackground>
  )
}

DossierSubmissionFooter.fragments = {
  DossierSubmissionFooterInfo: gql`
    fragment DossierSubmissionFooterInfo on DossierRequest {
      id
    }
  `,
}

export default DossierSubmissionFooter
