import dayjs from 'dayjs'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { GLOBAL_COPY } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import { TimelineEvent } from 'views/ClientJobHistory/ClientJobHistory.types'
import Icon from 'components/Icon/Icon'
import { JobCard, LogoContainer, FakeAvatar, LogoImage } from './ClientJobs.styles'
import { ClientJob } from './ClientJobs.types'
import Container from 'components/Container'
import Flex from 'components/Flex'
import { WidthContainer } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type RowProps = {
  clientId: string
  clientJob: ClientJob
  taglineRequestId?: string
}

export default function ClientJobsRow({
  clientJob,
  clientId,
  taglineRequestId,
}: RowProps): React.ReactElement {
  const searchParams = taglineRequestId
    ? createSearchParams({ taglineRequestId })
    : createSearchParams({ sourcedJobId: clientJob.id })

  return (
    <div>
      <Container>
        <Link
          to={`${generatePath(ROUTE_PATHS.CLIENT_JOB_HISTORY, {
            clientId: clientId,
          })}${searchParams ? `?${searchParams}` : ''}`}
        >
          <JobCard>
            <Padding vertical={3} horizontal={3}>
              <Flex justify="space-between">
                <Flex align="center">
                  {clientJob.companyLogoUrl ? (
                    <LogoContainer>
                      <LogoImage
                        src={clientJob.companyLogoUrl}
                        alt={clientJob.employerName ?? 'company logo'}
                      />
                    </LogoContainer>
                  ) : (
                    <FakeAvatar>
                      <Icon
                        name="FreeAgencyFlag"
                        height={48}
                        width={48}
                        primaryFill="faGrey2"
                      />
                    </FakeAvatar>
                  )}
                  <Padding left={1.5}>
                    <Txt size={16} bold>
                      {clientJob.employerName ?? GLOBAL_COPY.EN_DASH}
                    </Txt>
                    <Padding top={1}>
                      <Txt size={12} color="faGrey4">
                        {clientJob.title ?? GLOBAL_COPY.EN_DASH}
                      </Txt>
                    </Padding>
                  </Padding>
                </Flex>
                <Flex align="center">
                  <Padding right={3}>
                    <VList size={0.5}>
                      {clientJob.recentTimelineEvents?.map((event) => (
                        <RecentEventRow key={event.id} event={event} />
                      ))}
                    </VList>
                  </Padding>
                  <Icon name="ArrowRight" height={18} width={18} />
                </Flex>
              </Flex>
            </Padding>
          </JobCard>
        </Link>
      </Container>
    </div>
  )
}

function RecentEventRow({ event }: { event: TimelineEvent }) {
  return (
    <Flex align="center">
      <Padding right={2} inline>
        <WidthContainer width={100}>
          <Txt size={12} color="faGrey4" alignment="right">
            {dayjs(event.createdAt).fromNow()}
          </Txt>
        </WidthContainer>
      </Padding>
      <WidthContainer width={160}>
        <Txt size={14}>{event.type}</Txt>
      </WidthContainer>
    </Flex>
  )
}
