import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { Rating } from 'types'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import StarRating from 'components/StarRating/StarRating'
import { CompanySourcedJobColumns } from '../CompanyDetail.constants'
import { Company, SourcedJob } from '../CompanyDetail.types'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'
import {
  GetCompanySourcedJobsQuery,
  GetCompanySourcedJobsQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

const normalizeData = (
  data: NonNullable<GetCompanySourcedJobsQuery['company']>
): SourcedJob[] => {
  return getNodes<SourcedJob>(data.sourcedJobs)
    .map((item) => ({ ...item, createdAtDayjs: dayjs(item.createdAt) }))
    .sort((a, b) => sortByProp(a, b, 'createdAtDayjs'))
}

export default function CompanySourcedJobs({ company }: Props): React.ReactElement {
  const { data, error, loading } = useQuery<
    GetCompanySourcedJobsQuery,
    GetCompanySourcedJobsQueryVariables
  >(GET_COMPANY_SOURCED_JOBS, {
    variables: { id: company.id },
  })

  const items = data?.company ? normalizeData(data.company) : null

  return (
    <FullWidthTable
      headers={Object.values(CompanySourcedJobColumns)}
      isError={Boolean(error)}
      isLoading={loading}
      isEmpty={Boolean(items && !items.length)}
      emptyMessage={`Could not find any sourced jobs`}
      loadingMessage={`Loading sourced jobs...`}
      errorMessage={`There was an error fetching the sourced jobs`}
    >
      {items?.map((item) => (
        <CompanyDetailSourcedJobRow key={item.id} item={item} />
      ))}
    </FullWidthTable>
  )
}

type SourcedJobProps = { item: SourcedJob }

function CompanyDetailSourcedJobRow({ item }: SourcedJobProps): React.ReactElement {
  const review = item.reviews[0]
  return (
    <BodyRow>
      <BodyData>
        <Txt size={14} as="span" noWrap>
          <ExternalLink url={item.url}>{item.title}</ExternalLink>
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {review ? (
            <StarRating disabled rating={review.rating as Rating} iconSize={16} />
          ) : (
            <Txt size={14} as="span">
              No Review
            </Txt>
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {dayjs(item.createdAt).format(DATE_FORMAT.FRIENDLY_DATE_SHORT_MONTH)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {item.datePosted
            ? dayjs(item.datePosted).format(DATE_FORMAT.FRIENDLY_DATE_SHORT_MONTH)
            : EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_COMPANY_SOURCED_JOBS = gql`
  query GetCompanySourcedJobs($id: ID!) {
    company(id: $id) {
      id
      sourcedJobs {
        edges {
          node {
            id
            createdAt
            title
            datePosted
            url
            reviews {
              id
              rating
            }
          }
        }
      }
    }
  }
`
