import { gql } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import {
  EMPTY_CELL_PLACEHOLEDER,
  TaglineRejecterMap,
  TaglineRejectionReasonMap,
  TAGLINE_REQUEST_STATUS,
} from 'utils/constants'
import { TaglineRejectionReason } from 'utils/enums'
import { getTimeFromNow } from 'utils/helpers/time'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { maybeGetRejectionReasonInfo } from './TaglineStatus.helpers'
import { TaglineRequest, WorkTypes } from './TaglineStatus.types'
import Txt from 'components/Txt'

type Props = {
  request: TaglineRequest
  workType: string
}

function TaglineStatusRow({ request, workType }: Props): JSX.Element {
  const writerName = request.writerSubmissions[0]?.createdBy?.name || '-'
  const submissionId = request.writerSubmissions[0]?.id
  const rejectionInfo = maybeGetRejectionReasonInfo(request)
  const rejectionStage = rejectionInfo?.stage
    ? TaglineRejecterMap[rejectionInfo.stage]
    : EMPTY_CELL_PLACEHOLEDER
  const rejectionReason = rejectionInfo?.reason
    ? TaglineRejectionReasonMap[rejectionInfo.reason as TaglineRejectionReason]
    : ''
  const taglineRequestStatus = TAGLINE_REQUEST_STATUS[request.status]
  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {request.jobTitle}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {request.employerName}
        </Txt>
      </BodyData>
      <BodyData>
        {workType === WorkTypes.REVIEWED_REJECTED && submissionId ? (
          <Link to={generatePath(ROUTE_PATHS.REVIEW_FEEDBACK, { submissionId })}>
            <Txt hoverColor="black" underline size={14} lineHeight={1.3} noWrap>
              {taglineRequestStatus} {rejectionReason && `(${rejectionReason})`}
            </Txt>
          </Link>
        ) : (
          <Txt as="span" size={14} lineHeight={1.3} noWrap>
            {taglineRequestStatus} {rejectionReason && `(${rejectionReason})`}
          </Txt>
        )}
      </BodyData>
      {workType === WorkTypes.REVIEWED_REJECTED && (
        <BodyData>
          <Txt as="span" size={14}>
            {rejectionStage}
          </Txt>
        </BodyData>
      )}
      <BodyData>
        <Txt as="span" size={14}>
          {request.due}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {getTimeFromNow(request.lastStatusChangeDate)}
        </Txt>
      </BodyData>
      {workType === WorkTypes.REVIEWED_REJECTED && (
        <BodyData>
          <Txt as="span" size={14}>
            {writerName}
          </Txt>
        </BodyData>
      )}
    </BodyRow>
  )
}

TaglineStatusRow.fragments = {
  workRowInfo: gql`
    fragment TaglineStatusRowInfo on TaglineRequest {
      id
      jobTitle
      employerName
      status
      due
      createdAt
      assignedAt
      writerSubmissions {
        id
        createdAt
        createdBy {
          id
          name
        }
      }
      talentAgentReviews {
        id
        createdAt
        taglineRejectionReason
      }
      talentPartnerReviews {
        id
        createdAt
        taglineRejectionReason
      }
      taglineRequestRejections {
        id
        rejecterRole
        rejectionReason
      }
    }
  `,
}

export default TaglineStatusRow
