import React from 'react'
import { useNavContext } from 'context/nav'
import Shelf from 'components/Shelf/Shelf'
import { StyledMain } from './PrivateLayout.styles'

type Props = { children: React.ReactNode }

export default function PrivateLayout({ children }: Props): JSX.Element {
  const { status } = useNavContext()

  return (
    <>
      <Shelf />
      <StyledMain status={status}>{children}</StyledMain>
    </>
  )
}
