import { DropdownOption } from 'types'
import { buildJobDropdownOptions } from './buildDropdownOptions'
import getNodes from './getNodes'
import { GetClientsInterviewCyclesQuery } from 'generated/graphql'

type InterviewCycleConnection = NonNullable<
  GetClientsInterviewCyclesQuery['interviewCycles']
>

export function buildInterviewCycleOptions(
  connection: InterviewCycleConnection | null | undefined
): DropdownOption[] {
  const interviewCycles = (connection && getNodes(connection)) ?? []
  const options = buildJobDropdownOptions(interviewCycles)
  return options
}
