import React, { useState } from 'react'
import styled from 'styled-components/macro'
import { Rating } from 'types'
import Icon from 'components/Icon/Icon'

type Props = {
  disabled?: boolean
  onValueChange?: (value: Rating) => void
  rating?: Rating
  iconSize?: number
}

type StarButtonProps = {
  disabled?: boolean
}

const StarButton = styled.button<StarButtonProps>`
  ${({ disabled }) => disabled && 'cursor: unset;'}
  & + & {
    margin-left: ${({ theme }) => theme.rhythm(0.5)};
  }
`
const StarRatingBox = styled.div`
  white-space: nowrap;
`

export default function StarRating({
  disabled,
  iconSize = 24,
  rating,
  onValueChange,
}: Props): JSX.Element {
  const starArray: Rating[] = [1, 2, 3, 4, 5]
  const [hoveredStar, setHoveredStar] = useState<Rating>(null)
  return (
    <StarRatingBox onMouseLeave={() => setHoveredStar(null)}>
      {starArray.map((value) => {
        const isFilled = rating && value && rating >= value
        const isHovered = hoveredStar && value && hoveredStar >= value
        const filledColor = disabled ? 'faGrey4' : 'purple'

        return (
          <StarButton
            key={value}
            onClick={() => onValueChange?.(value)}
            onMouseEnter={() => setHoveredStar(value)}
            disabled={disabled}
            type="button"
          >
            <Icon
              height={iconSize}
              width={iconSize}
              name="Star"
              primaryFill={
                isFilled ? filledColor : isHovered ? 'lightPurple' : 'faGrey2'
              }
            />
          </StarButton>
        )
      })}
    </StarRatingBox>
  )
}
