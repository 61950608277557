import { ColumnMap } from 'types'

export const HEADERS: ColumnMap = {
  detailView: {
    label: '',
    id: 'detailView',
    collapse: true,
  },

  dueDate: {
    label: 'Due',
    id: 'dueDate',
  },
  freeAgent: {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  type: {
    label: 'Type',
    id: 'type',
  },
  assignees: {
    label: 'Red Team Members',
    id: 'assignees',
  },
  scheduled: {
    label: 'Status',
    id: 'status',
    collapse: true,
  },
}

export const ROLE_FILTER = {
  isCoordinator: true,
  isTalentAgent: true,
  isTalentPartner: true,
}

export const COPY = {
  modifySuccess: 'Interview prep request updated',
  modifyError: 'There was an error updating the interview prep request',
  confirmDelete:
    "Are you sure you want to delete this interview prep request? This action can't be undone",
  voidSuccess: 'Interview prep request deleted',
  voidError: 'There was an error deleting the interview prep request',
}

export const ID = {
  FORM: 'modifyInterviewPrepRequestForm',
}
