import styled from 'styled-components/macro'

export const TimelineContainer = styled.div`
  position: relative;
  padding-bottom: ${({ theme }) => theme.rhythm(8)};
  &::after {
    content: '';
    position: absolute;
    width: ${({ theme }) => theme.rhythm(0.25)};
    background-color: ${({ theme }) => theme.color.faGrey2};
    top: ${({ theme }) => theme.rhythm(6)};
    bottom: ${({ theme }) => theme.toRems(150)};
    left: ${({ theme }) => theme.toRems(1)};
  }
`

/* TODO (ericlus):  Refactor to make line end where the last bullet is */

export const TimelineEventContainer = styled.div`
  padding: ${({ theme }) => theme.rhythm(2)} ${({ theme }) => theme.toRems(30)};
  position: relative;
  width: ${({ theme }) => theme.rhythm(50)};
  &::after {
    content: '';
    position: absolute;
    width: ${({ theme }) => theme.rhythm(1.5)};
    height: ${({ theme }) => theme.rhythm(1.5)};
    background-color: ${({ theme }) => theme.color.faGrey2};
    border-radius: 50%;
    z-index: 1;
    top: ${({ theme }) => theme.toRems(34)};
    left: ${({ theme }) => theme.rhythm(-0.5)};
    outline: 0.8rem solid white;
  }
`

export const TimelineEventCard = styled.div`
  transform: translateX(0px);
  transition: transform 0.3s ease;
  &:hover {
    transform: translateX(0.6rem);
  }
`
