import React from 'react'
import { BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { Application } from './AssignApplicaitons.types'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  item: Application
}

export default function AssignApplicationsOverrideColumns({
  item,
}: Props): React.ReactElement {
  return (
    <>
      <BodyData>
        <Flex align="center">
          <Txt size={14}>{item.jobTitle}</Txt>
          {item.isImportant && (
            <Padding left={1}>
              <Icon
                name="Star"
                height={14}
                width={14}
                primaryFill="faBlue"
                display="block"
              />
            </Padding>
          )}
        </Flex>
      </BodyData>
      <BodyData>
        <Txt size={14}>{item.employerName}</Txt>
      </BodyData>
    </>
  )
}
