import dayjs, { Dayjs } from 'dayjs'
import React from 'react'
import { DATE_FORMAT, EMPTY_INPUT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import useFormState from 'views/ClientTracker/useFormState'
import WriterTaglineStatsTable from './WriterTaglineStatsTable'
import Container from 'components/Container'
import { DatePicker } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function WriterTaglineStats(): React.ReactElement {
  const { formState, setFormField } = useFormState<{ fromDate: Dayjs | null }>({
    fromDate: null,
  })

  return (
    <ViewBox>
      <Padding top={6}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            My Tagline Statistics
          </Txt>
          <Padding top={4}>
            <DatePicker
              label="Date Since"
              onValueChange={(value) =>
                setFormField('fromDate', value ? dayjs(value) : null)
              }
              name="fromDate"
              value={
                formState.fromDate
                  ? formState.fromDate.format(DATE_FORMAT.ONLY_DATE)
                  : EMPTY_INPUT
              }
            />
          </Padding>
        </Container>
        <Padding top={6}>
          <WriterTaglineStatsTable fromDate={formState.fromDate} />
        </Padding>
      </Padding>
    </ViewBox>
  )
}

WriterTaglineStats.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.WRITER_TAGLINE_STATS}
    key={ROUTE_PATHS.WRITER_TAGLINE_STATS}
  >
    <WriterTaglineStats />
  </PrivateRoute>,
]
