import React from 'react'
import { ReactSetState } from 'types'
import { State } from './EnrichmentSubmission.types'
import { TextArea, TextInput } from 'components/Inputs'
import VList from 'components/VList'

type Props = {
  state: State
  setState: ReactSetState<State>
}

export default function EnrichmentRequestForm({
  state,
  setState,
}: Props): React.ReactElement {
  function handleInputChange(value: string, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  return (
    <form id="enrichmentSubmission">
      <VList size={3}>
        <TextInput
          value={state.targetLinkedinUrl}
          onValueChange={(value: string) =>
            handleInputChange(value, 'targetLinkedinUrl')
          }
          label="LinkedIn"
          name="linkedinUrl"
          placeholder="https://linkedin.com/in/"
          fullWidth
        />
        <TextInput
          value={state.targetName}
          onValueChange={(value: string) => handleInputChange(value, 'targetName')}
          label="Name"
          name="name"
          fullWidth
        />
        <TextInput
          value={state.targetTitle}
          onValueChange={(value: string) => handleInputChange(value, 'targetTitle')}
          label="Person's Title"
          name="title"
          fullWidth
        />
        <TextInput
          value={state.targetEmail}
          onValueChange={(value: string) => handleInputChange(value, 'targetEmail')}
          label="Email"
          name="email"
          type="email"
          fullWidth
        />
        <TextArea
          value={state.notes}
          onValueChange={(value: string) => handleInputChange(value, 'notes')}
          label="Notes from Enricher"
          name="notes"
          height={120}
          fullWidth
        />
      </VList>
    </form>
  )
}
