import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useAuthContext } from 'context/auth'
import AgentActivitiesNav from 'components/AgentActivitiesNav/AgentActivitiesNav'
import WriterActivitiesNav from 'components/WriterActivitiesNav/WriterActivitiesNav'
import ActivityItem from './ActivityItem'
import { Activity, ActivityLogEdge } from './ActivityLogTable.types'
import Button from 'components/Button'
import HList from 'components/HList'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import { ActivityLogQuery } from 'generated/graphql'

type Props = {
  first?: number
  isPaginated?: boolean
}

const INITIAL_SIZE = 10
const BATCH_SIZE = 10

export default function ActivityLogTable({
  first = INITIAL_SIZE,
  isPaginated = false,
}: Props): JSX.Element {
  /**
   * request the next set of activity logs
   */
  function handleMoreClick() {
    void fetchMore({
      variables: {
        first: BATCH_SIZE,
        after: pageInfo?.endCursor,
      },
    })
  }

  const {
    userSession: {
      user: { isWriter },
    },
  } = useAuthContext()
  const { data, loading, fetchMore } = useQuery<ActivityLogQuery>(GET_ACTIVITY_LOG, {
    variables: { first },
  })
  const { edges, pageInfo } = data?.viewer?.activityLogs || {}
  const showLoadMoreButton = pageInfo?.hasNextPage && isPaginated

  // Because of the TypePolicy allowing for pagination (client.ts), we want to make sure that if
  // this component is not paginated we only render items equal to first
  const activityEdges = isPaginated ? edges : edges?.slice(0, first)
  const activityItems = activityEdges?.map((edge: ActivityLogEdge) => edge.node)

  if (loading) {
    return <Message message="Loading activity log..." />
  }

  return (
    <>
      <HList size={2}>
        {isWriter ? <WriterActivitiesNav /> : <AgentActivitiesNav />}
      </HList>
      <Padding top={2}>
        <VList size={1.5}>
          {activityItems?.length ? (
            activityItems.map((activity: Activity) => (
              <ActivityItem activity={activity} key={activity.id} />
            ))
          ) : (
            <Txt>No Activities Found</Txt>
          )}
        </VList>
      </Padding>
      {showLoadMoreButton && (
        <Padding top={3}>
          <Button onClick={handleMoreClick}>Load More</Button>
        </Padding>
      )}
    </>
  )
}

const GET_ACTIVITY_LOG = gql`
  query ActivityLog($first: Int!, $after: String) {
    viewer {
      id
      activityLogs(first: $first, after: $after) {
        edges {
          node {
            id
            ...ActivityItemInfo
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ActivityItem.fragments.activityItemInfo}
`
