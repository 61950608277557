import React from 'react'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { TableRowData } from './ReviewEnrichment.types'
import Txt from 'components/Txt'

type Props = {
  rowData: TableRowData
}

export default function ReviewEnrichmentHighlightedRow({
  rowData,
}: Props): React.ReactElement {
  return (
    <BodyRow color="blueHighlight">
      <BodyData colSpan={100}>
        <Txt as="span" size={14} bold>
          {rowData.clientName}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
