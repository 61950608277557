import styled from 'styled-components/macro'

// TODO (matthewalbrecht) use Autosuggest component for this styled component, component params make this not straight forward
// https://github.com/styled-components/styled-components/issues/1803
export const AutoSuggestStyles = styled.div`
  /* stylelint-disable block-no-empty */
  .react-autosuggest__container {
  }
  .react-autosuggest__container--open {
  }
  .react-autosuggest__input {
    border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: ${({ theme }) => theme.rhythm(1)};
    color: ${({ theme }) => theme.color.text};
    width: ${({ theme }) => theme.toRems(350)};
  }
  .react-autosuggest__input--open {
  }
  .react-autosuggest__input--focused {
  }
  .react-autosuggest__suggestions-container {
    max-height: ${({ theme }) => theme.toRems(300)};
    overflow: auto;
  }
  .react-autosuggest__suggestions-container--open {
  }
  .react-autosuggest__suggestions-list {
  }
  .react-autosuggest__suggestion {
    padding: ${({ theme }) => theme.rhythm(1)} ${({ theme }) => theme.rhythm(1)};
    border-radius: ${({ theme }) => theme.borderRadius};
    &:hover {
      background-color: ${({ theme }) => theme.color.faGrey1};
    }
  }
  .react-autosuggest__suggestion--first {
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
  .react-autosuggest__section-container {
  }
  .react-autosuggest__section-container--first {
  }
  .react-autosuggest__section-title {
  }
  /* stylelint-enable block-no-empty */
`
