import React, { useState } from 'react'
import Icon from 'components/Icon/Icon'
import AddAndEditUser from './AddAndEditUser'
import { User } from './Users.types'

type Props = {
  user: User
}

export default function UserDetailButton({ user }: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function handleClose() {
    setIsOpen(false)
  }

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <Icon
          name="Expand"
          width={14}
          height={14}
          primaryFill="text"
          primaryFillHover="black"
          display="block"
        />
      </button>
      <AddAndEditUser handleClose={handleClose} isOpen={isOpen} user={user} />
    </>
  )
}
