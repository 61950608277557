import React, { Fragment } from 'react'
import {
  TableBox,
  TableWrapper,
  Table,
  HeaderRow,
  HeaderData,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { COLUMNS } from './TaglineStatus.constants'
import { Filter, FreeAgent, TaglineRequest, WorkTypes } from './TaglineStatus.types'
import TaglineStatusHighlightedRow from './TaglineStatusHighlightedRow'
import TaglineStatusRow from './TaglineStatusRow'
import Txt from 'components/Txt'

type Props = {
  filter: Filter
  filteredFreeAgents: FreeAgent[]
}

export default function TaglineStatusTable({
  filter,
  filteredFreeAgents,
}: Props): React.ReactElement {
  const isCurrent = filter.workType === WorkTypes.CURRENT
  // hides some columns when the 'current' filter is selected
  const columns = COLUMNS.filter(
    ({ hideOnCurrent }) => !(hideOnCurrent && isCurrent)
  )

  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {columns.map(({ label, id }) => (
                <HeaderData key={id}>
                  <Txt size={12} bold>
                    {label}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {filteredFreeAgents.map((freeAgent) => {
              return (
                <Fragment key={freeAgent.id}>
                  <TaglineStatusHighlightedRow freeAgent={freeAgent} />
                  {freeAgent.taglineRequests.map((request: TaglineRequest) => (
                    <TaglineStatusRow
                      key={request.id}
                      request={request}
                      workType={filter.workType}
                    />
                  ))}
                </Fragment>
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </TableBox>
  )
}
