import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import Icon from 'components/Icon/Icon'
import { CACHE_STRINGS, COPY } from './AssignDossiers.constants'
import {
  VoidDossierRequestByCoordinatorMutation,
  VoidDossierRequestByCoordinatorMutationVariables,
} from 'generated/graphql'

type Props = {
  itemId: string
}

export default function AssignDossiersDeleteButton({ itemId }: Props): JSX.Element {
  const [voidDossierRequest, { loading }] = useMutation<
    VoidDossierRequestByCoordinatorMutation,
    VoidDossierRequestByCoordinatorMutationVariables
  >(VOID_DOSSIER_REQUEST, {
    update(cache) {
      removeItemFromCacheConnections<VoidDossierRequestByCoordinatorMutation>(
        cache,
        itemId,
        CACHE_STRINGS
      )
    },
    onCompleted() {
      toast.success(COPY.VOID_SUCCESS)
    },
    onError() {
      toast.error(COPY.VOID_ERROR)
    },
  })

  /**
   * execute the mutation for voiding the dossierRequest
   */
  function handleClick() {
    if (window.confirm(COPY.CONFIRM_VOID)) {
      void voidDossierRequest({
        variables: {
          VoidDossierRequestByCoordinatorInput: {
            dossierRequestId: itemId,
          },
        },
      })
    }
  }

  return (
    <button onClick={handleClick} disabled={loading}>
      <Icon name="Trash" width={16} height={16} primaryFill="faGrey3" />
    </button>
  )
}

/**
 * Used by coordinator to void (soft delete) a dossier request
 */
const VOID_DOSSIER_REQUEST = gql`
  mutation voidDossierRequestByCoordinator(
    $VoidDossierRequestByCoordinatorInput: VoidDossierRequestByCoordinatorInput!
  ) {
    voidDossierRequestByCoordinator(input: $VoidDossierRequestByCoordinatorInput) {
      dossierRequest {
        id
      }
    }
  }
`
