export const COLUMNS = [
  {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  {
    label: 'Talent Agent',
    id: 'talentAgent',
  },
  {
    label: 'Group',
    id: 'group',
  },
  {
    label: '# Jobs',
    id: 'numJobs',
  },
  {
    label: 'Assignee',
    id: 'assignee',
  },
]
