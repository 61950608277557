import { gql } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { useAuthContext } from 'context/auth'
import { INITIAL, ROLE_GROUPS } from 'utils/constants'
import checkRole from 'utils/helpers/checkRole'
import { maybeGetNodes } from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { AgentTableInfo } from 'components/AgentTable/AgentTable.types'
import DueDateTableItem from 'components/DueDateTableItem/DueDateTableItem'
import Icon from 'components/Icon/Icon'
import { Tooltip } from 'components/WrappedTooltip/WrappedTooltip'
import TableBodyItem from '../Table/TableBodyItem'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  tableInfo: AgentTableInfo
  hideQueueButton?: boolean
}

function TableBodyRow({ tableInfo }: Props): JSX.Element {
  const {
    userSession: { user },
  } = useAuthContext()

  const frReviewNodes = maybeGetNodes(tableInfo.pendingFrReviewTaglines)

  return (
    <tr key={tableInfo.clientName}>
      <TableBodyItem first>
        {checkRole(ROLE_GROUPS.CORE_TEAM) ? (
          <Link
            to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
              clientId: tableInfo.id,
            })}
          >
            <Txt as="span" underline hoverColor="black" size={14}>
              {tableInfo.clientName}
            </Txt>
          </Link>
        ) : (
          <Txt size={14} as="span">
            {tableInfo.clientName}
          </Txt>
        )}
      </TableBodyItem>
      <TableBodyItem>
        {tableInfo.sourcedJobsCount > 0 ? (
          <Link
            to={`${generatePath(ROUTE_PATHS.REVIEW_SOURCED_JOBS_QUEUE, {
              freeAgentId: tableInfo.id,
              sourcedJobId: INITIAL,
            })}`}
          >
            <Txt as="span" underline hoverColor="black" size={14}>
              {tableInfo.sourcedJobsCount}
            </Txt>
          </Link>
        ) : (
          0
        )}
      </TableBodyItem>
      <TableBodyItem>
        <ReviewRowItem
          count={tableInfo.pendingWritingTaglinesCount}
          showQueueButton={false}
          queuePath={''}
          showImportantIcon={
            tableInfo.importantPendingWritingTaglinesCount > 0 ||
            tableInfo.importantUnassignedTaglinesCount > 0
          }
        />
      </TableBodyItem>
      <TableBodyItem>
        <ReviewRowItem
          count={tableInfo.pendingTaReviewTaglinesCount}
          showQueueButton={user.isTalentAgent || user.isTalentPartner || user.isTee}
          queuePath={generatePath(ROUTE_PATHS.AGENT_QUEUE_BY_FREE_AGENT, {
            freeAgentId: tableInfo.id,
            requestId: INITIAL,
          })}
          showImportantIcon={Boolean(
            tableInfo.importantPendingTaReviewTaglinesCount
          )}
        />
      </TableBodyItem>
      <TableBodyItem>
        <ReviewRowItem
          count={tableInfo.pendingFrReviewTaglinesCount}
          showQueueButton={user.isTalentPartner || user.isTee}
          queuePath={generatePath(ROUTE_PATHS.PARTNER_QUEUE_BY_FREE_AGENT, {
            freeAgentId: tableInfo.id,
            requestId: INITIAL,
          })}
          showImportantIcon={Boolean(
            tableInfo.importantPendingFrReviewTaglinesCount
          )}
        />
        {frReviewNodes && frReviewNodes.length > 0 && (
          <Padding top={1.5}>
            <DueDateTableItem items={frReviewNodes} />
          </Padding>
        )}
      </TableBodyItem>
      <TableBodyItem>{tableInfo.applicationsCount}</TableBodyItem>
      <TableBodyItem>{tableInfo.interviewCyclesCount}</TableBodyItem>
    </tr>
  )
}

const CLIENT_INFO_FRAGMENT = gql`
  fragment ClientInfoForDashboardTable on Client {
    id
    name
    unassignedTaglines: taglineRequests(status: UNASSIGNED) {
      totalCount
    }
    pendingWritingTaglines: taglineRequests(status: PENDING_WRITING) {
      totalCount
    }
    pendingTaReviewTaglines: taglineRequests(status: PENDING_TA_REVIEW) {
      totalCount
    }
    importantUnassignedTaglines: taglineRequests(
      status: UNASSIGNED
      isImportant: true
    ) {
      totalCount
    }
    importantPendingWritingTaglines: taglineRequests(
      status: PENDING_WRITING
      isImportant: true
    ) {
      totalCount
    }
    importantPendingTaReviewTaglines: taglineRequests(
      status: PENDING_TA_REVIEW
      isImportant: true
    ) {
      totalCount
    }
    importantPendingFrReviewTaglines: taglineRequests(
      status: PENDING_TP_REVIEW
      isImportant: true
    ) {
      totalCount
    }
    sourcedJobs(isReviewed: false, isRedacted: false) {
      totalCount
    }
    applications(hasSubmission: false, inAirtable: false) {
      totalCount
    }
    interviewCycles(active: true) {
      totalCount
    }
  }
`

TableBodyRow.fragments = {
  clientInfoTA: gql`
    fragment ClientInfoForDashboardTableTA on Client {
      ...ClientInfoForDashboardTable
      pendingFrReviewTaglines: taglineRequests(status: PENDING_TP_REVIEW) {
        totalCount
        edges @include(if: $withFrInfo) {
          node {
            ...TaglineRequestInfoForDueDateTableItem
          }
        }
      }
    }
    ${CLIENT_INFO_FRAGMENT}
    ${DueDateTableItem.fragments.taglineRequestInfo}
  `,
}

export default TableBodyRow

type ReviewRowItemProps = {
  count: number
  showQueueButton: boolean
  queuePath: string
  showImportantIcon: boolean
}

function ReviewRowItem({
  count,
  showQueueButton,
  queuePath,
  showImportantIcon,
}: ReviewRowItemProps): React.ReactElement {
  const importantIcon = (
    <Tooltip label={'Contains A-Track tagline'}>
      <Padding left={1} inline>
        <Icon
          name="Star"
          height={14}
          width={14}
          primaryFill="link"
          nudge={{ down: 0.3 }}
        />
      </Padding>
    </Tooltip>
  )

  if (showQueueButton && count) {
    return (
      <Link to={queuePath}>
        <Txt size={14} as="span" color={count ? 'link' : 'text'}>
          {count}
        </Txt>
        <Padding left={1} inline>
          <Icon
            name="Queue"
            height={14}
            width={14}
            primaryFill="link"
            nudge={{ down: 0.3 }}
          />
        </Padding>
        {showImportantIcon && importantIcon}
      </Link>
    )
  }
  return (
    <>
      {count}
      {showImportantIcon && importantIcon}
    </>
  )
}
