import React from 'react'
import { Link } from 'react-router-dom'
import { QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import Button from 'components/Button'
import Flex from 'components/Flex'
import { FooterBar } from 'components/MiscStyles'

type Props = {
  isSubmitting: boolean
  isEditing: boolean
}

export default function AddAndEditDossierRequestFooter({
  isSubmitting,
  isEditing,
}: Props): React.ReactElement {
  const queryString = useQueryString()
  const cancelRoute = queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME

  return (
    <FooterBar>
      <Flex justify="space-between">
        <Button as={Link} to={cancelRoute} $type="secondary">
          Cancel
        </Button>
        {/* TODO: (mattthewalbrecht) disabled submit button if no changes have been made yet */}
        <Button
          $type="accept"
          as="input"
          disabled={isSubmitting}
          type="submit"
          form="newDossierRequest"
          value={isEditing ? 'Save' : 'Submit'}
        />
      </Flex>
    </FooterBar>
  )
}
