import React, { useState } from 'react'
import { EMPTY_INPUT } from 'utils/constants'
import { TabLabel } from 'components/AgentTable/AgentTable.constants'
import TabbedTableNav from 'components/TabbedTableNav/TabbedTableNav'
import TabbedTableTalentAgentDropdown from 'components/TabbedTableTalentAgentDropdown/TabbedTableTalentAgentDropdown'
import Flex from 'components/Flex'
import Padding from 'components/Padding'

type Props = {
  options: { TableComponent: React.ElementType; tabLabel: string }[]
  initialOptionIndex?: number
}

export default function DashboardTabbedTableWrapper({
  options,
  initialOptionIndex = 0,
}: Props): JSX.Element {
  const [currentTab, setCurrentTab] = useState(initialOptionIndex)
  const [talentAgentId, setTalentAgentId] = useState<string>(EMPTY_INPUT)
  // TODO: (matthewalbrecht) render all tables but hide non-active ones with css
  const Table = options[currentTab]?.TableComponent
  const currentTabLabel = options[currentTab]?.tabLabel
  return (
    <div>
      <Padding bottom={2}>
        <Flex align="center" justify="flex-start">
          <TabbedTableNav
            labels={options.map((option) => option.tabLabel)}
            currentTab={currentTab}
            setCurrentTab={setCurrentTab}
          />
          {currentTabLabel === TabLabel.BY_TALENT_AGENT && (
            <TabbedTableTalentAgentDropdown
              talentAgentId={talentAgentId}
              setTalentAgentId={setTalentAgentId}
            />
          )}
        </Flex>
      </Padding>
      <Flex align="flex-start" justify="flex-start">
        {Table && <Table talentAgentId={talentAgentId} />}
      </Flex>
    </div>
  )
}
