import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'

const AddAndEditTaglineRequestFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  isEditing: boolean
  isSubmitting: boolean
}

export default function AddAndEditTaglineRequestFooter({
  isEditing,
  isSubmitting,
}: Props): JSX.Element {
  const queryString = useQueryString()
  const cancelRoute = queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME

  return (
    <AddAndEditTaglineRequestFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={cancelRoute} $type="secondary">
          Cancel
        </Button>
        {/* TODO: (mattthewalbrecht) disabled submit button if no changes have been made yet */}
        <Button
          $type="accept"
          as="input"
          disabled={isSubmitting}
          type="submit"
          form="newTaglineRequest"
          value={isEditing ? 'Save' : 'Submit'}
        />
      </Flex>
    </AddAndEditTaglineRequestFooterBox>
  )
}
