import dayjs from 'dayjs'
import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  SortableEnrichmentSubmission,
  TableRowData,
  EnrichmentSubmissionConnection,
} from './ReviewEnrichment.types'

/**
 * Sorts the enrichment by createdAt date with most recent first
 * Groups them by Free agent and returns
 * @param connection EnrichmentSubmission Connection object
 */
export function groupJobs(
  connection: EnrichmentSubmissionConnection
): TableRowData[] {
  const rawEnrichmentSubmissions = getNodes(connection)
  const sortedEnrichmentSubmissions: SortableEnrichmentSubmission[] =
    rawEnrichmentSubmissions
      .map((enrichment) => ({
        ...enrichment,
        dateToSortBy: dayjs(enrichment.createdAt),
      }))
      .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))

  const groupedEnrichment = sortedEnrichmentSubmissions.reduce(
    (finalObject: KeyValue<TableRowData>, enrichment) => {
      const groupId = enrichment.enrichmentRequest.client.id
      const group = finalObject[groupId]
      if (group) {
        group.enrichmentSubmissions.push(enrichment)
      } else {
        finalObject[groupId] = {
          clientId: groupId,
          clientName: enrichment.enrichmentRequest.client.name,
          enrichmentSubmissions: [enrichment],
        }
      }
      return finalObject
    },
    {}
  )

  const tableData = Object.values(groupedEnrichment)
  return tableData
}
