import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import AddAndEditExpertRequestFooter from './AddAndEditExpertRequestFooter'
import AddAndEditExpertRequestForm from './AddAndEditExpertRequestForm'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function AddAndEditExpertRequest(): React.ReactElement {
  return (
    <ViewBox footer={<AddAndEditExpertRequestFooter />}>
      <Container noMax>
        <Padding top={6}>
          <AddAndEditExpertRequestForm />
        </Padding>
      </Container>
    </ViewBox>
  )
}

AddAndEditExpertRequest.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_EXPERT_REQUEST}
    key={ROUTE_PATHS.NEW_EXPERT_REQUEST}
  >
    <AddAndEditExpertRequest />
  </PrivateRoute>,
]
