import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { EMPTY_INPUT } from 'utils/constants'
import { MimeType } from 'utils/enums'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import useFormState from 'views/ClientTracker/useFormState'
import { TOAST } from './Users.constants'
import { User } from './Users.types'
import Button from 'components/Button'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  PublishTaCalendarMutation,
  PublishTaCalendarMutationVariables,
} from 'generated/graphql'

type State = {
  sharedWithId?: string | null
  talentAgentId?: string | null
}

type Props = {
  handleClose(): void
  isOpen: boolean
  users: User[]
}

export default function AddCalendar({
  handleClose,
  isOpen,
  users,
}: Props): React.ReactElement {
  const { formState, setFormField } = useFormState<State>({})

  const talentAgents = users.filter((user) => user.isTalentAgent)
  const talentAgentOptions = buildDropdownOptions(talentAgents)
  const allUserOptions = buildDropdownOptions(users)
  const clipboard = useCopyClipboard({
    onSuccess() {
      toast.success('Calendar link copied to clipboard')
    },
  })

  const [createCalendar] = useMutation<
    PublishTaCalendarMutation,
    PublishTaCalendarMutationVariables
  >(PUBLISH_TA_CALENDAR, {
    onCompleted(data) {
      clipboard.copy(
        data.publishTACalendar.calendarPublishTa.publishUrl,
        MimeType.Plain
      )
      handleClose()
    },
    onError() {
      toast.error(TOAST.CALENDAR_ERROR)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    void createCalendar({
      variables: {
        input: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          talentAgentId: formState.talentAgentId!,
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          sharedWithId: formState.sharedWithId!,
        },
      },
    })
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={handleClose} aria-label={`Add calander form`}>
      <Padding vertical={4} horizontal={4}>
        <DialogExitButton onClick={handleClose} />
        <Txt as="h2" bold size={24}>
          Create Calander
        </Txt>
        <Padding top={4}>
          <form onSubmit={handleSubmit}>
            <HList size={2}>
              <Dropdown
                label="Talent Agent"
                value={formState.talentAgentId ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  setFormField('talentAgentId', value || null)
                }
                options={talentAgentOptions}
                required
              />
              <Dropdown
                label="Shared With"
                value={formState.sharedWithId ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  setFormField('sharedWithId', value || null)
                }
                options={allUserOptions}
                required
              />
            </HList>
            <Padding top={6}>
              <Flex justify="flex-end">
                <Button type="submit" $type="accept">
                  Publish
                </Button>
              </Flex>
            </Padding>
          </form>
        </Padding>
      </Padding>
    </Dialog>
  )
}

const PUBLISH_TA_CALENDAR = gql`
  mutation PublishTACalendar($input: PublishTACalendarInput!) {
    publishTACalendar(input: $input) {
      calendarPublishTa {
        id
        publishUrl
      }
    }
  }
`
