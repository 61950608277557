import { ApolloCache, gql, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { generatePath, useLocation, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { Maybe } from 'types'
import {
  QS,
  INTERVIEW_CYCLE_SOURCE_MAP,
  UNKNOWN,
  AIRTABLE_OFFER_FORM_URL,
  AIRTABLE_COMP_REPORT_URL,
  InterviewCycleInactiveReasonMap,
} from 'utils/constants'
import { InactiveCycleReasons } from 'utils/enums'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import {
  CreateNewButton,
  CreateNewButtonContainer,
  CreateNewList,
  CreateNewOption,
} from 'views/ClientConfig/ClientConfig.styles'
import { rejectionOptions } from 'views/ClientInterviewCycles/ClientInterviewCycles.constants'
import { RECORD_INACTIVE_INTERVIEW_CYCLE } from 'views/ClientInterviewCycles/ClientInterviewCyclesRow'
import Icon from 'components/Icon/Icon'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import { JobDescriptionDialogButton } from 'components/JobDescriptionDialog/JobDescriptionDialog'
import RejectionDropdownButton from 'components/RejectionButton/RejectionDropdownButton'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import { COPY } from './InterviewCycleDetail.constants'
import { normalizeInterviews } from './InterviewCycleDetail.helpers'
import { InterviewCycle, Params } from './InterviewCycleDetail.types'
import InterviewCycleDetailDossiers from './InterviewCycleDetailDossiers'
import InterviewCycleDetailExpertRequests from './InterviewCycleDetailExpertRequests'
import InterviewCycleDetailInterviewList from './InterviewCycleDetailInterviewList'
import InterviewCycleDetailMetaSection from './InterviewCycleDetailMetaSection'
import InterviewCycleDetailPrepRequests from './InterviewCycleDetailPrepRequests'
import InterviewCycleMetaSection from './InterviewCycleMetaSection'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { SectionHeader } from 'components/MiscStyles'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  GetInterviewCycleByIdQuery,
  GetInterviewCycleByIdQueryVariables,
} from 'generated/graphql'

export default function InterviewCycleDetail(): React.ReactElement {
  const { pathname } = useLocation()
  const [showMetaForm, setShowMetaForm] = useState(false)
  const { interviewCycleId, clientId } = useParams<Params>()
  const { data, loading } = useQuery<
    GetInterviewCycleByIdQuery,
    GetInterviewCycleByIdQueryVariables
  >(GET_INTERVIEW_CYCLE, {
    variables: {
      id: interviewCycleId,
    },
    /* TODO (matthewalbrecht): update cache on request creation instaed of going to network  */
    fetchPolicy: 'cache-and-network',
  })

  const [recordInactiveInterviewCycle] = useMutation(
    RECORD_INACTIVE_INTERVIEW_CYCLE,
    {
      update: updateCacheAfterCloseSuccess,
      onCompleted() {
        toast.success(COPY.CLOSE_SUCCESS)
      },
      onError() {
        toast.error(COPY.CLOSE_ERROR)
      },
    }
  )

  const interviewCycle = data?.interviewCycle
  const interviews =
    interviewCycle?.interviews && normalizeInterviews(interviewCycle.interviews)
  const searchParams = {
    [QS.INTERVIEW_CYCLE_ID]: interviewCycleId,
    [QS.CLIENT_ID]: clientId,
    [QS.CALLBACK]: pathname,
  }
  const reportInterviewPath = generatePath(
    `${ROUTE_PATHS.ADD_INTERIVEW_FORM}?${createSearchParams(searchParams)}`
  )
  const newDossierRequestPath = generatePath(
    `${ROUTE_PATHS.NEW_DOSSIER_REQUEST}?${createSearchParams(searchParams)}`
  )
  const newInterviewPrepRequestPath = generatePath(
    `${ROUTE_PATHS.NEW_INTERVIEW_PREP_REQUEST}?${createSearchParams(searchParams)}`
  )
  const newExpertRequest = generatePath(
    `${ROUTE_PATHS.NEW_EXPERT_REQUEST}?${createSearchParams({
      [QS.INTERVIEW_CYCLE_ID]: interviewCycleId,
      [QS.CLIENT_ID]: clientId,
      [QS.CALLBACK]: pathname,
    })}`
  )

  /**
   * this will remove the current interviewCycle from the query in cache so that ui will properly update
   * @param cache Apollo's cache
   */
  function updateCacheAfterCloseSuccess(cache: ApolloCache<unknown>) {
    const params = { clientId: clientId || undefined, active: true }
    // if we are currently filtering by clientId then we want the query string to reflect that
    const cacheString = `interviewCycles:${JSON.stringify(params)}`

    cache.modify({
      fields: {
        [cacheString]: (queryResult, { readField }) =>
          removeCacheEdgeById(interviewCycleId, queryResult, readField),
      },
    })
  }

  function handleCloseInterviewCycle(rejectionReason: InactiveCycleReasons) {
    if (window.confirm(COPY.CONFIRM_CLOSE)) {
      void recordInactiveInterviewCycle({
        variables: {
          RecordInactiveInterviewCycleInput: {
            interviewCycleId: interviewCycleId,
            inactiveReason: rejectionReason,
          },
        },
      })
    }
  }

  return (
    <Padding vertical={4} horizontal={4}>
      <Flex justify="space-between" align="center">
        <div>
          <RectangleSkeletonWrap
            height={30}
            fullWidth
            width="100%"
            withViewBox={false}
            showContent={!loading}
          >
            <InterviewCycleTitle
              interviewCycle={interviewCycle}
              openForm={() => setShowMetaForm(true)}
            />
          </RectangleSkeletonWrap>
        </div>
        <CreateNewButtonContainer>
          <CreateNewButton>
            <Icon name="Plus" height={24} width={24} primaryFill="white" />
            <CreateNewList>
              <VList size={0.5}>
                <CreateNewOption>
                  <Txt as={Link} size={14} to={reportInterviewPath}>
                    Interview
                  </Txt>
                </CreateNewOption>
                <CreateNewOption>
                  <Txt as={Link} size={14} to={newDossierRequestPath}>
                    Dossier
                  </Txt>
                </CreateNewOption>
                {data?.interviewCycle && (
                  <ExternalLink
                    txtProps={{
                      size: 14,
                      underline: false,
                      color: 'text',
                    }}
                    url={`${AIRTABLE_COMP_REPORT_URL}?${createSearchParams({
                      'prefill_Request Type': 'Comp Report',
                      'prefill_Free Agent': data.interviewCycle.client.name,
                      'prefill_Assignment Information': `company: ${
                        data.interviewCycle.employerName ?? ''
                      }\nrole: ${data.interviewCycle.jobTitle ?? ''}\nlocation:\n${
                        data.interviewCycle.jobUrl
                          ? `JD: ${data.interviewCycle.jobUrl || ''}`
                          : ''
                      }`,
                    })}`}
                  >
                    <CreateNewOption>Comp Report</CreateNewOption>
                  </ExternalLink>
                )}
                <CreateNewOption>
                  <Txt as={Link} size={14} to={newExpertRequest}>
                    Expert
                  </Txt>
                </CreateNewOption>
                <CreateNewOption>
                  <Txt as={Link} size={14} to={newInterviewPrepRequestPath}>
                    Interview Prep
                  </Txt>
                </CreateNewOption>
                {data?.interviewCycle && (
                  <ExternalLink
                    txtProps={{
                      size: 14,
                      underline: false,
                      color: 'text',
                    }}
                    url={`${AIRTABLE_OFFER_FORM_URL}?${createSearchParams({
                      prefill_Employer: data.interviewCycle.employerName ?? '',
                      prefill_Candidate: data.interviewCycle.client.name,
                      prefill_Title: data.interviewCycle.jobTitle ?? '',
                    })}`}
                  >
                    <CreateNewOption>Offer</CreateNewOption>
                  </ExternalLink>
                )}
              </VList>
            </CreateNewList>
          </CreateNewButton>
        </CreateNewButtonContainer>
      </Flex>
      <div>
        <RectangleSkeletonWrap height={30} width={100} showContent={!loading}>
          {interviewCycle?.inactiveAt && interviewCycle.inactiveReason ? (
            <Txt>
              Cycle Closed -{' '}
              {
                InterviewCycleInactiveReasonMap[
                  interviewCycle.inactiveReason as InactiveCycleReasons
                ]
              }
            </Txt>
          ) : (
            <RejectionDropdownButton<InactiveCycleReasons>
              onSelection={handleCloseInterviewCycle}
              position="left"
              buttonProps={{ $type: 'primary', small: true }}
              buttonLabel="Close Cycle"
              dropdownOptions={rejectionOptions}
            />
          )}
        </RectangleSkeletonWrap>
      </div>
      <Padding top={4}>
        <VList size={4}>
          {interviewCycle && showMetaForm && (
            <InterviewCycleMetaSection
              interviewCycle={interviewCycle}
              closeForm={() => setShowMetaForm(false)}
            />
          )}
          <CycleDetailSection interviewCycle={interviewCycle} />

          <InterviewCycleDetailInterviewList
            interviews={interviews}
            pathname={pathname}
          />
          <InterviewCycleDetailDossiers
            interviewCycle={interviewCycle}
            loading={loading}
          />
          <InterviewCycleDetailExpertRequests
            interviewCycle={interviewCycle}
            loading={loading}
          />
          <InterviewCycleDetailPrepRequests
            interviewCycle={interviewCycle}
            loading={loading}
          />
        </VList>
      </Padding>
    </Padding>
  )
}

type TitleProps = {
  interviewCycle: Maybe<InterviewCycle>
  openForm(): void
}

function InterviewCycleTitle({ interviewCycle, openForm }: TitleProps) {
  return (
    <>
      <Txt uppercase spacing={0.8} bold size={12}>
        {interviewCycle?.client.name} — Interview Cycle
      </Txt>
      <Padding top={0.5}>
        <button onClick={openForm}>
          <Flex align="center" justify="space-between">
            <Txt as="h2" bold size={24}>
              {interviewCycle && getJobInfoLabel(interviewCycle)}
            </Txt>
            <Padding left={3}>
              <Icon
                name="Pen"
                height={16}
                width={16}
                primaryFill="faGrey3"
                primaryFillHover="text"
              />
            </Padding>
          </Flex>
        </button>
      </Padding>
    </>
  )
}

type SectionProps = {
  interviewCycle: Maybe<InterviewCycle>
  loading?: boolean
}

/* TODO (matthewalbrecht): break these sections out into their own files */

function CycleDetailSection({ interviewCycle }: SectionProps) {
  const [showMetaForm, setShowMetaForm] = useState(false)
  const showSourcedJobContent =
    interviewCycle?.jobTitle != null &&
    interviewCycle.jobUrl === interviewCycle.sourcedJob?.url

  return (
    <div>
      <SectionHeader
        headerCopy="Cycle Details"
        icon={
          <button onClick={() => setShowMetaForm(true)}>
            <Icon
              name="Pen"
              height={12}
              width={12}
              primaryFill="faGrey3"
              primaryFillHover="text"
            />
          </button>
        }
      />
      {!showMetaForm && (
        <>
          <HList as={Flex} size={2} align="center">
            {showSourcedJobContent && interviewCycle.sourcedJob?.company.domain && (
              <ExternalLink url={interviewCycle.sourcedJob.company.domain}>
                Company Website
              </ExternalLink>
            )}
            {showSourcedJobContent && interviewCycle.sourcedJob?.id && (
              <JobDescriptionDialogButton
                sourcedJobId={interviewCycle.sourcedJob.id}
                label="Job Description"
              />
            )}
          </HList>
          <Padding top={2}>
            <VList size={1}>
              <InlineValueProp
                value={
                  interviewCycle && INTERVIEW_CYCLE_SOURCE_MAP[interviewCycle.source]
                }
                label="Source"
              />
              {showSourcedJobContent && (
                <InlineValueProp
                  value={interviewCycle.sourcedJob?.datePosted ?? UNKNOWN.UNKNOWN}
                  label="Job Posted"
                />
              )}
            </VList>
          </Padding>
        </>
      )}
      {interviewCycle && showMetaForm && (
        <InterviewCycleDetailMetaSection
          interviewCycle={interviewCycle}
          closeForm={() => setShowMetaForm(false)}
        />
      )}
    </div>
  )
}

const GET_INTERVIEW_CYCLE = gql`
  query GetInterviewCycleById($id: ID!) {
    interviewCycle(id: $id) {
      id
      huntrUrl
      jobTitle
      employerName
      source
      inactiveAt
      inactiveReason
      sourcedJob {
        id
        datePosted
        company: companyEntity {
          domain
        }
        url
      }
      client {
        id
        name
      }
      interviews {
        edges {
          node {
            id
            publicId
            isActive
            scheduledFor {
              datetime
              timezone
            }
            debriefs {
              id
            }
          }
        }
      }
      ...MetaSectionInfo
      ...DossierRequestInfo
      ...ExpertRequestInfo
      ...PrepRequestInfo
    }
  }
  ${InterviewCycleDetailExpertRequests.fragments.expertRequestInfo}
  ${InterviewCycleMetaSection.fragments.metaSection}
  ${InterviewCycleDetailDossiers.fragments.dossierRequestInfo}
  ${InterviewCycleDetailPrepRequests.fragments.prepRequestInfo}
`

InterviewCycleDetail.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL}
    key={ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL}
  >
    <InterviewCycleDetail />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_DOSSIER_FEEDBACK}
    key={ROUTE_PATHS.CLIENT_CONFIG_DOSSIER_FEEDBACK}
  >
    <InterviewCycleDetail />
  </PrivateRoute>,
]
