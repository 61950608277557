import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { CANCEL_EXPERT_REQUEST } from 'views/ExpertRequests/EditExpertRequestStatus'
import { getStatusInfo } from 'views/ExpertRequests/ExpertRequests.helpers'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { COPY } from './ClientExpertRequests.constants'
import { ExpertRequestSortable, InterviewCycle } from './ClientExpertRequests.types'
import ExternalLink from 'components/ExternalLink'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'
import {
  CancelExpertRequestMutation,
  CancelExpertRequestMutationVariables,
} from 'generated/graphql'

type Props = {
  expertRequest: ExpertRequestSortable
  clientId: string
}

export default function ClientExpertRequestsRow({
  clientId,
  expertRequest,
}: Props): React.ReactElement {
  // mutations
  const [cancelExpertRequest] = useMutation<
    CancelExpertRequestMutation,
    CancelExpertRequestMutationVariables
  >(CANCEL_EXPERT_REQUEST, {
    onCompleted() {
      toast.success(COPY.CANCEL_SUCCESS)
    },
    onError() {
      toast.error(COPY.CANCEL_ERROR)
    },
  })

  function handleCancel() {
    // are you sure
    if (!window.confirm(COPY.CONFIRM_CANCEL)) {
      return
    }

    void cancelExpertRequest({
      variables: { input: { expertRequestId: expertRequest.id } },
    })
  }

  const showCancelButton = !expertRequest.airtableId && !expertRequest.canceledAt
  const [statusCopy, statusColor] = getStatusInfo(expertRequest)

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {dayjs(expertRequest.createdAt).format(DATE_FORMAT.ONLY_DATE)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {dayjs(expertRequest.due).format(DATE_FORMAT.ONLY_DATE)}
        </Txt>
      </BodyData>
      <BodyData>
        <Tag color={statusColor} small>
          <Txt size={13} alignment="center">
            {statusCopy}
          </Txt>
        </Tag>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {expertRequest.expert ? (
            <ExternalLink
              url={expertRequest.expert.linkedinUrl}
              inheritStyles
              label={expertRequest.expert.name}
            />
          ) : (
            EMPTY_CELL_PLACEHOLEDER
          )}
        </Txt>
      </BodyData>
      <BodyData>
        {expertRequest.interviewCycle ? (
          <InterviewCycleLink
            interviewCycle={expertRequest.interviewCycle}
            clientId={clientId}
          />
        ) : (
          <Txt size={14}>{EMPTY_CELL_PLACEHOLEDER}</Txt>
        )}
      </BodyData>
      <BodyData collapse>
        {showCancelButton && (
          <button onClick={handleCancel}>
            <Icon
              name="Cancel"
              width={14}
              height={14}
              primaryFill="text"
              primaryFillHover="black"
            />
          </button>
        )}
      </BodyData>
    </BodyRow>
  )
}

type InterviewCycleLinkProps = {
  interviewCycle: NonNullable<InterviewCycle>
  clientId: string
}

function InterviewCycleLink({
  interviewCycle,
  clientId,
}: InterviewCycleLinkProps): React.ReactElement {
  return (
    <Link
      to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
        clientId,
        interviewCycleId: interviewCycle.id,
      })}
    >
      <Txt underline color="text" hoverColor="black" size={14}>
        {getJobInfoLabel(interviewCycle)}
      </Txt>
    </Link>
  )
}

ClientExpertRequestsRow.fragments = {
  clientExpertRequestRowInfo: gql`
    fragment ClientExpertRequestRowInfo on ExpertRequest {
      id
      createdAt
      due
      expert {
        id
        name
        linkedinUrl
      }
      interviewCycle {
        id
        employerName
        jobTitle
      }
      ...StatusInfo
    }
    ${getStatusInfo.fragments.statusInfo}
  `,
}
