import { GetMissingApplicationInfoQuery } from 'generated/graphql'

export type Application = NonNullable<
  GetMissingApplicationInfoQuery['missingApplicationInfoByPublic']
>

export type Params = {
  applicationInfoId: string
}

export enum Status {
  Form,
  Success,
  AlreadySubmitted,
}
