import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'
import getNodes from 'utils/helpers/getNodes'
import { LAST_30_DAYS } from 'components/TalentAgentClientCards/TalentAgentClientCards.constants'
import { DateFilter } from 'components/TalentAgentClientCards/TalentAgentClientCards.types'
import { getClientsStageChanges } from './DashboardCard.helpers'
import { DashboardCard, StageChangeDashboardContent } from './DashboardCard.styles'
import DashboardCardTitle from './DashboardCardTitle'
import DashboardStageChangeCard from './DashboardStageChangeCard'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientsStageChangesQuery,
  GetClientsStageChangesQueryVariables,
} from 'generated/graphql'

type Props = {
  talentAgentId: string
}

const LIMIT = 20

export default function DashboardCardStageChanges({
  talentAgentId,
}: Props): React.ReactElement {
  const [dateFilter30Days, setDateFilter30Days] = useState<DateFilter | null>(null)
  const clientsStageChangesOptions = dateFilter30Days
    ? { variables: { talentAgentId, dateFilter30Days } }
    : { skip: true }
  useEffect(() => {
    setDateFilter30Days({
      start: dayjs().subtract(LAST_30_DAYS, 'days').format(),
      end: dayjs().format(),
    })
  }, [])
  const { data, loading } = useQuery<
    GetClientsStageChangesQuery,
    GetClientsStageChangesQueryVariables
  >(GET_CLIENTS_STAGE_CHANGES, clientsStageChangesOptions)
  const clients = data?.clients && getNodes(data.clients)
  const stageChanges = clients ? getClientsStageChanges(clients) : []
  return (
    <DashboardCard>
      <DashboardCardTitle title="Stage changes" />
      <StageChangeDashboardContent>
        {loading && <LoadingIndicator />}
        {!stageChanges.length && !loading && (
          <Padding all={2}>
            <Txt>There are no stage changes.</Txt>
          </Padding>
        )}
        {stageChanges.slice(0, LIMIT).map((stageChangeInfo, index) => (
          <DashboardStageChangeCard
            key={stageChangeInfo.id}
            hasMore={index !== stageChanges.slice(0, LIMIT).length - 1}
            stageChangeInfo={stageChangeInfo}
          />
        ))}
      </StageChangeDashboardContent>
      <Padding vertical={0.5} />
    </DashboardCard>
  )
}

const GET_CLIENTS_STAGE_CHANGES = gql`
  query GetClientsStageChanges(
    $talentAgentId: ID!
    $dateFilter30Days: DatetimeRangeFilter!
  ) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          ...ClientsStageChanges
        }
      }
    }
  }
  ${getClientsStageChanges.fragments.clientsStageChanges}
`
