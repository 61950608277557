import { useMutation } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import ROUTE_PATHS from 'utils/routePaths'
import { UPDATE_SEQUENCE_COPY } from 'gql/mutations'
import { buildUpdateSequenceCopyVariables } from './ClientTemplates.helpers'
import { ConfigPageEditorChanges } from './ClientTemplates.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import {
  UpdateTemplateSequenceCopyMutation,
  UpdateTemplateSequenceCopyMutationVariables,
} from 'generated/graphql'

type Props = {
  editorChanges: ConfigPageEditorChanges
  setEditorChanges: React.Dispatch<React.SetStateAction<ConfigPageEditorChanges>>
}

const ClientTemplatesFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

export default function ClientTemplatesFooter({
  editorChanges,
  setEditorChanges,
}: Props): JSX.Element {
  const [updateSequenceCopy, { loading }] = useMutation<
    UpdateTemplateSequenceCopyMutation,
    UpdateTemplateSequenceCopyMutationVariables
  >(UPDATE_SEQUENCE_COPY, {
    onCompleted({ updateTemplateSequenceCopy }) {
      const sequenceName = updateTemplateSequenceCopy?.templateSequence.name
      if (sequenceName) {
        toast.success(`Successfully updated "${sequenceName}" templates`)
      }
    },
  })

  function handleSaves() {
    const updateSequenceCopyVariables =
      buildUpdateSequenceCopyVariables(editorChanges)

    updateSequenceCopyVariables.forEach((variables) => {
      // TODO: (@matthewalbrecht) handle failures from the mutation
      void updateSequenceCopy({ variables })
    })

    setEditorChanges({})
  }

  return (
    <ClientTemplatesFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        {/* TODO: (mattthewalbrecht) disabled submit button if no changes have been made yet */}
        <Button $type="accept" onClick={handleSaves} disabled={loading}>
          Save Changes
        </Button>
      </Flex>
    </ClientTemplatesFooterBox>
  )
}
