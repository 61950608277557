import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { getColumns } from './SourcerStats.helpers'
import { GroupByOptions, SourcerStatsValues } from './SourcerStats.types'
import SourcerStatsRow from './SourcerStatsRow'
import Txt from 'components/Txt'

type Props = {
  tableData: SourcerStatsValues[]
  groupBy: GroupByOptions
}

export default function SourcerStatsTable({
  tableData,
  groupBy,
}: Props): React.ReactElement {
  const columns = getColumns(groupBy)

  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {columns.map((column) => (
                <HeaderData key={column.id}>
                  <Txt size={12} bold>
                    {column.label}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {tableData.map((rowData) => {
              return (
                <SourcerStatsRow
                  key={`${rowData.sourcerId}:${rowData.clientId}`}
                  rowData={rowData}
                  groupBy={groupBy}
                />
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </TableBox>
  )
}
