import { ColumnHeader, DropdownOption } from 'types'
import { ExpertRequestStatusMap } from 'utils/constants'
import { ExpertRequestStatus } from 'generated/graphql'

export const HEADERS: ColumnHeader[] = [
  {
    label: '',
    id: 'expand',
    collapse: true,
  },
  {
    label: 'Deadline',
    id: 'deadline',
  },
  {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  {
    label: 'Talent Agent',
    id: 'talentAgent',
  },
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Requested On',
    id: 'requestedOn',
  },
  {
    label: 'Expert',
    id: 'expert',
  },
  {
    label: 'Interview Cycle',
    id: 'interviewCycle',
  },
]

export const ID = {
  FORM: 'expertRequestStatusForm',
}

export const STATUS_OPTIONS: DropdownOption<ExpertRequestStatus>[] = [
  {
    label: ExpertRequestStatusMap[ExpertRequestStatus.REQUESTED],
    value: ExpertRequestStatus.REQUESTED,
  },
  {
    label: ExpertRequestStatusMap[ExpertRequestStatus.MESSAGE_SENT],
    value: ExpertRequestStatus.MESSAGE_SENT,
  },
  {
    label: ExpertRequestStatusMap[ExpertRequestStatus.IN_TOUCH],
    value: ExpertRequestStatus.IN_TOUCH,
  },
  {
    label: ExpertRequestStatusMap[ExpertRequestStatus.CALL_SCHEDULED],
    value: ExpertRequestStatus.CALL_SCHEDULED,
  },
  {
    label: ExpertRequestStatusMap[ExpertRequestStatus.PAYMENT_NEEDED],
    value: ExpertRequestStatus.PAYMENT_NEEDED,
  },
  {
    label: ExpertRequestStatusMap[ExpertRequestStatus.PAYMENT_COMPLETE],
    value: ExpertRequestStatus.PAYMENT_COMPLETE,
  },
]

export const COPY = {
  CANCEL_SUCCESS: 'Expert request has been canceled',
  CANCEL_ERROR: 'There was an error canceling the expert request',
  MODIFY_SUCCESS: 'Expert request updated',
  MODIFY_ERROR: 'There was an error updating the expert request',
  CONFIRM_CANCEL: 'Are you sure you want to cancel this Expert Request?',
}
