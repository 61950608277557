import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { KeyValue, Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import { SourcerQueueQuery, SourcerQueueQueryVariables } from 'generated/graphql'

type QueueReturn = {
  loading: boolean
  queueByViewer: string[]
  queueByClient: KeyValue<string[]>
}
type CountReturn = {
  loading: boolean
  totalCount: Undefinable<number>
}

type SourcingRequest = {
  id: string
  due: string
  client: {
    id: string
    name: string
  }
}

export default function useSourcerQueue(
  queryOptions: QueryHookOptions<SourcerQueueQuery, SourcerQueueQueryVariables> = {},
  assigneeId?: string
): QueueReturn {
  const {
    userSession: {
      user: { id: userId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<SourcerQueueQuery, SourcerQueueQueryVariables>(
    GET_SOURCER_QUEUE,
    {
      variables: {
        assigneeId: assigneeId || userId,
        onlyCount: false,
      },
      ...queryOptions,
    }
  )

  const sourcingRequests =
    data?.sourcingRequests?.edges
      ?.map((edge) => ({
        ...edge.node,
        sortableDue: dayjs(edge.node.due),
        clientName: edge.node.client.name,
      }))
      .sort((a: SourcingRequest, b: SourcingRequest) =>
        sortByProp(a, b, 'sortableDue', 'clientName')
      ) || []

  // map client ids to array of tagline requests
  const queueByClient: KeyValue<string[]> = sourcingRequests.reduce(
    (clientMap: KeyValue<string[]>, sourcingRequest: SourcingRequest) => {
      const clientArray = clientMap[sourcingRequest.client.id]
      if (clientArray) {
        clientArray.push(sourcingRequest.id)
      } else {
        clientMap[sourcingRequest.client.id] = [sourcingRequest.id]
      }
      return clientMap
    },
    {}
  )

  const queueByViewer = sourcingRequests.map(reduceObjectToId)

  return { queueByViewer, queueByClient, loading }
}

export function useSourcerQueueCount(
  queryOptions: QueryHookOptions<SourcerQueueQuery, SourcerQueueQueryVariables> = {},
  assigneeId: string
): CountReturn {
  const { data, loading } = useQuery<SourcerQueueQuery, SourcerQueueQueryVariables>(
    GET_SOURCER_QUEUE,
    {
      variables: {
        assigneeId,
        onlyCount: true,
      },
      ...queryOptions,
    }
  )

  return {
    loading,
    totalCount: data?.sourcingRequests?.totalCount ?? undefined,
  }
}

/**
 * used by writers to queue through all of their feedback
 */
const GET_SOURCER_QUEUE = gql`
  query SourcerQueue($assigneeId: ID!, $onlyCount: Boolean!) {
    sourcingRequests(assigneeId: $assigneeId, isAssigned: true, isOpen: true) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          due
          client {
            id
            name
          }
        }
      }
    }
  }
`
