import { ColumnMap } from 'types'
import { SHOW_TALENT_AGENT_REVIEW } from 'utils/settings'

export const columns: ColumnMap = SHOW_TALENT_AGENT_REVIEW
  ? {
      avgTalentAgentRating: { id: 'avgTalentAgentRating', label: 'AVG IR Rating' },
      talentAgentOnesAndTwosRate: {
        id: 'talentAgentOnesAndTwosRate',
        label: 'IR 1&2s Rate',
        tooltip: 'Percentage of reviewed work rated 1 or 2',
      },
      avgTalentPartnerRating: {
        id: 'avgTalentPartnerRating',
        label: 'AVG FR Rating',
      },
      talentPartnerOnesAndTwosRate: {
        id: 'talentPartnerOnesAndTwosRate',
        label: 'FR 1&2s Rate',
        tooltip: 'Percentage of reviewed work rated 1 or 2',
      },
      avgTimeSpentSec: { id: 'avgTimeSpentSec', label: 'AVG Time Spent' },
      rejectionRate: { id: 'rejectionRate', label: 'Rejection Rate' },
    }
  : {
      avgTalentPartnerRating: { id: 'avgTalentPartnerRating', label: 'AVG Rating' },
      talentPartnerOnesAndTwosRate: {
        id: 'talentPartnerOnesAndTwosRate',
        label: '1&2s Rate',
        tooltip: 'Percentage of reviewed work rated 1 or 2',
      },
      avgTimeSpentSec: { id: 'avgTimeSpentSec', label: 'AVG Time Spent' },
      rejectionRate: { id: 'rejectionRate', label: 'Rejection Rate' },
    }
