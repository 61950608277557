import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useAuthContext } from 'context/auth'
import TableBody from 'components/Table/TableBody'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS_BY_DUE } from './SourcerTable.constants'
import { buildRowDataByDueDate, getNumJobsSum } from './SourcerTable.helpers'
import {
  GetAssigneeSourcingRequestsDueDatesQuery,
  GetAssigneeSourcingRequestsDueDatesQueryVariables,
} from 'generated/graphql'

export default function SourcerDueDateTable(): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const { data } = useQuery<
    GetAssigneeSourcingRequestsDueDatesQuery,
    GetAssigneeSourcingRequestsDueDatesQueryVariables
  >(GET_ASSIGNEE_APPLICATIONS, {
    variables: {
      assigneeId,
    },
  })
  const rowData =
    data?.sourcingRequests && buildRowDataByDueDate(data.sourcingRequests)

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS_BY_DUE.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData?.map(({ due, sourcingRequests }) => (
          <tr key={due}>
            <TableBodyItem first content={due} />
            <TableBodyItem content={getNumJobsSum(sourcingRequests)} />
          </tr>
        ))}
      </TableBody>
      {rowData && !rowData.length && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}

/**
 * used by sourcers to get all of their sourcingRequests
 */
const GET_ASSIGNEE_APPLICATIONS = gql`
  query getAssigneeSourcingRequestsDueDates($assigneeId: ID!) {
    sourcingRequests(assigneeId: $assigneeId, isOpen: true) {
      edges {
        node {
          id
          due
          numJobs
        }
      }
    }
  }
`
