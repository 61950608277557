import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import { EMPTY_INPUT } from 'utils/constants'
import { Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import StarRating from 'components/StarRating/StarRating'
import { ACCOUNT_PERSONA_OPTIONS, TOAST } from './ClientTracker.constants'
import { getInitialFormState } from './ClientTracker.helpers'
import { Client, StatusUpdateFormState } from './ClientTracker.types'
import ClientTrackerStatusUpdateHistory from './ClientTrackerStatusUpdateHistory'
import useFormState from './useFormState'
import Button from 'components/Button'
import Flex from 'components/Flex'
import { Cell, Grid } from 'components/Grid'
import { CheckboxGroup, TextArea } from 'components/Inputs'
import Label from 'components/Label'
import Padding from 'components/Padding'
import VList from 'components/VList'
import {
  AccountPersona,
  CreateStatusUpdateMutation,
  CreateStatusUpdateMutationVariables,
} from 'generated/graphql'

type Props = {
  client: Client
  // we take clientId from params because it is non-null
  clientId: string
}

export default function ClientTrackerStatusUpdateForm({
  client,
  clientId,
}: Props): React.ReactElement {
  const initialState = getInitialFormState(client)
  const isReadOnly = !checkRole([Role.TalentAgent])
  const { formState, setFormField, setFormState } =
    useFormState<StatusUpdateFormState>(initialState)

  const [createStatusUpdate] = useMutation<
    CreateStatusUpdateMutation,
    CreateStatusUpdateMutationVariables
  >(CREATE_STATUS_UPDATE, {
    /* TODO (matthewalbrecht): add created status to cache */
    onCompleted() {
      toast.success(TOAST.createSuccess)
      setFormState({
        ...formState,
        statusMessage: null,
        lastStatusMessage: formState.statusMessage,
      })
    },
    onError() {
      toast.error(TOAST.createError)
    },
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    if (isReadOnly) {
      return
    }
    if (formState.relationshipStrength == null) {
      toast.error(TOAST.relationshipRequired)
      return
    }
    if (formState.satisfactionRating == null) {
      toast.error(TOAST.satisfactionRequired)
      return
    }

    void createStatusUpdate({
      variables: {
        input: {
          clientId: clientId,
          relationshipStrength: formState.relationshipStrength,
          satisfactionRating: formState.satisfactionRating,
          // this is a required field so we can be confident it will exist here
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          statusMessage: formState.statusMessage!,
          accountPersonas: Object.entries(formState.accountPersonas ?? [])
            .filter(([, selected]) => selected)
            .map(([val]) => val as AccountPersona),
        },
      },
    })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid columns="min-content minmax(auto, 70ch)" columnGap={4} rowGap={3}>
        <Cell>
          <VList size={3}>
            <div>
              <Label content="Relationship Strength *" txtProps={{ noWrap: true }} />
              <StarRating
                iconSize={20}
                rating={formState.relationshipStrength}
                disabled={isReadOnly}
                onValueChange={(value) =>
                  setFormField('relationshipStrength', value)
                }
              />
            </div>
          </VList>
        </Cell>
        <Cell>
          <CheckboxGroup
            name="accountPersonas"
            label="Account Persona"
            value={formState.accountPersonas}
            onValueChange={(value) => setFormField('accountPersonas', value)}
            disabled={isReadOnly}
            options={ACCOUNT_PERSONA_OPTIONS}
          />
        </Cell>
        <Cell>
          <div>
            <Label content="Client Satisfaction *" txtProps={{ noWrap: true }} />
            <StarRating
              iconSize={20}
              rating={formState.satisfactionRating}
              onValueChange={(value) => setFormField('satisfactionRating', value)}
              disabled={isReadOnly}
            />
          </div>
        </Cell>
        <Cell>
          <VList size={3}>
            <div>
              <div>
                <Flex justify="space-between">
                  <Label content="Status Update *" />
                  <ClientTrackerStatusUpdateHistory client={client} />
                </Flex>
                <TextArea
                  value={formState.lastStatusMessage ?? EMPTY_INPUT}
                  placeholder={
                    formState.lastStatusMessage ? '' : 'No previous update found.'
                  }
                  disabled
                />
              </div>
              {!isReadOnly && (
                <Padding top={1.5}>
                  <TextArea
                    value={formState.statusMessage ?? EMPTY_INPUT}
                    onValueChange={(value) => setFormField('statusMessage', value)}
                    required
                    placeholder="Provide an update on the status of the account..."
                  />
                </Padding>
              )}
            </div>
          </VList>
        </Cell>
        {!isReadOnly && (
          <Cell $width={2} justify="end">
            <Button type="submit" $type="accept">
              Submit
            </Button>
          </Cell>
        )}
      </Grid>
    </form>
  )
}

const CREATE_STATUS_UPDATE = gql`
  mutation CreateStatusUpdate($input: CreateStatusUpdateInput!) {
    createStatusUpdate(input: $input) {
      statusUpdate {
        id
      }
    }
  }
`

ClientTrackerStatusUpdateForm.fragments = {
  statusUpdate: gql`
    fragment StatusUpdateFormFragment on StatusUpdate {
      createdAt
      statusMessage
      accountPersonas
      relationshipStrength
      satisfactionRating
    }
  `,
}
