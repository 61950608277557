import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import ActivityLogTable from 'components/ActivityLogTable/ActivityLogTable'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function ActivityLog(): JSX.Element {
  return (
    <ViewBox>
      <Padding top={6}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            Activity Log
          </Txt>
          <Padding vertical={4}>
            <ActivityLogTable first={25} isPaginated />
          </Padding>
        </Container>
      </Padding>
    </ViewBox>
  )
}

ActivityLog.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.ACTIVITY_LOG} key={ROUTE_PATHS.ACTIVITY_LOG}>
    <ActivityLog />
  </PrivateRoute>,
]
