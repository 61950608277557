import { useQuery, useLazyQuery, gql } from '@apollo/client'
import React, { useCallback, useState, useMemo, useEffect } from 'react'
import AutoSuggest, {
  SuggestionsFetchRequestedParams,
  InputProps,
  SuggestionSelectedEventData,
} from 'react-autosuggest'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { useAuthContext } from 'context/auth'
import { EMPTY_INPUT } from 'utils/constants'
import { checkRoleEnum } from 'utils/helpers/checkRole'
import debounce from 'utils/helpers/debounce'
import { GET_CLIENTS_CONN, GET_TALENT_AGENTS } from 'gql/queries'
import {
  getSuggestionValue,
  normalizeSearchSuggestions,
  normalizeCompanySuggestions,
} from './SearchSuggestion.helpers'
import { AutoSuggestStyles } from './SearchSuggestion.styles'
import {
  DebounceFunctionType,
  SearchSuggestion as SearchSuggestionType,
  SearchSuggestionSection,
} from './SearchSuggestion.types'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  SearchCompaniesByDomainQuery,
  GetClientsConnQuery,
  GetTalentAgentsQuery,
  SearchCompaniesByDomainQueryVariables,
} from 'generated/graphql'

const COMPANY_SEARCH_FIRST = 100

type Props = {
  value: string
  onValueChange: (value: string) => void
  onSelectionValueChange(): void
}

const DEBOUNCE_TIME_MS = 200

export default function SearchSuggestion({
  value,
  onValueChange,
  onSelectionValueChange,
}: Props): React.ReactElement {
  const history = useHistory()
  const {
    userSession: { user },
  } = useAuthContext()
  const [suggestions, setSuggestions] = useState<SearchSuggestionSection[]>([])
  const { data } = useQuery<GetClientsConnQuery>(GET_CLIENTS_CONN)
  const { data: taData } = useQuery<GetTalentAgentsQuery>(GET_TALENT_AGENTS)
  const [searchCompany, { data: companyData }] = useLazyQuery<
    SearchCompaniesByDomainQuery,
    SearchCompaniesByDomainQueryVariables
  >(SEARCH_COMPANIES_BY_DOMAIN)

  const sections = useMemo(
    () => normalizeSearchSuggestions(user, data?.clients, taData?.talentAgents),
    [data?.clients, taData?.talentAgents, user]
  )
  useEffect(() => {
    if (companyData?.companies?.edges.length) {
      setSuggestions((suggestions) => [
        ...suggestions,
        ...normalizeCompanySuggestions(companyData),
      ])
    }
  }, [companyData])

  /**
   * fetches companies from clearbit Autocomplete API
   */
  const onSuggestionsFetchRequested = useCallback(
    ({ value }: SuggestionsFetchRequestedParams) => {
      const lowerValue = value.toLowerCase()
      const filtered = sections
        .map(({ title, items }) => ({
          title,
          items: items.filter(({ matches, permittedRoles }) => {
            if (!checkRoleEnum(user, permittedRoles)) {
              return false
            }

            return matches?.reduce(
              (hasMatch: boolean, match) =>
                hasMatch || match.toLowerCase().includes(lowerValue),
              false
            )
          }),
        }))
        .filter((section) => section.items.length)
      setSuggestions(filtered)
      if (value && value.length >= 2) {
        void searchCompany({
          variables: {
            first: COMPANY_SEARCH_FIRST,
            searchTerm: value,
          },
        })
      }
    },
    [sections, user, searchCompany]
  )

  /**
   * debounced onSuggestionsFetchRequested function
   */
  /* TODO (matthewalbrecht): rewrite this to pass linting */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce<DebounceFunctionType>(onSuggestionsFetchRequested, DEBOUNCE_TIME_MS),
    [onSuggestionsFetchRequested]
  )

  /**
   * clear suggestions
   */
  function onSuggestionsClearRequested() {
    setSuggestions([])
  }

  /**
   *
   */
  function handleSuggestionSelected(
    e: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<SearchSuggestionType>
  ) {
    history.push(suggestion.to)
    onSelectionValueChange()
    onValueChange(EMPTY_INPUT)
  }

  const allInputProps: InputProps<SearchSuggestionType> = {
    value,
    onChange(_, { newValue }) {
      onValueChange(newValue)
    },
    type: 'search',
    placeholder: 'Search\u2026',
    required: true,
    autoFocus: true,
  }

  function getSectionSuggestions(section: SearchSuggestionSection) {
    return section.items
  }

  // render individual element in suggestion list
  const renderSuggestion = (suggestion: SearchSuggestionType) => (
    <SearchSuggestionItem suggestion={suggestion} />
  )
  // render individual element in suggestion list
  const renderSectionTitle = (section: SearchSuggestionSection) => (
    <SearchSuggestionTitle section={section} />
  )

  return (
    <AutoSuggestStyles>
      <AutoSuggest<SearchSuggestionType>
        multiSection
        suggestions={suggestions}
        getSuggestionValue={getSuggestionValue}
        getSectionSuggestions={getSectionSuggestions}
        renderSuggestion={renderSuggestion}
        renderSectionTitle={renderSectionTitle}
        onSuggestionsFetchRequested={debouncedFetch}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        inputProps={allInputProps}
        shouldRenderSuggestions={() => true}
        onSuggestionSelected={handleSuggestionSelected}
      />
    </AutoSuggestStyles>
  )
}

type SearchSuggestionItemProps = {
  suggestion: SearchSuggestionType
}

function SearchSuggestionItem({
  suggestion: { to, label, subLabel },
}: SearchSuggestionItemProps): React.ReactElement {
  return (
    <Link to={to}>
      <Padding left={2}>
        <Flex align="center" justify="space-between">
          <Txt>{label}</Txt>
          <Txt size={14} color="faGrey2">
            {subLabel}
          </Txt>
        </Flex>
      </Padding>
    </Link>
  )
}

type SearchSuggestionTitleProps = {
  section: SearchSuggestionSection
}

function SearchSuggestionTitle({
  section: { title },
}: SearchSuggestionTitleProps): React.ReactElement {
  return (
    <Padding all={1}>
      <Txt size={12} bold color="faGrey4">
        {title}
      </Txt>
    </Padding>
  )
}

const SEARCH_COMPANIES_BY_DOMAIN = gql`
  query SearchCompaniesByDomain($searchTerm: String!, $first: Int!, $after: String) {
    companies(search: $searchTerm, first: $first, after: $after) {
      edges {
        node {
          id
          name
          domain
        }
      }
    }
  }
`
