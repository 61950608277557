import { useQuery, gql } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Redirect, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useEnrichmentQueue from 'hooks/useEnrichmentQueue'
import { DEFUALT_ENRICHMENT_POD_COUNT, GLOBAL_COPY, INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import TimeTracker from 'components/TimeTracker/TimeTracker'
import { DEFAULT_STATE } from './EnrichmentSubmission.constants'
import { Params, State } from './EnrichmentSubmission.types'
import EnrichmentSubmissionFooter from './EnrichmentSubmissionFooter'
import EnrichmentSubmissionLeft from './EnrichmentSubmissionLeft'
import EnrichmentSubmissionRight from './EnrichmentSubmissionRight'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import { GetEnrichmentRequestQuery } from 'generated/graphql'

export default function EnrichmentSubmission(): JSX.Element {
  const history = useHistory()
  const { podId, requestId } = useParams<Params>()
  const redirectToInitial = requestId === INITIAL
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const [timeSpentSec, setTimeSpentSec] = useState(0)
  const {
    fullQueue,
    loading: queueLoading,
    queueByPod,
  } = useEnrichmentQueue(DEFUALT_ENRICHMENT_POD_COUNT)
  const { data, loading, error } = useQuery<GetEnrichmentRequestQuery>(
    GET_ENRICHMENT_REQUEST,
    {
      variables: {
        id: requestId,
      },
      skip: redirectToInitial,
    }
  )

  /* TODO (matthewalbrecht): validate  */
  const enrichmentRequest = data?.enrichmentRequest
  const queue = useMemo(
    () => (podId ? queueByPod?.[podId] || [] : fullQueue || []),
    [fullQueue, podId, queueByPod]
  )
  const leftContent = useMemo(
    () =>
      enrichmentRequest && (
        <EnrichmentSubmissionLeft enrichmentRequest={enrichmentRequest} />
      ),
    [enrichmentRequest]
  )

  const rightContent = useMemo(
    () =>
      enrichmentRequest && (
        <EnrichmentSubmissionRight
          enrichmentRequest={enrichmentRequest}
          state={state}
          setState={setState}
        />
      ),
    [enrichmentRequest, state, setState]
  )

  useEffect(() => {
    if (!queueLoading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, queue, queueLoading])

  if (redirectToInitial && queue[0]) {
    const redirect = podId ? (
      <Redirect
        to={generatePath(ROUTE_PATHS.ENRICHER_QUEUE_BY_POD, {
          requestId: queue[0],
          podId,
        })}
      />
    ) : (
      <Redirect
        to={generatePath(ROUTE_PATHS.ENRICHER_QUEUE, {
          requestId: queue[0],
        })}
      />
    )
    return redirect
  }

  if (loading || queueLoading) {
    return <Message vertical message="Loading enrichment request" />
  }

  if (error) {
    return (
      <Message vertical message="There was an error loading enrichment request" />
    )
  }

  if (!enrichmentRequest) {
    return <Message vertical message="Cannot find the enrichment request" />
  }

  return (
    <DualScrollContainer key={requestId}>
      <Container noMax>
        <Padding top={2} bottom={2}>
          <Flex justify="flex-end" align="center">
            <Padding left={3}>
              <TimeTracker onValueChange={setTimeSpentSec} />
            </Padding>
          </Flex>
        </Padding>
      </Container>

      <DualScroll leftContent={leftContent} rightContent={rightContent} />
      <EnrichmentSubmissionFooter
        enrichmentRequest={enrichmentRequest}
        state={state}
        setState={setState}
        timeSpentSec={timeSpentSec}
        setTimeSpentSec={setTimeSpentSec}
        queue={queue}
        podId={podId}
        requestId={requestId}
      />
    </DualScrollContainer>
  )
}

EnrichmentSubmission.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ENRICHER_QUEUE}
    key={ROUTE_PATHS.ENRICHER_QUEUE}
  >
    <EnrichmentSubmission />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ENRICHER_QUEUE_BY_POD}
    key={ROUTE_PATHS.ENRICHER_QUEUE_BY_POD}
  >
    <EnrichmentSubmission />
  </PrivateRoute>,
]

const GET_ENRICHMENT_REQUEST = gql`
  query GetEnrichmentRequest($id: ID!) {
    enrichmentRequest(id: $id) {
      id
      ...EnrichmentSubmissionLeftInfo
      ...EnrichmentSubmissionRightInfo
      ...EnrichmentSubmissionFooterInfo
    }
  }
  ${EnrichmentSubmissionLeft.fragments.enrichmentRequestInfo}
  ${EnrichmentSubmissionRight.fragments.enrichmentRequestInfo}
  ${EnrichmentSubmissionFooter.fragments.enrichmentRequestInfo}
`
