import { ApolloCache, gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent } from 'types'
import { UNKNOWN } from 'utils/constants'
import { ID, YellowCardOption, YELLOW_CARD_OPTIONS } from './FinalCopy.constants'
import { Application, CompletionInfo } from './FinalCopy.types'
import Button from 'components/Button'
import { DialogExitButton, Dialog, DialogFooter } from 'components/DialogMisc'
import Flex from 'components/Flex'
import { RadioGroup, TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  ApplicationRejectionReason,
  RecordApplicationRejectionYellowCardMutation,
  RecordApplicationRejectionYellowCardMutationVariables,
} from 'generated/graphql'

type Props = {
  application: Application
  timeSpentSec: number
  handleCompletion(completionInfo: CompletionInfo): void
  updateCacheAfterSuccess<T>(cache: ApolloCache<T>): void
}

export default function FinalCopyYellowCard({
  application,
  timeSpentSec,
  handleCompletion,
  updateCacheAfterSuccess,
}: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState(false)
  const [questions, setQuestions] = useState('')
  const [submitOption, setSubmitOption] = useState<YellowCardOption>(
    YellowCardOption.SendToStaffAndFA
  )
  const jobInfo = `${application.jobTitle || UNKNOWN.JOB_TITLE} @ ${
    application.employerName || UNKNOWN.EMPLOYER_NAME
  }`
  const [recordYellowCard] = useMutation<
    RecordApplicationRejectionYellowCardMutation,
    RecordApplicationRejectionYellowCardMutationVariables
  >(RECORD_YELLOW_CARD, {
    update: updateCacheAfterSuccess,
  })

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    void yellowCardApplication()
  }

  async function yellowCardApplication() {
    const askClient = submitOption === YellowCardOption.SendToStaffAndFA

    const variables = {
      RecordApplicationRejectionYellowCardInput: {
        applicationId: application.id,
        timeSpentSec,
        questions,
        askClient,
        rejectionReason:
          submitOption === YellowCardOption.RequiresLoginInfo
            ? ApplicationRejectionReason.LOGIN_INFORMATION_NEEDED
            : ApplicationRejectionReason.ADDITIONAL_INFORMATION_NEEDED,
      },
    }
    try {
      await recordYellowCard({ variables })
      setIsOpen(false)
      setQuestions('')
      handleCompletion({ action: 'yellow carded' })
    } catch (error) {
      toast.error('There was an error yellow carding the application')
    }
  }

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onDismiss={() => setIsOpen(false)}
        aria-labelledby={ID.YELLOW_CARD_DIALOG_LABEL}
      >
        <DialogExitButton onClick={() => setIsOpen(false)} />
        <Padding vertical={4} horizontal={4}>
          <Txt as="h3" size={24} bold id={ID.YELLOW_CARD_DIALOG_LABEL}>
            {application.client.name}
          </Txt>
          <Padding top={1.5}>
            <Txt italic size={14} as="span">
              {jobInfo}
            </Txt>
          </Padding>
          <Padding top={4}>
            <form id={ID.YELLOW_CARD_FORM} onSubmit={handleSubmit}>
              <TextArea
                label="Additional Questions Asked on Application"
                onValueChange={(value) => setQuestions(value)}
                value={questions}
                height={200}
                required
              />
              <Padding top={4}>
                <RadioGroup
                  options={YELLOW_CARD_OPTIONS}
                  name="submitOption"
                  label="Who should handle this?"
                  value={submitOption}
                  onValueChange={(value: string) =>
                    setSubmitOption(value as YellowCardOption)
                  }
                />
              </Padding>
            </form>
          </Padding>
        </Padding>
        <DialogFooter>
          <Padding horizontal={4} vertical={1.5}>
            <Flex align="center" justify="flex-end">
              <Button
                $type="accept"
                as="input"
                type="submit"
                value="Submit"
                form={ID.YELLOW_CARD_FORM}
              />
            </Flex>
          </Padding>
        </DialogFooter>
      </Dialog>
      <Button $type="yellow-card" onClick={() => setIsOpen(true)}>
        Yellow Card
      </Button>
    </>
  )
}

const RECORD_YELLOW_CARD = gql`
  mutation RecordApplicationRejectionYellowCard(
    $RecordApplicationRejectionYellowCardInput: RecordApplicationRejectionYellowCardInput!
  ) {
    recordApplicationRejectionYellowCard(
      input: $RecordApplicationRejectionYellowCardInput
    ) {
      applicationSubmission {
        id
      }
    }
  }
`
