import React from 'react'
import { Route } from 'react-router-dom'
import Header from './Header/Header'

export default function PublicRoute({
  children,
  ...rest
}: {
  children?: React.ReactNode
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any
}): JSX.Element {
  return (
    <Route {...rest}>
      <Header />
      <main>{children}</main>
    </Route>
  )
}
