import { GetInterviewPublicDebriefQuery } from 'generated/graphql'

export type State = {
  interviewers: string
  thankYouCopy: string
  summaryPositives: string
  summaryNegatives: string
  openEndedInterviewQuestions: string
}

export type Params = {
  interviewPublicId: string
}

export enum Status {
  Open,
  Success,
  Declined,
}

export type Interview = NonNullable<
  GetInterviewPublicDebriefQuery['interviewByPublic']
>
