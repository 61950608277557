import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  Client,
  RawClient,
  SourcingRequest,
  SourcingRequestsConnection,
} from './SourcingStats.types'

/**
 * takes query response and transforms it into digestible data for our table
 * @param sourcingRequestsConnection sourcing request connection
 * @param rawClients list of all active clients
 */
export function buildClientsData(
  sourcingRequestsConnection: SourcingRequestsConnection,
  rawClients: RawClient[]
): Client[] {
  const sourcingRequests = getNodes(sourcingRequestsConnection)
  const clientMap = createClientStatsMap(sourcingRequests, rawClients)

  const sortedClients = Object.values(clientMap).sort((a, b) =>
    sortByProp(a, b, 'name')
  )
  return sortedClients
}

/**
 * creates a default row object for each client
 * @param clients array of all clients
 */
function buildDefaultClientMap(clients: RawClient[]): KeyValue<Client> {
  return clients.reduce((map: KeyValue<Client>, client) => {
    map[client.id] = {
      id: client.id,
      name: client.name,
      talentAgent: client.talentAgent.name,
      autoSourcingsWeekly: client.autoSourcingsWeekly,
      sourcingsRequested: 0,
      sourcingsSubmitted: 0,
      sourcingsApproved: 0,
      sourcingsRejected: 0,
    }
    return map
  }, {})
}

/**
 * adds sourcing request data to client map
 * @param sourcingRequests array of raw sourcing request nodes
 * @param clients client map
 */
function createClientStatsMap(
  sourcingRequests: SourcingRequest[],
  clients: RawClient[]
): KeyValue<Client> {
  const clientMap = buildDefaultClientMap(clients)

  sourcingRequests.forEach((request) => {
    const numRequested = request.numJobs
    const numSubmitted = request.sourcedJobs.length
    const entry = clientMap[request.client.id]

    // add requested / submitted count
    if (entry) {
      entry.sourcingsRequested += numRequested
      entry.sourcingsSubmitted += numSubmitted
    }

    // Add approved / rejected count
    request.sourcedJobs.forEach((job) => {
      const review = job.reviews[0]
      if (entry && review) {
        if (review.rejectionReason) {
          entry.sourcingsRejected += 1
        } else {
          entry.sourcingsApproved += 1
        }
      }
    })
  }, {})
  return clientMap
}
