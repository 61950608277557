import { ColumnHeader, DropdownOption, KeyValue } from 'types'
import { TaglineWorkRole } from 'generated/graphql'

export const columnItems: KeyValue<
  ColumnHeader & { userRoleHide?: TaglineWorkRole[] }
> = {
  freeAgent: { id: 'freeAgent', label: 'Free Agent' },
  talentAgent: {
    id: 'talentAgent',
    label: 'Talent Agent',
    userRoleHide: [TaglineWorkRole.TALENT_AGENT],
  },
  company: { id: 'company', label: 'Company' },
  writer: {
    id: 'writer',
    label: 'Writer',
    userRoleHide: [TaglineWorkRole.TALENT_AGENT],
  },
  writerSubmitDate: {
    id: 'writerSubmitDate',
    label: 'Writer Submit Date',
    userRoleHide: [TaglineWorkRole.TALENT_AGENT],
  },
  initialReviewer: { id: 'initialReviewer', label: 'Initial Reviewer' },
  taReviewDate: { id: 'taReviewDate', label: 'Initial Review Date' },
  finalReviewer: { id: 'finalReviewer', label: 'Final Reviewer' },
  frReviewDate: { id: 'frReviewDate', label: 'Final Review Date' },
  ratings: { id: 'ratings', label: 'Ratings' },
  rejected: { id: 'rejected', label: '', collapse: true },
}

export const FIRST = 25

export const userRoleOptions: DropdownOption<TaglineWorkRole>[] = [
  {
    label: 'Writer',
    value: TaglineWorkRole.WRITER,
  },
  {
    label: 'Initial Reviewer',
    value: TaglineWorkRole.TALENT_AGENT,
  },
  {
    label: 'Final Reviewer',
    value: TaglineWorkRole.FINAL_REVIEWER,
  },
]

export const anyRoleOption = {
  label: 'Any',
  value: TaglineWorkRole.ANY,
}
