import dayjs from 'dayjs'
import { Undefinable } from 'types'
import { sortByProp, sortByTaglineRequestStatus } from 'utils/sort'
import {
  Filter,
  FreeAgent,
  RawFreeAgent,
  RawTaglineRequest,
  TaglineRequest,
} from './TaglineStatus.types'
import {
  TaglineRequestRejectionRejecterRole,
  TaglineRequestStatus,
} from 'generated/graphql'

/**
 * returns the relevant date to the request's status
 * @param request tagline request object
 */
function getLastStatusChangeDate(request: RawTaglineRequest): string {
  let lastStatusChangeDate
  switch (request.status) {
    case TaglineRequestStatus.UNASSIGNED:
      lastStatusChangeDate = request.createdAt
      break
    case TaglineRequestStatus.PENDING_WRITING:
      lastStatusChangeDate = request.assignedAt
      break
    case TaglineRequestStatus.PENDING_TA_REVIEW:
      lastStatusChangeDate = request.writerSubmissions[0]?.createdAt
      break
    case TaglineRequestStatus.PENDING_TP_REVIEW:
      lastStatusChangeDate = request.talentAgentReviews[0]?.createdAt
      break
    case TaglineRequestStatus.REVIEWED:
      lastStatusChangeDate = request.talentPartnerReviews[0]?.createdAt
      break
    default:
      lastStatusChangeDate = request.createdAt
      break
  }
  return lastStatusChangeDate || request.createdAt
}

/**
 * sort the free agents and the tagline requests
 * @param freeAgents list of free agensts
 */
export function sortFreeAgents(freeAgents: RawFreeAgent[]): FreeAgent[] {
  return freeAgents
    .map((freeAgent) => ({
      ...freeAgent,
      taglineRequests: freeAgent.taglineRequests.edges
        .map(({ node: request }) => ({
          ...request,
          lastStatusChangeDate: dayjs(getLastStatusChangeDate(request)),
        }))
        .sort(sortByTaglineRequestStatus),
    }))
    .sort((a, b) => sortByProp(a, b, 'name'))
}

/**
 * filters out free agents given the current state of our dropdowns
 * @param freeAgents list of free agents
 * @param filter filter state
 */
export function filterFreeAgents(
  freeAgents: FreeAgent[],
  filter: Filter
): FreeAgent[] {
  return freeAgents.filter((freeAgent) => {
    if (filter.clientId && freeAgent.id !== filter.clientId) {
      return false
    }
    if (filter.talentAgentId && freeAgent.talentAgent.id !== filter.talentAgentId) {
      return false
    }
    if (!freeAgent.taglineRequests.length) {
      return false
    }
    return true
  })
}

type RejectionInfo = {
  stage: TaglineRequestRejectionRejecterRole
  reason: Undefinable<string>
}

export function maybeGetRejectionReasonInfo(
  request: TaglineRequest
): Undefinable<RejectionInfo> {
  const talentAgentReview = request.talentAgentReviews[0]
  const talentPartnerReview = request.talentPartnerReviews[0]
  const isTaglineRejected =
    talentAgentReview?.taglineRejectionReason ||
    talentPartnerReview?.taglineRejectionReason
  const isRejected =
    isTaglineRejected || request.status === TaglineRequestStatus.REJECTED

  if (!isRejected) {
    return undefined
  }
  const taglineRejection = request.taglineRequestRejections[0]
  if (taglineRejection) {
    return {
      stage: taglineRejection.rejecterRole,
      reason: taglineRejection.rejectionReason,
    }
  }
  if (talentPartnerReview) {
    return {
      stage: TaglineRequestRejectionRejecterRole.TALENT_PARTNER,
      reason: talentPartnerReview.taglineRejectionReason ?? undefined,
    }
  }
  if (talentAgentReview) {
    return {
      stage: TaglineRequestRejectionRejecterRole.TALENT_AGENT,
      reason: talentAgentReview.taglineRejectionReason ?? undefined,
    }
  }
  return undefined
}
