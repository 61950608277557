import React from 'react'
import {
  FakeAvatar,
  LogoContainer,
  LogoImage,
} from 'views/ClientJobs/ClientJobs.styles'
import Icon from 'components/Icon/Icon'

type Props = {
  src?: string | null
  alt: string
}

export default function DashboardCardClientHeadshot({
  src,
  alt,
}: Props): React.ReactElement {
  return (
    <>
      {src ? (
        <LogoContainer>
          <LogoImage height={58} width={58} src={src} alt={alt} />
        </LogoContainer>
      ) : (
        <FakeAvatar
          display="flex"
          justify="center"
          align="center"
          width={58}
          height={58}
        >
          <Icon name="User" height={36} width={36} primaryFill="darkGray" />
        </FakeAvatar>
      )}
    </>
  )
}
