import { Client, AgentTableInfo } from './AgentTable.types'

export function buildTableData(clients: Client[]): AgentTableInfo[] {
  return clients.map((client) => {
    return {
      id: client.id,
      clientName: client.name,
      sourcedJobsCount: client.sourcedJobs.totalCount,
      pendingWritingTaglinesCount:
        client.pendingWritingTaglines.totalCount +
        client.unassignedTaglines.totalCount,
      pendingTaReviewTaglinesCount: client.pendingTaReviewTaglines.totalCount,
      pendingFrReviewTaglinesCount: client.pendingFrReviewTaglines.totalCount,
      applicationsCount: client.applications.totalCount,
      interviewCyclesCount: client.interviewCycles.totalCount,
      pendingFrReviewTaglines: client.pendingFrReviewTaglines,
      importantPendingWritingTaglinesCount:
        client.importantPendingWritingTaglines.totalCount,
      importantUnassignedTaglinesCount:
        client.importantUnassignedTaglines.totalCount,
      importantPendingTaReviewTaglinesCount:
        client.importantPendingTaReviewTaglines.totalCount,
      importantPendingFrReviewTaglinesCount:
        client.importantPendingFrReviewTaglines.totalCount,
    }
  })
}
