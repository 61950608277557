import React from 'react'
import { DropdownOption, ReactSetState } from 'types'
import { OptionValues } from './DossierTable.constants'
import Button from 'components/Button'
import HList from 'components/HList'

type Props = {
  options: DropdownOption<OptionValues>[]
  currentTab: OptionValues
  setCurrentTab: ReactSetState<OptionValues>
}

export default function DossierTableNav({
  options,
  currentTab,
  setCurrentTab,
}: Props): JSX.Element {
  return (
    <HList size={2}>
      {options.map(({ label, value }) => (
        <Button
          key={label}
          $type="link"
          active={value === currentTab}
          onClick={() => setCurrentTab(value)}
        >
          {label}
        </Button>
      ))}
    </HList>
  )
}
