import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { TaglineRejectionReason } from 'utils/enums'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import getQueueLabel from 'utils/helpers/getQueueLabel'
import ROUTE_PATHS from 'utils/routePaths'
import RejectionButton from 'components/RejectionButton/RejectionButton'
import { RejectionOption } from 'components/RejectionButton/RejectionButton.types'
import {
  getNextRouteUrl,
  updateCacheOnSubmission,
  updateCacheOnRejection,
} from './InitialReview.helpers'
import { FormState, TaglineRequest } from './InitialReview.types'
import { DEFAULT_FORM_STATE } from './initialReview.constants'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  SubmitAgentReviewWorkMutation,
  SubmitAgentReviewWorkMutationVariables,
} from 'generated/graphql'

type Props = {
  getTimeSpentSec: () => number
  formState: FormState
  setFormState: ReactSetState<FormState>
  queue: string[]
  taglineRequest: TaglineRequest
  requestId: string
  freeAgentId: string | undefined
  talentAgentId: string | undefined
  podId: string | undefined
}

const InitialReviewFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

function InitialReviewFooter({
  taglineRequest,
  queue,
  getTimeSpentSec,
  requestId,
  freeAgentId,
  talentAgentId,
  podId,
  formState,
  setFormState,
}: Props): JSX.Element {
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, requestId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, {
    freeAgentId,
    talentAgentId,
    podId,
  })

  const [submitAgentReview, { loading: loadingSubmitTaglineReview }] = useMutation<
    SubmitAgentReviewWorkMutation,
    SubmitAgentReviewWorkMutationVariables
  >(TA_SUBMIT_TAGLINE_REVIEW, {
    update(cache) {
      updateCacheOnSubmission(cache, taglineRequest)
    },
  })

  /* TODO (matthewalbrecht): use generated types for variables*/
  const [submitTaglineRequestRejection, { loading: loadingTaglineRequestRejected }] =
    useMutation(TA_TAGLINE_REQUEST_REJECTION, {
      update(cache) {
        updateCacheOnRejection(cache, taglineRequest)
      },
    })

  /* TODO (matthewalbrecht): use generated types for variables*/
  const [submitTaglineRejection, { loading: loadingTaglineRejected }] = useMutation(
    IR_REVIEW_REJECTION,
    {
      update(cache) {
        updateCacheOnRejection(cache, taglineRequest)
      },
    }
  )

  /**
   *
   * @param data data returned from mutation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCompletion(
    resource: 'Tagline Request' | 'Tagline Review' | 'Tagline Submission',
    verb: 'rejected' | 'submitted'
  ) {
    const taglineInfo = `${getJobInfoLabel(taglineRequest)} for ${
      taglineRequest.client.name
    }`

    toast.success(`${resource}: ${taglineInfo} was ${verb}`)

    resetState()

    if (!nextRequestId) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl)
  }

  async function handleSubmit() {
    // Validate that there are equal amount of templates in the sequence and edited templates
    const validatedRating = validateRating()
    if (!validatedRating) {
      return
    }
    const writerSubmission = taglineRequest.writerSubmissions[0]
    if (!writerSubmission) {
      return
    }

    const items = writerSubmission.items.map((item) => ({
      taglineSubmissionItemId: item.id,
      copy: formState.editorChanges[item.id] ?? item.copy,
    }))

    const variables = {
      SubmitTalentAgentReviewInput: {
        taglineSubmissionId: writerSubmission.id,
        rating: validatedRating,
        feedback: formState.feedback,
        timeSpentSec: getTimeSpentSec(),
        items,
      },
    }

    try {
      await submitAgentReview({ variables })
      handleCompletion('Tagline Review', 'submitted')
    } catch {
      toast.error('There was an error submitting your work')
    }
  }

  /**
   * build variables and execute mutation for rejecting tagline request by IR
   * @param rejectionReason reason IR has for rejecting the tagline request
   */
  async function handleRejectTaglineRequest(
    rejectionReason: TaglineRejectionReason
  ) {
    const variables = {
      RejectTaglineRequestByTalentAgentInput: {
        taglineRequestId: taglineRequest.id,
        rejectionReason,
      },
    }

    try {
      await submitTaglineRequestRejection({ variables })
      handleCompletion('Tagline Request', 'rejected')
    } catch {
      toast.error('There was an error rejecting the tagline request')
    }
  }

  /**
   * build variables and execute mutation for rejecting tagline by IR
   * @param taglineRejectionReason reason IR has for rejecting the tagline
   */
  async function handleRejectTagline(
    taglineRejectionReason: TaglineRejectionReason
  ) {
    // Validate that there are equal amount of templates in the sequence and edited templates
    const validatedRating = validateRating()
    if (!validatedRating) {
      return
    }
    const writerSubmission = taglineRequest.writerSubmissions[0]
    if (!writerSubmission) {
      return
    }

    const variables = {
      SubmitTalentAgentRejectionInput: {
        taglineSubmissionId: writerSubmission.id,
        rating: validatedRating,
        feedback: formState.feedback,
        timeSpentSec: getTimeSpentSec(),
        taglineRejectionReason,
      },
    }

    try {
      await submitTaglineRejection({ variables })
      handleCompletion('Tagline Submission', 'rejected')
    } catch {
      toast.error('There was an error rejecting the tagline request')
    }
  }

  /**
   * make sure we have submissions for all of the needed templates
   */
  function validateRating() {
    if (!formState.rating) {
      toast.error('Must provide rating')
      return null
    }

    return formState.rating
  }

  function handleSkipClick() {
    if (!nextRequestId) {
      toast('End of queue')
    }

    resetState()

    history.push(nextRouteUrl)
  }

  /**
   * resets of state values
   */
  function resetState() {
    setFormState(DEFAULT_FORM_STATE)
  }

  const queueLabel = getQueueLabel(queue, requestId)

  const rejectionOptions: RejectionOption<TaglineRejectionReason>[] = [
    {
      rejectionReason: TaglineRejectionReason.JOB_POSTING_CLOSED,
      callback: handleRejectTaglineRequest,
      label: 'Job Posting Closed',
    },
    {
      rejectionReason: TaglineRejectionReason.FA_ALREADY_PLACED,
      callback: handleRejectTaglineRequest,
      label: 'FA Already Placed',
    },
    {
      rejectionReason: TaglineRejectionReason.BAD_JOB_FIT,
      callback: handleRejectTaglineRequest,
      label: 'Bad Job Fit',
    },
    {
      rejectionReason: TaglineRejectionReason.UNUSABLE_TAGLINE,
      callback: handleRejectTagline,
      label: 'Unusable Tagline',
    },
  ]

  return (
    <InitialReviewFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
          <RejectionButton<TaglineRejectionReason>
            rejectionOptions={rejectionOptions}
          />
          <Button
            $type="accept"
            onClick={handleSubmit}
            disabled={
              loadingSubmitTaglineReview ||
              loadingTaglineRequestRejected ||
              loadingTaglineRejected
            }
          >
            Submit
          </Button>
        </HList>
      </Flex>
    </InitialReviewFooterBox>
  )
}

InitialReviewFooter.fragments = {
  taglineRequestInfo: gql`
    fragment AgentReviewFooterInfo on TaglineRequest {
      id
      jobTitle
      employerName
      client {
        id
        name
        talentAgent {
          id
        }
      }
      writerSubmissions {
        id
        items {
          id
        }
      }
    }
  `,
}

export default InitialReviewFooter

/**
 * Used by TA to submit their tagline Review work
 */
const TA_SUBMIT_TAGLINE_REVIEW = gql`
  mutation SubmitAgentReviewWork(
    $SubmitTalentAgentReviewInput: SubmitTalentAgentReviewInput!
  ) {
    submitTalentAgentReview(input: $SubmitTalentAgentReviewInput) {
      # TODO (matthewalbrecht) consider adding taglineRequest to talentAgentReview resource for automatic cache updates
      talentAgentReview {
        id
        taglineSubmission {
          taglineRequest {
            id
            status
          }
        }
      }
    }
  }
`

/**
 * Used by TA to submit their rejections of Tagline Requests
 */
const TA_TAGLINE_REQUEST_REJECTION = gql`
  mutation RejectTaglineRequestByTalentAgent(
    $RejectTaglineRequestByTalentAgentInput: RejectTaglineRequestByTalentAgentInput!
  ) {
    rejectTaglineRequestByTalentAgent(
      input: $RejectTaglineRequestByTalentAgentInput
    ) {
      taglineRequest {
        id
        status
      }
    }
  }
`

/**
 * Used by IR to reject a Tagline
 */
const IR_REVIEW_REJECTION = gql`
  mutation RejectTaglineByInitialReviewer(
    $SubmitTalentAgentRejectionInput: SubmitTalentAgentRejectionInput!
  ) {
    submitTalentAgentRejection(input: $SubmitTalentAgentRejectionInput) {
      talentAgentReview {
        id
        taglineSubmission {
          id
          taglineRequest {
            id
            status
          }
        }
      }
    }
  }
`
