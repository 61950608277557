import React from 'react'
import { generatePath } from 'react-router'
import useEnrichmentQueue from 'hooks/useEnrichmentQueue'
import { DEFUALT_ENRICHMENT_POD_COUNT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import TableBody from 'components/Table/TableBody'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS } from './EnrichmentTable.constants'
import { RowData } from './EnrichmentTable.types'

// TODO: (matthewalbrecht) consider refactoring EnrichmentTable and WriterTable to use same component
export default function EnrichmentTable(): JSX.Element {
  const { queueByPod } = useEnrichmentQueue(DEFUALT_ENRICHMENT_POD_COUNT)

  const rowData: RowData[] | undefined =
    queueByPod &&
    Object.entries(queueByPod).map(([podId, queue]) => ({
      podId,
      queue,
    }))

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData?.map(({ podId, queue }) => (
          <tr key={podId}>
            <TableBodyItem first content={`${parseInt(podId) + 1}`} />
            <TableBodyActionList>
              {queue[0] && (
                <TableBodyAction
                  to={generatePath(ROUTE_PATHS.ENRICHER_QUEUE_BY_POD, {
                    podId,
                    requestId: queue[0],
                  })}
                  content={`Queue (${queue.length})`}
                />
              )}
            </TableBodyActionList>
          </tr>
        ))}
      </TableBody>
      {rowData && !rowData.length && (
        <TableEmpty message="No Enrichment Requests Found" />
      )}
    </table>
  )
}
