import { FieldPolicy } from '@apollo/client'
import { Reference, relayStylePagination } from '@apollo/client/utilities'
import { RelayFieldPolicy } from '@apollo/client/utilities/policies/pagination'

/**
 * This function wraps apollo clients' `relayStylePagination` helper function
 *
 * Becuase apollo has to merge against a defualt connection object our cache
 * will occasionally end up holding some default values like {edges: [], pageInfo: { hasNextPage: true}}
 * when our initial query to a resource omits these fields
 *
 * On subsequent querys to the resource apollo will check the cache first, looking to see
 * if what we've asked for already exists there
 *
 * Because edges field exists (even though it's an empty array) apollo will avoid
 * going to the server for the initial batch of edges requested (or all edges in cases where
 * we don't specify a `first` param)
 */
export default function wrappedRelayStylePagination<
  TNode extends Reference = Reference
>(
  keyArgs?: FieldPolicy<any>['keyArgs'] // eslint-disable-line @typescript-eslint/no-explicit-any
): RelayFieldPolicy<TNode> {
  let fieldPolicy = relayStylePagination<TNode>(keyArgs)
  const fieldPolicyReadBase = fieldPolicy.read
  const fieldPolicyMergeBase = fieldPolicy.merge

  // the `merge` field can also be a boolean which is a shorthand for options.mergeObjects(existing, incoming)
  if (fieldPolicyReadBase && typeof fieldPolicyMergeBase === 'function') {
    fieldPolicy = {
      ...fieldPolicy,
      /**
       *
       * This wrapper takes the relayStylePagination read function's result and
       * instead passes `undefined` for `edges` in 2 cases.
       *
       * 1. If we see that hasNextPage=true and there is no `first` param in the current query
       * 2. If we see that hasNextPage=true and there is a `first` params and length of edges is less than `first
       */
      read(existing, { args, ...rest }) {
        let readValue = fieldPolicyReadBase(existing, {
          args,
          ...rest,
        })
        const hasNextPage = readValue?.pageInfo?.hasNextPage
        if (
          (hasNextPage && args?.first == null) ||
          (hasNextPage &&
            args?.first != null &&
            readValue?.edges != null &&
            readValue.edges.length < args.first)
        ) {
          readValue = { ...readValue, edges: undefined }
        }
        return readValue
      },
      /**
       * This wrapper also extends the merge function,
       *
       * There are times where we may fetch all the records however we do not request the `hasNextPage` field
       * meaning hasNextPage does not update to `false`, which in turn would cause our read function to always
       * set edges to `undefined`
       *
       * the extension of the merge function will set hasNextPage to false when: args.first is undefined, and
       * the edges on `incoming` exist
       */
      merge(existing, incoming, { args, ...rest }) {
        const mergeValue = fieldPolicyMergeBase(existing, incoming, {
          args,
          ...rest,
        })

        if (!mergeValue) {
          return mergeValue
        }

        if (args && args.first == null && incoming?.edges) {
          mergeValue.pageInfo.hasNextPage = false
        }

        return mergeValue
      },
    }
  }
  return fieldPolicy
}
