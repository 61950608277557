import { ColumnMap } from 'types'

export const columns: ColumnMap = {
  name: {
    label: 'Reviewer',
    id: 'name',
  },
  editedIrAvg: {
    label: 'Edited IR Avg',
    id: 'editedIrAvg',
  },
  editedFrAvg: {
    label: 'Edited FR Avg',
    id: 'editedIrAvg',
  },
  nonEditedIrAvg: {
    label: 'Unedited IR Avg',
    id: 'nonEditedIrAvg',
  },
  nonEditedFrAvg: {
    label: 'Unedited FR Avg',
    id: 'nonEditedFrAvg',
  },
  nonEditedHigherRate: {
    label: 'Unedited TEE Rated Higher',
    id: 'nonEditedHigherRate',
    tooltip:
      'The percentage of times a TEE did not edit a tagline and rated it higher than FR',
  },
  avgTimeSpent: {
    label: 'Avg Time Spent',
    id: 'avgTimeSpent',
  },
  editRate: {
    label: 'Edit Rate',
    id: 'editRate',
  },
}
