import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { HEADERS } from './Clients.constants'
import { Client } from './Clients.types'
import Txt from 'components/Txt'
import { ClientsQuery } from 'generated/graphql'

export default function ClientsTable(): React.ReactElement {
  const { data, loading, error } = useQuery<ClientsQuery>(GET_CLIENTS)

  const clients = data?.clients
    ? getNodes(data.clients).sort((a, b) => sortByProp(a, b, 'name'))
    : []

  return (
    <FullWidthTable
      headers={HEADERS}
      isLoading={loading}
      isError={Boolean(error)}
      isEmpty={!loading && Boolean(data?.clients) && !clients.length}
      emptyMessage="Could not find any clients."
      loadingMessage="Loading clients..."
      errorMessage="There was an error fetching clients."
    >
      {clients.map((client) => (
        <ClientsRow key={client.id} client={client} />
      ))}
    </FullWidthTable>
  )
}

type Props = {
  client: Client
}

export function ClientsRow({ client }: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Link
          to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_PREFERENCES, {
            clientId: client.id,
          })}
        >
          <Txt size={14} link>
            {client.name}
          </Txt>
        </Link>
      </BodyData>
      <BodyData>
        <Txt size={14}>{client.email}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{client.talentAgent.name}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {client.isInactive ? (
            EMPTY_CELL_PLACEHOLEDER
          ) : (
            <Icon name="Check" height={14} width={14} primaryFill="text" />
          )}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_CLIENTS = gql`
  query Clients {
    clients(withInactive: true) {
      edges {
        node {
          id
          name
          email
          isInactive
          talentAgent {
            id
            name
          }
        }
      }
    }
  }
`
