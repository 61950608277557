import React from 'react'
import {
  ClientCompanyContainer,
  ActivityDashboardContentRow,
} from './DashboardCard.styles'
import { ActivityInfo } from './DashboardCard.types'
import DashboardCardClientCompanyLogo from './DashboardCardClientCompanyLogo'
import DashboardCardClientHeadshot from './DashboardCardClientHeadshot'
import DashboardCardJobInfoLabel from './DashboardCardJobInfoLabel'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  activityInfo: ActivityInfo
}

export default function DashboardClientsActivityCard({
  activityInfo,
}: Props): React.ReactElement {
  function getJobInfoLabel() {
    return (
      <DashboardCardJobInfoLabel
        jobInfo={activityInfo}
        actionText="has"
        phraseText="submitted a debrief"
      />
    )
  }
  return (
    <ActivityDashboardContentRow>
      <Flex align="flex-start">
        <ClientCompanyContainer>
          <DashboardCardClientHeadshot
            src={activityInfo.clientHeadshot}
            alt={activityInfo.clientName}
          />
          <DashboardCardClientCompanyLogo
            src={activityInfo.companyLogo}
            alt={activityInfo.companyName}
          />
        </ClientCompanyContainer>
        <div>
          {getJobInfoLabel()}
          <Padding top={0.75}>
            <Txt color="textLight" size={12}>
              {activityInfo.dateFromNow}
            </Txt>
          </Padding>
        </div>
      </Flex>
    </ActivityDashboardContentRow>
  )
}
