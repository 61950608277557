import { gql } from '@apollo/client'
import React from 'react'
import {
  TableBox,
  HeaderData,
  HeaderRow,
  Table,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { COLUMNS } from './ClientApplications.constants'
import { Application } from './ClientApplications.types'
import ClientApplicationsTableRow from './ClientApplicationsTableRow'
import Message from 'components/Message'
import Txt from 'components/Txt'

type Props = {
  applications: Application[]
}

function ClientApplicationsTable({ applications }: Props): JSX.Element {
  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {COLUMNS.map(({ label, id, collapse }) => (
                <HeaderData key={id} collapse={collapse}>
                  <Txt size={12} bold noWrap={collapse}>
                    {label}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {applications.map((application) => {
              return (
                <ClientApplicationsTableRow
                  key={application.id}
                  application={application}
                />
              )
            })}
          </tbody>
        </Table>
        {!applications.length && (
          <Message vertical message="No Applications Found" />
        )}
      </TableWrapper>
    </TableBox>
  )
}

ClientApplicationsTable.fragments = {
  tableInfo: gql`
    fragment ClientApplicationsTableInfo on Application {
      id
      ...ClientApplicationsTableRowInfo
    }
    ${ClientApplicationsTableRow.fragments.rowInfo}
  `,
}

export default ClientApplicationsTable
