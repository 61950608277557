import checkRole from 'utils/helpers/checkRole'
import { NavItem } from './OpenNav.types'

export function shouldHideSection(items: NavItem[]): boolean {
  // determine if any of the items will be shown
  return !items.reduce((accumValue, item) => {
    const roleMatch = checkRole(item.roles)
    // if role matches user and the item doesn't have the
    // `hide` flag we return true
    return accumValue || (roleMatch && !item.hide)
  }, false)
}
