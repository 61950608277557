import { gql, useQuery } from '@apollo/client'
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react'
import AutoSuggest, {
  SuggestionsFetchRequestedParams,
  InputProps,
  SuggestionSelectedEventData,
} from 'react-autosuggest'
import { getSortedTalentAgents } from './TalentAgents.helpers'
import { AutoSuggestStyles } from './TalentAgents.styles'
import { SearchSuggestionType, TalentAgentWithClients } from './TalentAgents.types'
import Txt from 'components/Txt'
import {
  Maybe,
  GetTalentAgentsAndClientsQuery,
  GetTalentAgentsAndClientsQueryVariables,
} from 'generated/graphql'

type Props = {
  setSelectedTalentAgent: React.Dispatch<
    React.SetStateAction<Maybe<SearchSuggestionType>>
  >
}

// https://developer.mozilla.org/en/docs/Web/JavaScript/Guide/Regular_Expressions#Using_Special_Characters
function escapeRegexCharacters(str: string) {
  return str.replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
}

export default function TalentAgentsSearchSuggestion({
  setSelectedTalentAgent,
}: Props): React.ReactElement {
  const { data } = useQuery<
    GetTalentAgentsAndClientsQuery,
    GetTalentAgentsAndClientsQueryVariables
  >(GET_TALENT_AGENTS_AND_CLIENTS)
  const appliedFilter = useRef<string | null>('')
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [suggestions, setSuggestions] = useState<SearchSuggestionType[]>([])
  const sortedTalentAgents = useMemo(() => getSortedTalentAgents(data), [data])

  // handles filtering of search results based on search term
  const getSuggestions = useCallback(
    (value: string | null): TalentAgentWithClients[] => {
      const valueTrimmed = value && value.trim()
      if (valueTrimmed === '') {
        return sortedTalentAgents
      }
      if (!valueTrimmed) {
        return []
      }
      const escapedValue = escapeRegexCharacters(valueTrimmed)
      const regex = new RegExp('^' + escapedValue, 'i')
      return sortedTalentAgents.filter((talentAgent) => regex.test(talentAgent.name))
    },
    [sortedTalentAgents]
  )

  // populates search dropdown on initial autofocus
  useEffect(() => {
    setSuggestions(getSuggestions(appliedFilter.current))
  }, [getSuggestions, appliedFilter])

  const allInputProps: InputProps<SearchSuggestionType> = {
    value: searchTerm,
    onChange(_, { newValue }) {
      setSearchTerm(newValue)
    },
    type: 'search',
    placeholder: 'Search\u2026',
    required: true,
    autoFocus: true,
  }

  // render individual element in suggestion list
  function renderSuggestion(suggestion: SearchSuggestionType): JSX.Element {
    return <Txt>{`${suggestion.name} (${suggestion.clients.length} clients)`}</Txt>
  }

  // clear suggestions
  function onSuggestionsClearRequested() {
    setSuggestions([])
    appliedFilter.current = null
  }

  // called everytime suggestions is recalculated
  function onSuggestionsFetchRequested({ value }: SuggestionsFetchRequestedParams) {
    setSuggestions(getSuggestions(value))
    appliedFilter.current = value
  }

  // input value when suggestion is clicked
  function getSuggestionValue(suggestion: SearchSuggestionType): string {
    return `${suggestion.name} (${suggestion.clients.length} clients)`
  }

  function handleSuggestionSelected(
    e: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<SearchSuggestionType>
  ) {
    setSelectedTalentAgent(suggestion)
  }

  return (
    <AutoSuggestStyles>
      <AutoSuggest<SearchSuggestionType>
        suggestions={suggestions}
        onSuggestionsFetchRequested={onSuggestionsFetchRequested}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        getSuggestionValue={getSuggestionValue}
        inputProps={allInputProps}
        renderSuggestion={renderSuggestion}
        shouldRenderSuggestions={() => true}
        onSuggestionSelected={handleSuggestionSelected}
      />
    </AutoSuggestStyles>
  )
}

const GET_TALENT_AGENTS_AND_CLIENTS = gql`
  query GetTalentAgentsAndClients {
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      ...TalentAgentSearch
    }
    clients {
      ...TalentAgentSearchClients
    }
  }
  ${getSortedTalentAgents.fragments.talentAgents}
  ${getSortedTalentAgents.fragments.clients}
`

// TODO: (ericlus) Add clients field to user type
