import { EMPTY_INPUT } from 'utils/constants'

export const DEFUALT_STATE = {
  clientId: EMPTY_INPUT,
  interviewCycleId: EMPTY_INPUT,
  expertRole: EMPTY_INPUT,
  expertCompany: EMPTY_INPUT,
  notes: EMPTY_INPUT,
  due: EMPTY_INPUT,
}

export const ID = {
  FORM: 'addEditExpertRequestForm',
}

export const COPY = {
  CREATE_SUCCESS: 'Expert Request added',
  CREATE_ERROR: 'There was an error adding the expert request',
}
