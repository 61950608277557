import * as React from 'react'
import styled, { css } from 'styled-components/macro'
import { breakpoint } from 'styled-components-breakpoint'

type Props = {
  as?: React.ElementType
  children?: React.ReactNode
  noMax?: boolean
  percentageWidth?: number
  align?: 'center' | 'left'
}

const Container = styled.div<Props>`
  margin: ${({ align = 'center' }) =>
    align === 'center' ? '0 auto' : '0 auto 0 0'};
  padding: 0 ${({ theme }) => theme.rhythm(2.5)};
  ${({ percentageWidth = 100 }) =>
    percentageWidth &&
    css`
      width: ${percentageWidth}%;
    `}

  ${breakpoint('tablet')`
    padding: 0 ${({ theme }) => theme.rhythm(5)};
  `}

  ${({ noMax }) =>
    !noMax &&
    breakpoint('desktop')`
    max-width: 128rem;
  `}
`

export default Container
