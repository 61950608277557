import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import TalentEnablementEditorStatsTable from './TalentEnablementEditorStatsTable'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function TalentEnablementEditorStats(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            TEE Stats
          </Txt>
        </Padding>
      </Container>
      <TalentEnablementEditorStatsTable />
    </ViewBox>
  )
}

TalentEnablementEditorStats.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.TALENT_ENABLEMENT_EDITOR_STATS}
    key={ROUTE_PATHS.TALENT_ENABLEMENT_EDITOR_STATS}
  >
    <TalentEnablementEditorStats />
  </PrivateRoute>,
]
