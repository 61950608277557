import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DropdownOption, ReactFormEvent } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { Client } from 'views/ClientSettings/ClientSettings.types'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import { TOAST } from './ClientTalentAgent.constants'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import { GetTalentAgentsForClientTalentAgentQuery } from 'generated/graphql'

type Props = {
  client: Client
  formId: string
}

function ClientTalentAgent({ client, formId }: Props): React.ReactElement {
  const [talentAgentId, setTalentAgentId] = useState<string>(client.talentAgent.id)
  const { data } =
    useQuery<GetTalentAgentsForClientTalentAgentQuery>(GET_TALENT_AGENTS)
  const talentAgentOptions: DropdownOption[] = buildDropdownOptions(
    data?.talentAgents || []
  )
  const [updateClientTalentAgent] = useMutation(UPDATE_CLIENT_TALENT_AGENT, {
    onCompleted() {
      toast.success(TOAST.UPDATE_SUCCESS)
    },
    onError() {
      toast.error(TOAST.UPDATE_ERROR)
    },
  })

  /**
   * no validation needed for this submit
   */
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    const message = `Are you sure you want to update ${client.name}'s Talent Agent?`

    if (window.confirm(message)) {
      const variables = {
        ModifyClientTalentAgentInput: {
          clientId: client.id,
          talentAgentId: talentAgentId,
        },
      }

      void updateClientTalentAgent({ variables })
    }
  }

  return (
    <form id={formId} onSubmit={handleSubmit}>
      <HList size={0.5} rowGapSize={1}>
        <Dropdown
          options={talentAgentOptions}
          value={talentAgentId}
          onValueChange={(value) => setTalentAgentId(value)}
          placeholder="Select Talent Agent"
          withEmptyOption={false}
        />
      </HList>
    </form>
  )
}

ClientTalentAgent.fragments = {
  ClientTalentAgentInfo: gql`
    fragment ClientTalentAgentInfo on Client {
      talentAgent {
        id
      }
    }
  `,
}

ClientTalentAgent.Skeleton = <RectangleSkeleton height={30} width={200} />

const GET_TALENT_AGENTS = gql`
  query GetTalentAgentsForClientTalentAgent {
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
    }
  }
`

const UPDATE_CLIENT_TALENT_AGENT = gql`
  mutation UpdateClientTalentAgent(
    $ModifyClientTalentAgentInput: ModifyClientTalentAgentInput!
  ) {
    modifyClientTalentAgent(input: $ModifyClientTalentAgentInput) {
      client {
        id
        ...ClientTalentAgentInfo
      }
    }
  }
  ${ClientTalentAgent.fragments.ClientTalentAgentInfo}
`

export default ClientTalentAgent
