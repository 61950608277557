import { ApolloCache } from '@apollo/client'
import { generatePath } from 'react-router'
import { CacheConnection, KeyValue } from 'types'
import { QueueReturn } from 'hooks/useTalentPartnerQueue'
import { CACHE_STRING } from 'utils/cacheString'
import { Typename } from 'utils/enums'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import getNextTotalCount from 'utils/helpers/getNextTotalCount'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import { Params, QueueInfo, QueueType, TaglineRequest } from './FinalReview.types'

/**
 * This will return a few things:
 * 1. the queue - actual array of ids
 * 2. initialPath - path to redirect to incase we are on the `/initial` route
 * 3. the path to redirect to once the work has been submitted, rejected or skipped
 * @param queueType either viewer, free agent, or pod
 * @param queues all the different queues for the queueTypes
 * @param params route params
 * @returns queue info object
 */
export function getQueueInfo(
  queueType: QueueType,
  queues: QueueReturn,
  { talentPartnerId, podId, freeAgentId, requestId }: Params
): QueueInfo {
  switch (queueType) {
    case QueueType.Viewer: {
      const queue = queues.queueByViewer
      const firstId = queue[0]
      const nextId = getNextRequestIdInQueue(queue, requestId)
      return createQueueInfo(
        { queue, firstId, nextId, path: ROUTE_PATHS.PARTNER_QUEUE },
        { talentPartnerId }
      )
    }
    case QueueType.FreeAgent: {
      const queue = queues.queueByClient[freeAgentId] || []
      const firstId = queue[0]
      const nextId = getNextRequestIdInQueue(queue, requestId)
      return createQueueInfo(
        { queue, firstId, nextId, path: ROUTE_PATHS.PARTNER_QUEUE_BY_FREE_AGENT },
        { freeAgentId }
      )
    }
    case QueueType.Pod: {
      const queue = queues.queueByPod[podId] || []
      const firstId = queue[0]
      const nextId = getNextRequestIdInQueue(queue, requestId)
      return createQueueInfo(
        { queue, firstId, nextId, path: ROUTE_PATHS.PARTNER_QUEUE_BY_POD },
        { podId }
      )
    }
  }
}

type CreateQueueInfoParams = {
  queue: string[]
  firstId: string | undefined
  nextId: string | null
  path: string
}

function createQueueInfo(
  { queue, firstId, nextId, path }: CreateQueueInfoParams,
  pathVariables: KeyValue
): QueueInfo {
  return {
    queue,
    initialPath:
      firstId &&
      generatePath(path, {
        ...pathVariables,
        requestId: firstId,
      }),
    nextRouteUrl:
      nextId &&
      generatePath(path, {
        ...pathVariables,
        requestId: nextId,
      }),
  }
}

export function updateCacheAfterSuccess(
  cache: ApolloCache<unknown>,
  taglineRequest: TaglineRequest
): void {
  const clientCacheId = cache.identify({
    id: taglineRequest.client.id,
    __typename: Typename.Client,
  })
  clientCacheId &&
    cache.modify({
      id: clientCacheId,
      fields: {
        [CACHE_STRING.CLIENT_PENDING_FR](
          existing: CacheConnection = { edges: [] },
          { readField }
        ) {
          return {
            ...removeCacheEdgeById(taglineRequest.id, existing, readField),
            totalCount: getNextTotalCount(existing, -1),
          }
        },
      },
    })
  // removes current tagline request from the PENDING_TP_REVIEW query field in the cache (on ROOT_QUERY)
  cache.modify({
    fields: {
      [CACHE_STRING.FR_REVIEW_QUEUE]: (
        existing: CacheConnection = { edges: [] },
        { readField }
      ) => {
        return {
          ...removeCacheEdgeById(taglineRequest.id, existing, readField),
          totalCount: getNextTotalCount(existing, -1),
        }
      },
    },
  })
}
