import React from 'react'
import Button from 'components/Button'

export default function InitialReviewPodsTableNav(): JSX.Element {
  return (
    <Button $type="link" active>
      Initial Review Work Queues
    </Button>
  )
}
