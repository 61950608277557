import React from 'react'
import { sortByProp } from 'utils/sort'
import TableBody from 'components/Table/TableBody'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { GroupedRequests, DateSortableObject } from './CurrentAssignments.types'

type Props<T> = {
  groupedRequests: GroupedRequests<T>
}

const COLUMNS = [
  { id: 'dueDate', name: 'Due Date' },
  { id: 'count', name: 'Items Due' },
]

export default function CurrentAssignmentsByDueDate<T extends DateSortableObject>({
  groupedRequests,
}: Props<T>): JSX.Element {
  /**
   * Convert our grouped requests into a sorted array
   * Object.entries takes `prop: value` converts it into [prop, value]
   */
  const sortedTableRows = Object.entries(groupedRequests).sort((a, b) =>
    sortByProp(a[1][0], b[1][0], 'dateToSortBy')
  )

  const isEmptyTable = !sortedTableRows.length

  // TODO: Abstract this jsx into own component shared by this and WriterTableByDueDate
  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COLUMNS.map((header) => (
            <TableHeaderItem key={header.name} content={header.name} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {sortedTableRows.map(([date, requests]) => (
          <tr key={date}>
            <TableBodyItem first content={date} />
            <TableBodyItem content={requests.length} />
          </tr>
        ))}
      </TableBody>
      {isEmptyTable && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}
