import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import { Params, Status } from './ReportApplicationInfo.types'
import ReportApplicationInfoAlreadySubmitted from './ReportApplicationInfoAlreadySubmitted'
import ReportApplicationInfoContent from './ReportApplicationInfoContent'
import ReportApplicationInfoForm from './ReportApplicationInfoForm'
import ReportApplicationInfoSuccess from './ReportApplicationInfoSuccess'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import PublicRoute from 'components/PublicRoute'
import ViewBox from 'components/ViewBox'
import {
  GetMissingApplicationInfoQuery,
  GetMissingApplicationInfoQueryVariables,
} from 'generated/graphql'

export default function ReportApplicationInfo(): React.ReactElement {
  const [status, setStatus] = useState<Status>(Status.Form)
  const { applicationInfoId } = useParams<Params>()
  const { data } = useQuery<
    GetMissingApplicationInfoQuery,
    GetMissingApplicationInfoQueryVariables
  >(GET_MISSING_APPLICATION_INFO, {
    variables: {
      publicId: applicationInfoId,
    },
  })

  // update status state if we recieve true isCompleted value
  useEffect(() => {
    if (data?.missingApplicationInfoByPublic?.isComplete) {
      setStatus(Status.AlreadySubmitted)
    }
  }, [data?.missingApplicationInfoByPublic?.isComplete])

  const application = data?.missingApplicationInfoByPublic
  const applicationExists = Boolean(application !== undefined)
  return (
    <ViewBox>
      <CenteredBox>
        <Padding vertical={6}>
          {status === Status.Form && (
            <>
              <ReportApplicationInfoContent application={application} />
              <Padding top={6}>
                <RectangleSkeletonWrap
                  showContent={applicationExists}
                  fullWidth
                  width="100%"
                  withViewBox={false}
                  height={300}
                >
                  {application && (
                    <ReportApplicationInfoForm
                      application={application}
                      setStatus={setStatus}
                    />
                  )}
                </RectangleSkeletonWrap>
              </Padding>
            </>
          )}
          {status === Status.Success && <ReportApplicationInfoSuccess />}
          {status === Status.AlreadySubmitted && (
            <ReportApplicationInfoAlreadySubmitted />
          )}
        </Padding>
      </CenteredBox>
    </ViewBox>
  )
}

ReportApplicationInfo.Routes = [
  <PublicRoute
    exact
    path={ROUTE_PATHS.REPORT_APPLICATION_INFO}
    key={ROUTE_PATHS.REPORT_APPLICATION_INFO}
  >
    <ReportApplicationInfo />
  </PublicRoute>,
]

const GET_MISSING_APPLICATION_INFO = gql`
  query GetMissingApplicationInfo($publicId: ID!) {
    missingApplicationInfoByPublic(publicId: $publicId) {
      publicId
      jobTitle
      employerName
      jobUrl
      questions
      isComplete
    }
  }
`
