import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import { Params, Status } from './AddInterviewDebrief.types'
import AddInterviewDebriefDeclined from './AddInterviewDebriefDeclined'
import AddInterviewDebriefForm from './AddInterviewDebriefForm'
import AddInterviewDebriefSuccess from './AddInterviewDebriefSuccess'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PublicRoute from 'components/PublicRoute'
import ViewBox from 'components/ViewBox'
import {
  GetInterviewPublicDebriefQuery,
  GetInterviewPublicDebriefQueryVariables,
} from 'generated/graphql'

export default function AddInterviewDebrief(): React.ReactElement {
  const { interviewPublicId } = useParams<Params>()
  const [status, setStatus] = useState<Status>(Status.Open)
  const { data, loading } = useQuery<
    GetInterviewPublicDebriefQuery,
    GetInterviewPublicDebriefQueryVariables
  >(GET_INTERVIEW_PUBLIC, {
    variables: {
      publicId: interviewPublicId,
    },
  })
  const interview = data?.interviewByPublic

  function getActivePane() {
    if (loading) {
      return <Message message="Loading..." />
    } else if (interview == null) {
      return <Message message="Unable to find interview." />
    } else if (status === Status.Declined || !interview.isActive) {
      return <AddInterviewDebriefDeclined />
    } else if (status === Status.Success) {
      return <AddInterviewDebriefSuccess />
    } else {
      return (
        <AddInterviewDebriefForm
          setStatus={setStatus}
          interview={interview}
          interviewPublicId={interviewPublicId}
        />
      )
    }
  }

  const pane = getActivePane()

  return (
    <ViewBox>
      <Container noMax>
        <Padding vertical={6}>{pane}</Padding>
      </Container>
    </ViewBox>
  )
}

AddInterviewDebrief.Routes = [
  <PublicRoute
    exact
    path={ROUTE_PATHS.ADD_INTERVIEW_DEBRIEF}
    key={ROUTE_PATHS.ADD_INTERVIEW_DEBRIEF}
  >
    <AddInterviewDebrief />
  </PublicRoute>,
]

const GET_INTERVIEW_PUBLIC = gql`
  query GetInterviewPublicDebrief($publicId: ID!) {
    interviewByPublic(publicId: $publicId) {
      publicId
      jobTitle
      employerName
      isActive
      scheduledFor {
        datetime
        timezone
      }
    }
  }
`
