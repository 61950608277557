import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import { formatDatetimeWithTz } from 'views/AddAndEditInterview/AddAndEditInterview.helpers'
import {
  DashboardContentRow,
  InterviewCardBackground,
  StyledTxt,
  ClientCompanyContainer,
  InterviewTimeContainer,
} from './DashboardCard.styles'
import { InterviewInfo } from './DashboardCard.types'
import DashboardCardClientCompanyLogo from './DashboardCardClientCompanyLogo'
import DashboardCardClientHeadshot from './DashboardCardClientHeadshot'
import DashboardCardCompanyLogo from './DashboardCardCompanyLogo'
import DashboardCardJobInfoLabel from './DashboardCardJobInfoLabel'
import Flex from 'components/Flex'
import Padding from 'components/Padding'

type Props = {
  interviewInfo: InterviewInfo
  formatString?: string
  isClientDashboard?: boolean
}

export default function DashboardInterviewCard({
  interviewInfo,
  formatString,
  isClientDashboard,
}: Props): React.ReactElement {
  return (
    <DashboardContentRow>
      <InterviewCardBackground isInterviewOver={interviewInfo.isInterviewOver}>
        <Flex>
          {isClientDashboard ? (
            <Padding right={2}>
              <DashboardCardCompanyLogo
                height={58}
                width={58}
                src={interviewInfo.companyLogo}
                alt={interviewInfo.companyName}
              />
            </Padding>
          ) : (
            <ClientCompanyContainer>
              <DashboardCardClientHeadshot
                src={interviewInfo.clientHeadshot}
                alt={interviewInfo.clientName}
              />
              <DashboardCardClientCompanyLogo
                src={interviewInfo.companyLogo}
                alt={interviewInfo.companyName}
              />
            </ClientCompanyContainer>
          )}
          <DashboardCardJobInfoLabel jobInfo={interviewInfo} />
        </Flex>
        <InterviewTimeContainer>
          <Link
            to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
              clientId: interviewInfo.clientId,
              interviewCycleId: interviewInfo.interviewCycleId,
            })}
          >
            <StyledTxt size={14} color="faGrey5" noWrap>
              {formatDatetimeWithTz(interviewInfo.scheduledFor, formatString)}
            </StyledTxt>
          </Link>
        </InterviewTimeContainer>
      </InterviewCardBackground>
    </DashboardContentRow>
  )
}
