import { InactiveCycleReasons } from 'utils/enums'

export const FIRST = 100

export const COPY = {
  CLOSE_SUCCESS: 'Interview cycle successfully closed',
  CLOSE_ERROR: 'There was an error closing the interview cycle',
  CONFIRM_CLOSE: 'Are you sure you want to permanently close this interview cycle?',
}

export const columns = [
  {
    label: '',
    id: 'expandInterviewCycle',
    collapse: true,
  },
  {
    label: 'Free Agent',
    id: 'freeAgent',
    hideOnClientFiltered: true,
  },
  {
    label: 'Company',
    id: 'company',
  },
  {
    label: 'Job Title',
    id: 'jobTitle',
  },
  {
    label: 'Source',
    id: 'source',
  },
  {
    label: 'Start Date',
    id: 'startDate',
  },
  {
    label: 'Huntr Link',
    id: 'huntrLink',
  },
  {
    label: 'Close',
    id: 'closeInterviewCycle',
    collapse: true,
  },
]

export const rejectionOptions = [
  {
    value: InactiveCycleReasons.CLIENT_NOT_INTERESTED,
    label: 'Client Not Interested',
  },
  {
    value: InactiveCycleReasons.REJECTED_AFTER_SCREEN,
    label: 'Rejected After Screen',
  },
  {
    value: InactiveCycleReasons.REJECTED_AFTER_ON_SITE,
    label: 'Rejected After On-site',
  },
  {
    value: InactiveCycleReasons.GHOSTED,
    label: 'Ghosted',
  },
  {
    value: InactiveCycleReasons.JOB_CLOSED,
    label: 'Job Closed',
  },
  {
    value: InactiveCycleReasons.INTERNAL_CANDIDATE,
    label: 'Internal Candidate',
  },
  {
    value: InactiveCycleReasons.ACCEPTED_THIS_JOB,
    label: 'Accepted This Job',
  },
  {
    value: InactiveCycleReasons.ACCEPTED_ANOTHER_JOB,
    label: 'Accepted Another Job',
  },
  {
    value: InactiveCycleReasons.OTHER,
    label: 'Other',
  },
]
