import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import getQueueLabel from 'utils/helpers/getQueueLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { DEFAULT_FORM_STATE } from './SourcedJobsReview.constants'
import { getNextRouteUrl } from './SourcedJobsReview.helpers'
import { FormState } from './SourcedJobsReview.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

const SourcedJobsReviewFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  queue: string[]
  sourcedJobId: string
  freeAgentId: string
  setFormState: ReactSetState<FormState>
}

export default function SourcedJobsReviewFooter({
  queue,
  sourcedJobId,
  freeAgentId,
  setFormState,
}: Props): JSX.Element {
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, sourcedJobId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, {
    freeAgentId,
  })
  const queueLabel = getQueueLabel(queue, sourcedJobId)
  /**
   * resets of state values
   */
  function resetState() {
    setFormState(DEFAULT_FORM_STATE)
  }

  function handleSkipClick() {
    if (!nextRequestId) {
      toast('End of queue')
    }

    resetState()

    history.push(nextRouteUrl)
  }

  return (
    <SourcedJobsReviewFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
        </HList>
      </Flex>
    </SourcedJobsReviewFooterBox>
  )
}
