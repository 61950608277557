import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { COLUMNS } from './SourcingReview.constants'
import { SourcedJob } from './SourcingReview.types'
import SourcingReviewRow from './SourcingReviewRow'
import Txt from 'components/Txt'

type Props = {
  sourcedJobs: SourcedJob[] | null | undefined
}

export default function SourcingReviewTable({
  sourcedJobs,
}: Props): React.ReactElement {
  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {COLUMNS.map((column) => (
                <HeaderData key={column.id}>
                  <Txt size={12} bold>
                    {column.label}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {sourcedJobs?.map((sourcedJob) => {
              return (
                <SourcingReviewRow key={sourcedJob.id} sourcedJob={sourcedJob} />
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </TableBox>
  )
}
