import { Reference } from '@apollo/client'
import { ReadFieldFunction } from '@apollo/client/cache/core/types/common'

type Edge<T> = {
  node: T
}
export type Connection<T> = {
  edges?: Edge<T>[]
}

export default function removeCacheEdgeById(
  itemId: string,
  queryResult: Connection<Reference>,
  readField: ReadFieldFunction
): Connection<Reference> {
  const newEdges = queryResult.edges?.filter((edge: Edge<Reference>) => {
    return readField('id', edge.node) !== itemId
  })
  return { ...queryResult, edges: newEdges }
}
