import React from 'react'
import { Company } from '../CompanyDetail.types'
import Container from 'components/Container'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

type Props = {
  company: Company
}

export default function CompanyDetails({ company }: Props): React.ReactElement {
  return (
    <ViewBox>
      <Padding top={3} bottom={6}>
        <Container percentageWidth={75} align="left">
          <Txt>
            {company.companyDescription
              ? company.companyDescription
              : 'No summary found.'}
          </Txt>
        </Container>
      </Padding>
    </ViewBox>
  )
}
