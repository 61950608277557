import { EMPTY_INPUT, SOURCED_JOB_REJECTION_MAP } from 'utils/constants'
import { SourcedJobRejectionReason } from 'utils/enums'

export const DEFAULT_FORM_STATE = {
  reviewNotes: EMPTY_INPUT,
  rating: null,
}

export const rejectionOptions = [
  {
    rejectionReason: SourcedJobRejectionReason.COMPANY_BRAND,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.COMPANY_BRAND],
  },
  {
    rejectionReason: SourcedJobRejectionReason.COMPANY_INDUSTRY,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.COMPANY_INDUSTRY],
  },
  {
    rejectionReason: SourcedJobRejectionReason.COMPANY_WRONG_STAGE,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.COMPANY_WRONG_STAGE],
  },
  {
    rejectionReason: SourcedJobRejectionReason.COMPANY_FIT_OTHER,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.COMPANY_FIT_OTHER],
  },
  {
    rejectionReason: SourcedJobRejectionReason.TOO_SENIOR,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.TOO_SENIOR],
  },
  {
    rejectionReason: SourcedJobRejectionReason.TOO_JUNIOR,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.TOO_JUNIOR],
  },
  {
    rejectionReason: SourcedJobRejectionReason.WRONG_POSITION,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.WRONG_POSITION],
  },
  {
    rejectionReason: SourcedJobRejectionReason.ALREADY_SOURCED_APPLIED,
    label:
      SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.ALREADY_SOURCED_APPLIED],
  },
  {
    rejectionReason: SourcedJobRejectionReason.WRONG_LOCATION,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.WRONG_LOCATION],
  },
  {
    rejectionReason: SourcedJobRejectionReason.OTHER,
    label: SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.OTHER],
  },
]
