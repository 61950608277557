import { useQuery, gql } from '@apollo/client'
import React from 'react'
import {
  avgRatingCopy,
  avgTimeSpentSecCopy,
  rateAsPctCopy,
} from 'utils/helpers/stats'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { columns } from './TalentEnablementEditorStats.constants'
import { normalizeUsers } from './TalentEnablementEditorStats.helpers'
import { TeeStats } from './TalentEnablementEditorStats.types'
import Txt from 'components/Txt'
import {
  GetTalentEnablementEditorStatsQuery,
  GetTalentEnablementEditorStatsQueryVariables,
} from 'generated/graphql'

export default function TalentEnablementEditorStatsTable(): React.ReactElement {
  const { data, loading, error } = useQuery<
    GetTalentEnablementEditorStatsQuery,
    GetTalentEnablementEditorStatsQueryVariables
  >(GET_TALENT_ENABLEMENT_STATS)

  const users = data?.users && normalizeUsers(data.users)

  return (
    <FullWidthTable
      headers={Object.values(columns)}
      isEmpty={false}
      emptyMessage={'Could not find any TEEs'}
      isLoading={loading}
      loadingMessage={'Loading TEE stats...'}
      isError={Boolean(error)}
      errorMessage={'There was an error loading TEE stats.'}
    >
      {users?.map((user) => (
        <Row key={user.id} user={user} />
      ))}
    </FullWidthTable>
  )
}

type Props = {
  user: TeeStats
}

function Row({
  user: {
    name,
    editedIrAvg,
    editedIrTotal,
    editedFrAvg,
    editedFrTotal,
    nonEditedIrAvg,
    nonEditedIrTotal,
    nonEditedFrAvg,
    nonEditedFrTotal,
    nonEditedTotalHigherIrReviewRate,
    nonEditedTotalHigherIrReviewTotal,
    timeSpentSecAvg,
    timeSpentSecTotal,
    editedRate,
    totalReviews,
  },
}: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Txt size={14} as="span">
          {name}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {avgRatingCopy(editedIrAvg, editedIrTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {avgRatingCopy(editedFrAvg, editedFrTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {avgRatingCopy(nonEditedIrAvg, nonEditedIrTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {avgRatingCopy(nonEditedFrAvg, nonEditedFrTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {rateAsPctCopy(
            nonEditedTotalHigherIrReviewRate,
            nonEditedTotalHigherIrReviewTotal
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {avgTimeSpentSecCopy(timeSpentSecAvg, timeSpentSecTotal)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {rateAsPctCopy(editedRate, totalReviews)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_TALENT_ENABLEMENT_STATS = gql`
  query GetTalentEnablementEditorStats {
    users(anyRole: { isTee: true }) {
      id
      name
      edited: pastRelevantWorkConn(userRole: TALENT_AGENT, isEdited: true) {
        edges {
          node {
            ...TaglineSubmissionInfo
          }
        }
      }
      nonEdited: pastRelevantWorkConn(userRole: TALENT_AGENT, isEdited: false) {
        edges {
          node {
            ...TaglineSubmissionInfo
          }
        }
      }
    }
  }
  fragment TaglineSubmissionInfo on TaglineSubmission {
    id
    talentAgentReviews {
      rating
      timeSpentSec
      taglineRejectionReason
    }
    talentPartnerReviews {
      ratingWriter
      ratingTalentAgent
      taglineRejectionReason
    }
  }
`
