import React from 'react'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import Button from 'components/Button'

export default function ActivityLogButton(): JSX.Element {
  return (
    <Button as={Link} to={ROUTE_PATHS.ACTIVITY_LOG}>
      Full Activity Log
    </Button>
  )
}
