import React from 'react'
import styled, { css } from 'styled-components/macro'
import Flex from './Flex'
import { hideScrollbar } from './MiscStyles'

type BoxProps = {
  unsetHeight: boolean
}

const Box = styled.div<BoxProps>`
  display: flex;
  flex-direction: column;
  ${({ unsetHeight }) =>
    !unsetHeight &&
    css`
      height: 100vh;
    `}
`

const Main = styled(Flex)`
  width: 100%;
  flex: 1;
`

const MainContent = styled.div`
  position: relative;
  width: 100%;
  ${hideScrollbar}
`

type Props = {
  children: React.ReactNode
  footer?: React.ReactNode
  unsetHeight?: boolean
}

export default function ViewBox({
  children,
  footer,
  unsetHeight = false,
}: Props): JSX.Element {
  return (
    <Box unsetHeight={unsetHeight}>
      <Main align="stretch">
        <MainContent>{children}</MainContent>
      </Main>
      {footer}
    </Box>
  )
}
