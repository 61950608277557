import { gql, useMutation, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DropdownOption, ReactFormEvent } from 'types'
import { DATE_FORMAT, EMPTY_INPUT } from 'utils/constants'
import { PAYMENT_TYPE_OPTIONS } from 'views/ExpertNetwork/ExpertNetwork.constants'
import {
  Grid,
  GridItem,
} from 'views/InterviewCycleDetail/interviewCycleDetail.styles'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import { ID, STATUS_OPTIONS, COPY } from './ExpertRequests.constants'
import { getDefaultState } from './ExpertRequests.helpers'
import { ExpertRequestFull, FormData } from './ExpertRequests.types'
import Button from 'components/Button'
import { Dialog, DialogExitButton, DialogFooter } from 'components/DialogMisc'
import Flex from 'components/Flex'
import { Dropdown, RadioGroup, TextArea, TextInput } from 'components/Inputs'
import Message from 'components/Message'
import { SectionHeader } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CancelExpertRequestMutation,
  CancelExpertRequestMutationVariables,
  ExpertPaymentType,
  ExpertRequestStatus,
  GetExpertRequestQuery,
  GetExpertRequestQueryVariables,
  Maybe,
  ModifyExpertRequestStatusMutation,
  ModifyExpertRequestStatusMutationVariables,
} from 'generated/graphql'

type SharedProps = {
  handleClose: () => void
  expertOptions: DropdownOption[]
}
type WrapperProps = {
  expertRequestId: string
  isOpen: boolean
} & SharedProps

type Props = {
  expertRequest: ExpertRequestFull
} & SharedProps

// Wrapper component so that expert request exists at the initial render for the form
export default function EditExpertRequestStatus({
  expertRequestId,
  isOpen,
  handleClose,
  expertOptions,
}: WrapperProps): React.ReactElement {
  const { data, loading } = useQuery<
    GetExpertRequestQuery,
    GetExpertRequestQueryVariables
  >(GET_EXPERT_REQUEST, {
    variables: {
      id: expertRequestId,
    },
  })

  const expertRequest = data?.expertRequest

  return (
    <Dialog
      isOpen={isOpen}
      onDismiss={handleClose}
      aria-label={`Edit expert request`}
      widthVW={[90, { sm: 80, md: 55, lg: 45, xl: 35 }]}
    >
      <Padding horizontal={4}>
        <DialogExitButton onClick={handleClose} />
      </Padding>
      {loading || !expertRequest ? (
        <Padding bottom={4} horizontal={4}>
          <Message message={loading ? 'Loading...' : 'No expert request found'} />
        </Padding>
      ) : (
        <EditExpertRequestStatusForm
          expertOptions={expertOptions}
          handleClose={handleClose}
          expertRequest={expertRequest}
        />
      )}
    </Dialog>
  )
}

function EditExpertRequestStatusForm({
  handleClose,
  expertRequest,
  expertOptions,
}: Props): Maybe<React.ReactElement> {
  // state
  const defaultState = getDefaultState(expertRequest)
  const [formData, setFormData] = useState<FormData>(defaultState)

  // mutations
  const [cancelExpertRequest] = useMutation<
    CancelExpertRequestMutation,
    CancelExpertRequestMutationVariables
  >(CANCEL_EXPERT_REQUEST, {
    onCompleted() {
      toast.success(COPY.CANCEL_SUCCESS)
      handleClose()
    },
    onError() {
      toast.error(COPY.CANCEL_ERROR)
    },
  })

  const [modifyExpertRequest] = useMutation<
    ModifyExpertRequestStatusMutation,
    ModifyExpertRequestStatusMutationVariables
  >(MODIFY_EXPERT_REQUEST, {
    onCompleted() {
      toast.success(COPY.MODIFY_SUCCESS)
      handleClose()
    },
    onError() {
      toast.error(COPY.MODIFY_ERROR)
    },
  })

  // helpers
  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()

    handleModify()
  }

  function handleCancel() {
    // are you sure
    if (!window.confirm(COPY.CONFIRM_CANCEL)) {
      return
    }

    void cancelExpertRequest({
      variables: { input: { expertRequestId: expertRequest.id } },
    })
  }

  function handleModify() {
    void modifyExpertRequest({
      variables: {
        input: {
          expertRequestId: expertRequest.id,
          status: formData.status,
          paymentAmount: formData.paymentAmount,
          paymentType: formData.paymentType,
          additionalPaymentDetails: formData.additionalPaymentDetails,
          callLengthMinutes: formData.callLengthMinutes,
          expertId: formData.expertId,
        },
      },
    })
  }

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<P extends keyof FormData, T extends FormData[P]>(
    value: T,
    prop: P
  ) {
    setFormData({ ...formData, [prop]: value })
  }

  // derived state
  const isCanceled = Boolean(expertRequest.canceledAt)

  /* TODO (matthewalbrecht): confirm that there is no unsaved changes */
  /* TODO (matthewalbrecht): add a "add new expert" button to this form */
  return (
    <>
      <Padding vertical={4} horizontal={4}>
        <Flex justify="space-between" align="flex-end">
          <div>
            <Txt as="h2" bold size={24}>
              {expertRequest.client.name} {isCanceled ? '(Canceled)' : ''}
            </Txt>
            <Padding top={2}>
              <VList size={1}>
                <InlineValueProp
                  value={expertRequest.client.talentAgent.name}
                  label="Talent Agent"
                />
                <InlineValueProp
                  value={dayjs(expertRequest.due).format(DATE_FORMAT.ONLY_DATE)}
                  label="Deadline"
                />
              </VList>
            </Padding>
          </div>
          {!isCanceled && (
            <Button $type="highlight" onClick={handleCancel}>
              Cancel Request
            </Button>
          )}
        </Flex>
        <form id={ID.FORM} onSubmit={handleSubmit}>
          <Padding top={4} bottom={6}>
            <RadioGroup
              label="Status"
              name="status"
              options={STATUS_OPTIONS}
              value={formData.status}
              disabled={isCanceled}
              onValueChange={(value) =>
                handleInputChange(value as ExpertRequestStatus, 'status')
              }
            />
          </Padding>
          <SectionHeader headerCopy="Request Notes" />
          <VList size={2.5}>
            <TextArea
              disabled
              label="Expert Company"
              value={expertRequest.expertCompany}
            />
            <TextArea
              disabled
              label="Expert Role"
              value={expertRequest.expertRole}
            />
            <TextArea
              disabled
              label="Additional Notes"
              value={expertRequest.notes}
            />
          </VList>
          <Padding top={6}>
            <SectionHeader headerCopy="Expert" />
            <Flex justify="flex-start" align="center">
              <Dropdown
                options={expertOptions}
                name="expertId"
                placeholder="Select an Expert"
                withEmptyOption
                value={formData.expertId || EMPTY_INPUT}
                onValueChange={(value) =>
                  handleInputChange(value || null, 'expertId')
                }
              />
            </Flex>
          </Padding>
          <Padding top={6}>
            <SectionHeader headerCopy="Payment Information" />
            <Grid
              gridTemplateColumns="1fr 1fr 1fr 1fr"
              rowGapSize={4}
              colGapSize={2}
            >
              <GridItem column={1} span={2}>
                <TextInput
                  name="paymentAmount"
                  label="Payment Amount"
                  type="number"
                  fullWidth
                  min={0}
                  disabled={isCanceled}
                  value={formData.paymentAmount || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value || null, 'paymentAmount')
                  }
                />
              </GridItem>
              <GridItem column={3} span={2}>
                <TextInput
                  name="callLength"
                  label="Call Length (minutes)"
                  type="number"
                  fullWidth
                  min={0}
                  disabled={isCanceled}
                  value={String(formData.callLengthMinutes) || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(
                      value ? Number(value) : null,
                      'callLengthMinutes'
                    )
                  }
                />
              </GridItem>
              <GridItem column={1} span={4}>
                <RadioGroup
                  label="Payment Type"
                  name="paymentType"
                  options={PAYMENT_TYPE_OPTIONS}
                  disabled={isCanceled}
                  value={formData.paymentType || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value as ExpertPaymentType, 'paymentType')
                  }
                />
              </GridItem>
              <GridItem column={1} span={4}>
                <TextArea
                  name="additionalPaymentDetails"
                  label="Additional Payment Details"
                  fullWidth
                  disabled={isCanceled}
                  value={formData.additionalPaymentDetails || EMPTY_INPUT}
                  onValueChange={(value) =>
                    handleInputChange(value, 'additionalPaymentDetails')
                  }
                />
              </GridItem>
            </Grid>
          </Padding>
        </form>
      </Padding>
      <DialogFooter>
        <Padding vertical={1} horizontal={4}>
          <Flex justify="flex-end">
            <Button
              $type="accept"
              as="input"
              type="submit"
              form={ID.FORM}
              value="Save"
            />
          </Flex>
        </Padding>
      </DialogFooter>
    </>
  )
}

const GET_EXPERT_REQUEST = gql`
  query GetExpertRequest($id: ID!) {
    expertRequest(id: $id) {
      id
      canceledAt
      client {
        id
        name
        talentAgent {
          id
          name
        }
      }
      due
      status
      expertCompany
      expertRole
      notes
      expert {
        id
      }
      paymentAmount
      callLengthMinutes
      paymentType
      additionalPaymentDetails
    }
  }
`

export const CANCEL_EXPERT_REQUEST = gql`
  mutation CancelExpertRequest($input: CancelExpertRequestInput!) {
    cancelExpertRequest(input: $input) {
      expertRequest {
        id
        canceledAt
      }
    }
  }
`

const MODIFY_EXPERT_REQUEST = gql`
  mutation ModifyExpertRequestStatus($input: ModifyExpertRequestStatusInput!) {
    modifyExpertRequestStatus(input: $input) {
      expertRequest {
        id
        status
        paymentAmount
        paymentType
        additionalPaymentDetails
        callLengthMinutes
        expert {
          id
          name
          linkedinUrl
        }
      }
    }
  }
`
