import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams, generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { DashboardContent } from 'components/ClientDashboardCard/ClientDashboardCard.styles'
import { DashboardCard } from 'components/DashboardCard/DashboardCard.styles'
import DashboardCardCompanyLogo from 'components/DashboardCard/DashboardCardCompanyLogo'
import DashboardCardTitle from 'components/DashboardCard/DashboardCardTitle'
import { Tooltip } from 'components/WrappedTooltip/WrappedTooltip'
import { Params } from './ClientDashboardCard.types'
import HList from 'components/HList'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientInterviewCyclesQuery,
  GetClientInterviewCyclesQueryVariables,
} from 'generated/graphql'

export default function ClientDashboardInterviewCycleLogos(): React.ReactElement {
  const { clientId } = useParams<Params>()
  const { data, loading } = useQuery<
    GetClientInterviewCyclesQuery,
    GetClientInterviewCyclesQueryVariables
  >(GET_CLIENT_INTERVIEW_CYCLES, {
    variables: {
      id: clientId,
    },
  })
  const interviewCycles = data?.client?.interviewCycles
    ? getNodes(data.client.interviewCycles)
    : []
  return (
    <>
      <DashboardCard>
        <DashboardCardTitle title="Interview cycles" />
        <DashboardContent>
          {loading && <LoadingIndicator />}
          {!interviewCycles.length && !loading && (
            <Padding all={3}>
              <Txt>There are no interview cycles.</Txt>
            </Padding>
          )}
          <Padding all={3}>
            <HList size={2} rowGapSize={2}>
              {interviewCycles.map((interviewCycle) => (
                <Tooltip
                  key={interviewCycle.id}
                  label={interviewCycle.company?.name}
                >
                  <Link
                    to={generatePath(
                      ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
                      {
                        clientId,
                        interviewCycleId: interviewCycle.id,
                      }
                    )}
                  >
                    <DashboardCardCompanyLogo
                      src={interviewCycle.company?.logoUrl}
                      alt={interviewCycle.company?.name}
                      height={58}
                      width={58}
                    />
                  </Link>
                </Tooltip>
              ))}
            </HList>
          </Padding>
        </DashboardContent>
      </DashboardCard>
    </>
  )
}

const GET_CLIENT_INTERVIEW_CYCLES = gql`
  query GetClientInterviewCycles($id: ID!) {
    client(id: $id) {
      interviewCycles(active: true) {
        edges {
          node {
            id
            company {
              name
              logoUrl
            }
          }
        }
      }
    }
  }
`
