import React from 'react'
import { useLocation } from 'react-router'
import { DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { ChangesProvider } from 'views/Assign/Assign.context'
import AssignHeader from 'views/Assign/AssignHeader'
import AssignTable from 'views/Assign/AssignTable'
import AssignFinalReviewsFooter from 'views/AssignFinalReviews/AssignFinalReviewsFooter'
import AssignFinalReviewsHighlightedClientRow from 'views/AssignFinalReviews/AssignFinalReviewsHighlightedClientRow'
import { ASSIGN_DATA, REASSIGN_DATA, COLUMNS } from './AssignFinalReviews.constants'
import AssignFinalReviewsOverrideColumns from './AssignFinalReviewsOverrideColumns'
import useAssignFinalReviewsData from './useAssignFinalReviewsData'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function AssignFinalReviews(): JSX.Element {
  const { pathname } = useLocation()
  const isReassigning = pathname === ROUTE_PATHS.ASSIGNED_FINAL_REVIEWS
  const { groupedRowData, assigneeOptions } =
    useAssignFinalReviewsData(isReassigning)
  const totalFinalReviews = groupedRowData.reduce(
    (total, groupedRow) => (total += groupedRow.items.length),
    0
  )
  return (
    <ChangesProvider>
      <ViewBox footer={<AssignFinalReviewsFooter />}>
        <Padding top={6}>
          <Container noMax>
            <AssignHeader
              isReassigning={isReassigning}
              assignData={ASSIGN_DATA}
              reassignData={REASSIGN_DATA}
              appendedHeaderCopy={`(${totalFinalReviews})`}
            />
          </Container>
          <Padding top={6}>
            <AssignTable
              isReassigning={isReassigning}
              groupedRowData={groupedRowData}
              assigneeOptions={assigneeOptions}
              columns={COLUMNS}
              workerType="Talent Partner"
              dueDateFormat={DATE_FORMAT.ONLY_DATE}
              ClientRowComponent={AssignFinalReviewsHighlightedClientRow}
              OverrideColumns={AssignFinalReviewsOverrideColumns}
              hideDatePicker
              isFinalReview
            />
          </Padding>
        </Padding>
      </ViewBox>
    </ChangesProvider>
  )
}

AssignFinalReviews.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AVAILABLE_FINAL_REVIEWS}
    key={ROUTE_PATHS.AVAILABLE_FINAL_REVIEWS}
  >
    <AssignFinalReviews />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ASSIGNED_FINAL_REVIEWS}
    key={ROUTE_PATHS.ASSIGNED_FINAL_REVIEWS}
  >
    <AssignFinalReviews />
  </PrivateRoute>,
]
