export enum Role {
  TalentAgent = 'isTalentAgent',
  TalentPartner = 'isTalentPartner',
  Writer = 'isWriter',
  TalentEnablement = 'isTea',
  EnablementEditor = 'isTee',
  Coordinator = 'isCoordinator',
  Applier = 'isApplier',
  Enricher = 'isEnricher',
  Sourcer = 'isSourcer',
  DossierWriter = 'isDossierWriter',
  ExpertCoordinator = 'isExpertCoordinator',
  DataCleaner = 'isDataCleaner',
  SalesCoordinator = 'isSalesCoordinator',
}

export enum LocalApplicationStatus {
  UNASSIGNED = 'UNASSIGNED',
  PENDING_APPLICATION = 'PENDING_APPLICATION',
  APPLIED = 'APPLIED',
  REJECTED = 'REJECTED',
  IN_AIRTABLE = 'IN_AIRTABLE',
  YELLOW_CARDED = 'YELLOW_CARDED',
}

export enum TaglineRejectionReason {
  BAD_JOB_FIT = 'BAD_JOB_FIT',
  UNUSABLE_TAGLINE = 'UNUSABLE_TAGLINE',
  JOB_POSTING_CLOSED = 'JOB_POSTING_CLOSED',
  FA_ALREADY_PLACED = 'FA_ALREADY_PLACED',
  VOIDED = 'VOIDED',
}

export enum EnrichmentRejectionReason {
  JOB_POSTING_CLOSED = 'JOB_POSTING_CLOSED',
  OTHER = 'OTHER',
  ALREADY_ENRICHED = 'ALREADY_ENRICHED',
}

export enum ApplicationRejectionReason {
  JOB_POSTING_CLOSED = 'JOB_POSTING_CLOSED',
  ADDITIONAL_INFORMATION_NEEDED = 'ADDITIONAL_INFORMATION_NEEDED',
  OTHER = 'OTHER',
  COORDINATOR_VOID = 'COORDINATOR_VOID',
}

export enum SourcingRejectionReason {
  INCOMPLETE_CLIENT_INFORMATION = 'INCOMPLETE_CLIENT_INFORMATION',
  OTHER = 'OTHER',
  COORDINATOR_VOID = 'COORDINATOR_VOID',
}

export enum SourcedJobRejectionReason {
  JOB_CLOSED = 'JOB_CLOSED',
  CONTRACT_WORK = 'CONTRACT_WORK',
  WRONG_LOCATION = 'WRONG_LOCATION',
  TOO_SENIOR = 'TOO_SENIOR',
  TOO_JUNIOR = 'TOO_JUNIOR',
  WRONG_POSITION = 'WRONG_POSITION',
  ALREADY_SOURCED_APPLIED = 'ALREADY_SOURCED_APPLIED',
  COMPANY_FIT = 'COMPANY_FIT',
  COMPANY_BRAND = 'COMPANY_BRAND',
  COMPANY_INDUSTRY = 'COMPANY_INDUSTRY',
  COMPANY_WRONG_STAGE = 'COMPANY_WRONG_STAGE',
  COMPANY_FIT_OTHER = 'COMPANY_FIT_OTHER',
  COORDINATOR_VOID = 'COORDINATOR_VOID',
  OTHER = 'OTHER',
}

export enum InactiveCycleReasons {
  CLIENT_NOT_INTERESTED = 'CLIENT_NOT_INTERESTED',
  REJECTED_AFTER_SCREEN = 'REJECTED_AFTER_SCREEN',
  REJECTED_AFTER_ON_SITE = 'REJECTED_AFTER_ON_SITE',
  GHOSTED = 'GHOSTED',
  JOB_CLOSED = 'JOB_CLOSED',
  INTERNAL_CANDIDATE = 'INTERNAL_CANDIDATE',
  ACCEPTED_THIS_JOB = 'ACCEPTED_THIS_JOB',
  ACCEPTED_ANOTHER_JOB = 'ACCEPTED_ANOTHER_JOB',
  OTHER = 'OTHER',
}

export enum SortDateOptions {
  FROM_DUE = 'fromDue',
  FROM_WRITER_SUBMISSION = 'fromWriterSubmission',
  FROM_TALENT_AGENT_REVIEW = 'fromTalentAgentReview',
}

export enum NavStatus {
  Open = 'Open',
  Collapsed = 'Collapsed',
}

export enum Typename {
  Application = 'Application',
  TaglineRequest = 'TaglineRequest',
  Client = 'Client',
  SourcingRequest = 'SourcingRequest',
  DossierRequest = 'DossierRequest',
}

export enum MimeType {
  Plain = 'text/plain',
  Html = 'text/html',
}

export enum TZ_NAMES {
  AMERICA_NEW_YORK = 'America/New_York',
  AMERICA_CHICAGO = 'America/Chicago',
  AMERICA_DENVER = 'America/Denver',
  AMERICA_LOS_ANGELES = 'America/Los_Angeles',
}

export enum InterviewRejectionReason {
  OTHER = 'OTHER',
  DEBRIEF_NEVER_WILL_HAPPEN = 'DEBRIEF_NEVER_WILL_HAPPEN',
  STAFF_CANCELED = 'STAFF_CANCELED',
}
