import React from 'react'
import { Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import ActivityLogTable from 'components/ActivityLogTable/ActivityLogTable'
import AgentCoverageTable from 'components/AgentTable/AgentCoverageTable'
import AgentFilteredTable from 'components/AgentTable/AgentFilteredTable'
import AgentTable from 'components/AgentTable/AgentTable'
import { TabLabel } from 'components/AgentTable/AgentTable.constants'
import ApplierTable from 'components/ApplierTable/ApplierTable'
import ApplierTableByDueDate from 'components/ApplierTable/ApplierTableByDueDate'
import ApplierTableNav from 'components/ApplierTableNav/ApplierTableNav'
import ApplierTableNavByDueDate from 'components/ApplierTableNav/ApplierTableNavByDueDate'
import ActivityLogButton from 'components/Buttons/ActivityLogButton'
import DashboardTabbedTableWrapper from 'components/DashboardTabbedTableWrapper/DashboardTabbedTableWrapper'
import DashboardTableWrapper from 'components/DashboardTableWrapper/DashboardTableWrapper'
import DossierTable from 'components/DossierTable/DossierTable'
import EnrichmentTable from 'components/EnrichmentTable/EnrichmentTable'
import EnrichmentTableNav from 'components/EnrichmentTable/EnrichmentTableNav'
import FinalReviewTable from 'components/FinalReviewTable/FinalReviewTable'
import FinalReviewTableNav from 'components/FinalReviewTableNav/FinalReviewTableNav'
import InitialReviewPodsTable from 'components/InitialReviewPodsTable/InitialReviewPodsTable'
import InitialReviewPodsTableNav from 'components/InitialReviewPodsTable/InitialReviewPodsTableNav'
import SourcerTable from 'components/SourcerTable/SourcerTable'
import SourcerTableByAssignee from 'components/SourcerTable/SourcerTableByAssignee'
import SourcerTableByDueDate from 'components/SourcerTable/SourcerTableByDueDate'
import WriterTable from 'components/WriterTable/WriterTable'
import WriterDueDateTable from 'components/WriterTable/WriterTableByDueDate'
import WriterTableNav from 'components/WriterTableNav/WriterTableNav'
import WriterTableNavByDueDate from 'components/WriterTableNav/WriterTableNavByDueDate'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'

export default function WorkDashboard(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt as="h2" size={24} bold>
            Dashboard
          </Txt>
        </Padding>
        <VList size={8}>
          {checkRole([
            Role.TalentAgent,
            Role.TalentPartner,
            Role.EnablementEditor,
            Role.TalentEnablement,
            Role.Coordinator,
          ]) && (
            <DashboardTabbedTableWrapper
              options={[
                {
                  TableComponent: AgentTable,
                  tabLabel: TabLabel.MY_CANDIDATES,
                },
                {
                  TableComponent: AgentCoverageTable,
                  tabLabel: TabLabel.ALL_CANDIDATES,
                },
                {
                  TableComponent: AgentFilteredTable,
                  tabLabel: TabLabel.BY_TALENT_AGENT,
                },
              ]}
              /* TODO (matthewalbrecht): consider using enums for explicit initial state */
              initialOptionIndex={
                checkRole([Role.TalentPartner])
                  ? AGENT_TABLE_TABS.COVERAGE
                  : checkRole([Role.TalentAgent])
                  ? AGENT_TABLE_TABS.MY_CLIENTS
                  : AGENT_TABLE_TABS.COVERAGE
              }
            />
          )}
          {checkRole([Role.Writer]) && (
            <>
              <DashboardTableWrapper
                TableComponent={WriterTable}
                NavComponent={WriterTableNav}
              />
              <DashboardTableWrapper
                TableComponent={WriterDueDateTable}
                NavComponent={WriterTableNavByDueDate}
              />
            </>
          )}
          {checkRole([Role.Writer]) && (
            <DashboardTableWrapper
              TableComponent={FinalReviewTable}
              NavComponent={FinalReviewTableNav}
            />
          )}
          {checkRole([Role.Applier]) && (
            <>
              <DashboardTableWrapper
                TableComponent={ApplierTable}
                NavComponent={ApplierTableNav}
              />
              <DashboardTableWrapper
                TableComponent={ApplierTableByDueDate}
                NavComponent={ApplierTableNavByDueDate}
              />
            </>
          )}
          {checkRole([Role.Sourcer]) && (
            <DashboardTabbedTableWrapper
              options={[
                {
                  TableComponent: SourcerTable,
                  tabLabel: 'My Sourcing Assignments',
                },
                {
                  TableComponent: SourcerTableByDueDate,
                  tabLabel: 'By Due Date',
                },
                {
                  TableComponent: SourcerTableByAssignee,
                  tabLabel: 'All Sourcing Assignments',
                },
              ]}
            />
          )}
          {checkRole([Role.Enricher]) && (
            <DashboardTableWrapper
              TableComponent={EnrichmentTable}
              NavComponent={EnrichmentTableNav}
            />
          )}
          {checkRole([Role.DossierWriter]) && <DossierTable />}
          {checkRole([Role.EnablementEditor]) && (
            <DashboardTableWrapper
              TableComponent={InitialReviewPodsTable}
              NavComponent={InitialReviewPodsTableNav}
            />
          )}
          <Padding bottom={4}>
            <Padding bottom={2}>
              <ActivityLogTable />
            </Padding>
            <ActivityLogButton />
          </Padding>
        </VList>
      </Container>
    </ViewBox>
  )
}

WorkDashboard.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.WORK_DASHBOARD}
    key={ROUTE_PATHS.WORK_DASHBOARD}
  >
    <WorkDashboard />
  </PrivateRoute>,
]

const AGENT_TABLE_TABS = {
  MY_CLIENTS: 0,
  COVERAGE: 1,
}
