import { useMutation, gql } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactChangeEvent } from 'types'
import { ERRORS } from 'utils/constants'
import { CREATE_TEMPLATE } from 'gql/mutations'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  CreateTemplateMutation,
  CreateTemplateMutationVariables,
  Template,
} from 'generated/graphql'

type Props = {
  sequenceId: string
}

const StyledInput = styled.input`
  border: none;
  padding: ${({ theme }) => theme.rhythm(0.75)} ${({ theme }) => theme.rhythm(1)};
  font-size: ${({ theme }) => theme.toRems(16)};
`

const AddNewTemplateBox = styled.button`
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  transition: 0.2s ease-out background-color;

  &:hover {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
`

export default function NewTemplateButton({ sequenceId }: Props): JSX.Element {
  const [isActive, setIsActive] = useState(false)
  const [name, setName] = useState('')
  const [createNewTemplate, { error }] = useMutation<
    CreateTemplateMutation,
    CreateTemplateMutationVariables
  >(CREATE_TEMPLATE, {
    errorPolicy: 'all',
    // This updates the local cache for our client, adding the newly created sequence to the record
    update(cache, { data, errors }) {
      const template = data?.createTemplate?.template
      if (template && !errors?.length) {
        cache.modify({
          id: cache.identify({ id: sequenceId, __typename: 'TemplateSequence' }),
          fields: {
            templates(templates: Template[]) {
              const newTemplateRef = cache.writeFragment({
                data: template,
                fragment: gql`
                  fragment NewTemplate on Template {
                    id
                    copy
                    name
                  }
                `,
              })

              return [...templates, newTemplateRef]
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (error?.message.includes(ERRORS.UNIQUE_VIOLATION)) {
      toast.error('Template names must be unique')
    } else if (error) {
      toast.error('There was an error creating the template')
    }
  }, [error])

  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    // mutation for creating new template
    void createNewTemplate({
      variables: {
        CreateTemplateInput: {
          templateSequenceId: sequenceId,
          name,
        },
      },
    })
    // This will flip what is rendered by this component back to a button
    setIsActive(false)
    // resets the inputs value to empty
    setName('')
    event.currentTarget.reset()
  }

  return (
    <AddNewTemplateBox onClick={() => setIsActive(true)}>
      <Padding vertical={3}>
        <Flex justify="center">
          {isActive ? (
            <form onSubmit={handleSubmit}>
              <StyledInput
                autoFocus
                value={name}
                onChange={(e: ReactChangeEvent) => setName(e.currentTarget.value)}
                placeholder="Type name..."
                name="templateName"
              />
              <Padding inline left={1}>
                <Button $type="accept" type="submit">
                  Add
                </Button>
              </Padding>
            </form>
          ) : (
            <Txt>+ Add Template</Txt>
          )}
        </Flex>
      </Padding>
    </AddNewTemplateBox>
  )
}
