import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { useAuthContext } from 'context/auth'
import { APPLICATION_DUE_TZ, EOD_HOUR, EOD_MINUTE, INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import DueDateTableItem from 'components/DueDateTableItem/DueDateTableItem'
import TableBody from 'components/Table/TableBody'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS } from './ApplierTable.constants'
import { buildRowData } from './ApplierTable.helpers'
import { ApplicationStatus, GetViewerApplicationsQuery } from 'generated/graphql'

// TODO: (matthewalbrecht) consider refactoring ApplierTable and WriterTable to use same component
export default function ApplierTable(): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const { data } = useQuery<GetViewerApplicationsQuery>(GET_VIEWER_APPLICATIONS, {
    variables: {
      assigneeId,
      status: ApplicationStatus.PENDING_APPLICATION,
    },
  })
  const rowData = data && data.applications && buildRowData(data.applications)

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData?.map((client) => (
          <tr key={client.name}>
            <TableBodyItem first content={client.name} />
            <TableBodyItem content={client.applications.length} />
            <TableBodyItem>
              <DueDateTableItem
                items={client.applications}
                fromNow
                dueHour={EOD_HOUR}
                dueMinute={EOD_MINUTE}
                dueTz={APPLICATION_DUE_TZ}
              />
            </TableBodyItem>
            <TableBodyActionList>
              {client.applications.length && (
                <TableBodyAction
                  to={generatePath(ROUTE_PATHS.APPLIER_QUEUE_BY_FREE_AGENT, {
                    freeAgentId: client.id,
                    applicationId: INITIAL,
                  })}
                  content="Queue"
                />
              )}
            </TableBodyActionList>
          </tr>
        ))}
      </TableBody>
      {rowData && !rowData.length && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}

/**
 * used by appliers to get all of their applications
 */
const GET_VIEWER_APPLICATIONS = gql`
  query getViewerApplications($assigneeId: ID!, $status: ApplicationStatus!) {
    applications(assigneeId: $assigneeId, status: $status) {
      edges {
        node {
          id
          due
          assignee {
            id
          }
          client {
            id
            name
          }
        }
      }
    }
  }
`
