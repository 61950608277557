import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import { Colors } from 'types'

type Props = {
  collapse?: boolean
  centered?: boolean
  verticalCentered?: boolean
}

export const TableWrapper = styled.div`
  /* This will make the table scrollable when it gets too small */
  display: block;
  max-width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
`

export const HeaderData = styled.th<Props>`
  text-align: left;
  width: ${({ collapse }) => (collapse ? '0.0000000001%' : '1%')};
`

export const BodyData = styled.td<Props>`
  /* Each cell should grow equally */
  /* But "collapsed" cells should be as small as possible */
  width: ${({ collapse }) => (collapse ? '0.0000000001%' : '1%')};

  ${({ centered }) =>
    centered &&
    css`
      text-align: center;
    `}

  ${({ verticalCentered }) =>
    verticalCentered &&
    css`
      vertical-align: middle;
    `}
`

export const HeaderRow = styled.tr`
  border-bottom: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
`

type BodyRowProps = {
  color?: keyof Colors
}

export const BodyRow = styled.tr<BodyRowProps>`
  &:nth-child(odd) {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }

  ${({ color, theme }) =>
    color &&
    css`
      background-color: ${theme.color[color]} !important;
    `}
`

export const Table = styled.table`
  /* Make sure the inner table is always as wide as needed */
  width: 100%;
  border-spacing: 0;

  ${HeaderData},
  ${BodyData} {
    margin: 0;
    padding: ${({ theme }) => theme.rhythm(1.5)} ${({ theme }) => theme.rhythm(1)};
    :first-of-type {
      padding-left: ${({ theme }) => theme.rhythm(5)};
    }
    :last-of-type {
      padding-right: ${({ theme }) => theme.rhythm(5)};
    }
  }
`

export const TableBox = styled.div`
  /* This is required to make the table full-width */
  display: block;
  max-width: 100%;
`

export const EditLink = styled(Link)`
  transition: fill ease-out 0.2s;
  &:hover .primaryFill {
    fill: ${({ theme }) => theme.color.faGrey4};
  }
`

export const CheckboxLabel = styled.label`
  padding: ${({ theme }) => theme.rhythm(1)};
  padding-left: 0;
`
