import { ToastContainer } from 'react-toastify'
import styled from 'styled-components/macro'

const WrappedToastContainer = styled(ToastContainer)`
  /* stylelint-disable block-no-empty */
  .Toastify__toast-container {
  }
  /* stylelint-enable block-no-empty */

  .Toastify__close-button > * {
    fill: rgba(255, 255, 255);
  }

  .Toastify__toast {
    border-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.color.text};
    font-family: ${({ theme }) => theme.font.primary};
    padding-left: ${({ theme }) => theme.rhythm(2)};
  }
  .Toastify__progress-bar {
    background: ${({ theme }) => theme.color.faGrey2};
  }
  .Toastify__toast--error {
    background-color: ${({ theme }) => theme.color.error};
    color: ${({ theme }) => theme.color.white};
    line-height: 1.4;

    .Toastify__progress-bar {
      background: rgba(255, 255, 255, 0.7);
    }
  }
  /* stylelint-disable block-no-empty */
  .Toastify__toast--warning {
  }
  /* stylelint-enable block-no-empty */
  .Toastify__toast--success {
    background-color: ${({ theme }) => theme.color.success};
    color: ${({ theme }) => theme.color.white};
    line-height: 1.4;

    .Toastify__progress-bar {
      background: rgba(255, 255, 255, 0.7);
    }
  }
  .Toastify__toast-body {
    padding: ${({ theme }) => theme.rhythm(1)} 0;
    padding-right: ${({ theme }) => theme.rhythm(1)};
  }
`

export default WrappedToastContainer
