import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuthContext } from 'context/auth'
import useCopyClipboard from 'hooks/useCopyClipboard'
import { DATE_FORMAT, ROLE_GROUPS } from 'utils/constants'
import { getApplicationStatusCopy } from 'utils/helpers/applications'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import Editor from 'components/Editor/Editor'
import ImportantIndicator from 'components/ImportantIndicator/ImportantIndicator'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { Application } from './FinalCopy.types'
import FinalCopyInfo from './FinalCopyInfo'
import ExternalLink from 'components/ExternalLink'
import { TextArea } from 'components/Inputs'
import Label from 'components/Label'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  application: Application
  isQueuePage: boolean
}

function FinalCopyLeft({ application, isQueuePage }: Props): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const clipboard = useCopyClipboard({
    onSuccess() {
      toast.success('Copied to clipboard!')
    },
  })

  const isCurrentAssignee = application.assignee?.id === assigneeId
  const showPrivateInfo =
    isQueuePage && (checkRole(ROLE_GROUPS.CORE_TEAM) || isCurrentAssignee)
  const missingApplicationInfoAnswers =
    application.priorMissingApplicationInfos[0]?.answers

  return (
    <VList size={4}>
      <div>
        {application.isImportant && (
          <Padding bottom={2.5}>
            <ImportantIndicator />
          </Padding>
        )}
        <Txt as="h2" size={24} bold>
          {application.client.name}
        </Txt>
        <Padding top={1.5}>
          <VList size={1}>
            <InlineValueProp
              value={application.client.talentAgent.name}
              label="Talent Agent"
            />
            {application.client.talentEnabler && (
              <InlineValueProp
                value={application.client.talentEnabler.name}
                label="Talent Enabler"
              />
            )}
            {!isQueuePage && (
              <InlineValueProp
                value={getApplicationStatusCopy(application)}
                label="Status"
              />
            )}
            <InlineValueProp
              value={dayjs(application.createdAt).format(DATE_FORMAT.DATE_AND_TIME)}
              label="Created At"
            />
            {application.assignee && (
              <InlineValueProp value={application.assignee.name} label="Applier" />
            )}
            <InlineValueProp
              value={application.client.locationPrefs}
              label="Location Preferences"
            />
            <InlineValueProp
              value={application.client.remotePrefs}
              label="Remote Preferences"
            />
          </VList>
        </Padding>
      </div>
      {application.client.resumeUrl && (
        <PDFViewer url={application.client.resumeUrl} />
      )}
      <WorkInfoLinks
        resumeUrl={application.client.resumeUrl}
        resumes={application.client.resumes}
        linkedinUrl={application.client.linkedinUrl}
      />
      <Txt link>
        <Link
          to={generatePath(ROUTE_PATHS.CLIENT_APPLICATION_ANSWERS, {
            clientId: application.client.id,
          })}
          target="_blank"
        >
          Application Answer Bank
        </Link>
      </Txt>
      {missingApplicationInfoAnswers && (
        <TextArea
          defaultValue={missingApplicationInfoAnswers}
          label="Missing Application Info Answers"
          height={240}
          disabled
        />
      )}
      {application.huntrHtmlDescription && (
        <Editor
          defaultValue={application.huntrHtmlDescription}
          label="Notes from Huntr"
          height={80}
          disabled
        />
      )}
      {application.client.bio && (
        <TextArea
          defaultValue={application.client.bio}
          label={`${application.client.name} Bio`}
          height={80}
          disabled
        />
      )}
      <div>
        <Label content="Client Info" padding={2.5} />
        <VList size={2}>
          <FinalCopyInfo
            property="Email"
            value={application.client.email}
            clipboard={clipboard}
          />
          {showPrivateInfo && (
            <>
              <FinalCopyInfo
                property="AngelList Email"
                value={application.client.angelListEmail}
                clipboard={clipboard}
              />
              <FinalCopyInfo
                property="AngelList Password"
                value={application.client.angelListPassword}
                clipboard={clipboard}
              />
              <FinalCopyInfo
                property="LinkedIn Email"
                value={application.client.linkedInEmail}
                clipboard={clipboard}
              />
              <FinalCopyInfo
                property="LinkedIn Password"
                value={application.client.linkedInPassword}
                clipboard={clipboard}
              />
            </>
          )}
          <FinalCopyInfo
            property="Disability"
            value={application.client.reportedDisability}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Race"
            value={application.client.reportedRace}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Veteran Status"
            value={application.client.reportedVeteranStatus}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Sponsorship Required"
            value={application.client.sponsorshipRequiredUS}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Gender"
            value={application.client.reportedGender}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Legally Authorized in US"
            value={application.client.legallyAuthorizedWorkUS}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Address"
            value={application.client.address}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Phone Number"
            value={application.client.phoneNumber}
            clipboard={clipboard}
          />
          <FinalCopyInfo
            property="Desired Salary"
            value={application.client.desiredSalary}
            clipboard={clipboard}
          />
          {application.client.questionnaireUrl && (
            <div>
              <Padding bottom={1}>
                <Txt size={14}>Questionnaire Link:&nbsp;</Txt>
              </Padding>
              <ExternalLink
                url={application.client.questionnaireUrl}
                label="Click Here"
              />
            </div>
          )}
        </VList>
      </div>
    </VList>
  )
}

FinalCopyLeft.fragments = {
  applicationInfo: gql`
    fragment FinalCopyLeftInfo on Application {
      id
      huntrHtmlDescription
      isImportant
      priorMissingApplicationInfos {
        answers
      }
      assignee {
        id
        name
      }
      ...ApplicationStatusCopy @skip(if: $skipStatus)
      client {
        id
        name
        email
        linkedinUrl
        resumeUrl
        resumes {
          url
          filename
        }
        bio
        angelListEmail
        angelListPassword
        linkedInPassword
        linkedInEmail
        reportedDisability
        reportedRace
        locationPrefs
        remotePrefs
        reportedVeteranStatus
        sponsorshipRequiredUS
        reportedGender
        legallyAuthorizedWorkUS
        address
        phoneNumber
        questionnaireUrl
        desiredSalary
        talentAgent {
          id
          name
        }
        talentEnabler {
          name
        }
      }
    }
    ${getApplicationStatusCopy.fragments.applicationStatusCopyInfo}
  `,
}

export default FinalCopyLeft
