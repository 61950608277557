import { gql, useQuery } from '@apollo/client'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { HEADERS } from './Contracts.constants'
import ContractsRow from './ContractsRow'
import Button from 'components/Button'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import { ContractsQuery, ContractsQueryVariables } from 'generated/graphql'

const INITIAL_SIZE = 100
const BATCH_SIZE = 100

export default function ContractsTable(): React.ReactElement {
  function handleMoreClick() {
    void fetchMore({
      variables: {
        first: BATCH_SIZE,
        after: pageInfo?.endCursor,
      },
    })
  }

  const { data, loading, error, fetchMore } = useQuery<
    ContractsQuery,
    ContractsQueryVariables
  >(CONTRACTS_QUERY, {
    variables: { first: INITIAL_SIZE },
  })
  const { pageInfo } = data?.contractSends || {}
  const showLoadMoreButton = pageInfo?.hasNextPage
  const contractSends = data?.contractSends ? getNodes(data.contractSends) : []

  return (
    <>
      <FullWidthTable
        headers={HEADERS}
        isEmpty={!loading && Boolean(data?.contractSends) && !contractSends.length}
        isLoading={loading}
        isError={Boolean(error)}
        emptyMessage="Could not find any contracts."
        loadingMessage="Loading contracts..."
        errorMessage="There was an error fetching contracts."
      >
        {contractSends.map((contractSend) => (
          <ContractsRow key={contractSend.id} contractSend={contractSend} />
        ))}
      </FullWidthTable>
      {showLoadMoreButton && (
        <Flex justify="center">
          <Padding top={3}>
            <Button onClick={handleMoreClick}>Load More</Button>
          </Padding>
        </Flex>
      )}
    </>
  )
}

const CONTRACTS_QUERY = gql`
  query Contracts($first: Int!, $after: String) {
    contractSends(first: $first, after: $after) {
      edges {
        node {
          createdAt
          id
          publicShortId
          personalEmail
          firstName
          lastName
        }
      }
      pageInfo {
        hasNextPage
        endCursor
      }
    }
  }
`
