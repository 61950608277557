import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { Typename } from 'utils/enums'
import Icon from 'components/Icon/Icon'
import {
  VoidTaglineRequestByCoordinatorMutation,
  VoidTaglineRequestByCoordinatorMutationVariables,
} from 'generated/graphql'

type Props = {
  itemId: string
}

export default function AssignTaglinesDeleteButton({ itemId }: Props): JSX.Element {
  /**
   * execute the mutation for voiding the tagline request
   */
  function handleClick() {
    const message = 'Are you sure you want to delete this tagline request?'

    if (window.confirm(message)) {
      void voidTaglineRequest({
        variables: {
          VoidTaglineRequestByCoordinatorInput: {
            taglineRequestId: itemId,
          },
        },
      })
    }
  }

  const [voidTaglineRequest, { loading }] = useMutation<
    VoidTaglineRequestByCoordinatorMutation,
    VoidTaglineRequestByCoordinatorMutationVariables
  >(VOID_TAGLINE_REQUEST_BY_COORDINATOR, {
    update(cache) {
      // Remove the selected tagline request from the cache
      cache.evict({
        id: cache.identify({
          id: itemId,
          __typename: Typename.TaglineRequest,
        }),
      })
    },
    onCompleted({ voidTaglineRequestByCoordinator }) {
      if (voidTaglineRequestByCoordinator?.taglineRequest) {
        const { client, employerName } =
          voidTaglineRequestByCoordinator.taglineRequest

        toast.success(
          `Tagline request for ${client.name} @ ${
            employerName || 'Unknown Company'
          } deleted`
        )
      }
    },
    onError() {
      toast.error('There was an error deleting the tagline request')
    },
  })

  return (
    <button onClick={handleClick} disabled={loading}>
      <Icon name="Trash" width={16} height={16} primaryFill="faGrey3" />
    </button>
  )
}

/**
 * Used by coordinator to void (soft delete) a tagline request
 */
const VOID_TAGLINE_REQUEST_BY_COORDINATOR = gql`
  mutation VoidTaglineRequestByCoordinator(
    $VoidTaglineRequestByCoordinatorInput: VoidTaglineRequestByCoordinatorInput!
  ) {
    voidTaglineRequestByCoordinator(input: $VoidTaglineRequestByCoordinatorInput) {
      taglineRequest {
        id
        jobTitle
        employerName
        client {
          id
          name
        }
      }
    }
  }
`
