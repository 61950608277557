import React from 'react'
import styled from 'styled-components/macro'
import Flex from 'components/Flex'

type Props = {
  leftContent: React.ReactNode
  rightContent: React.ReactNode
}

export const DualScrollContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`

const ScrollableDiv = styled.div`
  display: flex;
  padding: ${({ theme }) => theme.rhythm(5)};
  padding-top: ${({ theme }) => theme.rhythm(2)};
  width: 100%;
  height: 100%;
  overflow-y: auto;
  flex-direction: column;

  & + & {
    border-left: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  }
`

const DualScrollBox = styled(Flex)`
  width: 100%;
  flex: 1;
  height: 100%;
`

export default function DualScroll({
  leftContent,
  rightContent,
}: Props): JSX.Element {
  return (
    <DualScrollBox align="stretch" justify="flex-start">
      <ScrollableDiv>{leftContent}</ScrollableDiv>
      <ScrollableDiv>{rightContent}</ScrollableDiv>
    </DualScrollBox>
  )
}
