import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import ClientStatusTable from './ClientStatusTable'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function ClientStatus(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            Client Status
          </Txt>
        </Padding>
      </Container>
      <ClientStatusTable />
    </ViewBox>
  )
}

ClientStatus.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_STATUS}
    key={ROUTE_PATHS.CLIENT_STATUS}
  >
    <ClientStatus />
  </PrivateRoute>,
]
