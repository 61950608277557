import styled, { css } from 'styled-components/macro'
import Txt from 'components/Txt'

export const SuggestionRowLogo = styled.img`
  display: block;
  width: ${({ theme }) => theme.rhythm(3)};
  height: ${({ theme }) => theme.rhythm(3)};
`
export const SuggestionRow = styled.div`
  display: grid;
  grid-template-columns: [logo] ${({ theme }) => theme.rhythm(3)} [name] 3fr [domain] 1fr;
  column-gap: ${({ theme }) => theme.rhythm(1)};
  align-items: center;
`

export const SuggestionRowDomain = styled(Txt)`
  justify-self: end;
`

export const Relative = styled.div`
  position: relative;

  > img {
    height: ${({ theme }) => theme.rhythm(2)};
    width: ${({ theme }) => theme.rhythm(2)};
    position: absolute;
    right: ${({ theme }) => theme.rhythm(2)};
    top: ${({ theme }) => theme.toRems(10)};
  }
`

type Props = {
  logoActive?: boolean
}

// TODO (matthewalbrecht) use Autosuggest component for this styled component, component params make this not straight forward
// https://github.com/styled-components/styled-components/issues/1803
export const AutoSuggestStyles = styled.div<Props>`
  /* stylelint-disable block-no-empty */
  .react-autosuggest__container {
  }
  .react-autosuggest__container--open {
  }
  .react-autosuggest__input {
    border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
    border-radius: ${({ theme }) => theme.borderRadius};
    padding: ${({ theme }) => theme.rhythm(1)};
    color: ${({ theme }) => theme.color.text};
    width: 100%;

    ${({ logoActive }) =>
      logoActive &&
      css`
        padding-right: ${({ theme }) => theme.rhythm(5)};
      `}
  }
  .react-autosuggest__input--open {
  }
  .react-autosuggest__input--focused {
  }
  .react-autosuggest__suggestions-container {
  }
  .react-autosuggest__suggestions-container--open {
  }
  .react-autosuggest__suggestions-list {
  }
  .react-autosuggest__suggestion {
    padding: ${({ theme }) => theme.rhythm(1)} ${({ theme }) => theme.rhythm(1)};
    border-radius: ${({ theme }) => theme.borderRadius};
    &:hover {
      background-color: ${({ theme }) => theme.color.faGrey1};
    }
  }
  .react-autosuggest__suggestion--first {
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
  .react-autosuggest__section-container {
  }
  .react-autosuggest__section-container--first {
  }
  .react-autosuggest__section-title {
  }
  /* stylelint-enable block-no-empty */
`
