import React from 'react'
import ClientJobsList from './ClientJobsList'
import ViewBox from 'components/ViewBox'

type Props = {
  clientId: string
}

export default function ClientJobs({ clientId }: Props): React.ReactElement {
  return (
    <ViewBox>
      <ClientJobsList clientId={clientId} />
    </ViewBox>
  )
}
