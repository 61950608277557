import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { groupDataByProp } from './CurrentAssignment.helpers'
import CurrentAssignmentSection from './CurrentAssignmentSection'
import {
  SortableTaglineRequest,
  SortableApplication,
  SortableSourcingRequest,
  DateSortableEnrichmentRequest,
} from './CurrentAssignments.types'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import {
  GetAssignedTaglineRequestsQuery,
  GetAssignedTaglineRequestsQueryVariables,
} from 'generated/graphql'

export default function CurrentAssignments(): JSX.Element {
  const { data, loading, error } = useQuery<
    GetAssignedTaglineRequestsQuery,
    GetAssignedTaglineRequestsQueryVariables
  >(GET_ASSIGNED_TAGLINE_REQUESTS)

  const taglineRequestsRaw = data?.taglineRequests && getNodes(data.taglineRequests)

  /**
   * Add properties to each request for easy sorting and grouping
   */
  const taglineRequests: SortableTaglineRequest[] =
    taglineRequestsRaw?.map((request) => ({
      ...request,
      assigneeName: request.assignee?.name ?? '',
      dateToSortBy: dayjs(request.due),
    })) || []
  const applications: SortableApplication[] =
    data?.applications?.edges.map(({ node: request }) => ({
      ...request,
      // We query PENDING_APPLICATION which by definition must have a due date
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      due: request.due!,
      assigneeName: request.assignee?.name ?? '',
      dateToSortBy: dayjs(request.due ?? undefined),
    })) || []
  const sourcingRequests: SortableSourcingRequest[] =
    data?.sourcingRequests?.edges.map(({ node: request }) => ({
      ...request,
      assigneeName: request.assignee?.name ?? '',
      dateToSortBy: dayjs(request.due),
    })) || []
  const enrichmentRequests =
    data?.enrichmentRequests?.edges.map(({ node: request }) => ({
      ...request,
      dateToSortBy: dayjs(request.due),
    })) || []

  /**
   * build all the table data for this view
   */

  const taglineRequestsByDueDate = groupDataByProp<SortableTaglineRequest, 'due'>(
    taglineRequests,
    'due'
  )
  const taglineRequestsByAssigneeName = groupDataByProp<
    SortableTaglineRequest,
    'assigneeName'
  >(taglineRequests, 'assigneeName')
  const applicationsByDueDate = groupDataByProp<SortableApplication, 'due'>(
    applications,
    'due'
  )
  const applicationsByAssigneeName = groupDataByProp<
    SortableApplication,
    'assigneeName'
  >(applications, 'assigneeName')
  const sourcingRequestsByDueDate = groupDataByProp<SortableSourcingRequest, 'due'>(
    sourcingRequests,
    'due'
  )
  const sourcingRequestsByAssigneeName = groupDataByProp<
    SortableSourcingRequest,
    'assigneeName'
  >(sourcingRequests, 'assigneeName')
  const enrichmentRequestsByDueDate = groupDataByProp<
    DateSortableEnrichmentRequest,
    'due'
  >(enrichmentRequests, 'due')

  if (loading) {
    return <Message message="Loading current assigned work..." />
  }

  if (error) {
    return (
      <RefreshMessage message="There was an error loading current assignments" />
    )
  }

  return (
    <ViewBox>
      <Container>
        <Padding vertical={6}>
          <VList size={6}>
            <CurrentAssignmentSection<SortableTaglineRequest>
              title="Taglines"
              requestsByAssignee={taglineRequestsByAssigneeName}
              requestsByDueDate={taglineRequestsByDueDate}
            />
            <CurrentAssignmentSection<SortableApplication>
              title="Applications"
              requestsByDueDate={applicationsByDueDate}
              requestsByAssignee={applicationsByAssigneeName}
            />
            <CurrentAssignmentSection<DateSortableEnrichmentRequest>
              title="Enrichment"
              requestsByDueDate={enrichmentRequestsByDueDate}
            />
            <CurrentAssignmentSection<SortableSourcingRequest>
              title="Sourcing Requests"
              requestsByDueDate={sourcingRequestsByDueDate}
              requestsByAssignee={sourcingRequestsByAssigneeName}
            />
          </VList>
        </Padding>
      </Container>
    </ViewBox>
  )
}
CurrentAssignments.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CURRENT_ASSIGNMENTS}
    key={ROUTE_PATHS.CURRENT_ASSIGNMENTS}
  >
    <CurrentAssignments />
  </PrivateRoute>,
]
const GET_ASSIGNED_TAGLINE_REQUESTS = gql`
  query GetAssignedTaglineRequests {
    taglineRequests(status: PENDING_WRITING) {
      edges {
        node {
          id
          due
          assignee {
            id
            name
          }
        }
      }
    }
    applications(inAirtable: false, status: PENDING_APPLICATION) {
      edges {
        node {
          id
          due
          assignee {
            id
            name
          }
        }
      }
    }
    sourcingRequests(isAssigned: true, isOpen: true) {
      edges {
        node {
          id
          due
          assignee {
            id
            name
          }
        }
      }
    }
    enrichmentRequests(
      inAirtable: false
      isSubmitted: false
      orderBy: { field: DUE, direction: ASC }
    ) {
      edges {
        node {
          id
          due
        }
      }
    }
  }
`
