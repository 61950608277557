import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useAuthContext } from 'context/auth'
import { EMPTY_USER_SESSION } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import Message from 'components/Message'
import PublicRoute from 'components/PublicRoute'

export default function Logout(): JSX.Element {
  const history = useHistory()
  const { setUserSession } = useAuthContext()

  useEffect(() => {
    setUserSession(EMPTY_USER_SESSION)
    history.replace(ROUTE_PATHS.HOME)
    // TODO: clear Apollo cache
  }, [history, setUserSession])

  return <Message vertical message="Logging out ..." />
}

Logout.Routes = [
  <PublicRoute exact path={ROUTE_PATHS.LOGOUT} key={ROUTE_PATHS.LOGOUT}>
    <Logout />
  </PublicRoute>,
]
