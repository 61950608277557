import styled from 'styled-components/macro'

export const LogoContainer = styled.div`
  border-radius: ${({ theme }) => theme.borderRadius};
  overflow: hidden;
  background-color: ${({ theme }) => theme.color.white};
  border: 0.1rem solid ${({ theme }) => theme.color.lightGray};
`

type FakeAvatarProps = {
  width?: number
  height?: number
  display?: 'flex'
  align?: React.CSSProperties['alignItems']
  justify?: React.CSSProperties['justifyItems']
}

export const FakeAvatar = styled.div<FakeAvatarProps>`
  width: ${({ width = 48, theme }) => theme.toRems(width)};
  height: ${({ height = 48, theme }) => theme.toRems(height)};
  background-color: ${({ theme }) => theme.color.faGrey1};
  border-radius: ${({ theme }) => theme.borderRadius};
  display: ${({ display = 'flex' }) => display};
  align-items: ${({ align }) => align};
  justify-content: ${({ justify }) => justify};
`

export const JobCard = styled.div`
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  transition: transform 0.2s ease-in-out;
  box-shadow: ${({ theme }) => theme.boxShadow.medium};

  :hover {
    transform: translateX(${({ theme }) => theme.rhythm(0.5)});
  }
`

type LogoImageProps = {
  width?: number
  height?: number
}

export const LogoImage = styled.img<LogoImageProps>`
  width: ${({ width = 48, theme }) => theme.toRems(width)};
  height: ${({ height = 48, theme }) => theme.toRems(height)};
  object-fit: scale-down;
  display: block;
`
