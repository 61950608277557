import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { generatePath, useHistory, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactFormEvent } from 'types'
import { CACHE_STRING } from 'utils/cacheString'
import { EMPTY_INPUT, INTERVIEW_PREP_TYPE_MAP } from 'utils/constants'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import ROUTE_PATHS from 'utils/routePaths'
import useFormState from 'views/ClientTracker/useFormState'
import Icon from 'components/Icon/Icon'
import Checkbox from 'components/Inputs/Checkbox'
import UserSuggestion from 'components/UserSuggestion/UserSuggestion'
import { UserSuggestion as UserSuggestionType } from 'components/UserSuggestion/UserSuggestion.types'
import { getDefaultFormState } from './InterviewPrepRequest.helpers'
import { ID, ROLE_FILTER, COPY } from './InterviewPrepRequests.constants'
import { FormState, InterviewPrepRequest } from './InterviewPrepRequests.types'
import Button from 'components/Button'
import { Dialog, DialogExitButton } from 'components/DialogMisc'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  ModifyInterviewPrepRequestMutation,
  ModifyInterviewPrepRequestMutationVariables,
  VoidInterviewPrepRequestMutation,
  VoidInterviewPrepRequestMutationVariables,
} from 'generated/graphql'

type DialogProps = {
  interviewPrepRequest: InterviewPrepRequest
  isOpen: boolean
}

type Params = {
  interviewPrepRequestId?: string
}

type Assignee = UserSuggestionType

export function InterviewPrepRequestDialog({
  interviewPrepRequest,
  isOpen,
}: DialogProps): React.ReactElement {
  const history = useHistory()
  const { formState, setFormField, setFormState } = useFormState<FormState>(() =>
    getDefaultFormState(interviewPrepRequest)
  )

  const [modifyInterviewPrepRequest] = useMutation<
    ModifyInterviewPrepRequestMutation,
    ModifyInterviewPrepRequestMutationVariables
  >(MODIFY_INTERVIEW_PREP_REQUEST, {
    onCompleted() {
      toast.success(COPY.modifySuccess)
      onClose()
    },
    onError() {
      toast.error(COPY.modifyError)
    },
  })

  const [voidInterviewPrepRequest] = useMutation<
    VoidInterviewPrepRequestMutation,
    VoidInterviewPrepRequestMutationVariables
  >(VOID_INTERVIEW_PREP_REQUEST, {
    update(cache) {
      removeItemFromCacheConnections<VoidInterviewPrepRequestMutation>(
        cache,
        interviewPrepRequest.id,
        [CACHE_STRING.INTERVIEW_PREP_REQUESTS]
      )
    },
    onCompleted() {
      toast.success(COPY.voidSuccess)
      onClose()
    },
    onError() {
      toast.error(COPY.voidError)
    },
  })

  /* TODO (matthewalbrecht): consider creating a hook (& component) for suggestion inputs */
  function handleAssigneeSelection(value: UserSuggestionType | null) {
    const currentAssignees = formState.assignees ?? []
    if (
      value &&
      !currentAssignees.find((suggestion) => value.name === suggestion.name)
    ) {
      setFormState({
        ...formState,
        assignees: [...currentAssignees, value],
        assigneeInput: null,
      })
    }
  }

  /* TODO (matthewalbrecht): consider creating a hook (& component) for suggestion inputs */
  function handleRemoveAssigneeClick(id: string) {
    const newAssignees = formState.assignees?.filter((assignee) => {
      return assignee.id !== id
    })

    setFormField('assignees', newAssignees?.length ? newAssignees : null)
  }

  function onClose() {
    history.push(ROUTE_PATHS.INTERVIEW_PREP_REQUESTS)
  }

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    void modifyInterviewPrepRequest({
      variables: {
        input: {
          interviewPrepRequestId: interviewPrepRequest.id,
          assigneeIds: formState.assignees
            ? formState.assignees.map((item) => item.id)
            : [],
          isScheduled: formState.isScheduled,
        },
      },
    })
  }

  function handleDeleteClick() {
    if (window.confirm(COPY.confirmDelete)) {
      void voidInterviewPrepRequest({
        variables: {
          input: {
            interviewPrepRequestId: interviewPrepRequest.id,
          },
        },
      })
    }
  }

  return (
    <Dialog isOpen={isOpen} onDismiss={onClose} aria-label="Job description details">
      <Padding vertical={4} horizontal={4}>
        <DialogExitButton onClick={onClose} />
        <Txt size={24} bold as="h2">
          {interviewPrepRequest.client.name} —{' '}
          {INTERVIEW_PREP_TYPE_MAP[interviewPrepRequest.interviewPrepType]}
        </Txt>
        {interviewPrepRequest.interviewCycle && (
          <Padding top={2}>
            <Txt
              as={Link}
              to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
                clientId: interviewPrepRequest.client.id,
                interviewCycleId: interviewPrepRequest.interviewCycle.id,
              })}
              link
            >
              {interviewPrepRequest.interviewCycle.employerName
                ? `Interview Cycle: ${interviewPrepRequest.interviewCycle.employerName}`
                : 'Interview Cycle'}
            </Txt>
          </Padding>
        )}
        <Padding top={4}>
          <VList size={3}>
            <TextArea
              label="Notes"
              defaultValue={interviewPrepRequest.notes}
              disabled
            />
            <form id={ID.FORM} onSubmit={handleSubmit}>
              <Padding bottom={3}>
                <Checkbox
                  name="isScheduled"
                  option={{ label: 'Scheduled', value: 'isScheduled' }}
                  onValueChange={(value) => setFormField('isScheduled', value)}
                  checked={formState.isScheduled ?? false}
                />
              </Padding>
              <UserSuggestion
                value={formState.assigneeInput ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  setFormField('assigneeInput', value || null)
                }
                label="Assignees"
                placeholder="Enter assignee name"
                onSelectionValueChange={handleAssigneeSelection}
                roleFilter={ROLE_FILTER}
              />
              {formState.assignees && (
                <Padding top={2}>
                  <HList size={2} rowGapSize={1.5}>
                    {formState.assignees.map((assignee) => (
                      <AssigneeTag
                        assignee={assignee}
                        key={assignee.id}
                        handleRemoveClick={handleRemoveAssigneeClick}
                      />
                    ))}
                  </HList>
                </Padding>
              )}
            </form>

            <Flex justify="space-between">
              <Button $type="reject" type="button" onClick={handleDeleteClick}>
                Delete
              </Button>
              <Button $type="accept" type="submit" form={ID.FORM}>
                Save
              </Button>
            </Flex>
          </VList>
        </Padding>
      </Padding>
    </Dialog>
  )
}

type ButtonProps = {
  interviewPrepRequest: InterviewPrepRequest
}

export function InterviewPrepRequestButton({
  interviewPrepRequest,
}: ButtonProps): React.ReactElement {
  const { interviewPrepRequestId } = useParams<Params>()
  const isOpen = interviewPrepRequestId === interviewPrepRequest.id
  return (
    <div>
      <Link
        to={generatePath(ROUTE_PATHS.INTERVIEW_PREP_REQUEST_DETAIL, {
          interviewPrepRequestId: interviewPrepRequest.id,
        })}
      >
        <Icon name="Expand" height={14} width={14} primaryFill="text" />
      </Link>
      {isOpen && (
        <InterviewPrepRequestDialog
          interviewPrepRequest={interviewPrepRequest}
          isOpen={isOpen}
        />
      )}
    </div>
  )
}

const TagStyled = styled.div`
  background-color: ${({ theme }) => theme.color.faGrey1};
  display: flex;
  align-items: center;
  padding: ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(1)};
  border-radius: ${({ theme }) => theme.borderRadius};
`

type AssigneeTagProps = {
  assignee: Assignee
  handleRemoveClick(id: string): void
}

function AssigneeTag({ assignee, handleRemoveClick }: AssigneeTagProps) {
  return (
    <TagStyled>
      <Padding right={1}>
        <Txt>{assignee.name}</Txt>
      </Padding>
      <button onClick={() => handleRemoveClick(assignee.id)}>
        <Icon
          height={16}
          width={16}
          name="Exit"
          primaryFill="text"
          primaryFillHover="black"
          display="block"
        />
      </button>
    </TagStyled>
  )
}

const MODIFY_INTERVIEW_PREP_REQUEST = gql`
  mutation ModifyInterviewPrepRequest($input: ModifyInterviewPrepRequestInput!) {
    modifyInterviewPrepRequest(input: $input) {
      interviewPrepRequest {
        id
        scheduledAt
        assignees {
          id
        }
      }
    }
  }
`

const VOID_INTERVIEW_PREP_REQUEST = gql`
  mutation VoidInterviewPrepRequest($input: VoidInterviewPrepRequestInput!) {
    voidInterviewPrepRequest(input: $input) {
      interviewPrepRequest {
        id
      }
    }
  }
`

InterviewPrepRequestDialog.fragments = {
  dialogInfo: gql`
    fragment InterviewPrepRequestDialogInfo on InterviewPrepRequest {
      id
      scheduledAt
      assignees {
        id
        name
      }
      interviewPrepType
      notes
    }
  `,
}
