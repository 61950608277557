import React, { useEffect, useState } from 'react'
import { generatePath, useHistory, useParams, useRouteMatch } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import ClientApplications from 'views/ClientApplications/ClientApplications'
import ClientDashboard from 'views/ClientDashboard/ClientDashboard'
import ClientExpertRequests from 'views/ClientExpertRequests/ClientExpertRequests'
import ClientInterviewCycles from 'views/ClientInterviewCycles/ClientInterviewCycles'
import ClientInterviewPreps from 'views/ClientInterviewPreps/ClientInterviewPreps'
import ClientJobs from 'views/ClientJobs/ClientJobs'
import ClientPreferences from 'views/ClientPreferences/ClientPreferences'
import ClientSettings from 'views/ClientSettings/ClientSettings'
import ClientTemplates from 'views/ClientTemplates/ClientTemplates'
import ClientTracker from 'views/ClientTracker/ClientTracker'
import { TABS_INFO } from './ClientConfig.constants'
import { getSelectedTabIndex } from './ClientConfig.helpers'
import { Params } from './ClientConfig.types'
import ClientConfigHeader from './ClientConfigHeader'
import Container from 'components/Container'
import HList from 'components/HList'
import PrivateRoute from 'components/PrivateRoute'
import { Tab, TabList, TabPanel, Tabs } from 'components/StyledTabs'
import Txt from 'components/Txt'

export default function ClientConfig(): React.ReactElement {
  const history = useHistory()
  const { clientId } = useParams<Params>()
  const { path } = useRouteMatch()
  const selectedIndex = getSelectedTabIndex(path)
  const [tabIndex, setTabIndex] = useState(selectedIndex)

  useEffect(() => {
    setTabIndex(getSelectedTabIndex(path))
  }, [path, setTabIndex])

  const handleTabSelection = (index: number) => {
    const selectedTab = TABS_INFO[index]
    if (selectedTab?.route) {
      // update route on tab click
      history.push(generatePath(selectedTab.route, { clientId }))
    }
  }

  return (
    <Tabs onSelect={handleTabSelection} selectedIndex={tabIndex}>
      <ClientConfigHeader clientId={clientId} />
      <TabList>
        <Container noMax>
          <HList size={1}>
            {TABS_INFO.map((tab) => (
              <Tab key={tab.label}>
                <Txt as="span" size={14}>
                  {tab.label}
                </Txt>
              </Tab>
            ))}
          </HList>
        </Container>
      </TabList>
      <TabPanel>
        <ClientDashboard />
      </TabPanel>
      <TabPanel>
        <ClientPreferences clientId={clientId} />
      </TabPanel>
      <TabPanel>
        <ClientTemplates clientId={clientId} />
      </TabPanel>
      <TabPanel>
        {/* TODO (matthewalbrecht) make tableheader sticky */}
        <ClientApplications clientId={clientId} />
      </TabPanel>
      <TabPanel>
        {/* TODO (matthewalbrecht) make tableheader sticky */}
        <ClientInterviewCycles clientId={clientId} />
      </TabPanel>
      <TabPanel>
        {/* TODO (matthewalbrecht) make tableheader sticky */}
        <ClientInterviewPreps clientId={clientId} />
      </TabPanel>
      <TabPanel>
        {/* TODO (matthewalbrecht) make tableheader sticky */}
        <ClientExpertRequests clientId={clientId} />
      </TabPanel>
      <TabPanel>
        <ClientJobs clientId={clientId} />
      </TabPanel>
      <TabPanel>
        <ClientSettings clientId={clientId} />
      </TabPanel>
      <TabPanel>
        <ClientTracker clientId={clientId} />
      </TabPanel>
    </Tabs>
  )
}

ClientConfig.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.FREE_AGENT_CONFIG}
    key={ROUTE_PATHS.FREE_AGENT_CONFIG}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_DASHBOARD}
    key={ROUTE_PATHS.CLIENT_CONFIG_DASHBOARD}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_TRACKER}
    key={ROUTE_PATHS.CLIENT_CONFIG_TRACKER}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_PREFERENCES}
    key={ROUTE_PATHS.CLIENT_CONFIG_PREFERENCES}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_SETTINGS}
    key={ROUTE_PATHS.CLIENT_CONFIG_SETTINGS}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_TEMPLATES}
    key={ROUTE_PATHS.CLIENT_CONFIG_TEMPLATES}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_APPLICATIONS}
    key={ROUTE_PATHS.CLIENT_CONFIG_APPLICATIONS}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLES}
    key={ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLES}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_EXPERT_REQUESTS}
    key={ROUTE_PATHS.CLIENT_CONFIG_EXPERT_REQUESTS}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_PREP_REQUESTS}
    key={ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_PREP_REQUESTS}
  >
    <ClientConfig />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_CONFIG_JOBS}
    key={ROUTE_PATHS.CLIENT_CONFIG_JOBS}
  >
    <ClientConfig />
  </PrivateRoute>,
]
