import React, { useState } from 'react'
import { ReactSetState } from 'types'
import useGlobalKeyUp from 'hooks/useGlobalKeyUp'
import { EMPTY_INPUT } from 'utils/constants'
import Icon from 'components/Icon/Icon'
import SearchSuggestion from 'components/SearchSuggestion/SearchSuggestion'
import { OpenNavSearch, SearchKeyboardShortcutIcon } from './Search.styles'
import { SearchDialog } from 'components/DialogMisc'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import { Maybe } from 'generated/graphql'

type Props = {
  handleClose(): void
  isOpen: boolean
}

function Search({ handleClose, isOpen }: Props): React.ReactElement {
  const [searchTerm, setSearchTerm] = useState<Maybe<string>>(null)

  return (
    <SearchDialog
      isOpen={isOpen}
      onDismiss={() => {
        setSearchTerm(null)
        handleClose()
      }}
      aria-label="website search"
    >
      {isOpen && (
        <SearchInput
          searchTerm={searchTerm}
          setSearchTerm={setSearchTerm}
          handleClose={handleClose}
        />
      )}
    </SearchDialog>
  )
}

type SearchNavButtonProps = {
  collapsed: boolean
}

export function SearchNavButton({
  collapsed,
}: SearchNavButtonProps): React.ReactElement {
  useGlobalKeyUp('s', () => {
    setIsOpen(true)
  })
  const [isOpen, setIsOpen] = useState<boolean>(false)

  return (
    <>
      {collapsed ? (
        <button onClick={() => setIsOpen(true)}>
          <Icon
            name="Search"
            width={28}
            height={28}
            primaryFill="text"
            primaryFillHover="textDark"
          />
        </button>
      ) : (
        <OpenNavSearch onClick={() => setIsOpen(true)}>
          <Icon
            name="Search"
            width={14}
            height={14}
            primaryFill="text"
            primaryFillHover="textDark"
          />
          <Padding horizontal={1} style={{ flex: 1 }}>
            <Txt size={14} color="faGrey3" alignment="left">
              Search...
            </Txt>
          </Padding>
          <SearchKeyboardShortcutIcon justify="center" align="center">
            <Txt size={11} color="faGrey3">
              S
            </Txt>
          </SearchKeyboardShortcutIcon>
        </OpenNavSearch>
      )}
      <Search handleClose={() => setIsOpen(false)} isOpen={isOpen} />
    </>
  )
}

type SearchInputProps = {
  searchTerm: Maybe<string>
  setSearchTerm: ReactSetState<Maybe<string>>
  handleClose(): void
}

export function SearchInput({
  searchTerm,
  setSearchTerm,
  handleClose,
}: SearchInputProps): React.ReactElement {
  return (
    <SearchSuggestion
      value={searchTerm ?? EMPTY_INPUT}
      onValueChange={(value: string) => setSearchTerm(value)}
      onSelectionValueChange={handleClose}
    />
  )
}
