import { EMPTY_INPUT } from 'utils/constants'
import { DEFUALT_STATE } from './AddAndEditInterviewPrepRequest.constants'
import { State } from './AddAndEditInterviewPrepRequest.types'

type Params = {
  clientId: string | null
  interviewCycleId: string | null
}

export function getDefaultState({ clientId, interviewCycleId }: Params): State {
  return {
    ...DEFUALT_STATE,
    clientId: clientId ?? EMPTY_INPUT,
    interviewCycleId: interviewCycleId ?? EMPTY_INPUT,
  }
}
