import { ColumnMap } from 'types'

export const HEADERS: ColumnMap = {
  dossier: {
    label: 'Dossier',
    id: 'dossier',
  },
  feedback: {
    label: 'Feedback',
    id: 'feedback',
    collapse: true,
  },
  rating: {
    label: 'Rating',
    id: 'rating',
  },
  submissionDate: {
    label: 'Submission Date',
    id: 'submissionDate',
  },
}
