import ReactQuill from 'react-quill'
import styled, { css } from 'styled-components/macro'

type StyledEditorProps = {
  readOnly?: boolean
  height?: number
  withIndicator?: boolean
  variables?: RegExpMatchArray | null
}

export const StyledEditor = styled(ReactQuill)<StyledEditorProps>`
  width: 100%;
  & .ql-container {
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
    font-family: ${({ theme }) => theme.font.primary};

    ${({ readOnly, theme }) =>
      readOnly &&
      css`
        border-radius: ${theme.borderRadius};
        border: 0.1rem solid ${theme.color.faGrey2} !important;
        background-color: ${theme.color.disabledInput};
      `}
  }

  & .ql-toolbar {
    border-top-left-radius: ${({ theme }) => theme.borderRadius};
    border-top-right-radius: ${({ theme }) => theme.borderRadius};

    ${({ readOnly }) => readOnly && 'display: none;'}
  }

  & .ql-container strong {
    font-weight: bold;
  }

  & .ql-container em {
    font-style: italic;
  }

  & .ql-editor {
    min-height: ${({ theme, height = 200 }) => theme.toRems(height)};
    resize: vertical;
    position: relative;

    &::after {
      ${({ theme, withIndicator, variables }) =>
        withIndicator &&
        css`
          content: '';
          position: absolute;
          top: ${theme.rhythm(1)};
          right: ${theme.rhythm(1)};
          height: ${theme.toRems(12)};
          min-width: ${theme.toRems(12)};
          border-radius: 100%;

          background-color: ${() => {
            if (!variables) {
              return theme.color.success
            }
            if (variables.length === 1) {
              return theme.color.warning
            }
            return theme.color.errorLight
          }};
        `}
    }
    &::before {
      ${({ theme, withIndicator, variables }) =>
        withIndicator &&
        css`
          content: '${variables?.length || ``}';
          position: absolute;
          top: ${theme.rhythm(1)};
          right: ${theme.rhythm(3)};
          font-family: ${theme.font.primary};
          font-size: ${theme.toRems(10)};
        `}
    }
  }
`

export const EditorBox = styled.div`
  width: 100%;
`
