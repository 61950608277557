import { ColumnMap } from 'types'

export const HEADERS: ColumnMap = {
  avgRating: {
    id: 'avgRating',
    label: 'AVG Rating',
  },
  onesAndTwosRate: {
    id: 'onesAndTwosRate',
    label: '1&2s Rate',
    tooltip: 'Percentage of reviewed work rated 1 or 2',
  },
  rejectionRate: {
    id: 'rejectionRate',
    label: 'Rejection Rate',
  },
}
