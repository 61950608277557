import React from 'react'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

export default function ReportApplicationInfAlreadySubmitted(): React.ReactElement {
  return (
    <>
      <Txt size={18} bold alignment="center">
        You have already completed the form, thank you!
      </Txt>
      <Padding top={2}>
        <Txt alignment="center">You may now close the window.</Txt>
      </Padding>
    </>
  )
}
