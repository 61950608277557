import { gql, useQuery } from '@apollo/client'
import React, { useCallback, useMemo, useState } from 'react'
import AutoSuggest, {
  SuggestionsFetchRequestedParams,
  InputProps,
  SuggestionSelectedEventData,
} from 'react-autosuggest'
import debounce from 'utils/helpers/debounce'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import { getSuggestionValue } from './IndustrySuggestion.helpers'
import { AutoSuggestStyles } from './IndustrySuggestion.styles'
import {
  DebounceFunctionType,
  IndustrySuggestion as IndustrySuggestionType,
} from './IndustrySuggestion.types'
import { IndustrySuggestionItem } from './IndustrySuggestionItem'
import Label from 'components/Label'
import { GetAllIndustriesQuery } from 'generated/graphql'

type Props = {
  value: string
  onValueChange: (value: string) => void
  label?: string
  onSelectionValueChange(value: IndustrySuggestionType | null): void
}

const DEBOUNCE_TIME_MS = 500

export default function IndustrySuggestion({
  value,
  onValueChange,
  onSelectionValueChange,
  label,
}: Props): React.ReactElement {
  const [suggestions, setSuggestions] = useState<IndustrySuggestionType[]>([])
  const { data } = useQuery<GetAllIndustriesQuery>(GET_ALL_INDUSTRIES)
  const industries = useMemo(
    () =>
      data?.industries
        ? getNodes(data.industries).sort((a, b) => sortByProp(a, b, 'name'))
        : [],
    [data?.industries]
  )
  /**
   * filters industries based on search input
   */
  const onSuggestionsFetchRequested = useCallback(
    ({ value }: SuggestionsFetchRequestedParams) => {
      const lowerValue = value.toLowerCase()
      const filteredIndustries = industries.filter((industry) =>
        industry.name.toLowerCase().includes(lowerValue)
      )

      setSuggestions(filteredIndustries)
    },
    [setSuggestions, industries]
  )

  /**
   * debounced onSuggestionsFetchRequested function
   */
  /* TODO (matthewalbrecht): rewrite this to pass linting */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce<DebounceFunctionType>(onSuggestionsFetchRequested, DEBOUNCE_TIME_MS),
    [onSuggestionsFetchRequested]
  )

  /**
   * clear suggestions
   */
  function onSuggestionsClearRequested() {
    setSuggestions([])
  }

  /**
   *
   */
  function handleSuggestionSelected(
    e: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<IndustrySuggestionType>
  ) {
    onSelectionValueChange(suggestion)
  }

  const allInputProps: InputProps<IndustrySuggestionType> = {
    value,
    onChange(_, { newValue }) {
      onValueChange(newValue)

      if (!newValue) {
        onSelectionValueChange(null)
      }
    },
    type: 'search',
    placeholder: 'Enter industry',
  }

  // render individual element in suggestion list
  const renderSuggestion = (suggestion: IndustrySuggestionType) => (
    <IndustrySuggestionItem suggestion={suggestion} />
  )

  return (
    <AutoSuggestStyles>
      {label && <Label content={label} />}
      <AutoSuggest<IndustrySuggestionType>
        suggestions={suggestions}
        getSuggestionValue={getSuggestionValue}
        renderSuggestion={renderSuggestion}
        onSuggestionsFetchRequested={debouncedFetch}
        onSuggestionsClearRequested={onSuggestionsClearRequested}
        inputProps={allInputProps}
        onSuggestionSelected={handleSuggestionSelected}
        shouldRenderSuggestions={() => true}
      />
    </AutoSuggestStyles>
  )
}

const GET_ALL_INDUSTRIES = gql`
  query GetAllIndustries {
    industries {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
