import React, { useState } from 'react'
import { UserSession } from 'types'
import { AuthContext } from 'context/auth'
import { EMPTY_USER_SESSION } from 'utils/constants'

type Props = { [key: string]: unknown; children: React.ReactNode }

export default function AuthProvider({ children, ...props }: Props): JSX.Element {
  const existingUserSession = localStorage.getItem('userSession')
  // We add this in case there is no local storage
  // TODO(cancan101): this is not the correct fallback as there may be fields that are added
  /* TODO (matthewalbrecht): this JSON.parse will require some type guards */
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  const parsedUserSession =
    (existingUserSession && JSON.parse(existingUserSession)) || EMPTY_USER_SESSION
  const [userSession, setUserSession] = useState<UserSession>(parsedUserSession)

  const saveSession = (session: UserSession) => {
    localStorage.setItem('userSession', JSON.stringify(session))
    setUserSession(session)
  }
  return (
    <AuthContext.Provider
      value={{ userSession, setUserSession: saveSession }}
      {...props}
    >
      {children}
    </AuthContext.Provider>
  )
}
