import { DropdownOption } from 'types'
import { AccountPersonaMap } from 'utils/constants'
import { AccountPersona } from 'generated/graphql'

export const CLIENT_ACTIVITY_LOG_INITIAL_SIZE = 50
export const TRIAL_PERIOD_LENGTH_DAYS = 14
export const CLIENT_ACTIVITY_LOG_BATCH_SIZE = 125

/* TODO (matthewalbrecht): genericize this w/ helper function getOptionsFromEnum */

export const ACCOUNT_PERSONA_OPTIONS: DropdownOption[] = Object.values(
  AccountPersona
).map((persona) => ({
  value: persona,
  label: AccountPersonaMap[persona],
}))

export const TOAST = {
  relationshipRequired: 'Relationship strength is required',
  satisfactionRequired: 'Client satisfaction is required',
  accountPersonaRequired: 'Account persona is required',
  createSuccess: 'Status update successfully submitted',
  createError: 'There was an error creating the status update',
}
