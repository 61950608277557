export type GenericGroupedRowData = {
  id: string
  name: string
  items: GenericAssignItem[]
  roleCategory?: string | null
  roleExperience?: string | null
  tagsForTEs?: string[] | null
  talentAgentName?: string
}

export interface GenericAssignItem {
  id: string
  assignee?: Group | null
  client?: Group | null
  finalReviewer?: Group | null
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any | null
}

type Group = {
  id: string
  name: string
  roleCategory?: string | null
  roleExperience?: string | null
  tagsForTEs?: string[] | null
  talentAgent?: {
    name: string
  }
}

export default function groupAssignItems(
  items: GenericAssignItem[],
  isReassigning: boolean,
  keyName?: 'finalReviewer'
): GenericGroupedRowData[] {
  return items.reduce(
    (groupedItems: GenericGroupedRowData[], item: GenericAssignItem) => {
      const groupByProp = isReassigning ? keyName || 'assignee' : 'client'
      // TODO: (matthewalbrecht) conditional typing might be a good idea here
      const groupByItem = item[groupByProp]

      // if the person we are grouping by is null we will ignore when grouping
      if (!groupByItem) {
        return groupedItems
      }

      const existingGroup = groupedItems.find(
        (person) => person.id === groupByItem.id
      )

      if (existingGroup) {
        existingGroup.items.push(item)
      } else {
        groupedItems.push({
          id: groupByItem.id,
          name: groupByItem.name,
          items: [item],
          roleCategory: groupByItem.roleCategory,
          roleExperience: groupByItem.roleExperience,
          tagsForTEs: groupByItem.tagsForTEs,
          talentAgentName: groupByItem.talentAgent?.name,
        })
      }

      return groupedItems
    },
    []
  )
}
