import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { DATE_FORMAT } from 'utils/constants'
import {
  DashboardCard,
  DashboardContent,
} from 'components/DashboardCard/DashboardCard.styles'
import DashboardCardTitle from 'components/DashboardCard/DashboardCardTitle'
import DashboardInterviewCard from 'components/DashboardCard/DashboardInterviewCard'
import { ClientInterviewsType } from './ClientDashboardCard.constants'
import { getClientInterviews } from './ClientDashboardCard.helpers'
import { Params } from './ClientDashboardCard.types'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientInterviewsQuery,
  GetClientInterviewsQueryVariables,
} from 'generated/graphql'

export default function ClientDashboardCardPastInterviews(): React.ReactElement {
  const { clientId } = useParams<Params>()
  const { data, loading } = useQuery<
    GetClientInterviewsQuery,
    GetClientInterviewsQueryVariables
  >(GET_CLIENT_INTERVIEWS, {
    variables: {
      id: clientId,
    },
  })
  const pastInterviews = data?.client
    ? getClientInterviews(data.client, ClientInterviewsType.PAST)
    : []
  return (
    <DashboardCard>
      <DashboardCardTitle title="Past interviews" />
      <DashboardContent>
        {loading && <LoadingIndicator />}
        {!pastInterviews.length && !loading && (
          <Padding all={3}>
            <Txt>There are no past interviews.</Txt>
          </Padding>
        )}
        {pastInterviews.map((upcomingInterviewInfo) => (
          <DashboardInterviewCard
            key={upcomingInterviewInfo.id}
            interviewInfo={upcomingInterviewInfo}
            formatString={DATE_FORMAT.FRIENDLY_DATE_NO_YEAR_AND_TIME}
            isClientDashboard
          />
        ))}
      </DashboardContent>
      <Padding vertical={0.5} />
    </DashboardCard>
  )
}

const GET_CLIENT_INTERVIEWS = gql`
  query GetClientInterviews($id: ID!) {
    client(id: $id) {
      ...ClientInterviews
    }
  }
  ${getClientInterviews.fragments.clientInterviews}
`
