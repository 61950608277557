import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  release: process.env.REACT_APP_SENTRY_RELEASE,
  environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0.25,
  ignoreErrors: ['ResizeObserver loop limit exceeded'],
})
