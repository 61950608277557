export const ID = {
  YELLOW_CARD_DIALOG_LABEL: 'yellowCardDialogLabel',
  YELLOW_CARD_FORM: 'yellowCardForm',
}

export enum YellowCardOption {
  RequiresLoginInfo = 'Login Info Needed (ask TA/TEA)',
  SendToStaff = 'Ask TA/TEA',
  SendToStaffAndFA = 'Ask Free Agent',
}

export const YELLOW_CARD_OPTIONS = [
  {
    label: YellowCardOption.SendToStaffAndFA,
    value: YellowCardOption.SendToStaffAndFA,
  },
  {
    label: YellowCardOption.SendToStaff,
    value: YellowCardOption.SendToStaff,
  },
  {
    label: YellowCardOption.RequiresLoginInfo,
    value: YellowCardOption.RequiresLoginInfo,
  },
]
