import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { DATE_FORMAT, TZ, TZ_OPTIONS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { DateFilter } from './RedactSourcedJobs.constants'
import { groupJobs } from './RedactSourcedJobs.helpers'
import RedactSourcedJobsTable from './RedactSourcedJobsTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { DatePicker, Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetSourcedJobsForRedactionQuery,
  GetSourcedJobsForRedactionQueryVariables,
} from 'generated/graphql'

export default function RedactSourcedJobs(): React.ReactElement {
  const [dateFilter, setDateFilter] = useState<DateFilter>({
    timezone: TZ.MANILA,
    date: dayjs().tz(TZ.MANILA).format(DATE_FORMAT.ONLY_DATE),
  })
  // TODO (matthewalbrecht) paginate these results use size of ~200
  const { data, loading, error, refetch } = useQuery<
    GetSourcedJobsForRedactionQuery,
    GetSourcedJobsForRedactionQueryVariables
  >(GET_SOURCED_JOBS, {
    variables: {
      dateFilter: dateFilter,
    },
  })

  const tableData = useMemo(
    () => data?.sourcedJobs && groupJobs(data.sourcedJobs),
    [data?.sourcedJobs]
  )

  if (loading) {
    return <Message vertical message="Loading sourced jobs..." />
  }

  if (error) {
    return <RefreshMessage message="There was an error loading sourced jobs" />
  }

  if (!tableData) {
    return <Message vertical message="No sourced jobs found" />
  }

  function handleDateFilterChange(value: string, prop: keyof DateFilter) {
    setDateFilter({ ...dateFilter, [prop]: value })
    void refetch({ dateFilter })
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Txt size={24} bold>
            Redact Sourced Jobs
          </Txt>
        </Padding>
        <Padding top={4}>
          <HList size={2}>
            <Dropdown
              value={dateFilter.timezone}
              onValueChange={(value) => handleDateFilterChange(value, 'timezone')}
              options={TZ_OPTIONS}
              withEmptyOption={false}
              label="Timezone"
            />
            <DatePicker
              label="Day"
              value={dateFilter.date}
              onValueChange={(value) => handleDateFilterChange(value, 'date')}
            />
          </HList>
        </Padding>
      </Container>
      <Padding top={4}>
        <RedactSourcedJobsTable tableData={tableData} dateFilter={dateFilter} />
      </Padding>
    </ViewBox>
  )
}

RedactSourcedJobs.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REDACT_SOURCED_JOBS}
    key={ROUTE_PATHS.REDACT_SOURCED_JOBS}
  >
    <RedactSourcedJobs />
  </PrivateRoute>,
]

/**
 * NOTE: If this query's params are updated make sure to update the queryStrings used to update the cache after mutations
 */
const GET_SOURCED_JOBS = gql`
  query GetSourcedJobsForRedaction($dateFilter: DateFilter) {
    sourcedJobs(
      dateFilter: $dateFilter
      isRedacted: false
      isReviewed: false
      onlyActiveClients: true
    ) {
      edges {
        node {
          id
          title
          company: companyEntity {
            name
          }
          url
          createdBy {
            id
            name
          }
          createdAt
          sourcingRequest {
            id
            client {
              id
              name
              talentAgent {
                id
              }
            }
          }
        }
      }
    }
  }
`
