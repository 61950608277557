import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { ConnectionNode, Maybe } from 'types'
import { DATE_FORMAT, INTERVIEW_PREP_TYPE_MAP } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import { getInterviewPrepStatusTagInfo } from 'views/ClientInterviewPreps/ClientInterviewPreps.helpers'
import { INTERVIEW_PREP_HEADERS } from './InterviewCycleDetail.constants'
import { InterviewCycle, InterviewPrepRequest } from './InterviewCycleDetail.types'
import { Grid } from './interviewCycleDetail.styles'
import { SectionHeader, Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'

type Props = {
  loading: boolean
  interviewCycle: Maybe<InterviewCycle>
}

export default function InterviewCycleDetailPrepRequests({
  interviewCycle,
  loading,
}: Props): React.ReactElement {
  /* TODO (matthewalbrecht): sort these */
  function renderHeaders() {
    return INTERVIEW_PREP_HEADERS.map(({ label, id }) =>
      label ? (
        <Txt size={13} bold key={id}>
          {label}
        </Txt>
      ) : (
        <div key={id} />
      )
    )
  }

  const interviewPrepRequests = interviewCycle
    ? getNodes<InterviewPrepRequest>(interviewCycle.interviewPrepRequests)
        .map((request) => ({ ...request, dateToSortBy: dayjs(request.createdAt) }))
        .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))
    : []

  const hasInterviewPrepRequests = Boolean(interviewPrepRequests.length)

  return (
    <div>
      <SectionHeader headerCopy="Interview Prep Requests" />
      {loading && <Txt alignment="center">Loading...</Txt>}
      {!hasInterviewPrepRequests && !loading && (
        <Txt alignment="center">No Interview Prep Requests Found.</Txt>
      )}
      <Grid
        gridTemplateColumns="min-content min-content min-content min-content auto min-content"
        rowGapSize={2}
        colGapSize={1.5}
      >
        {hasInterviewPrepRequests && renderHeaders()}
        {interviewPrepRequests.map((interviewPrepRequest, index) => (
          <Row
            key={interviewPrepRequest.id}
            prepIndex={interviewPrepRequests.length - index}
            interviewPrepRequest={interviewPrepRequest}
          />
        ))}
      </Grid>
    </div>
  )
}

type RowProps = {
  prepIndex: number
  interviewPrepRequest: ConnectionNode<InterviewCycle['interviewPrepRequests']>
}

function Row({ prepIndex, interviewPrepRequest }: RowProps) {
  const { statusColor, statusCopy } =
    getInterviewPrepStatusTagInfo(interviewPrepRequest)

  return (
    <>
      <Txt size={13}>{prepIndex}</Txt>
      <Txt size={13} noWrap>
        {dayjs(interviewPrepRequest.createdAt).format(DATE_FORMAT.ONLY_DATE)}
      </Txt>
      <Txt size={13} noWrap>
        {interviewPrepRequest.due
          ? dayjs(interviewPrepRequest.due).format(DATE_FORMAT.ONLY_DATE)
          : 'Not Available'}
      </Txt>
      <Txt size={13} noWrap>
        {INTERVIEW_PREP_TYPE_MAP[interviewPrepRequest.interviewPrepType]}
      </Txt>
      <div />
      <Tag color={statusColor} small>
        <Txt size={13} noWrap alignment="center">
          {statusCopy}
        </Txt>
      </Tag>
    </>
  )
}

InterviewCycleDetailPrepRequests.fragments = {
  prepRequestInfo: gql`
    fragment PrepRequestInfo on InterviewCycle {
      interviewPrepRequests {
        edges {
          node {
            id
            createdAt
            interviewPrepType
            due
            ...InterviewPrepRequestStatusInfo
          }
        }
      }
    }
    ${getInterviewPrepStatusTagInfo.fragments.statusInfo}
  `,
}
