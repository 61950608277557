import { Rating } from 'types'
import { getAvgRating, getAvgTimeSpentSec } from 'utils/helpers/stats'
import {
  UserAggregatedStats,
  TeeStats,
  User,
} from './TalentEnablementEditorStats.types'

export function normalizeUsers(users: User[]): TeeStats[] {
  return users.map((user) => {
    const userAggregatedStats: UserAggregatedStats = {
      nonEditedIrRatings: [],
      nonEditedFrRatings: [],
      timeSpentSec: [],
      nonEditedTotalHigherIrReview: 0,
      nonEditedTotalHigherIrReviewTotal: 0,
      editedIrRatings: [],
      editedFrRatings: [],
    }

    user.edited.edges.forEach(({ node: submission }) => {
      const initialReview = submission.talentAgentReviews[0]
      const finalReview = submission.talentPartnerReviews[0]

      // only count submissions that have been fully reviewed w/o rejection reason
      if (
        initialReview &&
        !initialReview.taglineRejectionReason &&
        finalReview &&
        !finalReview.taglineRejectionReason
      ) {
        userAggregatedStats.editedIrRatings.push(initialReview.rating as Rating)
        userAggregatedStats.editedFrRatings.push(
          finalReview.ratingTalentAgent as Rating
        )
        if (initialReview.timeSpentSec) {
          userAggregatedStats.timeSpentSec.push(initialReview.timeSpentSec)
        }
      }
    })

    user.nonEdited.edges.forEach(({ node: submission }) => {
      const initialReview = submission.talentAgentReviews[0]
      const finalReview = submission.talentPartnerReviews[0]

      // only count submissions that have been fully reviewed w/o rejection reason
      if (
        initialReview &&
        !initialReview.taglineRejectionReason &&
        finalReview &&
        !finalReview.taglineRejectionReason
      ) {
        userAggregatedStats.nonEditedIrRatings.push(initialReview.rating as Rating)
        userAggregatedStats.nonEditedFrRatings.push(
          finalReview.ratingTalentAgent as Rating
        )
        if (initialReview.timeSpentSec) {
          userAggregatedStats.timeSpentSec.push(initialReview.timeSpentSec)
        }
        if (finalReview.ratingWriter) {
          userAggregatedStats.nonEditedTotalHigherIrReview +=
            initialReview.rating > finalReview.ratingWriter ? 1 : 0
          userAggregatedStats.nonEditedTotalHigherIrReviewTotal++
        }
      }
    })

    const [editedIrAvg, editedIrTotal] = getAvgRating(
      userAggregatedStats.editedIrRatings
    )
    const [editedFrAvg, editedFrTotal] = getAvgRating(
      userAggregatedStats.editedFrRatings
    )
    const [nonEditedIrAvg, nonEditedIrTotal] = getAvgRating(
      userAggregatedStats.nonEditedIrRatings
    )
    const [nonEditedFrAvg, nonEditedFrTotal] = getAvgRating(
      userAggregatedStats.nonEditedFrRatings
    )
    const [timeSpentSecAvg, timeSpentSecTotal] = getAvgTimeSpentSec(
      userAggregatedStats.timeSpentSec
    )
    const totalEdited = user.edited.edges.length
    const totalNonEdited = user.nonEdited.edges.length
    const totalReviews = totalEdited + totalNonEdited
    const editedRate = totalReviews ? totalEdited / totalReviews : NaN
    return {
      id: user.id,
      name: user.name,
      editedIrAvg,
      editedIrTotal,
      editedFrAvg,
      editedFrTotal,
      nonEditedIrAvg,
      nonEditedIrTotal,
      nonEditedFrAvg,
      nonEditedFrTotal,
      nonEditedTotalHigherIrReviewRate:
        userAggregatedStats.nonEditedTotalHigherIrReviewTotal
          ? userAggregatedStats.nonEditedTotalHigherIrReview /
            userAggregatedStats.nonEditedTotalHigherIrReviewTotal
          : NaN,
      nonEditedTotalHigherIrReviewTotal:
        userAggregatedStats.nonEditedTotalHigherIrReviewTotal,
      timeSpentSecAvg,
      timeSpentSecTotal,
      editedRate,
      totalReviews,
    }
  })
}
