import { gql, useQuery, useLazyQuery } from '@apollo/client'
import React, { useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { GET_TALENT_AGENTS } from 'gql/queries'
import {
  FREE_AGENT_REQUIRED_MESSAGE,
  WORK_TYPE_OPTIONS,
} from './TaglineStatus.constants'
import { filterFreeAgents, sortFreeAgents } from './TaglineStatus.helpers'
import { Filter, WorkTypes } from './TaglineStatus.types'
import TaglineStatusHighlightedRow from './TaglineStatusHighlightedRow'
import TaglineStatusRow from './TaglineStatusRow'
import TaglineStatusTable from './TaglineStatusTable'
import Container from 'components/Container'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetFreeAgentQuery,
  GetFreeAgentsQuery,
  GetTalentAgentsQuery,
} from 'generated/graphql'

export default function TaglineStatus(): JSX.Element {
  const [filter, setFilter] = useState<Filter>({
    clientId: '',
    talentAgentId: '',
    workType: WorkTypes.CURRENT,
  })

  const { data, loading, error } = useQuery<GetFreeAgentsQuery>(
    GET_ALL_FA_CURRENT_WORK,
    {
      notifyOnNetworkStatusChange: true,
      errorPolicy: 'all',
    }
  )

  const [
    getClientReviewedWork,
    {
      data: dataClient,
      loading: loadingClient,
      refetch: refetchClient,
      error: errorClient,
    },
  ] = useLazyQuery<GetFreeAgentQuery>(GET_FA, {
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'all',
  })

  const {
    data: usersData,
    loading: usersLoading,
    error: usersError,
  } = useQuery<GetTalentAgentsQuery>(GET_TALENT_AGENTS, {
    errorPolicy: 'all',
  })
  const freeAgentsOptions = buildDropdownOptions(data?.freeAgents || [])
  const talentAgentsOptions = buildDropdownOptions(usersData?.talentAgents || [])

  /**
   * Depending on the workType state we either want to be working with the basis of
   * getFreeAgents query results (current work) or the getFreeAgent query results (past work of single FA)
   */

  // Sort the free agents' and their tagline requests
  const sortedFreeAgents = useMemo(() => {
    const freeAgents =
      filter.workType === WorkTypes.CURRENT
        ? data?.freeAgents
        : dataClient?.client && [dataClient.client]
    return freeAgents ? sortFreeAgents(freeAgents) : []
  }, [data?.freeAgents, dataClient?.client, filter.workType])

  // filter the sorted tagline requests
  const filteredFreeAgents = useMemo(
    () => filterFreeAgents(sortedFreeAgents, filter),
    [filter, sortedFreeAgents]
  )

  /**
   *  sets the new filter object
   * @param value value of filter changed
   * @param filterType type of filter being updated
   */
  function handleTalentAgentFilterChange(value: string) {
    const newFilter = { ...filter, talentAgentId: value }
    setFilter(newFilter)
  }

  /**
   *  sets the new filter object
   * @param value value of filter changed
   * @param filterType type of filter being updated
   */
  function handleClientFilterChange(value: string) {
    const newFilter = { ...filter, clientId: value }

    // must refetch data for specific client if we are looking at reviewed/rejected work
    if (filter.workType === WorkTypes.REVIEWED_REJECTED) {
      void refetchClient({ id: value })
    }

    setFilter(newFilter)
  }

  /**
   * updates workType filter,
   * If we are moving to reviewed/rejected workType we require there to be a free agent selected
   * @param value workType value
   */
  function handleWorkTypeFilterChange(value: string) {
    if (value === WorkTypes.REVIEWED_REJECTED && !filter.clientId) {
      toast.error(FREE_AGENT_REQUIRED_MESSAGE)
    } else {
      setFilter({ ...filter, workType: value })
      // We will fetch data for a single client when this filter changes
      // if dataClient exists we know it's already been called before so we must refetch
      if (dataClient) {
        void refetchClient({ id: filter.clientId })
      } else {
        void getClientReviewedWork({ variables: { id: filter.clientId } })
      }
    }
  }

  if (loading || usersLoading || loadingClient) {
    return (
      <Message
        vertical
        message="Loading current work. This may take up to 30 seconds..."
      />
    )
  }

  if (error || usersError || errorClient) {
    return <RefreshMessage message="The request may have timed out" />
  }

  return (
    <ViewBox>
      <Padding top={6}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            Tagline Status
          </Txt>
          <Padding top={4}>
            <HList size={2}>
              <Dropdown
                label="Free Agent"
                onValueChange={(value) => handleClientFilterChange(value)}
                options={freeAgentsOptions}
                withEmptyOption={filter.workType === WorkTypes.CURRENT}
                defaultValue={filter.clientId}
              />
              <Dropdown
                label="Talent Agent"
                onValueChange={(value) => handleTalentAgentFilterChange(value)}
                options={talentAgentsOptions}
                defaultValue={filter.talentAgentId}
              />
              <Dropdown
                label="Work Type"
                onValueChange={handleWorkTypeFilterChange}
                options={WORK_TYPE_OPTIONS}
                withEmptyOption={false}
                value={filter.workType}
              />
            </HList>
          </Padding>
        </Container>
        <Padding top={6}>
          <TaglineStatusTable
            filteredFreeAgents={filteredFreeAgents}
            filter={filter}
          />
          {!filteredFreeAgents.length && (
            <Flex justify="center" align="center">
              <Padding vertical={6}>
                <Txt>No work to show</Txt>
              </Padding>
            </Flex>
          )}
        </Padding>
      </Padding>
    </ViewBox>
  )
}

TaglineStatus.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.TAGLINE_STATUS}
    key={ROUTE_PATHS.TAGLINE_STATUS}
  >
    <TaglineStatus />
  </PrivateRoute>,
]

const GET_ALL_FA_CURRENT_WORK = gql`
  query GetFreeAgents {
    freeAgents: allClients {
      id
      name
      taglineRequests(isCurrent: true) {
        edges {
          node {
            id
            ...TaglineStatusRowInfo
          }
        }
      }
      ...TaglineStatusHighlightedRowInfo
    }
  }
  ${TaglineStatusHighlightedRow.fragments.workHighlightedRowInfo}
  ${TaglineStatusRow.fragments.workRowInfo}
`

const GET_FA = gql`
  query GetFreeAgent($id: ID!) {
    client(id: $id) {
      id
      name
      taglineRequests(isCurrent: false) {
        edges {
          node {
            id
            ...TaglineStatusRowInfo
          }
        }
      }
      ...TaglineStatusHighlightedRowInfo
    }
  }
  ${TaglineStatusHighlightedRow.fragments.workHighlightedRowInfo}
  ${TaglineStatusRow.fragments.workRowInfo}
`
