import React from 'react'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  first?: boolean
  content?: React.ReactNode
  children?: React.ReactNode
}

function TableBodyItem({ first, content, children }: Props): JSX.Element {
  return (
    <Padding as="td" vertical={1.5} left={1} right={first ? 4 : 1}>
      <Txt as="span" size={14} color="text">
        {content ?? children}
      </Txt>
    </Padding>
  )
}

export default TableBodyItem
