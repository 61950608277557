import { generatePath } from 'react-router'
import { UserSession } from 'types'
import { INITIAL } from 'utils/constants'
import { Role } from 'utils/enums'
import getNodes from 'utils/helpers/getNodes'
import { PageDetail, pages } from 'utils/pages'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import { SearchSuggestion, SearchSuggestionSection } from './SearchSuggestion.types'
import {
  GetClientsConnQuery,
  SearchCompaniesByDomainQuery,
  GetTalentAgentsQuery,
} from 'generated/graphql'

// function to grab value of suggestion once selected given the suggestion object
export function getSuggestionValue(suggestion: SearchSuggestion): string {
  return suggestion.label
}

export function normalizeSearchSuggestions(
  user: UserSession['user'],
  clientsConnection: GetClientsConnQuery['clients'],
  talentAgentsData: GetTalentAgentsQuery['talentAgents']
): SearchSuggestionSection[] {
  const clients = clientsConnection
    ? getNodes(clientsConnection).sort((a, b) => sortByProp(a, b, 'name'))
    : []
  const talentAgents = talentAgentsData ?? []
  const {
    sourcerQueue,
    enrichmentQueue,
    applierQueue,
    writerQueue,
    initialReviewQueue,
    finalReviewQueue,
    taglineFeedback,
    dossierQueue,
    ...nonQueuePages
  } = pages

  /* TODO (matthewalbrecht): consider moving permittedRoles to section level */
  const clientSuggestions = clients.map(({ id, name }) => ({
    label: name,
    subLabel: 'Config',
    to: generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, { clientId: id }),
    permittedRoles: [
      Role.Coordinator,
      Role.TalentPartner,
      Role.TalentAgent,
      Role.TalentEnablement,
      Role.ExpertCoordinator,
    ],
    matches: [name],
  }))

  const clientQueueSections = clients.map(({ id, name }) => {
    return {
      title: `${name}'s Queues`,
      items: [
        {
          label: 'Sourcing Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.SOURCER_QUEUE_BY_FREE_AGENT, {
            freeAgentId: id,
            requestId: INITIAL,
          }),
          permittedRoles: sourcerQueue.permittedRoles,
          matches: [name],
        },
        {
          label: 'Sourced Jobs Review Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.REVIEW_SOURCED_JOBS_QUEUE, {
            freeAgentId: id,
            sourcedJobId: INITIAL,
          }),
          permittedRoles: [Role.TalentAgent, Role.TalentEnablement],
          matches: [name],
        },
        {
          label: 'Tagline Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.WRITER_QUEUE_BY_FREE_AGENT, {
            freeAgentId: id,
            requestId: INITIAL,
          }),
          permittedRoles: writerQueue.permittedRoles,
          matches: [name],
        },
        {
          label: 'Initial Review Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.AGENT_QUEUE_BY_FREE_AGENT, {
            freeAgentId: id,
            requestId: INITIAL,
          }),
          permittedRoles: initialReviewQueue.permittedRoles,
          matches: [name],
        },
        {
          label: 'Final Review Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.PARTNER_QUEUE_BY_FREE_AGENT, {
            freeAgentId: id,
            requestId: INITIAL,
          }),
          permittedRoles: finalReviewQueue.permittedRoles,
          matches: [name],
        },
        {
          label: 'Applying Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.APPLIER_QUEUE_BY_FREE_AGENT, {
            freeAgentId: id,
            applicationId: INITIAL,
          }),
          permittedRoles: applierQueue.permittedRoles,
          matches: [name],
        },
        {
          label: 'Dossier Queue',
          subLabel: 'Queue',
          to: generatePath(ROUTE_PATHS.DOSSIER_QUEUE_BY_FREE_AGENT, {
            freeAgentId: id,
            requestId: INITIAL,
          }),
          permittedRoles: dossierQueue.permittedRoles,
          matches: [name],
        },
      ],
    }
  })

  const talentAgentStatsSections = talentAgents.map(({ id, name }) => {
    return {
      title: `${name}'s Stats`,
      items: [
        {
          label: `${name} Stats`,
          subLabel: 'Stats',
          to: generatePath(ROUTE_PATHS.TALENT_AGENT_STATS, {
            talentAgentId: id,
          }),
          permittedRoles: [Role.Coordinator, Role.TalentAgent],
          matches: [name],
        },
      ],
    }
  })

  const pageSuggestions = Object.values(nonQueuePages).map(
    ({ pageName, permittedRoles, to, category }: PageDetail) => ({
      label: pageName,
      subLabel: category,
      permittedRoles: permittedRoles,
      to: to(user.id),
      matches: [pageName],
    })
  )

  const queueSuggestions = [
    sourcerQueue,
    enrichmentQueue,
    applierQueue,
    writerQueue,
    initialReviewQueue,
    finalReviewQueue,
    taglineFeedback,
    dossierQueue,
  ].map(({ pageName, permittedRoles, to }) => ({
    label: pageName,
    subLabel: 'Queue',
    permittedRoles: permittedRoles,
    to: to(user.id),
    matches: [pageName],
  }))

  return [
    { title: 'Clients', items: clientSuggestions },
    { title: 'Queues', items: queueSuggestions },
    { title: 'Views', items: pageSuggestions },
    ...clientQueueSections,
    ...talentAgentStatsSections,
  ]
}

export function normalizeCompanySuggestions(
  companyData: SearchCompaniesByDomainQuery
): SearchSuggestionSection[] {
  if (!companyData.companies) {
    return []
  }
  const { companies: companiesPage } = pages
  const companies = getNodes(companyData.companies)
  const normalizedCompanies = companies
    .filter(({ domain }) => !!domain)
    .map(({ id, name }) => ({
      label: name,
      to: generatePath(ROUTE_PATHS.COMPANY_DETAIL, { companyId: id }),
      permittedRoles: companiesPage.permittedRoles,
    }))
  return [{ title: 'Company', items: normalizedCompanies }]
}
