import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { CACHE_STRING } from 'utils/cacheString'
import { DATE_FORMAT, EMPTY_INPUT } from 'utils/constants'
import { Role, SourcedJobRejectionReason } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import RejectionDropdownButton from 'components/RejectionButton/RejectionDropdownButton'
import StarRating from 'components/StarRating/StarRating'
import { Tooltip } from 'components/WrappedTooltip/WrappedTooltip'
import {
  notesRequired,
  ratingRequired,
  rejectionOptions,
  TOAST,
} from './ReviewSourcedJobs.constants'
import { RowState, SourcedJob } from './ReviewSourcedJobs.types'
import { ActionButton } from './ReviewedSourcedJobs.styles'
import ExternalLink from 'components/ExternalLink'
import HList from 'components/HList'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  rowData: SourcedJob
  talentAgentId: string
  clientId: string
}

export default function ReviewSourcedJobsRow({
  rowData,
  talentAgentId,
  clientId,
}: Props): React.ReactElement {
  const [rowState, setRowState] = useState<RowState>({})
  const isTalentAgent = checkRole([Role.TalentAgent])
  const [acceptJob] = useMutation(ACCEPT_SOURCED_JOB, {
    onCompleted() {
      toast.success(TOAST.ACCEPT_SUCCESS)
    },
    onError() {
      toast.error(TOAST.ACCEPT_ERROR)
    },
    update(cache) {
      removeItemFromCacheConnections(cache, rowData.id, [
        CACHE_STRING.SOURCED_JOB_REVIEW(talentAgentId),
        CACHE_STRING.SOURCED_JOB_REVIEW_FREE_AGENT(talentAgentId, clientId),
      ])
    },
  })

  const [rejectJob] = useMutation(REJECT_SOURCED_JOB, {
    onCompleted() {
      toast.success(TOAST.REJECT_SUCCESS)
    },
    onError() {
      toast.error(TOAST.REJECT_ERROR)
    },
    update(cache) {
      removeItemFromCacheConnections(cache, rowData.id, [
        CACHE_STRING.SOURCED_JOB_REVIEW(talentAgentId),
        CACHE_STRING.SOURCED_JOB_REVIEW_FREE_AGENT(talentAgentId, clientId),
      ])
    },
  })

  function handleApproveClick() {
    if (!rowState.rating) {
      toast.error(TOAST.MISSING_RATING)
      return
    }
    const variables = {
      AcceptSourcedJobInput: {
        sourcedJobId: rowData.id,
        rating: rowState.rating,
        reviewNotes: rowState.reviewNotes ?? '',
      },
    }
    void acceptJob({ variables })
  }

  function handleRejectClick(rejectionReason: SourcedJobRejectionReason) {
    if (ratingRequired.includes(rejectionReason) && !rowState.rating) {
      return toast.error(TOAST.MISSING_REJECTION_RATING)
    }

    if (notesRequired.includes(rejectionReason) && !rowState.reviewNotes) {
      return toast.error(TOAST.MISSING_NOTES)
    }

    const variables = {
      RejectSourcedJobInput: {
        sourcedJobId: rowData.id,
        rating: rowState.rating,
        rejectionReason,
        reviewNotes: rowState.reviewNotes ?? '',
      },
    }
    void rejectJob({ variables })
  }

  function handleValueChange<T>(value: T, prop: keyof RowState) {
    setRowState({ ...rowState, [prop]: value })
  }

  const datePosted = rowData.datePosted && dayjs(rowData.datePosted).fromNow()

  const dateSourced = dayjs(rowData.createdAt).format(DATE_FORMAT.ONLY_DATE)

  const dateTooltip = (
    <div>
      <Txt size={14} lineHeight={1.5}>
        Date Sourced: {dateSourced}
      </Txt>
    </div>
  )
  const notesTooltip = (
    <div>
      <Txt size={14} lineHeight={1.5}>
        {rowData.notes}
      </Txt>
    </div>
  )

  return (
    <BodyRow>
      <BodyData>
        <Link
          target="_blank"
          to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, {
            companyId: rowData.company.id,
          })}
        >
          <Txt as="span" size={14} underline hoverColor="black">
            {rowData.company.name}
          </Txt>
        </Link>
      </BodyData>
      <BodyData>
        <ExternalLink
          url={rowData.url}
          txtProps={{
            size: 14,
            color: 'text',
            hoverColor: 'black',
            lineHeight: 1.5,
          }}
        >
          {rowData.title}
        </ExternalLink>
        {datePosted && (
          <Txt size={12} color="faGrey4" lineHeight={1.5}>
            {datePosted}
          </Txt>
        )}
      </BodyData>
      <BodyData collapse>
        <VList size={0.5}>
          <Tooltip label={dateTooltip}>
            <div>
              <Icon name="Calendar" width={14} height={14} primaryFill="text" />
            </div>
          </Tooltip>
          {rowData.notes && (
            <Tooltip label={notesTooltip}>
              <div>
                <Icon name="Comment" width={14} height={14} primaryFill="text" />
              </div>
            </Tooltip>
          )}
        </VList>
      </BodyData>
      <BodyData>
        <TextArea
          rows={1}
          minWidth={180}
          onValueChange={(value) => handleValueChange(value || null, 'reviewNotes')}
          value={rowState.reviewNotes || EMPTY_INPUT}
        />
      </BodyData>
      <BodyData collapse>
        <StarRating
          iconSize={16}
          onValueChange={(value) => handleValueChange(value, 'rating')}
          rating={rowState.rating}
        />
        <Padding top={0.5}>
          <HList size={1}>
            <Tooltip label="Approve" ariaLabel="Approve">
              <ActionButton
                onClick={handleApproveClick}
                disabled={!isTalentAgent}
                color={isTalentAgent ? 'success' : 'faGrey2'}
                hoverColor={isTalentAgent ? 'successLight' : 'faGrey2'}
                style={{ display: 'inline-block' }}
              />
            </Tooltip>
            <Tooltip label="Reject" ariaLabel="Reject">
              <div>
                <RejectionDropdownButton
                  dropdownOptions={rejectionOptions}
                  position="left"
                  disabled={!isTalentAgent}
                  onSelection={handleRejectClick}
                  buttonChildren={
                    <ActionButton
                      as="div"
                      color={isTalentAgent ? 'error' : 'faGrey2'}
                      hoverColor={isTalentAgent ? 'errorLight' : 'faGrey2'}
                    />
                  }
                />
              </div>
            </Tooltip>
          </HList>
        </Padding>
      </BodyData>
    </BodyRow>
  )
}

const ACCEPT_SOURCED_JOB = gql`
  mutation AcceptSourcedJob($AcceptSourcedJobInput: AcceptSourcedJobInput!) {
    acceptSourcedJob(input: $AcceptSourcedJobInput) {
      sourcedJobReview {
        id
      }
    }
  }
`

const REJECT_SOURCED_JOB = gql`
  mutation RejectSourcedJob($RejectSourcedJobInput: RejectSourcedJobInput!) {
    rejectSourcedJob(input: $RejectSourcedJobInput) {
      sourcedJobReview {
        id
      }
    }
  }
`
