import { gql } from '@apollo/client'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import { TalentAgentWithClients } from './TalentAgents.types'
import { GetTalentAgentsAndClientsQuery } from 'generated/graphql'

export function getSortedTalentAgents(
  talentAgentsAndClientData: GetTalentAgentsAndClientsQuery | undefined
): TalentAgentWithClients[] {
  const talentAgents = talentAgentsAndClientData?.talentAgents
    ? talentAgentsAndClientData.talentAgents
    : []
  const clients = talentAgentsAndClientData?.clients
    ? getNodes(talentAgentsAndClientData.clients)
    : []

  const talentAgentMap = talentAgents.reduce(
    (map: Record<string, TalentAgentWithClients>, talentAgent) => {
      map[talentAgent.id] = {
        id: talentAgent.id,
        name: talentAgent.name,
        clients: [],
      }
      return map
    },
    {}
  )
  clients.forEach((client) => {
    const talentAgent = talentAgentMap[client.talentAgent.id]
    if (talentAgent) {
      talentAgent.clients.push(client)
    }
  })

  const sortedTalentAgents = Object.values(talentAgentMap)
    .sort((a, b) => sortByProp(a, b, 'name'))
    // Move TA's with zero clients to the bottom of the list
    .sort((a, b) => (a.clients.length > 0 && b.clients.length === 0 ? -1 : 0))

  return sortedTalentAgents
}

getSortedTalentAgents.fragments = {
  talentAgents: gql`
    fragment TalentAgentSearch on User {
      id
      name
    }
  `,
  clients: gql`
    fragment TalentAgentSearchClients on ClientConnection {
      edges {
        node {
          id
          name
          talentAgent {
            id
          }
        }
      }
    }
  `,
}
