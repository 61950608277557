import dayjs from 'dayjs'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  InterviewCycleSortable,
  InterviewCycleConnection,
  InterviewCycleNode,
} from './ClientInterviewCycles.types'

export function buildTableData(
  connection: InterviewCycleConnection
): InterviewCycleSortable[] {
  const rawInterviewCycles = getNodes<InterviewCycleNode>(connection)

  const interviewCycles = rawInterviewCycles
    .map((interviewCycle) => ({
      ...interviewCycle,
      dateToSortBy: dayjs(interviewCycle.createdAt),
    }))
    .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))
    .sort((a) => (a.inactiveAt ? 1 : -1))

  return interviewCycles
}
