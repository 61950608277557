import { gql, useQuery } from '@apollo/client'
import React from 'react'
import InlineValueProp from 'components/InlineValueProp/InlineValueProp'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import Container from 'components/Container'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import VList from 'components/VList'
import ViewBox from 'components/ViewBox'
import { GetClientPrefsInfoQuery } from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientPreferences({ clientId }: Props): React.ReactElement {
  const { data } = useQuery<GetClientPrefsInfoQuery>(GET_CLIENT_PREFS_INFO, {
    variables: { id: clientId },
  })

  const client = data?.client
  const clientExists = Boolean(client)
  return (
    <ViewBox>
      <Padding top={2} bottom={6}>
        <Container percentageWidth={75} align="left">
          <VList size={4}>
            <VList size={1.5}>
              <InlineValueProp
                value={client?.roleExperience}
                label="Experience Level"
              />
              <InlineValueProp value={client?.roleCategory} label="Role Category" />
              <InlineValueProp
                value={client?.remotePrefs}
                label="Remote Preferences"
              />
              <InlineValueProp
                value={client?.desiredSalary}
                label="Desired Salary"
              />
              <InlineValueProp
                value={client?.desiredCompanySize}
                label="Desired Company Size"
              />
              <InlineValueProp
                value={client?.desiredIndustries}
                label="Desired Industries"
              />
            </VList>
            <RectangleSkeletonWrap
              height={450}
              width="100%"
              withViewBox={false}
              showContent={clientExists}
            >
              {client?.resumeUrl && <PDFViewer url={client.resumeUrl} />}
            </RectangleSkeletonWrap>
            <RectangleSkeletonWrap
              width={180}
              height={16}
              showContent={clientExists}
            >
              <WorkInfoLinks
                resumeUrl={client?.resumeUrl}
                linkedinUrl={client?.linkedinUrl}
                huntrUrl={client?.huntrUrl}
                questionnaireUrl={client?.questionnaireUrl}
                jobPrefsEditUrl={client?.jobPrefsEditUrl}
                resumes={client?.resumes}
              />
            </RectangleSkeletonWrap>
            <RectangleSkeletonWrap
              fullWidth
              width="100%"
              height={120}
              withViewBox={false}
              showContent={clientExists}
            >
              <TextArea
                label="Location Preferences"
                defaultValue={client?.locationPrefs || ''}
                height={80}
                disabled
              />
            </RectangleSkeletonWrap>
            <RectangleSkeletonWrap
              fullWidth
              width="100%"
              height={120}
              withViewBox={false}
              showContent={clientExists}
            >
              <TextArea
                label="Job Preferences"
                defaultValue={client?.jobPrefs || ''}
                height={120}
                disabled
              />
            </RectangleSkeletonWrap>
            <RectangleSkeletonWrap
              fullWidth
              width="100%"
              height={80}
              withViewBox={false}
              showContent={clientExists}
            >
              <TextArea
                defaultValue={client?.bio || ''}
                label={`${client?.name ?? ''} Bio`}
                height={80}
                disabled
              />
            </RectangleSkeletonWrap>
          </VList>
        </Container>
      </Padding>
    </ViewBox>
  )
}

const GET_CLIENT_PREFS_INFO = gql`
  query GetClientPrefsInfo($id: ID!) {
    client(id: $id) {
      id
      bio
      desiredCompanySize
      desiredIndustries
      desiredSalary
      huntrUrl
      jobPrefs
      linkedinUrl
      locationPrefs
      jobPrefsEditUrl
      name
      questionnaireUrl
      remotePrefs
      resumeUrl
      roleCategory
      roleExperience
      resumes {
        url
        filename
      }
    }
  }
`
