import { gql, useQuery } from '@apollo/client'
import React, { useMemo, useState } from 'react'
import { useAuthContext } from 'context/auth'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COLUMNS, LABEL_OPTIONS, OptionValues } from './DossierTable.constants'
import {
  groupDossierRequests,
  normalizeDossierRequests,
} from './DossierTable.helpers'
import DossierTableBody from './DossierTableBody'
import DossierTableNav from './DossierTableNav'
import Padding from 'components/Padding'
import { GetAssigneeDossierRequestsQuery } from 'generated/graphql'

export default function DossierTable(): JSX.Element {
  const [currentTab, setCurrentTab] = useState<OptionValues>(OptionValues.MyWork)
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const { data, loading } = useQuery<GetAssigneeDossierRequestsQuery>(
    GET_DOSSIER_ASSIGNMENTS,
    {
      variables: {
        assigneeId,
      },
    }
  )

  const normalizedDossierRequests = useMemo(
    () =>
      data?.dossierRequests ? normalizeDossierRequests(data.dossierRequests) : [],
    [data]
  )
  const allTableData = useMemo(
    () => groupDossierRequests(normalizedDossierRequests),
    [normalizedDossierRequests]
  )

  const isEmpty = !allTableData[currentTab].length && !loading

  /* TODO (matthewalbrecht): consider using an actual tab component here */
  return (
    <section>
      <Padding bottom={2}>
        <DossierTableNav
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
          options={LABEL_OPTIONS}
        />
      </Padding>
      <table>
        <thead>
          <TableHeaderRow>
            {COLUMNS[currentTab].map((header) => (
              <TableHeaderItem key={header} content={header} />
            ))}
            <th />
          </TableHeaderRow>
        </thead>
        <DossierTableBody currentTab={currentTab} allTableData={allTableData} />
        {loading && <TableEmpty message="Loading..." />}
        {isEmpty && <TableEmpty message="No Assignments Found" />}
      </table>
    </section>
  )
}

/**
 * used by dossier writers to get all of their dossierRequests
 */
const GET_DOSSIER_ASSIGNMENTS = gql`
  query GetAssigneeDossierRequests($assigneeId: ID!) {
    dossierRequests(assigneeId: $assigneeId, isOpen: true) {
      edges {
        node {
          id
          due
          client {
            id
            name
          }
        }
      }
    }
  }
`
