import { gql, useQuery } from '@apollo/client'
import React from 'react'
import ActivityItem from 'components/ActivityLogTable/ActivityItem'
import {
  CLIENT_ACTIVITY_LOG_INITIAL_SIZE,
  CLIENT_ACTIVITY_LOG_BATCH_SIZE,
} from './ClientTracker.constants'
import Button from 'components/Button'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  ClientActivityLogQuery,
  ClientActivityLogQueryVariables,
} from 'generated/graphql'

type Props = {
  first?: number
  clientId: string
}

export default function ClientTrackerActivityLog({
  first = CLIENT_ACTIVITY_LOG_INITIAL_SIZE,
  clientId,
}: Props): JSX.Element {
  /**
   * request the next set of activity logs
   */
  function handleMoreClick() {
    void fetchMore({
      variables: {
        first: CLIENT_ACTIVITY_LOG_BATCH_SIZE,
        after: pageInfo?.endCursor,
      },
    })
  }

  const { data, loading, fetchMore } = useQuery<
    ClientActivityLogQuery,
    ClientActivityLogQueryVariables
  >(GET_CLIENT_ACTIVITY_LOG, {
    variables: { clientId: clientId, first },
  })

  if (loading) {
    return <Message message="Loading activity log..." />
  }

  const { edges, pageInfo } = data?.client?.activityLogs || {}
  const showLoadMoreButton = pageInfo?.hasNextPage

  const activityItems = edges?.map((edge) => edge.node)

  return (
    <>
      <VList size={1.5}>
        {activityItems?.length ? (
          activityItems.map((activity) => (
            <ActivityItem activity={activity} key={activity.id} />
          ))
        ) : (
          <Txt>No Activities Found</Txt>
        )}
      </VList>

      {showLoadMoreButton && (
        <Padding top={3}>
          <Button onClick={handleMoreClick}>Load More</Button>
        </Padding>
      )}
    </>
  )
}

/* TODO (matthewalbrecht): consider moving this into the parent query */
/**
 * NOTE: this may not be the _best_ way to handle this pagination. consider making the initial fetch in the parent component w/ other data
 * and then subsequent fetches here
 */
const GET_CLIENT_ACTIVITY_LOG = gql`
  query ClientActivityLog($clientId: ID!, $first: Int!, $after: String) {
    client(id: $clientId) {
      id
      activityLogs(first: $first, after: $after) {
        edges {
          node {
            id
            ...ActivityItemInfo
          }
          cursor
        }
        pageInfo {
          hasNextPage
          endCursor
        }
      }
    }
  }
  ${ActivityItem.fragments.activityItemInfo}
`
