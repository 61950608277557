import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { EMPTY_CELL_PLACEHOLEDER, INTERVIEW_PREP_TYPE_MAP } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import { getInterviewPrepStatusTagInfo } from 'views/ClientInterviewPreps/ClientInterviewPreps.helpers'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import {
  InterviewPrepRequestButton,
  InterviewPrepRequestDialog,
} from './InterviewPrepRequestDialog'
import { HEADERS } from './InterviewPrepRequests.constants'
import { InterviewPrepRequest } from './InterviewPrepRequests.types'
import HList from 'components/HList'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'
import { GetInterviewPrepRequestsQuery } from 'generated/graphql'

export default function InterviewPrepRequestsTable(): React.ReactElement {
  const { data, loading, error } = useQuery<GetInterviewPrepRequestsQuery>(
    GET_INTERVIEW_PREP_REQUESTS
  )

  const interviewPrepRequests = data?.interviewPrepRequests
    ? getNodes(data.interviewPrepRequests)
        .map((item) => ({
          ...item,
          dateToSortBy: dayjs(item.due),
        }))
        .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))
    : []

  return (
    <FullWidthTable
      headers={Object.values(HEADERS)}
      isLoading={loading}
      isError={Boolean(error)}
      isEmpty={
        !loading &&
        Boolean(data?.interviewPrepRequests) &&
        !interviewPrepRequests.length
      }
      emptyMessage="Could not find any interview prep requests."
      loadingMessage="Loading interview prep requests..."
      errorMessage="There was an error fetching interview prep requests."
    >
      {interviewPrepRequests.map((interviewPrepRequest) => (
        <InterviewPrepRequestsRow
          key={interviewPrepRequest.id}
          interviewPrepRequest={interviewPrepRequest}
        />
      ))}
    </FullWidthTable>
  )
}

type RowProps = {
  interviewPrepRequest: InterviewPrepRequest
}

function InterviewPrepRequestsRow({
  interviewPrepRequest,
}: RowProps): React.ReactElement {
  const { statusColor, statusCopy } =
    getInterviewPrepStatusTagInfo(interviewPrepRequest)

  return (
    <BodyRow>
      <BodyData collapse>
        <InterviewPrepRequestButton interviewPrepRequest={interviewPrepRequest} />
      </BodyData>
      <BodyData>
        <Txt size={14}>{interviewPrepRequest.due}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {interviewPrepRequest.client.name} (
          {interviewPrepRequest.client.talentAgent.name})
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {INTERVIEW_PREP_TYPE_MAP[interviewPrepRequest.interviewPrepType]}
        </Txt>
      </BodyData>
      <BodyData>
        <HList size={1}>
          {interviewPrepRequest.assignees.length
            ? interviewPrepRequest.assignees.map((assignee) => (
                <Tag key={assignee.id} small color="purple">
                  <Txt size={12} color="white">
                    {assignee.name}
                  </Txt>
                </Tag>
              ))
            : EMPTY_CELL_PLACEHOLEDER}
        </HList>
      </BodyData>
      <BodyData collapse>
        <Tag color={statusColor} small>
          <Txt size={13} noWrap alignment="center">
            {statusCopy}
          </Txt>
        </Tag>
      </BodyData>
    </BodyRow>
  )
}

const GET_INTERVIEW_PREP_REQUESTS = gql`
  query GetInterviewPrepRequests {
    interviewPrepRequests {
      edges {
        node {
          id
          interviewCycle {
            id
            employerName
          }
          assignees {
            id
            name
          }
          client {
            id
            name
            talentAgent {
              id
              name
            }
          }
          interviewPrepType
          due
          ...InterviewPrepRequestDialogInfo
          ...InterviewPrepRequestStatusInfo
        }
      }
    }
  }
  ${InterviewPrepRequestDialog.fragments.dialogInfo}
  ${getInterviewPrepStatusTagInfo.fragments.statusInfo}
`
