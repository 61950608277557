import { ConnectionNode } from 'types'
import { GetDossierRequestsForAssigningQuery } from 'generated/graphql'

export enum DateInputType {
  Date = 'Date',
  Hour = 'Hour',
}

type DossierRequestConnection = NonNullable<
  GetDossierRequestsForAssigningQuery['dossierRequests']
>
export type DossierRequest = ConnectionNode<DossierRequestConnection>
