import React from 'react'
import { NavOption } from './Assign.types'
import AssignNav from './AssignNav'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  isReassigning: boolean
  assignData: NavOption
  reassignData: NavOption
  appendedHeaderCopy?: string
}

export default function AssignHeader({
  isReassigning,
  assignData,
  reassignData,
  appendedHeaderCopy,
}: Props): JSX.Element {
  return (
    <>
      <Padding bottom={4}>
        <Txt as="h2" size={24} bold>
          {isReassigning ? reassignData.header : assignData.header}
          {appendedHeaderCopy ? ` ${appendedHeaderCopy}` : ''}
        </Txt>
      </Padding>
      <AssignNav assignData={assignData} reassignData={reassignData} />
    </>
  )
}
