import { gql } from '@apollo/client'

/**
 * Used to request a magic link to be sent to the given email
 */
export const REQUEST_MAGIC_LINK = gql`
  mutation RequestMagicLink($RequestMagicLinkInput: RequestMagicLinkInput!) {
    requestMagicLink(input: $RequestMagicLinkInput) {
      success
    }
  }
`

/**
 * used to recieve the access token and user session info from the magic link token
 */
export const LOGIN_WITH_MAGIC_LINK = gql`
  mutation LoginWithMagicLink($LoginWithMagicLinkInput: LoginWithMagicLinkInput!) {
    loginWithMagicLink(input: $LoginWithMagicLinkInput) {
      accessToken
      expiresIn
      errorCode
      viewer {
        name
        email
        id
        isAdmin
        isWriter
        isTalentAgent
        isCoordinator
        isTalentPartner
        isTea
        isTee
        isApplier
        isEnricher
        isSourcer
        isDossierWriter
        isExpertCoordinator
        isDataCleaner
        isSalesCoordinator
      }
    }
  }
`

/**
 * used to create a new tempalte sequence for a free agent
 */
export const CREATE_SEQUENCE = gql`
  mutation CreateTemplateSequence(
    $CreateTemplateSequenceInput: CreateTemplateSequenceInput!
  ) {
    createTemplateSequence(input: $CreateTemplateSequenceInput) {
      templateSequence {
        name
        id
        templates {
          name
          id
          copy
        }
      }
    }
  }
`

/**
 * used to create a new template on a sequence
 */
export const CREATE_TEMPLATE = gql`
  mutation CreateTemplate($CreateTemplateInput: CreateTemplateInput!) {
    createTemplate(input: $CreateTemplateInput) {
      template {
        name
        id
        copy
      }
    }
  }
`

/**
 * Used by the free agent config page to update copy changes made to a particular sequence
 */
export const UPDATE_SEQUENCE_COPY = gql`
  mutation UpdateTemplateSequenceCopy(
    $UpdateTemplateSequenceCopyInput: UpdateTemplateSequenceCopyInput!
  ) {
    updateTemplateSequenceCopy(input: $UpdateTemplateSequenceCopyInput) {
      templateSequence {
        id
        name
        templates {
          id
          copy
        }
      }
    }
  }
`

/**
 * Used to create a new tagline request
 */
export const CREATE_TAGLINE_REQUEST = gql`
  mutation CreateTaglineRequest(
    $CreateTaglineRequestInput: CreateTaglineRequestInput!
  ) {
    createTaglineRequest(input: $CreateTaglineRequestInput) {
      taglineRequest {
        id
        client {
          name
        }
      }
    }
  }
`

/**
 * Used to modify an existing tagline request
 */
export const MODIFY_TAGLINE_REQUEST = gql`
  mutation ModifyTaglineRequest(
    $ModifyTaglineRequestInput: ModifyTaglineRequestInput!
  ) {
    modifyTaglineRequest(input: $ModifyTaglineRequestInput) {
      taglineRequest {
        id
        due
        jobTitle
        employerName
        huntrUrl
        client {
          name
        }
      }
    }
  }
`
