/**
 * convert h:mm to seconds
 * @param timeSpent value in form of h:mm
 */
export default function getTimeSpentSec(timeSpent: string): number {
  const [hours, minutes] = timeSpent.split(':')
  const totalSeconds =
    parseInt(hours || '0', 10) * 60 * 60 + parseInt(minutes || '0', 10) * 60
  return totalSeconds
}
