import React from 'react'
import { GenericGroupedRowData } from 'utils/helpers/groupAssigItems'
import AssignHighlightedRow from 'views/Assign/AssignHighlightedRow'
import { BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import HList from 'components/HList'
import Txt from 'components/Txt'

type Props = {
  group: GenericGroupedRowData
  handleGroupCheck: (event: React.FormEvent<HTMLInputElement>) => void
  checked: boolean
}

export default function AssignTaglinesHighlightedClientRow({
  group,
  handleGroupCheck,
  checked,
}: Props): JSX.Element {
  // TODO (matthewalbrecht) clean up the types in assigning components
  return (
    <AssignHighlightedRow
      group={group}
      handleGroupCheck={handleGroupCheck}
      checked={checked}
    >
      <BodyData>
        <Txt as="span" size={14} bold>
          {group.talentAgentName}
        </Txt>
      </BodyData>
      <BodyData colSpan={100}>
        <HList size={2}>
          <Txt as="span" size={14} bold>
            {group.roleCategory || 'UNKNOWN ROLE'}
          </Txt>
          <Txt size={14} bold>
            |
          </Txt>
          <Txt size={14} bold>
            {group.roleExperience || 'UNKNOWN LEVEL'}
          </Txt>
          <Txt size={14} bold>
            |
          </Txt>
          <Txt size={14} bold>
            Count: {group.items.length}
          </Txt>
        </HList>
      </BodyData>
    </AssignHighlightedRow>
  )
}
