import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import TalentAgentClientCards from 'components/TalentAgentClientCards/TalentAgentClientCards'
import { NavButton } from './TalentAgents.styles'
import { SearchSuggestionType } from './TalentAgents.types'
import HList from 'components/HList'
import Padding from 'components/Padding'

type Props = {
  talentAgent: SearchSuggestionType
}

export default function TalentAgentSection({
  talentAgent,
}: Props): React.ReactElement {
  return (
    <>
      <HList size={2}>
        <Link
          to={generatePath(ROUTE_PATHS.TALENT_AGENTS_DASHBOARD, {
            talentAgentId: talentAgent.id,
          })}
        >
          <NavButton>Dashboard</NavButton>
        </Link>
        <Link
          to={generatePath(ROUTE_PATHS.TALENT_AGENT_STATS, {
            talentAgentId: talentAgent.id,
          })}
        >
          <NavButton>Statistics</NavButton>
        </Link>
      </HList>
      <Padding bottom={2}>
        <TalentAgentClientCards talentAgentId={talentAgent.id} />
      </Padding>
    </>
  )
}
