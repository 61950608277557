import React from 'react'
import { Maybe } from 'types'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import { Application } from './ReportApplicationInfo.types'
import ExternalLink from 'components/ExternalLink'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  application: Maybe<Application>
}

export default function ReportApplicationInfoContent({
  application,
}: Props): React.ReactElement {
  const applicationExists = Boolean(application !== undefined)

  return (
    <>
      <RectangleSkeletonWrap height={14} width={100} showContent={applicationExists}>
        <Txt size={12} spacing={1} uppercase>
          Application For:
        </Txt>
      </RectangleSkeletonWrap>
      <Padding top={0.5}>
        <RectangleSkeletonWrap
          height={30}
          width={300}
          showContent={applicationExists}
        >
          <Txt size={30} bold lineHeight={1.3}>
            {application && getJobInfoLabel(application)}
          </Txt>
        </RectangleSkeletonWrap>
      </Padding>
      <Padding top={2}>
        <RectangleSkeletonWrap
          height={18}
          width={100}
          showContent={applicationExists}
        >
          {application?.jobUrl && (
            <ExternalLink url={application.jobUrl} label="Job Description" />
          )}
        </RectangleSkeletonWrap>
      </Padding>
    </>
  )
}
