import React from 'react'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { HEADERS } from './SourcerReview.constants'
import { RowData } from './SourcerReview.types'
import SourcerReviewHighlightedRow from './SourcerReviewHighlightedRow'
import SourcerReviewRow from './SourcerReviewRow'

type Props = {
  tableData: RowData[]
  isEmpty: boolean
  isLoading: boolean
  isError: boolean
}

export default function SourcerReviewTable({
  tableData,
  isEmpty,
  isLoading,
  isError,
}: Props): React.ReactElement {
  return (
    <FullWidthTable
      headers={HEADERS}
      isEmpty={isEmpty}
      emptyMessage="No sourcing data found"
      isLoading={isLoading}
      loadingMessage="Loading sourcer review..."
      isError={isError}
      errorMessage="There was an error loading sourcing data..."
    >
      {tableData.map((assigneeRowData) => (
        <React.Fragment key={assigneeRowData.assigneeId}>
          <SourcerReviewHighlightedRow assigneeRowData={assigneeRowData} />
          {assigneeRowData.requests.map((clientRowData) => (
            <SourcerReviewRow
              key={clientRowData.clientId}
              clientRowData={clientRowData}
            />
          ))}
        </React.Fragment>
      ))}
    </FullWidthTable>
  )
}
