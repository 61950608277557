import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { PAGE_SIZE } from './ClientInterviewPreps.constants'
import {
  buildTableData,
  getInterviewPrepStatusTagInfo,
} from './ClientInterviewPreps.helpers'
import ClientInterviewPrepsTable from './ClientInterviewPrepsTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import ViewBox from 'components/ViewBox'
import {
  GetInterviewPrepsQuery,
  GetInterviewPrepsQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientInterviewPreps({
  clientId,
}: Props): React.ReactElement {
  /* TODO (matthewalbrecht): move this into the table component and colocate row component there */
  const { data, fetchMore, loading } = useQuery<
    GetInterviewPrepsQuery,
    GetInterviewPrepsQueryVariables
  >(GET_INTERVIEW_PREP_REQUESTS, {
    variables: {
      clientId: clientId,
      first: PAGE_SIZE,
    },
  })

  if (loading) {
    return <Message vertical message="Loading data..." />
  }

  const tableData = data?.interviewPrepRequests
    ? buildTableData(data.interviewPrepRequests)
    : []

  /**
   * fetches additional interview prep requests when load more button is clicked
   */
  function handleLoadMore() {
    void fetchMore({
      variables: {
        first: PAGE_SIZE,
        after: endCursor || undefined,
      },
    })
  }

  const { pageInfo } = data?.interviewPrepRequests ?? {}
  const { hasNextPage, endCursor } = pageInfo ?? {}
  const showLoadMoreButton = hasNextPage

  return (
    <ViewBox unsetHeight>
      <Padding top={1}>
        <ClientInterviewPrepsTable tableData={tableData} clientId={clientId} />
        {!tableData.length && (
          <Message message="No interview prep / mock requests found" vertical />
        )}
      </Padding>
      {showLoadMoreButton && (
        <Padding top={4}>
          <Container noMax>
            <Flex justify="center">
              <Button onClick={handleLoadMore}>Load More</Button>
            </Flex>
          </Container>
        </Padding>
      )}
    </ViewBox>
  )
}

const GET_INTERVIEW_PREP_REQUESTS = gql`
  query GetInterviewPreps($clientId: ID!, $first: Int, $after: String) {
    interviewPrepRequests(clientId: $clientId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          id
          createdAt
          due
          interviewPrepType
          interviewCycle {
            id
            employerName
            jobTitle
          }
          ...InterviewPrepRequestStatusInfo
        }
      }
    }
  }
  ${getInterviewPrepStatusTagInfo.fragments.statusInfo}
`
