import { ApolloCache, gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { INTERVIEW_CYCLE_SOURCE_MAP } from 'utils/constants'
import { InactiveCycleReasons } from 'utils/enums'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import RejectionDropdownButton from 'components/RejectionButton/RejectionDropdownButton'
import ClientInterviewCycleExpandButton from './ClientInterviewCycleExpandButton'
import { COPY, rejectionOptions } from './ClientInterviewCycles.constants'
import { InterviewCycleSortable } from './ClientInterviewCycles.types'
import ExternalLink from 'components/ExternalLink'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'

type Props = {
  interviewCycle: InterviewCycleSortable
  clientId: string
}

export default function ClientInterviewCyclesRow({
  clientId,
  interviewCycle,
}: Props): React.ReactElement {
  const [recordInactiveInterviewCycle] = useMutation(
    RECORD_INACTIVE_INTERVIEW_CYCLE,
    {
      update: updateCacheAfterCloseSuccess,
      onCompleted() {
        toast.success(COPY.CLOSE_SUCCESS)
      },
      onError() {
        toast.error(COPY.CLOSE_ERROR)
      },
    }
  )

  /**
   * this will remove the current interviewCycle from the query in cache so that ui will properly update
   * @param cache Apollo's cache
   */
  function updateCacheAfterCloseSuccess(cache: ApolloCache<unknown>) {
    const params = { clientId: clientId || undefined, active: true }
    // if we are currently filtering by clientId then we want the query string to reflect that
    const cacheString = `interviewCycles:${JSON.stringify(params)}`

    cache.modify({
      fields: {
        [cacheString]: (queryResult, { readField }) =>
          removeCacheEdgeById(interviewCycle.id, queryResult, readField),
      },
    })
  }

  function handleCloseInterviewCycle(rejectionReason: InactiveCycleReasons) {
    if (window.confirm(COPY.CONFIRM_CLOSE)) {
      void recordInactiveInterviewCycle({
        variables: {
          RecordInactiveInterviewCycleInput: {
            interviewCycleId: interviewCycle.id,
            inactiveReason: rejectionReason,
          },
        },
      })
    }
  }

  const isActive = !interviewCycle.inactiveAt

  return (
    <BodyRow>
      <BodyData collapse>
        <ClientInterviewCycleExpandButton
          interviewCycleId={interviewCycle.id}
          clientId={clientId}
        />
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {interviewCycle.employerName}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {interviewCycle.jobTitle}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {INTERVIEW_CYCLE_SOURCE_MAP[interviewCycle.source]}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {interviewCycle.startDate}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          <ExternalLink label="Link" inheritStyles url={interviewCycle.huntrUrl} />
        </Txt>
      </BodyData>
      <BodyData collapse>
        {isActive ? (
          <RejectionDropdownButton<InactiveCycleReasons>
            onSelection={handleCloseInterviewCycle}
            position="left"
            buttonChildren={
              <Icon name="Cancel" height={16} width={16} primaryFill="text" />
            }
            dropdownOptions={rejectionOptions}
          />
        ) : (
          <Tag color="yellowLight" small>
            <Txt as="span" size={14}>
              Inactive
            </Txt>
          </Tag>
        )}
      </BodyData>
    </BodyRow>
  )
}

export const RECORD_INACTIVE_INTERVIEW_CYCLE = gql`
  mutation RecordInactiveInterviewCycle(
    $RecordInactiveInterviewCycleInput: RecordInactiveInterviewCycleInput!
  ) {
    recordInactiveInterviewCycle(input: $RecordInactiveInterviewCycleInput) {
      interviewCycle {
        id
        inactiveAt
        inactiveReason
      }
    }
  }
`
