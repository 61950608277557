import React, { useState } from 'react'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
} from 'recharts'
import { ChartData, RechartsFunction } from './TalentAgentStats.types'

type Props = {
  chartData: ChartData[]
}

export default function TalentAgentStatsChart({
  chartData,
}: Props): React.ReactElement {
  const [referenceLineValue, setReferenceLineValue] = useState<number>(0)
  const handleYAxisClick = (event: { value: number }) => {
    if (referenceLineValue === event.value) {
      setReferenceLineValue(0)
    } else {
      setReferenceLineValue(event.value)
    }
  }
  return (
    <ResponsiveContainer width="95%" height="50%">
      <BarChart
        width={500}
        height={300}
        barSize={80}
        data={chartData}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <XAxis dataKey="date" fontFamily="DM Sans, sans-serif" />
        <Tooltip />
        <YAxis
          fontFamily="DM Sans, sans-serif"
          cursor="pointer"
          onClick={handleYAxisClick as RechartsFunction}
        />
        <Bar dataKey="count" fill="#2f5fb2" />
        {referenceLineValue && (
          <ReferenceLine
            y={referenceLineValue}
            stroke="#C06FE6"
            strokeDasharray="3 3"
          />
        )}
      </BarChart>
    </ResponsiveContainer>
  )
}
