import React from 'react'
import Padding from './Padding'
import Txt, { TxtProps } from './Txt'

type Props = {
  padding?: number
  htmlFor?: string
  size?: number
  content: React.ReactNode
  txtProps?: TxtProps
}

/**
 * @param padding defaults to 1.5
 * @param size defaults to 16
 */
export default function Label({
  padding = 1.5,
  htmlFor,
  content,
  size,
  txtProps = {},
}: Props): React.ReactElement {
  return (
    <Padding bottom={padding}>
      <Txt as="label" htmlFor={htmlFor} size={size} lineHeight={1.3} {...txtProps}>
        {content}
      </Txt>
    </Padding>
  )
}
