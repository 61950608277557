import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { useAuthContext } from 'context/auth'
import { EOD_HOUR, EOD_MINUTE, INITIAL, SOURCING_DUE_TZ } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import DueDateTableItem from 'components/DueDateTableItem/DueDateTableItem'
import TableBody from 'components/Table/TableBody'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS } from './SourcerTable.constants'
import { buildRowData, getNumJobsSum } from './SourcerTable.helpers'
import {
  GetAssigneeSourcingRequestsQuery,
  GetAssigneeSourcingRequestsQueryVariables,
} from 'generated/graphql'

export default function SourcerTable(): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const { data } = useQuery<
    GetAssigneeSourcingRequestsQuery,
    GetAssigneeSourcingRequestsQueryVariables
  >(GET_ASSIGNEE_APPLICATIONS, {
    variables: {
      assigneeId,
    },
  })

  const rowData = data?.sourcingRequests && buildRowData(data.sourcingRequests)

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {rowData?.map((client) => (
          <tr key={client.name}>
            <TableBodyItem first content={client.name} />
            <TableBodyItem content={getNumJobsSum(client.sourcingRequests)} />
            <TableBodyItem>
              <DueDateTableItem
                items={client.sourcingRequests}
                fromNow
                dueHour={EOD_HOUR}
                dueMinute={EOD_MINUTE}
                dueTz={SOURCING_DUE_TZ}
              />
            </TableBodyItem>
            <TableBodyActionList>
              {client.sourcingRequests.length && (
                <TableBodyAction
                  to={generatePath(ROUTE_PATHS.SOURCING_QUEUE_BY_FREE_AGENT, {
                    freeAgentId: client.id,
                    requestId: INITIAL,
                  })}
                  content="Queue"
                />
              )}
            </TableBodyActionList>
          </tr>
        ))}
      </TableBody>
      {rowData && !rowData.length && <TableEmpty message="No Assignments Found" />}
    </table>
  )
}

/**
 * used by sourcers to get all of their sourcingRequests
 */
const GET_ASSIGNEE_APPLICATIONS = gql`
  query getAssigneeSourcingRequests($assigneeId: ID!) {
    sourcingRequests(assigneeId: $assigneeId, isOpen: true) {
      edges {
        node {
          id
          due
          numJobs
          client {
            id
            name
          }
        }
      }
    }
  }
`
