import React from 'react'
import { OpenParams, PDFObject } from 'react-pdfobject'

type Props = {
  url: string
}

export default function PDFViewer({ url }: Props): JSX.Element {
  // Cast needed while waiting for: https://github.com/sugarshin/react-pdfobject/pull/106
  return (
    <PDFObject
      url={url}
      height="45rem"
      pdfOpenParams={{ toolbar: 0, view: 'FitH' } as OpenParams}
    />
  )
}
