import { WorkTypes } from './TaglineStatus.types'

export const WORK_TYPE_OPTIONS = [
  { label: 'Current', value: WorkTypes.CURRENT },
  { label: 'Reviewed/Rejected', value: WorkTypes.REVIEWED_REJECTED },
]

export const FREE_AGENT_REQUIRED_MESSAGE =
  'Must have free agent selected when filtering by Reviewed/Rejected work'

export const COLUMNS = [
  { label: 'Job Title', id: 'jobTitle' },
  { label: 'Company', id: 'company' },
  { label: 'Status', id: 'status' },
  { label: 'Rejecter', id: 'rejecter', hideOnCurrent: true },
  { label: 'Due Date', id: 'due' },
  { label: 'Last Status Change', id: 'statusChange' },
  { label: 'Writer', id: 'writer', hideOnCurrent: true },
]
