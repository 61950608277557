import React from 'react'
import TableBody from 'components/Table/TableBody'
import { OptionValues } from './DossierTable.constants'
import { AllTableData } from './DossierTable.types'
import DossierTableClientRow from './DossierTableClientRow'
import DossierTableDueRow from './DossierTableDueRow'

type Props = {
  currentTab: OptionValues
  allTableData: AllTableData
}

export default function DossierTableBody({
  currentTab,
  allTableData,
}: Props): React.ReactElement {
  return (
    <TableBody>
      {currentTab === OptionValues.MyWork &&
        allTableData.MyWork.map((row) => (
          <DossierTableClientRow key={row.clientId} rowData={row} />
        ))}
      {currentTab === OptionValues.ByDueDate &&
        allTableData.ByDueDate.map((row) => (
          <DossierTableDueRow key={row.dateAndTime} rowData={row} />
        ))}
    </TableBody>
  )
}
