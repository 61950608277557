import React from 'react'
import Button from 'components/Button'

export default function ApplierTableNavByDueDate(): JSX.Element {
  return (
    <Button $type="link" active>
      My Applications By Due Date
    </Button>
  )
}
