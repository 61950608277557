import React from 'react'
import styled, { css } from 'styled-components/macro'
import { Colors } from 'types'
import { ReactComponent as FreeAgencyFlag } from 'assets/icons/FreeAgencyFlag.svg'
import { ReactComponent as AngelList } from 'assets/icons/angel-list.svg'
import { ReactComponent as ArrowRight } from 'assets/icons/angle-right.svg'
import { ReactComponent as Cancel } from 'assets/icons/ban.svg'
import { ReactComponent as Calendar } from 'assets/icons/calendar.svg'
import { ReactComponent as CaretArrowDown } from 'assets/icons/caret-arrow-down.svg'
import { ReactComponent as Check } from 'assets/icons/check-circle.svg'
import { ReactComponent as ClipboardCheck } from 'assets/icons/clipboard-check.svg'
import { ReactComponent as ClipboardList } from 'assets/icons/clipboard-list.svg'
import { ReactComponent as Clipboard } from 'assets/icons/clipboard.svg'
import { ReactComponent as Cog } from 'assets/icons/cog.svg'
import { ReactComponent as Comment } from 'assets/icons/comment.svg'
import { ReactComponent as Crunchbase } from 'assets/icons/crunchbase.svg'
import { ReactComponent as Expand } from 'assets/icons/expand.svg'
import { ReactComponent as ExternalLink } from 'assets/icons/external-link.svg'
import { ReactComponent as ExportAsCsv } from 'assets/icons/file-csv.svg'
import { ReactComponent as File } from 'assets/icons/file.svg'
import { ReactComponent as FolderPlus } from 'assets/icons/folder-plus.svg'
import { ReactComponent as Glassdoor } from 'assets/icons/glassdoor.svg'
import { ReactComponent as Globe } from 'assets/icons/globe.svg'
import { ReactComponent as Huntr } from 'assets/icons/huntr.svg'
import { ReactComponent as Info } from 'assets/icons/info-circle.svg'
import { ReactComponent as Queue } from 'assets/icons/layer-group.svg'
import { ReactComponent as Linkedin } from 'assets/icons/linkedin.svg'
import { ReactComponent as OpenLock } from 'assets/icons/lock-open.svg'
import { ReactComponent as Lock } from 'assets/icons/lock.svg'
import { ReactComponent as Minus } from 'assets/icons/minus.svg'
import { ReactComponent as Pause } from 'assets/icons/pause-circle.svg'
import { ReactComponent as Pen } from 'assets/icons/pen.svg'
import { ReactComponent as Play } from 'assets/icons/play-circle.svg'
import { ReactComponent as AddCircle } from 'assets/icons/plus-circle.svg'
import { ReactComponent as Plus } from 'assets/icons/plus.svg'
import { ReactComponent as Search } from 'assets/icons/search.svg'
import { ReactComponent as Star } from 'assets/icons/star.svg'
import { ReactComponent as ThumbsDown } from 'assets/icons/thumbs-down.svg'
import { ReactComponent as ThumbsUp } from 'assets/icons/thumbs-up.svg'
import { ReactComponent as TimesCircle } from 'assets/icons/times-circle.svg'
import { ReactComponent as Exit } from 'assets/icons/times.svg'
import { ReactComponent as Trash } from 'assets/icons/trash.svg'
import { ReactComponent as User } from 'assets/icons/user.svg'
import { IconNames } from './Icon.types'

interface IconProps extends StyleProps {
  name: IconNames
}

type NudgeStyle = {
  up?: number
  down?: number
  left?: number
  right?: number
}

type StyleProps = {
  height?: number
  width?: number
  display?: 'inline-block' | 'block'
  primaryFill?: keyof Colors
  primaryFillHover?: keyof Colors
  primaryStroke?: keyof Colors
  nudge?: NudgeStyle
  rotateDegrees?: number
  iconName?: IconNames
}

const IconBox = styled.i<StyleProps>`
  display: ${({ display = 'inline-block' }) => display};
  position: relative;

  /* Force constrained layout to ensure predictable svg rendering */
  > * {
    height: 100%;
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
    width: 100%;
  }

  ${({ height, theme }) =>
    height &&
    css`
      height: ${theme.toRems(height)};
    `}

  ${({ width, theme }) =>
    width &&
    css`
      width: ${theme.toRems(width)};
    `}

  ${({ primaryFill, theme }) =>
    primaryFill &&
    css`
      & .primaryFill {
        fill: ${theme.color[primaryFill]};
      }
    `}

  ${({ primaryFillHover, theme }) =>
    primaryFillHover &&
    css`
      &:hover .primaryFill {
        fill: ${theme.color[primaryFillHover]};
      }
    `}

  ${({ primaryStroke, theme }) =>
    primaryStroke &&
    css`
      & .primaryStroke {
        stroke: ${theme.color[primaryStroke]};
      }
    `}

  ${({ rotateDegrees }) =>
    rotateDegrees &&
    css`
      transform: rotateZ(${rotateDegrees}deg);
    `}

    ${({ iconName }) =>
    iconName === 'Huntr' &&
    css`
      & .section1 {
        fill: #fff;
      }
      & .section2 {
        fill: #fff;
      }
      & .section3 {
        fill: #fff;
      }
      & .section4 {
        fill: #fff;
      }
      & .section5 {
        fill: #fff;
      }
      & .section6 {
        fill: #fff;
      }
      &:hover .section1 {
        fill: #ff3569;
      }
      &:hover .section2 {
        fill: #fdc305;
      }
      &:hover .section3 {
        fill: #80febb;
      }
      &:hover .section4 {
        fill: #0070fa;
      }
      &:hover .section5 {
        fill: #694eea;
      }
      &:hover .section6 {
        fill: #e2deea;
      }
    `}

    ${({ nudge, theme }) => {
    if (!nudge) {
      return
    }
    const styles = []
    if (nudge.left) {
      styles.push(
        css`
          right: ${theme.rhythm(nudge.left)};
        `
      )
    }
    if (nudge.right) {
      styles.push(
        css`
          left: ${theme.rhythm(nudge.right)};
        `
      )
    }
    if (nudge.up) {
      styles.push(
        css`
          bottom: ${theme.rhythm(nudge.up)};
        `
      )
    }
    if (nudge.down) {
      styles.push(
        css`
          top: ${theme.rhythm(nudge.down)};
        `
      )
    }
    return styles
  }}
`

export default function Icon({ name, ...rest }: IconProps): JSX.Element {
  const iconMap = {
    AddCircle: <AddCircle />,
    ArrowRight: <ArrowRight />,
    Calendar: <Calendar />,
    Cancel: <Cancel />,
    Check: <Check />,
    Clipboard: <Clipboard />,
    Cog: <Cog />,
    Exit: <Exit />,
    Expand: <Expand />,
    ExportAsCsv: <ExportAsCsv />,
    ExternalLink: <ExternalLink />,
    FreeAgencyFlag: <FreeAgencyFlag />,
    Info: <Info />,
    Lock: <Lock />,
    OpenLock: <OpenLock />,
    Pause: <Pause />,
    Pen: <Pen />,
    Play: <Play />,
    Plus: <Plus />,
    Queue: <Queue />,
    Search: <Search />,
    Star: <Star />,
    ThumbsDown: <ThumbsDown />,
    ThumbsUp: <ThumbsUp />,
    Trash: <Trash />,
    Linkedin: <Linkedin />,
    Huntr: <Huntr />,
    Comment: <Comment />,
    Globe: <Globe />,
    AngelList: <AngelList />,
    Glassdoor: <Glassdoor />,
    Crunchbase: <Crunchbase />,
    CaretArrowDown: <CaretArrowDown />,
    Minus: <Minus />,
    User: <User />,
    File: <File />,
    ClipboardCheck: <ClipboardCheck />,
    ClipboardList: <ClipboardList />,
    FolderPlus: <FolderPlus />,
    TimesCircle: <TimesCircle />,
  }

  return <IconBox {...rest}>{iconMap[name]}</IconBox>
}
