import React, { useState } from 'react'
import styled, { css } from 'styled-components/macro'
import Icon from 'components/Icon/Icon'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type StyledEditableLabelProps = {
  isEditing: boolean
}

const StyledEditableLabel = styled.div<StyledEditableLabelProps>`
  cursor: pointer;
  padding: ${({ theme }) => theme.rhythm(1.5)};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ isEditing }) =>
    isEditing &&
    css`
      padding: ${({ theme }) => theme.toRems(3)} ${({ theme }) => theme.rhythm(1)}
        ${({ theme }) => theme.rhythm(1)} 0;
    `}

  label {
    cursor: pointer;
  }

  &:hover {
    ${({ isEditing }) =>
      !isEditing &&
      css`
        background-color: rgba(0, 0, 0, 0.1);
      `}
  }
`

type StyledInputProps = {
  size?: number
  bold?: boolean
}

const StyledInput = styled.input<StyledInputProps>`
  border: none;
  padding: ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(1.5)};
  line-height: 1;
  color: ${({ theme }) => theme.color.text};
  padding-left: ${({ theme }) => theme.rhythm(1.5)};
  font-size: ${({ size = 16, theme }) => theme.toRems(size)};
  ${({ bold }) => bold && 'font-weight: 700;'}
`

type Props = {
  label: string
  value: string
  onSubmit: (
    event: React.FormEvent<HTMLFormElement>,
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  ) => void
  onValueChange(value: string): void
  txtProperties?: {
    size?: number
    bold?: boolean
  }
}

export default function EditableLabel({
  value,
  label,
  onSubmit,
  txtProperties,
  onValueChange,
}: Props): JSX.Element {
  const [isEditing, setIsEditing] = useState(false)
  function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
    onSubmit(event, setIsEditing)
  }

  return (
    <StyledEditableLabel onClick={() => setIsEditing(true)} isEditing={isEditing}>
      {isEditing ? (
        <form onSubmit={handleSubmit}>
          <StyledInput
            autoFocus
            value={value}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
              onValueChange(event.currentTarget.value)
            }
            {...txtProperties}
          />
        </form>
      ) : (
        <Txt as="label" {...txtProperties}>
          {label}
        </Txt>
      )}
      {!isEditing && (
        <Padding inline left={2}>
          <Icon name="Pen" height={12} width={12} primaryFill="text" />
        </Padding>
      )}
    </StyledEditableLabel>
  )
}
