import React, { useState } from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { getDefaultState } from './NewInterviewCycle.helpers'
import { State } from './NewInterviewCycle.types'
import NewInterviewCycleFooter from './NewInterviewCycleFooter'
import NewInterviewCycleForm from './NewInterviewCycleForm'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function NewInterviewCycle(): React.ReactElement {
  const queryString = useQueryString()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [state, setState] = useState<State>(getDefaultState(queryString))

  return (
    <ViewBox
      footer={
        <NewInterviewCycleFooter
          state={state}
          setState={setState}
          isSubmitting={isSubmitting}
          setIsSubmitting={setIsSubmitting}
          queryString={queryString}
        />
      }
    >
      <Container noMax>
        <Padding top={6}>
          <NewInterviewCycleForm state={state} setState={setState} />
        </Padding>
      </Container>
    </ViewBox>
  )
}

NewInterviewCycle.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.NEW_INTERVIEW_CYCLE}
    key={ROUTE_PATHS.NEW_INTERVIEW_CYCLE}
  >
    <NewInterviewCycle />
  </PrivateRoute>,
]
