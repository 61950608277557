import React from 'react'
import Button from 'components/Button'

export default function WriterTableNavByDueDate(): JSX.Element {
  return (
    <Button $type="link" active>
      My Taglines By Due Date
    </Button>
  )
}
