import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { columns } from './ClientExpertRequests.constants'
import { ExpertRequestSortable } from './ClientExpertRequests.types'
import ExpertRequestsRow from './ClientExpertRequestsRow'
import Txt from 'components/Txt'

type Props = {
  tableData: ExpertRequestSortable[]
  clientId: string
}

export default function ClientExpertRequestsTable({
  tableData,
  clientId,
}: Props): React.ReactElement {
  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {columns.map((column) => (
                <HeaderData key={column.id} collapse={column.collapse}>
                  <Txt size={12} bold>
                    {column.label}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {tableData.map((expertRequest) => (
              <ExpertRequestsRow
                key={expertRequest.id}
                expertRequest={expertRequest}
                clientId={clientId}
              />
            ))}
          </tbody>
        </Table>
      </TableWrapper>
    </TableBox>
  )
}
