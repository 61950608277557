/**
 * returns true if access token is expired
 * @param expiresAt milliseconds since epoch when the token expires
 */
export default function isTokenExpired(expiresAt: number): boolean {
  const current = new Date()
  const hourInMs = 1000 * 60 * 60
  const millisecondsSinceEpoch = current.getTime()

  // Will resolve to false if we are within an hour of the expire date
  return expiresAt < millisecondsSinceEpoch + hourInMs
}
