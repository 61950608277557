import React from 'react'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

export default function ReportInterviewDecline(): React.ReactElement {
  return (
    <>
      <Txt size={18} bold alignment="center">
        This interview has been declined.
      </Txt>
      <Padding top={2}>
        <Txt alignment="center">
          If this was a mistake please reach out to your Talent Agent. You may now
          close the window.
        </Txt>
      </Padding>
    </>
  )
}
