import React from 'react'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import { StatBoxContainer } from './ClientConfig.styles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  header: string
  subHeader: string
  value: string | undefined
}

export default function ClientConfigStatBox({
  header,
  subHeader,
  value,
}: Props): React.ReactElement {
  if (!value) {
    return <RectangleSkeleton height={80} width={80} />
  }
  return (
    <StatBoxContainer>
      <Padding vertical={3} horizontal={4}>
        <Txt size={14}>{header}</Txt>
        <Padding top={1.5}>
          <Txt size={24} bold>
            {value}
          </Txt>
        </Padding>
        <Padding top={1.5}>
          <Txt size={14} color="faGrey4">
            {subHeader}
          </Txt>
        </Padding>
      </Padding>
    </StatBoxContainer>
  )
}
