import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import {
  SourcedJobsReviewQueueQuery,
  SourcedJobsReviewQueueQueryVariables,
  SourcedJobsReviewQueueCountQuery,
  SourcedJobsReviewQueueCountQueryVariables,
} from 'generated/graphql'

type QueueReturn = {
  fullQueue: string[] | undefined
  loading: boolean
}

type CountReturn = {
  loading: boolean
  totalCount: Undefinable<number>
}

const INITIAL_COUNT = 0

export default function useSourcedJobsReviewQueue(
  queryOptions: QueryHookOptions<
    SourcedJobsReviewQueueQuery,
    SourcedJobsReviewQueueQueryVariables
  > = {},
  clientId: string
): QueueReturn {
  const { data, loading } = useQuery<
    SourcedJobsReviewQueueQuery,
    SourcedJobsReviewQueueQueryVariables
  >(GET_SOURCED_JOBS_REVIEW_QUEUE, {
    variables: {
      id: clientId,
    },
    ...queryOptions,
  })

  // build sorted array of sourced jobs
  const sourcedJobs = data?.client?.sourcedJobs.edges
    .map((edge) => ({
      ...edge.node,
    }))
    .sort((a, b) => sortByProp(a, b, 'createdAt'))

  const fullQueue = sourcedJobs?.map(reduceObjectToId)

  return { fullQueue, loading }
}

export function useSourcedJobsReviewQueueCount(
  queryOptions: QueryHookOptions<
    SourcedJobsReviewQueueCountQuery,
    SourcedJobsReviewQueueCountQueryVariables
  > = {}
): CountReturn {
  const {
    userSession: {
      user: { id: talentAgentId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<
    SourcedJobsReviewQueueCountQuery,
    SourcedJobsReviewQueueCountQueryVariables
  >(GET_SOURCED_JOBS_REVIEW_QUEUE_COUNT, {
    ...queryOptions,
    variables: {
      talentAgentId,
    },
  })

  const totalCount = data?.clients?.edges.reduce(
    (sourcedJobCount, clientJob) =>
      sourcedJobCount + clientJob.node.sourcedJobs.totalCount,
    INITIAL_COUNT
  )
  return { loading, totalCount }
}

const GET_SOURCED_JOBS_REVIEW_QUEUE = gql`
  query SourcedJobsReviewQueue($id: ID!) {
    client(id: $id) {
      sourcedJobs(isRedacted: false, isReviewed: false) {
        edges {
          node {
            id
            createdAt
          }
        }
      }
    }
  }
`

const GET_SOURCED_JOBS_REVIEW_QUEUE_COUNT = gql`
  query SourcedJobsReviewQueueCount($talentAgentId: ID!) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          sourcedJobs(isReviewed: false, isRedacted: false) {
            totalCount
          }
        }
      }
    }
  }
`
