import { lighten, darken } from 'polished'
import React from 'react'
import styled from 'styled-components/macro'
import { DropdownOption } from 'types'
import { EMPTY_INPUT } from 'utils/constants'
import Button from 'components/Button'
import HList from 'components/HList'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'
import Txt from 'components/Txt'

type Props = {
  name: string
  label?: string
  defaultValue?: string
  options: DropdownOption[]
  required?: boolean
  value?: string | null
  onValueChange?: (value: string) => void
  loadingOptions?: boolean
  loadingMessage?: string
  emptyOptionsMessage?: string
  description?: string
  disabled?: boolean
  canDeselect?: boolean
}

const RadioButton = styled.input`
  position: absolute;
  opacity: 0 !important;
  cursor: pointer;
  right: 50%;
  bottom: 0;
  transform: translateX(50%);
  z-index: -1;

  &:disabled ~ div {
    cursor: default;
    opacity: 0.7;
  }

  &:checked {
    ~ div {
      background-color: ${({ theme }) => theme.color.faBlue};
      border: 0.1rem solid ${({ theme }) => theme.color.faBlue};
      color: ${({ theme }) => theme.color.white};
    }
  }
  &:hover:not(:disabled) {
    ~ div {
      background-color: ${({ theme }) => darken(0.1, theme.color.white)};
    }
    &:checked ~ div {
      background-color: ${({ theme }) => lighten(0.1, theme.color.faBlue)};
      border: 0.1rem solid ${({ theme }) => lighten(0.1, theme.color.faBlue)};
    }
  }
`

const RadioButtonWrapper = styled.label`
  position: relative;
  cursor: pointer;
  user-select: none;
`

export function RadioGroup({
  name,
  label,
  options,
  value,
  defaultValue,
  description,
  required,
  onValueChange,
  loadingMessage,
  emptyOptionsMessage,
  loadingOptions = false,
  disabled = false,
  canDeselect = false,
}: Props): JSX.Element {
  function handleClick(event: React.MouseEvent<HTMLInputElement, MouseEvent>) {
    let newValue = event.currentTarget.value
    if (newValue === value) {
      if (canDeselect) {
        newValue = EMPTY_INPUT
      } else {
        return
      }
    }
    onValueChange?.(newValue)
  }

  return (
    <div>
      {label && <Label content={label} />}
      {description && <InputDescription description={description} />}
      <HList size={1} rowGapSize={1}>
        {options.map((option) => (
          <RadioButtonWrapper key={option.value}>
            <RadioButton
              onClick={handleClick}
              type="radio"
              name={name}
              value={option.value}
              defaultChecked={
                defaultValue !== undefined
                  ? option.value === defaultValue
                  : undefined
              }
              checked={value !== undefined ? value === option.value : undefined}
              required={required}
              disabled={disabled}
            />
            <Button as="div" small>
              {option.label}
            </Button>
          </RadioButtonWrapper>
        ))}
        {!options.length && !loadingOptions && (
          <Txt color="error" italic size={14} lineHeight={1.3}>
            {emptyOptionsMessage || 'No options found.'}
          </Txt>
        )}
        {loadingOptions && loadingMessage && (
          <Txt color="faGrey4" italic size={14} lineHeight={1.3}>
            {loadingMessage}
          </Txt>
        )}
      </HList>
    </div>
  )
}
