import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { useAuthContext } from 'context/auth'
import { EMPTY_INPUT } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import DossierFeedbackTable, { DossierSubmissionRow } from './DossierFeedbackTable'
import Container from 'components/Container'
import { Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetDossierWritersQuery,
  GetDossierWritersQueryVariables,
  GetUserDossierFeedbackQuery,
  GetUserDossierFeedbackQueryVariables,
} from 'generated/graphql'

export default function DossierFeedback(): React.ReactElement {
  const {
    userSession: { user: viewer },
  } = useAuthContext()
  const [dossierWriterId, setDossierWriterId] = useState(EMPTY_INPUT)
  const { data: dropdownData } = useQuery<
    GetDossierWritersQuery,
    GetDossierWritersQueryVariables
  >(GET_DOSSIER_WRITERS, { skip: !viewer.isCoordinator })

  const { data, loading, error, refetch } = useQuery<
    GetUserDossierFeedbackQuery,
    GetUserDossierFeedbackQueryVariables
  >(GET_USER_DOSSIER_FEEDBACK, {
    variables: {
      createdById: viewer.id,
    },
  })

  const dossierWriterOptions = buildDropdownOptions(dropdownData?.users ?? [])

  function handleInputChange(value: string) {
    setDossierWriterId(value)
    void refetch({ createdById: value || undefined })
  }

  const dossierSubmissions = data?.dossierSubmissions
    ? getNodes(data.dossierSubmissions)
        .filter((item) => !item.rejectionReason)
        .map((item) => ({ ...item, createdAtDayjs: dayjs(item.createdAt) }))
        .sort((a, b) => sortByProp(a, b, '-createdAtDayjs'))
    : []

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            Dossier Feedback
          </Txt>
        </Padding>
        {viewer.isCoordinator && (
          <Padding bottom={4}>
            <Dropdown
              label="Writer"
              value={dossierWriterId}
              onValueChange={handleInputChange}
              options={dossierWriterOptions}
              placeholder="Choose a dossier writer"
              withEmptyOption={false}
            />
          </Padding>
        )}
      </Container>
      <DossierFeedbackTable
        dossierSubmissions={dossierSubmissions}
        loading={loading}
        error={error}
        isEmpty={
          !loading && Boolean(data?.dossierSubmissions) && !dossierSubmissions.length
        }
      />
    </ViewBox>
  )
}

DossierFeedback.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.DOSSIER_FEEDBACK}
    key={ROUTE_PATHS.DOSSIER_FEEDBACK}
  >
    <DossierFeedback />
  </PrivateRoute>,
]

const GET_DOSSIER_WRITERS = gql`
  query GetDossierWriters {
    users(anyRole: { isDossierWriter: true }) {
      id
      name
    }
  }
`

const GET_USER_DOSSIER_FEEDBACK = gql`
  query GetUserDossierFeedback($createdById: ID!) {
    dossierSubmissions(createdById: $createdById, isReviewed: true) {
      edges {
        node {
          id
          fileUrl
          createdAt
          rejectionReason
          ...DossierSubmissionRowInfo
        }
      }
    }
  }
  ${DossierSubmissionRow.fragments.DossierSubmissionRowInfo}
`
