export const FR_POD_COUNT = Number(process.env.REACT_APP_FR_POD_COUNT) || 4
export const IR_POD_COUNT = Number(process.env.REACT_APP_IR_POD_COUNT) || 4

export const WRITERS_PAGE_DAYS_AGO =
  Number(process.env.REACT_APP_WRITERS_DAYS_AGO) || 90
export const SOURCERS_PAGE_DAYS_AGO =
  Number(process.env.REACT_APP_SOURCERS_DAYS_AGO) || 90
export const SOURCING_REVIEW_MAX_DAYS =
  Number(process.env.REACT_APP_SOURCING_DAY_REVIEW_MAX_DAYS) || 16
export const SHOW_IMPORTANT_FLAG =
  process.env.REACT_APP_SHOW_IMPORTANT_FLAG === 'true'
export const SHOW_TRACKER = process.env.REACT_APP_SHOW_TRACKER === 'true'
export const SHOW_TEMPLATE_VARIABLES =
  process.env.REACT_APP_SHOW_TEMPLATE_VARIABLES === 'true'

export const TEMPLATE_VARIABLES_TEST_RATE = process.env
  .REACT_APP_TEMPLATE_VARIABLES_TEST_RATE
  ? Number(process.env.REACT_APP_TEMPLATE_VARIABLES_TEST_RATE)
  : 0

export const SHOW_TALENT_AGENT_REVIEW =
  process.env.REACT_APP_SHOW_TALENT_AGENT_REVIEW === 'true'

export const SHOW_NO_TAGLINE_FLAG =
  process.env.REACT_APP_SHOW_NO_TAGLINE_FLAG === 'true'
