import { gql } from '@apollo/client'
import { ColumnHeader, DropdownOption } from 'types'
import {
  ExpertFunctionalArea,
  ExpertPaymentType,
  ExpertSeniorityLevel,
} from 'generated/graphql'

export const HEADERS: ColumnHeader[] = [
  {
    label: '',
    id: 'expand',
    collapse: true,
  },
  {
    label: 'Name',
    id: 'name',
  },
  {
    label: 'LinkedIn',
    id: 'linkedin',
  },
  {
    label: 'Current Title',
    id: 'currentTitle',
  },
  {
    label: 'Companies',
    id: 'companies',
  },
  {
    label: 'Seniority',
    id: 'seniority',
  },
  {
    label: 'Functional Area',
    id: 'functionalAreas',
  },
  {
    label: 'Hourly Rate',
    id: 'hourlyRate',
  },
  {
    label: 'Contractor',
    id: 'isContractor',
  },
]

export const SENIORITY_LEVEL_OPTIONS: DropdownOption<ExpertSeniorityLevel>[] = [
  {
    label: 'Analyst',
    value: ExpertSeniorityLevel.ANALYST,
  },
  {
    label: 'Manager',
    value: ExpertSeniorityLevel.MANAGER,
  },
  {
    label: 'Director',
    value: ExpertSeniorityLevel.DIRECTOR,
  },
  {
    label: 'VP',
    value: ExpertSeniorityLevel.VP,
  },
  {
    label: 'C-Level',
    value: ExpertSeniorityLevel.C_LEVEL,
  },
]

export const FUNCTIONAL_AREA_OPTIONS: DropdownOption<ExpertFunctionalArea>[] = [
  {
    label: 'Design',
    value: ExpertFunctionalArea.DESIGN,
  },
  {
    label: 'Engineering',
    value: ExpertFunctionalArea.ENGINEERING,
  },
  {
    label: 'Finance',
    value: ExpertFunctionalArea.FINANCE,
  },
  {
    label: 'Marketing',
    value: ExpertFunctionalArea.MARKETING,
  },
  {
    label: 'Operations',
    value: ExpertFunctionalArea.OPERATIONS,
  },
  {
    label: 'Product',
    value: ExpertFunctionalArea.PRODUCT,
  },
  {
    label: 'Sales',
    value: ExpertFunctionalArea.SALES,
  },
  {
    label: 'Legal',
    value: ExpertFunctionalArea.LEGAL,
  },
]

export const PAYMENT_TYPE_OPTIONS: DropdownOption<ExpertPaymentType>[] = [
  {
    label: 'Amazon',
    value: ExpertPaymentType.AMAZON,
  },
  {
    label: 'Paypal',
    value: ExpertPaymentType.PAYPAL,
  },
  {
    label: 'Gusto',
    value: ExpertPaymentType.GUSTO,
  },
]

export const ID = {
  FORM: 'expertForm',
}

export const TOAST = {
  CREATE_SUCCESS: 'Expert created',
  CREATE_ERROR: 'There was an error creating the expert.',
  MODIFY_SUCCESS: 'Expert updated',
  MODIFY_ERROR: 'There was an error updating the expert.',
}

export const EXPERT_REQUEST_HEADERS: ColumnHeader[] = [
  {
    label: '#',
    id: 'number',
  },
  {
    label: '',
    id: 'space',
  },
  {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  {
    label: 'Talent Agent',
    id: 'talentAgent',
  },
  {
    label: 'Deadline',
    id: 'deadline',
  },
]

export const ExpertFragment = gql`
  fragment ExpertInfo on Expert {
    id
    name
    linkedinUrl
    email
    employers {
      edges {
        node {
          id
          name
          domain
          logoUrl
        }
      }
    }
    seniorityLevels
    industries {
      edges {
        node {
          id
          name
        }
      }
    }
    functionalAreas
    currentTitle
    hourlyRate
    is1099ed
    isProBono
    paymentType
    additionalPaymentDetails
    expertRequests {
      edges {
        node {
          id
          due
          client {
            id
            name
            talentAgent {
              id
              name
            }
          }
        }
      }
    }
  }
`
