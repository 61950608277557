import { gql } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'

type Props = {
  jobTitle: string | null | undefined
  company: { id?: string; name: string | null | undefined } | null | undefined
  huntrUrl: string
}

function JobHeading({ jobTitle, company, huntrUrl }: Props): React.ReactElement {
  const externalLink = (
    <ExternalLink
      url={huntrUrl}
      label="Huntr"
      inheritStyles
      txtProps={{
        hoverColor: 'black',
        color: 'text',
      }}
    />
  )
  const employer = company?.id ? (
    <Link
      to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, { companyId: company.id })}
      target="_blank"
    >
      <Txt as="span" underline inheritStyles hoverColor="black" color="text">
        {company.name}
      </Txt>
    </Link>
  ) : (
    company?.name || 'Unknown company'
  )

  if (jobTitle || company?.name) {
    return (
      <>
        {jobTitle || 'Unknown Role'} @ {employer} ({externalLink})
      </>
    )
  } else {
    return (
      <>
        Unable to load job information from
        {externalLink}
      </>
    )
  }
}

JobHeading.fragments = {
  jobHeadingInfo: gql`
    fragment JobHeadingInfo on TaglineRequest {
      jobTitle
      employerName
      huntrUrl
      company {
        id
        name
      }
    }
  `,
  jobHeadingInfoApplication: gql`
    fragment JobHeadingInfoApplication on Application {
      jobTitle
      employerName
      huntrUrl
      company {
        id
        name
      }
    }
  `,
  jobHeadingInfoEnrichment: gql`
    fragment JobHeadingInfoEnrichment on EnrichmentRequest {
      jobTitle
      employerName
      huntrUrl
      company {
        id
        name
      }
    }
  `,
}

export default JobHeading
