import { DropdownOption } from 'types'

export const ID = {
  FORM: 'addInterviewForm',
}

export const DEFUALT_STATE = {
  clientId: '',
  interviewCycleId: '',
  datetime: '',
  timezone: null,
  interviewerNames: '',
  notes: '',
  isScheduled: true,
}

export const SCHEDULED_OPTIONS: DropdownOption[] = [
  {
    label: 'Scheduled',
    value: String(true),
  },
  {
    label: 'Unscheduled',
    value: String(false),
  },
]

export const COPY = {
  CREATE_SUCCESS: 'Interview added',
  CREATE_ERROR: 'There was an error adding the interview',
  UPDATE_SUCCESS: 'Interview updated',
  UPDATE_ERROR: 'There was an error updating the interview',
}

export enum TzType {
  TzTypeDefault = 'Default',
  TzTypeEvent = 'Event',
}
