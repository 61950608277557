import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import { getApplicationStatusCopy } from 'utils/helpers/applications'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { CompanyApplicationColumns } from '../CompanyDetail.constants'
import { Application, Company } from '../CompanyDetail.types'
import Txt from 'components/Txt'
import {
  GetCompanyApplicationsQuery,
  GetCompanyApplicationsQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

const normalizeData = (
  data: NonNullable<GetCompanyApplicationsQuery['company']>
): Application[] => {
  return getNodes<Application>(data.applications)
    .map((item) => ({ ...item, createdAtDayjs: dayjs(item.createdAt) }))
    .sort((a, b) => sortByProp(a, b, 'createdAtDayjs'))
}

export default function CompanyApplications({ company }: Props): React.ReactElement {
  const { data, error, loading } = useQuery<
    GetCompanyApplicationsQuery,
    GetCompanyApplicationsQueryVariables
  >(GET_COMPANY_APPLICATIONS, {
    variables: { id: company.id },
  })

  const items = data?.company ? normalizeData(data.company) : null

  return (
    <FullWidthTable
      headers={Object.values(CompanyApplicationColumns)}
      isError={Boolean(error)}
      isLoading={loading}
      isEmpty={Boolean(items && !items.length)}
      emptyMessage={`Could not find any applications`}
      loadingMessage={`Loading applications...`}
      errorMessage={`There was an error fetching the applications`}
    >
      {items?.map((item) => (
        <CompanyDetailApplicationRow key={item.id} item={item} />
      ))}
    </FullWidthTable>
  )
}

type AppProps = { item: Application }

function CompanyDetailApplicationRow({ item }: AppProps): React.ReactElement {
  const statusCopy = getApplicationStatusCopy(item)
  const submission = item.applicationSubmissions.find((a) => !a.rejectionReason)
  return (
    <BodyRow>
      <BodyData collapse={CompanyApplicationColumns.link?.collapse}>
        <Link
          to={generatePath(ROUTE_PATHS.APPLICATION_INFO, { applicationId: item.id })}
        >
          <Icon
            name="ExternalLink"
            height={14}
            width={14}
            primaryFill="text"
            primaryFillHover="black"
          />
        </Link>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {item.client.name}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {item.jobTitle ?? EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {statusCopy}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {submission
            ? dayjs(submission.createdAt).format(
                DATE_FORMAT.FRIENDLY_DATE_SHORT_MONTH
              )
            : EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {item.interviewCycles.length ? 'Yes' : 'No'}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_COMPANY_APPLICATIONS = gql`
  query GetCompanyApplications($id: ID!) {
    company(id: $id) {
      id
      applications {
        edges {
          node {
            id
            client {
              id
              name
            }
            interviewCycles {
              id
            }
            jobTitle
            employerName
            createdAt
            applicationSubmissions {
              id
              createdAt
            }
            ...ApplicationStatusCopy
          }
        }
      }
    }
  }
  ${getApplicationStatusCopy.fragments.applicationStatusCopyInfo}
`
