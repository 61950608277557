import React from 'react'
import { sortByProp } from 'utils/sort'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { COLUMNS } from './AutoSourcingDashboard.constants'
import { Clients } from './AutoSourcingDashboard.types'
import AutoSourcingDashboardRow from './AutoSourcingDashboardRow'
import Message from 'components/Message'
import Txt from 'components/Txt'

type Props = {
  clients: Clients | null | undefined
  loading: boolean
}

export default function AutoSourcingDashboardTable({
  clients,
  loading,
}: Props): React.ReactElement {
  const noClients = !loading && !clients?.length
  const sortedClients =
    clients && clients.slice().sort((a, b) => sortByProp(a, b, 'name'))

  return (
    <>
      <TableBox>
        <TableWrapper>
          <Table>
            <thead>
              <HeaderRow>
                {COLUMNS.map((column) => (
                  <HeaderData key={column.id}>
                    <Txt size={12} bold>
                      {column.label}
                    </Txt>
                  </HeaderData>
                ))}
              </HeaderRow>
            </thead>
            <tbody>
              {sortedClients?.map((client) => (
                <AutoSourcingDashboardRow key={client.id} client={client} />
              ))}
            </tbody>
          </Table>
        </TableWrapper>
      </TableBox>
      {loading && <Message message="Loading clients..." vertical />}
      {noClients && <Message message="No clients to show" vertical />}
    </>
  )
}
