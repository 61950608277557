import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import { StyledTxt, NameTextContainer } from './DashboardCard.styles'
import { JobInfo } from './DashboardCard.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  jobInfo: JobInfo
  actionText?: string
  phraseText?: string
}

export default function DashboardCardJobInfoLabel({
  jobInfo,
  actionText,
  phraseText,
}: Props): React.ReactElement {
  function renderCompanyDomain() {
    if (jobInfo.companyDomain) {
      return (
        <ExternalLink url={jobInfo.companyDomain} txtProps={{ underline: false }}>
          <StyledTxt size={14} color="darkGray">
            {jobInfo.companyName}
          </StyledTxt>
        </ExternalLink>
      )
    }
    if (jobInfo.companyId) {
      return (
        <Link
          to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, {
            companyId: jobInfo.companyId,
          })}
        >
          <Txt size={14} color="darkGray">
            {jobInfo.companyName}
          </Txt>
        </Link>
      )
    }
    return (
      <Txt size={14} color="darkGray">
        {jobInfo.companyName}
      </Txt>
    )
  }

  return (
    <Flex align="center">
      <div>
        <Padding bottom={1}>
          {actionText && phraseText ? (
            <NameTextContainer>
              <Link
                to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
                  clientId: jobInfo.clientId,
                })}
              >
                <StyledTxt display="inline" color="darkGray" bold>
                  {jobInfo.clientName}
                </StyledTxt>
              </Link>
              <Txt display="inline"> {actionText} </Txt>
              <Txt display="inline" color="darkGray" bold>
                {phraseText}
              </Txt>
            </NameTextContainer>
          ) : (
            <Link
              to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
                clientId: jobInfo.clientId,
              })}
            >
              <StyledTxt color="darkGray" bold>
                {jobInfo.clientName}
              </StyledTxt>
            </Link>
          )}
        </Padding>
        <Flex wrap="wrap">
          {jobInfo.jobUrl ? (
            <ExternalLink url={jobInfo.jobUrl} txtProps={{ underline: false }}>
              <StyledTxt size={14} color="darkGray">
                {jobInfo.jobTitle}
              </StyledTxt>
            </ExternalLink>
          ) : (
            <Txt size={14} color="darkGray">
              {jobInfo.jobTitle}
            </Txt>
          )}
          <Txt size={14} color="darkGray">
            &nbsp;@&nbsp;
          </Txt>
          {renderCompanyDomain()}
        </Flex>
      </div>
    </Flex>
  )
}
