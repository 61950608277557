import { ApolloError } from '@apollo/client'
import React from 'react'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import UserRow from './UserRow'
import { COLUMNS } from './Users.constants'
import { User } from './Users.types'

type Props = {
  users: User[]
  loading: boolean
  error: ApolloError | undefined
}

export default function UsersTable({
  users,
  loading,
  error,
}: Props): React.ReactElement {
  return (
    <FullWidthTable
      errorMessage="There was an error fetching users"
      isError={Boolean(error)}
      loadingMessage="Loading users..."
      isLoading={loading}
      isEmpty={Boolean(!loading && !users.length)}
      emptyMessage="No users found."
      headers={COLUMNS}
    >
      {users.map((user) => (
        <UserRow key={user.id} user={user} />
      ))}
    </FullWidthTable>
  )
}
