import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { ReactFormEvent, ReactSetState } from 'types'
import CompanySuggestion from 'components/CompanySuggestion/CompanySuggestion'
import { Suggestion } from 'components/CompanySuggestion/CompanySuggestion.types'
import Editor from 'components/Editor/Editor'
import { DEFAULT_STATE, ERROR_CODE, TOAST } from './SourcingSubmission.constants'
import { SourcingRequest, State } from './SourcingSubmission.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import { TextArea, TextInput } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import { SubmitSourcedJobMutation } from 'generated/graphql'

type Props = {
  state: State
  setState: ReactSetState<State>
  sourcingRequest: SourcingRequest
}

export default function SourcingSubmissionForm({
  state,
  setState,
  sourcingRequest,
}: Props): React.ReactElement {
  const [hasBlurred, setHasBlurred] = useState(false)
  const [hasSelected, setHasSelected] = useState(false)
  function handleInputChange(value: string, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  async function addJob(event: ReactFormEvent) {
    event.preventDefault()

    if (!validateFormData()) {
      return
    }

    const { companyDomain, companyLogo, ...rest } = state
    const SubmitSourcedJobInput = {
      ...rest,
      sourcingRequestId: sourcingRequest.id,
      // We want to send null if user hasn't selected company from auto-suggest
      companyDomain: companyDomain || null,
      companyLogo: companyLogo || null,
    }

    // eslint-disable-next-line no-console
    try {
      const { data } = await submitSourcedJob({
        variables: {
          SubmitSourcedJobInput,
        },
      })
      const errorCode = data?.submitSourcedJob?.errorCode
      if (errorCode) {
        switch (errorCode) {
          case ERROR_CODE.DUP_URL:
            toast.error(TOAST.DUP_URL)
            break

          default:
            toast.error(TOAST.JOB_ERROR)
            break
        }
      } else {
        setState(DEFAULT_STATE)
      }
    } catch {
      toast.error('There was an error adding the job')
    }
  }

  function validateFormData() {
    if (!state.jobDescription) {
      toast.error('Must add job description')
      return false
    }
    return true
  }

  /**
   * sets the appropriate state properties when user clicks suggestion
   * @param selection suggestion object
   */
  function handleSelectionChange(selection: Suggestion | null) {
    setState((prevState) => ({
      ...prevState,
      company: selection?.name ?? '',
      companyDomain: selection?.domain ?? '',
      companyLogo: selection?.logo ?? '',
    }))
    setHasSelected(true)
  }

  const [submitSourcedJob, { loading }] =
    useMutation<SubmitSourcedJobMutation>(SUBMIT_SOURCED_JOBS)

  const currentSelection = hasSelected
    ? {
        name: state.company,
        domain: state.companyDomain,
        logo: state.companyLogo,
      }
    : null

  return (
    <form id="sourcingSubmission" onSubmit={addJob}>
      <Padding bottom={3}>
        <Flex justify="space-between" align="center">
          <Txt>New Sourcing</Txt>
          <Button
            $type="accept"
            as="input"
            type="submit"
            value="Add Job +"
            form="sourcingSubmission"
            disabled={!hasSelected || loading}
          />
        </Flex>
      </Padding>
      <VList size={3}>
        <TextInput
          value={state.url}
          onValueChange={(value: string) => handleInputChange(value, 'url')}
          label="Job Description URL *"
          name="url"
          type="url"
          required
          placeholder="https://"
          fullWidth
          autoComplete="off"
        />
        <div>
          {state.company && !hasSelected && hasBlurred && (
            <Padding bottom={1.5}>
              <Txt size={14} color="error">
                Must select a company from the dropdown
              </Txt>
            </Padding>
          )}
          <CompanySuggestion
            value={state.company}
            onValueChange={(value: string) => handleInputChange(value, 'company')}
            label="Company *"
            selection={currentSelection}
            onSelectionValueChange={handleSelectionChange}
            onBlur={() => setHasBlurred(true)}
            required
          />
        </div>
        <TextInput
          value={state.title}
          onValueChange={(value: string) => handleInputChange(value, 'title')}
          label="Job Title *"
          name="title"
          fullWidth
          required
          autoComplete="off"
        />
        <Editor
          key={`${sourcingRequest.id}:${sourcingRequest.sourcedJobs.length}`}
          handleChange={(value: string) =>
            handleInputChange(value, 'jobDescription')
          }
          label="Job Description *"
        />
        <TextArea
          value={state.notes}
          onValueChange={(value: string) => handleInputChange(value, 'notes')}
          label="Notes"
          name="notes"
          height={120}
          fullWidth
        />
      </VList>
    </form>
  )
}

/**
 * Used by Sourcer to submit a job
 */
const SUBMIT_SOURCED_JOBS = gql`
  mutation SubmitSourcedJob($SubmitSourcedJobInput: SubmitSourcedJobInput!) {
    submitSourcedJob(input: $SubmitSourcedJobInput) {
      sourcedJob {
        id
        sourcingRequest {
          id
          sourcedJobs {
            id
            company: companyEntity {
              name
            }
            title
          }
        }
      }
      errorCode
    }
  }
`
