const COL_HEADERS = ['Free Agent', 'Assignments', 'Deadlines']
const COL_HEADERS_BY_DUE = ['Due Date', 'Assignments']

export enum OptionValues {
  MyWork = 'MyWork',
  ByDueDate = 'ByDueDate',
}

export const LABEL_OPTIONS = [
  { label: 'My Dossier Work', value: OptionValues.MyWork },
  { label: 'By Due Date', value: OptionValues.ByDueDate },
]

export const COLUMNS = {
  [OptionValues.MyWork]: COL_HEADERS,
  [OptionValues.ByDueDate]: COL_HEADERS_BY_DUE,
}
