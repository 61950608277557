import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components/macro'
import Padding from 'components/Padding'

type ShelfProps = {
  isCollapsed: boolean
}

export const ShelfArrowButton = styled.button<ShelfProps>`
  align-items: flex-start;
  display: flex;
  padding: ${({ theme }) => theme.rhythm(3)} 0;
  justify-content: center;
  outline: none;
  position: absolute;
  right: ${({ theme }) => theme.rhythm(-3)};
  top: ${({ theme }) => theme.rhythm(3)};
  width: ${({ theme }) => theme.rhythm(6)};
`

export const StyledShelf = styled.div<ShelfProps>`
  position: fixed;
  height: 100%;
  left: 0;
  top: 0;
  width: ${({ theme, isCollapsed }) =>
    isCollapsed ? theme.toRems(56) : theme.toRems(240)};
  background: ${({ theme }) => theme.color.faGrey150};
  transition: width 0.3s ease-out;
`

export const ShelfContent = styled.div<ShelfProps>`
  padding: ${({ theme, isCollapsed }) =>
    isCollapsed ? theme.rhythm(1) : theme.rhythm(2)};
  padding-bottom: ${({ theme }) => theme.rhythm(3)};
  width: 100%;
  height: 100vh;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: ${({ isCollapsed }) => (isCollapsed ? 'flex-start' : 'flex-start')};
  justify-content: space-between;
`

export const ShelfArrowCircle = styled.div<ShelfProps>`
  align-items: center;
  background: ${({ theme }) => theme.color.white};
  border-radius: 50%;
  display: flex;
  height: ${({ theme }) => theme.rhythm(3)};
  justify-content: center;
  width: ${({ theme }) => theme.rhythm(3)};
  border: 0.1rem solid ${({ theme }) => theme.color.text};
  position: relative;

  ${({ isCollapsed }) =>
    !isCollapsed &&
    css`
      transform: scaleX(-1);
    `}
`

export const OpenNavContainer = styled.div`
  flex: 1;
  overflow-y: auto;
  margin: ${({ theme }) => theme.rhythm(5)} 0 ${({ theme }) => theme.rhythm(5)} 0;
`

export const Name = styled.div`
  align-self: center;
  white-space: nowrap;
`

export const LogoutButton = styled(Link)`
  width: 100%;

  &:hover {
    text-decoration: underline ${({ theme }) => theme.color.faGrey3};
  }
`

export const SearchButtonContainer = styled(Padding)`
  width: 100%;
`

export const ShelfButtonContainer = styled(Padding)`
  min-width: 100%;
`

export const ShelfButton = styled.button`
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  font-size: ${({ theme }) => theme.toRems(12)};
  color: ${({ theme }) => theme.color.faGrey5};
  padding: ${({ theme }) => theme.rhythm(0.75)} ${({ theme }) => theme.rhythm(1.25)};
  width: 100%;
  white-space: nowrap;
  background-color: ${({ theme }) => theme.color.white};
  border-radius: ${({ theme }) => theme.borderRadius};
  &:hover:enabled {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
`
