import { gql } from '@apollo/client'
import React from 'react'
import { Rating, Undefinable } from 'types'
import { TaglineRejectionReasonMap } from 'utils/constants'
import { TaglineRejectionReason } from 'utils/enums'
import Editor from 'components/Editor/Editor'
import JobHeading from 'components/JobHeading/JobHeading'
import StarRating from 'components/StarRating/StarRating'
import { TaglineSubmission } from './ReviewFeedback.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  taglineSubmission: TaglineSubmission
}

function ReviewFeedbackRight({ taglineSubmission }: Props): JSX.Element {
  const talentPartnerReview = taglineSubmission.talentPartnerReviews[0]
  const talentAgentReview = taglineSubmission.talentAgentReviews[0]

  const { taglineRequest } = taglineSubmission

  return (
    <>
      <Padding bottom={4}>
        <Txt bold size={20} lineHeight={1.3}>
          <JobHeading
            jobTitle={taglineRequest.jobTitle}
            company={taglineRequest.company ?? { name: taglineRequest.employerName }}
            huntrUrl={taglineRequest.huntrUrl}
          />
        </Txt>
        <Padding top={1}>
          {taglineRequest.jobUrl && (
            <ExternalLink url={taglineRequest.jobUrl} label="Job Description" />
          )}
        </Padding>
      </Padding>
      <VList size={4}>
        {/* TODO: handle possibilty of multiple items */}
        <Editor
          disabled
          defaultValue={taglineSubmission.items[0]?.copy}
          label="Writer Submission"
          height={160}
        />
        {/* TODO: handle possibilty of multiple items */}
        {talentPartnerReview &&
          (talentPartnerReview.taglineRejectionReason ? (
            <Txt color="error">
              Tagline was rejected by FR due to:&nbsp;
              {
                TaglineRejectionReasonMap[
                  talentPartnerReview.taglineRejectionReason as TaglineRejectionReason
                ]
              }
            </Txt>
          ) : (
            <Editor
              disabled
              defaultValue={talentPartnerReview.items[0]?.copy}
              label={`Final ${talentPartnerReview.items[0]?.name ?? ''}`}
              height={160}
            />
          ))}
        {talentAgentReview ? (
          <div>
            {talentAgentReview.taglineRejectionReason ? (
              <Txt color="error">
                Tagline was rejected by IR due to:&nbsp;
                {
                  TaglineRejectionReasonMap[
                    talentAgentReview.taglineRejectionReason as TaglineRejectionReason
                  ]
                }
              </Txt>
            ) : (
              <Editor
                disabled
                defaultValue={talentAgentReview.items[0]?.copy}
                label={`IR Edited ${talentAgentReview.items[0]?.name ?? ''}`}
                height={160}
              />
            )}
            <Padding top={4} bottom={1}>
              <TextArea
                disabled
                defaultValue={talentAgentReview.feedback}
                label={`IR Feedback for Writer (${talentAgentReview.createdBy.name})`}
              />
            </Padding>
            <Flex justify="flex-end">
              <StarRating
                iconSize={18}
                rating={talentAgentReview.rating as Rating}
                disabled
              />
            </Flex>
          </div>
        ) : (
          <Txt color="error">There is no Initial Review</Txt>
        )}
        {talentPartnerReview ? (
          <>
            <div>
              <Padding bottom={1}>
                <TextArea
                  disabled
                  defaultValue={talentPartnerReview.feedbackWriter ?? undefined}
                  label={`FR Feedback for Writer (${talentPartnerReview.createdBy.name})`}
                />
              </Padding>
              <Flex justify="flex-end">
                <StarRating
                  iconSize={18}
                  rating={talentPartnerReview.ratingWriter as Undefinable<Rating>}
                  disabled
                />
              </Flex>
            </div>
            {talentPartnerReview.feedbackTalentAgent &&
              talentPartnerReview.ratingTalentAgent && (
                <div>
                  <Padding bottom={1}>
                    <TextArea
                      disabled
                      defaultValue={talentPartnerReview.feedbackTalentAgent}
                      label={`FR Feedback for IR (${talentPartnerReview.createdBy.name})`}
                    />
                  </Padding>
                  <Flex justify="flex-end">
                    <StarRating
                      iconSize={18}
                      rating={talentPartnerReview.ratingTalentAgent as Rating}
                      disabled
                    />
                  </Flex>
                </div>
              )}
          </>
        ) : (
          <Txt color="error">There is no Talent Partner Review</Txt>
        )}
      </VList>
    </>
  )
}

ReviewFeedbackRight.fragments = {
  taglineSubmissionInfo: gql`
    fragment ReviewFeedbackRightInfo on TaglineSubmission {
      items {
        id
        name
        copy
      }
      taglineRequest {
        id
        huntrUrl
        jobTitle
        employerName
        jobUrl
        ...JobHeadingInfo
      }
      talentPartnerReviews {
        id
        feedbackWriter
        ratingWriter
        feedbackTalentAgent
        ratingTalentAgent
        taglineRejectionReason
        items {
          id
          name
          copy
        }
        createdBy {
          name
        }
      }
      talentAgentReviews {
        feedback
        rating
        taglineRejectionReason
        items {
          name
          copy
        }
        createdBy {
          name
        }
      }
    }
    ${JobHeading.fragments.jobHeadingInfo}
  `,
}

export default ReviewFeedbackRight
