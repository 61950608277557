import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import getNodes from 'utils/helpers/getNodes'
import { sortByDatetime, sortByProp } from 'utils/sort'
import { StageChangeType } from 'components/DashboardCard/DashboardCard.constants'
import { StageChangeInfo } from 'components/DashboardCard/DashboardCard.types'
import { ClientInterviewsType } from './ClientDashboardCard.constants'
import {
  Client,
  InterviewInfo,
  ClientStageChanges,
  WorkStats,
} from './ClientDashboardCard.types'
import { GetClientInProgressWorkQuery } from 'generated/graphql'

export function getInProgressWorkStats(
  workData: GetClientInProgressWorkQuery
): WorkStats | undefined {
  const { client } = workData
  if (!client) {
    return
  }
  const sourcingRequests = client.sourcingRequests.totalCount
  const sourcedJobsReview = client.sourcedJobs.totalCount
  const taglinesRequested = client.unassigned.totalCount
  const taglinesDrafting =
    client.pendingWriting.totalCount + client.pendingAgentReview.totalCount
  const taglinesReviewed = client.pendingPartnerReview.totalCount
  const applicationsAppliedTo = client.applications.totalCount
  return {
    sourcingRequests,
    sourcedJobsReview,
    taglinesRequested,
    taglinesDrafting,
    taglinesReviewed,
    applicationsAppliedTo,
  }
}

getInProgressWorkStats.fragments = {
  inProgressWorkStats: gql`
    fragment InProgressWorkStats on Client {
      sourcedJobs(isReviewed: false) {
        totalCount
      }
      sourcingRequests(isOpen: true) {
        totalCount
      }
      unassigned: taglineRequests(status: UNASSIGNED) {
        totalCount
      }
      pendingWriting: taglineRequests(status: PENDING_WRITING) {
        totalCount
      }
      pendingAgentReview: taglineRequests(status: PENDING_TA_REVIEW) {
        totalCount
      }
      pendingPartnerReview: taglineRequests(status: PENDING_TP_REVIEW) {
        totalCount
      }
      applications(hasApplicationSubmissionSubmission: false) {
        totalCount
      }
    }
  `,
}

export function getClientInterviews(
  client: Client,
  interviewsType: ClientInterviewsType
): InterviewInfo[] {
  const clientInterviews: InterviewInfo[] = []
  const interviewCycles = getNodes(client.interviewCycles)
  interviewCycles.forEach((interviewCycle) => {
    const interviews = getNodes(interviewCycle.interviews)
    interviews.forEach((interview) => {
      if (!interview.scheduledFor) {
        return
      }
      if (
        interviewsType === ClientInterviewsType.UPCOMING
          ? dayjs().isBefore(interview.scheduledFor.datetime)
          : dayjs().isAfter(interview.scheduledFor.datetime)
      ) {
        clientInterviews.push({
          id: interview.id,
          interviewCycleId: interviewCycle.id,
          scheduledFor: interview.scheduledFor,
          clientId: client.id,
          clientName: client.name,
          clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
          companyId: interviewCycle.company?.id,
          companyName: interviewCycle.company?.name,
          companyLogo: interviewCycle.company?.logoUrl,
          companyDomain: interviewCycle.company?.domain,
          jobTitle: interviewCycle.jobTitle,
          jobUrl: interviewCycle.jobUrl,
        })
      }
    })
  })
  return clientInterviews.sort((a, b) =>
    sortByDatetime(a.scheduledFor.datetime, b.scheduledFor.datetime)
  )
}

getClientInterviews.fragments = {
  clientInterviews: gql`
    fragment ClientInterviews on Client {
      id
      name
      headshotFiles {
        url
      }
      interviewCycles(active: true) {
        edges {
          node {
            id
            company {
              id
              name
              logoUrl
              domain
            }
            jobTitle
            jobUrl
            interviews {
              edges {
                node {
                  id
                  scheduledFor {
                    datetime
                    timezone
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}

export function getClientStageChanges(
  client: ClientStageChanges
): StageChangeInfo[] {
  const stageChanges: StageChangeInfo[] = []
  const interviewCycles = getNodes(client.interviewCycles)
  interviewCycles.forEach((interviewCycle) => {
    const clientInfo = {
      clientId: client.id,
      clientName: client.name,
      clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
      companyId: interviewCycle.company?.id,
      companyName: interviewCycle.company?.name,
      companyLogo: interviewCycle.company?.logoUrl,
      companyDomain: interviewCycle.company?.domain,
      jobTitle: interviewCycle.jobTitle,
      jobUrl: interviewCycle.jobUrl,
    }
    const interviews = getNodes(interviewCycle.interviews)
    if (interviewCycle.inactiveAt) {
      stageChanges.push({
        id: interviewCycle.id,
        interviewCycleId: interviewCycle.id,
        type: StageChangeType.INTERVIEW_CYCLE_REJECTED,
        dateFromNow: dayjs(interviewCycle.inactiveAt).fromNow(),
        dateToSortBy: dayjs(interviewCycle.inactiveAt),
        ...clientInfo,
      })
    } else {
      stageChanges.push({
        id: interviewCycle.id,
        interviewCycleId: interviewCycle.id,
        type: StageChangeType.NEW_INTERVIEW_CYCLE,
        dateFromNow: dayjs(interviewCycle.createdAt).fromNow(),
        dateToSortBy: dayjs(interviewCycle.createdAt),
        ...clientInfo,
      })
      interviews.forEach((interview) => {
        stageChanges.push({
          id: interview.id,
          interviewCycleId: interviewCycle.id,
          type: StageChangeType.NEW_INTERVIEW,
          dateFromNow: dayjs(interview.createdAt).fromNow(),
          dateToSortBy: dayjs(interview.createdAt),
          ...clientInfo,
        })
      })
    }
  })
  return stageChanges.sort((a, b) => sortByProp(b, a, 'dateToSortBy'))
}

getClientStageChanges.fragments = {
  clientStageChanges: gql`
    fragment ClientStageChanges on Client {
      id
      name
      headshotFiles {
        url
      }
      interviewCycles {
        edges {
          node {
            id
            company {
              id
              name
              logoUrl
              domain
            }
            jobTitle
            jobUrl
            createdAt
            inactiveAt
            interviews {
              edges {
                node {
                  id
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  `,
}
