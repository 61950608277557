import { gql, useMutation } from '@apollo/client'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { CACHE_STRING } from 'utils/cacheString'
import { QS } from 'utils/constants'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { getNextRouteUrl } from './ReviewFeedback.helpers'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import {
  AcknowledgeWriterFeedbackMutation,
  AcknowledgeWriterFeedbackMutationVariables,
} from 'generated/graphql'

type Props = {
  queue: string[]
  isReadOnly?: boolean
  submissionId: string
  writerId: string
}

const ReviewFeedbackFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

export default function ReviewFeedbackFooter({
  queue,
  isReadOnly = false,
  submissionId,
  writerId,
}: Props): JSX.Element {
  const queryString = useQueryString()
  const okayRoute = queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, submissionId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, writerId)

  const [acknowledgeFeedback, { data, error }] = useMutation<
    AcknowledgeWriterFeedbackMutation,
    AcknowledgeWriterFeedbackMutationVariables
  >(WRITER_ACKNOWLEDGE_FEEDBACK, {
    update(cache) {
      removeItemFromCacheConnections(cache, submissionId, [
        CACHE_STRING.FEEDBACK_QUEUE(writerId),
      ])
    },
  })

  useEffect(() => {
    if (data) {
      // TODO: (matthewalbrecht) use actual data here
      toast.success(`Acknowledged Feedback`)
      if (!nextRequestId) {
        toast('End of queue')
      }
      history.replace(nextRouteUrl)
    }
    if (error) {
      toast.error('There was an error acknowledging feedback')
    }
  }, [data, error, history, nextRouteUrl, nextRequestId])

  function handleSkipClick() {
    if (!nextRequestId) {
      toast('End of queue')
    }

    history.push(nextRouteUrl)
  }

  function handleSubmit() {
    if (!submissionId) {
      toast.error(`Can't find tagline submission`)
    }

    void acknowledgeFeedback({
      variables: {
        AcknowledgeWriterFeedbackInput: { taglineSubmissionId: submissionId },
      },
    })
  }

  return (
    <ReviewFeedbackFooterBox vertical={2} horizontal={5}>
      {isReadOnly ? (
        <Button as={Link} to={okayRoute} $type="secondary">
          Okay
        </Button>
      ) : (
        <Flex justify="space-between" align="center">
          <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
            Cancel
          </Button>
          <HList size={2}>
            <Button onClick={handleSkipClick} $type="secondary">
              Skip
            </Button>
            <Button $type="accept" onClick={handleSubmit}>
              Got it
            </Button>
          </HList>
        </Flex>
      )}
    </ReviewFeedbackFooterBox>
  )
}

/**
 * Used by writers to acknowledge feedback on written taglines
 */
const WRITER_ACKNOWLEDGE_FEEDBACK = gql`
  mutation AcknowledgeWriterFeedback(
    $AcknowledgeWriterFeedbackInput: AcknowledgeWriterFeedbackInput!
  ) {
    acknowledgeWriterFeedback(input: $AcknowledgeWriterFeedbackInput) {
      writerFeedbackAcknowledgement {
        id
      }
    }
  }
`
