import { gql, useQuery } from '@apollo/client'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import { getTrialClients } from './DashboardCard.helpers'
import { DashboardCard, DashboardContent } from './DashboardCard.styles'
import DashboardCardTitle from './DashboardCardTitle'
import DashboardTrialClientCard from './DashboardTrialClientCard'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import { GetClientTrialClientsQuery } from 'generated/graphql'

type Props = {
  talentAgentId: string
}

export default function DashboardCardTrialClients({
  talentAgentId,
}: Props): React.ReactElement {
  const { data, loading } = useQuery<GetClientTrialClientsQuery>(
    GET_CLIENT_TRIAL_CLIENTS,
    {
      variables: { talentAgentId },
    }
  )
  const clients = data?.clients && getNodes(data.clients)
  const trialClients = clients && clients.length ? getTrialClients(clients) : []
  return (
    <DashboardCard>
      <DashboardCardTitle title="Trial clients" />
      <DashboardContent>
        {loading && <LoadingIndicator />}
        {!trialClients.length && !loading && (
          <Padding all={2}>
            <Txt>There are no trial clients.</Txt>
          </Padding>
        )}
        {trialClients.map((trialClientInfo) => (
          <DashboardTrialClientCard
            key={trialClientInfo.id}
            trialClientInfo={trialClientInfo}
          />
        ))}
      </DashboardContent>
      <Padding vertical={0.5} />
    </DashboardCard>
  )
}

const GET_CLIENT_TRIAL_CLIENTS = gql`
  query GetClientTrialClients($talentAgentId: ID!) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          ...TrialClients
        }
      }
    }
  }
  ${getTrialClients.fragments.trialClients}
`
