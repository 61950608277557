import { useLocation } from 'react-router'

type SearchParams = { [key: string]: string }

export function createSearchParams(data: SearchParams): string {
  return new URLSearchParams(data).toString()
}

export function useQueryString(): URLSearchParams {
  const { search } = useLocation()

  return new URLSearchParams(search)
}
