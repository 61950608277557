import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Redirect, useHistory, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import useSourcerQueue from 'hooks/useSourcerQueue'
import { GLOBAL_COPY, INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import TimeTracker from 'components/TimeTracker/TimeTracker'
import { DEFAULT_STATE } from './SourcingSubmission.constants'
import { Params, State } from './SourcingSubmission.types'
import SourcingSubmissionFooter from './SourcingSubmissionFooter'
import SourcingSubmissionLeft from './SourcingSubmissionLeft'
import SourcingSubmissionRight from './SourcingSubmissionRight'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import {
  GetSourcingRequestQuery,
  GetSourcingRequestQueryVariables,
} from 'generated/graphql'

export default function SourcingSubmission(): JSX.Element {
  const history = useHistory()
  const { freeAgentId, sourcerId, requestId } = useParams<Params>()
  const redirectToInitial = requestId === INITIAL
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const [timeSpentSec, setTimeSpentSec] = useState<number>(0)
  const {
    queueByViewer,
    queueByClient,
    loading: queueLoading,
  } = useSourcerQueue(undefined, sourcerId)
  const { data, loading, error } = useQuery<
    GetSourcingRequestQuery,
    GetSourcingRequestQueryVariables
  >(GET_SOURCING_REQUEST, {
    variables: {
      id: requestId,
    },
    skip: redirectToInitial,
  })

  const sourcingRequest = data?.sourcingRequest
  const queue = useMemo(
    () => (freeAgentId ? queueByClient[freeAgentId] || [] : queueByViewer),
    [freeAgentId, queueByClient, queueByViewer]
  )

  const leftContent = useMemo(
    () =>
      sourcingRequest && (
        <SourcingSubmissionLeft sourcingRequest={sourcingRequest} />
      ),
    [sourcingRequest]
  )
  const rightContent = useMemo(
    () =>
      sourcingRequest && (
        <SourcingSubmissionRight
          sourcingRequest={sourcingRequest}
          state={state}
          setState={setState}
        />
      ),
    [sourcingRequest, state, setState]
  )

  useEffect(() => {
    if (!queueLoading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, queue, queueLoading])

  if (redirectToInitial && queue[0]) {
    const queueType = sourcerId ? 'sourcer' : 'free-agent'
    const typeId = sourcerId ? sourcerId : freeAgentId

    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.SOURCING_ASSIGNMENT_QUEUES, {
          requestId: queue[0],
          typeId: typeId,
          type: queueType,
        })}
      />
    )
  }

  if (loading) {
    return <Message vertical message="Loading sourcing request" />
  }

  if (error) {
    return (
      <Message vertical message="There was an error loading the sourcing request" />
    )
  }

  if (!sourcingRequest) {
    return <Message vertical message="Could not find the sourcing request" />
  }

  return (
    <DualScrollContainer key={requestId}>
      <Container noMax>
        <Padding top={2} bottom={2}>
          <Flex justify="flex-end" align="center">
            <Flex align="center">
              <Padding left={3}>
                <TimeTracker onValueChange={setTimeSpentSec} />
              </Padding>
            </Flex>
          </Flex>
        </Padding>
      </Container>
      <DualScroll leftContent={leftContent} rightContent={rightContent} />
      <SourcingSubmissionFooter
        sourcingRequest={sourcingRequest}
        state={state}
        setState={setState}
        timeSpentSec={timeSpentSec}
        setTimeSpentSec={setTimeSpentSec}
        queue={queue}
        requestId={requestId}
        freeAgentId={freeAgentId}
        sourcerId={sourcerId}
      />
    </DualScrollContainer>
  )
}

SourcingSubmission.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.SOURCER_QUEUE}
    key={ROUTE_PATHS.SOURCER_QUEUE}
  >
    <SourcingSubmission />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.SOURCER_QUEUE_BY_FREE_AGENT}
    key={ROUTE_PATHS.SOURCER_QUEUE_BY_FREE_AGENT}
  >
    <SourcingSubmission />
  </PrivateRoute>,
]

const GET_SOURCING_REQUEST = gql`
  query GetSourcingRequest($id: ID!) {
    sourcingRequest(id: $id) {
      id
      ...SourcingSubmissionLeftInfo
      ...SourcingSubmissionRightInfo
    }
  }

  # fragments aren't playing nice with the @client directive
  ${SourcingSubmissionLeft.fragments.sourcingRequestInfo}
  ${SourcingSubmissionRight.fragments.sourcingRequestInfo}
`
