import React from 'react'
import Padding from './Padding'
import Txt from './Txt'

type Props = {
  description: string
}

export default function InputDescription({
  description,
}: Props): React.ReactElement {
  return (
    <Padding bottom={1.5}>
      <Txt as="span" italic size={14} lineHeight={1.3} color="faGrey4">
        {description}
      </Txt>
    </Padding>
  )
}
