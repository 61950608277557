import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { KeyValue, MaybeConnectionNode, Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { SortDateOptions } from 'utils/enums'
import { maybeGetNodes } from 'utils/helpers/getNodes'
import { buildSortedQueueByDate } from 'utils/helpers/queueSorting'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { sortByProp } from 'utils/sort'
import { WriterQueueQuery, WriterQueueQueryVariables } from 'generated/graphql'

type QueueReturn = {
  queueByViewer: string[]
  loading: boolean
  queueByClient: KeyValue<string[]>
}

type CountReturn = {
  loading: boolean
  totalCount: Undefinable<number>
}

type TaglineRequest = MaybeConnectionNode<
  NonNullable<WriterQueueQuery['taglineRequests']>
>

type Group = {
  id: string
  taglineRequests: TaglineRequest[]
}
type GroupedTaglineRequests = KeyValue<Group>

export default function useWriterQueue(
  queryOptions: QueryHookOptions<WriterQueueQuery, WriterQueueQueryVariables> = {}
): QueueReturn {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<WriterQueueQuery, WriterQueueQueryVariables>(
    GET_WRITER_QUEUE,
    {
      ...queryOptions,
      variables: {
        assigneeId,
        onlyCount: false,
      },
    }
  )

  const taglineRequests = data?.taglineRequests
    ? maybeGetNodes(data.taglineRequests)
    : []

  // map client ids to array of tagline requests
  const groupedClients =
    taglineRequests?.reduce((clientMap: GroupedTaglineRequests, taglineRequest) => {
      const groupId = taglineRequest.client.id
      const entry = clientMap[groupId]
      if (entry) {
        entry.taglineRequests.push(taglineRequest)
      } else {
        clientMap[taglineRequest.client.id] = {
          id: groupId,
          taglineRequests: [taglineRequest],
        }
      }
      return clientMap
    }, {}) || {}

  const clients = Object.values(groupedClients)

  /**
   * Group the raw tagline requests by [client-dueDate]
   * This returns an object {[groupId]: [array af tagline requests]}
   */
  const groupedTaglineRequests = taglineRequests
    ? taglineRequests
        .map((taglineRequest) => ({
          ...taglineRequest,
          createdAtDayjs: dayjs(taglineRequest.createdAt),
          dueDayjs: dayjs(taglineRequest.due),
        }))
        .flat()
        .reduce((groups: KeyValue<TaglineRequest[]>, request) => {
          const groupValue = `${request.client.id}:${request.due}`
          const entry = groups[groupValue]
          if (entry) {
            entry.push(request)
          } else {
            groups[groupValue] = [request]
          }
          return groups
        }, {})
    : {}

  const sortDate = SortDateOptions.FROM_DUE
  /* TODO (matthewalbrecht): build sorted queue by date should use generics so we recieve accurate return type */
  const sortedTaglinesByClient = buildSortedQueueByDate(clients, sortDate)

  /**
   * map: sort each groups tagline requests by createdAt
   * sort: sort the groups by first element's [due -> createdAt]
   * flat: flatten groups into array of depth 1
   * map: reduce to array of ids fro the queue
   */
  const queueByViewer = Object.values(groupedTaglineRequests)
    .map((requests) => requests.sort((a, b) => sortByProp(a, b, 'createdAtDayjs')))
    .sort((a, b) => sortByProp(a[0], b[0], 'dueDayjs', 'createdAtDayjs'))
    .flat()
    .map(reduceObjectToId)

  // Build object with {[clientId]: [...[ids of tagline requests in their queue]]}
  const queueByClient = sortedTaglinesByClient.reduce(
    (finalObject, clientRequests) => {
      /* TODO (matthewalbrecht): refactor buildSortedQueueByDate */
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
      const clientId: string | undefined = clientRequests[0]?.client?.id
      if (!clientId) {
        return finalObject
      }

      return {
        ...finalObject,
        [clientId]: clientRequests.map(reduceObjectToId),
      }
    },
    {}
  )

  return { queueByViewer, queueByClient, loading }
}

export function useWriterQueueCount(
  queryOptions: QueryHookOptions<WriterQueueQuery, WriterQueueQueryVariables> = {}
): CountReturn {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<WriterQueueQuery, WriterQueueQueryVariables>(
    GET_WRITER_QUEUE,
    {
      ...queryOptions,
      variables: {
        assigneeId,
        onlyCount: true,
      },
    }
  )

  return { loading, totalCount: data?.taglineRequests?.totalCount ?? undefined }
}

/**
 * used by writers to queue through all of their assigned requests
 */
const GET_WRITER_QUEUE = gql`
  query WriterQueue($assigneeId: ID!, $onlyCount: Boolean!) {
    taglineRequests(assigneeId: $assigneeId, status: PENDING_WRITING) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          due
          status
          createdAt
          client {
            id
          }
        }
      }
    }
  }
`
