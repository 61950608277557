import React from 'react'
import { BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import { SourcingRequest } from './AssignSourcing.types'
import Txt from 'components/Txt'

type Props = {
  item: SourcingRequest
}

export default function AssignSourcingRowItems({ item }: Props): React.ReactElement {
  return (
    <BodyData>
      <Txt size={14}>{item.numJobs}</Txt>
    </BodyData>
  )
}
