import React, { useState } from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import AddAndEditExpert from 'views/ExpertNetwork/AddAndEditExpert'
import Checkbox from 'components/Inputs/Checkbox'
import ExpertRequestsTable from './ExpertRequestsTable'
import Button from 'components/Button'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function ExpertRequests(): React.ReactElement {
  const [showInactive, setShowInactive] = useState(false)

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Flex justify="space-between" align="center">
            <Txt size={24} bold as="h2">
              Expert Requests
            </Txt>
            <NewExpertButton />
          </Flex>
        </Padding>
        <Padding bottom={4}>
          <Checkbox
            name="showInactive"
            option={{ label: 'Show Inactive', value: 'showInactive' }}
            checked={showInactive}
            onValueChange={(checked) => setShowInactive(checked)}
          />
        </Padding>
      </Container>
      <ExpertRequestsTable showInactive={showInactive} />
    </ViewBox>
  )
}

ExpertRequests.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EXPERT_REQUESTS}
    key={ROUTE_PATHS.EXPERT_REQUESTS}
  >
    <ExpertRequests />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.EXPERT_REQUEST_DETAIL}
    key={ROUTE_PATHS.EXPERT_REQUEST_DETAIL}
  >
    <ExpertRequests />
  </PrivateRoute>,
]

function NewExpertButton(): React.ReactElement {
  const [isOpen, setisOpen] = useState(false)

  function handleClose() {
    setisOpen(false)
  }

  return (
    <>
      <Button $type="highlight" onClick={() => setisOpen(true)}>
        New Expert
      </Button>
      <AddAndEditExpert handleClose={handleClose} isOpen={isOpen} />
    </>
  )
}
