import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import styled from 'styled-components/macro'
import { getTimeFromNow } from 'utils/helpers/time'
import { Activity } from './ActivityLogTable.types'
import Txt from 'components/Txt'

const Time = styled(Txt)`
  display: inline-block;
  width: ${({ theme }) => theme.toRems(120)};
`

const ActivityDescription = styled(Txt)`
  display: inline-block;
  padding-left: ${({ theme }) => theme.rhythm(1)};
`

type Props = {
  activity: Activity
}

function ActivityItem({ activity }: Props): JSX.Element {
  const timeFromNow = getTimeFromNow(dayjs(activity.createdAt))

  return (
    <div>
      <Time as="span" size={14} color="faGrey4">
        {timeFromNow}
      </Time>
      <ActivityDescription as="span" size={14}>
        {activity.message}
      </ActivityDescription>
    </div>
  )
}

ActivityItem.fragments = {
  activityItemInfo: gql`
    fragment ActivityItemInfo on ActivityLog {
      id
      message
      createdAt
    }
  `,
}

export default ActivityItem
