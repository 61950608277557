import { KeyValue, Rating } from 'types'
import { TaglineRequestPartnerReviewQuery } from 'generated/graphql'

export type FormState = {
  ratingTalentAgent: Rating
  feedbackTalentAgent: string
  ratingWriter: Rating
  feedbackWriter: string
  editorChanges: KeyValue
}

export type Params = {
  requestId: string
  talentPartnerId: string
  freeAgentId: string
  podId: string
}

export type TaglineRequest = NonNullable<
  TaglineRequestPartnerReviewQuery['taglineRequest']
>

export enum QueueType {
  Pod,
  Viewer,
  FreeAgent,
}

export type QueueInfo = {
  queue: string[]
  initialPath: string | undefined
  nextRouteUrl: string | null
}
