import { SHOW_TALENT_AGENT_REVIEW } from 'utils/settings'

const reusedColumns = SHOW_TALENT_AGENT_REVIEW
  ? [
      { id: 'avgTalentAgentRating', name: 'AVG IR Rating' },
      { id: 'talentAgentOnesAndTwosRate', name: 'IR 1&2s Rate' },
      { id: 'avgTalentPartnerRating', name: 'AVG FR Rating' },
      { id: 'talentPartnerOnesAndTwosRate', name: 'FR 1&2s Rate' },
      { id: 'avgTimeSpentSec', name: 'AVG Time Spent' },
      { id: 'rejectionRate', name: 'Rejection Rate' },
    ]
  : [
      { id: 'avgTalentPartnerRating', name: 'AVG Rating' },
      { id: 'talentPartnerOnesAndTwosRate', name: '1&2s Rate' },
      { id: 'avgTimeSpentSec', name: 'AVG Time Spent' },
      { id: 'rejectionRate', name: 'Rejection Rate' },
    ]

export const TALENT_AGENT_COLUMNS = [
  { id: 'talentAgent', name: 'Talent Agent' },
  { id: 'writer', name: 'Writer' },
  ...reusedColumns,
]

export const WRITER_COLUMNS = [{ id: 'writer', name: 'Writer' }, ...reusedColumns]

export const CLIENT_COLUMNS = [
  { id: 'freeAgent', name: 'Free Agent' },
  { id: 'writer', name: 'Writer' },
  ...reusedColumns,
]

export const GROUP_BY_OPTIONS = [
  {
    value: 'writerId',
    label: 'Writer',
  },
  {
    value: 'clientId',
    label: 'Free Agent',
  },
  {
    value: 'talentAgentId',
    label: 'Talent Agent',
  },
]

export enum FILTER_TYPES {
  GROUP_BY = 'groupBy',
  CLIENT_ID = 'clientId',
  WRITER_ID = 'writerId',
  FROM_DATE = 'fromDate',
  TALENT_AGENT_ID = 'talentAgentId',
}

export enum GROUP_BY_TYPES {
  CLIENT_ID = 'clientId',
  WRITER_ID = 'writerId',
  TALENT_AGENT_ID = 'talentAgentId',
}
