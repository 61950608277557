import { gql, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import { Template } from 'types'
import { ERRORS } from 'utils/constants'
import EditableLabel from 'components/EditableLabel/EditableLabel'
import EditorLabelWithAction from 'components/EditorLabelWithAction/EditorLabelWithAction'
import { COPY } from './ClientTemplates.constants'
import {
  DeleteTemplateMutation,
  DeleteTemplateMutationVariables,
  RenameTemplateMutation,
  RenameTemplateMutationVariables,
} from 'generated/graphql'

type Props = {
  template: {
    id: string
    name: string
    copy: string
  }
  sequence: {
    id: string
    name?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  onDelete?(): void
}

export default function ClientTemplatesTemplateLabel({
  template,
  sequence,
  onDelete,
}: Props): JSX.Element {
  const [templateName, setTemplateName] = useState(template.name)
  const [renameTemplate, { error: renameError }] = useMutation<
    RenameTemplateMutation,
    RenameTemplateMutationVariables
  >(RENAME_TEMPLATE, {
    errorPolicy: 'all',
  })
  // TODO: (matthewalbrecht) investigate why data property is undefined on success of mutation
  const [deleteTemplate, { error: deleteError }] = useMutation<
    DeleteTemplateMutation,
    DeleteTemplateMutationVariables
  >(DELETE_TEMPLATE, {
    errorPolicy: 'all',
    onCompleted({ deleteTemplate }) {
      if (deleteTemplate) {
        toast.success(
          `Successfully deleted template "${template.name}" on "${
            sequence.name ?? ''
          }"`
        )
        onDelete?.()
      }
    },
    update(cache, { data }) {
      if (data?.deleteTemplate) {
        // If we find no errors we know remove the template from the cache
        cache.modify({
          id: cache.identify({ id: sequence.id, __typename: 'TemplateSequence' }),
          fields: {
            templates(templates: Template[], { readField }) {
              const newTemplates = templates.filter(
                (templateElement: { id: string }) =>
                  readField('id', templateElement) !== template.id
              )
              return newTemplates
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (deleteError?.message.includes(ERRORS.FOREIGN_KEY_VIOLATION)) {
      toast.error(
        'This template is being used in Tagline Requests, it cannot be deleted'
      )
    } else if (deleteError) {
      toast.error('There was an error deleting the template')
    }
  }, [deleteError])

  useEffect(() => {
    if (renameError?.message.includes(ERRORS.UNIQUE_VIOLATION)) {
      toast.error('Template names must be unique')
    } else if (renameError) {
      toast.error('There was an error renaming the template')
    }
  }, [renameError])

  function handleRenameSubmit(
    event: React.FormEvent<HTMLFormElement>,
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    event.preventDefault()
    // mutation for creating new template
    void renameTemplate({
      variables: {
        RenameTemplateInput: {
          id: template.id,
          /* TODO (matthewalbrecht): make this state not formData */
          name: templateName,
        },
      },
    })
    // This will flip what is rendered by this component back to a button
    setIsEditing(false)
    // resets the inputs value to empty
    event.currentTarget.reset()
  }

  function handleDeleteClick() {
    if (window.confirm(COPY.CONFIRM_DELETE)) {
      void deleteTemplate({
        variables: {
          DeleteTemplateInput: {
            id: template.id,
          },
        },
      })
    }
  }

  return (
    <EditorLabelWithAction
      onActionClick={handleDeleteClick}
      ActionIconName="Trash"
      label={
        <EditableLabel
          label={`Edit ${template.name}`}
          onSubmit={handleRenameSubmit}
          value={templateName}
          onValueChange={(value) => setTemplateName(value)}
        />
      }
    />
  )
}

const DELETE_TEMPLATE = gql`
  mutation DeleteTemplate($DeleteTemplateInput: DeleteTemplateInput!) {
    deleteTemplate(input: $DeleteTemplateInput) {
      success
    }
  }
`

const RENAME_TEMPLATE = gql`
  mutation RenameTemplate($RenameTemplateInput: RenameTemplateInput!) {
    renameTemplate(input: $RenameTemplateInput) {
      template {
        id
        name
      }
    }
  }
`
