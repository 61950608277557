import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { DATE_FORMAT, INTERVIEW_CYCLE_SOURCE_MAP, UNKNOWN } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { getInterviewCycleStatusCopy } from 'utils/helpers/interviewCycleStatus'
import { sortByProp } from 'utils/sort'
import ClientInterviewCycleExpandButton from 'views/ClientInterviewCycles/ClientInterviewCycleExpandButton'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { CompanyInterviewCycleColumns } from '../CompanyDetail.constants'
import { Company, InterviewCycle } from '../CompanyDetail.types'
import Button from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'
import {
  GetCompanyInterviewCyclesQuery,
  GetCompanyInterviewCyclesQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

const normalizeData = (
  data: NonNullable<GetCompanyInterviewCyclesQuery['company']>
): InterviewCycle[] => {
  return getNodes<InterviewCycle>(data.interviewCycles)
    .map((item) => ({ ...item, dateStartedDayjs: dayjs(item.startDate) }))
    .sort((a, b) => sortByProp(a, b, 'dateStartedDayjs'))
}

export default function CompanyInterviewCycles({
  company,
}: Props): React.ReactElement {
  const { data, error, loading } = useQuery<
    GetCompanyInterviewCyclesQuery,
    GetCompanyInterviewCyclesQueryVariables
  >(GET_COMPANY_INTERVIEW_CYCLES, {
    variables: { id: company.id },
  })

  const items = data?.company ? normalizeData(data.company) : null

  return (
    <FullWidthTable
      headers={Object.values(CompanyInterviewCycleColumns)}
      isError={Boolean(error)}
      isLoading={loading}
      isEmpty={Boolean(items && !items.length)}
      emptyMessage={`Could not find any interview cycles`}
      loadingMessage={`Loading interview cycles...`}
      errorMessage={`There was an error fetching the interview cycles`}
    >
      {items?.map((item) => (
        <CompanyDetailInterviewCycleRow key={item.id} item={item} />
      ))}
    </FullWidthTable>
  )
}

type InterviewCycleProps = { item: InterviewCycle }

function CompanyDetailInterviewCycleRow({
  item,
}: InterviewCycleProps): React.ReactElement {
  return (
    <BodyRow>
      <BodyData collapse>
        <ClientInterviewCycleExpandButton
          interviewCycleId={item.id}
          clientId={item.client.id}
        />
      </BodyData>
      <BodyData>
        <Txt size={14} as="span" noWrap>
          {item.client.name}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span" noWrap>
          {item.jobTitle ?? UNKNOWN.JOB_TITLE}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span" noWrap>
          {INTERVIEW_CYCLE_SOURCE_MAP[item.source]}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {dayjs(item.startDate).format(DATE_FORMAT.FRIENDLY_DATE_SHORT_MONTH)}
        </Txt>
      </BodyData>
      <BodyData>
        <ExternalLink url={item.huntrUrl}>
          <Button small as="span">
            Huntr Card
          </Button>
        </ExternalLink>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {getInterviewCycleStatusCopy(item)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_COMPANY_INTERVIEW_CYCLES = gql`
  query GetCompanyInterviewCycles($id: ID!) {
    company(id: $id) {
      id
      interviewCycles {
        edges {
          node {
            id
            client {
              id
              name
            }
            jobTitle
            source
            createdAt
            startDate
            huntrUrl
            ...InterviewCycleStatusCopy
          }
        }
      }
    }
  }
  ${getInterviewCycleStatusCopy.fragments.interviewCycleStatusCopy}
`
