import React from 'react'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { RowData } from './SourcerReview.types'
import Txt from 'components/Txt'

type Props = {
  assigneeRowData: RowData
}
export default function SourcerReviewHighlightedRow({
  assigneeRowData,
}: Props): React.ReactElement {
  return (
    <BodyRow color="blueHighlight">
      <BodyData>
        <Txt as="span" size={14}>
          {assigneeRowData.assigneeName}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {assigneeRowData.sourcingsRequested}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {assigneeRowData.sourcingsSubmitted}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {assigneeRowData.sourcingsApproved}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {assigneeRowData.sourcingsRejected}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
