import { ApolloCache, gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { CACHE_STRING } from 'utils/cacheString'
import { SourcedJobRejectionReason } from 'utils/enums'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import RejectionDropdownButton from 'components/RejectionButton/RejectionDropdownButton'
import { DateFilter, rejectionOptions, TOAST } from './RedactSourcedJobs.constants'
import { SourcedJob } from './RedactSourcedJobs.types'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'

type Props = {
  rowData: SourcedJob
  dateFilter: DateFilter
}

export default function RedactSourcedJobsRedactionRow({
  rowData,
  dateFilter,
}: Props): React.ReactElement {
  const [redactSourcedJob] = useMutation(REDACT_SOURCED_JOB, {
    onCompleted() {
      toast.success(TOAST.REDACT_SUCCESS)
    },
    onError() {
      toast.error(TOAST.REDACT_ERROR)
    },
    update: updateCacheAfterSuccess,
  })

  /**
   * this will remove the current request from the query in cache so that ui will properly update
   * @param cache Apollo's cache
   */
  function updateCacheAfterSuccess(cache: ApolloCache<unknown>) {
    const cacheString = CACHE_STRING.SOURCED_JOB_REDACTION({
      // we do this because order needs to be correct for the cacheString
      date: dateFilter.date,
      timezone: dateFilter.timezone,
    })
    cache.modify({
      fields: {
        [cacheString]: (queryResult, { readField }) =>
          removeCacheEdgeById(rowData.id, queryResult, readField),
      },
    })
  }

  /**
   * executes the redactSourcedJob mutation
   * @param redactionReason reason for user to redact sourced job
   */
  function handleRedaction(redactionReason: SourcedJobRejectionReason) {
    const variables = {
      RedactSourcedJobInput: {
        sourcedJobId: rowData.id,
        redactionReason,
      },
    }

    void redactSourcedJob({
      variables,
    })
  }

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.company.name}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.title}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          <ExternalLink label="Link" inheritStyles url={rowData.url} />
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.createdBy.name}
        </Txt>
      </BodyData>
      <BodyData collapse>
        <RejectionDropdownButton
          dropdownOptions={rejectionOptions}
          onSelection={handleRedaction}
          buttonChildren={
            <Icon name="Trash" height={16} width={16} primaryFill="text" />
          }
          position="left"
        />
      </BodyData>
    </BodyRow>
  )
}

const REDACT_SOURCED_JOB = gql`
  mutation RedactSourcedJob($RedactSourcedJobInput: RedactSourcedJobInput!) {
    redactSourcedJob(input: $RedactSourcedJobInput) {
      sourcedJobRedaction {
        id
      }
    }
  }
`
