import { generatePath } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'

type RouteValues = {
  applierId: string | undefined
  freeAgentId: string | undefined
}

/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextApplicationId: string | null,
  { applierId, freeAgentId }: RouteValues
): string {
  const typeId = applierId || freeAgentId
  if (nextApplicationId && typeId) {
    return generatePath(ROUTE_PATHS.APPLIER_ASSIGNMENT_QUEUES, {
      applicationId: nextApplicationId,
      typeId,
      type: applierId ? 'applier' : 'free-agent',
    })
  }
  return ROUTE_PATHS.HOME
}
