import { Maybe } from 'types'
import { UNKNOWN } from 'utils/constants'

type JobInfoObject = {
  jobTitle?: Maybe<string>
  employerName?: Maybe<string>
}
export default function getJobInfoLabel({
  jobTitle,
  employerName,
}: JobInfoObject): string {
  const title = jobTitle || UNKNOWN.JOB_TITLE
  const employer = employerName || UNKNOWN.EMPLOYER_NAME
  return `${title} @ ${employer}`
}
