import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import ClientJobHistory from 'views/ClientJobHistory/ClientJobHistory'
import {
  buildSourcedJobTimelineEvents,
  buildTaglineRequestJobTimelineEvents,
} from 'views/ClientJobHistory/ClientJobHistory.helpers'
import FullWidthItemList from 'components/FullWidthItemList/FullWidthItemList'
import { ClientJob } from './ClientJobs.types'
import ClientJobsRow from './ClientJobsRow'
import { BackgroundColor } from 'components/MiscStyles'
import Padding from 'components/Padding'
import {
  GetClientSourcedAndTaglineRequestJobsQuery,
  GetClientSourcedAndTaglineRequestJobsQueryVariables,
} from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientJobsList({ clientId }: Props): React.ReactElement {
  const { data, loading, error } = useQuery<
    GetClientSourcedAndTaglineRequestJobsQuery,
    GetClientSourcedAndTaglineRequestJobsQueryVariables
  >(GET_CLIENT_JOBS, {
    variables: {
      id: clientId,
    },
  })

  const sourcedJobs = data?.client?.sourcedJobs
    ? getNodes(data.client.sourcedJobs)
    : []

  const taglineRequests = data?.client?.taglineRequests
    ? getNodes(data.client.taglineRequests)
    : []

  const normalizedSourcedJobs = sourcedJobs.map((sourcedJob) => {
    const sourcedJobTimelineEvents = buildSourcedJobTimelineEvents(sourcedJob)
    return {
      id: sourcedJob.id,
      employerName: sourcedJob.company.name,
      createdAt: sourcedJob.createdAt,
      title: sourcedJob.title,
      recentTimelineEvents: sourcedJobTimelineEvents.slice(0, 3),
      recentDateToSortBy: dayjs(sourcedJobTimelineEvents[0]?.createdAt),
      companyLogoUrl: sourcedJob.company.logoUrl,
    }
  })

  const normalizedTaglineRequestJobs = taglineRequests
    .filter((taglineRequest) => !taglineRequest.sourcedJob)
    .map((taglineRequest) => {
      const taglineRequestJobTimeline =
        buildTaglineRequestJobTimelineEvents(taglineRequest)
      return {
        id: taglineRequest.id,
        employerName: taglineRequest.company?.name ?? taglineRequest.employerName,
        createdAt: taglineRequest.createdAt,
        title: taglineRequest.jobTitle,
        taglineRequestId: taglineRequest.id,
        recentTimelineEvents: taglineRequestJobTimeline.slice(0, 3),
        companyLogoUrl: taglineRequest.company?.logoUrl,
        // tagline request job timeline will always have a tagline requested event if tagline request exists and createdAt is non nullable
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        recentDateToSortBy: dayjs(taglineRequestJobTimeline[0]!.createdAt),
      }
    })

  const clientJobs = [
    ...normalizedSourcedJobs,
    ...normalizedTaglineRequestJobs,
  ].sort((a, b) => sortByProp(b, a, 'recentDateToSortBy'))

  return (
    <BackgroundColor color="faGrey1" style={{ minHeight: '100%' }}>
      <Padding vertical={5}>
        <FullWidthItemList
          spacing={3}
          isLoading={loading}
          isError={Boolean(error)}
          isEmpty={!loading && Boolean(data?.client) && !clientJobs.length}
          emptyMessage="Could not find any jobs."
          loadingMessage="Loading jobs..."
          errorMessage="There was an error fetching jobs."
        >
          {clientJobs.map((clientJob: ClientJob) => (
            <ClientJobsRow
              key={clientJob.id}
              clientId={clientId}
              clientJob={clientJob}
              taglineRequestId={clientJob.taglineRequestId}
            />
          ))}
        </FullWidthItemList>
      </Padding>
    </BackgroundColor>
  )
}

ClientJobsList.fragments = {
  clientJobsTableInfoApplication: gql`
    fragment ClientJobsTableInfoApplication on Application {
      id
      createdAt
      applicationSubmissions {
        id
        createdAt
        rejectionReason
      }
      missingApplicationInfos {
        id
        createdAt
        answeredAt
        declineReason
        applicationNew {
          id
        }
      }
      priorMissingApplicationInfos {
        id
        answeredAt
        declineReason
      }
      interviewCycles {
        id
        createdAt
        inactiveAt
        dossierRequests {
          edges {
            node {
              id
              createdAt
            }
          }
        }
        dossierSubmissions {
          edges {
            node {
              id
              createdAt
              rejectionReason
              fileUrl
            }
          }
        }
        interviews {
          edges {
            node {
              id
              createdAt
              debriefs {
                id
                createdAt
                rating
              }
            }
          }
        }
        interviewPrepRequests {
          edges {
            node {
              id
              createdAt
            }
          }
        }
        expertRequests {
          edges {
            node {
              id
              createdAt
            }
          }
        }
      }
    }
  `,
  clientJobsTableInfoEnrichmentRequests: gql`
    fragment ClientJobsTableInfoEnrichmentRequests on EnrichmentRequest {
      id
      createdAt
      enrichmentSubmissions {
        edges {
          node {
            id
            createdAt
            rejectionReason
          }
        }
      }
    }
  `,
  clientJobsTableInfoHuntrJobActions: gql`
    fragment ClientJobsTableInfoHuntrJobActions on HuntrJobAction {
      id
      jobActionType
      huntrAction {
        createdAt
      }
    }
  `,
}

const GET_CLIENT_JOBS = gql`
  query GetClientSourcedAndTaglineRequestJobs($id: ID!) {
    client(id: $id) {
      sourcedJobs {
        edges {
          node {
            id
            company: companyEntity {
              name
              logoUrl
            }
            createdAt
            title
            client {
              id
              name
            }
            reviews {
              id
              createdAt
              rating
              rejectionReason
            }
            huntrJobActions {
              edges {
                node {
                  ...ClientJobsTableInfoHuntrJobActions
                }
              }
            }
            taglineRequests {
              edges {
                node {
                  ...ClientJobHistoryInfoTaglineRequest
                }
              }
            }
            enrichmentRequests {
              edges {
                node {
                  ...ClientJobsTableInfoEnrichmentRequests
                }
              }
            }
            applications {
              edges {
                node {
                  ...ClientJobsTableInfoApplication
                }
              }
            }
            redactions {
              id
              createdAt
            }
          }
        }
      }
      taglineRequests {
        edges {
          node {
            id
            createdAt
            jobTitle
            employerName
            client {
              id
              name
            }
            company {
              name
              logoUrl
            }
            sourcedJob {
              id
            }
            applications {
              edges {
                node {
                  ...ClientJobsTableInfoApplication
                }
              }
            }
            enrichmentRequests {
              edges {
                node {
                  ...ClientJobsTableInfoEnrichmentRequests
                }
              }
            }
            huntrJobActions {
              edges {
                node {
                  ...ClientJobsTableInfoHuntrJobActions
                }
              }
            }
            ...ClientJobHistoryInfoTaglineRequest
          }
        }
      }
    }
  }
  ${ClientJobsList.fragments.clientJobsTableInfoApplication}
  ${ClientJobsList.fragments.clientJobsTableInfoEnrichmentRequests}
  ${ClientJobsList.fragments.clientJobsTableInfoHuntrJobActions}
  ${ClientJobHistory.fragments.clientJobHistoryInfoTaglineRequest}
`
