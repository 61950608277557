import { ApolloError, gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { ConnectionNode, Rating } from 'types'
import { DATE_FORMAT, EMPTY_INPUT } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyRow, BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import StarRating from 'components/StarRating/StarRating'
import { HEADERS } from './DossierFeedback.constants'
import ExternalLink from 'components/ExternalLink'
import { TextArea } from 'components/Inputs'
import Txt from 'components/Txt'
import { GetUserDossierFeedbackQuery } from 'generated/graphql'

type DossierSubmission = ConnectionNode<
  NonNullable<GetUserDossierFeedbackQuery['dossierSubmissions']>
>

type Props = {
  isEmpty: boolean
  dossierSubmissions: DossierSubmission[]
  loading: boolean
  error: ApolloError | undefined
}

export default function DossierFeedbackTable({
  isEmpty,
  dossierSubmissions,
  loading,
  error,
}: Props): React.ReactElement {
  return (
    <FullWidthTable
      headers={Object.values(HEADERS)}
      isLoading={loading}
      isError={Boolean(error)}
      isEmpty={isEmpty}
      emptyMessage="Could not find any clients."
      loadingMessage="Loading clients..."
      errorMessage="There was an error fetching clients."
    >
      {dossierSubmissions.map((dossierSubmission) => (
        <DossierSubmissionRow
          key={dossierSubmission.id}
          dossierSubmission={dossierSubmission}
        />
      ))}
    </FullWidthTable>
  )
}

type RowProps = {
  dossierSubmission: DossierSubmission
}

export function DossierSubmissionRow({
  dossierSubmission,
}: RowProps): React.ReactElement {
  const feedback = dossierSubmission.reviews[0]?.feedback
  const rating = dossierSubmission.reviews[0]?.rating as Rating

  return (
    <BodyRow>
      <BodyData>
        {dossierSubmission.fileUrl && (
          <ExternalLink
            url={dossierSubmission.fileUrl}
            label={getJobInfoLabel(dossierSubmission)}
            txtProps={{ size: 14 }}
          />
        )}
      </BodyData>
      <BodyData collapse>
        <TextArea defaultValue={feedback ?? EMPTY_INPUT} disabled minWidth={240} />
      </BodyData>
      <BodyData>
        <StarRating disabled iconSize={14} rating={rating} />
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {dayjs(dossierSubmission.createdAt).format(DATE_FORMAT.ONLY_DATE)}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

DossierSubmissionRow.fragments = {
  DossierSubmissionRowInfo: gql`
    fragment DossierSubmissionRowInfo on DossierSubmission {
      employerName
      jobTitle
      reviews {
        id
        rating
        feedback
      }
    }
  `,
}
