import dayjs from 'dayjs'
import { DATE_FORMAT } from 'utils/constants'
import getCreatedAtRangeFilter, {
  DatetimeTzRangeFilter,
} from 'utils/helpers/getCreatedAtRangeFilterTz'
import getNodes from 'utils/helpers/getNodes'
import parseRating from 'utils/helpers/parseRating'
import {
  PeopleFilter,
  RawSourcedJobRedaction,
  RawSourcedJobReview,
  SourcedJob,
  SourcedJobConnection,
  SourcedJobStatus,
} from './SourcingReview.types'

import { GetSourcingReviewQueryVariables } from 'generated/graphql'

export function buildTableData(
  connection: SourcedJobConnection,
  timezone: string
): SourcedJob[] {
  const rawSourcedJobs = getNodes(connection)

  return rawSourcedJobs.map((job) => {
    const review = job.reviews[0]
    const redaction = job.redactions[0]
    return {
      id: job.id,
      url: job.url,
      company: job.company.name,
      title: job.title,
      sourcerName: job.createdBy.name,
      clientName: job.sourcingRequest.client.name,
      status: getStatus(review, redaction),
      rating: parseRating(review?.rating),
      notes: review?.reviewNotes,
      rejectionReason: review?.rejectionReason,
      createdAt: dayjs(job.createdAt).tz(timezone).format(DATE_FORMAT.ONLY_DATE),
    }
  })
}

function getStatus(
  review: RawSourcedJobReview | undefined,
  redaction: RawSourcedJobRedaction | undefined
): SourcedJobStatus {
  if (review) {
    return review.rejectionReason ? 'Rejected' : 'Accepted'
  }
  if (redaction) {
    return 'Redacted'
  }
  return 'Unreviewed'
}

export function getQueryVariables(
  dateFilter: DatetimeTzRangeFilter,
  peopleFilter: PeopleFilter
): GetSourcingReviewQueryVariables {
  return {
    createdAtRangeFilter: getCreatedAtRangeFilter(dateFilter),
    talentAgentId: peopleFilter.talentAgentId || undefined,
    clientId: peopleFilter.clientId || undefined,
    createdById: peopleFilter.createdById || undefined,
  }
}
