import React from 'react'
import styled from 'styled-components/macro'
import { ColumnHeader } from 'types'
import Txt from 'components/Txt'

/* TODO (matthewalbrecht): make this grid styled component more robust */
type GridProps = {
  gridTemplateColumns: string
  rowGapSize: number
  colGapSize: number
}

export const Grid = styled.div<GridProps>`
  display: grid;
  grid-template-columns: ${({ gridTemplateColumns }) => gridTemplateColumns};
  grid-template-rows: 1fr;
  row-gap: ${({ theme, rowGapSize }) => theme.rhythm(rowGapSize)};
  column-gap: ${({ theme, colGapSize }) => theme.rhythm(colGapSize)};
  align-items: center;
`

type GridItemProps = {
  column: number
  span: number
}

export const GridItem = styled.div<GridItemProps>`
  grid-column: ${({ column, span }) => `${column} / span ${span}`};
`

type GridHeadersProps = {
  headers: ColumnHeader[]
}

export function GridHeaders({ headers }: GridHeadersProps): React.ReactElement {
  return (
    <>
      {headers.map(({ label, id }) =>
        label ? (
          <Txt size={13} bold key={id}>
            {label}
          </Txt>
        ) : (
          <div key={id} />
        )
      )}
    </>
  )
}
