import { GetInterviewPublicQuery, Timezone } from 'generated/graphql'

export type State = {
  datetime: string
  timezone: Timezone | null
}

export type Interview = NonNullable<GetInterviewPublicQuery['interviewByPublic']>

export type Params = {
  interviewPublicId: string
}

export enum Status {
  Open,
  Success,
  Declined,
}
