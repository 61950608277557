import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { Colors } from 'types'
import { ExpertRequestStatusMap } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  ExpertRequest,
  ExpertRequestConnection,
  ExpertRequestFull,
  FormData,
  RawExpertRequest,
} from './ExpertRequests.types'
import { ExpertRequestStatus, StatusInfoFragment } from 'generated/graphql'

export function getTableData(
  connection: ExpertRequestConnection,
  showInactive: boolean
): ExpertRequest[] {
  // get nodes
  const rawExpertRequests = getNodes<RawExpertRequest>(connection)

  // normalize
  let expertRequests = rawExpertRequests.map((expertRequest) => ({
    ...expertRequest,
    dueDayjs: dayjs(expertRequest.due),
    createdAtDayjs: dayjs(expertRequest.createdAt),
  }))

  // filter
  if (!showInactive) {
    expertRequests = expertRequests.filter((expertRequest) => {
      if (expertRequest.canceledAt) {
        return false
      }
      if (expertRequest.status === ExpertRequestStatus.PAYMENT_COMPLETE) {
        return false
      }
      return true
    })
  }

  // sort
  expertRequests.sort((a, b) => sortByProp(a, b, '-dueDayjs'))

  return expertRequests
}

export function getDefaultState(expertRequest: ExpertRequestFull): FormData {
  return {
    status: expertRequest.status,
    paymentAmount: expertRequest.paymentAmount,
    paymentType: expertRequest.paymentType,
    additionalPaymentDetails: expertRequest.additionalPaymentDetails,
    callLengthMinutes: expertRequest.callLengthMinutes,
    expertId: expertRequest.expert?.id,
  }
}

export function getStatusInfo(
  expertRequest: StatusInfoFragment
): [string, keyof Colors] {
  if (expertRequest.airtableId) {
    return ['In Airtable', 'faGrey2']
  }
  if (expertRequest.canceledAt) {
    return ['Canceled', 'faRed']
  }
  switch (expertRequest.status) {
    case ExpertRequestStatus.REQUESTED:
      return [ExpertRequestStatusMap[ExpertRequestStatus.REQUESTED], 'blueHighlight']
    case ExpertRequestStatus.MESSAGE_SENT:
      return [
        ExpertRequestStatusMap[ExpertRequestStatus.MESSAGE_SENT],
        'yellowLight',
      ]
    case ExpertRequestStatus.IN_TOUCH:
      return [ExpertRequestStatusMap[ExpertRequestStatus.IN_TOUCH], 'yellowLight']
    case ExpertRequestStatus.CALL_SCHEDULED:
      return [
        ExpertRequestStatusMap[ExpertRequestStatus.CALL_SCHEDULED],
        'faLightBlue',
      ]
    case ExpertRequestStatus.PAYMENT_NEEDED:
      return [
        ExpertRequestStatusMap[ExpertRequestStatus.PAYMENT_NEEDED],
        'yellowDark',
      ]
    case ExpertRequestStatus.PAYMENT_COMPLETE:
      return [
        ExpertRequestStatusMap[ExpertRequestStatus.PAYMENT_COMPLETE],
        'successLight',
      ]
  }
}

getStatusInfo.fragments = {
  statusInfo: gql`
    fragment StatusInfo on ExpertRequest {
      status
      canceledAt
      airtableId
    }
  `,
}
