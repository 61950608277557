import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { DATE_FORMAT } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp, sortByDatetime } from 'utils/sort'
import { TRIAL_PERIOD_LENGTH_DAYS } from 'views/ClientTracker/ClientTracker.constants'
import { isInTrialPeriod } from 'views/ClientTracker/ClientTracker.helpers'
import { getClientStageChanges } from 'components/ClientDashboardCard/ClientDashboardCard.helpers'
import {
  TrialClientApplicationStatus,
  ClientActivityType,
} from './DashboardCard.constants'
import {
  ClientsInterview,
  TrialClient,
  InterviewInfo,
  TrialClientInfo,
  StageChangeInfo,
  ActivityInfo,
  ClientStageChanges,
  ClientsActivity,
} from './DashboardCard.types'

export function getClientsInterviewsToday(
  clients: ClientsInterview[]
): InterviewInfo[] {
  const interviewsToday: InterviewInfo[] = []
  clients.forEach((client) => {
    const interviewCycles = getNodes(client.interviewCycles)
    interviewCycles.forEach((interviewCycle) => {
      const interviews = getNodes(interviewCycle.interviews)
      interviews.forEach((interview) => {
        if (!interview.scheduledFor) {
          return
        }
        if (dayjs().isSame(interview.scheduledFor.datetime, 'day')) {
          const isInterviewOver = dayjs().isAfter(interview.scheduledFor.datetime)
          interviewsToday.push({
            id: interview.id,
            interviewCycleId: interviewCycle.id,
            scheduledFor: interview.scheduledFor,
            clientId: client.id,
            clientName: client.name,
            clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
            companyId: interviewCycle.company?.id,
            companyName: interviewCycle.company?.name,
            companyLogo: interviewCycle.company?.logoUrl,
            companyDomain: interviewCycle.company?.domain,
            jobTitle: interviewCycle.jobTitle,
            jobUrl: interviewCycle.jobUrl,
            isInterviewOver,
          })
        }
      })
    })
  })
  return (
    interviewsToday
      .sort((a, b) =>
        sortByDatetime(a.scheduledFor.datetime, b.scheduledFor.datetime)
      )
      // Move interviews that are over to the bottom of the list
      .sort((a, b) => (!a.isInterviewOver && b.isInterviewOver ? -1 : 0))
  )
}

export function getClientsInterviewsThisWeek(
  clients: ClientsInterview[]
): InterviewInfo[] {
  const interviewsThisWeek: InterviewInfo[] = []
  clients.forEach((client) => {
    const interviewCycles = getNodes(client.interviewCycles)
    interviewCycles.forEach((interviewCycle) => {
      const interviews = getNodes(interviewCycle.interviews)
      interviews.forEach((interview) => {
        if (!interview.scheduledFor) {
          return
        }
        if (dayjs().isSame(interview.scheduledFor.datetime, 'week')) {
          const isInterviewOver = dayjs().isAfter(interview.scheduledFor.datetime)
          interviewsThisWeek.push({
            id: interview.id,
            interviewCycleId: interviewCycle.id,
            scheduledFor: interview.scheduledFor,
            clientId: client.id,
            clientName: client.name,
            clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
            companyId: interviewCycle.company?.id,
            companyName: interviewCycle.company?.name,
            companyLogo: interviewCycle.company?.logoUrl,
            companyDomain: interviewCycle.company?.domain,
            jobTitle: interviewCycle.jobTitle,
            jobUrl: interviewCycle.jobUrl,
            isInterviewOver,
          })
        }
      })
    })
  })
  return (
    interviewsThisWeek
      .sort((a, b) =>
        sortByDatetime(a.scheduledFor.datetime, b.scheduledFor.datetime)
      )
      // Move interviews that are over to the bottom of the list
      .sort((a, b) => (!a.isInterviewOver && b.isInterviewOver ? -1 : 0))
  )
}

export function getClientsInterviewNextWeek(
  clients: ClientsInterview[]
): InterviewInfo[] {
  const interviewsNextWeek: InterviewInfo[] = []
  clients.forEach((client) => {
    const interviewCycles = getNodes(client.interviewCycles)
    interviewCycles.forEach((interviewCycle) => {
      const interviews = getNodes(interviewCycle.interviews)
      interviews.forEach((interview) => {
        if (!interview.scheduledFor) {
          return
        }
        const startOfNextWeek = dayjs().day(7)
        const endOfNextWeek = dayjs().day(13)
        if (
          dayjs(interview.scheduledFor.datetime) >= startOfNextWeek &&
          dayjs(interview.scheduledFor.datetime) <= endOfNextWeek
        ) {
          const isInterviewOver = dayjs().isAfter(interview.scheduledFor.datetime)
          interviewsNextWeek.push({
            id: interview.id,
            interviewCycleId: interviewCycle.id,
            scheduledFor: interview.scheduledFor,
            clientId: client.id,
            clientName: client.name,
            clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
            companyId: interviewCycle.company?.id,
            companyName: interviewCycle.company?.name,
            companyLogo: interviewCycle.company?.logoUrl,
            companyDomain: interviewCycle.company?.domain,
            jobTitle: interviewCycle.jobTitle,
            jobUrl: interviewCycle.jobUrl,
            isInterviewOver,
          })
        }
      })
    })
  })
  return interviewsNextWeek.sort((a, b) =>
    sortByDatetime(a.scheduledFor.datetime, b.scheduledFor.datetime)
  )
}

// TODO: (ericlus) look into sharing code in the form of fragments
getClientsInterviewNextWeek.fragments = {
  clientsInterviews: gql`
    fragment ClientsInterviews on Client {
      id
      name
      headshotFiles {
        url
      }
      interviewCycles(active: true) {
        edges {
          node {
            id
            company {
              id
              name
              logoUrl
              domain
            }
            jobTitle
            jobUrl
            interviews {
              edges {
                node {
                  id
                  scheduledFor {
                    datetime
                    timezone
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}

function getApplicationStatus(client: TrialClient): TrialClientApplicationStatus {
  if (client.applications.totalCount) {
    return TrialClientApplicationStatus.APPS_SENT
  }
  if (client.taglineRequests.totalCount) {
    return TrialClientApplicationStatus.APPS_IN_PROGESS
  }
  return TrialClientApplicationStatus.NO_APPS_SENT
}

export function getTrialClients(clients: TrialClient[]): TrialClientInfo[] {
  const trialClients: TrialClientInfo[] = []
  clients.forEach((client) => {
    if (isInTrialPeriod(client)) {
      const trialStartDate = dayjs(client.callOneDate)
      const trialEndDate = dayjs(client.callOneDate).add(
        TRIAL_PERIOD_LENGTH_DAYS,
        'days'
      )
      const trialDateRange = `${trialStartDate.format(
        DATE_FORMAT.FRIENDLY_DATE_NO_YEAR
      )} - ${trialEndDate.format(DATE_FORMAT.FRIENDLY_DATE_NO_YEAR)}`
      const appStatus = getApplicationStatus(client)
      trialClients.push({
        id: client.id,
        name: client.name,
        headshot: client.headshotFiles && client.headshotFiles[0]?.url,
        trialDateRange,
        timeLeft: `${trialEndDate.fromNow(true)} left`,
        linkedinUrl: client.linkedinUrl,
        isResumeUploaded: Boolean(client.resumeUrl),
        appStatus,
        dateToSortBy: trialEndDate,
      })
    }
  })
  return trialClients.sort((a, b) => sortByProp(a, b, 'dateToSortBy'))
}

getTrialClients.fragments = {
  trialClients: gql`
    fragment TrialClients on Client {
      id
      name
      callOneDate
      linkedinUrl
      resumeUrl
      headshotFiles {
        url
      }
      taglineRequests {
        totalCount
      }
      applications(hasApplicationSubmissionSubmission: true) {
        totalCount
      }
    }
  `,
}

export function getClientsStageChanges(
  clients: ClientStageChanges[]
): StageChangeInfo[] {
  const stageChanges: StageChangeInfo[] = []
  clients.forEach((client) => {
    stageChanges.push(...getClientStageChanges(client))
  })
  return stageChanges.sort((a, b) => sortByProp(b, a, 'dateToSortBy'))
}

getClientsStageChanges.fragments = {
  clientsStageChanges: gql`
    fragment ClientsStageChanges on Client {
      id
      name
      headshotFiles {
        url
      }
      interviewCycles(createdAtRangeFilter: $dateFilter30Days) {
        edges {
          node {
            id
            company {
              id
              name
              logoUrl
              domain
            }
            jobTitle
            jobUrl
            createdAt
            inactiveAt
            interviews {
              edges {
                node {
                  id
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  `,
}

export function getClientsActivity(clients: ClientsActivity[]): ActivityInfo[] {
  const activity: ActivityInfo[] = []
  clients.forEach((client) => {
    const interviewCycles = getNodes(client.interviewCycles)
    interviewCycles.forEach((interviewCycle) => {
      const clientInfo = {
        clientId: client.id,
        clientName: client.name,
        clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
        companyId: interviewCycle.company?.id,
        companyName: interviewCycle.company?.name,
        companyLogo: interviewCycle.company?.logoUrl,
        companyDomain: interviewCycle.company?.domain,
        jobTitle: interviewCycle.jobTitle,
        jobUrl: interviewCycle.jobUrl,
      }
      const interviews = getNodes(interviewCycle.interviews)
      interviews.forEach((interview) => {
        const debriefs = interview.debriefs[0]
        if (debriefs) {
          activity.push({
            id: debriefs.id,
            type: ClientActivityType.DEBRIEF_SUBMITTED,
            dateFromNow: dayjs(debriefs.createdAt).fromNow(),
            dateToSortBy: dayjs(debriefs.createdAt),
            ...clientInfo,
          })
        }
      })
    })
  })
  return activity.sort((a, b) => sortByProp(b, a, 'dateToSortBy'))
}

getClientsActivity.fragments = {
  clientsActivity: gql`
    fragment ClientsActivity on Client {
      id
      name
      headshotFiles {
        url
      }
      interviewCycles(active: true, createdAtRangeFilter: $dateFilter) {
        edges {
          node {
            id
            company {
              id
              name
              logoUrl
              domain
            }
            jobTitle
            jobUrl
            interviews {
              edges {
                node {
                  debriefs {
                    id
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}
