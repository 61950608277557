import { ColumnMap } from 'types'

export const CompanyApplicationColumns: ColumnMap = {
  link: {
    label: 'Copy',
    id: 'link',
    collapse: true,
  },
  clientName: {
    label: 'Free Agent',
    id: 'clientName',
  },
  jobTitle: {
    label: 'Job Title',
    id: 'jobTitle',
  },
  status: {
    label: 'Status',
    id: 'status',
  },
  submissionDate: {
    label: 'Submission Date',
    id: 'submissionDate',
  },
  ledToCycle: {
    label: 'Led to Interview?',
    id: 'ledToCycle',
  },
}

export const CompanyEnrichmentSubmissionColumns: ColumnMap = {
  name: {
    label: 'Name',
    id: 'name',
  },
  email: {
    label: 'Email',
    id: 'email',
  },
  jobTitle: {
    label: 'Job Title',
    id: 'jobTitle',
  },
  created: {
    label: 'Date Added',
    id: 'created',
  },
}

export const CompanyExpertColumns: ColumnMap = {
  name: {
    label: 'Name',
    id: 'name',
  },
  totalCalls: {
    label: 'Total Calls',
    id: 'totalCalls',
  },
}

export const CompanyDossierSubmissionColumns: ColumnMap = {
  jobTitle: {
    label: 'Job Title',
    id: 'jobTitle',
  },
  rating: {
    label: 'Rating',
    id: 'rating',
  },
  dossierLink: {
    label: 'Link',
    id: 'dossierLink',
  },
  isRejected: {
    label: 'Rejected?',
    id: 'isRejected',
  },
}

export const CompanySourcedJobColumns: ColumnMap = {
  jobTitle: {
    label: 'Job Title',
    id: 'jobTitle',
  },
  rating: {
    label: 'Rating',
    id: 'rating',
  },
  dateSourced: {
    label: 'Date Sourced',
    id: 'dateSourced',
  },
  datePosted: {
    label: 'Date Posted',
    id: 'datePosted',
  },
}

export const CompanyInterviewCycleColumns: ColumnMap = {
  expandInterviewCycle: {
    label: '',
    id: 'expandInterviewCycle',
    collapse: true,
  },
  freeAgent: {
    label: 'Free Agent',
    id: 'freeAgent',
  },
  jobTitle: {
    label: 'Job Title',
    id: 'jobTitle',
  },
  source: {
    label: 'Source',
    id: 'source',
  },
  startDate: {
    label: 'Start Date',
    id: 'startDate',
  },
  huntrUrl: {
    label: 'Huntr Link',
    id: 'huntrUrl',
  },
  status: {
    label: 'Status',
    id: 'status',
  },
}
