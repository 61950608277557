import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { QS } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { getJobHistoryState } from './ClientJobHistory.helpers'
import ClientJobTimeline from './ClientJobTimeline'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetClientSourcedJobHistoryQuery,
  GetClientSourcedJobHistoryQueryVariables,
  GetClientTaglineRequestJobHistoryQuery,
  GetClientTaglineRequestJobHistoryQueryVariables,
} from 'generated/graphql'

export default function ClientJobHistory(): React.ReactElement {
  const queryString = useQueryString()
  const sourcedJobId = queryString.get(QS.SOURCED_JOB_ID)
  const taglineRequestId = queryString.get(QS.TAGLINE_REQUEST_ID)
  const sourcedJobDataOptions = sourcedJobId
    ? { variables: { id: sourcedJobId } }
    : { skip: true }
  const taglineRequestDataOptions = taglineRequestId
    ? { variables: { id: taglineRequestId } }
    : { skip: true }

  const sourcedJobData = useQuery<
    GetClientSourcedJobHistoryQuery,
    GetClientSourcedJobHistoryQueryVariables
  >(GET_CLIENT_SOURCED_JOB_HISTORY, sourcedJobDataOptions)

  const taglineRequestData = useQuery<
    GetClientTaglineRequestJobHistoryQuery,
    GetClientTaglineRequestJobHistoryQueryVariables
  >(GET_CLIENT_TAGLINE_REQUEST_JOB_HISTORY, taglineRequestDataOptions)

  const jobHistoryState = getJobHistoryState(
    sourcedJobData,
    taglineRequestData,
    taglineRequestId
  )
  if (!jobHistoryState) {
    return <Message vertical message="Could not find job history." />
  }
  if (jobHistoryState.isErrored) {
    return (
      <Message
        vertical
        message="There was an error retrieving the job history data."
      />
    )
  }
  if (jobHistoryState.isLoading) {
    return <Message message="Loading job history..." />
  }
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={18}>{jobHistoryState.clientInfo?.name}</Txt>
          <Padding top={1}>
            <Txt size={24} bold as="h2">
              {getJobInfoLabel({
                jobTitle: jobHistoryState.clientInfo?.jobTitle,
                employerName: jobHistoryState.clientInfo?.employerName,
              })}
            </Txt>
          </Padding>
        </Padding>
        {jobHistoryState.timelineEvents && (
          <ClientJobTimeline jobEvents={jobHistoryState.timelineEvents} />
        )}
      </Container>
    </ViewBox>
  )
}

ClientJobHistory.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.CLIENT_JOB_HISTORY}
    key={ROUTE_PATHS.CLIENT_JOB_HISTORY}
  >
    <ClientJobHistory />
  </PrivateRoute>,
]

ClientJobHistory.fragments = {
  clientJobHistoryInfoTaglineRequest: gql`
    fragment ClientJobHistoryInfoTaglineRequest on TaglineRequest {
      id
      createdAt
      huntrUrl
      taglineRequestRejections {
        id
        createdAt
        rejectionReason
      }
      writerSubmissions {
        id
        createdAt
        talentAgentReviews {
          id
          createdAt
          rating
          taglineRejectionReason
        }
        talentPartnerReviews {
          id
          createdAt
          ratingTalentAgent
          taglineRejectionReason
        }
      }
    }
  `,
}

const GET_CLIENT_SOURCED_JOB_HISTORY = gql`
  query GetClientSourcedJobHistory($id: ID!) {
    sourcedJob(id: $id) {
      id
      company: companyEntity {
        name
      }
      title
      createdAt
      client {
        id
        name
      }
      reviews {
        id
        createdAt
        rating
        rejectionReason
      }
      huntrJobActions {
        edges {
          node {
            id
            jobActionType
            toListName
            fromListName
            huntrAction {
              createdAt
            }
          }
        }
      }
      taglineRequests {
        edges {
          node {
            ...ClientJobHistoryInfoTaglineRequest
          }
        }
      }
      enrichmentRequests {
        edges {
          node {
            id
            createdAt
            enrichmentSubmissions {
              edges {
                node {
                  id
                  createdAt
                  rejectionReason
                }
              }
            }
          }
        }
      }
      applications {
        edges {
          node {
            id
            createdAt
            applicationSubmissions {
              id
              createdAt
              rejectionReason
            }
            missingApplicationInfos {
              id
              createdAt
              answeredAt
              declineReason
              applicationNew {
                id
              }
            }
            priorMissingApplicationInfos {
              id
              answeredAt
              declineReason
            }
            interviewCycles {
              id
              createdAt
              inactiveAt
              dossierRequests {
                edges {
                  node {
                    id
                    createdAt
                  }
                }
              }
              dossierSubmissions {
                edges {
                  node {
                    id
                    createdAt
                    rejectionReason
                    fileUrl
                  }
                }
              }
              interviews {
                edges {
                  node {
                    id
                    createdAt
                    debriefs {
                      id
                      createdAt
                      rating
                    }
                  }
                }
              }
              interviewPrepRequests {
                edges {
                  node {
                    id
                    createdAt
                  }
                }
              }
              expertRequests {
                edges {
                  node {
                    id
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
      redactions {
        id
        createdAt
      }
    }
  }
  ${ClientJobHistory.fragments.clientJobHistoryInfoTaglineRequest}
`

const GET_CLIENT_TAGLINE_REQUEST_JOB_HISTORY = gql`
  query GetClientTaglineRequestJobHistory($id: ID!) {
    taglineRequest(id: $id) {
      jobTitle
      employerName
      client {
        id
        name
      }
      company {
        name
      }
      sourcedJob {
        id
      }
      applications {
        edges {
          node {
            id
            createdAt
            applicationSubmissions {
              id
              createdAt
              rejectionReason
            }
            missingApplicationInfos {
              id
              createdAt
              answeredAt
              declineReason
              applicationNew {
                id
              }
            }
            priorMissingApplicationInfos {
              id
              answeredAt
              declineReason
            }
            interviewCycles {
              id
              createdAt
              inactiveAt
              dossierRequests {
                edges {
                  node {
                    id
                    createdAt
                  }
                }
              }
              dossierSubmissions {
                edges {
                  node {
                    id
                    createdAt
                    rejectionReason
                    fileUrl
                  }
                }
              }
              interviews {
                edges {
                  node {
                    id
                    createdAt
                    debriefs {
                      id
                      createdAt
                      rating
                    }
                  }
                }
              }
              interviewPrepRequests {
                edges {
                  node {
                    id
                    createdAt
                  }
                }
              }
              expertRequests {
                edges {
                  node {
                    id
                    createdAt
                  }
                }
              }
            }
          }
        }
      }
      enrichmentRequests {
        edges {
          node {
            id
            createdAt
            enrichmentSubmissions {
              edges {
                node {
                  id
                  createdAt
                  rejectionReason
                }
              }
            }
          }
        }
      }
      huntrJobActions {
        edges {
          node {
            id
            jobActionType
            toListName
            fromListName
            huntrAction {
              createdAt
            }
          }
        }
      }
      ...ClientJobHistoryInfoTaglineRequest
    }
  }
  ${ClientJobHistory.fragments.clientJobHistoryInfoTaglineRequest}
`
