import styled from 'styled-components/macro'
import { Colors } from 'types'

export const ActionButton = styled.button<{
  color: keyof Colors
  hoverColor: keyof Colors
}>`
  width: ${({ theme }) => theme.rhythm(5.5)};
  height: ${({ theme }) => theme.rhythm(2)};
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme, color }) => theme.color[color]};

  &:hover {
    background-color: ${({ theme, hoverColor }) => theme.color[hoverColor]};
  }
`
