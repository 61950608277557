import { DefaultTheme } from 'styled-components/macro'

const RHYTHM = 8

export const toRems = (size: number): string => `${size / 10}rem`
export const rhythm = (mult: number): string => `${(mult * RHYTHM) / 10}rem`

const theme: DefaultTheme = {
  color: {
    faBlue: '#216EF2',
    faDarkBlue: '#212E45',
    faYellow: '#FFE870',
    faLightBlue: '#A3F2F2',
    faPink: '#FCD6D1',
    faGreen: '#66F2A8',
    faRed: '#FA8A8A',
    faGrey1: '#FAF7F5',
    faGrey150: '#F6F1EE',
    faGrey2: '#CFD1D1',
    faGrey3: '#A3A8AD',
    faGrey4: '#78808A',
    faGrey5: '#4D5766',
    yellowLight: '#ffeab6',
    yellow: '#ffcd60',
    yellowDark: '#ffba24',
    purple: '#6152e8',
    lightPurple: '#BFB9F5',
    text: '#4D5766',
    textDark: '#343E4D',
    textLight: '#6A6A6A',
    link: '#0b51c9',
    blueHighlight: '#e0f0ff',
    white: '#fff',
    black: '#000',
    darkGray: '#333333',
    lightGray: '#eaeaea',
    lightBlue: '#F6FAFF',
    iconPurple: '#977BC7',
    iconLightPurple: '#FBF8FF',
    iconBlue: '#7397D7',
    iconLightBlue: '#F6FAFF',
    iconGray: '#909090',
    iconLightGray: '#F8F8F8',
    numberCardGray: '#FAFAFA',
    numberDarkGray: '#646464',
    success: '#0d9e51',
    disabledInput: '#EFEFEF4D',
    successLight: '#0fbd61',
    warning: '#d8b909',
    error: '#f10a0a',
    errorLight: '#f86262',
    linkedinBlue: '#0072b1',
    huntrPurple: '#1A0545',
    glassdoorGreen: '#0CAA41',
  },
  font: {
    primary: `'DM Sans', sans-serif`,
    secondary: `'DM Serif Display', serif`,
  },
  boxShadow: {
    primary: '0 1px 3px 0 rgb(0 0 0 / 0.1), 0 1px 2px -1px rgb(0 0 0 / 0.1);',
    medium: '0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);',
    large: '0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);',
  },
  size: {
    headerHeight: rhythm(8),
  },
  borderRadius: '.4rem',
  toRems,
  rhythm,
}

export default theme
