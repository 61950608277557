import { ApolloCache } from '@apollo/client'
import { Modifiers } from '@apollo/client/cache/core/types/common'
import removeCacheEdgeById from './removeCacheEdgeById'

/**
 * this will remove the current item from the connection in cache so that ui will properly update
 * @param cache Apollo's cache
 */
export function removeItemFromCacheConnections<TMutation = unknown>(
  cache: ApolloCache<TMutation>,
  itemId: string,
  cacheStrings: string[]
): void {
  const fields = cacheStrings.reduce((modifiers: Modifiers, cacheString) => {
    modifiers[cacheString] = (queryResult, { readField }) => {
      return removeCacheEdgeById(itemId, queryResult, readField)
    }
    return modifiers
  }, {})

  cache.modify({
    fields,
  })
}
