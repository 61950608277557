import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { DropdownOption, Undefinable } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import groupAssignItems, {
  GenericGroupedRowData,
} from 'utils/helpers/groupAssigItems'
import { sortByProp } from 'utils/sort'
import {
  GetSourcersQuery,
  GetSourcingForAssigningQuery,
  GetSourcingForAssigningQueryVariables,
} from 'generated/graphql'

type ReturnType = {
  groupedRowData: Undefinable<GenericGroupedRowData[]>
  assigneeOptions: DropdownOption[]
}

export default function useAssignSourcingData(isReassigning: boolean): ReturnType {
  // TODO: (matthewalbrecht) avoid making multiple network requests on status changes
  const { data: sourcingRequestData } = useQuery<
    GetSourcingForAssigningQuery,
    GetSourcingForAssigningQueryVariables
  >(GET_SOURCING_FOR_ASSIGNING, {
    variables: {
      isAssigned: isReassigning,
    },
  })

  const { data: applierData } = useQuery<GetSourcersQuery>(GET_SOURCERS)

  const sourcingRequests = sourcingRequestData?.sourcingRequests?.edges.map(
    (edge) => ({
      ...edge.node,
      sortableDue: dayjs(edge.node.due),
      sortableCreatedAt: dayjs(edge.node.createdAt),
      clientName: edge.node.client.name,
      assigneeId: edge.node.assignee?.id,
    })
  )

  // this restructures the data to fit our nested table row structure
  // we show client or Sourcer and then all of their sourcingRequests
  const groupedRowData = sourcingRequests
    ? groupAssignItems(sourcingRequests, isReassigning)
    : []

  // sort the requests by clientName-due
  groupedRowData.forEach((group) => {
    group.items.sort((a, b) => sortByProp(a, b, 'clientName', 'sortableDue'))
  })

  // sort groups by soonest due
  groupedRowData.sort((a, b) => sortByProp(a.items[0], b.items[0], 'sortableDue'))

  const assigneeOptions: DropdownOption[] = buildDropdownOptions(
    applierData?.users || []
  )

  return { groupedRowData, assigneeOptions }
}

/**
 * Use to query all sourcingRequests
 */
const GET_SOURCING_FOR_ASSIGNING = gql`
  query GetSourcingForAssigning($isAssigned: Boolean!) {
    sourcingRequests(isAssigned: $isAssigned, isInProgress: false, isOpen: true) {
      edges {
        node {
          id
          client {
            id
            name
            roleCategory
            roleExperience
          }
          due
          createdAt
          assignee {
            id
            name
          }
          assignedAt
          numJobs
        }
      }
    }
  }
`

/**
 * used to get all appliers
 */
const GET_SOURCERS = gql`
  query GetSourcers {
    users(anyRole: { isSourcer: true }) {
      id
      name
    }
  }
`
