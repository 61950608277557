import React, { useCallback, useRef, useState } from 'react'
import { RejectionTypes } from 'types'
import useOutsideClick from 'hooks/useOutsideClick'
import { Option, Options, RejectButtonBox } from './RejectionButton.styles'
import { RejectionOption, RejectionPositions } from './RejectionButton.types'
import Button from 'components/Button'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props<T extends RejectionTypes> = {
  /* TODO (matthewalbrecht): Allow for mutliple types of rejection options */
  rejectionOptions: RejectionOption<T>[]
  disabled?: boolean
  position?: RejectionPositions
}

export default function RejectButton<T extends RejectionTypes>({
  rejectionOptions,
  disabled = false,
  position = 'top',
}: Props<T>): JSX.Element {
  const [showOptions, setShowOptions] = useState<boolean>(false)
  const node = useRef<HTMLDivElement>(null)
  const handleOutsideClick = useCallback(
    () => setShowOptions(false),
    [setShowOptions]
  )
  useOutsideClick(node, handleOutsideClick)

  function handleSubmit({ rejectionReason, callback }: RejectionOption<T>) {
    callback(rejectionReason)
    setShowOptions(false)
  }

  return (
    <RejectButtonBox ref={node}>
      <Button
        $type="reject"
        onClick={() => setShowOptions(!showOptions)}
        disabled={disabled}
      >
        Reject
      </Button>
      <Options active={showOptions} position={position}>
        <VList size={0.5}>
          {rejectionOptions.map((option) => (
            <Option key={option.label} onClick={() => handleSubmit(option)}>
              <Txt size={14} lineHeight={1.3}>
                {option.label}
              </Txt>
            </Option>
          ))}
        </VList>
      </Options>
    </RejectButtonBox>
  )
}
