import styled from 'styled-components/macro'
import { bp, breakpoints } from 'theme/breakpoint'

export const ButtonRow = styled.div`
  display: flex;
  flex-direction: column;

  ${bp(breakpoints.md)} {
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`

export const RejectButtonBox = styled.div`
  padding-top: ${({ theme }) => theme.rhythm(1)};
  ${bp(breakpoints.md)} {
    padding-top: 0;
    margin-left: auto;
  }
`
