import { UserSession } from 'types'
import { useAuthContext } from 'context/auth'
import { Role } from 'utils/enums'

type RoleProps =
  | 'isTalentAgent'
  | 'isTalentPartner'
  | 'isWriter'
  | 'isTea'
  | 'isTee'
  | 'isCoordinator'
  | 'isAdmin'
  | 'isApplier'
  | 'isEnricher'
  | 'isSourcer'
  | 'isDossierWriter'
  | 'isExpertCoordinator'
  | 'isDataCleaner'
  | 'isSalesCoordinator'

/**
 * returns true user has any role in the roles array given
 * @param roles takes an array of allowed role types
 */
export default function checkRole(roles: RoleProps[]): boolean {
  /* TODO (matthewalbrecht): make this a custom hook that returns a memoized function */
  const {
    userSession: { user },
    // eslint-disable-next-line react-hooks/rules-of-hooks
  } = useAuthContext()

  return roles.reduce((hasRole: boolean, role) => hasRole || user[role], false)
}

export function checkRoleEnum(user: UserSession['user'], roles: Role[]): boolean {
  return roles.reduce((hasRole: boolean, role) => hasRole || user[role], false)
}
