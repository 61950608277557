import React from 'react'
import { Undefinable } from 'types'
import { INTERVIEW_HEADERS } from './InterviewCycleDetail.constants'
import { Interview } from './InterviewCycleDetail.types'
import InterviewCycleDetailInterview from './InterviewCycleDetailInterview'
import { Grid } from './interviewCycleDetail.styles'
import { SectionHeader } from 'components/MiscStyles'
import Txt from 'components/Txt'

type Props = {
  pathname: string
  interviews: Undefinable<Interview[]>
}

export default function InterviewCycleDetailInterviewList({
  pathname,
  interviews,
}: Props): React.ReactElement {
  function renderHeaders() {
    return INTERVIEW_HEADERS.map(({ label, id }) =>
      label ? (
        <Txt size={13} bold key={id}>
          {label}
        </Txt>
      ) : (
        <div key={id} />
      )
    )
  }

  const hasInterviews = Boolean(interviews && interviews.length)

  return (
    <div>
      <SectionHeader headerCopy="Interviews" />
      {!hasInterviews && <Txt alignment="center">No Interviews Found.</Txt>}
      {interviews && (
        <Grid
          gridTemplateColumns="min-content min-content min-content auto min-content min-content"
          rowGapSize={2}
          colGapSize={1.5}
        >
          {hasInterviews && renderHeaders()}
          {interviews.map((interview, index) => (
            <InterviewCycleDetailInterview
              interview={interview}
              pathname={pathname}
              key={interview.id}
              interviewNumber={interviews.length - index}
            />
          ))}
        </Grid>
      )}
    </div>
  )
}
