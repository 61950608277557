import ROUTE_PATHS from 'utils/routePaths'

type TabsInfo = {
  label: string
  route: string
  matchingRoutes: string[]
}

export const TABS_INFO: TabsInfo[] = [
  {
    label: 'Dashboard',
    route: ROUTE_PATHS.CLIENT_CONFIG_DASHBOARD,
    matchingRoutes: [],
  },
  {
    label: 'Preferences',
    route: ROUTE_PATHS.CLIENT_CONFIG_PREFERENCES,
    matchingRoutes: [],
  },
  {
    label: 'Templates',
    route: ROUTE_PATHS.CLIENT_CONFIG_TEMPLATES,
    matchingRoutes: [],
  },
  {
    label: 'Applications',
    route: ROUTE_PATHS.CLIENT_CONFIG_APPLICATIONS,
    matchingRoutes: [],
  },
  {
    label: 'Interview Cycles',
    route: ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLES,
    matchingRoutes: [],
  },
  {
    label: 'Interview Prep',
    route: ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_PREP_REQUESTS,
    matchingRoutes: [],
  },
  {
    label: 'Expert Requests',
    route: ROUTE_PATHS.CLIENT_CONFIG_EXPERT_REQUESTS,
    matchingRoutes: [],
  },
  {
    label: 'Jobs',
    route: ROUTE_PATHS.CLIENT_CONFIG_JOBS,
    matchingRoutes: [],
  },
  {
    label: 'Settings',
    route: ROUTE_PATHS.CLIENT_CONFIG_SETTINGS,
    matchingRoutes: [],
  },
  {
    label: 'Tracker',
    route: ROUTE_PATHS.CLIENT_CONFIG_TRACKER,
    matchingRoutes: [],
  },
]
