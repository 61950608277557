import { gql } from '@apollo/client'
import React from 'react'
import { TemplateMap } from 'types'
import Editor from 'components/Editor/Editor'

type Props = {
  hideLabel?: boolean
  template: {
    id: string
    name: string
    copy: string
  }
  sequence: {
    id: string
    name?: string
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any
  }
  templateMap?: TemplateMap | null
  handleEditorChange: (
    content: string,
    templateId: string,
    templateSequenceId: string
  ) => void
  withIndicator?: boolean
}

function TemplateEditor({
  template,
  sequence,
  withIndicator = false,
  handleEditorChange,
  hideLabel = false,
  templateMap,
}: Props): JSX.Element {
  const label = `Edit ${template.name} ${sequence.name || ''}`

  return (
    <Editor
      defaultValue={template.copy}
      label={hideLabel ? undefined : label}
      handleChange={(content: string) =>
        handleEditorChange(content, template.id, sequence.id)
      }
      withIndicator={withIndicator}
      templateMap={templateMap}
    />
  )
}

TemplateEditor.fragments = {
  templateEditorSequenceInfo: gql`
    fragment TemplateEditorSequenceInfo on TemplateSequence {
      id
      name
      templates {
        id
        name
        copy
      }
    }
  `,
  writerSubmissionInfo: gql`
    fragment TemplateEditorWriterSubmissionInfo on TaglineSubmission {
      id
      items {
        id
        name
        copy
      }
    }
  `,
  agentReviewInfo: gql`
    fragment TemplateEditorAgentReviewInfo on TalentAgentReview {
      id
      items {
        id
        name
        copy
      }
    }
  `,
}

export default TemplateEditor
