import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'
import { Tooltip } from 'components/WrappedTooltip/WrappedTooltip'
import { TrialClientApplicationStatus } from './DashboardCard.constants'
import {
  DashboardContentRow,
  IconContainer,
  StyledTxt,
} from './DashboardCard.styles'
import { TrialClientInfo } from './DashboardCard.types'
import DashboardCardClientHeadshot from './DashboardCardClientHeadshot'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  trialClientInfo: TrialClientInfo
}

export default function DashboardTrialClientCard({
  trialClientInfo,
}: Props): React.ReactElement {
  function renderApplicationIcon() {
    if (trialClientInfo.appStatus === TrialClientApplicationStatus.APPS_SENT) {
      return (
        <Tooltip label="Applications sent">
          <IconContainer>
            <Icon
              name="ClipboardCheck"
              height={18}
              width={18}
              primaryFill="faGrey5"
            />
          </IconContainer>
        </Tooltip>
      )
    }
    if (trialClientInfo.appStatus === TrialClientApplicationStatus.APPS_IN_PROGESS) {
      return (
        <Tooltip label="Applications in progress">
          <IconContainer>
            <Icon name="Clipboard" height={18} width={18} primaryFill="faGrey5" />
          </IconContainer>
        </Tooltip>
      )
    }
    return (
      <Tooltip label="No applications sent">
        <IconContainer>
          <Icon name="Clipboard" height={18} width={18} primaryFill="iconGray" />
        </IconContainer>
      </Tooltip>
    )
  }
  return (
    <DashboardContentRow>
      <Flex align="center" justify="space-between">
        <Flex align="center">
          <Padding right={1}>
            <DashboardCardClientHeadshot
              src={trialClientInfo.headshot}
              alt={trialClientInfo.name}
            />
          </Padding>
          <div>
            <Flex align="center">
              <Link
                to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
                  clientId: trialClientInfo.id,
                })}
              >
                <StyledTxt bold>{trialClientInfo.name}</StyledTxt>
              </Link>
              {trialClientInfo.linkedinUrl && (
                <Padding left={0.75}>
                  <ExternalLink url={trialClientInfo.linkedinUrl}>
                    <Icon
                      name="Linkedin"
                      height={20}
                      width={20}
                      primaryFill="linkedinBlue"
                    />
                  </ExternalLink>
                </Padding>
              )}
            </Flex>
            <Padding top={0.5}>
              <Flex>
                <Txt size={14}>{trialClientInfo.timeLeft}</Txt>
                {trialClientInfo.trialDateRange && (
                  <Txt size={14}>&nbsp;({trialClientInfo.trialDateRange})</Txt>
                )}
              </Flex>
            </Padding>
          </div>
        </Flex>
        <Flex>
          <Tooltip
            label={
              trialClientInfo.isResumeUploaded
                ? 'Resume uploaded'
                : 'Resume not uploaded'
            }
          >
            <IconContainer>
              <Icon
                name="File"
                height={17.5}
                width={17.5}
                primaryFill={
                  trialClientInfo.isResumeUploaded ? 'faGrey5' : 'iconGray'
                }
              />
            </IconContainer>
          </Tooltip>
          <Padding left={2}>{renderApplicationIcon()}</Padding>
        </Flex>
      </Flex>
    </DashboardContentRow>
  )
}
