import { DeltaOperation } from 'quill'
import React, { useCallback, useEffect, useState } from 'react'
import ReactQuill, { Quill } from 'react-quill'
import 'react-quill/dist/quill.snow.css'
import { TemplateMap } from 'types'
import { TEMPLATE_VARIABLE_REGEX } from 'utils/constants'
import { fillTemplate } from 'utils/helpers/templateVariables'
import { EditorSource } from './Editor.constants'
import { EditorBox, StyledEditor } from './Editor.styles'
import Label from 'components/Label'

type Props = {
  label?: string
  defaultValue?: string
  disabled?: boolean
  height?: number
  withIndicator?: boolean
  handleChange?: (content: string) => void
  templateMap?: TemplateMap | null
}

export default function Editor({
  label,
  defaultValue = '',
  handleChange,
  disabled,
  height,
  withIndicator = false,
  templateMap,
}: Props): JSX.Element {
  const [variables, setVariables] = useState<RegExpMatchArray | null>(
    getVariables(defaultValue)
  )
  const [quillEditor, setQuillEditor] = useState<Quill | null>(null)
  useEffect(() => {
    if (templateMap && quillEditor) {
      const contents = quillEditor.getContents()
      // map through the ops array and find insert statements, find and replace all
      // template variables
      quillEditor.setContents(
        {
          ...contents,
          ops: contents.map<DeltaOperation>((op) => {
            return {
              ...op,
              insert:
                op.insert != null ? fillTemplate(op.insert, templateMap) : undefined,
            }
          }),
        },
        // source is user because they are filling in the inputs, and clicking the button to fill the temlates
        'user'
      )
    }
  }, [quillEditor, templateMap])

  const handleRef = useCallback(
    (ref: ReactQuill | null) => {
      if (ref) {
        const quill = ref.getEditor()
        setQuillEditor(quill)
        // enable grammarly
        delete quill.root.dataset.gramm
      }
    },
    [setQuillEditor]
  )

  // returns an array of strings that match the regex or null
  function getVariables(value: string) {
    /* TODO (matthewalbrecht): consider using RegExp.exec() */
    // exec is to be looped over and allows you to gather more detail about each match,
    // however we are just looking for a count string.match is much simpler for our use case
    // since it will return array of all matches
    // eslint-disable-next-line @typescript-eslint/prefer-regexp-exec
    return value.match(TEMPLATE_VARIABLE_REGEX)
  }

  return (
    <EditorBox>
      {label && <Label padding={2} content={label} />}
      <StyledEditor
        defaultValue={defaultValue}
        onChange={(content, _, source) => {
          // check if source of the changes are from a user before invoking callback
          if (source === EditorSource.User) {
            // TODO (matthewalbrecht): debounce this
            setVariables(getVariables(content))
            handleChange?.(content)
          }
        }}
        // https://quilljs.com/docs/formats/
        formats={[
          'bold',
          'italic',
          'link',
          'size',
          'strike',
          'underline',
          'blockquote',
          'header',
          'indent',
          'list',
          'align',
        ]}
        scrollingContainer=".ql-editor"
        readOnly={disabled}
        height={height}
        ref={handleRef}
        withIndicator={withIndicator}
        variables={variables}
      />
    </EditorBox>
  )
}
