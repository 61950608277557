import React, { createContext, Dispatch, useContext, useReducer } from 'react'
import { KeyValue } from 'types'

enum ActionTypes {
  UpdateMap = 'updateMap',
  UpdateCheckedItems = 'updateCheckedItem',
  Reset = 'reset',
}

interface Action<A> {
  type: A
}

type PossibleActions =
  | ItemAssignmentAction
  | UpdateMapAction
  | UpdateCheckedItemsAction

type ItemAssignmentAction = Action<ActionTypes>
type ItemAssignmentDispatch = Dispatch<PossibleActions>
export type ItemsUpdateMapValue = {
  assigneeId?: string | null
  due?: string | null
}
type ChangesContextType = { state: State; dispatch: ItemAssignmentDispatch }

interface UpdateMapAction extends ItemAssignmentAction {
  payload: KeyValue<ItemsUpdateMapValue>
}

interface UpdateCheckedItemsAction extends ItemAssignmentAction {
  payload: KeyValue<boolean>
}

type State = {
  updates: KeyValue<ItemsUpdateMapValue>
  // TODO: (matthewalbrecht) consider moving this back into component
  checkedItems: KeyValue<boolean>
}

const ChangesContext = createContext<ChangesContextType | null>(null)

const INITIAL_STATE = {
  updates: {},
  checkedItems: {},
}

function changesReducer(state: State, action: PossibleActions) {
  switch (action.type) {
    case ActionTypes.UpdateMap: {
      const newUpdates: KeyValue<ItemsUpdateMapValue> = {}
      const incomingUpdates = (action as UpdateMapAction).payload

      // merges incoming updates with existing updates 1 level deep
      Object.entries(incomingUpdates).forEach(([id, values]) => {
        const currentItemState = state.updates[id] || {}
        newUpdates[id] = { ...currentItemState, ...values }
      })

      return {
        ...state,
        updates: { ...state.updates, ...newUpdates },
      }
    }
    case ActionTypes.UpdateCheckedItems: {
      return {
        ...state,
        checkedItems: {
          ...state.checkedItems,
          ...(action as UpdateCheckedItemsAction).payload,
        },
      }
    }
    case ActionTypes.Reset: {
      return INITIAL_STATE
    }
    // typescript will error if not all actions types are handled
  }
}

type ProviderProps = {
  children: React.ReactNode
}

export function ChangesProvider({ children }: ProviderProps): JSX.Element {
  const [state, dispatch] = useReducer(changesReducer, INITIAL_STATE)

  return (
    <ChangesContext.Provider value={{ state, dispatch }}>
      {children}
    </ChangesContext.Provider>
  )
}

type UseChangesReturnType = {
  state: State
  dispatch: ItemAssignmentDispatch
}

/**
 * the hook that will actually expose our state and dispatch
 */
export function useChanges(): UseChangesReturnType {
  const context = useContext(ChangesContext)
  if (!context) {
    throw new Error('useChanges must be used within a ChangesProvider')
  }

  return context
}

// functions for components to use in order to interact with state
export const updateData = (
  dispatch: ItemAssignmentDispatch,
  newDataMap: KeyValue<ItemsUpdateMapValue>
): void => {
  dispatch({ type: ActionTypes.UpdateMap, payload: newDataMap })
}

export const modifyCheckedItems = (
  dispatch: ItemAssignmentDispatch,
  newCheckedItemsMap: KeyValue<boolean>
): void =>
  dispatch({
    type: ActionTypes.UpdateCheckedItems,
    payload: newCheckedItemsMap,
  })

export const resetState = (dispatch: ItemAssignmentDispatch): void =>
  dispatch({
    type: ActionTypes.Reset,
  })
