import React from 'react'
import { useAuthContext } from 'context/auth'
import ROUTE_PATHS from 'utils/routePaths'
import TalentAgentDashboard from 'views/TalentAgentDashboard/TalentAgentDashboard'
import WorkDashboard from 'views/WorkDashboard/WorkDashboard'
import PrivateRoute from 'components/PrivateRoute'

export default function Home(): React.ReactElement {
  const {
    userSession: {
      user: { isTalentAgent, id },
    },
  } = useAuthContext()

  if (isTalentAgent) {
    return <TalentAgentDashboard talentAgentId={id} />
  }
  return <WorkDashboard />
}

Home.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.HOME} key={ROUTE_PATHS.HOME}>
    <Home />
  </PrivateRoute>,
]
