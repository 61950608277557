const ROUTE_PATHS = {
  HOME: '/',
  LOGIN: '/login',
  LOGOUT: '/logout',
  MAGIC_LINK: '/magic/:token',
  AVAILABLE_TAGLINES: '/taglines/available',
  ASSIGNED_TAGLINES: '/taglines/assigned',
  AVAILABLE_APPLICATIONS: '/applications/available',
  ASSIGNED_APPLICATIONS: '/applications/assigned',
  AVAILABLE_SOURCING: '/sourcing-requests/available',
  ASSIGNED_SOURCING: '/sourcing-requests/assigned',
  AVAILABLE_FINAL_REVIEWS: '/final-reviews/available',
  ASSIGNED_FINAL_REVIEWS: '/final-reviews/assigned',
  CURRENT_ASSIGNMENTS: '/taglines/current-assignments',
  REVIEWED_TAGLINES: '/taglines/reviewed',
  WRITER_TAGLINE_STATS: '/writer/:writerId/taglines/reviewed',
  NEW_TAGLINE_REQUEST: '/tagline-request/new',
  EDIT_TAGLINE_REQUEST: '/tagline-request/:requestId/edit',
  FREE_AGENT_CONFIG: '/free-agent/:clientId',
  TAGLINE_STATUS: '/taglines/status',
  TAGLINE_WORK_LOG: '/taglines/log',
  REVIEW_FEEDBACK_QUEUE: '/writer/:writerId/feedback/:submissionId',
  REVIEW_FEEDBACK: '/feedback/:submissionId',
  WRITER_QUEUE: '/writer/:writerId/tagline-request/:requestId',
  WRITER_QUEUE_BY_FREE_AGENT: '/free-agent/:freeAgentId/tagline-request/:requestId',
  APPLIER_QUEUE: '/applier/:applierId/application/:applicationId',
  APPLIER_QUEUE_BY_FREE_AGENT: '/free-agent/:freeAgentId/application/:applicationId',
  APPLIER_ASSIGNMENT_QUEUES:
    '/:type(applier|free-agent)/:typeId/application/:applicationId',
  APPLICATION_INFO: '/applications/:applicationId',
  SOURCER_QUEUE: '/sourcer/:sourcerId/sourcing-request/:requestId',
  SOURCER_QUEUE_BY_FREE_AGENT:
    '/free-agent/:freeAgentId/sourcing-request/:requestId',
  SOURCER_ASSIGNMENT_QUEUES:
    '/:type(sourcer|free-agent)/:typeId/sourcing-request/:requestId',
  WRITER_ASSIGNMENT_QUEUES:
    '/:type(writer|free-agent)/:typeId/tagline-request/:requestId',
  AGENT_QUEUE: '/talent-agent/:talentAgentId/agent-review/:requestId',
  AGENT_QUEUE_BY_FREE_AGENT: '/free-agent/:freeAgentId/agent-review/:requestId',
  AGENT_QUEUE_BY_POD: '/agent-review/:podId/:requestId',
  AGENT_REVIEW_QUEUES:
    '/:type(talent-agent|free-agent)/:typeId/agent-review/:requestId',
  PARTNER_QUEUE: '/talent-partner/:talentPartnerId/partner-review/:requestId',
  PARTNER_QUEUE_BY_FREE_AGENT: '/free-agent/:freeAgentId/partner-review/:requestId',
  PARTNER_QUEUE_BY_POD: '/partner-review/:podId/:requestId',
  PARTNER_REVIEW_QUEUES:
    '/:type(talent-partner|free-agent)/:typeId/partner-review/:requestId',
  TA_OR_TP_QUEUE:
    '/free-agent/:freeAgentId/:queueType(partner-review|agent-review)/:requestId',
  ACTIVITY_LOG: '/activity-log',
  ENRICHER_QUEUE: '/enrichment-request/:requestId',
  ENRICHER_QUEUE_BY_POD: '/enrichment-request/:podId/:requestId',
  NEW_SOURCING_REQUEST: '/sourcing-request/new',
  EDIT_SOURCING_REQUEST: '/sourcing-request/:requestId/edit',
  SOURCING_QUEUE: '/sourcer/:sourcerId/sourcing-request/:requestId',
  SOURCING_QUEUE_BY_FREE_AGENT:
    '/free-agent/:freeAgentId/sourcing-request/:requestId',
  SOURCING_ASSIGNMENT_QUEUES:
    '/:type(sourcer|free-agent)/:typeId/sourcing-request/:requestId',
  REDACT_SOURCED_JOBS: '/sourced-jobs/redact',
  REVIEW_SOURCED_JOBS: '/sourced-jobs/review',
  REVIEW_SOURCED_JOBS_QUEUE:
    '/free-agent/:freeAgentId/sourced-jobs-review/:sourcedJobId',
  REDACT_ENRICHMENT: '/enrichment/redact',
  PREFERENCES: '/preferences',
  NEW_INTERVIEW_CYCLE: '/interview-cycle/new',
  CLIENT_CONFIG_DEFAULT: '/free-agent/:clientId',
  CLIENT_CONFIG_DASHBOARD: '/free-agent/:clientId/dashboard',
  CLIENT_CONFIG_TRACKER: '/free-agent/:clientId/tracker',
  CLIENT_CONFIG_PREFERENCES: '/free-agent/:clientId/preferences',
  CLIENT_CONFIG_SETTINGS: '/free-agent/:clientId/settings',
  CLIENT_CONFIG_TEMPLATES: '/free-agent/:clientId/templates',
  CLIENT_CONFIG_APPLICATIONS: '/free-agent/:clientId/applications',
  CLIENT_CONFIG_INTERVIEW_CYCLES: '/free-agent/:clientId/interview-cycles',
  CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL:
    '/free-agent/:clientId/interview-cycles/:interviewCycleId',
  CLIENT_CONFIG_DOSSIER_FEEDBACK:
    '/free-agent/:clientId/interview-cycles/:interviewCycleId/dossier-submission/:dossierSubmissionId',
  CLIENT_CONFIG_EXPERT_REQUESTS: '/free-agent/:clientId/expert-requests',
  CLIENT_CONFIG_INTERVIEW_PREP_REQUESTS:
    '/free-agent/:clientId/interview-prep-requests',
  CLIENT_CONFIG_JOBS: '/free-agent/:clientId/jobs',
  CLIENT_JOB_HISTORY: '/free-agent/:clientId/jobs/history',
  CLIENT_APPLICATION_ANSWERS: '/free-agent/:clientId/application-answers',
  SOURCING_STATS: '/sourcing/stats',
  SOURCING_REVIEW: '/sourcing/sourcing-review',
  SOURCER_REVIEW: '/sourcing/sourcer-review',
  AUTO_SOURCING_DASHBOARD: '/sourcing/auto-sourcing',
  SOURCER_STATS: '/sourcing/sourcers',
  MY_SOURCING_STATS: '/sourcer/:sourcerId/stats',
  NEW_DOSSIER_REQUEST: '/dossier-request/new',
  NEW_DOSSIER_UPDATE_REQUEST:
    '/dossier-submission/:dossierSubmissionId/update-request',
  EDIT_DOSSIER_REQUEST: '/dossier-request/:dossierRequestId/edit',
  AVAILABLE_DOSSIERS: '/dossier-requests/available',
  ASSIGNED_DOSSIERS: '/dossier-requests/assigned',
  DOSSIER_QUEUE: '/dossier-writer/:dossierWriterId/dossier-request/:requestId',
  DOSSIER_QUEUE_BY_FREE_AGENT: '/free-agent/:freeAgentId/dossier-request/:requestId',
  DOSSIER_ASSIGNMENT_QUEUES:
    '/:type(dossier-writer|free-agent)/:typeId/dossier-request/:requestId',
  REPORT_APPLICATION_INFO: '/application-info/:applicationInfoId/report',
  REPORT_INTERVIEW_TIME: '/interview/:interviewPublicId/report-time',
  ADD_INTERIVEW_FORM: '/interview/new',
  EDIT_INTERIVEW_FORM: '/interview/:interviewId/edit',
  ADD_INTERVIEW_DEBRIEF: '/interview/:interviewPublicId/debrief/new',
  INTERVIEW_DEBRIEF: '/interview-debrief/:interviewDebriefId',
  INTERVIEW_PREP_REQUESTS: '/interview-prep-requests',
  INTERVIEW_PREP_REQUEST_DETAIL: '/interview-prep-requests/:interviewPrepRequestId',
  CLOSE_MESSAGE: '/close',
  REVIEW_DOSSIERS: '/dossiers/review',
  DOSSIER_LIBRARY: '/dossiers/library',
  CONTRACTS: '/contracts',
  NEW_INTERVIEW_PREP_REQUEST: '/interview-prep-request/new',
  NEW_EXPERT_REQUEST: '/expert-request/new',
  EXPERT_REQUESTS: '/expert-requests',
  EXPERT_REQUEST_DETAIL: '/expert-requests/:expertRequestId',
  USERS: '/users',
  CLIENTS: '/clients',
  EXPERT_NETWORK: '/expert-network',
  CLIENT_STATUS: '/client-status',
  DOSSIER_FEEDBACK: '/dossiers/feedback',
  COMPANY_DETAIL: '/company/:companyId',
  COMPANY_SEARCH: '/companies',
  COMPANY_MERGE: '/companies/merge/:companyId',
  TALENT_ENABLEMENT_EDITOR_STATS: '/talent-enablement/stats',
  TALENT_AGENTS: '/talent-agents',
  TALENT_AGENT_STATS: '/talent-agents/:talentAgentId/stats',
  TALENT_AGENTS_DASHBOARD: '/talent-agents/:talentAgentId/dashboard',
  WORK_DASHBOARD: '/work-dashboard/',
}

export default ROUTE_PATHS
