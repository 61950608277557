import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import InterviewPrepRequestsTable from './InterviewPrepRequestsTable'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function InterviewPrepRequests(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt bold as="h2" size={24}>
            Interview Prep Requests
          </Txt>
        </Padding>
      </Container>
      <InterviewPrepRequestsTable />
    </ViewBox>
  )
}

InterviewPrepRequests.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.INTERVIEW_PREP_REQUESTS}
    key={ROUTE_PATHS.INTERVIEW_PREP_REQUESTS}
  >
    <InterviewPrepRequests />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.INTERVIEW_PREP_REQUEST_DETAIL}
    key={ROUTE_PATHS.INTERVIEW_PREP_REQUEST_DETAIL}
  >
    <InterviewPrepRequests />
  </PrivateRoute>,
]
