import React from 'react'
import ExternalLink from 'components/ExternalLink'
import HList from 'components/HList'
import { FileDownload, Maybe } from 'generated/graphql'

type Props = {
  resumeUrl: string | null | undefined
  resumes?: FileDownload[] | null | undefined
  linkedinUrl: string | null | undefined
  huntrUrl?: string | null
  questionnaireUrl?: string | null
  jobPrefsEditUrl?: Maybe<string>
}

export default function WorkInfoLinks({
  questionnaireUrl,
  resumeUrl,
  huntrUrl,
  resumes,
  linkedinUrl,
  jobPrefsEditUrl,
}: Props): JSX.Element | null {
  const showLinkList = resumeUrl || linkedinUrl || resumes
  const showResumes = resumes && resumes.length > 1
  if (!showLinkList) {
    return null
  }
  return (
    <HList size={2}>
      {linkedinUrl && <ExternalLink url={linkedinUrl} label="LinkedIn" />}
      {huntrUrl && <ExternalLink url={huntrUrl} label="Huntr" />}
      {questionnaireUrl && (
        <ExternalLink url={questionnaireUrl} label="Questionnaire" />
      )}
      {jobPrefsEditUrl && (
        <ExternalLink url={jobPrefsEditUrl} label="Edit Job Prefs" />
      )}
      {showResumes
        ? resumes.map(({ url, filename }: FileDownload) => (
            <ExternalLink
              key={url}
              url={url}
              label={`View Resume (${filename ?? ''})`}
            />
          ))
        : resumeUrl && <ExternalLink url={resumeUrl} label="View Resume" />}
    </HList>
  )
}
