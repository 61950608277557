import React from 'react'
import styled from 'styled-components/macro'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  content: React.ReactNode
}

const StyledTableHeaderItem = styled(Padding)`
  text-align: left;
`

function TableHeaderItem({ content }: Props): JSX.Element {
  return (
    <StyledTableHeaderItem as="th" vertical={1} left={1} right={4}>
      <Txt as="span" size={12} color="text" bold>
        {content}
      </Txt>
    </StyledTableHeaderItem>
  )
}

export default TableHeaderItem
