import React from 'react'
import styled from 'styled-components/macro'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  message: string
}

const TableEmptyBox = styled.tfoot`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

export default function TableEmpty({ message }: Props): JSX.Element {
  return (
    <TableEmptyBox>
      <tr>
        {/* colSpan set at a high number ensures it will span all columns */}
        <td colSpan={100}>
          <Flex justify="center">
            <Padding vertical={3}>
              <Txt>{message}</Txt>
            </Padding>
          </Flex>
        </td>
      </tr>
    </TableEmptyBox>
  )
}
