import { ColumnHeader } from 'types'

export const PAGE_SIZE = 50

export const columns: ColumnHeader[] = [
  {
    label: 'Submitted',
    id: 'submitted',
  },
  {
    label: 'Due',
    id: 'due',
  },
  {
    label: 'Status',
    id: 'status',
  },
  {
    label: 'Expert',
    id: 'expert',
  },
  {
    label: 'Interview Cycle',
    id: 'interviewCycle',
  },
  {
    label: '',
    id: 'close',
    collapse: true,
  },
]

export const COPY = {
  CANCEL_SUCCESS: 'Expert request has been canceled',
  CANCEL_ERROR: 'There was an error canceling the expert request',
  CONFIRM_CANCEL: 'Are you sure you want to cancel this expert request?',
}
