import { CACHE_STRING } from 'utils/cacheString'
import ROUTE_PATHS from 'utils/routePaths'

export const ASSIGN_DATA = {
  header: 'Available Dossiers',
  route: ROUTE_PATHS.AVAILABLE_DOSSIERS,
}
export const REASSIGN_DATA = {
  header: 'Assigned Dossiers',
  route: ROUTE_PATHS.ASSIGNED_DOSSIERS,
}

export const COLUMNS = [
  {
    name: '',
    id: 'checkbox',
    collapse: true,
  },
  {
    name: 'Free Agent',
    id: 'freeAgent',
    hideOnAvailable: true,
  },
  {
    name: 'Job Title',
    id: 'jobTitle',
  },
  {
    name: 'Company',
    id: 'company',
  },
  {
    name: 'Type',
    id: 'dossierType',
  },
  {
    name: 'Prior Assignee',
    id: 'priorAssignee',
  },
  {
    name: 'Due Date',
    id: 'due',
    collapse: true,
  },
  {
    name: 'Submission Date',
    id: 'submittedDate',
    hideOnAssigned: true,
  },
  {
    name: 'Assignment',
    id: 'assignment',
    collapse: true,
  },
]

export const COPY = {
  VOID_SUCCESS: 'Dossier request voided',
  VOID_ERROR: 'There was an error voiding the dossier request',
  CONFIRM_VOID: 'Are you sure you want to void this dossier request?',
}

export const CACHE_STRINGS = [
  CACHE_STRING.DOSSIER_REQUEST_ASSIGNED,
  CACHE_STRING.DOSSIER_REQUEST_UNASSIGNED,
]
