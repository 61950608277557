import React from 'react'
import Button from 'components/Button'

export default function WriterTableNav(): JSX.Element {
  return (
    <Button $type="link" active>
      My Taglines
    </Button>
  )
}
