import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DATE_FORMAT, EMPTY_INPUT, TZ_OPTIONS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { DatetimeTzRangeFilter } from 'utils/helpers/getCreatedAtRangeFilterTz'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { SOURCING_REVIEW_MAX_DAYS } from 'utils/settings'
import { DEFAULT_TZ, INITIAL_RANGE, TOAST } from './SourcingReview.constants'
import { buildTableData, getQueryVariables } from './SourcingReview.helpers'
import { PeopleFilter } from './SourcingReview.types'
import SourcingReviewExport from './SourcingReviewExport'
import SourcingReviewTable from './SourcingReviewTable'
import Container from 'components/Container'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { DatePicker, Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetSourcingReviewFilterInfoQuery,
  GetSourcingReviewQuery,
  GetSourcingReviewQueryVariables,
} from 'generated/graphql'

function SourcingReview(): React.ReactElement {
  const [filter, setFilter] = useState<DatetimeTzRangeFilter>({
    start: dayjs().subtract(INITIAL_RANGE, 'days'),
    end: dayjs(),
    timezone: DEFAULT_TZ,
  })

  const [peopleFilter, setPeopleFilter] = useState<PeopleFilter>({})

  const { data, loading, refetch } = useQuery<
    GetSourcingReviewQuery,
    GetSourcingReviewQueryVariables
  >(GET_SOURCING_REVIEW, {
    variables: getQueryVariables(filter, peopleFilter),
  })

  const { data: filterData } = useQuery<GetSourcingReviewFilterInfoQuery>(
    GET_SOURCING_REVIEW_FILTER_INFO
  )
  const talentAgentOptions = buildDropdownOptions(filterData?.talentAgents || [])
  const sourcerOptions = buildDropdownOptions(filterData?.sourcers || [])
  const clientOptions = buildDropdownOptions(
    filterData?.clients ? getNodes(filterData.clients) : []
  )

  /**
   *  sets the new fromDate filter
   * @param value value of DatePicker change
   */
  function handleDateFilterChange(value: string, prop: keyof DatetimeTzRangeFilter) {
    if (!value) {
      return
    }
    const newValue = prop === 'timezone' ? value : dayjs(value)
    let newFilter = { ...filter, [prop]: newValue }
    const dayDiff = newFilter.end.diff(newFilter.start, 'days')

    if (dayDiff > SOURCING_REVIEW_MAX_DAYS || dayDiff < 0) {
      if (prop === 'start') {
        toast(TOAST.UPDATE_END)
        newFilter = {
          ...newFilter,
          end: newFilter.start.add(SOURCING_REVIEW_MAX_DAYS, 'days'),
        }
      }
      if (prop === 'end') {
        toast(TOAST.UPDATE_START)
        newFilter = {
          ...newFilter,
          start: newFilter.end.subtract(SOURCING_REVIEW_MAX_DAYS, 'days'),
        }
      }
    }
    setFilter(newFilter)
    void refetch(getQueryVariables(newFilter, peopleFilter))
  }

  /**
   * update state when new filter option is selected
   * @param value new value selected from dropdown
   * @param prop piece of state we are editing
   */
  function handlePeopleFilterChange(value: string | null, prop: keyof PeopleFilter) {
    const newPeopleFilter = { ...peopleFilter, [prop]: value }
    setPeopleFilter(newPeopleFilter)
    void refetch(getQueryVariables(filter, newPeopleFilter))
  }

  const sourcedJobs =
    data?.sourcedJobs && buildTableData(data.sourcedJobs, filter.timezone)

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Flex justify="space-between" align="center">
            <Txt size={24} as="h2" bold>
              Sourcing Review
            </Txt>
            <SourcingReviewExport sourcedJobs={sourcedJobs} filter={filter} />
          </Flex>
        </Padding>
        <Padding vertical={4}>
          <HList size={2} rowGapSize={2}>
            <Dropdown
              value={filter.timezone}
              onValueChange={(value) => handleDateFilterChange(value, 'timezone')}
              options={TZ_OPTIONS}
              withEmptyOption={false}
              label="Timezone"
            />
            <DatePicker
              label="Start Date"
              value={filter.start.format(DATE_FORMAT.ONLY_DATE)}
              onValueChange={(value) => handleDateFilterChange(value, 'start')}
            />
            <DatePicker
              label="End Date"
              value={filter.end.format(DATE_FORMAT.ONLY_DATE)}
              onValueChange={(value) => handleDateFilterChange(value, 'end')}
            />
          </HList>
          <Padding top={3}>
            <HList size={2}>
              <Dropdown
                label="Talent Agent"
                name="talentAgent"
                value={peopleFilter.talentAgentId ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  handlePeopleFilterChange(value || null, 'talentAgentId')
                }
                options={talentAgentOptions}
                withEmptyOption
                minWidth={150}
              />
              <Dropdown
                label="Sourcer"
                name="createdBy"
                value={peopleFilter.createdById ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  handlePeopleFilterChange(value || null, 'createdById')
                }
                options={sourcerOptions}
                withEmptyOption
                minWidth={150}
              />
              <Dropdown
                label="Free Agent"
                name="client"
                value={peopleFilter.clientId ?? EMPTY_INPUT}
                onValueChange={(value) =>
                  handlePeopleFilterChange(value || null, 'clientId')
                }
                options={clientOptions}
                withEmptyOption
                minWidth={150}
              />
            </HList>
          </Padding>
        </Padding>
      </Container>
      <SourcingReviewTable sourcedJobs={sourcedJobs} />
      {loading && <Message message="Loading review" vertical />}
      {!loading && !sourcedJobs?.length && (
        <Message message="No sourcing found on this day" vertical />
      )}
    </ViewBox>
  )
}
SourcingReview.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.SOURCING_REVIEW}
    key={ROUTE_PATHS.SOURCING_REVIEW}
  >
    <SourcingReview />
  </PrivateRoute>,
]
export default SourcingReview

const GET_SOURCING_REVIEW = gql`
  query GetSourcingReview(
    $createdAtRangeFilter: DatetimeRangeFilter!
    $talentAgentId: ID
    $createdById: ID
    $clientId: ID
  ) {
    sourcedJobs(
      createdAtRangeFilter: $createdAtRangeFilter
      talentAgentId: $talentAgentId
      createdById: $createdById
      clientId: $clientId
    ) {
      edges {
        node {
          id
          company: companyEntity {
            name
          }
          url
          title
          createdBy {
            id
            name
          }
          createdAt
          sourcingRequest {
            client {
              id
              name
            }
          }
          reviews {
            id
            rejectionReason
            rating
            reviewNotes
          }
          redactions {
            id
          }
        }
      }
    }
  }
`

const GET_SOURCING_REVIEW_FILTER_INFO = gql`
  query GetSourcingReviewFilterInfo {
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
    }
    sourcers: users(anyRole: { isSourcer: true }) {
      id
      name
    }
    clients {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
