import { gql } from '@apollo/client'
import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  SourcingRequest,
  SourcingRequestByAssignee,
  SourcingRequestByClient,
  SourcingRequestConnection,
  RowData,
  SourcingRequestWithStats,
} from './SourcerReview.types'

/**
 * takes in faw sourcing request connection and groups by sourcer than by FA sorting alphabetically by names
 * @param sourcingRequestConnection
 * @returns array of row data
 */
export function getGroupedSourcerData(
  sourcingRequestConnection: SourcingRequestConnection
): RowData[] {
  const rawSourcingRequests = getNodes<SourcingRequest>(sourcingRequestConnection)
  const sourcingRequestsWithStats: SourcingRequestWithStats[] =
    addStatsToSourcingRequest(rawSourcingRequests)
  const sourcingRequestsByAssignee = Object.values(
    groupRequestsByAssignee(sourcingRequestsWithStats)
  ).sort((a, b) => sortByProp(a, b, 'assigneeName'))

  const tableData = sourcingRequestsByAssignee.map((group) => ({
    ...group,
    requests: Object.values(groupRequestsByClient(group.requests)).sort((a, b) =>
      sortByProp(a, b, 'clientName')
    ),
  }))

  return tableData
}

getGroupedSourcerData.fragments = {
  tableData: gql`
    fragment SourcerReviewTableData on SourcingRequest {
      id
      numJobs
      assignee {
        id
        name
      }
      client {
        id
        name
      }
      sourcedJobs {
        id
        reviews {
          id
          rejectionReason
        }
      }
    }
  `,
}

function addStatsToSourcingRequest(
  sourcingRequests: SourcingRequest[]
): SourcingRequestWithStats[] {
  return sourcingRequests.map((request) => {
    const sourcingsRequested = request.numJobs
    const sourcingsSubmitted = request.sourcedJobs.length
    let sourcingsRejected = 0
    let sourcingsApproved = 0

    request.sourcedJobs.forEach((job) => {
      const review = job.reviews[0]
      if (review) {
        if (review.rejectionReason) {
          sourcingsRejected += 1
        } else {
          sourcingsApproved += 1
        }
      }
    })

    return {
      ...request,
      sourcingsRequested,
      sourcingsSubmitted,
      sourcingsRejected,
      sourcingsApproved,
    }
  })
}

function groupRequestsByAssignee(
  sourcingRequests: SourcingRequestWithStats[]
): KeyValue<SourcingRequestByAssignee> {
  return sourcingRequests.reduce(
    (groups: KeyValue<SourcingRequestByAssignee>, request) => {
      if (!request.assignee) {
        return groups
      }
      const group = groups[request.assignee.id]

      if (group) {
        group.requests.push(request)
        group.sourcingsRequested += request.sourcingsRequested
        group.sourcingsSubmitted += request.sourcingsSubmitted
        group.sourcingsRejected += request.sourcingsRejected
        group.sourcingsApproved += request.sourcingsApproved
      } else {
        groups[request.assignee.id] = {
          assigneeId: request.assignee.id,
          assigneeName: request.assignee.name,
          sourcingsRequested: request.sourcingsRequested,
          sourcingsSubmitted: request.sourcingsSubmitted,
          sourcingsRejected: request.sourcingsRejected,
          sourcingsApproved: request.sourcingsApproved,
          requests: [request],
        }
      }
      return groups
    },
    {}
  )
}

function groupRequestsByClient(
  sourcingRequests: SourcingRequestWithStats[]
): KeyValue<SourcingRequestByClient> {
  return sourcingRequests.reduce(
    (groups: KeyValue<SourcingRequestByClient>, request) => {
      const group = groups[request.client.id]

      if (group) {
        group.requests.push(request)
        group.sourcingsRequested += request.sourcingsRequested
        group.sourcingsSubmitted += request.sourcingsSubmitted
        group.sourcingsRejected += request.sourcingsRejected
        group.sourcingsApproved += request.sourcingsApproved
      } else {
        groups[request.client.id] = {
          clientId: request.client.id,
          clientName: request.client.name,
          sourcingsRequested: request.sourcingsRequested,
          sourcingsSubmitted: request.sourcingsSubmitted,
          sourcingsRejected: request.sourcingsRejected,
          sourcingsApproved: request.sourcingsApproved,

          requests: [request],
        }
      }
      return groups
    },
    {}
  )
}
