import styled from 'styled-components/macro'

export const QueueBox = styled.div`
  position: relative;
`

export const QueueCount = styled.div`
  align-items: center;
  background: ${({ theme }) => theme.color.faGrey1};
  border-radius: 50%;
  border: 0.1rem solid ${({ theme }) => theme.color.faGrey2};
  display: flex;
  height: ${({ theme }) => theme.rhythm(2.5)};
  justify-content: center;
  position: absolute;
  right: ${({ theme }) => theme.rhythm(-1)};
  top: ${({ theme }) => theme.rhythm(-1)};
  width: ${({ theme }) => theme.rhythm(2.5)};
`
