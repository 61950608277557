import React from 'react'
import { Undefinable } from 'types'
import { useNavContext } from 'context/nav'
import { NavStatus } from 'utils/enums'
import Icon from 'components/Icon/Icon'
import { SearchNavButton } from 'components/Search/Search'
import { QueueBox, QueueCount } from './CollapsedNav.styles'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  sourcerQueueCount: Undefinable<number>
  applierQueueCount: Undefinable<number>
  writerQueueCount: Undefinable<number>
  reviewFeedbackQueueCount: Undefinable<number>
  talentAgentReviewQueueCount: Undefinable<number>
  talentPartnerReviewQueueCount: Undefinable<number>
  dossierQueueCount: Undefinable<number>
  enrichmentQueueCount: Undefinable<number>
  sourcedJobsReviewQueueCount: Undefinable<number>
}

export default function CollapsedNavContent({
  sourcerQueueCount,
  applierQueueCount,
  writerQueueCount,
  reviewFeedbackQueueCount,
  talentAgentReviewQueueCount,
  talentPartnerReviewQueueCount,
  dossierQueueCount,
  enrichmentQueueCount,
  sourcedJobsReviewQueueCount,
}: Props): JSX.Element {
  const { status, setStatus } = useNavContext()
  const isCollapsed = status === NavStatus.Collapsed

  /**
   * Sums all queue totals
   */
  function getTotalQueueItems() {
    const total =
      (sourcerQueueCount || 0) +
      (applierQueueCount || 0) +
      (writerQueueCount || 0) +
      (reviewFeedbackQueueCount || 0) +
      (talentAgentReviewQueueCount || 0) +
      (talentPartnerReviewQueueCount || 0) +
      (dossierQueueCount || 0) +
      (enrichmentQueueCount || 0) +
      (sourcedJobsReviewQueueCount || 0)

    return total
  }

  return (
    <div>
      <Flex justify="center" column>
        <button
          onClick={() =>
            setStatus?.(isCollapsed ? NavStatus.Open : NavStatus.Collapsed)
          }
        >
          <QueueBox title="Total Work">
            <Icon
              name="Queue"
              width={28}
              height={28}
              primaryFill="text"
              primaryFillHover="textDark"
            />
            <QueueCount>
              <Txt size={12} bold>
                {getTotalQueueItems()}
              </Txt>
            </QueueCount>
          </QueueBox>
        </button>
        <Padding top={4}>
          <SearchNavButton collapsed />
        </Padding>
      </Flex>
    </div>
  )
}
