import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs, { Dayjs } from 'dayjs'
import { MaybeConnectionNode, KeyValue, Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { maybeGetNodes } from 'utils/helpers/getNodes'
import getPodId from 'utils/helpers/getPodId'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { IR_POD_COUNT } from 'utils/settings'
import { sortByProp } from 'utils/sort'
import {
  TalentAgentQueueQuery,
  TalentAgentQueueQueryVariables,
} from 'generated/graphql'

type QueueReturn = {
  queueByViewer: string[]
  loading: boolean
  queueByClient: KeyValue<string[]>
  queueByPod: KeyValue<string[]>
}

type CountReturn = {
  loading: boolean
  totalCount: Undefinable<number>
}

type TaglineRequestRaw = MaybeConnectionNode<
  NonNullable<TalentAgentQueueQuery['taglineRequests']>
>

type TaglineRequest = TaglineRequestRaw & {
  podId: number
  writerSubmissionCreatedAtDayJs: Dayjs
  clientId: string
}

type GroupedTaglineRequests = KeyValue<TaglineRequest[]>

export default function useTalentAgentQueue(
  queryOptions: QueryHookOptions<
    TalentAgentQueueQuery,
    TalentAgentQueueQueryVariables
  > = {},
  fetchAll?: boolean
): QueueReturn {
  const {
    userSession: {
      user: { id: talentAgentId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<
    TalentAgentQueueQuery,
    TalentAgentQueueQueryVariables
  >(GET_TALENT_AGENT_QUEUE, {
    ...queryOptions,
    variables: {
      talentAgentId: fetchAll ? undefined : talentAgentId,
      onlyCount: false,
    },
  })

  const nodes = data?.taglineRequests
    ? maybeGetNodes(data.taglineRequests) ?? []
    : []

  // add all needed props for grouping/sorting
  const taglineRequests = normalizeTaglineRequests(nodes)

  // map client/pod ids to array of tagline requests
  const [groupedByClient, groupedByPod] = groupTaglineRequests(taglineRequests)

  // sort groups according to their first element's writer submission date
  const sortedTaglinesByClient = Object.values(groupedByClient).sort((a, b) =>
    sortByProp(a[0], b[0], 'writerSubmissionCreatedAtDayJs')
  )

  // sort the pods items by due date
  const sortedTaglinesByPod = Object.values(groupedByPod)
  sortedTaglinesByPod.forEach((pod) =>
    pod.sort((a, b) => sortByProp(a, b, 'dueDayJs'))
  )

  // build the full queue (with all of the work)
  const queueByViewer = sortedTaglinesByClient
    .flat()
    .sort((a, b) => sortByProp(a, b, 'writerSubmissionCreatedAtDayJs'))
    .map(reduceObjectToId)

  // build queue map {[clientId]: taglineRequestId[]}
  const queueByClient = sortedTaglinesByClient.reduce(
    (finalObject, clientRequests) =>
      clientRequests[0]
        ? {
            ...finalObject,
            [clientRequests[0].clientId]: clientRequests.map(reduceObjectToId),
          }
        : finalObject,
    {}
  )

  // build queue map {[podId]: taglineRequestId[]}
  const queueByPod = sortedTaglinesByPod.reduce(
    (finalObject, podRequests) =>
      podRequests[0]
        ? {
            ...finalObject,
            [podRequests[0].podId]: podRequests.map(reduceObjectToId),
          }
        : finalObject,
    {}
  )

  return { queueByViewer, queueByClient, queueByPod, loading }
}

export function useTalentAgentQueueCount(
  queryOptions: QueryHookOptions<
    TalentAgentQueueQuery,
    TalentAgentQueueQueryVariables
  > = {},
  fetchAll?: boolean
): CountReturn {
  const {
    userSession: {
      user: { id: talentAgentId },
    },
  } = useAuthContext()

  const { data, loading } = useQuery<
    TalentAgentQueueQuery,
    TalentAgentQueueQueryVariables
  >(GET_TALENT_AGENT_QUEUE, {
    ...queryOptions,
    variables: {
      talentAgentId: fetchAll ? undefined : talentAgentId,
      onlyCount: true,
    },
  })

  return { loading, totalCount: data?.taglineRequests?.totalCount ?? undefined }
}

function normalizeTaglineRequests(requests: TaglineRequestRaw[]): TaglineRequest[] {
  return requests
    .map((request) => ({
      ...request,
      // IR pods are based on the TaglineRequest's ID
      podId: getPodId(request.id, IR_POD_COUNT),
      clientId: request.client.id,
      writerSubmissionCreatedAtDayJs: dayjs(request.writerSubmissions[0]?.createdAt),
      dueDayJs: dayjs(request.due),
    }))
    .sort((a, b) => sortByProp(a, b, 'writerSubmissionCreatedAtDayJs'))
}

function groupTaglineRequests(
  taglineRequests: TaglineRequest[]
): [GroupedTaglineRequests, GroupedTaglineRequests] {
  return taglineRequests.reduce(
    (
      [clientMap, podMap]: [GroupedTaglineRequests, GroupedTaglineRequests],
      taglineRequest
    ) => {
      // client & pod map keys
      const clientId = taglineRequest.clientId
      const podId = taglineRequest.podId

      // build the client map
      const clientEntry = clientMap[clientId]
      if (clientEntry) {
        clientEntry.push(taglineRequest)
      } else {
        clientMap[clientId] = [taglineRequest]
      }

      // build the pod map
      const podEntry = podMap[podId]
      if (podEntry) {
        podEntry.push(taglineRequest)
      } else {
        podMap[podId] = [taglineRequest]
      }
      return [clientMap, podMap]
    },
    [{}, {}]
  )
}

/**
 * used by talent agents to queue through all of their assigned requests
 */
const GET_TALENT_AGENT_QUEUE = gql`
  query TalentAgentQueue($talentAgentId: ID, $onlyCount: Boolean!) {
    taglineRequests(talentAgentId: $talentAgentId, status: PENDING_TA_REVIEW) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          due
          writerSubmissions {
            createdAt
          }
          client {
            id
            name
          }
        }
      }
    }
  }
`
