import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { COLUMNS } from './SourcingStats.constants'
import { Client } from './SourcingStats.types'
import SourcingStatsRow from './SourcingStatsRow'
import Message from 'components/Message'
import Txt from 'components/Txt'

type Props = {
  clients: Client[] | undefined | null
  loading: boolean
}

export default function SourcingStatsTable({
  clients,
  loading,
}: Props): React.ReactElement {
  return (
    <>
      <TableBox>
        <TableWrapper>
          <Table>
            <thead>
              <HeaderRow>
                {COLUMNS.map((column) => (
                  <HeaderData key={column.id}>
                    <Txt size={12} bold>
                      {column.label}
                    </Txt>
                  </HeaderData>
                ))}
              </HeaderRow>
            </thead>
            <tbody>
              {clients?.map((client) => {
                return <SourcingStatsRow key={client.id} client={client} />
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </TableBox>
      {loading && <Message message="Loading sourcing stats..." vertical />}
      {!loading && !clients?.length && (
        <Message message="No sourcing stats found" vertical />
      )}
    </>
  )
}
