import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { CompanySizeMap } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import {
  LogoContainer,
  FakeAvatar,
  LogoImage,
} from 'views/ClientJobs/ClientJobs.styles'
import Icon from 'components/Icon/Icon'
import { Company, Params } from './CompanyDetail.types'
import CompanyDetailContent from './CompanyDetailContent'
import Container from 'components/Container'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import LoadingIndicator from 'components/LoadingIndicator'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { GetCompanyInfoQuery, GetCompanyInfoQueryVariables } from 'generated/graphql'

type Props = {
  company: Company
}

function CompanyDetail({ company }: Props): React.ReactElement {
  const companyDetails = [
    company.location,
    company.size ? `${CompanySizeMap[company.size]} employees` : undefined,
    company.companyType?.toLowerCase(),
    company.yearFounded ? `Est. ${company.yearFounded}` : undefined,
  ]
  const companyDetailsFormatted = companyDetails
    .filter((detail) => Boolean(detail))
    .join(' | ')
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Flex align="center" justify="space-between">
            <div>
              <Padding bottom={2}>
                <Txt size={24} bold as="h2" style={{ display: 'inline' }}>
                  {company.name}
                </Txt>
                {company.domain && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.domain}>
                      <Icon
                        name="Globe"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="link"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.linkedinUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.linkedinUrl}>
                      <Icon
                        name="Linkedin"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="linkedinBlue"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.glassdoorUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.glassdoorUrl}>
                      <Icon
                        name="Glassdoor"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="glassdoorGreen"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.crunchbaseUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.crunchbaseUrl}>
                      <Icon
                        name="Crunchbase"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="black"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
                {company.angellistUrl && (
                  <Padding inline left={1}>
                    <ExternalLink url={company.angellistUrl}>
                      <Icon
                        name="AngelList"
                        height={24}
                        width={24}
                        primaryFill="faGrey2"
                        primaryFillHover="black"
                        nudge={{ down: 0.5 }}
                      />
                    </ExternalLink>
                  </Padding>
                )}
              </Padding>
              <Txt capitalize as="span">
                {companyDetailsFormatted}
              </Txt>
            </div>
            <div>
              {company.logoUrl ? (
                <LogoContainer>
                  <LogoImage src={company.logoUrl} alt={company.name} />
                </LogoContainer>
              ) : (
                <FakeAvatar>
                  <Icon
                    name="FreeAgencyFlag"
                    height={48}
                    width={48}
                    primaryFill="faGrey2"
                  />
                </FakeAvatar>
              )}
            </div>
          </Flex>
        </Padding>
      </Container>
      <CompanyDetailContent company={company} />
    </ViewBox>
  )
}

export default function CompanyDetailWrapper(): React.ReactElement {
  const { companyId } = useParams<Params>()
  const { data, loading, error } = useQuery<
    GetCompanyInfoQuery,
    GetCompanyInfoQueryVariables
  >(GET_COMPANY_INFO, { variables: { id: companyId } })
  return (
    <ViewBox>
      {loading && <LoadingIndicator />}
      {error && (
        <Message
          vertical
          message="There was an error retrieving the company data."
        />
      )}
      {!error && !loading && data?.company && (
        <CompanyDetail company={data.company} />
      )}
    </ViewBox>
  )
}

CompanyDetailWrapper.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.COMPANY_DETAIL}
    key={ROUTE_PATHS.COMPANY_DETAIL}
  >
    <CompanyDetailWrapper />
  </PrivateRoute>,
]

const GET_COMPANY_INFO = gql`
  query GetCompanyInfo($id: ID!) {
    company(id: $id) {
      id
      name
      domain
      logoUrl
      linkedinUrl
      glassdoorUrl
      crunchbaseUrl
      angellistUrl
      employeeCount
      size
      yearFounded
      location
      companyType
      companyDescription
      experts {
        totalCount
      }
      applications {
        totalCount
      }
      enrichmentSubmissions {
        totalCount
      }
      dossierSubmissions {
        totalCount
      }
      interviewCycles {
        totalCount
      }
      sourcedJobs {
        totalCount
      }
    }
  }
`
