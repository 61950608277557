import { DropdownGroup } from 'types'
import { SourcedJobRejectionReason } from 'utils/enums'

export type DateFilter = {
  date: string
  timezone: string
}

export const redactColumns = [
  { label: 'Company', id: 'company' },
  { label: 'Job Title', id: 'jobTitle' },
  { label: 'Job Description', id: 'jobDescription' },
  { label: 'Sourced By', id: 'sourcedBy' },
  { label: '', id: 'redact', collapse: true },
]

export const TOAST = {
  REDACT_SUCCESS: 'Job Redacted',
  REDACT_ERROR: 'There was an error redacting the job',
}

export const rejectionOptions: DropdownGroup<SourcedJobRejectionReason>[] = [
  {
    groupLabel: 'Company Fit',
    options: [
      {
        value: SourcedJobRejectionReason.COMPANY_BRAND,
        label: 'Brand / Reputation',
      },
      {
        value: SourcedJobRejectionReason.COMPANY_INDUSTRY,
        label: 'Industry / Vertical',
      },
      {
        value: SourcedJobRejectionReason.COMPANY_WRONG_STAGE,
        label: 'Stage / Maturity',
      },
      {
        value: SourcedJobRejectionReason.COMPANY_FIT_OTHER,
        label: 'Other',
      },
    ],
  },
  {
    groupLabel: 'Role Mismatch',
    options: [
      {
        value: SourcedJobRejectionReason.TOO_SENIOR,
        label: 'Too Senior',
      },
      {
        value: SourcedJobRejectionReason.TOO_JUNIOR,
        label: 'Too Junior',
      },
      {
        value: SourcedJobRejectionReason.WRONG_POSITION,
        label: 'Wrong Position',
      },
    ],
  },
  {
    groupLabel: 'Other',
    options: [
      {
        value: SourcedJobRejectionReason.ALREADY_SOURCED_APPLIED,
        label: 'Already Sourced / Applied',
      },
      {
        value: SourcedJobRejectionReason.JOB_CLOSED,
        label: 'Job Closed',
      },
      {
        value: SourcedJobRejectionReason.WRONG_LOCATION,
        label: 'Wrong Location',
      },
      {
        value: SourcedJobRejectionReason.OTHER,
        label: 'Other',
      },
    ],
  },
]

export const ratingRequired = [
  SourcedJobRejectionReason.WRONG_LOCATION,
  SourcedJobRejectionReason.TOO_SENIOR,
  SourcedJobRejectionReason.TOO_JUNIOR,
  SourcedJobRejectionReason.WRONG_POSITION,
  SourcedJobRejectionReason.COMPANY_FIT_OTHER,
  SourcedJobRejectionReason.COMPANY_BRAND,
  SourcedJobRejectionReason.COMPANY_INDUSTRY,
  SourcedJobRejectionReason.COMPANY_WRONG_STAGE,
]

export const notesRequired = [
  SourcedJobRejectionReason.COMPANY_FIT_OTHER,
  SourcedJobRejectionReason.OTHER,
]
