import styled from 'styled-components/macro'

export const CompanyMergeResultButton = styled.button`
  background-color: ${({ theme }) => theme.color.faGrey1};
  display: block;
  width: 100%;
  border-radius: ${({ theme }) => theme.borderRadius};

  &:hover {
    background-color: ${({ theme }) => theme.color.blueHighlight};
  }
`
