import React from 'react'
import TableBodyItem from 'components/Table/TableBodyItem'
import { GroupedDossierRequestsByDate } from './DossierTable.types'

type Props = {
  rowData: GroupedDossierRequestsByDate
}

export default function DossierTableDueRow({ rowData }: Props): React.ReactElement {
  return (
    <tr key={rowData.dateAndTime}>
      <TableBodyItem first content={rowData.dateAndTime} />
      <TableBodyItem content={rowData.dossierRequests.length} />
    </tr>
  )
}
