/* eslint-disable import/no-named-as-default-member */
import dayjs from 'dayjs'
import advancedFormat from 'dayjs/plugin/advancedFormat'
import relativeTime from 'dayjs/plugin/relativeTime'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
import React from 'react'
import { render } from 'react-dom'
import { ThemeProvider } from 'styled-components/macro'
import { load } from 'webfontloader'
import { TZ } from 'utils/constants'
import GlobalStyles from 'theme/GlobalStyles'
import theme from 'theme/theme'
import App from 'App'
import * as serviceWorker from 'serviceWorker'
import '@reach/dialog/styles.css'
import '@reach/tooltip/styles.css'

// use plugin that allows us to use relative time formatting on dayjs objects and timezones
// TODO: (matthewalbrecht) make extend named import and address 'no import found' issue
dayjs.extend(relativeTime)
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advancedFormat)

// set default timezone for dayjs dates
// must use .tz() on dayjs object to give default timezone
dayjs.tz.setDefault(TZ.EASTERN)

load({
  google: {
    families: ['DM Sans:400,700', 'DM Serif Display'],
  },
})

render(
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <App />
    </ThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
