import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  DossierSubmissionGroup,
  DossierSubmissionsConnection,
} from './ReviewDossierSubmission.types'

export function normalizeDossierSubmissions(
  connection: DossierSubmissionsConnection
): DossierSubmissionGroup[] {
  const nodes = getNodes(connection)

  const groups = nodes.reduce(
    (map: KeyValue<DossierSubmissionGroup>, dossierSubmission) => {
      const groupValue = dossierSubmission.client.id
      const group = map[groupValue]

      if (group) {
        group.dossierSubmissions.push(dossierSubmission)
      } else {
        map[groupValue] = {
          clientId: dossierSubmission.client.id,
          clientName: dossierSubmission.client.name,
          dossierSubmissions: [dossierSubmission],
        }
      }

      return map
    },
    {}
  )

  return Object.values(groups).sort((a, b) => sortByProp(a, b, 'clientName'))
}
