import { generatePath } from 'react-router'
import { toast } from 'react-toastify'
import { TIME_TRACKER_REGEX } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { State } from './DossierSubmission.types'

/**
 * validates the state values before submitting
 * @param state
 */
export function validateState(state: State): boolean {
  if (!TIME_TRACKER_REGEX.exec(state.timeSpent)) {
    toast.error('Must have valid Time Spent value (h:mm)')
    return false
  }
  if (!state.fileUrl) {
    toast.error('Dossier Link is required')
    return false
  }
  return true
}

type RouteValues = {
  dossierWriterId: string | undefined
  freeAgentId: string | undefined
}

/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextRequestId: string | null,
  { dossierWriterId, freeAgentId }: RouteValues
): string | null {
  const typeId = dossierWriterId || freeAgentId
  if (nextRequestId && typeId) {
    return generatePath(ROUTE_PATHS.DOSSIER_ASSIGNMENT_QUEUES, {
      requestId: nextRequestId,
      typeId,
      type: dossierWriterId ? 'dossier-writer' : 'free-agent',
    })
  }
  return null
}
