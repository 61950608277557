import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { DateFilter, redactColumns } from './RedactSourcedJobs.constants'
import { SourcedJob, TableRowData } from './RedactSourcedJobs.types'
import ReviewSourcedJobsHighlightedRow from './RedactSourcedJobsHighlightedRow'
import ReviewSourcedJobsRedactionRow from './RedactSourcedJobsRedactionRow'
import Message from 'components/Message'
import Txt from 'components/Txt'

type Props = {
  tableData: TableRowData[]
  dateFilter: DateFilter
}

export default function RedactSourcedJobsTable({
  tableData,
  dateFilter,
}: Props): React.ReactElement {
  return (
    <>
      <TableBox>
        <TableWrapper>
          <Table>
            <thead>
              <HeaderRow>
                {redactColumns.map((column) => (
                  <HeaderData key={column.id} collapse={column.collapse}>
                    <Txt size={12} bold>
                      {column.label}
                    </Txt>
                  </HeaderData>
                ))}
              </HeaderRow>
            </thead>
            <tbody>
              {tableData.map((rowData: TableRowData) => {
                return (
                  <React.Fragment key={rowData.clientId}>
                    <ReviewSourcedJobsHighlightedRow
                      key={rowData.clientId}
                      rowData={rowData}
                    />
                    {rowData.sourcedJobs.map((job: SourcedJob) => (
                      <ReviewSourcedJobsRedactionRow
                        key={job.id}
                        rowData={job}
                        dateFilter={dateFilter}
                      />
                    ))}
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </TableBox>
      {!tableData.length && <Message message="No jobs to show" vertical />}
    </>
  )
}
