import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import { Colors } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  InterviewPrepRequestConnection,
  InterviewPrepRequestNode,
  InterviewPrepRequestSortable,
} from './ClientInterviewPreps.types'
import { InterviewPrepRequestStatusInfoFragment } from 'generated/graphql'

export function buildTableData(
  connection: InterviewPrepRequestConnection
): InterviewPrepRequestSortable[] {
  const rawInterviewPrepRequests = getNodes<InterviewPrepRequestNode>(connection)

  const interviewPrepRequests = rawInterviewPrepRequests
    .map((interviewPrepRequest) => ({
      ...interviewPrepRequest,
      dateToSortBy: dayjs(interviewPrepRequest.createdAt),
    }))
    .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))

  return interviewPrepRequests
}

export function getInterviewPrepStatusTagInfo(
  interviewPrepRequest: InterviewPrepRequestStatusInfoFragment
): {
  statusColor: keyof Colors
  statusCopy: string
} {
  if (interviewPrepRequest.airtableId) {
    return { statusColor: 'faGrey2', statusCopy: 'In Airtable' }
  }
  if (interviewPrepRequest.scheduledAt) {
    return { statusColor: 'faGreen', statusCopy: 'Scheduled' }
  }
  return { statusColor: 'yellowLight', statusCopy: 'Requested' }
}

getInterviewPrepStatusTagInfo.fragments = {
  statusInfo: gql`
    fragment InterviewPrepRequestStatusInfo on InterviewPrepRequest {
      scheduledAt
      airtableId
    }
  `,
}
