/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable @typescript-eslint/no-explicit-any */
export default function debounce<T extends (...args: any[]) => any>(
  callback: T,
  waitFor: number
): (...args: Parameters<T>) => ReturnType<T> {
  let timeout: NodeJS.Timeout
  return function (...args: Parameters<T>): ReturnType<T> {
    let result: any
    clearTimeout(timeout)
    timeout = setTimeout(() => {
      result = callback(...args)
    }, waitFor)
    /* TODO (matthewalbrecht): make the types stricter */
    // eslint-disable-next-line @typescript-eslint/no-unsafe-return
    return result
  }
}
