import React from 'react'
import styled from 'styled-components/macro'
import Flex from 'components/Flex'
import Padding from 'components/Padding'

const AssignFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  children: React.ReactNode
}

export default function AssignFooter({ children }: Props): JSX.Element {
  return (
    <AssignFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        {children}
      </Flex>
    </AssignFooterBox>
  )
}
