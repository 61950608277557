import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import {
  DashboardCard,
  StageChangeDashboardContent,
} from 'components/DashboardCard/DashboardCard.styles'
import DashboardCardTitle from 'components/DashboardCard/DashboardCardTitle'
import DashboardStageChangeCard from 'components/DashboardCard/DashboardStageChangeCard'
import { getClientStageChanges } from './ClientDashboardCard.helpers'
import { Params } from './ClientDashboardCard.types'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientStageChangesQuery,
  GetClientStageChangesQueryVariables,
} from 'generated/graphql'

export default function ClientDashboardStageChanges(): React.ReactElement {
  const { clientId } = useParams<Params>()
  const { data, loading } = useQuery<
    GetClientStageChangesQuery,
    GetClientStageChangesQueryVariables
  >(GET_CLIENT_STAGE_CHANGES, {
    variables: { id: clientId },
  })
  const stageChanges = data?.client ? getClientStageChanges(data.client) : []
  return (
    <DashboardCard>
      <DashboardCardTitle title="Stage changes" />
      <StageChangeDashboardContent>
        {loading && <LoadingIndicator />}
        {!loading && !stageChanges.length && (
          <Padding all={3}>
            <Txt>There are no stage changes.</Txt>
          </Padding>
        )}
        {stageChanges.map((stageChangeInfo, index) => (
          <DashboardStageChangeCard
            key={stageChangeInfo.id}
            hasMore={index !== stageChanges.length - 1}
            stageChangeInfo={stageChangeInfo}
            isClientDashboard
          />
        ))}
      </StageChangeDashboardContent>
      <Padding vertical={0.5} />
    </DashboardCard>
  )
}

const GET_CLIENT_STAGE_CHANGES = gql`
  query GetClientStageChanges($id: ID!) {
    client(id: $id) {
      ...ClientStageChanges
    }
  }
  ${getClientStageChanges.fragments.clientStageChanges}
`
