import React from 'react'
import {
  TableBox,
  HeaderData,
  HeaderRow,
  Table,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { columnItems } from './TaglineWorkLog.constants'
import { TaglineSubmission } from './TaglineWorkLog.types'
import TaglineWorkLogRow from './TaglineWorkLogRow'

import Txt from 'components/Txt'
import { TaglineWorkRole } from 'generated/graphql'

type Props = {
  work: TaglineSubmission[]
  userRole: TaglineWorkRole
}

export default function TaglineWorkLogTable({ work, userRole }: Props): JSX.Element {
  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {Object.entries(columnItems).map(([id, column]) =>
                column.userRoleHide?.includes(userRole) ? null : (
                  <HeaderData key={id} collapse={column.collapse}>
                    <Txt size={12} bold>
                      {column.label}
                    </Txt>
                  </HeaderData>
                )
              )}
            </HeaderRow>
          </thead>
          <tbody>
            {work.map((taglineSubmission: TaglineSubmission) => {
              return (
                <TaglineWorkLogRow
                  key={taglineSubmission.id}
                  taglineSubmission={taglineSubmission}
                  userRole={userRole}
                />
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </TableBox>
  )
}
