import React, { useMemo } from 'react'
import { generatePath } from 'react-router-dom'
import { Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { INITIAL, ROLE_GROUPS } from 'utils/constants'
import { Role } from 'utils/enums'
import { pages } from 'utils/pages'
import ROUTE_PATHS from 'utils/routePaths'
import { accountsItems, assignItems, collectionsItems } from './OpenNav.constants'
import { shouldHideSection } from './OpenNav.helpers'
import { NavItem, NavItemGroup } from './OpenNav.types'
import OpenNavLink from './OpenNavLink'
import { Line } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  sourcerQueueCount: Undefinable<number>
  applierQueueCount: Undefinable<number>
  writerQueueCount: Undefinable<number>
  reviewFeedbackQueueCount: Undefinable<number>
  talentAgentReviewQueueCount: Undefinable<number>
  talentPartnerReviewQueueCount: Undefinable<number>
  dossierQueueCount: Undefinable<number>
  enrichmentQueueCount: Undefinable<number>
  dossierSubmissionCount: Undefinable<number>
  sourcedJobsReviewQueueCount: Undefinable<number>
}

export default function OpenNav({
  sourcerQueueCount,
  applierQueueCount,
  writerQueueCount,
  reviewFeedbackQueueCount,
  talentAgentReviewQueueCount,
  talentPartnerReviewQueueCount,
  dossierQueueCount,
  enrichmentQueueCount,
  dossierSubmissionCount,
  sourcedJobsReviewQueueCount,
}: Props): JSX.Element {
  const { userSession } = useAuthContext()

  /**
   * returns memoized array of work items
   */
  const workItems = useMemo(() => {
    const dossiersLabel = `Dossier Reviews${
      dossierSubmissionCount != null ? ` (${dossierSubmissionCount})` : ''
    }`

    /* TODO (matthewalbrecht): Use utils/page map for values */
    /* TODO (matthewalbrecht): Consider always showing queues with `(?)` until the count has loaded. */
    return [
      {
        content: 'Work Dashboard',
        to: generatePath(ROUTE_PATHS.WORK_DASHBOARD),
        roles: [Role.TalentAgent],
      },
      {
        content: `Sourcer Queue (${sourcerQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.SOURCER_QUEUE, {
          sourcerId: userSession.user.id,
          requestId: INITIAL,
        }),
        hide: !sourcerQueueCount,
        roles: [Role.Sourcer],
      },
      {
        content: `Applier Queue (${applierQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.APPLIER_QUEUE, {
          applierId: userSession.user.id,
          applicationId: INITIAL,
        }),
        // this is true while value is undefined or zero
        hide: !applierQueueCount,
        roles: [Role.Applier],
      },
      {
        content: `Enrichment Queue (${enrichmentQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.ENRICHER_QUEUE, {
          requestId: INITIAL,
        }),
        hide: !enrichmentQueueCount,
        roles: [Role.Enricher],
      },
      {
        content: dossiersLabel,
        to: generatePath(ROUTE_PATHS.REVIEW_DOSSIERS),
        roles: [Role.TalentAgent, Role.TalentEnablement],
      },
      {
        content: `Dossier Queue (${dossierQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.DOSSIER_QUEUE, {
          dossierWriterId: userSession.user.id,
          requestId: INITIAL,
        }),
        hide: !dossierQueueCount,
        roles: [Role.DossierWriter],
      },
      {
        content: `Writer Queue (${writerQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.WRITER_QUEUE, {
          writerId: userSession.user.id,
          requestId: INITIAL,
        }),
        hide: !writerQueueCount,
        roles: [Role.Writer],
      },
      {
        content: `Initial Review Queue (${talentAgentReviewQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.AGENT_QUEUE, {
          talentAgentId: userSession.user.id,
          requestId: INITIAL,
        }),
        roles: [Role.TalentAgent],
        hide: !talentAgentReviewQueueCount,
      },
      {
        content: `Final Review Queue (${talentPartnerReviewQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.PARTNER_QUEUE, {
          talentPartnerId: userSession.user.id,
          requestId: INITIAL,
        }),
        roles: [Role.TalentPartner, Role.EnablementEditor],
        hide: !talentPartnerReviewQueueCount,
      },
      {
        content: `Sourced Job Review (${sourcedJobsReviewQueueCount ?? 0})`,
        to: generatePath(ROUTE_PATHS.HOME),
        roles: [Role.TalentAgent],
        hide: !sourcedJobsReviewQueueCount,
      },
    ]
  }, [
    sourcerQueueCount,
    applierQueueCount,
    writerQueueCount,
    talentAgentReviewQueueCount,
    talentPartnerReviewQueueCount,
    dossierQueueCount,
    enrichmentQueueCount,
    dossierSubmissionCount,
    sourcedJobsReviewQueueCount,
    userSession.user.id,
  ])

  const insightItems = useMemo(() => {
    return [
      {
        header: 'For Core Team',
        items: [
          {
            content: 'Tagline Status',
            to: ROUTE_PATHS.TAGLINE_STATUS,
            roles: ROLE_GROUPS.CORE_TEAM,
          },
        ],
      },
      {
        header: 'For Writer & Sourcer',
        items: [
          {
            content: `Tagline Feedback (${reviewFeedbackQueueCount ?? 0})`,
            to: generatePath(ROUTE_PATHS.REVIEW_FEEDBACK_QUEUE, {
              writerId: userSession.user.id,
              submissionId: INITIAL,
            }),
            roles: [Role.Writer],
            hide: !reviewFeedbackQueueCount,
          },
          {
            content: 'Tagline Work Log',
            to: ROUTE_PATHS.TAGLINE_WORK_LOG,
            roles: ROLE_GROUPS.NON_TEA,
          },
          {
            content: 'My Tagline Stats',
            to: generatePath(ROUTE_PATHS.WRITER_TAGLINE_STATS, {
              writerId: userSession.user.id,
            }),
            roles: [Role.Writer],
          },
          {
            content: 'Sourcing Review',
            to: ROUTE_PATHS.SOURCING_REVIEW,
            roles: [Role.Sourcer, Role.Coordinator],
          },
          {
            content: 'My Sourcing Stats',
            to: generatePath(ROUTE_PATHS.MY_SOURCING_STATS, {
              sourcerId: userSession.user.id,
            }),
            roles: [Role.Sourcer],
          },
          {
            content: pages.dossierFeedback.pageName,
            to: pages.dossierFeedback.to(),
            roles: pages.dossierFeedback.permittedRoles,
          },
        ],
      },
      {
        header: 'For Work Coordinators',
        items: [
          {
            content: 'Redact Jobs',
            to: ROUTE_PATHS.REDACT_SOURCED_JOBS,
            roles: [Role.Sourcer],
          },
          {
            content: 'Review Enrichment',
            to: ROUTE_PATHS.REDACT_ENRICHMENT,
            roles: [Role.Enricher],
          },
          {
            content: 'Sourcer Allocation',
            to: ROUTE_PATHS.SOURCER_REVIEW,
            roles: [Role.Sourcer, Role.Coordinator],
          },
          {
            content: pages.clientStatus.pageName,
            to: pages.clientStatus.to(),
            roles: pages.clientStatus.permittedRoles,
          },
          {
            content: pages.talentAgents.pageName,
            to: pages.talentAgents.to(),
            roles: pages.talentAgents.permittedRoles,
          },
        ],
      },
    ]
  }, [reviewFeedbackQueueCount, userSession.user.id])

  return (
    <Padding left={3}>
      <VList size={5}>
        <NavSection name="Work" items={workItems} />
        <NestedNavSection name="Assign" groups={assignItems} />
        <NestedNavSection name="Insight" groups={insightItems} />
        <NavSection name="Accounts" items={accountsItems} />
        <NavSection name="Collections" items={collectionsItems} />
      </VList>
    </Padding>
  )
}

type NavSectionProps = {
  name: string
  items: NavItem[]
}
/**
 * renders a nav section, will hide entire section if no items should be shown
 * items can be hidden because of user role or if a queue is empty
 * @param name name of the nav section
 * @param items nav items represented as objects
 */
function NavSection({ name, items }: NavSectionProps) {
  const hideSection = shouldHideSection(items)

  if (hideSection) {
    return null
  }

  return (
    <div>
      <NavSectionHeader name={name} />
      <Padding top={3}>
        <NavSectionList items={items} />
      </Padding>
    </div>
  )
}

type NestedNavSectionProps = {
  name: string
  groups: NavItemGroup[]
}
/**
 * renders a nav section with nested groups, will hide entire section if no items should be shown
 * items can be hidden because of user role or if a queue is empty
 * @param name name of the nav section
 * @param groups nav groups represented as objects
 */
function NestedNavSection({
  name,
  groups,
}: NestedNavSectionProps): JSX.Element | null {
  // these two lengths will be equal if all subsections will be hidden, and
  // in that case we want to hide the entire section
  const hideSection =
    groups.filter((group) => shouldHideSection(group.items)).length === groups.length

  if (hideSection) {
    return null
  }

  return (
    <div>
      <NavSectionHeader name={name} />
      <Padding top={3}>
        <VList size={2}>
          {groups.map((group) => (
            <NavSectionGroup key={group.header} group={group} />
          ))}
        </VList>
      </Padding>
    </div>
  )
}

type NavSectionGroupProp = {
  group: NavItemGroup
}

function NavSectionGroup({ group }: NavSectionGroupProp) {
  const hideGroup = shouldHideSection(group.items)
  if (hideGroup) {
    return null
  }

  return (
    <div>
      <Txt size={13} color="faGrey4">
        {group.header}:
      </Txt>
      <Padding left={1.5} top={1.5}>
        <NavSectionList items={group.items} />
      </Padding>
    </div>
  )
}

type NavSectionHeaderProps = {
  name: string
}

function NavSectionHeader({ name }: NavSectionHeaderProps) {
  return (
    <>
      <Txt size={11} color="faGrey4" bold spacing={3} uppercase>
        {name}
      </Txt>
      <Padding top={0.5} right={1.8}>
        <Line color="faGrey2" />
      </Padding>
    </>
  )
}

type NavSectionListProps = {
  items: NavItem[]
}

function NavSectionList({ items }: NavSectionListProps) {
  return (
    <VList size={1.5}>
      {items.map(({ to = '', content, roles, hide }) => (
        <OpenNavLink key={content} to={to} roles={roles} hide={hide}>
          <Txt noWrap>{content}</Txt>
        </OpenNavLink>
      ))}
    </VList>
  )
}
