import React from 'react'
import { EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import {
  SuggestionRow,
  SuggestionRowDomain,
  SuggestionRowLogo,
} from './CompanySuggestion.styles'
import { Suggestion } from './CompanySuggestion.types'
import Txt from 'components/Txt'

type Props = {
  suggestion: Suggestion
}

export function CompanySuggestionItem({ suggestion }: Props): React.ReactElement {
  return (
    <SuggestionRow>
      {/* TODO (matthewalbrecht): show placeholder logo */}
      {suggestion.logo ? <SuggestionRowLogo src={suggestion.logo} /> : <div />}
      <Txt>{suggestion.name}</Txt>
      <SuggestionRowDomain size={14} color="faGrey3">
        {suggestion.domain ?? EMPTY_CELL_PLACEHOLEDER}
      </SuggestionRowDomain>
    </SuggestionRow>
  )
}
