import React from 'react'
import styled from 'styled-components/macro'
import { ClipboardAPI } from 'hooks/useCopyClipboard'
import { MimeType } from 'utils/enums'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

const CopyableValue = styled(Txt)`
  display: inline-block;
  padding: ${({ theme }) => theme.rhythm(0.5)} ${({ theme }) => theme.rhythm(0)};
  &:hover {
    background-color: ${({ theme }) => theme.color.faBlue};
    color: ${({ theme }) => theme.color.white};
  }
`

type Props = {
  clipboard: ClipboardAPI
  property: string
  value: string | null | undefined
}

export default function FinalCopyInfo({
  clipboard,
  property,
  value,
}: Props): React.ReactElement | null {
  if (!property) {
    return null
  }
  return (
    <div>
      <Padding bottom={1}>
        <Txt size={14}>{property}:&nbsp;</Txt>
      </Padding>
      <CopyableValue
        bold
        onClick={() => clipboard.copy(value || undefined, MimeType.Plain)}
      >
        {value || '–'}
      </CopyableValue>
    </div>
  )
}
