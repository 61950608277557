import { gql } from '@apollo/client'
import React from 'react'
import { EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import UserDetailButton from './UserDetailButton'
import { TexFragment } from './Users.constants'
import { User } from './Users.types'
import Flex from 'components/Flex'
import Txt from 'components/Txt'

type Props = {
  user: User
}

export default function UserRow({ user }: Props): React.ReactElement {
  const checkIcon = (
    <Icon
      name="Check"
      width={16}
      height={16}
      primaryFill="success"
      display="block"
    />
  )
  const emptyCell = <Txt size={14}>{EMPTY_CELL_PLACEHOLEDER}</Txt>
  return (
    <BodyRow>
      <BodyData collapse>
        <UserDetailButton user={user} />
      </BodyData>
      <BodyData>
        <Txt size={14}>{user.name}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{user.email}</Txt>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isInactive ? emptyCell : checkIcon}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isApplier ? checkIcon : emptyCell}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isDossierWriter ? checkIcon : emptyCell}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isEnricher ? checkIcon : emptyCell}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isSourcer ? checkIcon : emptyCell}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isTea ? checkIcon : emptyCell}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isTee ? checkIcon : emptyCell}</Flex>
      </BodyData>
      <BodyData>
        <Flex align="center">{user.isWriter ? checkIcon : emptyCell}</Flex>
      </BodyData>
    </BodyRow>
  )
}

UserRow.fragments = {
  userRowInfo: gql`
    fragment UserRowInfo on User {
      ...TexInfo
    }
    ${TexFragment}
  `,
}
