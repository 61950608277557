import React from 'react'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { DateFilter, redactColumns } from './ReviewEnrichment.constants'
import { EnrichmentSubmission, TableRowData } from './ReviewEnrichment.types'
import ReviewEnrichmentHighlightedRow from './ReviewEnrichmentHighlightedRow'
import ReviewEnrichmentRedactionRow from './ReviewEnrichmentRow'
import Message from 'components/Message'
import Txt from 'components/Txt'

type Props = {
  tableData: TableRowData[]
  dateFilter: DateFilter
}

export default function ReviewEnrichmentTable({
  tableData,
  dateFilter,
}: Props): React.ReactElement {
  return (
    <>
      <TableBox>
        <TableWrapper>
          <Table>
            <thead>
              <HeaderRow>
                {redactColumns.map((column) => (
                  <HeaderData key={column.id}>
                    <Txt size={12} bold>
                      {column.label}
                    </Txt>
                  </HeaderData>
                ))}
              </HeaderRow>
            </thead>
            <tbody>
              {tableData.map((rowData: TableRowData) => {
                return (
                  <React.Fragment key={rowData.clientId}>
                    <ReviewEnrichmentHighlightedRow
                      key={rowData.clientId}
                      rowData={rowData}
                    />
                    {rowData.enrichmentSubmissions.map(
                      (enrichment: EnrichmentSubmission) => (
                        <ReviewEnrichmentRedactionRow
                          key={enrichment.id}
                          rowData={enrichment}
                          dateFilter={dateFilter}
                        />
                      )
                    )}
                  </React.Fragment>
                )
              })}
            </tbody>
          </Table>
        </TableWrapper>
      </TableBox>
      {!tableData.length && <Message message="No enrichment to show" vertical />}
    </>
  )
}
