import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath, Link, useLocation } from 'react-router-dom'
import {
  AIRTABLE_COMP_REPORT_URL,
  AIRTABLE_OFFER_FORM_URL,
  AIRTABLE_WRITING_REQUEST_URL,
  QS,
} from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'
import Icon from 'components/Icon/Icon'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import {
  CreateNewButton,
  CreateNewButtonContainer,
  CreateNewList,
  CreateNewOption,
} from './ClientConfig.styles'
import Container from 'components/Container'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt, { Bold } from 'components/Txt'
import VList from 'components/VList'
import { GetBasicClientInfoQuery } from 'generated/graphql'

type Props = {
  clientId: string
}

const ACTION_LIST_TXT_SIZE = 14

export default function ClientConfigHeader({ clientId }: Props): React.ReactElement {
  const { pathname } = useLocation()
  const { data } = useQuery<GetBasicClientInfoQuery>(GET_BASIC_CLIENT_INFO, {
    variables: { id: clientId },
  })

  const client = data?.client
  const taglineRequestPath = generatePath(
    `${ROUTE_PATHS.NEW_TAGLINE_REQUEST}?${createSearchParams({
      [QS.CLIENT_ID]: clientId,
    })}`
  )
  const sourcingRequestPath = generatePath(
    `${ROUTE_PATHS.NEW_SOURCING_REQUEST}?${createSearchParams({
      [QS.CLIENT_ID]: clientId,
    })}`
  )
  const newInterviewCyclePath = generatePath(
    `${ROUTE_PATHS.NEW_INTERVIEW_CYCLE}?${createSearchParams({
      [QS.CLIENT_ID]: clientId,
    })}`
  )
  const newInterviewPrepPath = generatePath(
    `${ROUTE_PATHS.NEW_INTERVIEW_PREP_REQUEST}?${createSearchParams({
      [QS.CLIENT_ID]: clientId,
      [QS.CALLBACK]: pathname,
    })}`
  )
  const newExpertRequestPath = generatePath(
    `${ROUTE_PATHS.NEW_EXPERT_REQUEST}?${createSearchParams({
      [QS.CLIENT_ID]: clientId,
      [QS.CALLBACK]: pathname,
    })}`
  )

  return (
    <Container noMax>
      <Padding top={3} bottom={3}>
        <Flex justify="space-between" align="center">
          <div>
            <Txt size={24} bold>
              {client?.name ? (
                client.name
              ) : (
                <RectangleSkeleton height={32} width={140} />
              )}
              {client?.linkedinUrl && (
                <Padding inline left={1}>
                  <ExternalLink url={client.linkedinUrl}>
                    <Icon
                      name="Linkedin"
                      height={24}
                      width={24}
                      primaryFill="faGrey2"
                      primaryFillHover="linkedinBlue"
                      nudge={{ down: 0.5 }}
                    />
                  </ExternalLink>
                </Padding>
              )}
              {client?.huntrUrl && (
                <Padding inline left={1}>
                  <ExternalLink url={client.huntrUrl}>
                    <Icon
                      name="Huntr"
                      iconName="Huntr"
                      height={21}
                      width={24}
                      primaryFill="faGrey2"
                      primaryFillHover="huntrPurple"
                      nudge={{ down: 0.3 }}
                    />
                  </ExternalLink>
                </Padding>
              )}
            </Txt>
            <Padding top={1}>
              <Txt size={14} lineHeight={1.3}>
                {client ? (
                  <>
                    Talent Agent: <Bold>{client.talentAgent.name}</Bold>
                  </>
                ) : (
                  <RectangleSkeleton height={20} width={180} />
                )}
              </Txt>
            </Padding>
          </div>
          <CreateNewButtonContainer>
            <CreateNewButton>
              <Icon name="Plus" height={24} width={24} primaryFill="white" />
              <CreateNewList>
                <VList size={0.5}>
                  {client && (
                    <ExternalLink
                      txtProps={{
                        size: ACTION_LIST_TXT_SIZE,
                        underline: false,
                        color: 'text',
                      }}
                      url={`${AIRTABLE_WRITING_REQUEST_URL}?${createSearchParams({
                        'prefill_Request Type': 'Resume',
                        'prefill_Free Agent': client.name,
                      })}`}
                    >
                      <CreateNewOption>Resume Request</CreateNewOption>
                    </ExternalLink>
                  )}
                  {client && (
                    <ExternalLink
                      txtProps={{
                        size: ACTION_LIST_TXT_SIZE,
                        underline: false,
                        color: 'text',
                      }}
                      url={`${AIRTABLE_WRITING_REQUEST_URL}?${createSearchParams({
                        'prefill_Request Type': 'Cover Letter',
                        'prefill_Free Agent': client.name,
                      })}`}
                    >
                      <CreateNewOption>Cover Letter Request</CreateNewOption>
                    </ExternalLink>
                  )}
                  <Txt
                    as={Link}
                    size={ACTION_LIST_TXT_SIZE}
                    to={sourcingRequestPath}
                  >
                    <CreateNewOption>Sourcing Request</CreateNewOption>
                  </Txt>
                  <Txt as={Link} size={ACTION_LIST_TXT_SIZE} to={taglineRequestPath}>
                    <CreateNewOption>Tagline Request</CreateNewOption>
                  </Txt>
                  <Txt
                    as={Link}
                    size={ACTION_LIST_TXT_SIZE}
                    to={newInterviewCyclePath}
                  >
                    <CreateNewOption>Interview Cycle</CreateNewOption>
                  </Txt>
                  <Txt
                    as={Link}
                    size={ACTION_LIST_TXT_SIZE}
                    to={newInterviewPrepPath}
                  >
                    <CreateNewOption>Interview Prep</CreateNewOption>
                  </Txt>
                  {client && (
                    <ExternalLink
                      txtProps={{
                        size: 14,
                        underline: false,
                        color: 'text',
                      }}
                      url={`${AIRTABLE_COMP_REPORT_URL}?${createSearchParams({
                        'prefill_Request Type': 'Comp Report',
                        'prefill_Free Agent': client.name,
                      })}`}
                    >
                      <CreateNewOption>Comp Report</CreateNewOption>
                    </ExternalLink>
                  )}
                  <Txt
                    as={Link}
                    size={ACTION_LIST_TXT_SIZE}
                    to={newExpertRequestPath}
                  >
                    <CreateNewOption>Expert Request</CreateNewOption>
                  </Txt>
                  {client && (
                    <ExternalLink
                      txtProps={{
                        size: ACTION_LIST_TXT_SIZE,
                        underline: false,
                        color: 'text',
                      }}
                      url={`${AIRTABLE_WRITING_REQUEST_URL}?${createSearchParams({
                        'prefill_Free Agent': client.name,
                      })}`}
                    >
                      <CreateNewOption>Writing Request - Other</CreateNewOption>
                    </ExternalLink>
                  )}
                  {client && (
                    <ExternalLink
                      txtProps={{
                        size: 14,
                        underline: false,
                        color: 'text',
                      }}
                      url={`${AIRTABLE_OFFER_FORM_URL}?${createSearchParams({
                        prefill_Candidate: client.name,
                      })}`}
                    >
                      <CreateNewOption>Offer</CreateNewOption>
                    </ExternalLink>
                  )}
                </VList>
              </CreateNewList>
            </CreateNewButton>
          </CreateNewButtonContainer>
        </Flex>
      </Padding>
    </Container>
  )
}

const GET_BASIC_CLIENT_INFO = gql`
  query GetBasicClientInfo($id: ID!) {
    client(id: $id) {
      id
      name
      linkedinUrl
      huntrUrl
      talentAgent {
        id
        name
      }
    }
  }
`
