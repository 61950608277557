import dayjs from 'dayjs'
import { useCallback, useRef } from 'react'

type ReturnType = {
  getTimeSpentSec: () => number
}

export default function useTimeTracking(): ReturnType {
  const { current: timeStart } = useRef(+dayjs())

  const getTimeSpentSec = useCallback(() => {
    const now = +dayjs()
    const newTimeSpentSec = Math.floor((now - timeStart) / 1000)
    return newTimeSpentSec
  }, [timeStart])

  return {
    getTimeSpentSec,
  }
}
