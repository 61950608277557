import React from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import styled from 'styled-components/macro'
import { InputProps, inputStyles, StyledTextArea } from 'components/Inputs/Input'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'

type Props = {
  label?: React.ReactNode
  name?: string
  value?: string
  defaultValue?: string
  placeholder?: string
  activeIndicator?: boolean
  minWidth?: number
  height?: number
  disabled?: boolean
  fullWidth?: boolean
  rows?: number
  required?: boolean
  description?: string
  autosize?: boolean
  onValueChange?: (value: string) => void
}

const TextAreaBox = styled.div`
  width: 100%;
`

/* TODO (matthewalbrecht): inherit these styles from Inpu */
const StyledTextAreaAutosize = styled(TextareaAutosize)<InputProps>`
  ${inputStyles}
`

export function TextArea({
  label,
  value,
  defaultValue,
  name,
  activeIndicator = false,
  disabled,
  placeholder,
  height,
  rows,
  minWidth,
  required,
  fullWidth = true,
  onValueChange,
  description,
  autosize = false,
}: Props): JSX.Element {
  const inputProps = {
    name: name,
    value: value,
    activeIndicator: activeIndicator,
    defaultValue: defaultValue,
    disabled: disabled,
    placeholder: placeholder,
    height: height,
    minWidth: minWidth,
    fullWidth: fullWidth,
    required: required,
    rows: rows,
    onChange: (event: React.ChangeEvent<HTMLTextAreaElement>) => {
      onValueChange?.(event.currentTarget.value)
    },
  }
  return (
    <TextAreaBox>
      {label && <Label content={label} padding={description ? 2 : 1.5} />}
      {description && <InputDescription description={description} />}
      {autosize ? (
        <StyledTextAreaAutosize {...inputProps} maxRows={6} />
      ) : (
        <StyledTextArea id={name} as="textarea" {...inputProps} />
      )}
    </TextAreaBox>
  )
}
