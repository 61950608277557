import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import { KeyValue, Undefinable } from 'types'
import getPodId from 'utils/helpers/getPodId'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import {
  EnrichmentQueueQuery,
  EnrichmentQueueQueryVariables,
} from 'generated/graphql'

type QueueReturn = {
  fullQueue: string[] | undefined
  queueByPod: KeyValue<string[]> | undefined
  loading: boolean
}

type CountReturn = {
  totalCount: Undefinable<number>
  loading: boolean
}

type EnrichmentRequest = {
  id: string
  podId: number
}

export default function useEnrichmentQueue(
  podCount: number,
  queryOptions: QueryHookOptions<
    EnrichmentQueueQuery,
    EnrichmentQueueQueryVariables
  > = {}
): QueueReturn {
  const { data, loading } = useQuery<
    EnrichmentQueueQuery,
    EnrichmentQueueQueryVariables
  >(GET_ENRICHMENT_REQUESTS, {
    ...queryOptions,
    variables: {
      onlyCount: false,
    },
  })

  // build sorted array of enrichment requests
  const enrichmentRequests = data?.enrichmentRequests?.edges?.map(
    (edge): EnrichmentRequest => {
      return {
        id: edge.node.id,
        podId: getPodId(edge.node.id, podCount),
      }
    }
  )

  const fullQueue = enrichmentRequests?.map(reduceObjectToId)
  const queueByPod = enrichmentRequests?.reduce(
    (groupedRequests: KeyValue<string[]>, request: EnrichmentRequest) => {
      const group = groupedRequests[request.podId]

      if (group) {
        group.push(request.id)
      } else {
        groupedRequests[request.podId] = [request.id]
      }

      return groupedRequests
    },
    {}
  )

  return {
    loading,
    fullQueue,
    queueByPod,
  }
}

export function useEnrichmentQueueCount(
  podCount: number,
  queryOptions: QueryHookOptions<
    EnrichmentQueueQuery,
    EnrichmentQueueQueryVariables
  > = {}
): CountReturn {
  const { data, loading } = useQuery<
    EnrichmentQueueQuery,
    EnrichmentQueueQueryVariables
  >(GET_ENRICHMENT_REQUESTS, {
    ...queryOptions,
    variables: {
      onlyCount: true,
    },
  })

  return {
    loading,
    totalCount: data?.enrichmentRequests?.totalCount ?? undefined,
  }
}

/* TODO @matthewalbrecht: share query variables for easy cache updating */
/**
 * used by enrichers to queue through all of their enrichment requests
 */
const GET_ENRICHMENT_REQUESTS = gql`
  query EnrichmentQueue($onlyCount: Boolean!) {
    enrichmentRequests(
      inAirtable: false
      isSubmitted: false
      isApplied: false
      orderBy: { field: DUE, direction: ASC }
    ) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
        }
      }
    }
  }
`
