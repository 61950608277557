import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { Client } from './ClientStatus.types'
import Txt from 'components/Txt'

type Props = {
  client: Client
}

export default function ClientStatusRow({ client }: Props): React.ReactElement {
  const lastStatusUpdateDate = client.statusUpdates.edges[0]?.node.createdAt
  const lastUpdate = lastStatusUpdateDate
    ? dayjs(lastStatusUpdateDate).format(DATE_FORMAT.FRIENDLY_DATE_SHORT_MONTH)
    : EMPTY_CELL_PLACEHOLEDER
  return (
    <BodyRow>
      <BodyData>
        <Txt
          as={Link}
          to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
            clientId: client.id,
          })}
          size={14}
          underline
          hoverColor="black"
        >
          {client.name}
        </Txt>
      </BodyData>

      <BodyData collapse>
        <Txt size={14} noWrap>
          {lastUpdate}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

ClientStatusRow.fragments = {
  clientRowInfo: gql`
    fragment ClientStatusRowInfo on Client {
      name
      statusUpdates(first: 1) {
        edges {
          node {
            id
            createdAt
          }
        }
      }
    }
  `,
}
