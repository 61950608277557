import dayjs from 'dayjs'
import React, { useState, useEffect, useCallback } from 'react'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { TIME_TRACKER_REGEX } from 'utils/constants'
import Icon from 'components/Icon/Icon'
import Flex from 'components/Flex'
import Label from 'components/Label'
import Padding from 'components/Padding'

type Props = {
  onValueChange?: (value: number) => void
  hide?: boolean
}

const StyledTimeInput = styled.input`
  padding: ${({ theme }) => theme.rhythm(1)};
  width: ${({ theme }) => theme.rhythm(7)};
  text-align: center;
`

export default function TimeTracker({
  onValueChange,
  hide = false,
}: Props): JSX.Element | null {
  const [inputValue, setInputValue] = useState('')
  const [timeStart, setTimeStart] = useState(+dayjs())
  const [timeSpentSec, setTimeSpentSec] = useState(0)
  const [isActive, setIsActive] = useState(true)

  /**
   * calls update function from props
   * updates time spent internal state
   * updates value of text input formatted mm:ss
   */
  const updateValue = useCallback(
    (value) => {
      const minutes = Math.floor(value / 60)
      const seconds = value - minutes * 60

      onValueChange?.(value)
      setTimeSpentSec(value)
      setInputValue(`${minutes}:${seconds.toString().padStart(2, '0')}`)
    },
    [onValueChange, setTimeSpentSec]
  )

  /**
   * used to create a interval when
   */
  useEffect(() => {
    let interval: NodeJS.Timeout

    if (isActive) {
      interval = setInterval(() => {
        const now = +dayjs()
        const newTimeSpentSec = Math.floor((now - timeStart) / 1000)
        updateValue(newTimeSpentSec)
      }, 250)
    }

    return () => clearInterval(interval)
  }, [isActive, timeStart, updateValue])

  function toggle() {
    if (!isActive) {
      // if we are about to resume the timer
      // we reset timeStart to total time spent so far subtracted from the current time
      setTimeStart(+dayjs() - timeSpentSec * 1000)
    }
    setIsActive(!isActive)
  }

  function handleChange(event: React.ChangeEvent<HTMLInputElement>) {
    const value = event.currentTarget.value
    if (value === '0' || !value) {
      updateValue(0)
    } else if (TIME_TRACKER_REGEX.exec(value)) {
      const [minutes, seconds] = value.split(':')
      const totalSeconds =
        parseInt(minutes || '0', 10) * 60 + parseInt(seconds || '0', 10)
      updateValue(totalSeconds)
    } else {
      toast.error('Must be in the format of "m:ss"')
      updateValue(timeSpentSec)
    }
  }

  if (hide) {
    return null
  }

  return (
    <Flex align="center">
      <Flex column align="flex-end">
        <Label size={12} padding={0.5} content="Time Spent (m:ss)" />
        <Flex align="center">
          <Padding right={1}>
            <button onClick={toggle}>
              {isActive ? (
                <Icon name="Pause" height={24} width={24} primaryFill="text" />
              ) : (
                <Icon name="Play" height={24} width={24} primaryFill="text" />
              )}
            </button>
          </Padding>
          <StyledTimeInput
            value={inputValue}
            onChange={(e) => setInputValue(e.currentTarget.value)}
            onBlur={handleChange}
            disabled={isActive}
          />
        </Flex>
      </Flex>
    </Flex>
  )
}
