import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { CompanyExpertColumns } from '../CompanyDetail.constants'
import { Company, Expert } from '../CompanyDetail.types'
import ExternalLink from 'components/ExternalLink'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetCompanyExpertsQuery,
  GetCompanyExpertsQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

const normalizeData = (
  data: NonNullable<GetCompanyExpertsQuery['company']>
): Expert[] => {
  return getNodes<Expert>(data.experts)
    .map((item) => ({ ...item, createdAtDayjs: dayjs(item.createdAt) }))
    .sort((a, b) => sortByProp(a, b, 'createdAtDayjs'))
}

export default function CompanyExperts({ company }: Props): React.ReactElement {
  const { data, error, loading } = useQuery<
    GetCompanyExpertsQuery,
    GetCompanyExpertsQueryVariables
  >(GET_COMPANY_EXPERTS, {
    variables: { id: company.id },
  })

  const items = data?.company ? normalizeData(data.company) : null

  return (
    <FullWidthTable
      headers={Object.values(CompanyExpertColumns)}
      isError={Boolean(error)}
      isLoading={loading}
      isEmpty={Boolean(items && !items.length)}
      emptyMessage={`Could not find any experts`}
      loadingMessage={`Loading experts...`}
      errorMessage={`There was an error fetching the experts`}
    >
      {items?.map((item) => (
        <CompanyDetailExpertRow key={item.id} item={item} />
      ))}
    </FullWidthTable>
  )
}

type ExpertProps = { item: Expert }

function CompanyDetailExpertRow({ item }: ExpertProps): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Txt size={14} as="span" noWrap>
          {item.name}
          {item.linkedinUrl ? (
            <Padding inline left={1}>
              <ExternalLink url={item.linkedinUrl}>
                <Icon
                  name="Linkedin"
                  height={16}
                  width={16}
                  primaryFill="text"
                  primaryFillHover="linkedinBlue"
                  nudge={{ down: 0.2 }}
                />
              </ExternalLink>
            </Padding>
          ) : null}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14} as="span">
          {item.expertRequests.totalCount}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

const GET_COMPANY_EXPERTS = gql`
  query GetCompanyExperts($id: ID!) {
    company(id: $id) {
      id
      experts {
        edges {
          node {
            id
            name
            linkedinUrl
            createdAt
            expertRequests {
              totalCount
            }
          }
        }
      }
    }
  }
`
