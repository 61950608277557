import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useMemo, useState } from 'react'
import { generatePath, Redirect, useHistory, useParams } from 'react-router'
import { toast } from 'react-toastify'
import useDossierQueue from 'hooks/useDossierQueue'
import { GLOBAL_COPY, INITIAL } from 'utils/constants'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import ROUTE_PATHS from 'utils/routePaths'
import DualScroll, { DualScrollContainer } from 'components/DualScroll/DualScroll'
import { DEFAULT_STATE } from './DossierSubmission.constants'
import { getNextRouteUrl } from './DossierSubmission.helpers'
import { Params, State } from './DossierSubmission.types'
import DossierSubmissionFooter from './DossierSubmissionFooter'
import DossierSubmissionLeft from './DossierSubmissionLeft'
import DossierSubmissionRight from './DossierSubmissionRight'
import Message from 'components/Message'
import PrivateRoute from 'components/PrivateRoute'
import {
  GetDossierRequestQuery,
  GetDossierRequestQueryVariables,
} from 'generated/graphql'

function DossierSubmission(): React.ReactElement {
  // state
  const history = useHistory()
  const { freeAgentId, dossierWriterId, requestId } = useParams<Params>()
  const redirectToInitial = requestId === INITIAL
  const [state, setState] = useState<State>(DEFAULT_STATE)
  const { queueByViewer, queueByClient, loading } = useDossierQueue()

  // derived state
  const queue = useMemo(
    () => (freeAgentId ? queueByClient[freeAgentId] ?? [] : queueByViewer),
    [freeAgentId, queueByClient, queueByViewer]
  )
  const nextRequestId = getNextRequestIdInQueue(queue, requestId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, {
    freeAgentId,
    dossierWriterId,
  })

  // query
  const { data, error } = useQuery<
    GetDossierRequestQuery,
    GetDossierRequestQueryVariables
  >(GET_DOSSIER_REQUEST, {
    variables: {
      id: requestId,
    },
    skip: redirectToInitial,
  })

  // derived state
  const dossierRequest = data?.dossierRequest

  // memoized components
  const leftContent = useMemo(
    () => <DossierSubmissionLeft dossierRequest={dossierRequest} />,
    [dossierRequest]
  )
  const rightContent = useMemo(
    () => (
      <DossierSubmissionRight
        dossierRequest={dossierRequest}
        state={state}
        setState={setState}
        nextRouteUrl={nextRouteUrl}
      />
    ),
    [dossierRequest, state, nextRouteUrl]
  )

  useEffect(() => {
    if (!loading && !queue[0]) {
      toast(GLOBAL_COPY.NO_QUEUE_FOUND)
      history.push(ROUTE_PATHS.HOME)
    }
  }, [history, queue, loading])

  // redirect to first item in queue if on `/initial` route
  if (redirectToInitial && queue[0]) {
    const queueType = dossierWriterId ? 'dossier-writer' : 'free-agent'
    const typeId = dossierWriterId ? dossierWriterId : freeAgentId

    return (
      <Redirect
        to={generatePath(ROUTE_PATHS.DOSSIER_ASSIGNMENT_QUEUES, {
          requestId: queue[0],
          typeId: typeId,
          type: queueType,
        })}
      />
    )
  }

  if (error) {
    return (
      <Message vertical message="There was an error loading the dossier request" />
    )
  }

  return (
    <DualScrollContainer key={requestId}>
      <DualScroll leftContent={leftContent} rightContent={rightContent} />
      {dossierRequest && (
        <DossierSubmissionFooter
          setState={setState}
          queue={queue}
          requestId={requestId}
          nextRouteUrl={nextRouteUrl}
        />
      )}
    </DualScrollContainer>
  )
}

DossierSubmission.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.DOSSIER_QUEUE}
    key={ROUTE_PATHS.DOSSIER_QUEUE}
  >
    <DossierSubmission />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.DOSSIER_QUEUE_BY_FREE_AGENT}
    key={ROUTE_PATHS.DOSSIER_QUEUE_BY_FREE_AGENT}
  >
    <DossierSubmission />
  </PrivateRoute>,
]

export default DossierSubmission

const GET_DOSSIER_REQUEST = gql`
  query GetDossierRequest($id: ID!) {
    dossierRequest(id: $id) {
      id
      ...DossierSubmissionFooterInfo
      ...DossierSubmissionLeftInfo
      ...DossierSubmissionRightInfo
    }
  }
  ${DossierSubmissionFooter.fragments.DossierSubmissionFooterInfo}
  ${DossierSubmissionLeft.fragments.DossierSubmissionLeftInfo}
  ${DossierSubmissionRight.fragments.DossierSubmissionRightInfo}
`
