import { gql } from '@apollo/client'

export const GET_CLIENTS = gql`
  query GetClients {
    clients: allClients {
      id
      name
    }
  }
`

export const GET_CLIENTS_CONN = gql`
  query GetClientsConn {
    clients {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`

export const GET_CLIENTS_INTERVIEW_CYCLES = gql`
  query GetClientsInterviewCycles($clientId: ID!) {
    interviewCycles(clientId: $clientId, active: true) {
      edges {
        node {
          id
          employerName
          jobTitle
        }
      }
    }
  }
`

export const GET_TALENT_AGENTS = gql`
  query GetTalentAgents {
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
    }
  }
`
