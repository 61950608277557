import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import ROUTE_PATHS from 'utils/routePaths'
import Icon from 'components/Icon/Icon'
import Container from 'components/Container'
import Flex from 'components/Flex'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

const HeaderBox = styled(Container)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

const Content = styled(Flex)`
  height: ${({ theme }) => theme.size.headerHeight};
`

export default function Header(): JSX.Element {
  return (
    <HeaderBox noMax>
      <Content align="center" justify="space-between">
        <Flex as={Link} align="center" to={ROUTE_PATHS.HOME}>
          <Icon name="FreeAgencyFlag" height={48} width={48} primaryFill="faBlue" />
          <Padding left={2}>
            <Txt as="h1" size={30} color="faBlue" bold>
              Deskjockey
            </Txt>
          </Padding>
        </Flex>
      </Content>
    </HeaderBox>
  )
}
