import { gql } from '@apollo/client'
import { ColumnHeader, DropdownOption } from 'types'
import { Role } from 'utils/enums'
import { sortByProp } from 'utils/sort'

export const ID = {
  FORM: 'addEditUserForm',
}
export const roleOptions: DropdownOption<Role>[] = [
  {
    label: 'Applier',
    value: Role.Applier,
  },
  {
    label: 'Dossier Writer',
    value: Role.DossierWriter,
  },
  {
    label: 'Enricher',
    value: Role.Enricher,
  },
  {
    label: 'Sourcer',
    value: Role.Sourcer,
  },
  {
    label: 'TEA',
    value: Role.TalentEnablement,
  },
  {
    label: 'TEE',
    value: Role.EnablementEditor,
  },
  {
    label: 'Writer',
    value: Role.Writer,
  },
]

export const TOAST = {
  createSuccess(name: string): string {
    return `User ${name} added`
  },
  CREATE_PERMISSION_DENIED: "Cannot update an admin's permissions",
  CREATE_ERROR: 'There was an error creating the user',
  modifySuccess(name: string): string {
    return `User ${name} updated`
  },
  MODIFY_ERROR: 'There was an error updating the user',
  CALENDAR_ERROR: 'There was an error creating the calendar',
}

export const COLUMNS: ColumnHeader[] = [
  { label: '', id: 'expand', collapse: true },
  { label: 'Name', id: 'name' },
  { label: 'Email', id: 'email' },
  { label: 'Active', id: 'isActive' },
  { label: 'Applier', id: 'isApplier' },
  { label: 'Dossier Writer', id: 'isDossierWriter' },
  { label: 'Enricher', id: 'isEnricher' },
  { label: 'Sourcer', id: 'isSourcer' },
  { label: 'TEA', id: 'isTea' },
  { label: 'TEE', id: 'isTee' },
  { label: 'Writer', id: 'isWriter' },
]

export const TexFragment = gql`
  fragment TexInfo on User {
    id
    name
    email
    isTea
    isTee
    isWriter
    isDossierWriter
    isEnricher
    isSourcer
    isApplier
    isTalentAgent
    isCoordinator
    isTalentPartner
    isExpertCoordinator
    isDataCleaner
    isSalesCoordinator
    isInactive
    isAdmin
  }
`

export const ERROR_CODE = {
  PERMISSION_DENIED: 'permission_denied',
}

export const extendedRoleOptions: DropdownOption<Role>[] = [
  ...roleOptions,
  {
    label: 'Talent Agent',
    value: Role.TalentAgent,
  },
  {
    label: 'Final Reviewer',
    value: Role.TalentPartner,
  },
  {
    label: 'Coordinator',
    value: Role.Coordinator,
  },
  {
    label: 'Expert Coordinator',
    value: Role.ExpertCoordinator,
  },
].sort((a, b) => sortByProp(a, b, 'label'))
