import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { KeyValue } from 'types'
import { DATE_FORMAT } from 'utils/constants'
import { getDueTime } from 'utils/helpers/time'
import { sortByProp } from 'utils/sort'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  items: DueItem[]
  fromNow?: boolean
  dueHour?: number
  dueMinute?: number
  dueTz?: string
  dateFormat?: string
}

type DueItem = {
  due?: string | null | undefined
}

function DueDateTableItem({
  items = [],
  fromNow = false,
  dueHour,
  dueMinute,
  dueTz,
  dateFormat = DATE_FORMAT.ONLY_DATE,
}: Props): JSX.Element {
  if (!items.length) {
    return <>0</>
  }

  // reduces tagline items into { [dueDate]: count }
  const countsByDueDatesObject = items.reduce(
    (finalObject: KeyValue<number>, item) => {
      const dueDate = item.due
      if (!dueDate) {
        return finalObject
      }
      const dueDateCount = finalObject[dueDate] || 0

      finalObject[dueDate] = dueDateCount + 1

      return finalObject
    },
    {}
  )

  // formats our object into an array of mappable content and sorts it by date
  const countsByDueDates = Object.entries(countsByDueDatesObject)
    .map(([due, count]) => ({
      due: dayjs(due),
      count,
    }))
    .sort((a, b) => sortByProp(a, b, 'due'))

  return (
    <Padding right={3}>
      <VList size={1}>
        {countsByDueDates.map((entry) => (
          <Txt size={14} color="text" key={entry.due.toISOString()}>
            <Txt as="span" size={14} bold>
              {entry.count} &nbsp;
            </Txt>
            {/* TODO: (matthewalbrecht) enforce this with type system */}
            {fromNow && dueHour != null && dueMinute != null && dueTz != null
              ? `due ${getDueTime(entry.due, dueHour, dueMinute, dueTz)}`
              : entry.due.format(dateFormat)}
          </Txt>
        ))}
      </VList>
    </Padding>
  )
}

DueDateTableItem.fragments = {
  taglineRequestInfo: gql`
    fragment TaglineRequestInfoForDueDateTableItem on TaglineRequest {
      id
      due
    }
  `,
}

export default DueDateTableItem
