import { gql } from '@apollo/client'
import React from 'react'
import { KeyValue, ReactSetState, TemplateMap } from 'types'
import JobHeading from 'components/JobHeading/JobHeading'
import TemplateEditor from 'components/TemplateEditor/TemplateEditor'
import Templating from 'components/Templating/Templating'
import { TaglineRequest } from './WriterSubmission.types'
import ExternalLink from 'components/ExternalLink'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  setEditorChanges: ReactSetState<KeyValue>
  taglineRequest: TaglineRequest
  isTemplateFillingTest: boolean | null
  templateMap: TemplateMap | null
  setTemplateMap: ReactSetState<TemplateMap | null>
  setAreTemplatesFilled: ReactSetState<boolean>
}

function WriterSubmissionRight({
  taglineRequest,
  setEditorChanges,
  isTemplateFillingTest,
  templateMap,
  setTemplateMap,
  setAreTemplatesFilled,
}: Props): JSX.Element {
  /* TODO (matthewalbrecht): Save this map to db */
  function handleEditorChange(content: string, templateId: string) {
    setEditorChanges((prevEditorChanges) => ({
      ...prevEditorChanges,
      [templateId]: content,
    }))
  }

  function handleTemplateFill<
    TemplateFields extends Record<string, string | null | undefined>
  >(templateMap: TemplateFields) {
    setTemplateMap(templateMap)
    setAreTemplatesFilled(true)
  }

  return (
    <>
      <Padding bottom={4}>
        <Txt bold size={20} lineHeight={1.3}>
          <JobHeading
            jobTitle={taglineRequest.jobTitle}
            company={taglineRequest.company ?? { name: taglineRequest.employerName }}
            huntrUrl={taglineRequest.huntrUrl}
          />
        </Txt>
        <Padding top={1}>
          {taglineRequest.jobUrl && (
            <ExternalLink url={taglineRequest.jobUrl} label="Job Description" />
          )}
        </Padding>
      </Padding>
      {isTemplateFillingTest && (
        <Padding bottom={4}>
          <Templating
            taglineRequest={taglineRequest}
            handleTemplateFill={handleTemplateFill}
          />
        </Padding>
      )}
      <VList size={4}>
        {taglineRequest.templateSequence.templates.map((template) => (
          <TemplateEditor
            key={template.id}
            template={template}
            sequence={taglineRequest.templateSequence}
            handleEditorChange={handleEditorChange}
            withIndicator
            templateMap={templateMap}
          />
        ))}
      </VList>
    </>
  )
}

WriterSubmissionRight.fragments = {
  taglineRequestInfo: gql`
    fragment WriterSubmissionRightInfo on TaglineRequest {
      id
      jobUrl
      templateSequence {
        id
        templates {
          id
          name
          copy
        }
        ...TemplateEditorSequenceInfo
      }
      ...JobHeadingInfo
    }
    ${TemplateEditor.fragments.templateEditorSequenceInfo}
    ${JobHeading.fragments.jobHeadingInfo}
  `,
}

export default WriterSubmissionRight
