import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import getNodes from 'utils/helpers/getNodes'
import { sortByDatetime, sortByProp } from 'utils/sort'
import { isInTrialPeriod } from 'views/ClientTracker/ClientTracker.helpers'
import { LAST_7_DAYS, LAST_14_DAYS } from './TalentAgentClientCards.constants'
import {
  ClientInfo,
  UpcomingInterview,
  ClientCardInfo,
} from './TalentAgentClientCards.types'

function getAccountAge(client: ClientInfo): string {
  if (client.callOneDate) {
    return `${dayjs(client.callOneDate).fromNow(true)} old`
  }
  return 'New client'
}

function getSourcedJobsSinceDaysFromTodayTotalCount(
  client: ClientInfo,
  daysFromToday: number
): number {
  const sourcedJobs = getNodes(client.sourcedJobs)
  const pastDays = dayjs().subtract(daysFromToday, 'days')
  return sourcedJobs.filter((sourcedJob) => {
    const review = sourcedJob.reviews[0]
    if (review && !review.rejectionReason) {
      return dayjs(review.createdAt).isAfter(pastDays)
    }
    return false
  }).length
}

getSourcedJobsSinceDaysFromTodayTotalCount.fragments = {
  sourcedJobsSinceDaysFromTodayTotalCount: gql`
    fragment SourcedJobsSinceDaysFromTodayTotalCount on Client {
      sourcedJobs(isReviewed: true) {
        edges {
          node {
            reviews {
              rejectionReason
              createdAt
            }
          }
        }
      }
    }
  `,
}

function getReviewedSourcedJobsTotalCount(client: ClientInfo): number {
  const sourcedJobs = getNodes(client.sourcedJobs)
  return sourcedJobs.filter((sourcedJob) => {
    const review = sourcedJob.reviews[0]
    return review && !review.rejectionReason
  }).length
}

getReviewedSourcedJobsTotalCount.fragments = {
  reviewedSourcedJobsTotalCount: gql`
    fragment ReviewedSourcedJobsTotalCount on Client {
      sourcedJobs(isReviewed: true) {
        edges {
          node {
            reviews {
              rejectionReason
            }
          }
        }
      }
    }
  `,
}

function getTaglinesWIPTotalCount(client: ClientInfo): number {
  return (
    client.unassigned.totalCount +
    client.pendingAgentReview.totalCount +
    client.pendingPartnerReview.totalCount +
    client.pendingWriting.totalCount
  )
}

getTaglinesWIPTotalCount.fragments = {
  taglinesWIPTotalCount: gql`
    fragment TaglinesWIPTotalCount on Client {
      unassigned: taglineRequests(status: UNASSIGNED) {
        totalCount
      }
      pendingWriting: taglineRequests(status: PENDING_WRITING) {
        totalCount
      }
      pendingAgentReview: taglineRequests(status: PENDING_TA_REVIEW) {
        totalCount
      }
      pendingPartnerReview: taglineRequests(status: PENDING_TP_REVIEW) {
        totalCount
      }
    }
  `,
}

function getTaglinesSinceDaysFromTodayTotalCount(
  client: ClientInfo,
  daysFromToday: number
): number {
  const taglines = getNodes(client.reviewed)
  const pastDays = dayjs().subtract(daysFromToday, 'days')
  return taglines.filter((tagline) => dayjs(tagline.createdAt).isAfter(pastDays))
    .length
}

getTaglinesSinceDaysFromTodayTotalCount.fragments = {
  taglinesSinceDaysFromTodayTotalCount: gql`
    fragment TaglinesSinceDaysFromTodayTotalCount on Client {
      reviewed: taglineRequests(status: REVIEWED) {
        edges {
          node {
            createdAt
          }
        }
      }
    }
  `,
}

function getDossiersWIPTotalCount(client: ClientInfo): number {
  let count = 0
  const interviewCycles = getNodes(client.interviewCycles)
  interviewCycles.forEach((interviewCycle) => {
    const dossierRequests = getNodes(interviewCycle.dossierRequests)
    dossierRequests.forEach((dossierRequest) => {
      if (!dossierRequest.priorDossierSubmission) {
        count++
      }
    })
  })
  return count
}

getDossiersWIPTotalCount.fragments = {
  dossiersWIPTotalCount: gql`
    fragment DossiersWIPTotalCount on Client {
      interviewCycles(active: true) {
        edges {
          node {
            dossierRequests {
              edges {
                node {
                  priorDossierSubmission {
                    id
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}

function getDossiersSinceDaysFromTodayTotalCount(
  client: ClientInfo,
  daysFromToday: number
): number {
  let count = 0
  const interviewCycles = getNodes(client.interviewCycles)
  const pastDays = dayjs().subtract(daysFromToday, 'days')
  interviewCycles.forEach((interviewCycle) => {
    const dossierSubmissions = getNodes(interviewCycle.dossierSubmissions)
    count += dossierSubmissions.filter((dossierSubmission) =>
      dayjs(dossierSubmission.createdAt).isAfter(pastDays)
    ).length
  })
  return count
}

getDossiersSinceDaysFromTodayTotalCount.fragments = {
  dossiersSinceDaysFromTodayTotalCount: gql`
    fragment DossiersSinceDaysFromTodayTotalCount on Client {
      interviewCycles(active: true) {
        edges {
          node {
            dossierSubmissions {
              edges {
                node {
                  createdAt
                }
              }
            }
          }
        }
      }
    }
  `,
}

function getAllDossiersTotalCount(client: ClientInfo): number {
  let count = 0
  const interviewCycles = getNodes(client.interviewCycles)
  interviewCycles.forEach((interviewCycle) => {
    count += interviewCycle.dossierSubmissions.totalCount
  })
  return count
}

getAllDossiersTotalCount.fragments = {
  allDossiersTotalCount: gql`
    fragment DossiersTotalCount on Client {
      interviewCycles(active: true) {
        edges {
          node {
            dossierSubmissions {
              totalCount
            }
          }
        }
      }
    }
  `,
}

function getUpcomingInterviews(client: ClientInfo): UpcomingInterview[] {
  const upcomingInterviews: UpcomingInterview[] = []
  const interviewCycles = getNodes(client.interviewCycles)
  interviewCycles.forEach((interviewCycle) => {
    const interviews = getNodes(interviewCycle.interviews)
    interviews.forEach((interview) => {
      if (!interview.scheduledFor) {
        return
      }
      if (!dayjs().isAfter(interview.scheduledFor.datetime)) {
        upcomingInterviews.push({
          id: interview.id,
          scheduledFor: interview.scheduledFor,
          company: interviewCycle.company
            ? {
                companyId: interviewCycle.company.id,
                companyName: interviewCycle.company.name,
                companyLogo: interviewCycle.company.logoUrl,
                companyDomain: interviewCycle.company.domain,
              }
            : null,
          jobTitle: interviewCycle.jobTitle,
          jobUrl: interviewCycle.jobUrl,
        })
      }
    })
  })
  return upcomingInterviews.sort((a, b) =>
    sortByDatetime(a.scheduledFor.datetime, b.scheduledFor.datetime)
  )
}

getUpcomingInterviews.fragments = {
  upcomingInterviews: gql`
    fragment UpcomingInterviews on Client {
      interviewCycles(active: true) {
        edges {
          node {
            company {
              id
              name
              logoUrl
              domain
            }
            jobTitle
            jobUrl
            interviews {
              edges {
                node {
                  id
                  scheduledFor {
                    datetime
                    timezone
                  }
                }
              }
            }
          }
        }
      }
    }
  `,
}

export function getClientCardsInfo(clients: ClientInfo[]): ClientCardInfo[] {
  return clients
    .map((client) => ({
      id: client.id,
      name: client.name,
      isInTrialPeriod: isInTrialPeriod(client),
      clientHeadshot: client.headshotFiles && client.headshotFiles[0]?.url,
      accountAge: getAccountAge(client),
      activeCycles: client.interviewCycles.totalCount,
      activeCyclesPast7Days: client.interviewCyclesPast7Days.totalCount,
      allCycles: client.interviewCyclesAllTime.totalCount,
      sourcingWIP: client.sourcingRequests.totalCount,
      sourcedJobsPast7Days: getSourcedJobsSinceDaysFromTodayTotalCount(
        client,
        LAST_7_DAYS
      ),
      sourcedJobsPast14Days: getSourcedJobsSinceDaysFromTodayTotalCount(
        client,
        LAST_14_DAYS
      ),
      reviewedSourcedJobs: getReviewedSourcedJobsTotalCount(client),
      taglinesWIP: getTaglinesWIPTotalCount(client),
      taglinesPast7Days: getTaglinesSinceDaysFromTodayTotalCount(
        client,
        LAST_7_DAYS
      ),
      taglinesPast14Days: getTaglinesSinceDaysFromTodayTotalCount(
        client,
        LAST_14_DAYS
      ),
      allTaglines: client.reviewed.totalCount,
      dossiersWIP: getDossiersWIPTotalCount(client),
      dossiersPast7Days: getDossiersSinceDaysFromTodayTotalCount(
        client,
        LAST_7_DAYS
      ),
      dossiersPast14Days: getDossiersSinceDaysFromTodayTotalCount(
        client,
        LAST_14_DAYS
      ),
      allDossiers: getAllDossiersTotalCount(client),
      upcomingInterviews: getUpcomingInterviews(client),
    }))
    .sort((a, b) => sortByProp(a, b, 'name'))
}

getClientCardsInfo.fragments = {
  clientCardsInfo: gql`
    fragment ClientCardsInfo on Client {
      id
      name
      callOneDate
      headshotFiles {
        url
      }
      interviewCycles(active: true) {
        totalCount
      }
      interviewCyclesPast7Days: interviewCycles(
        active: true
        createdAtRangeFilter: $dateFilter7Days
      ) {
        totalCount
      }
      interviewCyclesAllTime: interviewCycles {
        totalCount
      }
      sourcingRequests(isOpen: true) {
        totalCount
      }
      interviewCycles(active: true) {
        totalCount
      }
      reviewed: taglineRequests(status: REVIEWED) {
        totalCount
      }
      ...SourcedJobsSinceDaysFromTodayTotalCount
      ...ReviewedSourcedJobsTotalCount
      ...TaglinesWIPTotalCount
      ...TaglinesSinceDaysFromTodayTotalCount
      ...DossiersWIPTotalCount
      ...DossiersSinceDaysFromTodayTotalCount
      ...DossiersTotalCount
      ...UpcomingInterviews
    }
    ${getSourcedJobsSinceDaysFromTodayTotalCount.fragments
      .sourcedJobsSinceDaysFromTodayTotalCount}
    ${getReviewedSourcedJobsTotalCount.fragments.reviewedSourcedJobsTotalCount}
    ${getTaglinesWIPTotalCount.fragments.taglinesWIPTotalCount}
    ${getTaglinesSinceDaysFromTodayTotalCount.fragments
      .taglinesSinceDaysFromTodayTotalCount}
    ${getDossiersWIPTotalCount.fragments.dossiersWIPTotalCount}
    ${getDossiersSinceDaysFromTodayTotalCount.fragments
      .dossiersSinceDaysFromTodayTotalCount}
    ${getAllDossiersTotalCount.fragments.allDossiersTotalCount}
    ${getUpcomingInterviews.fragments.upcomingInterviews}
  `,
}
