import React from 'react'
import { Input } from 'components/Inputs/Input'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'

type ChangeEvent = React.ChangeEvent<HTMLInputElement>

type Props = {
  label?: string
  name?: string
  required?: boolean
  value?: string
  onValueChange?: (value: string) => void
  defaultValue?: string
  width?: number
  description?: string
  min?: string
  max?: string
  type?: 'date' | 'datetime-local'
  ariaLabel?: string
}

export function DatePicker({
  label,
  name,
  required,
  width,
  value,
  description,
  min,
  max,
  onValueChange,
  defaultValue,
  type = 'date',
  ariaLabel = 'date picker',
}: Props): JSX.Element {
  return (
    <div>
      {label && <Label htmlFor={name} content={label} />}
      {description && <InputDescription description={description} />}
      <Input
        id={name}
        name={name}
        defaultValue={defaultValue}
        type={type}
        value={value}
        required={required}
        width={width}
        onChange={(event: ChangeEvent) => onValueChange?.(event.currentTarget.value)}
        aria-label={ariaLabel}
        min={min}
        max={max}
        height={35}
      />
    </div>
  )
}
