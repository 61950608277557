import { useMutation, gql } from '@apollo/client'
import React, { useRef } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactFormEvent } from 'types'
import { EMPTY_INPUT, HUNTR_REGEX_STRING, QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { SHOW_IMPORTANT_FLAG, SHOW_NO_TAGLINE_FLAG } from 'utils/settings'
import { sortByProp } from 'utils/sort'
import { useQueryString } from 'utils/urls'
import { CREATE_TAGLINE_REQUEST, MODIFY_TAGLINE_REQUEST } from 'gql/mutations'
import useFormState from 'views/ClientTracker/useFormState'
import Checkbox from 'components/Inputs/Checkbox'
import {
  TaglineRequest,
  TaglineRequestFormState,
} from './AddAndEditTaglineRequest.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import {
  TextArea,
  TextInput,
  DatePicker,
  RadioGroup,
  Dropdown,
} from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CreateTaglineRequestMutation,
  CreateTaglineRequestMutationVariables,
  ModifyTaglineRequestMutation,
  ModifyTaglineRequestMutationVariables,
} from 'generated/graphql'

type Props = {
  freeAgents: {
    id: string
    name: string
    templateSequences: { name: string; id: string }[]
  }[]
  taglineRequest: TaglineRequest | null | undefined
  isSubmitting: boolean
  setIsSubmitting: React.Dispatch<React.SetStateAction<boolean>>
}

const AddAndEditTaglineRequestFormBox = styled.form`
  margin: 0 auto;
  width: 50%;
  padding-bottom: ${({ theme }) => theme.rhythm(4)};
`

function AddAndEditTaglineRequestForm({
  freeAgents,
  taglineRequest,
  isSubmitting,
  setIsSubmitting,
}: Props): JSX.Element {
  const queryString = useQueryString()
  const defaultFreeAgentId =
    taglineRequest?.client.id || queryString.get(QS.CLIENT_ID) || undefined
  const defaultHuntrUrl =
    taglineRequest?.huntrUrl || queryString.get(QS.HUNTR_URL) || undefined
  const formRef = useRef<HTMLFormElement>(null)
  const { formState, setFormField, setFormState } =
    useFormState<TaglineRequestFormState>({
      clientId: defaultFreeAgentId,
      huntrUrl: defaultHuntrUrl,
      templateSequenceId: taglineRequest?.templateSequence.id,
      notes: taglineRequest?.notes,
      due: taglineRequest?.due,
      taglineRequestId: taglineRequest?.id,
      isImportant: taglineRequest?.isImportant,
      noTagline: taglineRequest?.noTagline,
    })
  const history = useHistory()

  const isEditPage = !!taglineRequest
  const submissionRoute = queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME
  const headerCopy = isEditPage ? 'Edit Request' : 'New Request'

  const [submitRequest] = useMutation<
    CreateTaglineRequestMutation,
    CreateTaglineRequestMutationVariables
  >(CREATE_TAGLINE_REQUEST, {
    refetchQueries: ['GetTaglineRequestsByStatus'],
    onCompleted() {
      toast.success(`New tagline request created`)
      // if we prefilled huntr url we want to send them to a "You may now close the tab" page
      if (queryString.get(QS.HUNTR_URL)) {
        history.push(ROUTE_PATHS.CLOSE_MESSAGE)
      } else {
        setFormState({
          ...formState,
          huntrUrl: undefined,
          notes: undefined,
          isImportant: false,
          noTagline: false,
        })
        setIsSubmitting(false)
      }
    },
    onError() {
      toast.error(`There was an error submitting your request`)
      setIsSubmitting(false)
    },
  })

  const [updateRequest] = useMutation<
    ModifyTaglineRequestMutation,
    ModifyTaglineRequestMutationVariables
  >(MODIFY_TAGLINE_REQUEST, {
    onCompleted() {
      toast.success(`Tagline request updated`)
      history.push(submissionRoute)
    },
    onError() {
      toast.error(`There was an error updating your request`)
    },
  })

  const freeAgentDropdownOptions = freeAgents
    .map((freeAgent) => ({
      value: freeAgent.id,
      label: freeAgent.name,
    }))
    .sort((a, b) => sortByProp(a, b, 'label'))

  const currentFreeAgent = freeAgents.find(
    (freeAgent) => freeAgent.id === formState.clientId
  )

  const templateSequenceOptions = currentFreeAgent?.templateSequences.map(
    (sequence) => ({
      value: sequence.id,
      label: sequence.name,
    })
  )

  /**
   * handle validating and executing submitting/updating mutation
   * @param event submission event
   */
  function handleSubmit(event: ReactFormEvent): void {
    event.preventDefault()

    if (!formState.templateSequenceId) {
      toast.error('Free Agent does not have any Template Sequences')
      return
    }

    if (formState.noTagline !== true && !formState.notes) {
      toast.error(
        'Must provide a connection between the Free Agent and the position'
      )
      return
    }
    const notes = formState.notes ?? ''

    if (
      isSubmitting ||
      !formState.clientId ||
      !formState.huntrUrl ||
      !formState.due
    ) {
      return
    }

    setIsSubmitting(true)

    if (isEditPage) {
      void updateRequest({
        variables: {
          ModifyTaglineRequestInput: {
            clientId: formState.clientId,
            huntrUrl: formState.huntrUrl,
            templateSequenceId: formState.templateSequenceId,
            notes,
            due: formState.due,
            taglineRequestId: taglineRequest.id,
            isImportant: formState.isImportant,
          },
        },
      })
    } else {
      void submitRequest({
        variables: {
          CreateTaglineRequestInput: {
            clientId: formState.clientId,
            huntrUrl: formState.huntrUrl,
            templateSequenceId: formState.templateSequenceId,
            notes,
            due: formState.due,
            isConnectionRequested: true,
            isImportant: formState.isImportant,
            noTagline: formState.noTagline,
          },
        },
      })
    }
  }

  function handleClearAll() {
    setFormState({})
  }

  return (
    <AddAndEditTaglineRequestFormBox
      onSubmit={handleSubmit}
      ref={formRef}
      id="newTaglineRequest"
    >
      <Padding bottom={4}>
        <Flex justify="space-between" align="center">
          <Txt as="h2" size={24} bold>
            {headerCopy}
          </Txt>
          <Button $type="link" type="reset" onClick={handleClearAll}>
            <Txt size={14} underline hoverColor="black">
              Clear Form
            </Txt>
          </Button>
        </Flex>
      </Padding>
      <VList size={4}>
        <Dropdown
          name="clientId"
          label="Free Agent"
          options={freeAgentDropdownOptions}
          onValueChange={(value) => setFormField('clientId', value)}
          disabled={isEditPage}
          value={formState.clientId ?? EMPTY_INPUT}
          required
        />
        <TextInput
          name="huntrUrl"
          label="Huntr URL"
          onValueChange={(value) => setFormField('huntrUrl', value)}
          value={formState.huntrUrl ?? EMPTY_INPUT}
          width={300}
          placeholder="https://huntr.co/"
          type="url"
          pattern={HUNTR_REGEX_STRING}
          required
          autoComplete="off"
        />
        {templateSequenceOptions && (
          <RadioGroup
            name="templateSequenceId"
            label="Template"
            value={formState.templateSequenceId ?? EMPTY_INPUT}
            onValueChange={(value) => setFormField('templateSequenceId', value)}
            options={templateSequenceOptions}
            emptyOptionsMessage="There are no Template Sequences associated with this Free Agent"
            required
          />
        )}
        <TextArea
          name="notes"
          label={
            <>
              Notes
              <Padding top={2}>
                <Txt as="span" italic size={14} lineHeight={1.3}>
                  Please add notes indicating a connection between the FA and the
                  role/company
                </Txt>
              </Padding>
            </>
          }
          value={formState.notes ?? EMPTY_INPUT}
          onValueChange={(value) => setFormField('notes', value)}
          defaultValue={taglineRequest?.notes}
          height={80}
        />
        <DatePicker
          name="due"
          label="Due Date"
          value={formState.due ?? EMPTY_INPUT}
          defaultValue={taglineRequest?.due}
          onValueChange={(value) => setFormField('due', value)}
          required
        />
        {SHOW_IMPORTANT_FLAG && (
          <Checkbox
            label="Mark as A-Track"
            name="isImportant"
            checked={formState.isImportant || false}
            onValueChange={(value) => setFormField('isImportant', value)}
            option={{ label: 'A-Track', value: 'isImportant' }}
          />
        )}
        {SHOW_NO_TAGLINE_FLAG && (
          <Checkbox
            label="Accelerated"
            name="noTagline"
            checked={formState.noTagline ?? false}
            onValueChange={(value) => setFormField('noTagline', value)}
            option={{ label: 'No Tagline', value: 'noTagline' }}
          />
        )}
      </VList>
    </AddAndEditTaglineRequestFormBox>
  )
}

AddAndEditTaglineRequestForm.fragments = {
  clientInfo: gql`
    fragment ClientInfoAddAndEditTaglineRequestForm on Client {
      id
      name
      templateSequences {
        id
        name
      }
    }
  `,
  taglineRequestInfo: gql`
    fragment TaglineRequestInfo on TaglineRequest {
      id
      huntrUrl
      isConnectionRequested
      templateSequence {
        id
      }
      due
      notes
      isImportant
      noTagline
      client {
        id
        name
      }
    }
  `,
}

export default AddAndEditTaglineRequestForm
