import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { DATE_FORMAT } from 'utils/constants'
import Editor from 'components/Editor/Editor'
import ImportantIndicator from 'components/ImportantIndicator/ImportantIndicator'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { TaglineRequest } from './InitialReview.types'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  taglineRequest: TaglineRequest
}

function InitialReviewLeft({ taglineRequest }: Props): JSX.Element {
  const dueDateLabel = `Writer Due Date: ${dayjs(taglineRequest.due).format(
    DATE_FORMAT.ONLY_DATE
  )}`

  return (
    <>
      <Padding bottom={4}>
        {taglineRequest.isImportant && (
          <Padding bottom={2.5}>
            <ImportantIndicator />
          </Padding>
        )}
        <Padding bottom={1.5}>
          <Txt as="h2" size={24} bold>
            {taglineRequest.client.name}
          </Txt>
        </Padding>
        <VList size={1}>
          <Txt size={14}>{dueDateLabel}</Txt>
        </VList>
      </Padding>
      <Padding bottom={4}>
        <TextArea
          defaultValue={taglineRequest.notes}
          label="Assignment notes"
          disabled
          rows={4}
        />
      </Padding>
      {taglineRequest.client.resumeUrl && (
        <Padding bottom={4}>
          <PDFViewer url={taglineRequest.client.resumeUrl} />
        </Padding>
      )}
      <VList size={4}>
        <WorkInfoLinks
          resumeUrl={taglineRequest.client.resumeUrl}
          linkedinUrl={taglineRequest.client.linkedinUrl}
        />
        {taglineRequest.huntrHtmlDescription && (
          <Editor
            defaultValue={taglineRequest.huntrHtmlDescription}
            label="Notes from Huntr"
            height={80}
            disabled
          />
        )}
        {taglineRequest.client.bio && (
          <TextArea
            defaultValue={taglineRequest.client.bio}
            label={`${taglineRequest.client.name} Bio`}
            height={80}
            disabled
          />
        )}
      </VList>
    </>
  )
}

InitialReviewLeft.fragments = {
  taglineRequestInfo: gql`
    fragment AgentReviewLeftInfo on TaglineRequest {
      id
      huntrHtmlDescription
      notes
      due
      isImportant
      client {
        id
        name
        linkedinUrl
        resumeUrl
        bio
      }
    }
  `,
}
export default InitialReviewLeft
