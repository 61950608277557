import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { Maybe, ReactFormEvent, ReactSetState } from 'types'
import { QS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { buildInterviewCycleOptions } from 'utils/helpers/buildInterviewCycleOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { GET_CLIENTS, GET_CLIENTS_INTERVIEW_CYCLES } from 'gql/queries'
import { DOSSIER_OPTIONS, TOAST } from './AddAndEditDossierRequest.constants'
import {
  getDefaultState,
  getDue,
  getFormHeading,
  getHourOptions,
} from './AddAndEditDossierRequest.helpers'
import {
  DossierRequest,
  DossierSubmission,
  State,
} from './AddAndEditDossierRequest.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import InputDescription from 'components/InputDescription'
import { DatePicker, Dropdown, RadioGroup, TextArea } from 'components/Inputs'
import Label from 'components/Label'
import { FullPageForm } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  CreateDossierRequestMutation,
  CreateDossierRequestMutationVariables,
  GetClientsInterviewCyclesQuery,
  GetClientsInterviewCyclesQueryVariables,
  GetClientsQuery,
  ModifyDossierRequestMutation,
  ModifyDossierRequestMutationVariables,
} from 'generated/graphql'

type Props = {
  setIsSubmitting: ReactSetState<boolean>
  dossierRequest: Maybe<DossierRequest>
  priorDossierSubmission: Maybe<DossierSubmission>
  isEditing: boolean
}

export default function AddAndEditDossierRequestForm({
  setIsSubmitting,
  dossierRequest,
  priorDossierSubmission,
  isEditing,
}: Props): React.ReactElement {
  // prefills
  const queryString = useQueryString()
  const clientIdParam = queryString.get(QS.CLIENT_ID)
  const interviewCycleIdParam = queryString.get(QS.INTERVIEW_CYCLE_ID)

  // state
  const history = useHistory()
  const [state, setState] = useState<State>(
    getDefaultState(dossierRequest, priorDossierSubmission, {
      clientId: clientIdParam,
      interviewCycleId: interviewCycleIdParam,
    })
  )

  // queries
  const { data: clientData } = useQuery<GetClientsQuery>(GET_CLIENTS)
  const { data: interviewCycleData, refetch: refetchInterviewCycles } = useQuery<
    GetClientsInterviewCyclesQuery,
    GetClientsInterviewCyclesQueryVariables
  >(GET_CLIENTS_INTERVIEW_CYCLES, {
    variables: { clientId: state.clientId },
    skip: !state.clientId,
  })

  // mutations
  const [createDossierRequest] = useMutation<
    CreateDossierRequestMutation,
    CreateDossierRequestMutationVariables
  >(CREATE_DOSSIER_REQUEST, {
    onCompleted() {
      toast.success(TOAST.CREATE_SUCCESS)
      history.push(queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME)
    },
    onError() {
      toast.error(TOAST.CREATE_ERROR)
      setIsSubmitting(false)
    },
  })
  const [modifyDossierRequest] = useMutation<
    ModifyDossierRequestMutation,
    ModifyDossierRequestMutationVariables
  >(MODIFY_DOSSIER_REQUEST, {
    onCompleted() {
      toast.success(TOAST.MODIFY_SUCCESS)
      history.push(queryString.get(QS.CALLBACK) || ROUTE_PATHS.HOME)
    },
    onError() {
      toast.error(TOAST.MODIFY_ERROR)
      setIsSubmitting(false)
    },
  })

  // normalizing data
  const clientOptions = buildDropdownOptions(clientData?.clients || [])
  const interviewCycleOptions = buildInterviewCycleOptions(
    interviewCycleData?.interviewCycles
  )

  /**
   * updates state with new client Id, resets interviewCycleId state, and fetches client's interview cycles
   * @param value input value
   */
  function handleClientIdValueChange(value: string) {
    const newState = { ...state, clientId: value, interviewCycleId: '' }
    setState(newState)

    void refetchInterviewCycles({ clientId: value })
  }

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<T = string>(value: T, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    if (state.dueHour == null) {
      return toast.warning('Must enter the hour it is due.')
    }
    setIsSubmitting(true)
    const due = getDue(state.dueDate, state.dueHour)
    if (isEditing) {
      updateDossierRequest(due)
    } else {
      submitDossierRequest(due)
    }
  }

  /**
   * execute mutation for creating dossier request
   */
  function submitDossierRequest(due: string) {
    void createDossierRequest({
      variables: {
        CreateDossierRequestInput: {
          interviewCycleId: state.interviewCycleId,
          notes: state.notes,
          due,
          // dossier type is requried and validated throught HTML5 validation
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dossierType: state.dossierType!,
          priorDossierSubmissionId: priorDossierSubmission?.id,
        },
      },
    })
  }

  /**
   * execute mutation for modifying dossier request
   */
  function updateDossierRequest(due: string) {
    void modifyDossierRequest({
      variables: {
        ModifyDossierRequestInput: {
          // if we are in the edit flow dossier request exists
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dossierRequestId: dossierRequest!.id,
          notes: state.notes,
          due,
          // dossier type is requried and validated throught HTML5 validation
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          dossierType: state.dossierType!,
        },
      },
    })
  }

  // derived state
  const isUpdateRequest = Boolean(priorDossierSubmission)
  const showLoadingMessage = !state.clientId || !interviewCycleData
  const hourOptions = getHourOptions(isEditing ? state.dueHour : null)
  const formLabel = getFormHeading(isEditing, isUpdateRequest)

  // There will never be an instance where both of these are non-null
  const fileUrl =
    // this is the fileUrl of the submission to update if we are in updating a request
    priorDossierSubmission?.fileUrl ||
    // this is the fileUrl of prior submission if we are editing an existing request
    dossierRequest?.priorDossierSubmission?.fileUrl

  return (
    <FullPageForm id="newDossierRequest" onSubmit={handleSubmit}>
      <Padding top={6} bottom={4}>
        <Txt as="h2" bold size={24}>
          {formLabel}
        </Txt>
        {fileUrl && (
          <Padding top={2}>
            <ExternalLink url={fileUrl}>Previous Dossier</ExternalLink>
          </Padding>
        )}
      </Padding>
      <VList size={4}>
        <Dropdown
          label="Free Agent *"
          options={clientOptions}
          value={state.clientId}
          onValueChange={handleClientIdValueChange}
          minWidth={100}
          placeholder="Select Free Agent"
          withEmptyOption={false}
          required
          disabled={isEditing || isUpdateRequest}
        />
        <RadioGroup
          label="Interview Cycle *"
          name="interviewCycle"
          options={interviewCycleOptions}
          value={state.interviewCycleId}
          onValueChange={(value) => handleInputChange(value, 'interviewCycleId')}
          loadingOptions={showLoadingMessage}
          loadingMessage={'Please select a Free Agent to see their interview cycles'}
          required
          disabled={isEditing || isUpdateRequest}
        />
        <RadioGroup
          label="What type of dossier? *"
          name="dossierType"
          options={DOSSIER_OPTIONS}
          value={state.dossierType || ''}
          onValueChange={(value) => handleInputChange(value, 'dossierType')}
          // NOTE: if required is removed you must also remove the non-null-assertion
          // in the dossierType mutation variable
          required
        />
        <TextArea
          label="Assignment Information *"
          description="Include the following: a link to the interviewer's linkedin page (or just their name), what stage interview it is."
          value={state.notes}
          onValueChange={(value) => handleInputChange(value, 'notes')}
          required
        />
        <div>
          <Label content="Need by *" htmlFor="dueDate" />
          <InputDescription description="Generally this should be the day before the scheduled interview. Please consider requests will need 1-2 days for turnaround time" />
          <Flex align="flex-end">
            <DatePicker
              value={state.dueDate}
              onValueChange={(value) => handleInputChange(value, 'dueDate')}
              name="dueDate"
              required
            />
            <Padding inline left={2}>
              <Dropdown
                value={state.dueHour != null ? String(state.dueHour) : ''}
                onValueChange={(value) =>
                  handleInputChange<number>(Number(value), 'dueHour')
                }
                options={hourOptions.map((option) => ({
                  ...option,
                  value: String(option.value),
                }))}
                withEmptyOption={false}
                placeholder="Select Time (Eastern)"
                required
              />
            </Padding>
          </Flex>
        </div>
      </VList>
    </FullPageForm>
  )
}

const CREATE_DOSSIER_REQUEST = gql`
  mutation CreateDossierRequest(
    $CreateDossierRequestInput: CreateDossierRequestInput!
  ) {
    createDossierRequest(input: $CreateDossierRequestInput) {
      dossierRequest {
        id
      }
    }
  }
`

const MODIFY_DOSSIER_REQUEST = gql`
  mutation ModifyDossierRequest(
    $ModifyDossierRequestInput: ModifyDossierRequestInput!
  ) {
    modifyDossierRequest(input: $ModifyDossierRequestInput) {
      dossierRequest {
        id
        notes
        due
        dossierType
      }
    }
  }
`
