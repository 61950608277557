export const ID = {
  FORM: 'reportInterviewTimeForm',
}

export const DEFAULT_STATE = {
  datetime: '',
  timezone: null,
}

export const COPY = {
  REQUIRED_FIELDS: 'Must provide a date and timezone',
  SUBMIT_SUCCESS: 'Interview time reported',
  SUBMIT_ERROR: 'There was an error reporting the interview time',
  DECLINE_SUCCESS: 'Interview Declined',
  DECLINE_ERROR: 'There was an error declining the interview',
}
