import { gql } from '@apollo/client'
import React from 'react'
import { EditorItem } from 'types'
import { useAuthContext } from 'context/auth'
import { QUICK_REVIEW_FOR_WRITER_OPTIONS } from 'utils/constants'
import { SetFormField } from 'views/ClientTracker/useFormState'
import Editor from 'components/Editor/Editor'
import JobHeading from 'components/JobHeading/JobHeading'
import StarRating from 'components/StarRating/StarRating'
import TemplateEditor from 'components/TemplateEditor/TemplateEditor'
import { FormState, TaglineRequest } from './InitialReview.types'
import ButtonList from 'components/ButtonList'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import { BackgroundColor } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  formState: FormState
  setFormField: SetFormField<FormState>
  taglineRequest: TaglineRequest
}

function InitialReviewRight({
  taglineRequest,
  formState,
  setFormField,
}: Props): JSX.Element {
  const {
    userSession: {
      user: { id: viewerId },
    },
  } = useAuthContext()

  function handleEditorChange(content: string, templateId: string) {
    setFormField('editorChanges', {
      ...formState.editorChanges,
      [templateId]: content,
    })
  }

  function handleQuickReviewChange(value: string) {
    const existingCopy = formState.feedback.trimEnd()
    setFormField('feedback', `${existingCopy}${existingCopy ? '\n' : ''}- ${value}`)
  }

  const firstSubmission = taglineRequest.writerSubmissions[0]

  if (!firstSubmission) {
    return (
      <Flex justify="center">
        <Padding top={4}>
          <Txt>Cannot find a submission for this tagline request</Txt>
        </Padding>
      </Flex>
    )
  }

  const firstItem = firstSubmission.items[0]
  const showWrittenByViewerWarning = viewerId === firstSubmission.createdBy.id

  return (
    <>
      {showWrittenByViewerWarning && (
        <Padding bottom={4}>
          <BackgroundColor color="yellow" rounded>
            <Padding all={2}>
              <Txt color="text" size={16} alignment="center">
                This submission was written by you, please skip.
              </Txt>
            </Padding>
          </BackgroundColor>
        </Padding>
      )}
      <Padding bottom={4}>
        <Txt bold size={20} lineHeight={1.3}>
          <JobHeading
            jobTitle={taglineRequest.jobTitle}
            company={taglineRequest.company ?? { name: taglineRequest.employerName }}
            huntrUrl={taglineRequest.huntrUrl}
          />
        </Txt>
        <Padding top={1}>
          {taglineRequest.jobUrl && (
            <ExternalLink url={taglineRequest.jobUrl} label="Job Description" />
          )}
        </Padding>
      </Padding>
      <Padding bottom={6}>
        <Editor
          disabled
          defaultValue={firstItem?.copy}
          label={`${firstItem?.name ?? ''} (Original)`}
          height={80}
        />
        <Padding top={3}>
          <Padding bottom={3}>
            <ButtonList
              options={QUICK_REVIEW_FOR_WRITER_OPTIONS}
              onClick={handleQuickReviewChange}
            />
          </Padding>
          <TextArea
            value={formState.feedback}
            placeholder="Type additional feedback here..."
            onValueChange={(value) => setFormField('feedback', value)}
            activeIndicator
          />
          <Padding top={1}>
            <Flex justify="flex-end">
              <StarRating
                rating={formState.rating}
                onValueChange={(value) => setFormField('rating', value)}
                iconSize={18}
              />
            </Flex>
          </Padding>
        </Padding>
      </Padding>
      <VList size={4}>
        {firstSubmission.items.map((item: EditorItem) => (
          <TemplateEditor
            key={item.id}
            template={item}
            sequence={firstSubmission}
            handleEditorChange={handleEditorChange}
            withIndicator
          />
        ))}
      </VList>
    </>
  )
}

InitialReviewRight.fragments = {
  taglineRequestInfo: gql`
    fragment AgentReviewRightInfo on TaglineRequest {
      id
      jobUrl
      writerSubmissions {
        id
        items {
          id
          copy
          name
        }
        createdBy {
          id
        }
        ...TemplateEditorWriterSubmissionInfo
      }
      ...JobHeadingInfo
    }
    ${TemplateEditor.fragments.writerSubmissionInfo}
    ${JobHeading.fragments.jobHeadingInfo}
  `,
}

export default InitialReviewRight
