import React from 'react'
import { Company } from './CompanyDetail.types'
import CompanyApplications from './Tables/CompanyApplications'
import CompanyDetails from './Tables/CompanyDetails'
import CompanyDossierSubmissions from './Tables/CompanyDossierSubmissions'
import CompanyEnrichmentSubmissions from './Tables/CompanyEnrichmentSubmissions'
import CompanyExperts from './Tables/CompanyExperts'
import CompanyInterviewCycles from './Tables/CompanyInterviewCycles'
import CompanySourcedJobs from './Tables/CompanySourcedJobs'
import Container from 'components/Container'
import HList from 'components/HList'
import { Tab, TabList, TabPanel, Tabs } from 'components/StyledTabs'
import Txt from 'components/Txt'

type Props = {
  company: Company
}

export default function CompanyDetailContent({
  company,
}: Props): React.ReactElement {
  return (
    <div>
      <Tabs>
        <TabList>
          <Container noMax>
            <HList size={1}>
              <Tab>
                <Txt as="span" size={14}>
                  Details
                </Txt>
              </Tab>
              <Tab>
                <Txt as="span" size={14}>
                  Apps ({company.applications.totalCount})
                </Txt>
              </Tab>
              <Tab>
                <Txt as="span" size={14}>
                  Enrichment ({company.enrichmentSubmissions.totalCount})
                </Txt>
              </Tab>
              <Tab>
                <Txt as="span" size={14}>
                  Experts ({company.experts.totalCount})
                </Txt>
              </Tab>
              <Tab>
                <Txt as="span" size={14}>
                  Dossiers ({company.dossierSubmissions.totalCount})
                </Txt>
              </Tab>
              <Tab>
                <Txt as="span" size={14}>
                  Sourced Jobs ({company.sourcedJobs.totalCount})
                </Txt>
              </Tab>
              <Tab>
                <Txt as="span" size={14}>
                  Interview Cycles ({company.interviewCycles.totalCount})
                </Txt>
              </Tab>
            </HList>
          </Container>
        </TabList>

        <TabPanel>
          <CompanyDetails company={company} />
        </TabPanel>
        <TabPanel>
          <CompanyApplications company={company} />
        </TabPanel>
        <TabPanel>
          <CompanyEnrichmentSubmissions company={company} />
        </TabPanel>
        <TabPanel>
          <CompanyExperts company={company} />
        </TabPanel>
        <TabPanel>
          <CompanyDossierSubmissions company={company} />
        </TabPanel>
        <TabPanel>
          <CompanySourcedJobs company={company} />
        </TabPanel>
        <TabPanel>
          <CompanyInterviewCycles company={company} />
        </TabPanel>
      </Tabs>
    </div>
  )
}
