import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import ROUTE_PATHS from 'utils/routePaths'
import { Company } from './CompanyMerge.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { BackgroundColor } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  company: Company
}

export default function CompanyMergeDetail({ company }: Props): React.ReactElement {
  return (
    <Padding>
      <Flex align="center">
        <Padding right={1}>
          {company.logoUrl ? (
            <LogoImage src={company.logoUrl} />
          ) : (
            <BackgroundColor
              color="faGrey1"
              style={{ width: '4rem', height: '4rem' }}
            />
          )}
        </Padding>
        <Txt size={24} as="h2">
          {company.name}
        </Txt>
      </Flex>
      <Padding top={4}>
        <VList size={4}>
          {company.domain && (
            <div>
              <Padding bottom={1}>
                <Txt size={12} uppercase spacing={1}>
                  Website
                </Txt>
              </Padding>
              <Txt size={16}>
                <ExternalLink
                  url={company.domain}
                  inheritStyles
                  txtProps={{ color: 'text', hoverColor: 'link' }}
                >
                  {company.domain}
                </ExternalLink>
              </Txt>
            </div>
          )}
          <div>
            <Padding bottom={1}>
              <Txt size={12} uppercase spacing={1}>
                Company Details
              </Txt>
            </Padding>
            <Link
              to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, {
                companyId: company.id,
              })}
              target="__blank"
            >
              <Txt size={16} color="text" hoverColor="link" underline>
                See related work
              </Txt>
            </Link>
          </div>
        </VList>
      </Padding>
    </Padding>
  )
}

const LogoImage = styled.img`
  width: ${({ theme }) => theme.toRems(48)};
  height: ${({ theme }) => theme.toRems(48)};
`
