import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import ContractsTable from './ContractsTable'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'

export default function Contracts(): React.ReactElement {
  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt size={24} bold as="h2">
            Contracts
          </Txt>
        </Padding>
      </Container>
      <ContractsTable />
    </ViewBox>
  )
}

Contracts.Routes = [
  <PrivateRoute exact path={ROUTE_PATHS.CONTRACTS} key={ROUTE_PATHS.CONTRACTS}>
    <Contracts />
  </PrivateRoute>,
]
