import React from 'react'
import {
  TableBox,
  TableWrapper,
  Table,
  HeaderRow,
  HeaderData,
} from 'components/FullWidthTable/FullWidthTableStyles'
import { COLUMNS } from './ReviewDossierSubmission.constants'
import {
  DossierSubmission,
  DossierSubmissionGroup,
} from './ReviewDossierSubmission.types'
import ReviewDossierSubmissionHighlightedRow from './ReviewDossierSubmissionHighlightedRow'
import ReviewDossierSubmissionRow from './ReviewDossierSubmissionRow'
import Txt from 'components/Txt'

type Props = {
  tableData: DossierSubmissionGroup[]
}

export default function ReviewDossierSubmissionsTable({
  tableData,
}: Props): React.ReactElement {
  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {COLUMNS.map((column) => (
                <HeaderData key={column.id} collapse={column.collapse}>
                  <Txt size={12} bold>
                    {column.label}
                  </Txt>
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>
            {tableData.map((rowData: DossierSubmissionGroup) => {
              return (
                <React.Fragment key={rowData.clientId}>
                  <ReviewDossierSubmissionHighlightedRow
                    key={rowData.clientId}
                    rowData={rowData}
                  />
                  {rowData.dossierSubmissions.map(
                    (dossierSubmission: DossierSubmission) => (
                      <ReviewDossierSubmissionRow
                        key={dossierSubmission.id}
                        rowData={dossierSubmission}
                      />
                    )
                  )}
                </React.Fragment>
              )
            })}
          </tbody>
        </Table>
      </TableWrapper>
    </TableBox>
  )
}
