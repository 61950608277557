import * as React from 'react'
import styled, {
  css,
  DefaultTheme,
  ThemedStyledProps,
} from 'styled-components/macro'
import { Colors, Font } from 'types'

export type TxtProps = {
  size?: number
  family?: keyof Font
  as?: React.ElementType
  uppercase?: boolean
  capitalize?: boolean
  bold?: boolean
  semibold?: boolean
  light?: boolean
  italic?: boolean
  link?: boolean
  color?: keyof Colors
  hoverColor?: keyof Colors
  spacing?: number
  lineHeight?: number
  alignment?: string
  noWrap?: boolean
  children?: React.ReactNode
  inheritStyles?: boolean
  underline?: boolean
  display?: 'inline' | 'block'
}

export const Bold = styled.strong`
  font-weight: 700;
`

/**
 * @param size defaults to `16`
 * @param family defaults to `primary`
 * @param color defaults to `text`
 */
const Txt = styled.p<TxtProps>`
  ${({ display }) =>
    display &&
    css`
      display: ${display};
    `};
  font-size: ${({ size = 16, theme }) => theme.toRems(size)};
  font-weight: 400;
  font-family: ${({ theme, family = 'primary' }) => theme.font[family]};
  ${({ uppercase }) => uppercase && 'text-transform: uppercase;'}
  ${({ capitalize }) => capitalize && 'text-transform: capitalize;'}
  ${({ italic }) =>
    italic &&
    css`
      font-style: italic;
    `}
  ${({ bold }) => bold && 'font-weight: 700;'}
  ${({ semibold }) => semibold && 'font-weight: 500;'}
  ${({ light }) => light && 'font-weight: 300;'}
  ${getColorStyle}
  ${({ spacing, theme }) =>
    spacing &&
    css`
      letter-spacing: ${theme.toRems(spacing)};
    `}
  ${({ lineHeight }) =>
    lineHeight &&
    css`
      line-height: ${lineHeight};
    `}
  ${({ alignment }) =>
    alignment &&
    css`
      text-align: ${alignment};
    `}

  ${({ inheritStyles }) =>
    inheritStyles &&
    css`
      font-size: inherit;
      font-weight: inherit;
      font-family: inherit;
      font-weight: inherit;
      letter-spacing: inherit;
      line-height: inherit;
      text-align: inherit;
    `}

  ${({ link, theme }) =>
    link &&
    css`
      text-decoration: underline;
      color: ${theme.color.link};
    `}

  ${({ underline }) =>
    underline &&
    css`
      text-decoration: underline;
    `}

  ${({ hoverColor, theme }) =>
    hoverColor &&
    css`
      &:hover {
        color: ${theme.color[hoverColor]};
      }
    `}
    
  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}
`

function getColorStyle({
  color,
  inheritStyles,
  theme,
}: ThemedStyledProps<TxtProps, DefaultTheme>) {
  let finalColor = theme.color.text
  if (inheritStyles) {
    finalColor = 'inherit'
  }
  if (color) {
    finalColor = theme.color[color]
  }

  return css`
    color: ${finalColor};
  `
}

export default Txt
