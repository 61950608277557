import React from 'react'
import { Link } from 'react-router-dom'
import styled from 'styled-components/macro'
import { Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'

const StyledLink = styled(Link)`
  display: block;

  &:hover {
    text-decoration: underline ${({ theme }) => theme.color.text};
  }
`

type Props = {
  roles?: Role[]
  to: string
  children: React.ReactNode
  hide?: boolean
}

export default function OpenNavLink({
  roles,
  to,
  children,
  hide = false,
}: Props): JSX.Element | null {
  if (hide) {
    return null
  }
  if (roles && !checkRole(roles)) {
    return null
  }

  return <StyledLink to={to}>{children}</StyledLink>
}
