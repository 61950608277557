import { generatePath } from 'react-router'
import { INITIAL, ROLE_GROUPS } from './constants'
import { Role } from './enums'
import ROUTE_PATHS from './routePaths'

export type PageDetail = {
  pageName: string
  to(viewerId?: string): string
  permittedRoles: Role[]
  category: string
}

type PageKeys =
  | 'sourcerQueue'
  | 'applierQueue'
  | 'enrichmentQueue'
  | 'dossierReviews'
  | 'dossierQueue'
  | 'writerQueue'
  | 'companies'
  | 'initialReviewQueue'
  | 'finalReviewQueue'
  | 'taglineStatus'
  | 'taglineFeedback'
  | 'taglineWorkLog'
  | 'myTaglineStats'
  | 'sourcingReview'
  | 'mySourcingStats'
  | 'redactJobs'
  | 'reviewEnrichment'
  | 'sourcerAllocation'
  | 'sourcingAssignment'
  | 'taglineAssignment'
  | 'applicationAssignment'
  | 'dossierAssignment'
  | 'expertRequestsAssignment'
  | 'finalReviewAssignment'
  | 'autoSourcing'
  | 'currentAssignments'
  | 'writersStats'
  | 'sourcersStats'
  | 'sourcingStats'
  | 'clients'
  | 'users'
  | 'dossierLibrary'
  | 'expertNetwork'
  | 'contracts'
  | 'clientStatus'
  | 'dossierFeedback'
  | 'interviewPrepRequestsAssignment'
  | 'talentEnablementEditorsStats'
  | 'talentAgents'
  | 'workDashboard'
/* TODO (matthewalbrecht): add extra search matches for pages here */
export const pages: Record<PageKeys, PageDetail> = {
  workDashboard: {
    pageName: 'Work Dashboard',
    to: (): string => generatePath(ROUTE_PATHS.WORK_DASHBOARD),
    permittedRoles: [Role.TalentAgent],
    category: 'Work',
  },
  sourcerQueue: {
    pageName: 'Sourcer Queue',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.SOURCER_QUEUE, {
        sourcerId: viewerId,
        requestId: INITIAL,
      }),
    permittedRoles: [Role.Sourcer],
    category: 'Work',
  },
  applierQueue: {
    pageName: 'Applier Queue',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.APPLIER_QUEUE, {
        applierId: viewerId,
        applicationId: INITIAL,
      }),
    permittedRoles: [Role.Applier],
    category: 'Work',
  },
  enrichmentQueue: {
    pageName: 'Enrichment Queue',
    to: (): string =>
      generatePath(ROUTE_PATHS.ENRICHER_QUEUE, { requestId: INITIAL }),
    permittedRoles: [Role.Enricher],
    category: 'Work',
  },
  dossierReviews: {
    pageName: 'Dossier Reviews',
    to: (): string => generatePath(ROUTE_PATHS.REVIEW_DOSSIERS),
    permittedRoles: [Role.TalentAgent, Role.TalentEnablement],
    category: 'Work',
  },
  dossierQueue: {
    pageName: 'Dossier Queue',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.DOSSIER_QUEUE, {
        dossierWriterId: viewerId,
        requestId: INITIAL,
      }),
    permittedRoles: [Role.DossierWriter],
    category: 'Work',
  },
  writerQueue: {
    pageName: 'Writer Queue',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.WRITER_QUEUE, {
        writerId: viewerId,
        requestId: INITIAL,
      }),
    permittedRoles: [Role.Writer],
    category: 'Work',
  },
  initialReviewQueue: {
    pageName: 'Initial Review Queue',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.AGENT_QUEUE, {
        talentAgentId: viewerId,
        requestId: INITIAL,
      }),
    permittedRoles: [Role.TalentAgent],
    category: 'Work',
  },
  finalReviewQueue: {
    pageName: 'Final Review Queue',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.PARTNER_QUEUE, {
        talentPartnerId: viewerId,
        requestId: INITIAL,
      }),
    permittedRoles: [Role.TalentPartner, Role.EnablementEditor],
    category: 'Work',
  },
  taglineStatus: {
    pageName: 'Tagline Status',
    to: (): string => ROUTE_PATHS.TAGLINE_STATUS,
    permittedRoles: ROLE_GROUPS.CORE_TEAM,
    category: 'Insight',
  },
  taglineFeedback: {
    pageName: 'Tagline Feedback',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.REVIEW_FEEDBACK_QUEUE, {
        writerId: viewerId,
        submissionId: INITIAL,
      }),
    permittedRoles: [Role.Writer],
    category: 'Insight',
  },
  taglineWorkLog: {
    pageName: 'Tagline Work Log',
    to: (): string => ROUTE_PATHS.TAGLINE_WORK_LOG,
    permittedRoles: ROLE_GROUPS.NON_TEA,
    category: 'Insight',
  },
  dossierFeedback: {
    pageName: 'Dossier Feedback',
    to: (): string => ROUTE_PATHS.DOSSIER_FEEDBACK,
    permittedRoles: [Role.DossierWriter, Role.Coordinator],
    category: 'Insight',
  },
  myTaglineStats: {
    pageName: 'My Tagline Stats',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.WRITER_TAGLINE_STATS, { writerId: viewerId }),
    permittedRoles: [Role.Writer],
    category: 'Insight',
  },
  sourcingReview: {
    pageName: 'Sourcing Review',
    to: (): string => ROUTE_PATHS.SOURCING_REVIEW,
    permittedRoles: [Role.Sourcer, Role.Coordinator],
    category: 'Insight',
  },
  mySourcingStats: {
    pageName: 'My Sourcing Stats',
    to: (viewerId: string): string =>
      generatePath(ROUTE_PATHS.MY_SOURCING_STATS, { sourcerId: viewerId }),
    permittedRoles: [Role.Sourcer],
    category: 'Insight',
  },
  redactJobs: {
    pageName: 'Redact Jobs',
    to: (): string => ROUTE_PATHS.REDACT_SOURCED_JOBS,
    permittedRoles: [Role.Sourcer],
    category: 'Insight',
  },
  reviewEnrichment: {
    pageName: 'Review Enrichment',
    to: (): string => ROUTE_PATHS.REDACT_ENRICHMENT,
    permittedRoles: [Role.Enricher],
    category: 'Insight',
  },
  sourcerAllocation: {
    pageName: 'Sourcer Allocation',
    to: (): string => ROUTE_PATHS.SOURCER_REVIEW,
    permittedRoles: [Role.Sourcer, Role.Coordinator],
    category: 'Insight',
  },
  sourcingAssignment: {
    pageName: 'Sourcing Assignment',
    to: (): string => ROUTE_PATHS.AVAILABLE_SOURCING,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  taglineAssignment: {
    pageName: 'Tagline Assignment',
    to: (): string => ROUTE_PATHS.AVAILABLE_TAGLINES,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  applicationAssignment: {
    pageName: 'Application Assignment',
    to: (): string => ROUTE_PATHS.AVAILABLE_APPLICATIONS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  dossierAssignment: {
    pageName: 'Dossier Assignment',
    to: (): string => ROUTE_PATHS.AVAILABLE_DOSSIERS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  expertRequestsAssignment: {
    pageName: 'Expert Requests',
    to: (): string => ROUTE_PATHS.EXPERT_REQUESTS,
    permittedRoles: [Role.ExpertCoordinator],
    category: 'Assign',
  },
  finalReviewAssignment: {
    pageName: 'Final Review Assignment',
    to: (): string => ROUTE_PATHS.AVAILABLE_FINAL_REVIEWS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  interviewPrepRequestsAssignment: {
    pageName: 'Interview Prep Requests',
    to: (): string => ROUTE_PATHS.INTERVIEW_PREP_REQUESTS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  autoSourcing: {
    pageName: 'Auto Sourcing',
    to: (): string => ROUTE_PATHS.AUTO_SOURCING_DASHBOARD,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  currentAssignments: {
    pageName: 'Current Assignments',
    to: (): string => ROUTE_PATHS.CURRENT_ASSIGNMENTS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  writersStats: {
    pageName: 'Writers',
    to: (): string => ROUTE_PATHS.REVIEWED_TAGLINES,
    permittedRoles: [Role.TalentPartner, Role.Coordinator],
    category: 'Assign',
  },
  sourcersStats: {
    pageName: 'Sourcers',
    to: (): string => ROUTE_PATHS.SOURCER_STATS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  sourcingStats: {
    pageName: 'Sourcing Stats',
    to: (): string => ROUTE_PATHS.SOURCING_STATS,
    permittedRoles: [Role.Coordinator],
    category: 'Assign',
  },
  clientStatus: {
    pageName: 'Client Status',
    to: (): string => ROUTE_PATHS.CLIENT_STATUS,
    permittedRoles: [Role.Coordinator, Role.TalentAgent],
    category: 'Insight',
  },
  clients: {
    pageName: 'Clients',
    to: (): string => ROUTE_PATHS.CLIENTS,
    permittedRoles: [Role.Coordinator],
    category: 'Accounts',
  },
  users: {
    pageName: 'Users',
    to: (): string => ROUTE_PATHS.USERS,
    permittedRoles: [Role.Coordinator],
    category: 'Accounts',
  },
  dossierLibrary: {
    pageName: 'Dossier Library',
    to: (): string => ROUTE_PATHS.DOSSIER_LIBRARY,
    permittedRoles: [Role.Coordinator, Role.TalentAgent, Role.TalentEnablement],
    category: 'Collection',
  },
  companies: {
    pageName: 'Companies',
    to: (): string => ROUTE_PATHS.COMPANY_SEARCH,
    permittedRoles: [
      Role.Coordinator,
      Role.TalentPartner,
      Role.TalentAgent,
      Role.TalentEnablement,
      Role.EnablementEditor,
      Role.Applier,
      Role.ExpertCoordinator,
    ],
    category: 'Collection',
  },
  expertNetwork: {
    pageName: 'Expert Network',
    to: (): string => ROUTE_PATHS.EXPERT_NETWORK,
    permittedRoles: [
      Role.ExpertCoordinator,
      Role.TalentAgent,
      Role.TalentEnablement,
    ],
    category: 'Collection',
  },
  contracts: {
    pageName: 'Contracts',
    to: (): string => ROUTE_PATHS.CONTRACTS,
    permittedRoles: [Role.SalesCoordinator],
    category: 'Collection',
  },
  talentEnablementEditorsStats: {
    pageName: 'TEE Stats',
    to: (): string => ROUTE_PATHS.TALENT_ENABLEMENT_EDITOR_STATS,
    permittedRoles: [Role.Coordinator, Role.TalentPartner],
    category: 'Assign',
  },
  talentAgents: {
    pageName: 'Talent Agents',
    to: (): string => ROUTE_PATHS.TALENT_AGENTS,
    permittedRoles: [Role.Coordinator, Role.TalentAgent],
    category: 'Insight',
  },
}
