import React from 'react'
import Icon from 'components/Icon/Icon'
import Flex from 'components/Flex'
import { BackgroundColor } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

export default function ImportantIndicator(): React.ReactElement {
  return (
    <BackgroundColor color="faBlue" rounded>
      <Padding vertical={0.75}>
        <Flex align="center" justify="center">
          <Padding right={1}>
            <Icon
              name="Star"
              height={14}
              width={14}
              primaryFill="white"
              display="block"
            />
          </Padding>
          <Txt size={14} color="white" bold>
            Marked as A-Track
          </Txt>
          <Padding left={1}>
            <Icon
              name="Star"
              height={14}
              width={14}
              primaryFill="white"
              display="block"
            />
          </Padding>
        </Flex>
      </Padding>
    </BackgroundColor>
  )
}
