import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import TalentAgentDashboard from 'views/TalentAgentDashboard/TalentAgentDashboard'
import { Params } from './TalentAgentDashboardPage.types'
import PrivateRoute from 'components/PrivateRoute'
import { GetTalentAgentByIdQuery } from 'generated/graphql'

export default function TalentAgentDashboardPage(): React.ReactElement {
  const { talentAgentId } = useParams<Params>()
  const { data } = useQuery<GetTalentAgentByIdQuery>(GET_TALENT_AGENT_BY_ID, {
    variables: { id: talentAgentId },
  })
  return (
    <TalentAgentDashboard
      talentAgentId={talentAgentId}
      userName={data?.user?.name ?? null}
    />
  )
}

const GET_TALENT_AGENT_BY_ID = gql`
  query GetTalentAgentById($id: ID!) {
    user(id: $id) {
      name
    }
  }
`

TalentAgentDashboardPage.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.TALENT_AGENTS_DASHBOARD}
    key={ROUTE_PATHS.TALENT_AGENTS_DASHBOARD}
  >
    <TalentAgentDashboardPage />
  </PrivateRoute>,
]
