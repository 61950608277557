import styled from 'styled-components/macro'

export const AutoSuggestStyles = styled.div`
  /* stylelint-disable block-no-empty */
  .react-autosuggest__container {
  }
  .react-autosuggest__container--open {
  }
  .react-autosuggest__input {
    border: 0.1rem solid ${({ theme }) => theme.color.lightGray};
    border-radius: ${({ theme }) => theme.toRems(10)};
    padding: ${({ theme }) => theme.rhythm(1.5)} ${({ theme }) => theme.rhythm(2)};
    color: ${({ theme }) => theme.color.text};
    width: ${({ theme }) => theme.toRems(389)};
    font-size: ${({ theme }) => theme.toRems(16)};
  }
  .react-autosuggest__input--open {
  }
  .react-autosuggest__input--focused {
  }
  .react-autosuggest__suggestions-container {
    max-height: ${({ theme }) => theme.toRems(300)};
    overflow: auto;
  }
  .react-autosuggest__suggestions-container--open {
    position: absolute;
    background-color: white;
    width: ${({ theme }) => theme.toRems(389)};
    border: 0.1rem solid ${({ theme }) => theme.color.lightGray};
    border-radius: ${({ theme }) => theme.rhythm(1)};
    z-index: 1;
  }
  .react-autosuggest__suggestions-list {
  }
  .react-autosuggest__suggestion {
    padding: ${({ theme }) => theme.rhythm(1)} ${({ theme }) => theme.rhythm(1)};
    border-radius: ${({ theme }) => theme.borderRadius};
    &:hover {
      background-color: ${({ theme }) => theme.color.faGrey1};
    }
  }
  .react-autosuggest__suggestion--first {
  }
  .react-autosuggest__suggestion--highlighted {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
  .react-autosuggest__section-container {
  }
  .react-autosuggest__section-container--first {
  }
  .react-autosuggest__section-title {
  }
  /* stylelint-enable block-no-empty */
`

export const NavButton = styled.button`
  border: 0.1rem solid ${({ theme }) => theme.color.lightGray};
  font-size: ${({ theme }) => theme.toRems(16)};
  color: ${({ theme }) => theme.color.faGrey5};
  padding: ${({ theme }) => theme.rhythm(1.5)} ${({ theme }) => theme.rhythm(3.5)};
  &:hover:enabled {
    background-color: ${({ theme }) => theme.color.faGrey1};
  }
  border-radius: ${({ theme }) => theme.toRems(10)};
`
