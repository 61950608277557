import dayjs from 'dayjs'
import React from 'react'
import {
  DATE_FORMAT,
  DOSSIER_TYPE_MAP,
  EMPTY_CELL_PLACEHOLEDER,
} from 'utils/constants'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import Icon from 'components/Icon/Icon'
import { DossierSubmission } from './DossierLibrary.types'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'

type Props = {
  dossierSubmission: DossierSubmission
}

export default function DossierLibraryRow({
  dossierSubmission,
}: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Txt size={14}>
          {dossierSubmission.employerName ?? EMPTY_CELL_PLACEHOLEDER}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{dossierSubmission.jobTitle ?? EMPTY_CELL_PLACEHOLEDER}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{dossierSubmission.client.name}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {dayjs(dossierSubmission.createdAt).format(DATE_FORMAT.DATE_AND_TIME)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{dossierSubmission.createdBy.name}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {DOSSIER_TYPE_MAP[dossierSubmission.dossierRequest.dossierType]}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {dossierSubmission.fileUrl ? (
            <ExternalLink url={dossierSubmission.fileUrl} inheritStyles>
              <Icon name="ExternalLink" height={14} width={14} primaryFill="link" />
            </ExternalLink>
          ) : (
            EMPTY_CELL_PLACEHOLEDER
          )}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
