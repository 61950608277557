// Adapted From: https://usehooks.com/useKeyPress/

import { useEffect } from 'react'

export default function useGlobalKeyUp(
  targetKey: string,
  onKeyPress: () => void
): void {
  // If pressed key is our target key then set to true
  function upHandler(event: KeyboardEvent): void {
    // We don't want our callback to fire if the key was pressed while a user is typing in a form
    if (
      (event.target as Element).matches(
        'input, textarea, option, optgroup, select, [contenteditable=true]'
      )
    ) {
      return
    }
    if (event.key === targetKey) {
      onKeyPress()
    }
  }
  // Add event listeners
  useEffect(() => {
    window.addEventListener('keyup', upHandler)
    // Remove event listeners on cleanup
    return () => {
      window.removeEventListener('keyup', upHandler)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []) // Empty array ensures that effect is only run on mount and unmount
}
