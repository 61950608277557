import React, { useState } from 'react'
import AddAndEditUser from './AddAndEditUser'
import Button from 'components/Button'

export default function UserAddButton(): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function handleClose() {
    setIsOpen(false)
  }

  return (
    <>
      <Button $type="highlight" large onClick={() => setIsOpen(true)}>
        Add User
      </Button>
      <AddAndEditUser handleClose={handleClose} isOpen={isOpen} />
    </>
  )
}
