import dayjs, { Dayjs } from 'dayjs'
import { Rating } from 'types'
import getNodes from 'utils/helpers/getNodes'
import parseRating from 'utils/helpers/parseRating'
import { getAvgRating } from 'utils/helpers/stats'
import { RowData, SourcedJob, SourcedJobConnection } from './SourcerWorkStats.types'
import { Maybe } from 'generated/graphql'

export function getRowData(
  connection: SourcedJobConnection,
  dateSince: Maybe<Dayjs>
): RowData {
  const normalizedSourcedJobs = normalizeSourcedJobs(connection)
  const filteredSourcedJobs = dateSince
    ? filterSourcedJobs(normalizedSourcedJobs, dateSince)
    : normalizedSourcedJobs
  return calculateStatsRow(filteredSourcedJobs)
}

/**
 * normalize the raw data from gql
 * @param connection sourced job connection
 */
export function normalizeSourcedJobs(
  connection: SourcedJobConnection
): SourcedJob[] {
  const rawSourcedJobs = getNodes(connection)

  return rawSourcedJobs.map((job) => ({
    rating: parseRating(job.reviews[0]?.rating),
    rejectionReason: job.reviews[0]?.rejectionReason,
    createdAtDayjs: dayjs(job.createdAt),
  }))
}

/**
 * filter the normalized data
 * @param sourcedJobs list of normalized sourced jobs
 * @param dateSince date filter user applied
 */
export function filterSourcedJobs(
  sourcedJobs: SourcedJob[],
  dateSince: Dayjs
): SourcedJob[] {
  return sourcedJobs.filter((job) => dateSince < job.createdAtDayjs)
}

/**
 * calculates the relevant stats for a row and adds it to the rowData array
 * @param tableData accumulating row data
 * @param group individual group
 */
function calculateStatsRow(sourcedJobs: SourcedJob[]): RowData {
  const total = sourcedJobs.length
  const ratings: Rating[] = []
  let rejectedCount = 0

  sourcedJobs.forEach((job) => {
    if (job.rating !== undefined) {
      ratings.push(job.rating)
    }
    if (job.rejectionReason) {
      rejectedCount += 1
    }
  })

  const [avgRating, avgRatingTotal, onesAndTwosRate] = getAvgRating(ratings)
  const rejectionRate = rejectedCount / total

  return {
    avgRating,
    avgRatingTotal,
    onesAndTwosRate,
    rejectionRate,
    total,
  }
}
