import { ApolloCache, gql, useMutation } from '@apollo/client'
import React from 'react'
import { Link, useHistory } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { LINKEDIN_REGEX } from 'utils/constants'
import { EnrichmentRejectionReason } from 'utils/enums'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import getQueueLabel from 'utils/helpers/getQueueLabel'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import RejectionButton from 'components/RejectionButton/RejectionButton'
import { getNextRouteUrl } from './EnrichmentRequest.helpers'
import { DEFAULT_STATE } from './EnrichmentSubmission.constants'
import { EnrichmentRequest, State } from './EnrichmentSubmission.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

const WriterSubmissionFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

type Props = {
  enrichmentRequest: EnrichmentRequest
  state: State
  setState: ReactSetState<State>
  timeSpentSec: number
  setTimeSpentSec: ReactSetState<number>
  queue: string[]
  podId: string | undefined
  requestId: string
}

function EnrichmentSubmissionFooter({
  enrichmentRequest,
  state,
  setState,
  timeSpentSec,
  setTimeSpentSec,
  queue,
  podId,
  requestId,
}: Props): React.ReactElement {
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, requestId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, podId)

  const hasChanged =
    state.targetLinkedinUrl ||
    state.targetName ||
    state.targetTitle ||
    state.targetEmail ||
    state.notes

  const [recordSubmission, { loading: loadingSubmission }] = useMutation(
    RECORD_ENRICHMENT_SUBMISSION,
    {
      update: updateCacheAfterSuccess,
    }
  )
  const [recordRejection, { loading: loadingRejection }] = useMutation(
    RECORD_ENRICHMENT_REJECTION,
    {
      update: updateCacheAfterSuccess,
    }
  )

  /**
   * this will remove the current request from the query in cache so that ui will properly update
   * @param cache Apollo's cache
   */
  function updateCacheAfterSuccess(cache: ApolloCache<unknown>) {
    /* TODO @matthewalbrecht: have this colocated with the query variables */
    const params = {
      inAirtable: false,
      isApplied: false,
      isSubmitted: false,
      orderBy: {
        direction: 'ASC',
        field: 'DUE',
      },
    }

    cache.modify({
      fields: {
        [`enrichmentRequests(${JSON.stringify(params)})`]: (
          queryResult,
          { readField }
        ) => removeCacheEdgeById(requestId, queryResult, readField),
      },
    })
  }

  /**
   *
   * @param data data returned from mutation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCompletion(isRejected: boolean) {
    const enrichmentInfo = `${enrichmentRequest.jobTitle ?? ''} @ ${
      enrichmentRequest.employerName ?? ''
    } for ${enrichmentRequest.client.name}`
    const enrichmentAction = isRejected ? 'rejected' : 'submitted'

    toast.success(`Enrichment: ${enrichmentInfo} was ${enrichmentAction}`)

    setState(DEFAULT_STATE)
    setTimeSpentSec(0)

    if (!nextRequestId) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl)
  }

  async function handleSubmission() {
    if (!validateInput()) {
      return
    }

    try {
      await recordSubmission({
        variables: {
          RecordEnrichmentSubmissionInput: {
            enrichmentRequestId: enrichmentRequest.id,
            timeSpentSec,
            ...state,
          },
        },
      })

      handleCompletion(false)
    } catch (error) {
      toast.error('There was an error submitting the enrichment')
    }
  }

  async function handleRejection(rejectionReason: EnrichmentRejectionReason) {
    try {
      await recordRejection({
        variables: {
          RecordEnrichmentRejectionInput: {
            enrichmentRequestId: enrichmentRequest.id,
            rejectionReason,
            timeSpentSec,
          },
        },
      })
      handleCompletion(true)
    } catch (error) {
      toast.error('There was an error submitting the enrichment')
    }
  }

  function validateInput() {
    if (state.targetLinkedinUrl && !LINKEDIN_REGEX.test(state.targetLinkedinUrl)) {
      toast.error('Linkedin needs to be a valid LinkedIn URL')
      return false
    }
    return true
  }

  function handleSkipClick() {
    const nextRequestId = getNextRequestIdInQueue(queue, requestId)

    if (!nextRequestId) {
      toast('End of queue')
    }

    setState(DEFAULT_STATE)
    setTimeSpentSec(0)

    history.push(nextRouteUrl)
  }

  const rejectionOptions = [
    {
      rejectionReason: EnrichmentRejectionReason.ALREADY_ENRICHED,
      callback: handleRejection,
      label: 'Already Enriched',
    },
    {
      rejectionReason: EnrichmentRejectionReason.JOB_POSTING_CLOSED,
      callback: handleRejection,
      label: 'Job Posting Closed',
    },
    {
      rejectionReason: EnrichmentRejectionReason.OTHER,
      callback: handleRejection,
      label: 'Other',
    },
  ]

  const queueLabel = getQueueLabel(queue, requestId)

  return (
    <WriterSubmissionFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          {/* TODO (matthewalbrecht) Prompt user to confirm if there they have filled out the form */}
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
          <RejectionButton<EnrichmentRejectionReason>
            rejectionOptions={rejectionOptions}
          />
          <Button
            $type="accept"
            onClick={handleSubmission}
            disabled={!hasChanged || loadingSubmission || loadingRejection}
          >
            Submit
          </Button>
        </HList>
      </Flex>
    </WriterSubmissionFooterBox>
  )
}

/**
 * record submission of enrichment
 */
const RECORD_ENRICHMENT_SUBMISSION = gql`
  mutation RecordEnrichmentSubmission(
    $RecordEnrichmentSubmissionInput: RecordEnrichmentSubmissionInput!
  ) {
    recordEnrichmentSubmission(input: $RecordEnrichmentSubmissionInput) {
      enrichmentSubmission {
        id
        enrichmentRequest {
          id
        }
      }
    }
  }
`

/**
 * record a rejection of enrichment
 */
const RECORD_ENRICHMENT_REJECTION = gql`
  mutation RecordEnrichmentRejection(
    $RecordEnrichmentRejectionInput: RecordEnrichmentRejectionInput!
  ) {
    recordEnrichmentRejection(input: $RecordEnrichmentRejectionInput) {
      enrichmentSubmission {
        id
        enrichmentRequest {
          id
        }
      }
    }
  }
`

EnrichmentSubmissionFooter.fragments = {
  enrichmentRequestInfo: gql`
    fragment EnrichmentSubmissionFooterInfo on EnrichmentRequest {
      id
      jobTitle
      employerName
      client {
        name
      }
    }
  `,
}

export default EnrichmentSubmissionFooter
