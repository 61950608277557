import { useQuery, gql } from '@apollo/client'
import React, { useState } from 'react'
import { useAuthContext } from 'context/auth'
import getNodes from 'utils/helpers/getNodes'
import { sortByPropAlt } from 'utils/sort'
import {
  ColumnSortState,
  ColumnSortType,
} from 'components/FullWidthTable/FullWidthTable.types'
import HeaderLabelSortable from 'components/FullWidthTable/HeaderLabelSortable'
import TableBody from '../Table/TableBody'
import TableBodyRow from '../Table/TableBodyRow'
import TableEmpty from '../Table/TableEmpty'
import TableHeaderRow from '../Table/TableHeaderRow'
import { columns } from './AgentTable.constants'
import { buildTableData } from './AgentTable.helpers'
import { HeaderData } from './AgentTable.styles'
import {
  AgentFilteredTableDataQuery,
  AgentFilteredTableDataQueryVariables,
} from 'generated/graphql'

type Props = {
  talentAgentId: string
}

export default function AgentFilteredTable({
  talentAgentId,
}: Props): React.ReactElement {
  const {
    userSession: { user },
  } = useAuthContext()
  const { data, loading } = useQuery<
    AgentFilteredTableDataQuery,
    AgentFilteredTableDataQueryVariables
  >(GET_CLIENTS_TAGLINE_REQUEST_BY_TALENT_AGENT, {
    variables: {
      talentAgentId,
      withFrInfo: user.isTalentPartner || user.isTee,
    },
    // Should be handled somewhere else probably
    skip: talentAgentId === '',
  })

  const [columnSortState, setColumnSortState] = useState<ColumnSortState>({
    orderBy: 'clientName',
    direction: ColumnSortType.ASC,
  })
  const clients = data?.clients ? getNodes(data.clients) : []
  const tableData = buildTableData(clients)

  const sortedTableData = tableData.sort((a, b) => {
    return sortByPropAlt(
      a,
      b,
      columnSortState.orderBy,
      columnSortState.direction !== ColumnSortType.ASC
    )
  })

  function handleColumnSortChange(columnId: string, value: ColumnSortState) {
    if (value.direction === ColumnSortType.ASC || value.orderBy !== columnId) {
      setColumnSortState({ orderBy: columnId, direction: ColumnSortType.DESC })
    } else {
      setColumnSortState({ orderBy: columnId, direction: ColumnSortType.ASC })
    }
  }

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {Object.values(columns).map((header) => (
            <HeaderData key={header.id}>
              <HeaderLabelSortable
                id={header.id}
                label={header.label}
                columnSortState={columnSortState}
                onColumnSortChange={handleColumnSortChange}
              />
            </HeaderData>
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {sortedTableData.map((tableInfo) => (
          <TableBodyRow tableInfo={tableInfo} key={tableInfo.id} />
        ))}
      </TableBody>
      {!tableData.length && (
        <TableEmpty message={loading ? 'Loading...' : 'No Clients Found'} />
      )}
    </table>
  )
}

/**
 * used by agents to fetch all of their clients' tagline requests
 */
const GET_CLIENTS_TAGLINE_REQUEST_BY_TALENT_AGENT = gql`
  query AgentFilteredTableData($talentAgentId: ID!, $withFrInfo: Boolean!) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          id
          ...ClientInfoForDashboardTableTA
        }
      }
    }
  }

  ${TableBodyRow.fragments.clientInfoTA}
`
