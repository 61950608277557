import { Dayjs } from 'dayjs'
import { SOD_HOUR, SOD_MINUTE } from 'utils/constants'
import { DatetimeRangeFilter } from 'generated/graphql'

export type DatetimeTzRangeFilter = {
  timezone: string
  start: Dayjs
  end: Dayjs
}

export default function getCreatedAtRangeFilter({
  start,
  end,
  timezone,
}: DatetimeTzRangeFilter): DatetimeRangeFilter {
  return {
    start: start.tz(timezone, true).hour(SOD_HOUR).minute(SOD_MINUTE).format(),
    end: end
      .tz(timezone, true)
      .add(1, 'day')
      .hour(SOD_HOUR)
      .minute(SOD_MINUTE)
      .format(),
  }
}
