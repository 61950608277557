export const COPY = {
  CONFIRM_CANCEL: 'Are you sure you want to permanently cancel this interview?',
  DECLINE_SUCCESS: 'Interview Canceled',
  DECLINE_ERROR: 'There was an error canceling the interview',
  CANCEL_EXPERT_REQUEST_SUCCESS: 'Expert request has been canceled',
  CANCEL_EXPERT_REQUEST_ERROR: 'There was an error canceling the expert request',
  CONFIRM_CANCEL_EXPERT_REQUEST:
    'Are you sure you want to cancel this expert request?',
  REVIEW_DOSSIER_SUCCESS: 'Dossier submission review successful',
  REVIEW_DOSSIER_ERROR: 'There was an error reviewing the dossier submission',
  REQUIRE_RATING: 'Rating is required to submit a review.',
  REQUIRE_FEEDBACK: 'Feedback is required to submit a review.',
  CLOSE_SUCCESS: 'Interview cycle successfully closed',
  CLOSE_ERROR: 'There was an error closing the interview cycle',
  CONFIRM_CLOSE: 'Are you sure you want to permanently close this interview cycle?',
}

export const INTERVIEW_HEADERS = [
  { label: '#', id: 'number' },
  { label: 'Date', id: 'date' },
  { label: 'Status', id: 'status' },
  { label: '', id: 'open' },
  { label: 'Debrief', id: 'debrief' },
  { label: '', id: 'cancel' },
]

export const EXPERT_COLUMN_INFO = {
  GRID_TEMPLATE_COLUMNS:
    'min-content min-content min-content min-content auto min-content min-content',
  COLUMN_LABELS: [
    { label: '#', id: 'number' },
    { label: 'Submitted', id: 'date' },
    { label: 'Due', id: 'due' },
    { label: 'Expert', id: 'expert' },
    { label: '', id: 'open' },
    { label: 'Status', id: 'status' },
    { label: '', id: 'cancel' },
  ],
}

export const INTERVIEW_PREP_HEADERS = [
  { label: '#', id: 'number' },
  { label: 'Submitted', id: 'date' },
  { label: 'Due', id: 'due' },
  { label: 'Type', id: 'type' },
  { label: '', id: 'open' },
  { label: 'Status', id: 'status' },
]

export const DOSSIER_HEADERS = [
  { label: '#', id: 'number' },
  { label: 'Submitted', id: 'date' },
  { label: 'Due', id: 'due' },
  { label: 'Type', id: 'type' },
  { label: 'Status', id: 'status' },
  { label: '', id: 'open' },
  { label: 'Feedback', id: 'feedback' },
  { label: 'Update', id: 'update' },
  { label: 'Link', id: 'link' },
]
