import React from 'react'
import Flex from './Flex'
import Padding from './Padding'
import Txt from './Txt'

type Props = {
  message: React.ReactNode
  vertical?: boolean
}

export default function Message({ message, vertical = false }: Props): JSX.Element {
  return (
    <Flex justify="center">
      <Padding top={vertical ? 0 : 4} vertical={vertical ? 4 : 0}>
        <Txt>{message}</Txt>
      </Padding>
    </Flex>
  )
}
