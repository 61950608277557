import { captureException } from '@sentry/react'
import { UUID_VALIDATION_REGEX } from 'utils/constants'

export default function isUUID(id: string): boolean {
  try {
    parseUUID(id)
  } catch (error) {
    captureException(error)
    return false
  }
  return true
}

function parseUUID(id: string) {
  if (!UUID_VALIDATION_REGEX.test(id)) {
    throw new Error(`UUID is not valid. Received: ${id}`)
  }
  return id
}

export function getRandomNumFromUUID(uuid: string): number {
  return (parseInt(uuid.replace(/-/gi, ''), 16) % 1000) / 999
}
