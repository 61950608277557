import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useState, useEffect } from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import DashboardCardClientHeadshot from 'components/DashboardCard/DashboardCardClientHeadshot'
import DashboardCardCompanyLogo from 'components/DashboardCard/DashboardCardCompanyLogo'
import { LAST_7_DAYS } from './TalentAgentClientCards.constants'
import { getClientCardsInfo } from './TalentAgentClientCards.helpers'
import {
  ClientCardStyle,
  LoadingIndicatorContainer,
  Divider,
  ClientCardRowLabel,
  ClientCardColumn,
  ClientCardHeader,
  TrialBadge,
  UpcomingInterviewsContainer,
  ClientCardContainer,
} from './TalentAgentClientCards.styles'
import { ClientCardInfo, DateFilter } from './TalentAgentClientCards.types'
import TalentAgentClientCardsJobInfoLabel from './TalentAgentClientCardsJobInfoLabel'
import Flex from 'components/Flex'
import LoadingIndicator from 'components/LoadingIndicator'
import Message from 'components/Message'
import { Line } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  GetClientCardsInfoQuery,
  GetClientCardsInfoQueryVariables,
} from 'generated/graphql'

type Props = {
  talentAgentId: string
}

export default function TalentAgentClientCards({
  talentAgentId,
}: Props): React.ReactElement {
  // Gets the filter range of first day of last week (Sun) to today
  // Used to match the filtering of past week to today by the other date groupings (sourcing, taglines, dossiers)
  const [dateFilter7Days, setDateFilter7Days] = useState<DateFilter | null>(null)
  const clientCardsInfoOptions = dateFilter7Days
    ? { variables: { talentAgentId, dateFilter7Days } }
    : { skip: true }
  useEffect(() => {
    setDateFilter7Days({
      start: dayjs().subtract(LAST_7_DAYS, 'days').format(),
      end: dayjs().format(),
    })
  }, [])
  const { data, loading, error } = useQuery<
    GetClientCardsInfoQuery,
    GetClientCardsInfoQueryVariables
  >(GET_CLIENT_CARDS_INFO, clientCardsInfoOptions)

  if (loading) {
    return (
      <LoadingIndicatorContainer>
        <LoadingIndicator />
      </LoadingIndicatorContainer>
    )
  }

  if (error) {
    return <Message vertical message="There was an error loading clients." />
  }

  const clients = data?.clients ? getNodes(data.clients) : []
  const clientCardsInfo = getClientCardsInfo(clients)

  return (
    <Padding top={3} bottom={2}>
      <ClientCardContainer>
        {clientCardsInfo.map((clientCardInfo) => (
          <ClientCard key={clientCardInfo.id} clientCardInfo={clientCardInfo} />
        ))}
      </ClientCardContainer>
    </Padding>
  )
}

type ClientCardProps = {
  clientCardInfo: ClientCardInfo
}

function ClientCard({ clientCardInfo }: ClientCardProps): React.ReactElement {
  return (
    <ClientCardStyle>
      <Padding vertical={2.75} horizontal={2.75}>
        <Flex align="center">
          <Padding right={1}>
            <DashboardCardClientHeadshot
              src={clientCardInfo.clientHeadshot}
              alt={clientCardInfo.name}
            />
          </Padding>
          <ClientCardHeader>
            <Link
              to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
                clientId: clientCardInfo.id,
              })}
            >
              <Txt color="darkGray" bold>
                {clientCardInfo.name}
              </Txt>
            </Link>
            {clientCardInfo.isInTrialPeriod && (
              <TrialBadge>
                <Txt size={12} color="faBlue">
                  TRIAL
                </Txt>
              </TrialBadge>
            )}
            <Padding top={1}>
              <Txt size={14}>{clientCardInfo.accountAge}</Txt>
            </Padding>
          </ClientCardHeader>
        </Flex>
        <Padding top={4}>
          <Flex>
            <Padding right={1.5}>
              <ClientCardColumn align="flex-start">
                <Txt size={14}>Cycles</Txt>
              </ClientCardColumn>
            </Padding>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>Active</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.activeCycles}</Txt>
            </ClientCardColumn>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>7 Days</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.activeCyclesPast7Days}</Txt>
            </ClientCardColumn>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>Total</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.allCycles}</Txt>
            </ClientCardColumn>
          </Flex>
        </Padding>
        <Padding top={4}>
          <Flex>
            <Padding right={1.5}>
              <ClientCardColumn align="flex-start">
                <Txt size={14}>Sourcing</Txt>
                <Padding vertical={2}>
                  <Txt size={14}>Taglines</Txt>
                </Padding>
                <Txt size={14}>Dossiers</Txt>
              </ClientCardColumn>
            </Padding>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>WIP</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.sourcingWIP}</Txt>
              <Padding vertical={2}>
                <Txt size={14}>{clientCardInfo.taglinesWIP}</Txt>
              </Padding>
              <Txt size={14}>{clientCardInfo.dossiersWIP}</Txt>
            </ClientCardColumn>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>7 Days</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.sourcedJobsPast7Days}</Txt>
              <Padding vertical={2}>
                <Txt size={14}>{clientCardInfo.taglinesPast7Days}</Txt>
              </Padding>
              <Txt size={14}>{clientCardInfo.dossiersPast7Days}</Txt>
            </ClientCardColumn>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>14 Days</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.sourcedJobsPast14Days}</Txt>
              <Padding vertical={2}>
                <Txt size={14}>{clientCardInfo.taglinesPast14Days}</Txt>
              </Padding>
              <Txt size={14}>{clientCardInfo.dossiersPast14Days}</Txt>
            </ClientCardColumn>
            <Divider />
            <ClientCardColumn>
              <ClientCardRowLabel>
                <Txt size={10}>Total</Txt>
              </ClientCardRowLabel>
              <Txt size={14}>{clientCardInfo.reviewedSourcedJobs}</Txt>
              <Padding vertical={2}>
                <Txt size={14}>{clientCardInfo.allTaglines}</Txt>
              </Padding>
              <Txt size={14}>{clientCardInfo.allDossiers}</Txt>
            </ClientCardColumn>
          </Flex>
          <div>
            <Padding top={3} bottom={1.5}>
              <Line color="lightGray" />
            </Padding>
            <Padding bottom={1}>
              <Txt size={14} color="darkGray" bold>
                Upcoming interviews
              </Txt>
            </Padding>
            {!clientCardInfo.upcomingInterviews.length && (
              <Txt size={14}>There are no upcoming interviews.</Txt>
            )}
            <UpcomingInterviewsContainer>
              <VList size={1}>
                {clientCardInfo.upcomingInterviews.map((interview) => (
                  <Flex key={interview.id} align="center">
                    <Padding right={1}>
                      <DashboardCardCompanyLogo
                        height={25}
                        width={25}
                        src={interview.company?.companyLogo}
                        alt={interview.company?.companyName}
                      />
                    </Padding>
                    <TalentAgentClientCardsJobInfoLabel jobInfo={interview} />
                  </Flex>
                ))}
              </VList>
            </UpcomingInterviewsContainer>
          </div>
        </Padding>
      </Padding>
    </ClientCardStyle>
  )
}

// TODO: Add createdAtRangeFilter for sourcedJobs, taglineRequests, and dossierSubmission

const GET_CLIENT_CARDS_INFO = gql`
  query GetClientCardsInfo(
    $talentAgentId: ID!
    $dateFilter7Days: DatetimeRangeFilter!
  ) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          ...ClientCardsInfo
        }
      }
    }
  }
  ${getClientCardsInfo.fragments.clientCardsInfo}
`
