import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { DashboardContent } from 'components/ClientDashboardCard/ClientDashboardCard.styles'
import { DashboardCard } from 'components/DashboardCard/DashboardCard.styles'
import DashboardCardTitle from 'components/DashboardCard/DashboardCardTitle'
import { getInProgressWorkStats } from './ClientDashboardCard.helpers'
import { Params } from './ClientDashboardCard.types'
import ClientDashboardNumberCard from './ClientDashboardNumberCard'
import Flex from 'components/Flex'
import HList from 'components/HList'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientInProgressWorkQuery,
  GetClientInProgressWorkQueryVariables,
} from 'generated/graphql'

export default function ClientDashboardInProgressWork(): React.ReactElement {
  const { clientId } = useParams<Params>()
  const { data, loading } = useQuery<
    GetClientInProgressWorkQuery,
    GetClientInProgressWorkQueryVariables
  >(GET_CLIENT_IN_PROGRESS_WORK, {
    variables: {
      id: clientId,
    },
  })
  const inProgressWorkStats = data?.client && getInProgressWorkStats(data)
  return (
    <>
      <DashboardCard>
        <DashboardCardTitle title="In progress work" />
        <DashboardContent>
          {loading || !inProgressWorkStats ? (
            <LoadingIndicator />
          ) : (
            <Padding top={1.75} bottom={2} horizontal={3}>
              <Flex>
                <Padding right={5.5}>
                  <Padding bottom={1.25}>
                    <Txt size={14} color="darkGray">
                      Sourcing
                    </Txt>
                  </Padding>
                  <Flex wrap="nowrap">
                    <Padding right={2}>
                      <ClientDashboardNumberCard
                        value={inProgressWorkStats.sourcingRequests}
                        label="Active"
                      />
                    </Padding>
                    <ClientDashboardNumberCard
                      value={inProgressWorkStats.sourcedJobsReview}
                      label="TA Review"
                    />
                  </Flex>
                </Padding>
                <div>
                  <Padding bottom={1.25}>
                    <Txt size={14} color="darkGray">
                      Taglines
                    </Txt>
                  </Padding>
                  <HList size={2}>
                    <ClientDashboardNumberCard
                      value={inProgressWorkStats.taglinesRequested}
                      label="Requested"
                    />
                    <ClientDashboardNumberCard
                      value={inProgressWorkStats.taglinesDrafting}
                      label="Drafting"
                    />
                    <ClientDashboardNumberCard
                      value={inProgressWorkStats.taglinesReviewed}
                      label="Review"
                    />
                    <ClientDashboardNumberCard
                      value={inProgressWorkStats.applicationsAppliedTo}
                      label="Being Applied"
                    />
                  </HList>
                </div>
              </Flex>
            </Padding>
          )}
        </DashboardContent>
      </DashboardCard>
    </>
  )
}

const GET_CLIENT_IN_PROGRESS_WORK = gql`
  query GetClientInProgressWork($id: ID!) {
    client(id: $id) {
      ...InProgressWorkStats
    }
  }
  ${getInProgressWorkStats.fragments.inProgressWorkStats}
`
