import dayjs from 'dayjs'
import { Colors, Rating } from 'types'
import { DATE_FORMAT, NOT_AVAILABLE, UNKNOWN } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { TrialClient } from 'components/DashboardCard/DashboardCard.types'
import { ClientInfo } from 'components/TalentAgentClientCards/TalentAgentClientCards.types'
import { TRIAL_PERIOD_LENGTH_DAYS } from './ClientTracker.constants'
import { Client, StatusUpdateFormState } from './ClientTracker.types'
import { AccountPersona } from 'generated/graphql'

type MaybeClient = Client | undefined | null

export function getThisWeekAndLastWeekDates(): {
  startOfWeek: string
  endOfWeek: string
  startOfLastWeek: string
  endOfLastWeek: string
} {
  // starts on sunday
  const startOfWeek = dayjs().startOf('week')
  const endOfWeek = dayjs().endOf('week')
  const startOfLastWeek = startOfWeek.subtract(7, 'days')
  const endOfLastWeek = endOfWeek.subtract(7, 'days')

  return {
    startOfWeek: startOfWeek.format(),
    endOfWeek: endOfWeek.format(),
    startOfLastWeek: startOfLastWeek.format(),
    endOfLastWeek: endOfLastWeek.format(),
  }
}

export function getSignDateValue(client: MaybeClient): string {
  if (!client?.signDateInitial) {
    return UNKNOWN.DATE
  }

  const signDate = dayjs(client.signDateInitial)
  const formattedSignDate = signDate.format(DATE_FORMAT.ONLY_DATE)
  const dateFromNow = signDate.fromNow(true)

  return `${formattedSignDate} (${dateFromNow})`
}

export function getIncomeShareRateValue(
  client: MaybeClient
): string | undefined | null {
  return (
    client?.incomeShareRateCurrent &&
    `${Math.round(Number(client.incomeShareRateCurrent) * 1000) / 10}%`
  )
}

export type AggregatedStats = {
  thisWeek: number | undefined
  lastWeek: number | undefined
  lifetime: number | undefined
  active?: number
}

export function getAppsData(client: MaybeClient): AggregatedStats {
  const totalApps = client?.totalApps.totalCount
  const thisWeekApps = client?.thisWeekApps.totalCount
  const lastWeekApps = client?.lastWeekApps.totalCount

  return {
    thisWeek: thisWeekApps,
    lastWeek: lastWeekApps,
    lifetime: totalApps,
  }
}

export function getCyclesData(client: MaybeClient): AggregatedStats {
  const totalCycles = client?.totalCycles.totalCount
  const thisWeekCycles = client?.thisWeekCycles.totalCount
  const lastWeekCycles = client?.lastWeekCycles.totalCount
  const activeCycles = client?.activeCycles.totalCount
  return {
    active: activeCycles,
    thisWeek: thisWeekCycles,
    lastWeek: lastWeekCycles,
    lifetime: totalCycles,
  }
}

enum HuntrColumns {
  Interested = 'Interested?',
  NotInterested = 'Not interested',
}

enum HuntrActionType {
  Created = 'CREATED',
}

export function getSourcingNotInterestedRateValue(client: MaybeClient): string {
  if (!client) {
    return NOT_AVAILABLE
  }
  const created = client.huntrJobActions.edges.filter(
    ({ node: action }) =>
      action.jobActionType === HuntrActionType.Created &&
      action.toListName === HuntrColumns.Interested
  )
  const notInterested = client.huntrJobActions.edges.filter(
    ({ node: action }) =>
      action.fromListName === HuntrColumns.Interested &&
      action.toListName === HuntrColumns.NotInterested
  )
  if (created.length === 0) {
    return NOT_AVAILABLE
  }
  return getAsPercentage(notInterested.length, created.length)
}

function getAsPercentage(totalValue: number, value: number): string {
  return `${Math.round((totalValue / value) * 100)}%`
}

export function getOutstandingOffers(
  client: MaybeClient
): number | undefined | null {
  const pendingJobOffers = client?.jobOffers?.edges.filter(
    ({ node }) => node.isPending
  )

  return pendingJobOffers && pendingJobOffers.length
}

export function getCallOneDate(client: MaybeClient): string | undefined | null {
  return (
    client?.callOneDate && dayjs(client.callOneDate).format(DATE_FORMAT.ONLY_DATE)
  )
}

export function isInTrialPeriod(
  client: MaybeClient | TrialClient | ClientInfo
): boolean {
  /* TODO (matthewalbrecht): reconsider how we display trial period on UI */
  return client?.callOneDate
    ? dayjs(client.callOneDate).add(TRIAL_PERIOD_LENGTH_DAYS + 1, 'days') > dayjs()
    : false
}

export function getInitialFormState(client: MaybeClient): StatusUpdateFormState {
  const statusUpdates = client?.statusUpdates ? getNodes(client.statusUpdates) : []
  const previousStatusUpdate = statusUpdates[0]

  return previousStatusUpdate
    ? {
        lastStatusMessage: previousStatusUpdate.statusMessage,
        statusMessage: null,
        accountPersonas: previousStatusUpdate.accountPersonas.reduce(
          (map, persona) => ({ ...map, [persona]: true }),
          {}
        ),
        relationshipStrength: previousStatusUpdate.relationshipStrength as Rating,
        satisfactionRating: previousStatusUpdate.satisfactionRating as Rating,
      }
    : {}
}

export function getTagColorFromPersona(accountPersonas: AccountPersona): {
  tagColor: keyof Colors
  textColor: keyof Colors
} {
  switch (accountPersonas) {
    case AccountPersona.EXECUTIVE:
      return { tagColor: 'purple', textColor: 'white' }
    case AccountPersona.HARD_TO_FIT:
      return { tagColor: 'yellow', textColor: 'text' }
    case AccountPersona.RESCUE:
      return { tagColor: 'errorLight', textColor: 'white' }
    case AccountPersona.STANDARD:
      return { tagColor: 'faGrey2', textColor: 'text' }
    case AccountPersona.TIME_SAVER:
      return { tagColor: 'faGreen', textColor: 'text' }
  }
}

export function getInterviewRate(client: MaybeClient): string {
  const apps = client?.totalApps.totalCount
  const cycles = client?.totalCycles.totalCount
  // if cycles is 0 we want to show 0% not unknown
  if (cycles != null && apps) {
    return `${Math.floor((cycles / apps) * 100)}%`
  }
  return UNKNOWN.UNKNOWN
}
