import { gql, useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { generatePath, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import { ReactSetState } from 'types'
import { useDebounceState } from 'hooks/useDebounceState'
import { EMPTY_INPUT } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { CompanySearchRow } from 'views/CompanySearch/CompanySearch'
import Icon from 'components/Icon/Icon'
import { CompanyMergeResultButton } from './CompanyMerge.styles'
import { Company, CompanySearchResult } from './CompanyMerge.types'
import Button from 'components/Button'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { TextInput } from 'components/Inputs'
import LoadingIndicator from 'components/LoadingIndicator'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import {
  MergeCompaniesMutation,
  MergeCompaniesMutationVariables,
  SearchCompaniesByNameQuery,
  SearchCompaniesByNameQueryVariables,
} from 'generated/graphql'

type Props = {
  company: Company
}

const SEARCH_DEBOUNCE_MS = 1000

export default function CompanyMergeSearch({ company }: Props): React.ReactElement {
  const [selectedCompany, setSelectedCompany] = useState<CompanySearchResult | null>(
    null
  )

  if (selectedCompany) {
    return (
      <CompanyMergeSelection
        company={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />
    )
  }

  return (
    <CompanySearchResults
      company={company}
      setSelectedCompany={setSelectedCompany}
    />
  )
}

type CompanySearchResultsProps = {
  company: Company
  setSelectedCompany: ReactSetState<CompanySearchResult | null>
}

function CompanySearchResults({
  company,
  setSelectedCompany,
}: CompanySearchResultsProps): React.ReactElement {
  const [searchTerm, setSearchTerm, debouncedSearchTerm] = useDebounceState<string>(
    company.name,
    SEARCH_DEBOUNCE_MS
  )
  const { data, loading, error, refetch } = useQuery<
    SearchCompaniesByNameQuery,
    SearchCompaniesByNameQueryVariables
  >(SEARCH_COMPANIES_BY_NAME, {
    variables: {
      search: searchTerm,
    },
  })

  useEffect(() => {
    if (debouncedSearchTerm && debouncedSearchTerm.length >= 3) {
      void refetch({
        search: debouncedSearchTerm,
      })
    }
  }, [debouncedSearchTerm, refetch])

  const companies = data?.companies
    ? getNodes(data.companies).filter((company) => !company.domain)
    : null

  return (
    <>
      <Padding bottom={4}>
        <HList size={2} rowGapSize={2}>
          <TextInput
            name="search"
            type="search"
            value={searchTerm || EMPTY_INPUT}
            placeholder="Search company..."
            onValueChange={(value) => setSearchTerm(value)}
            icon={<Icon name="Search" height={12} width={12} />}
            width={350}
          />
        </HList>
      </Padding>
      {loading && (
        <Padding top={10}>
          <Padding style={{ position: 'relative' }}>
            <LoadingIndicator />
          </Padding>
        </Padding>
      )}
      {error && (
        <Message
          vertical
          message="There was an error retrieving the company data."
        />
      )}
      {!error && !loading && companies && (
        <VList size={2}>
          {companies.map((company) => (
            <CompanyMergeResultButton
              key={company.id}
              onClick={() => setSelectedCompany(company)}
            >
              <Padding vertical={1} horizontal={3}>
                <Flex justify="space-between" align="center">
                  <Txt size={14}>{company.name}</Txt>
                  <Flex>
                    <div>
                      <Txt size={10} uppercase spacing={0.5}>
                        Sourced Jobs
                      </Txt>
                      <Txt size={16}>{company.sourcedJobs.totalCount}</Txt>
                    </div>
                    <Padding left={2}>
                      <Txt size={10} uppercase spacing={0.5}>
                        Apps
                      </Txt>
                      <Txt size={16}>{company.applications.totalCount}</Txt>
                    </Padding>
                  </Flex>
                </Flex>
              </Padding>
            </CompanyMergeResultButton>
          ))}
        </VList>
      )}
    </>
  )
}

const SEARCH_COMPANIES_BY_NAME = gql`
  query SearchCompaniesByName($search: String!) {
    companies(search: $search) {
      edges {
        node {
          ...SearchResultInfo
          sourcedJobs {
            edges {
              node {
                id
                url
              }
            }
          }
        }
      }
    }
  }
  ${CompanySearchRow.fragments.searchResultInfo}
`

type CompanyMergeSelectionProps = {
  company: CompanySearchResult
  setSelectedCompany: ReactSetState<CompanySearchResult | null>
}

function CompanyMergeSelection({
  company,
  setSelectedCompany,
}: CompanyMergeSelectionProps) {
  const { companyId: toCompanyId } = useParams<{ companyId: string }>()
  const [mergeCompanies] = useMutation<
    MergeCompaniesMutation,
    MergeCompaniesMutationVariables
  >(MERGE_COMPANIES)

  function handleMergeClick() {
    void mergeCompanies({
      variables: {
        input: {
          toCompany: {
            id: toCompanyId,
          },
          fromCompanies: [{ id: company.id }],
        },
      },
      onCompleted(data) {
        if (data.mergeCompanies.success) {
          toast.success(COPY.MERGE_SUCCESS)
          setSelectedCompany(null)
        } else {
          toast.error(COPY.MERGE_ERROR)
        }
      },
      onError() {
        toast.error(COPY.MERGE_ERROR)
      },
    })
  }

  return (
    <Padding style={{ height: '100%' }}>
      <Flex justify="space-between">
        <Button onClick={() => setSelectedCompany(null)}>
          <Padding inline right={0.5}>
            <Icon
              name="ArrowRight"
              height={14}
              width={14}
              nudge={{ down: 0.25 }}
              rotateDegrees={180}
            />
          </Padding>
          Back
        </Button>
        <Button $type="accept" onClick={handleMergeClick}>
          Merge
        </Button>
      </Flex>
      <Padding top={3}>
        <Txt size={20}>{company.name}</Txt>
      </Padding>
      <Padding top={4}>
        <Padding bottom={2}>
          <Txt>Sourced Jobs links</Txt>
        </Padding>
        <VList size={1}>
          {company.sourcedJobs.edges.map(({ node: { id, url } }) => (
            <div key={id}>
              <ExternalLink url={url}>{url}</ExternalLink>
            </div>
          ))}
        </VList>
      </Padding>
      <Padding top={3}>
        <Link
          to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, { companyId: company.id })}
        >
          <Txt as="span" link>
            See other related work
            <Icon
              name="ArrowRight"
              height={12}
              width={12}
              nudge={{ down: 0.25 }}
              primaryFill="link"
            />
          </Txt>
        </Link>
      </Padding>
    </Padding>
  )
}

const MERGE_COMPANIES = gql`
  mutation MergeCompanies($input: MergeCompaniesInput!) {
    mergeCompanies(input: $input) {
      success
    }
  }
`

const COPY = {
  MERGE_SUCCESS: 'Succesfully merged companies',
  MERGE_ERROR: 'There was an error merging companies',
}
