import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { UNKNOWN } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { StyledTxt } from 'components/DashboardCard/DashboardCard.styles'
import { UpcomingInterview } from 'components/TalentAgentClientCards/TalentAgentClientCards.types'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import Txt from 'components/Txt'

type Props = {
  jobInfo: UpcomingInterview
}

export default function TalentAgentClientCardsJobInfoLabel({
  jobInfo,
}: Props): React.ReactElement {
  function renderJobTitle(): React.ReactElement {
    if (jobInfo.jobUrl) {
      return (
        <ExternalLink url={jobInfo.jobUrl} txtProps={{ underline: false }}>
          <StyledTxt size={14} color="darkGray">
            {jobInfo.jobTitle}
          </StyledTxt>
        </ExternalLink>
      )
    }
    return (
      <Txt size={14} color="darkGray">
        {jobInfo.jobTitle}
      </Txt>
    )
  }

  function renderCompanyName(): React.ReactElement {
    if (!jobInfo.company) {
      return (
        <Txt size={14} color="darkGray">
          {UNKNOWN.EMPLOYER_NAME}
        </Txt>
      )
    }
    return (
      <Link
        to={generatePath(ROUTE_PATHS.COMPANY_DETAIL, {
          companyId: jobInfo.company.companyId,
        })}
      >
        <Txt size={14} color="darkGray">
          {jobInfo.company.companyName}
        </Txt>
      </Link>
    )
  }

  return (
    <Flex wrap="wrap">
      {renderJobTitle()}
      <Txt size={14} color="darkGray">
        &nbsp;@&nbsp;
      </Txt>
      {renderCompanyName()}
    </Flex>
  )
}
