import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { DATE_FORMAT, TZ } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { SOURCERS_PAGE_DAYS_AGO } from 'utils/settings'
import { FILTER_TYPES, GROUP_BY_OPTIONS } from './SourcerStats.constants'
import {
  calculateGroupStats,
  filterSourcedJobs,
  groupSourcedJobs,
  normalizeSourcedJobs,
  sortTableData,
} from './SourcerStats.helpers'
import { Filter } from './SourcerStats.types'
import SourcerStatsTable from './SourcerStatsTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { DatePicker, Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { GetSourcerStatsQuery } from 'generated/graphql'

function toETSoD(date: dayjs.Dayjs): dayjs.Dayjs {
  return date.tz(TZ.EASTERN).startOf('day')
}

function SourcerStats(): React.ReactElement {
  const earliestDate = toETSoD(dayjs()).subtract(SOURCERS_PAGE_DAYS_AGO, 'days')
  const [filter, setFilter] = useState<Filter>({
    groupBy: 'sourcerId',
    startDate: earliestDate,
  })

  const { data, loading } = useQuery<GetSourcerStatsQuery>(GET_SOURCER_STATS, {
    variables: {
      createdAtStart: filter.startDate.format(),
    },
  })

  // TODO (matthewalbrecht) think about using a hook for normalization, filter, group, calculation, and sort
  const normalizedSourcedJobs = useMemo(
    () => (data?.sourcedJobs ? normalizeSourcedJobs(data.sourcedJobs) : []),
    [data]
  )
  const filteredSourcedJobs = useMemo(
    () => filterSourcedJobs(normalizedSourcedJobs, filter),
    [normalizedSourcedJobs, filter]
  )
  const groupedSourcedJobs = useMemo(
    () => groupSourcedJobs(filteredSourcedJobs, filter.groupBy),
    [filteredSourcedJobs, filter.groupBy]
  )
  const unsortedTableData = useMemo(
    () => calculateGroupStats(groupedSourcedJobs),
    [groupedSourcedJobs]
  )
  const tableData = useMemo(
    () => sortTableData(unsortedTableData, filter.groupBy),
    [unsortedTableData, filter.groupBy]
  )

  // build options for dropdowns
  const freeAgentsOptions = buildDropdownOptions(data?.clients || [])
  const sourcerOptions = buildDropdownOptions(data?.sourcers || [])
  const talentAgentOptions = buildDropdownOptions(data?.talentAgents || [])

  /**
   *  sets the new filter object
   * @param value value of filter changed
   * @param filterType type of filter being updated
   */
  function handleFilterValueChange(value: string, filterType: keyof Filter) {
    setFilter({ ...filter, [filterType]: value })
  }

  /**
   *  sets the new startDate filter
   * @param value value of DatePicker change
   */
  function handleStartDateFilterValueChange(value: string) {
    if (!value) {
      return
    }
    setFilter({ ...filter, startDate: toETSoD(dayjs(value)) })
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt as="h2" bold size={24}>
            Sourcers
          </Txt>
          <Padding top={4}>
            <HList size={2} rowGapSize={2}>
              <Dropdown
                label="Group By"
                defaultValue={filter.groupBy}
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.GROUP_BY)
                }
                options={GROUP_BY_OPTIONS}
                withEmptyOption={false}
              />
              <Dropdown
                label="Sourcer"
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.SOURCER_ID)
                }
                options={sourcerOptions}
              />
              <Dropdown
                label="Talent Agent"
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.TALENT_AGENT_ID)
                }
                options={talentAgentOptions}
              />
              <Dropdown
                label="Free Agent"
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.CLIENT_ID)
                }
                options={freeAgentsOptions}
              />
              <DatePicker
                label="Date Since (in ET)"
                onValueChange={handleStartDateFilterValueChange}
                name="startDate"
                min={earliestDate.format(DATE_FORMAT.ONLY_DATE)}
                value={filter.startDate.format(DATE_FORMAT.ONLY_DATE)}
              />
            </HList>
          </Padding>
        </Padding>
      </Container>
      <SourcerStatsTable tableData={tableData} groupBy={filter.groupBy} />
      {loading && <Message message="Loading sourcer stats..." />}
      {!loading && !tableData.length && <Message message="No sourcers found." />}
    </ViewBox>
  )
}

SourcerStats.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.SOURCER_STATS}
    key={ROUTE_PATHS.SOURCER_STATS}
  >
    <SourcerStats />
  </PrivateRoute>,
]

export default SourcerStats

const GET_SOURCER_STATS = gql`
  query GetSourcerStats($createdAtStart: Datetime!) {
    sourcedJobs(
      createdAtRangeFilter: { start: $createdAtStart }
      isRedacted: false
    ) {
      edges {
        node {
          id
          createdAt
          createdBy {
            id
            name
          }
          reviews {
            id
            rating
            rejectionReason
          }
          client {
            id
            name
            talentAgent {
              id
              name
            }
          }
        }
      }
    }
    clients: allClients {
      id
      name
    }
    sourcers: users(anyRole: { isSourcer: true }) {
      id
      name
    }
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
    }
  }
`
