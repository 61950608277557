import React from 'react'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { DateFilter } from './ReviewEnrichment.constants'
import { EnrichmentSubmission } from './ReviewEnrichment.types'
import ExternalLink from 'components/ExternalLink'
import Txt from 'components/Txt'

type Props = {
  rowData: EnrichmentSubmission
  dateFilter: DateFilter
}

export default function ReviewEnrichmentRow({ rowData }: Props): React.ReactElement {
  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.enrichmentRequest.employerName}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.enrichmentRequest.jobTitle}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.targetName || '–'}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.targetEmail || '–'}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.targetTitle || '–'}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.targetLinkedinUrl ? (
            <ExternalLink
              label="Link"
              inheritStyles
              url={rowData.targetLinkedinUrl}
            />
          ) : (
            <Txt as="span" size={14}>
              No Link Found
            </Txt>
          )}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          <ExternalLink
            label="Link"
            inheritStyles
            url={rowData.enrichmentRequest.huntrUrl}
          />
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {rowData.createdBy.name}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}
