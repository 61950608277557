import { gql, useQuery } from '@apollo/client'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { HEADERS } from './DossierLibrary.constants'
import { filterDossierSubmission } from './DossierLibrary.helpers'
import DossierLibraryRow from './DossierLibraryRow'
import { DossierLibraryQuery, Maybe } from 'generated/graphql'

type Props = {
  searchTerm: Maybe<string>
}

export default function DossierLibraryTable({
  searchTerm,
}: Props): React.ReactElement {
  const { data, loading, error } =
    useQuery<DossierLibraryQuery>(DOSSIER_LIBRARY_QUERY)
  let dossierSubmissions = data?.dossierSubmissions
    ? getNodes(data.dossierSubmissions).sort((a, b) =>
        sortByProp(a, b, 'employerName')
      )
    : []
  dossierSubmissions = filterDossierSubmission(dossierSubmissions, searchTerm)

  return (
    <FullWidthTable
      headers={HEADERS}
      isEmpty={
        !loading && Boolean(data?.dossierSubmissions) && !dossierSubmissions.length
      }
      isLoading={loading}
      isError={Boolean(error)}
      emptyMessage="Could not find any dossiers."
      loadingMessage="Loading dossiers..."
      errorMessage="There was an error fetching dossiers."
    >
      {dossierSubmissions.map((dossierSubmission) => (
        <DossierLibraryRow
          key={dossierSubmission.id}
          dossierSubmission={dossierSubmission}
        />
      ))}
    </FullWidthTable>
  )
}

const DOSSIER_LIBRARY_QUERY = gql`
  query DossierLibrary {
    dossierSubmissions(isRejected: false) {
      edges {
        node {
          id
          jobTitle
          employerName
          createdAt
          client {
            id
            name
          }
          fileUrl
          dossierRequest {
            id
            dossierType
          }
          createdBy {
            id
            name
          }
        }
      }
    }
  }
`
