import ROUTE_PATHS from 'utils/routePaths'

export const ASSIGN_DATA = {
  header: 'Available Taglines',
  route: ROUTE_PATHS.AVAILABLE_TAGLINES,
}
export const REASSIGN_DATA = {
  header: 'Assigned Taglines',
  route: ROUTE_PATHS.ASSIGNED_TAGLINES,
}

export const COLUMNS = [
  {
    name: '',
    id: 'checkbox',
    collapse: true,
  },
  {
    name: 'Free Agent',
    id: 'freeAgent',
    hideOnAvailable: true,
  },
  {
    name: 'Job Title',
    id: 'jobTitle',
  },
  {
    name: 'Company',
    id: 'company',
  },
  {
    name: 'Due Date',
    id: 'due',
    collapse: true,
  },
  {
    name: 'Submission Date',
    id: 'submittedDate',
    hideOnAssigned: true,
  },
  {
    name: 'Assignment',
    id: 'assignment',
    collapse: true,
  },
  {
    name: 'Edit',
    id: 'edit',
    collapse: true,
  },
  {
    name: 'Remove',
    id: 'remove',
    collapse: true,
  },
]
