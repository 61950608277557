import dayjs from 'dayjs'
import { KeyValue } from 'types'
import { DATE_FORMAT } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import { OptionValues } from './DossierTable.constants'
import {
  DossierRequestConnection,
  DossierRequest,
  GroupedDossierRequestsByClient,
  GroupedDossierRequestsByDate,
  AllTableData,
} from './DossierTable.types'

export function normalizeDossierRequests(
  connection: DossierRequestConnection
): DossierRequest[] {
  const dossierRequests = getNodes(connection)

  return dossierRequests.map((request) => ({
    id: request.id,
    due: request.due,
    sortableDue: dayjs(request.due),
    // this is used for grouping requests together
    // theres a limited amount of times options
    dateAndTime: dayjs(request.due).format(DATE_FORMAT.DATE_AND_TIME),
    clientId: request.client.id,
    clientName: request.client.name,
  }))
}

export function groupDossierRequests(
  dossierRequests: DossierRequest[]
): AllTableData {
  return {
    [OptionValues.MyWork]: groupDossierRequestsByClient(dossierRequests),
    [OptionValues.ByDueDate]: groupDossierRequestsByDate(dossierRequests),
  }
}

/**
 * builds the array of row data for table by client
 * @param dossierRequests list of dossier requests
 */
function groupDossierRequestsByClient(
  dossierRequests: DossierRequest[]
): GroupedDossierRequestsByClient[] {
  const groupedDossierRequests = dossierRequests.reduce(
    (groups: KeyValue<GroupedDossierRequestsByClient>, request) => {
      const groupValue = request.clientId
      const entry = groups[groupValue]

      if (entry) {
        entry.dossierRequests.push(request)
      } else {
        groups[groupValue] = {
          clientId: groupValue,
          clientName: request.clientName,
          dossierRequests: [request],
        }
      }
      return groups
    },
    {}
  )
  return Object.values(groupedDossierRequests).sort((a, b) =>
    sortByProp(a, b, 'clientName')
  )
}

/**
 * builds the array of row data for table by due date
 * @param dossierRequests list of dossier requests
 */
function groupDossierRequestsByDate(
  dossierRequests: DossierRequest[]
): GroupedDossierRequestsByDate[] {
  const groupedDossierRequests = dossierRequests.reduce(
    (groups: KeyValue<GroupedDossierRequestsByDate>, request) => {
      const groupValue = request.dateAndTime
      const entry = groups[groupValue]

      if (entry) {
        entry.dossierRequests.push(request)
      } else {
        groups[groupValue] = {
          due: request.due,
          sortableDue: request.sortableDue,
          dateAndTime: groupValue,
          dossierRequests: [request],
        }
      }
      return groups
    },
    {}
  )
  return Object.values(groupedDossierRequests).sort((a, b) =>
    sortByProp(a, b, 'sortableDue')
  )
}
