import dayjs, { Dayjs } from 'dayjs'
import { Rating } from 'types'
import getNodes from 'utils/helpers/getNodes'
import parseRating from 'utils/helpers/parseRating'
import { getAvgTimeSpentSec, getAvgRating } from 'utils/helpers/stats'
import {
  TableRowData,
  TaglineSubmission,
  TaglineSubmissionConnection,
} from './WriterTaglineStats.types'

export function buildTableData(
  connection: TaglineSubmissionConnection,
  fromDate: Dayjs | null
): TableRowData {
  const rawTaglineSubmissions = getNodes<TaglineSubmission>(connection)
  const filteredTaglines = rawTaglineSubmissions.filter(
    (taglineSubmission) => !fromDate || dayjs(taglineSubmission.createdAt) > fromDate
  )

  const total = filteredTaglines.length
  const times: number[] = []
  const talentAgentRatings: Rating[] = []
  const talentPartnerRatings: Rating[] = []
  let rejectedCount = 0

  filteredTaglines.forEach((taglineSubmission) => {
    const talentAgentReview = taglineSubmission.talentAgentReviews[0]
    const talentPartnerReview = taglineSubmission.talentPartnerReviews[0]

    times.push(taglineSubmission.timeSpentSec)
    if (talentAgentReview) {
      talentAgentRatings.push(parseRating(talentAgentReview.rating))
    }
    if (talentPartnerReview) {
      talentPartnerRatings.push(parseRating(talentPartnerReview.ratingWriter))
    }
    if (talentPartnerReview?.taglineRejectionReason) {
      rejectedCount += 1
    }
  })

  const [avgTimeSpentSec, avgTimeSpentSecTotal] = getAvgTimeSpentSec(times)
  const rejectionRate = total ? rejectedCount / total : NaN
  const [
    avgTalentAgentRating,
    avgTalentAgentRatingTotal,
    talentAgentOnesAndTwosRate,
  ] = getAvgRating(talentAgentRatings)
  const [
    avgTalentPartnerRating,
    avgTalentPartnerRatingTotal,
    talentPartnerOnesAndTwosRate,
  ] = getAvgRating(talentPartnerRatings)

  return {
    total,
    avgTimeSpentSec,
    avgTimeSpentSecTotal,
    avgTalentAgentRating,
    avgTalentAgentRatingTotal,
    talentAgentOnesAndTwosRate,
    avgTalentPartnerRating,
    avgTalentPartnerRatingTotal,
    talentPartnerOnesAndTwosRate,
    rejectionRate,
  }
}
