import dayjs, { Dayjs } from 'dayjs'
import { SortDateOptions } from 'utils/enums'
import { sortByProp } from 'utils/sort'

type Client = {
  id: string
  name?: string
  taglineRequests: RawTaglineRequest[]
}

type RawTaglineRequest = {
  id: string
  due: string
  createdAt?: string
  talentAgentReviews?: {
    id: string
    createdAt: string
  }[]
  client?: {
    id: string
  }
  writerSubmissions?: {
    createdAt: string
  }[]
}

type SortableTaglineRequest = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [prop: string]: any
  id: string
  dateToSortBy: Dayjs
}

// builds a queue grouped by client sorted by a given date
export function buildSortedQueueByDate(
  clients: Client[],
  sortDateFunction: SortDateOptions
): SortableTaglineRequest[][] {
  const taglineMapFunction = sortDate[sortDateFunction]

  return clients
    .map((client) =>
      client.taglineRequests
        .map(taglineMapFunction)
        .map((request) => ({
          ...request,
          clientId: client.id,
          clientName: client.name,
        }))
        .sort((a, b) => sortByProp(a, b, 'dateToSortBy'))
    )
    .filter((client: SortableTaglineRequest[]) => client.length)
    .sort((a, b) => sortByProp(a[0], b[0], 'dateToSortBy'))
}

const sortDate = {
  [SortDateOptions.FROM_TALENT_AGENT_REVIEW]: (
    request: RawTaglineRequest
  ): SortableTaglineRequest => {
    return {
      ...request,
      dateToSortBy: dayjs(request.talentAgentReviews?.[0]?.createdAt),
    }
  },
  [SortDateOptions.FROM_DUE]: (
    request: RawTaglineRequest
  ): SortableTaglineRequest => {
    return { ...request, dateToSortBy: dayjs(request.due) }
  },
  [SortDateOptions.FROM_WRITER_SUBMISSION]: (
    request: RawTaglineRequest
  ): SortableTaglineRequest => {
    return {
      ...request,
      dateToSortBy: dayjs(request.writerSubmissions?.[0]?.createdAt),
    }
  },
}
