import React, { useCallback, useRef, useState } from 'react'
import { DropdownGroup, DropdownOption, ReactSetState, RejectionTypes } from 'types'
import useOutsideClick from 'hooks/useOutsideClick'
import { Options, RejectButtonBox } from './RejectionButton.styles'
import { RejectionPositions } from './RejectionButton.types'
import Button, { ButtonProps } from 'components/Button'
import { Dropdown } from 'components/Inputs'

type Props<T extends RejectionTypes> = {
  dropdownOptions: DropdownOption<T>[] | DropdownGroup<T>[]
  disabled?: boolean
  buttonChildren?: React.ReactElement
  onSelection: (rejectionReason: T) => void
  position?: RejectionPositions
  buttonProps?: ButtonProps
  buttonLabel?: string
}

export default function RejectionDropdownButton<T extends RejectionTypes>({
  dropdownOptions,
  disabled = false,
  buttonChildren,
  onSelection,
  position = 'top',
  buttonProps = {},
  buttonLabel = 'Reject',
}: Props<T>): JSX.Element {
  const [showOptions, setShowOptions] = useState<boolean>(false)

  function handleSubmit(rejectionReason: T) {
    onSelection(rejectionReason)
    setShowOptions(false)
  }

  return (
    <RejectButtonBox>
      {buttonChildren ? (
        <button
          style={{ display: 'block' }}
          onClick={() => setShowOptions(!showOptions)}
          disabled={disabled}
          {...buttonProps}
        >
          {buttonChildren}
        </button>
      ) : (
        <Button
          $type="reject"
          onClick={() => setShowOptions(!showOptions)}
          disabled={disabled}
          {...buttonProps}
        >
          {buttonLabel}
        </Button>
      )}
      {showOptions && (
        <ContentBox<T>
          position={position}
          dropdownOptions={dropdownOptions}
          handleSubmit={handleSubmit}
          setShowOptions={setShowOptions}
        />
      )}
    </RejectButtonBox>
  )
}

type ContentBoxProps<T extends RejectionTypes> = {
  position: RejectionPositions
  dropdownOptions: DropdownOption<T>[] | DropdownGroup<T>[]
  setShowOptions: ReactSetState<boolean>
  handleSubmit(rejectionReason: T): void
}

/**
 * Split this component up because useOutsideClick will create an event listener
 * and hundereds of these buttons could be on a page so moving useOutside click here will only
 * created event listeners if the user has clicked on the button
 */
function ContentBox<T extends RejectionTypes>({
  position,
  dropdownOptions,
  handleSubmit,
  setShowOptions,
}: ContentBoxProps<T>) {
  const node = useRef<HTMLDivElement>(null)
  const handleOutsideClick = useCallback(
    () => setShowOptions(false),
    [setShowOptions]
  )
  useOutsideClick(node, handleOutsideClick)

  return (
    <Options active position={position} ref={node}>
      <Dropdown
        options={dropdownOptions}
        placeholder="Select an option"
        defaultValue=""
        withEmptyOption={false}
        onValueChange={(value) => handleSubmit(value as T)}
      />
    </Options>
  )
}
