import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { ReactSetState } from 'types'
import { TaglineRejectionReason } from 'utils/enums'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import getQueueLabel from 'utils/helpers/getQueueLabel'
import ROUTE_PATHS from 'utils/routePaths'
import RejectionButton from 'components/RejectionButton/RejectionButton'
import { DEFAULT_FORM_DATA, Resource } from './FinalReview.constants'
import { updateCacheAfterSuccess } from './FinalReview.helpers'
import { FormState, TaglineRequest } from './FinalReview.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  SubmitPartnerReviewWork2Mutation,
  SubmitPartnerReviewWork2MutationVariables,
  SubmitTalentPartnerRejection2Mutation,
  SubmitTalentPartnerRejection2MutationVariables,
  TaglineRejectionReason as TaglineRejectionReasonGQL,
} from 'generated/graphql'

type Props = {
  getTimeSpentSec: () => number
  formState: FormState
  setFormState: ReactSetState<FormState>
  queue: string[]
  taglineRequest: TaglineRequest
  requestId: string
  nextRouteUrl: string | null
}

const FinalReviewFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

function FinalReviewFooter({
  taglineRequest,
  queue,
  formState,
  setFormState,
  getTimeSpentSec,
  requestId,
  nextRouteUrl,
}: Props): JSX.Element {
  const history = useHistory()

  const [submitPartnerReview2, { loading: loadingSubmitTaglineReview2 }] =
    useMutation<
      SubmitPartnerReviewWork2Mutation,
      SubmitPartnerReviewWork2MutationVariables
    >(TP_SUBMIT_TAGLINE_REVIEW_2, {
      update(cache) {
        updateCacheAfterSuccess(cache, taglineRequest)
      },
    })

  const [submitPartnerRejection2, { loading: loadingRejected }] = useMutation<
    SubmitTalentPartnerRejection2Mutation,
    SubmitTalentPartnerRejection2MutationVariables
  >(TP_SUBMIT_TAGLINE_REJECTION, {
    update(cache) {
      updateCacheAfterSuccess(cache, taglineRequest)
    },
  })

  const [submitTaglineRequestRejection, { loading: loadingTaglineRequestRejected }] =
    useMutation(TP_TAGLINE_REQUEST_REJECTION, {
      update(cache) {
        updateCacheAfterSuccess(cache, taglineRequest)
      },
    })

  /**
   *
   * @param data data returned from mutation
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function handleCompletion(isRejected: boolean, resource: Resource) {
    const taglineInfo = `${getJobInfoLabel(taglineRequest)} for ${
      taglineRequest.client.name
    }`
    const taglineAction = isRejected ? 'rejected' : 'submitted'

    toast.success(`${resource}: ${taglineInfo} was ${taglineAction}`)

    resetState()

    if (!nextRouteUrl) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl || ROUTE_PATHS.HOME)
  }

  async function handleSubmitReview() {
    const taglineSubmission = taglineRequest.writerSubmissions[0]
    if (!taglineSubmission) {
      return
    }

    const talentAgentReview = taglineRequest.talentAgentReviews[0]
    let ratingTalentAgent = undefined
    let feedbackTalentAgent = undefined
    let talentAgentReviewId = undefined
    if (talentAgentReview) {
      ratingTalentAgent = validateTalentAgentRating()
      if (ratingTalentAgent == null) {
        return
      }
      feedbackTalentAgent = formState.feedbackTalentAgent
      talentAgentReviewId = talentAgentReview.id
    }

    const submissionData = {
      // TODO: adjust how this works once multiple submissions are possible
      talentAgentReviewId,
      taglineRequestId: taglineRequest.id,
      taglineSubmissionId: taglineSubmission.id,
      timeSpentSec: getTimeSpentSec(),
      feedbackWriter: formState.feedbackWriter,
      ratingWriter: formState.ratingWriter,
      feedbackTalentAgent,
      ratingTalentAgent,
    }

    const items = talentAgentReview
      ? talentAgentReview.items.map((item) => ({
          talentAgentReviewItemId: item.id,
          taglineSubmissionItemId: item.taglineSubmissionItem.id,
          copy: formState.editorChanges[item.id] ?? item.copy,
        }))
      : taglineSubmission.items.map((item) => ({
          taglineSubmissionItemId: item.id,
          copy: formState.editorChanges[item.id] ?? item.copy,
        }))

    const variables = {
      SubmitTalentPartnerReviewInput: {
        ...submissionData,
        items,
      },
    }

    try {
      await submitPartnerReview2({ variables })
      handleCompletion(false, Resource.TaglineReview)
    } catch {
      toast.error('There was an error submitting your work')
    }
  }

  /**
   * handles validation, building variable, and executing mutation for rejecting a tagline/review
   * @param taglineRejectionReason reason for rejecting tagline/review
   */
  async function handleSubmitRejectionReview(
    taglineRejectionReason: TaglineRejectionReason
  ) {
    const taglineSubmission = taglineRequest.writerSubmissions[0]
    if (!taglineSubmission) {
      return
    }

    const talentAgentReview = taglineRequest.talentAgentReviews[0]
    let ratingTalentAgent = undefined
    let feedbackTalentAgent = undefined
    let talentAgentReviewId = undefined
    if (talentAgentReview) {
      ratingTalentAgent = validateTalentAgentRating(taglineRejectionReason)
      if (ratingTalentAgent == null) {
        return
      }
      feedbackTalentAgent = formState.feedbackTalentAgent
      talentAgentReviewId = talentAgentReview.id
    }

    const variables = {
      SubmitTalentPartnerRejectionInput: {
        // TODO: adjust how this works once multiple submissions are possible
        talentAgentReviewId,
        taglineRequestId: taglineRequest.id,
        taglineSubmissionId: taglineSubmission.id,
        /*
          TODO (matthewalbrecht): the only option that will arrive here is unusabled tagline this is a bandaid solve
          for an issue regarding the typing of RejectionButton.  Rejection options is actually a combination of two different enums (from the generated types)
          and our rejection button generic type in it's current form can't handle that.
        */
        taglineRejectionReason: TaglineRejectionReasonGQL.UNUSABLE_TAGLINE,
        feedbackWriter: formState.feedbackWriter,
        ratingWriter: formState.ratingWriter,
        feedbackTalentAgent,
        ratingTalentAgent,
      },
    }

    try {
      await submitPartnerRejection2({ variables })
      handleCompletion(true, Resource.TaglineReview)
    } catch {
      toast.error('There was an error rejecting the tagline')
    }
  }

  /**
   * handles validation, building variable, and executing mutation for rejecting a tagline request
   * @param rejectionReason reason for rejecting the tagline request
   */
  async function handleRejectTaglineRequest(
    rejectionReason: TaglineRejectionReason
  ) {
    const variables = {
      RejectTaglineRequestByTalentPartnerInput: {
        taglineRequestId: taglineRequest.id,
        rejectionReason,
      },
    }

    try {
      await submitTaglineRequestRejection({ variables })
      handleCompletion(true, Resource.TaglineRequest)
    } catch {
      toast.error('There was an error rejecting the tagline request')
    }
  }

  /**
   * validate formData before mutating
   * @param rejectionReason (optional) reason for rejecting tagline or tagline request
   */
  function validateTalentAgentRating(rejectionReason?: TaglineRejectionReason) {
    const requireRating =
      !rejectionReason || rejectionReason === TaglineRejectionReason.UNUSABLE_TAGLINE

    if (requireRating && formState.ratingTalentAgent != null) {
      return formState.ratingTalentAgent
    }

    toast.error('Must provide rating for Initial Reviewer')
    return null
  }

  function handleSkipClick() {
    if (!nextRouteUrl) {
      toast('End of queue')
    }

    setFormState(DEFAULT_FORM_DATA)

    history.push(nextRouteUrl || ROUTE_PATHS.HOME)
  }

  /**
   * resets of state values
   */
  function resetState() {
    setFormState(DEFAULT_FORM_DATA)
  }

  const queueLabel = getQueueLabel(queue, requestId)

  const rejectionOptions = [
    {
      rejectionReason: TaglineRejectionReason.JOB_POSTING_CLOSED,
      callback: handleRejectTaglineRequest,
      label: 'Job Posting Closed',
    },
    {
      rejectionReason: TaglineRejectionReason.FA_ALREADY_PLACED,
      callback: handleRejectTaglineRequest,
      label: 'FA Already Placed',
    },
    {
      rejectionReason: TaglineRejectionReason.BAD_JOB_FIT,
      callback: handleRejectTaglineRequest,
      label: 'Bad Job Fit',
    },
    {
      rejectionReason: TaglineRejectionReason.UNUSABLE_TAGLINE,
      callback: handleSubmitRejectionReview,
      label: 'Unusable Tagline',
    },
  ]

  return (
    <FinalReviewFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
          Cancel
        </Button>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
          <RejectionButton<TaglineRejectionReason>
            rejectionOptions={rejectionOptions}
          />
          <Button
            $type="accept"
            onClick={handleSubmitReview}
            disabled={
              loadingSubmitTaglineReview2 ||
              loadingRejected ||
              loadingTaglineRequestRejected
            }
          >
            Submit
          </Button>
        </HList>
      </Flex>
    </FinalReviewFooterBox>
  )
}

FinalReviewFooter.fragments = {
  taglineRequestInfo: gql`
    fragment PartnerReviewFooterInfo on TaglineRequest {
      id
      jobTitle
      employerName
      client {
        id
        name
      }
      talentAgentReviews {
        id
        items {
          id
          taglineSubmissionItem {
            id
          }
        }
      }
      writerSubmissions {
        id
        items {
          id
          name
          copy
        }
      }
    }
  `,
}

export default FinalReviewFooter

/**
 * Used by TP to submit their tagline Review work
 */
const TP_SUBMIT_TAGLINE_REVIEW_2 = gql`
  mutation SubmitPartnerReviewWork2(
    $SubmitTalentPartnerReviewInput: SubmitTalentPartnerReview2Input!
  ) {
    submitTalentPartnerReview2(input: $SubmitTalentPartnerReviewInput) {
      # TODO (matthewalbrecht) consider adding taglineRequest to talentPartnerReview resource for automatic cache updates
      talentPartnerReview {
        id
        taglineSubmission {
          id
          taglineRequest {
            id
            status
          }
        }
      }
    }
  }
`

/**
 * Used by TP to submit their rejections of Review work
 */
const TP_SUBMIT_TAGLINE_REJECTION = gql`
  mutation SubmitTalentPartnerRejection2(
    $SubmitTalentPartnerRejectionInput: SubmitTalentPartnerRejection2Input!
  ) {
    submitTalentPartnerRejection2(input: $SubmitTalentPartnerRejectionInput) {
      # TODO (matthewalbrecht) consider adding taglineRequest to talentPartnerReview resource for automatic cache updates
      talentPartnerReview {
        id
        taglineSubmission {
          id
          taglineRequest {
            id
            status
          }
        }
      }
    }
  }
`

/**
 * Used by TP to submit their rejections of Tagline Requests
 */
const TP_TAGLINE_REQUEST_REJECTION = gql`
  mutation RejectTaglineRequestByTalentPartner(
    $RejectTaglineRequestByTalentPartnerInput: RejectTaglineRequestByTalentPartnerInput!
  ) {
    rejectTaglineRequestByTalentPartner(
      input: $RejectTaglineRequestByTalentPartnerInput
    ) {
      taglineRequest {
        id
        status
      }
    }
  }
`
