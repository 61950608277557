export const DEFAULT_FORM_DATA = {
  ratingTalentAgent: null,
  feedbackTalentAgent: '',
  ratingWriter: null,
  feedbackWriter: '',
  editorChanges: {},
}

export enum Resource {
  TaglineRequest = 'Tagline Request',
  TaglineReview = 'Tagline Review',
}

export const COPY = {
  EMPTY_QUEUE: 'This queue is empty.',
}
