import { gql } from '@apollo/client'
import React from 'react'
import styled from 'styled-components/macro'
import { EditorItem } from 'types'
import {
  QUICK_REVIEW_FOR_IR_OPTIONS,
  QUICK_REVIEW_FOR_WRITER_OPTIONS,
} from 'utils/constants'
import { SetFormField } from 'views/ClientTracker/useFormState'
import Editor from 'components/Editor/Editor'
import JobHeading from 'components/JobHeading/JobHeading'
import StarRating from 'components/StarRating/StarRating'
import TemplateEditor from 'components/TemplateEditor/TemplateEditor'
import { FormState, TaglineRequest } from './FinalReview.types'
import ButtonList from 'components/ButtonList'
import ExternalLink from 'components/ExternalLink'
import Flex from 'components/Flex'
import { TextArea } from 'components/Inputs'
import Label from 'components/Label'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'
import WordCountLabel from 'components/WordCountLabel'

const TEMPLATE_NAME_TAGLINE = 'tagline'
const MAX_WORD_COUNT = 85

const TaglineContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: ${({ theme }) => theme.rhythm(1)};
`

type Props = {
  formState: FormState
  setFormField: SetFormField<FormState>
  taglineRequest: TaglineRequest
}

function FinalReviewRight({
  taglineRequest,
  formState,
  setFormField,
}: Props): JSX.Element {
  function handleQuickReviewChange(
    value: string,
    property: 'feedbackWriter' | 'feedbackTalentAgent'
  ) {
    const existingCopy = formState[property].trimEnd()

    setFormField(property, `${existingCopy}${existingCopy ? '\n' : ''}- ${value}`)
  }

  function handleEditorChange(content: string, templateId: string) {
    setFormField('editorChanges', {
      ...formState.editorChanges,
      [templateId]: content,
    })
  }

  const talentAgentReview = taglineRequest.talentAgentReviews[0]
  const taglineSubmission = taglineRequest.writerSubmissions[0]

  const toReview = talentAgentReview ?? taglineSubmission
  if (!toReview) {
    return <Message message="Cannot find a submission for this tagline request" />
  }
  const firstReviewItem = toReview.items[0]
  if (!firstReviewItem) {
    return <Message message="Cannot find any items on submission" />
  }

  const firstSubmissionItem = taglineSubmission?.items[0]
  const hasTalentAgentChanges = firstReviewItem.copy !== firstSubmissionItem?.copy
  const templateVariables = taglineSubmission?.templateVariables
  const taglineWords = templateVariables?.find(
    (variable) => variable.key === TEMPLATE_NAME_TAGLINE
  )?.value

  return (
    <VList size={4}>
      <div>
        <Txt bold size={20} lineHeight={1.3}>
          <JobHeading
            jobTitle={taglineRequest.jobTitle}
            company={taglineRequest.company ?? { name: taglineRequest.employerName }}
            huntrUrl={taglineRequest.huntrUrl}
          />
        </Txt>
        <Padding top={1}>
          {taglineRequest.jobUrl && (
            <ExternalLink url={taglineRequest.jobUrl} label="Job Description" />
          )}
        </Padding>
      </div>
      {/* ORIGINAL COVER LETTER */}
      <Editor
        disabled
        defaultValue={firstSubmissionItem?.copy}
        label={`${firstSubmissionItem?.name ?? ''} (Original)`}
        height={80}
      />
      <TaglineContainer>
        {taglineWords ? (
          <WordCountLabel
            txtProps={{ size: 12 }}
            label="Tagline word count"
            content={taglineWords}
            maxWordCount={MAX_WORD_COUNT}
          />
        ) : (
          <Txt size={12} color="faGrey3">
            Tagline not found
          </Txt>
        )}
      </TaglineContainer>
      {/* FEEDBACK FOR ORIGINAL COVER LETTER */}
      <div>
        <Padding bottom={3}>
          <ButtonList
            options={QUICK_REVIEW_FOR_WRITER_OPTIONS}
            onClick={(value) => handleQuickReviewChange(value, 'feedbackWriter')}
          />
        </Padding>
        <TextArea
          value={formState.feedbackWriter}
          name="feedbackWriter"
          placeholder="Type Writer feedback here..."
          onValueChange={(value) => setFormField('feedbackWriter', value)}
          activeIndicator
        />
        <Padding top={1}>
          <Flex justify="flex-end">
            <StarRating
              rating={formState.ratingWriter}
              onValueChange={(value) => setFormField('ratingWriter', value)}
              iconSize={18}
            />
          </Flex>
        </Padding>
      </div>
      {/* EDITOR DEFAULT TO TALENT AGENT SUBMITTED COVER LETTER */}
      <div>
        <Label
          content={`Edit ${firstReviewItem.name}${
            hasTalentAgentChanges ? '' : ' (No Updates by Prior Editor)'
          }`}
        />
        <TemplateEditor
          hideLabel
          template={firstReviewItem}
          sequence={toReview}
          handleEditorChange={handleEditorChange}
          withIndicator
        />
      </div>
      {/* FEEDBACK FOR TALENT AGENT SUBMITTED COVER LETTER */}
      {talentAgentReview && (
        <div>
          <Padding bottom={3}>
            <ButtonList
              options={QUICK_REVIEW_FOR_IR_OPTIONS}
              onClick={(value) =>
                handleQuickReviewChange(value, 'feedbackTalentAgent')
              }
            />
          </Padding>
          <TextArea
            value={formState.feedbackTalentAgent}
            name="feedbackTalentAgent"
            placeholder="Type IR feedback here..."
            onValueChange={(value) => setFormField('feedbackTalentAgent', value)}
            activeIndicator
          />
          <Padding top={1}>
            <Flex justify="flex-end">
              <StarRating
                rating={formState.ratingTalentAgent}
                onValueChange={(value) => setFormField('ratingTalentAgent', value)}
                iconSize={18}
              />
            </Flex>
          </Padding>
        </div>
      )}
      {/* REST OF THE TEMPLATES */}
      <VList size={4}>
        {toReview.items.slice(1).map((item: EditorItem) => (
          <TemplateEditor
            key={item.id}
            template={item}
            sequence={toReview}
            handleEditorChange={handleEditorChange}
            withIndicator
          />
        ))}
      </VList>
      <Editor
        disabled
        defaultValue={firstReviewItem.copy}
        label={`${firstReviewItem.name} by Prior Editor`}
        height={80}
      />
    </VList>
  )
}

FinalReviewRight.fragments = {
  taglineRequestInfo: gql`
    fragment PartnerReviewRightInfo on TaglineRequest {
      id
      jobUrl
      talentAgentReviews {
        id
        items {
          id
          name
          copy
        }
        createdBy {
          id
          name
        }
        ...TemplateEditorAgentReviewInfo
      }
      writerSubmissions {
        id
        items {
          id
          name
          copy
        }
        templateVariables {
          key
          value
        }
      }
      ...JobHeadingInfo
    }
    ${TemplateEditor.fragments.agentReviewInfo}
    ${JobHeading.fragments.jobHeadingInfo}
  `,
}

export default FinalReviewRight
