import { useEffect } from 'react'

/**
 * This hook detects whether the user clicked outside of the referenced element and calls the callback
 * @param ref an html element
 * @param cb callback for when we click outside `ref`
 */
export default function useOutsideClick(
  ref: React.RefObject<HTMLElement>,
  callback: () => void
): void {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(this: Document, event: MouseEvent) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        callback()
      }
    }
    // Bind the event listener
    document.addEventListener<'mousedown'>('mousedown', handleClickOutside)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener<'mousedown'>('mousedown', handleClickOutside)
    }
  }, [callback, ref])
}
