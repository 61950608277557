import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router'
import { toast } from 'react-toastify'
import { ReactSetState } from 'types'
import { EMPTY_INPUT, SOURCED_JOB_REJECTION_MAP } from 'utils/constants'
import { SourcedJobRejectionReason, Role } from 'utils/enums'
import checkRole from 'utils/helpers/checkRole'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import { SetFormField } from 'views/ClientTracker/useFormState'
import StarRating from 'components/StarRating/StarRating'
import {
  notesRequired,
  ratingRequired,
  TOAST,
} from '../ReviewSourcedJobs/ReviewSourcedJobs.constants'
import { DEFAULT_FORM_STATE, rejectionOptions } from './SourcedJobsReview.constants'
import {
  getNextRouteUrl,
  updateCacheAfterSuccess,
} from './SourcedJobsReview.helpers'
import { SourcedJob, FormState, RejectionOption } from './SourcedJobsReview.types'
import Button from 'components/Button'
import HList from 'components/HList'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt, { Bold } from 'components/Txt'
import VList from 'components/VList'

type Props = {
  queue: string[]
  sourcedJob: SourcedJob
  setFormField: SetFormField<FormState>
  setFormState: ReactSetState<FormState>
  formState: FormState
  sourcedJobId: string
  freeAgentId: string
}

export default function SourcedJobsReviewLeft({
  queue,
  sourcedJob,
  formState,
  setFormField,
  setFormState,
  sourcedJobId,
  freeAgentId,
}: Props): JSX.Element {
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, sourcedJobId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, {
    freeAgentId,
  })

  function resetState() {
    setFormState(DEFAULT_FORM_STATE)
  }

  const [acceptJob] = useMutation(ACCEPT_SOURCED_JOB, {
    onCompleted() {
      toast.success(TOAST.ACCEPT_SUCCESS)
    },
    onError() {
      toast.error(TOAST.ACCEPT_ERROR)
    },
    update(cache) {
      updateCacheAfterSuccess(cache, sourcedJob)
    },
  })

  const [rejectJob] = useMutation(REJECT_SOURCED_JOB, {
    onCompleted() {
      toast.success(TOAST.REJECT_SUCCESS)
    },
    onError() {
      toast.error(TOAST.REJECT_ERROR)
    },
    update(cache) {
      updateCacheAfterSuccess(cache, sourcedJob)
    },
  })

  async function handleRejectSourcedJob(rejectionReason: SourcedJobRejectionReason) {
    if (ratingRequired.includes(rejectionReason) && !formState.rating) {
      return toast.error(TOAST.MISSING_REJECTION_RATING)
    }

    if (notesRequired.includes(rejectionReason) && !formState.reviewNotes) {
      return toast.error(TOAST.MISSING_NOTES)
    }

    const variables = {
      RejectSourcedJobInput: {
        sourcedJobId: sourcedJobId,
        rating: formState.rating,
        rejectionReason,
        reviewNotes: formState.reviewNotes ?? '',
      },
    }

    try {
      await rejectJob({ variables })
      handleCompletion()
    } catch {
      toast.error('There was an error rejecting the sourced job review')
    }
  }

  function handleCompletion() {
    resetState()

    if (!nextRequestId) {
      toast('End of queue')
    }

    history.replace(nextRouteUrl)
  }

  async function handleSubmit() {
    if (!formState.rating) {
      toast.error(TOAST.MISSING_RATING)
      return
    }
    const variables = {
      AcceptSourcedJobInput: {
        sourcedJobId: sourcedJobId,
        rating: formState.rating,
        reviewNotes: formState.reviewNotes ?? '',
      },
    }
    try {
      await acceptJob({ variables })
      handleCompletion()
    } catch {
      toast.error('There was an error submitting your work')
    }
  }

  return (
    <>
      <Padding bottom={4}>
        <VList size={1.5}>
          <Txt size={14} lineHeight={1.3}>
            Free Agent: <Bold>{sourcedJob.client.name}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Talent Agent: <Bold>{sourcedJob.client.talentAgent.name}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Experience Level:&nbsp;
            <Bold>{sourcedJob.client.roleExperience || 'Unknown'}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Role Category:&nbsp;
            <Bold>{sourcedJob.client.roleCategory || 'Unknown'}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Remote Prefrences:&nbsp;
            <Bold>{sourcedJob.client.remotePrefs || 'Unknown'}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Desired Salary:&nbsp;
            <Bold>{sourcedJob.client.desiredSalary || 'Unknown'}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Desired Company Size:&nbsp;
            <Bold>{sourcedJob.client.desiredCompanySize || 'Unknown'}</Bold>
          </Txt>
          <Txt size={14} lineHeight={1.3}>
            Desired Industries:&nbsp;
            <Bold>{sourcedJob.client.desiredIndustries || 'Unknown'}</Bold>
          </Txt>
        </VList>
      </Padding>
      <Padding bottom={3}>
        <StarRating
          iconSize={28}
          onValueChange={(value) => setFormField('rating', value)}
          rating={formState.rating}
        />
      </Padding>
      <Padding bottom={4}>
        <TextArea
          placeholder="Type additional feedback for sourcers here..."
          rows={4}
          onValueChange={(value) => setFormField('reviewNotes', value)}
          value={formState.reviewNotes || EMPTY_INPUT}
        />
      </Padding>
      <ButtonList
        handleSubmit={handleSubmit}
        rejectionOptions={rejectionOptions}
        handleRejectSourcedJob={handleRejectSourcedJob}
      />
    </>
  )
}

type ButtonListProps = {
  rejectionOptions: RejectionOption<SourcedJobRejectionReason>[]
  handleSubmit: () => void
  handleRejectSourcedJob: (
    rejectionReason: SourcedJobRejectionReason
  ) => Promise<React.ReactText | undefined>
}

function ButtonList({
  rejectionOptions,
  handleSubmit,
  handleRejectSourcedJob,
}: ButtonListProps): JSX.Element {
  const showCoordinatorVoidButton = checkRole([Role.DataCleaner])
  return (
    <>
      <HList size={1}>
        <Button color="success" borderColor="success" onClick={handleSubmit}>
          Approve
        </Button>
        <Button
          color="faGrey4"
          borderColor="faGrey4"
          onClick={() =>
            handleRejectSourcedJob(SourcedJobRejectionReason.JOB_CLOSED)
          }
        >
          {SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.JOB_CLOSED]}
        </Button>
        {rejectionOptions.map(({ label, rejectionReason }) => (
          <Button
            key={label}
            color="error"
            borderColor="error"
            onClick={() => handleRejectSourcedJob(rejectionReason)}
          >
            {label}
          </Button>
        ))}
      </HList>
      {showCoordinatorVoidButton && (
        <Padding top={2}>
          <Button
            color="faGrey4"
            borderColor="faGrey4"
            onClick={() =>
              handleRejectSourcedJob(SourcedJobRejectionReason.COORDINATOR_VOID)
            }
          >
            {SOURCED_JOB_REJECTION_MAP[SourcedJobRejectionReason.COORDINATOR_VOID]}
          </Button>
        </Padding>
      )}
    </>
  )
}

const ACCEPT_SOURCED_JOB = gql`
  mutation AcceptSourcedJob($AcceptSourcedJobInput: AcceptSourcedJobInput!) {
    acceptSourcedJob(input: $AcceptSourcedJobInput) {
      sourcedJobReview {
        id
      }
    }
  }
`

const REJECT_SOURCED_JOB = gql`
  mutation RejectSourcedJob($RejectSourcedJobInput: RejectSourcedJobInput!) {
    rejectSourcedJob(input: $RejectSourcedJobInput) {
      sourcedJobReview {
        id
      }
    }
  }
`
