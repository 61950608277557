import { gql, useQuery } from '@apollo/client'
import React from 'react'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { HEADERS } from './ExpertRequests.constants'
import { getTableData } from './ExpertRequests.helpers'
import ExpertRequestsRow from './ExpertRequestsRow'
import {
  GetExpertDropdownQuery,
  GetExpertRequestsQuery,
  GetExpertsQueryVariables,
} from 'generated/graphql'

type Props = {
  showInactive: boolean
}

export default function ExpertRequestsTable({
  showInactive,
}: Props): React.ReactElement {
  const { data, loading, error } = useQuery<
    GetExpertRequestsQuery,
    GetExpertsQueryVariables
  >(GET_EXPERT_REQUESTS)
  const { data: expertData } = useQuery<GetExpertDropdownQuery>(GET_EXPERT_DROPDOWN)

  const expertOptions = expertData?.experts
    ? buildDropdownOptions(getNodes(expertData.experts))
    : []

  const tableData = data?.expertRequests
    ? getTableData(data.expertRequests, showInactive)
    : []

  return (
    <FullWidthTable
      headers={HEADERS}
      isLoading={loading}
      isError={Boolean(error)}
      isEmpty={Boolean(data?.expertRequests && !tableData.length)}
      emptyMessage="Could not find any expert requests."
      loadingMessage="Loading expert requests..."
      errorMessage="There was an error fetching expert requests."
    >
      {tableData.map((expertRequest) => (
        <ExpertRequestsRow
          key={expertRequest.id}
          expertRequest={expertRequest}
          expertOptions={expertOptions}
        />
      ))}
    </FullWidthTable>
  )
}

const GET_EXPERT_REQUESTS = gql`
  query GetExpertRequests {
    expertRequests {
      edges {
        node {
          ...ExpertRequestsRowInfo
        }
      }
    }
  }
  ${ExpertRequestsRow.fragments.ExpertRequestsRowInfo}
`

const GET_EXPERT_DROPDOWN = gql`
  query GetExpertDropdown {
    experts {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
