import React, { ReactElement } from 'react'
import styled from 'styled-components/macro'
import { Input } from 'components/Inputs/Input'
import InputDescription from 'components/InputDescription'
import Label from 'components/Label'

type Props = {
  autoComplete?: string
  defaultValue?: string | number
  fullWidth?: boolean
  label?: string
  name: string
  onValueChange?: (value: string) => void
  pattern?: string
  placeholder?: string
  required?: boolean
  type?: string
  value?: string
  width?: number
  min?: number
  description?: string
  disabled?: boolean
  autoFocus?: boolean
  icon?: ReactElement
}

export function TextInput({
  autoComplete,
  defaultValue,
  fullWidth,
  label,
  name,
  onValueChange,
  pattern,
  placeholder,
  required,
  type,
  value,
  disabled = false,
  width,
  min,
  description,
  icon,
  autoFocus,
}: Props): JSX.Element {
  return (
    <div>
      {label && <Label htmlFor={name} content={label} />}
      {description && <InputDescription description={description} />}
      <InputContainer>
        <Input
          id={name}
          name={name}
          defaultValue={defaultValue}
          value={value}
          type={type}
          required={required}
          width={width}
          fullWidth={fullWidth}
          autoComplete={autoComplete}
          placeholder={placeholder}
          pattern={pattern}
          min={min}
          disabled={disabled}
          autoFocus={autoFocus}
          onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
            onValueChange?.(event.currentTarget.value)
          }
          withIcon={Boolean(icon)}
        />
        {icon && <InputIcon>{icon}</InputIcon>}
      </InputContainer>
    </div>
  )
}

const InputContainer = styled.div`
  position: relative;
`

const InputIcon = styled.div`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: ${({ theme }) => theme.rhythm(1)};
`
