import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { Typename } from 'utils/enums'
import Icon from 'components/Icon/Icon'
import {
  VoidSourcingRequestByCoordinatorMutation,
  VoidSourcingRequestByCoordinatorMutationVariables,
} from 'generated/graphql'

type Props = {
  itemId: string
}

export default function AssignSourcingsDeleteButton({ itemId }: Props): JSX.Element {
  /**
   * execute the mutation for voiding the sourcing request
   */
  function handleClick() {
    const message = 'Are you sure you want to delete this sourcing request?'

    if (window.confirm(message)) {
      void voidSourcingRequest({
        variables: {
          VoidSourcingRequestByCoordinatorInput: {
            sourcingRequestId: itemId,
          },
        },
      })
    }
  }

  // TODO (matthewalbrecht) use coordinator void mutation once available
  const [voidSourcingRequest, { loading }] = useMutation<
    VoidSourcingRequestByCoordinatorMutation,
    VoidSourcingRequestByCoordinatorMutationVariables
  >(VOID_SOURCING_REQUEST_BY_COORDINATOR, {
    update(cache) {
      // Remove the selected sourcing request from the cache
      cache.evict({
        id: cache.identify({
          id: itemId,
          __typename: Typename.SourcingRequest,
        }),
      })
    },
    onCompleted() {
      toast.success(`Sourcing request deleted`)
    },
    onError() {
      toast.error('There was an error deleting the sourcing request')
    },
  })

  return (
    <button onClick={handleClick} disabled={loading}>
      <Icon name="Trash" width={16} height={16} primaryFill="faGrey3" />
    </button>
  )
}

/**
 * Used by coordinator to void (soft delete) a sourcing request
 */
const VOID_SOURCING_REQUEST_BY_COORDINATOR = gql`
  mutation VoidSourcingRequestByCoordinator(
    $VoidSourcingRequestByCoordinatorInput: VoidSourcingRequestByCoordinatorInput!
  ) {
    voidSourcingRequestByCoordinator(input: $VoidSourcingRequestByCoordinatorInput) {
      sourcingRequest {
        id
      }
    }
  }
`
