import { useQuery, gql } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'
import ReportInterviewDecline from './ReportInterviewDecline'
import ReportInterviewSuccess from './ReportInterviewSuccess'
import { Params, Status } from './ReportInterviewTime.types'
import ReportInterviewTimeForm from './ReportInterviewTimeForm'
import Container from 'components/Container'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PublicRoute from 'components/PublicRoute'
import ViewBox from 'components/ViewBox'
import {
  GetInterviewPublicQuery,
  GetInterviewPublicQueryVariables,
} from 'generated/graphql'

export default function ReportInterviewTime(): React.ReactElement {
  const { interviewPublicId } = useParams<Params>()
  const [status, setStatus] = useState<Status>(Status.Open)
  const { data, loading } = useQuery<
    GetInterviewPublicQuery,
    GetInterviewPublicQueryVariables
  >(GET_INTERVIEW_PUBLIC, {
    variables: {
      publicId: interviewPublicId,
    },
  })
  const interview = data?.interviewByPublic

  function getActivePane() {
    if (loading) {
      return <Message message="Loading..." />
    } else if (interview == null) {
      return <Message message="Unable to find the interview." />
    } else if (status === Status.Declined || !interview.isActive) {
      return <ReportInterviewDecline />
    } else if (status === Status.Success) {
      return <ReportInterviewSuccess />
    } else {
      return <ReportInterviewTimeForm interview={interview} setStatus={setStatus} />
    }
  }

  const pane = getActivePane()

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>{pane}</Padding>
      </Container>
    </ViewBox>
  )
}

ReportInterviewTime.Routes = [
  <PublicRoute
    exact
    path={ROUTE_PATHS.REPORT_INTERVIEW_TIME}
    key={ROUTE_PATHS.REPORT_INTERVIEW_TIME}
  >
    <ReportInterviewTime />
  </PublicRoute>,
]

const GET_INTERVIEW_PUBLIC = gql`
  query GetInterviewPublic($publicId: ID!) {
    interviewByPublic(publicId: $publicId) {
      publicId
      jobTitle
      employerName
      isActive
      scheduledFor {
        datetime
        timezone
      }
    }
  }
`
