import dayjs from 'dayjs'
import React from 'react'
import { DATE_FORMAT, DOSSIER_DUE_TZ } from 'utils/constants'
import assertUnreachable from 'utils/helpers/assertUnreachable'
import { getHourOptions } from 'views/AddAndEditDossierRequest/AddAndEditDossierRequest.helpers'
import { InputTypes } from 'views/Assign/Assign.types'
import { DateInputType } from './AssignDossiers.types'
import Flex from 'components/Flex'
import { DatePicker, Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'

type Props = {
  onInputChange: (value: string, itemId: string, type: InputTypes) => void
  date: string
  id: string
  ariaLabel: string
}

export default function AssignDossiersDatetimePicker({
  onInputChange,
  date,
  id,
  ariaLabel,
}: Props): React.ReactElement {
  function handleInputChange<T extends string | number = string>(
    value: T,
    type: DateInputType
  ) {
    let newDate = dayjs(date).tz(DOSSIER_DUE_TZ)
    const oldHour = newDate.hour()
    switch (type) {
      case DateInputType.Hour:
        newDate = newDate.hour(Number(value))
        break
      case DateInputType.Date:
        newDate = dayjs(value).tz(DOSSIER_DUE_TZ).hour(oldHour)
        break
      default:
        assertUnreachable(type)
    }
    onInputChange(newDate.format(), id, InputTypes.Due)
  }

  const dayjsDate = date ? dayjs(date).tz(DOSSIER_DUE_TZ) : undefined
  const hour = dayjsDate ? dayjsDate.hour() : null
  const onlyDate = dayjsDate ? dayjsDate.format(DATE_FORMAT.ONLY_DATE) : ''
  return (
    <Flex align="flex-end">
      <DatePicker
        value={onlyDate}
        onValueChange={(value) => handleInputChange(value, DateInputType.Date)}
        ariaLabel={ariaLabel}
      />
      <Padding inline left={2}>
        <Dropdown
          value={hour != null ? String(hour) : ''}
          onValueChange={(value) =>
            handleInputChange<number>(Number(value), DateInputType.Hour)
          }
          options={getHourOptions(hour).map((option) => ({
            ...option,
            value: String(option.value),
          }))}
          withEmptyOption={false}
          placeholder="Eastern"
        />
      </Padding>
    </Flex>
  )
}
