import { gql, useQuery } from '@apollo/client'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { buildClientsData } from './SourcingStats.helpers'
import SourcingStatsTable from './SourcingStatsTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { DatePicker } from 'components/Inputs'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { GetSourcingStatsQuery } from 'generated/graphql'

function SourcingStats(): React.ReactElement {
  const [startDate, setStartDate] = useState<Dayjs>(dayjs().startOf('week'))

  /**
   *  sets the new fromDate filter
   * @param value value of DatePicker change
   */
  function handleDateFilterChange(value: string) {
    if (!value) {
      return
    }
    setStartDate(dayjs(value))
    void refetch({
      dueDateRangeFilter: { start: startDate.format(DATE_FORMAT.ONLY_DATE) },
    })
  }

  const { data, loading, refetch } = useQuery<GetSourcingStatsQuery>(
    GET_SOURCING_STATS,
    {
      variables: {
        dueDateRangeFilter: {
          start: startDate.format(DATE_FORMAT.ONLY_DATE),
        },
      },
    }
  )

  const clients =
    data?.sourcingRequests &&
    data.clients &&
    buildClientsData(data.sourcingRequests, data.clients)

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6} bottom={4}>
          <Txt as="h2" bold size={24}>
            Sourcing Stats
          </Txt>
          <Padding top={4}>
            <HList size={2} rowGapSize={2}>
              <DatePicker
                label="Due Since"
                value={startDate.format(DATE_FORMAT.ONLY_DATE)}
                onValueChange={(value) => handleDateFilterChange(value)}
              />
            </HList>
          </Padding>
        </Padding>
      </Container>
      <SourcingStatsTable clients={clients} loading={loading} />
    </ViewBox>
  )
}

SourcingStats.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.SOURCING_STATS}
    key={ROUTE_PATHS.SOURCING_STATS}
  >
    <SourcingStats />
  </PrivateRoute>,
]

export default SourcingStats

// NOTE: (matthewalbrecht) this may need to be updated if we allow ad hoc sourcing
const GET_SOURCING_STATS = gql`
  query GetSourcingStats($dueDateRangeFilter: DateRangeFilter!) {
    sourcingRequests(dueDateRangeFilter: $dueDateRangeFilter) {
      edges {
        node {
          id
          numJobs
          client {
            id
          }
          sourcedJobs {
            id
            reviews {
              id
              rejectionReason
            }
          }
        }
      }
    }
    clients: allClients {
      id
      name
      autoSourcingsWeekly
      talentAgent {
        id
        name
      }
    }
  }
`
