import React from 'react'
import { Rating } from 'types'
import { roundToNDecimalPoints } from 'utils/helpers/numbers'
import { getMinutesSecondsFromSeconds } from 'utils/helpers/time'
import { Bold } from 'components/Txt'

const ONE_HOUR_IN_SEC = 60 * 60

/**
 * calculate the average timeSpentSec given an array of values
 * @param times array of timeSpentSec values
 */
export function getAvgTimeSpentSec(times: number[]): [number, number] {
  // sometimes timeSpentSec may be null/undefined
  const filteredTimes = times.filter((time) => {
    if (!time) {
      return false
    }

    // don't count anything over an hour
    if (time > ONE_HOUR_IN_SEC) {
      return false
    }

    return true
  })
  const total = filteredTimes.reduce((accumTotal, time) => accumTotal + time, 0)

  // if there is no data return NaN instead of 0
  if (!filteredTimes.length) {
    return [NaN, 0]
  }

  return [total / filteredTimes.length, filteredTimes.length]
}

/**
 * calculate the average rating given an array of values
 * @param ratings array of rating values
 */
export function getAvgRating(ratings: Rating[]): [number, number, number] {
  // sometimes rating may be null
  const filteredRatings = ratings.filter((rating) => rating)
  const onesAndTwosCount = filteredRatings.reduce(
    (total, rating) => (rating && rating <= 2 ? total + 1 : total),
    0
  )
  const total = ratings.reduce(
    (accumTotal, rating) => (rating ? accumTotal + rating : accumTotal),
    0
  )

  // if there is no data return NaN instead of 0
  if (!filteredRatings.length) {
    return [NaN, 0, 0]
  }

  // "|| 1" to protect against divide by zero
  return [
    total / filteredRatings.length,
    filteredRatings.length,
    onesAndTwosCount / filteredRatings.length,
  ]
}

/**
 * returns copy to render for avg rating
 * @param avgRating avg rating
 * @param total total count of ratings used to calculate avg
 */
export function avgRatingCopy(avgRating: number, total: number): JSX.Element {
  return (
    <>
      <Bold>{isNaN(avgRating) ? '-' : roundToNDecimalPoints(avgRating, 1)}</Bold>
      &nbsp;{renderTotal(total)}
    </>
  )
}

/**
 * returns copy to render for rate of 1s and 2s in an array of tagline requests
 * @param rate rate of 1s and 2s
 * @param total total count of tagline requests
 */
export function oneAndTwosRateCopy(rate: number, total: number): JSX.Element {
  // if the total === 0, we just want to render a dash, as the calculated percentage wouldn't be relevant
  return (
    <>
      <Bold>{!total ? '-' : `${Math.round(rate * 100).toString()}%`}</Bold>
      &nbsp;{renderTotal(total)}
    </>
  )
}

/**
 * returns copy to render for avg time
 * @param avgTimeSpentSec avg time spent
 * @param avgTimeSpentSecTotal total count of time spent used to calculate avg
 */
export function avgTimeSpentSecCopy(
  avgTimeSpentSec: number,
  avgTimeSpentSecTotal: number
): JSX.Element {
  return (
    <>
      <Bold>{formatSeconds(avgTimeSpentSec)}</Bold>
      &nbsp;{renderTotal(avgTimeSpentSecTotal)}
    </>
  )
}

/**
 * returns copy to render for rejection rate
 * @param rate decimal number representing how often tagline is rejected
 * @param total total tagline requests
 */
export function rateAsPctCopy(rate: number, total: number): JSX.Element {
  return (
    <>
      <Bold>{isNaN(rate) ? '-' : `${Math.round(rate * 100).toString()}%`}</Bold>
      &nbsp;{renderTotal(total)}
    </>
  )
}

/**
 * returns string
 * @param total size of data used to calculate avg
 */
function renderTotal(total: number): string {
  return total ? `(${total})` : ''
}

/**
 * formats the avg time spent into mm:ss format
 * @param timeSec avg time spent
 */
function formatSeconds(avgTimeSpentSec: number): string {
  if (isNaN(avgTimeSpentSec)) {
    return '-'
  }
  const { minutes, seconds } = getMinutesSecondsFromSeconds(
    Math.round(avgTimeSpentSec)
  )
  return `${minutes}:${seconds.toString().padStart(2, '0')}`
}
