import { useQuery } from '@apollo/client'
import React from 'react'
import { DropdownOption } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import { GET_TALENT_AGENTS } from 'gql/queries'
import { Dropdown } from 'components/Inputs'
import Padding from 'components/Padding'
import { GetTalentAgentsQuery } from 'generated/graphql'

type Props = {
  talentAgentId: string
  setTalentAgentId: React.Dispatch<React.SetStateAction<string>>
}

export default function TabbedTableDropdown({
  talentAgentId,
  setTalentAgentId,
}: Props): React.ReactElement {
  const { data } = useQuery<GetTalentAgentsQuery>(GET_TALENT_AGENTS)
  const talentAgentOptions: DropdownOption[] = buildDropdownOptions(
    data?.talentAgents || []
  )
  return (
    <Padding left={2}>
      <Dropdown
        options={talentAgentOptions}
        value={talentAgentId}
        onValueChange={(value) => setTalentAgentId(value)}
        placeholder="Select Talent Agent"
        withEmptyOption={false}
      />
    </Padding>
  )
}
