import { generatePath } from 'react-router-dom'
import {
  SOURCED_JOB_REJECTION_MAP,
  ApplicationRejectionReasonMap,
  MissingApplicationInfoDeclineReasonMap,
  TaglineRejectionReasonMap,
  EnrichmentRejectionReasonMap,
  HuntrListNameMap,
  UNKNOWN,
} from 'utils/constants'
import {
  EnrichmentRejectionReason,
  SourcedJobRejectionReason,
  TaglineRejectionReason,
} from 'utils/enums'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { sortByProp } from 'utils/sort'
import { TYPE, HuntrActionType } from './ClientJobHistory.constants'
import {
  ClientTaglineRequestJob,
  ClientSourcedJob,
  TimelineEvent,
  JobHistoryState,
  SourcedJobQueryResult,
  TaglineRequestJobQueryResult,
} from './ClientJobHistory.types'
import { ApplicationRejectionReason } from 'generated/graphql'

enum HuntrColumns {
  Interested = 'Interested?',
  Wishlist = 'Wishlist',
  Applied = 'Applied',
  Interview = 'Interview',
  Offer = 'Offer',
  Passed = 'Passed',
  NotInterested = 'Not interested',
}

export function buildSourcedJobTimelineEvents(
  clientJob: ClientSourcedJob
): TimelineEvent[] {
  const timelineEvents = []

  // Sourced Job
  timelineEvents.push({
    id: clientJob.id,
    type: TYPE.JOB_SOURCED,
    createdAt: clientJob.createdAt,
  })

  // Sourced Job Approved/Rejected
  clientJob.reviews.forEach((review) => {
    if (review.rejectionReason) {
      timelineEvents.push({
        id: review.id,
        type: TYPE.SOURCED_JOB_REJECTED,
        createdAt: review.createdAt,
        rating: review.rating,
        reason:
          SOURCED_JOB_REJECTION_MAP[
            review.rejectionReason as SourcedJobRejectionReason
          ],
      })
    } else {
      timelineEvents.push({
        id: review.id,
        type: TYPE.SOURCED_JOB_APPROVED,
        createdAt: review.createdAt,
        rating: review.rating,
      })
    }
  })

  // Enrichment requested
  const enrichmentRequestsInfo = getNodes(clientJob.enrichmentRequests)
  enrichmentRequestsInfo.forEach((enrichmentRequest) => {
    timelineEvents.push({
      id: enrichmentRequest.id,
      type: TYPE.ENRICHMENT_REQUESTED,
      createdAt: enrichmentRequest.createdAt,
    })

    // Enrichment submitted/rejected
    const enrichmentSubmissionsInfo = getNodes(
      enrichmentRequest.enrichmentSubmissions
    )
    enrichmentSubmissionsInfo.forEach((enrichmentSubmission) => {
      if (enrichmentSubmission.rejectionReason) {
        timelineEvents.push({
          id: enrichmentSubmission.id,
          type: TYPE.ENRICHMENT_REJECTED,
          createdAt: enrichmentSubmission.createdAt,
          reason:
            EnrichmentRejectionReasonMap[
              enrichmentSubmission.rejectionReason as EnrichmentRejectionReason
            ],
        })
      } else {
        timelineEvents.push({
          id: enrichmentSubmission.id,
          type: TYPE.ENRICHMENT_SUBMITTED,
          createdAt: enrichmentSubmission.createdAt,
        })
      }
    })
  })

  // Sourced Job Redactions
  clientJob.redactions.forEach((redaction) =>
    timelineEvents.push({
      id: redaction.id,
      type: TYPE.SOURCED_JOB_REDACTED,
      createdAt: redaction.createdAt,
    })
  )

  // Huntr Job Actions
  const huntrJobActionsInfo = getNodes(clientJob.huntrJobActions)

  huntrJobActionsInfo.forEach((huntrJobAction) => {
    // Huntr Job Created
    if (huntrJobAction.jobActionType === HuntrActionType.Created) {
      timelineEvents.push({
        id: huntrJobAction.id,
        type: TYPE.HUNTR_CARD_CREATED,
        createdAt: huntrJobAction.huntrAction.createdAt,
        externalLinkUrl: clientJob.taglineRequests.edges[0]?.node.huntrUrl,
      })
    }
    // Huntr Job Moved
    if (huntrJobAction.jobActionType === HuntrActionType.Moved) {
      timelineEvents.push({
        id: huntrJobAction.id,
        type: TYPE.HUNTR_CARD_MOVED,
        createdAt: huntrJobAction.huntrAction.createdAt,
        reason: `${
          huntrJobAction.fromListName
            ? HuntrListNameMap[huntrJobAction.fromListName as HuntrColumns]
            : UNKNOWN.UNKNOWN
        } to ${
          huntrJobAction.toListName
            ? HuntrListNameMap[huntrJobAction.toListName as HuntrColumns]
            : UNKNOWN.UNKNOWN
        }`,
        externalLinkUrl: clientJob.taglineRequests.edges[0]?.node.huntrUrl,
      })
    }
  })

  const applicationsInfo = getNodes(clientJob.applications)

  applicationsInfo.forEach((application) => {
    // Application requested/request updated
    if (application.priorMissingApplicationInfos.length) {
      timelineEvents.push({
        id: application.id,
        type: TYPE.APPLICATION_REQUEST_UPDATED,
        createdAt: application.createdAt,
      })
    } else {
      timelineEvents.push({
        id: application.id,
        type: TYPE.APPLICATION_REQUESTED,
        createdAt: application.createdAt,
      })
    }

    // Application submission/rejection
    application.applicationSubmissions.forEach((applicationSubmission) => {
      if (applicationSubmission.rejectionReason) {
        if (
          applicationSubmission.rejectionReason ===
          ApplicationRejectionReason.ADDITIONAL_INFORMATION_NEEDED
        ) {
          timelineEvents.push({
            id: applicationSubmission.id,
            type: TYPE.APPLICATION_YELLOW_CARDED,
            createdAt: applicationSubmission.createdAt,
            internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: application.id,
            }),
          })
        } else if (
          applicationSubmission.rejectionReason ===
          ApplicationRejectionReason.LOGIN_INFORMATION_NEEDED
        ) {
          timelineEvents.push({
            id: applicationSubmission.id,
            type: TYPE.APPLICATION_YELLOW_CARDED,
            createdAt: applicationSubmission.createdAt,
            reason:
              ApplicationRejectionReasonMap[
                applicationSubmission.rejectionReason as ApplicationRejectionReason
              ],
            internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: application.id,
            }),
          })
        } else {
          timelineEvents.push({
            id: applicationSubmission.id,
            type: TYPE.APPLICATION_REJECTED,
            createdAt: applicationSubmission.createdAt,
            reason:
              ApplicationRejectionReasonMap[
                applicationSubmission.rejectionReason as ApplicationRejectionReason
              ],
            internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: application.id,
            }),
          })
        }
      } else {
        timelineEvents.push({
          id: applicationSubmission.id,
          type: TYPE.APPLICATION_SUBMITTED,
          createdAt: applicationSubmission.createdAt,
          internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
            applicationId: application.id,
          }),
        })
      }
    })

    // Application missing info
    application.missingApplicationInfos.forEach((missingApplicationInfo) => {
      timelineEvents.push({
        id: missingApplicationInfo.id,
        type: TYPE.MISSING_APPLICATION_INFO_REQUESTED,
        createdAt: missingApplicationInfo.createdAt,
        internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
          applicationId: application.id,
        }),
      })
      if (missingApplicationInfo.answeredAt) {
        if (missingApplicationInfo.declineReason) {
          timelineEvents.push({
            id: missingApplicationInfo.id,
            type: TYPE.MISSING_APPLICATION_INFO_DECLINED,
            createdAt: missingApplicationInfo.answeredAt,
            reason:
              MissingApplicationInfoDeclineReasonMap[
                missingApplicationInfo.declineReason
              ],
          })
        } else {
          timelineEvents.push({
            id: missingApplicationInfo.id,
            type: TYPE.MISSING_APPLICATION_INFO_ANSWERED,
            createdAt: missingApplicationInfo.answeredAt,
            internalLinkRoute:
              missingApplicationInfo.applicationNew &&
              generatePath(ROUTE_PATHS.APPLICATION_INFO, {
                applicationId: missingApplicationInfo.applicationNew.id,
              }),
          })
        }
      }
    })

    // Application interview cycles
    application.interviewCycles.forEach((interviewCycle) => {
      timelineEvents.push({
        id: interviewCycle.id,
        type: TYPE.INTERVIEW_CYCLE_STARTED,
        createdAt: interviewCycle.createdAt,
        internalLinkRoute: generatePath(
          ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
          {
            clientId: clientJob.client.id,
            interviewCycleId: interviewCycle.id,
          }
        ),
      })

      // Application dossier requests
      const dossierRequestsInfo = getNodes(interviewCycle.dossierRequests)
      dossierRequestsInfo.forEach((dossierRequest) =>
        timelineEvents.push({
          id: dossierRequest.id,
          type: TYPE.DOSSIER_REQUESTED,
          createdAt: dossierRequest.createdAt,
        })
      )

      // Application dossier submissions
      const dossierSubmissionsInfo = getNodes(interviewCycle.dossierSubmissions)
      dossierSubmissionsInfo.forEach((dossierSubmission) => {
        if (dossierSubmission.rejectionReason) {
          timelineEvents.push({
            id: dossierSubmission.id,
            type: TYPE.DOSSIER_REJECTED,
            createdAt: dossierSubmission.createdAt,
            reason: dossierSubmission.rejectionReason,
          })
        } else {
          timelineEvents.push({
            id: dossierSubmission.id,
            type: TYPE.DOSSIER_SUBMITTED,
            createdAt: dossierSubmission.createdAt,
            externalLinkUrl: dossierSubmission.fileUrl,
          })
        }
      })

      // Application expert requests
      const expertRequestsInfo = getNodes(interviewCycle.expertRequests)
      expertRequestsInfo.forEach((expertRequest) =>
        timelineEvents.push({
          id: expertRequest.id,
          type: TYPE.EXPERT_REQUESTED,
          createdAt: expertRequest.createdAt,
        })
      )

      // Application interview prep requests
      const interviewPrepRequestsInfo = getNodes(
        interviewCycle.interviewPrepRequests
      )
      interviewPrepRequestsInfo.forEach((interviewPrepRequest) =>
        timelineEvents.push({
          id: interviewPrepRequest.id,
          type: TYPE.INTERVIEW_PREP_REQUESTED,
          createdAt: interviewPrepRequest.createdAt,
        })
      )

      // Application interview
      const interviewsInfo = getNodes(interviewCycle.interviews)
      interviewsInfo.forEach((interview) => {
        timelineEvents.push({
          id: interview.id,
          type: TYPE.INTERVIEW_ADDED,
          createdAt: interview.createdAt,
          internalLinkRoute: generatePath(
            ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
            {
              clientId: clientJob.client.id,
              interviewCycleId: interviewCycle.id,
            }
          ),
        })

        // Application interview debrief
        interview.debriefs.forEach((debrief) =>
          timelineEvents.push({
            id: debrief.id,
            type: TYPE.INTERVIEW_DEBRIEF_ADDED,
            createdAt: debrief.createdAt,
            rating: debrief.rating,
            internalLinkRoute: generatePath(ROUTE_PATHS.INTERVIEW_DEBRIEF, {
              interviewDebriefId: debrief.id,
            }),
          })
        )
      })

      // Application interview cycle closed
      if (interviewCycle.inactiveAt) {
        timelineEvents.push({
          id: interviewCycle.id,
          type: TYPE.INTERVIEW_CYCLE_CLOSED,
          createdAt: interviewCycle.inactiveAt,
          internalLinkRoute: generatePath(
            ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
            {
              clientId: clientJob.client.id,
              interviewCycleId: interviewCycle.id,
            }
          ),
        })
      }
    })
  })

  const taglineRequestsInfo = getNodes(clientJob.taglineRequests)

  taglineRequestsInfo.forEach((request) => {
    // Tagline request
    timelineEvents.push({
      id: request.id,
      type: TYPE.TAGLINE_REQUESTED,
      createdAt: request.createdAt,
    })

    request.taglineRequestRejections.forEach((requestRejection) =>
      // Tagline request rejected
      timelineEvents.push({
        id: requestRejection.id,
        type: TYPE.TAGLINE_REQUEST_REJECTED,
        createdAt: requestRejection.createdAt,
        reason:
          TaglineRejectionReasonMap[
            requestRejection.rejectionReason as TaglineRejectionReason
          ],
      })
    )

    request.writerSubmissions.forEach((writerSubmission) => {
      // Tagline submission
      timelineEvents.push({
        id: writerSubmission.id,
        type: TYPE.TAGLINE_SUBMITTED,
        createdAt: writerSubmission.createdAt,
      })

      writerSubmission.talentAgentReviews.forEach((talentAgentReview) => {
        if (talentAgentReview.taglineRejectionReason) {
          // Talent agent tagline rejection
          timelineEvents.push({
            id: talentAgentReview.id,
            type: TYPE.INITIAL_REVIEW_REJECTED,
            createdAt: talentAgentReview.createdAt,
            rating: talentAgentReview.rating,
            reason: talentAgentReview.taglineRejectionReason,
          })
        } else {
          // Talent agent review
          timelineEvents.push({
            id: talentAgentReview.id,
            type: TYPE.INITIAL_REVIEW_APPROVED,
            createdAt: talentAgentReview.createdAt,
            rating: talentAgentReview.rating,
          })
        }
      })

      writerSubmission.talentPartnerReviews.forEach((talentPartnerReview) => {
        if (talentPartnerReview.taglineRejectionReason) {
          // Talent partner tagline rejection
          timelineEvents.push({
            id: talentPartnerReview.id,
            type: TYPE.FINAL_REVIEW_REJECTED,
            createdAt: talentPartnerReview.createdAt,
            rating: talentPartnerReview.ratingTalentAgent,
            reason: talentPartnerReview.taglineRejectionReason,
          })
        } else {
          // Talent partner review
          timelineEvents.push({
            id: talentPartnerReview.id,
            type: TYPE.FINAL_REVIEW_APPROVED,
            createdAt: talentPartnerReview.createdAt,
            rating: talentPartnerReview.ratingTalentAgent,
          })
        }
      })
    })
  })

  return timelineEvents.sort((a, b) => sortByProp(b, a, 'createdAt'))
}

export function buildTaglineRequestJobTimelineEvents(
  clientJob: ClientTaglineRequestJob
): TimelineEvent[] {
  const timelineEvents = []

  // Tagline request
  timelineEvents.push({
    id: clientJob.id,
    type: TYPE.TAGLINE_REQUESTED,
    createdAt: clientJob.createdAt,
  })

  const applicationsInfo = getNodes(clientJob.applications)

  applicationsInfo.forEach((application) => {
    // Application requested/request updated
    if (application.priorMissingApplicationInfos.length) {
      timelineEvents.push({
        id: application.id,
        type: TYPE.APPLICATION_REQUEST_UPDATED,
        createdAt: application.createdAt,
      })
    } else {
      timelineEvents.push({
        id: application.id,
        type: TYPE.APPLICATION_REQUESTED,
        createdAt: application.createdAt,
      })
    }

    // Application submission/rejection
    application.applicationSubmissions.forEach((applicationSubmission) => {
      if (applicationSubmission.rejectionReason) {
        if (
          applicationSubmission.rejectionReason ===
          ApplicationRejectionReason.ADDITIONAL_INFORMATION_NEEDED
        ) {
          timelineEvents.push({
            id: applicationSubmission.id,
            type: TYPE.APPLICATION_YELLOW_CARDED,
            createdAt: applicationSubmission.createdAt,
            internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: application.id,
            }),
          })
        } else if (
          applicationSubmission.rejectionReason ===
          ApplicationRejectionReason.LOGIN_INFORMATION_NEEDED
        ) {
          timelineEvents.push({
            id: applicationSubmission.id,
            type: TYPE.APPLICATION_YELLOW_CARDED,
            createdAt: applicationSubmission.createdAt,
            reason:
              ApplicationRejectionReasonMap[
                applicationSubmission.rejectionReason as ApplicationRejectionReason
              ],
            internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: application.id,
            }),
          })
        } else {
          timelineEvents.push({
            id: applicationSubmission.id,
            type: TYPE.APPLICATION_REJECTED,
            createdAt: applicationSubmission.createdAt,
            reason:
              ApplicationRejectionReasonMap[
                applicationSubmission.rejectionReason as ApplicationRejectionReason
              ],
            internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
              applicationId: application.id,
            }),
          })
        }
      } else {
        timelineEvents.push({
          id: applicationSubmission.id,
          type: TYPE.APPLICATION_SUBMITTED,
          createdAt: applicationSubmission.createdAt,
          internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
            applicationId: application.id,
          }),
        })
      }
    })

    // Application missing info
    application.missingApplicationInfos.forEach((missingApplicationInfo) => {
      timelineEvents.push({
        id: missingApplicationInfo.id,
        type: TYPE.MISSING_APPLICATION_INFO_REQUESTED,
        createdAt: missingApplicationInfo.createdAt,
        internalLinkRoute: generatePath(ROUTE_PATHS.APPLICATION_INFO, {
          applicationId: application.id,
        }),
      })
      if (missingApplicationInfo.answeredAt) {
        if (missingApplicationInfo.declineReason) {
          timelineEvents.push({
            id: missingApplicationInfo.id,
            type: TYPE.MISSING_APPLICATION_INFO_DECLINED,
            createdAt: missingApplicationInfo.answeredAt,
            reason:
              MissingApplicationInfoDeclineReasonMap[
                missingApplicationInfo.declineReason
              ],
          })
        } else {
          timelineEvents.push({
            id: missingApplicationInfo.id,
            type: TYPE.MISSING_APPLICATION_INFO_ANSWERED,
            createdAt: missingApplicationInfo.answeredAt,
            internalLinkRoute:
              missingApplicationInfo.applicationNew &&
              generatePath(ROUTE_PATHS.APPLICATION_INFO, {
                applicationId: missingApplicationInfo.applicationNew.id,
              }),
          })
        }
      }
    })

    // Application interview cycles
    application.interviewCycles.forEach((interviewCycle) => {
      timelineEvents.push({
        id: interviewCycle.id,
        type: TYPE.INTERVIEW_CYCLE_STARTED,
        createdAt: interviewCycle.createdAt,
        internalLinkRoute: generatePath(
          ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
          {
            clientId: clientJob.client.id,
            interviewCycleId: interviewCycle.id,
          }
        ),
      })

      // Application dossier requests
      const dossierRequestsInfo = getNodes(interviewCycle.dossierRequests)
      dossierRequestsInfo.forEach((dossierRequest) =>
        timelineEvents.push({
          id: dossierRequest.id,
          type: TYPE.DOSSIER_REQUESTED,
          createdAt: dossierRequest.createdAt,
        })
      )

      // Application dossier submissions
      const dossierSubmissionsInfo = getNodes(interviewCycle.dossierSubmissions)
      dossierSubmissionsInfo.forEach((dossierSubmission) => {
        if (dossierSubmission.rejectionReason) {
          timelineEvents.push({
            id: dossierSubmission.id,
            type: TYPE.DOSSIER_REJECTED,
            createdAt: dossierSubmission.createdAt,
            reason: dossierSubmission.rejectionReason,
          })
        } else {
          timelineEvents.push({
            id: dossierSubmission.id,
            type: TYPE.DOSSIER_SUBMITTED,
            createdAt: dossierSubmission.createdAt,
            externalLinkUrl: dossierSubmission.fileUrl,
          })
        }
      })

      // Application expert requests
      const expertRequestsInfo = getNodes(interviewCycle.expertRequests)
      expertRequestsInfo.forEach((expertRequest) =>
        timelineEvents.push({
          id: expertRequest.id,
          type: TYPE.EXPERT_REQUESTED,
          createdAt: expertRequest.createdAt,
        })
      )

      // Application interview prep requests
      const interviewPrepRequestsInfo = getNodes(
        interviewCycle.interviewPrepRequests
      )
      interviewPrepRequestsInfo.forEach((interviewPrepRequest) =>
        timelineEvents.push({
          id: interviewPrepRequest.id,
          type: TYPE.INTERVIEW_PREP_REQUESTED,
          createdAt: interviewPrepRequest.createdAt,
        })
      )

      // Application interview
      const interviewsInfo = getNodes(interviewCycle.interviews)
      interviewsInfo.forEach((interview) => {
        timelineEvents.push({
          id: interview.id,
          type: TYPE.INTERVIEW_ADDED,
          createdAt: interview.createdAt,
          internalLinkRoute: generatePath(
            ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
            {
              clientId: clientJob.client.id,
              interviewCycleId: interviewCycle.id,
            }
          ),
        })

        // Application interview debrief
        interview.debriefs.forEach((debrief) =>
          timelineEvents.push({
            id: debrief.id,
            type: TYPE.INTERVIEW_DEBRIEF_ADDED,
            createdAt: debrief.createdAt,
            rating: debrief.rating,
            internalLinkRoute: generatePath(ROUTE_PATHS.INTERVIEW_DEBRIEF, {
              interviewDebriefId: debrief.id,
            }),
          })
        )
      })

      // Application interview cycle closed
      if (interviewCycle.inactiveAt) {
        timelineEvents.push({
          id: interviewCycle.id,
          type: TYPE.INTERVIEW_CYCLE_CLOSED,
          createdAt: interviewCycle.inactiveAt,
          internalLinkRoute: generatePath(
            ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL,
            {
              clientId: clientJob.client.id,
              interviewCycleId: interviewCycle.id,
            }
          ),
        })
      }
    })
  })

  // Huntr Job Actions
  const huntrJobActionsInfo = getNodes(clientJob.huntrJobActions)

  huntrJobActionsInfo.forEach((huntrJobAction) => {
    // Huntr Job Created
    if (huntrJobAction.jobActionType === HuntrActionType.Created) {
      timelineEvents.push({
        id: huntrJobAction.id,
        type: TYPE.HUNTR_CARD_CREATED,
        createdAt: huntrJobAction.huntrAction.createdAt,
        externalLinkUrl: clientJob.huntrUrl,
      })
    }
    // Huntr Job Moved
    if (huntrJobAction.jobActionType === HuntrActionType.Moved) {
      timelineEvents.push({
        id: huntrJobAction.id,
        type: TYPE.HUNTR_CARD_MOVED,
        createdAt: huntrJobAction.huntrAction.createdAt,
        reason: `${
          huntrJobAction.fromListName
            ? HuntrListNameMap[huntrJobAction.fromListName as HuntrColumns]
            : UNKNOWN.UNKNOWN
        } to ${
          huntrJobAction.toListName
            ? HuntrListNameMap[huntrJobAction.toListName as HuntrColumns]
            : UNKNOWN.UNKNOWN
        }`,
        externalLinkUrl: clientJob.huntrUrl,
      })
    }
  })

  // Enrichment requested
  const enrichmentRequestsInfo = getNodes(clientJob.enrichmentRequests)
  enrichmentRequestsInfo.forEach((enrichmentRequest) => {
    timelineEvents.push({
      id: enrichmentRequest.id,
      type: TYPE.ENRICHMENT_REQUESTED,
      createdAt: enrichmentRequest.createdAt,
    })

    // Enrichment submitted/rejected
    const enrichmentSubmissionsInfo = getNodes(
      enrichmentRequest.enrichmentSubmissions
    )
    enrichmentSubmissionsInfo.forEach((enrichmentSubmission) => {
      if (enrichmentSubmission.rejectionReason) {
        timelineEvents.push({
          id: enrichmentSubmission.id,
          type: TYPE.ENRICHMENT_REJECTED,
          createdAt: enrichmentSubmission.createdAt,
          reason:
            EnrichmentRejectionReasonMap[
              enrichmentSubmission.rejectionReason as EnrichmentRejectionReason
            ],
        })
      } else {
        timelineEvents.push({
          id: enrichmentSubmission.id,
          type: TYPE.ENRICHMENT_SUBMITTED,
          createdAt: enrichmentSubmission.createdAt,
        })
      }
    })
  })

  clientJob.taglineRequestRejections.forEach((requestRejection) =>
    // Tagline request rejected
    timelineEvents.push({
      id: requestRejection.id,
      type: TYPE.TAGLINE_REQUEST_REJECTED,
      createdAt: requestRejection.createdAt,
      reason:
        TaglineRejectionReasonMap[
          requestRejection.rejectionReason as TaglineRejectionReason
        ],
    })
  )

  clientJob.writerSubmissions.forEach((writerSubmission) => {
    // Tagline submission
    timelineEvents.push({
      id: writerSubmission.id,
      type: TYPE.TAGLINE_SUBMITTED,
      createdAt: writerSubmission.createdAt,
    })

    writerSubmission.talentAgentReviews.forEach((talentAgentReview) => {
      if (talentAgentReview.taglineRejectionReason) {
        // Talent agent tagline rejection
        timelineEvents.push({
          id: talentAgentReview.id,
          type: TYPE.INITIAL_REVIEW_REJECTED,
          createdAt: talentAgentReview.createdAt,
          rating: talentAgentReview.rating,
          reason: talentAgentReview.taglineRejectionReason,
        })
      } else {
        // Talent agent review
        timelineEvents.push({
          id: talentAgentReview.id,
          type: TYPE.INITIAL_REVIEW_APPROVED,
          createdAt: talentAgentReview.createdAt,
          rating: talentAgentReview.rating,
        })
      }
    })

    writerSubmission.talentPartnerReviews.forEach((talentPartnerReview) => {
      if (talentPartnerReview.taglineRejectionReason) {
        // Talent partner tagline rejection
        timelineEvents.push({
          id: talentPartnerReview.id,
          type: TYPE.FINAL_REVIEW_REJECTED,
          createdAt: talentPartnerReview.createdAt,
          rating: talentPartnerReview.ratingTalentAgent,
          reason: talentPartnerReview.taglineRejectionReason,
        })
      } else {
        // Talent partner review
        timelineEvents.push({
          id: talentPartnerReview.id,
          type: TYPE.FINAL_REVIEW_APPROVED,
          createdAt: talentPartnerReview.createdAt,
          rating: talentPartnerReview.ratingTalentAgent,
        })
      }
    })
  })

  return timelineEvents.sort((a, b) => sortByProp(b, a, 'createdAt'))
}

export function getJobHistoryState(
  sourcedJobQueryResult: SourcedJobQueryResult,
  taglineRequestJobQueryResult: TaglineRequestJobQueryResult,
  taglineRequestId: string | null
): JobHistoryState | null {
  if (taglineRequestId) {
    const { data, loading, error } = taglineRequestJobQueryResult
    if (loading) {
      return {
        isLoading: loading,
      }
    }
    if (error) {
      return {
        isErrored: Boolean(error),
      }
    }
    if (!data?.taglineRequest) {
      return null
    }
    const timelineEvents = buildTaglineRequestJobTimelineEvents(data.taglineRequest)
    return {
      timelineEvents,
      clientInfo: {
        name: data.taglineRequest.client.name,
        jobTitle: data.taglineRequest.jobTitle,
        employerName:
          data.taglineRequest.company?.name ?? data.taglineRequest.employerName,
      },
    }
  }
  const { data, loading, error } = sourcedJobQueryResult
  if (loading) {
    return {
      isLoading: loading,
    }
  }
  if (error) {
    return {
      isErrored: Boolean(error),
    }
  }
  if (!data?.sourcedJob) {
    return null
  }
  const timelineEvents = buildSourcedJobTimelineEvents(data.sourcedJob)
  return {
    timelineEvents,
    clientInfo: {
      name: data.sourcedJob.client.name,
      jobTitle: data.sourcedJob.title,
      employerName: data.sourcedJob.company.name,
    },
  }
}
