import React from 'react'
import { generatePath } from 'react-router'
import { DATE_FORMAT, INITIAL } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import DueDateTableItem from 'components/DueDateTableItem/DueDateTableItem'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import { GroupedDossierRequestsByClient } from './DossierTable.types'

type Props = {
  rowData: GroupedDossierRequestsByClient
}

export default function DossierTableClientRow({
  rowData,
}: Props): React.ReactElement {
  const showQueueButton = rowData.dossierRequests.length > 0
  return (
    <tr key={rowData.clientId}>
      <TableBodyItem first content={rowData.clientName} />
      <TableBodyItem content={rowData.dossierRequests.length} />
      <TableBodyItem>
        <DueDateTableItem
          items={rowData.dossierRequests}
          dateFormat={DATE_FORMAT.DATE_AND_TIME}
        />
      </TableBodyItem>
      <TableBodyActionList>
        {showQueueButton && (
          <TableBodyAction
            content="Queue"
            to={generatePath(ROUTE_PATHS.DOSSIER_QUEUE_BY_FREE_AGENT, {
              freeAgentId: rowData.clientId,
              requestId: INITIAL,
            })}
          />
        )}
      </TableBodyActionList>
    </tr>
  )
}
