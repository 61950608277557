import { gql } from '@apollo/client'
import React from 'react'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { FreeAgent } from './TaglineStatus.types'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  freeAgent: FreeAgent
}

function TaglineStatusHighlightedRow({ freeAgent }: Props): JSX.Element {
  return (
    <BodyRow color="blueHighlight">
      <BodyData colSpan={100}>
        <Txt as="span" size={14} bold>
          {freeAgent.name}
          <Padding horizontal={3} inline>
            <Txt as="span" size={14}>
              |
            </Txt>
          </Padding>
          {freeAgent.talentAgent.name}
        </Txt>
      </BodyData>
    </BodyRow>
  )
}

TaglineStatusHighlightedRow.fragments = {
  workHighlightedRowInfo: gql`
    fragment TaglineStatusHighlightedRowInfo on Client {
      id
      name
      talentAgent {
        id
        name
      }
    }
  `,
}

export default TaglineStatusHighlightedRow
