import { gql, QueryHookOptions, useQuery } from '@apollo/client'
import dayjs, { Dayjs } from 'dayjs'
import { MaybeConnectionNode, KeyValue, Undefinable } from 'types'
import { useAuthContext } from 'context/auth'
import { maybeGetNodes } from 'utils/helpers/getNodes'
import getPodId from 'utils/helpers/getPodId'
import { reduceObjectToId } from 'utils/helpers/reduceObjectToId'
import { FR_POD_COUNT } from 'utils/settings'
import { sortByProp } from 'utils/sort'
import {
  TalentPartnerQueueQuery,
  TalentPartnerQueueQueryVariables,
} from 'generated/graphql'

export type QueueReturn = {
  queueByViewer: string[]
  loading: boolean
  queueByClient: KeyValue<string[]>
  queueByPod: KeyValue<string[]>
}

export type CountReturn = {
  loading: boolean
  totalCount: Undefinable<number>
}

type TaglineRequestRaw = MaybeConnectionNode<
  NonNullable<TalentPartnerQueueQuery['taglineRequests']>
>

type TaglineRequest = TaglineRequestRaw & {
  podId: number
  clientId: string
  dateToSortBy: Dayjs
}

type GroupedTaglineRequests = KeyValue<
  (TaglineRequest & { podId: number; clientId: string; dateToSortBy: Dayjs })[]
>

export default function useTalentPartnerQueue(
  queryOptions: QueryHookOptions<
    TalentPartnerQueueQuery,
    TalentPartnerQueueQueryVariables
  > = {}
): QueueReturn {
  const {
    userSession: {
      user: { id: userId, isCoordinator },
    },
  } = useAuthContext()
  const { data, loading } = useQuery<
    TalentPartnerQueueQuery,
    TalentPartnerQueueQueryVariables
  >(GET_TALENT_PARTNER_QUEUE, {
    ...queryOptions,
    variables: {
      // if user is coordinator than they should be able to view all queues
      finalReviewerId: isCoordinator ? undefined : userId,
      onlyCount: false,
    },
  })

  const nodes = data?.taglineRequests ? maybeGetNodes(data.taglineRequests) : []

  // add all needed props for grouping/sorting
  const taglineRequests = normalizeTaglineRequests(nodes || [])

  // map client/pod ids to array of tagline requests
  const [groupedByClient, groupedByPod] = groupTaglineRequests(taglineRequests)

  // sort groups according to their first element's talent initial review date
  const sortedTaglinesByClient = Object.values(groupedByClient).sort((a, b) =>
    sortByProp(a[0], b[0], 'dateToSortBy')
  )
  const sortedTaglinesByPod = Object.values(groupedByPod).sort((a, b) =>
    sortByProp(a[0], b[0], 'dateToSortBy')
  )

  // build the full queue (with all of the work)
  const queueByViewer = sortedTaglinesByClient
    .flat()
    .filter((taglineRequest) => taglineRequest.finalReviewer?.id === userId)
    .sort((a, b) => sortByProp(a, b, 'dateToSortBy'))
    .map(reduceObjectToId)

  // build queue map {[clientId]: taglineRequestId[]}
  const queueByClient = sortedTaglinesByClient.reduce(
    (finalObject, clientRequests) =>
      clientRequests[0]
        ? {
            ...finalObject,
            [clientRequests[0].clientId]: clientRequests.map(reduceObjectToId),
          }
        : finalObject,
    {}
  )

  // build queue map {[podId]: taglineRequestId[]}
  const queueByPod = sortedTaglinesByPod.reduce(
    (finalObject, podRequests) =>
      podRequests[0]
        ? {
            ...finalObject,
            [podRequests[0].podId]: podRequests.map(reduceObjectToId),
          }
        : finalObject,
    {}
  )

  return { queueByViewer, queueByClient, loading, queueByPod }
}

export function useTalentPartnerQueueCount(
  queryOptions: QueryHookOptions<
    TalentPartnerQueueQuery,
    TalentPartnerQueueQueryVariables
  > = {}
): CountReturn {
  const { userSession } = useAuthContext()
  const { data, loading } = useQuery<
    TalentPartnerQueueQuery,
    TalentPartnerQueueQueryVariables
  >(GET_TALENT_PARTNER_QUEUE, {
    ...queryOptions,
    variables: {
      finalReviewerId: userSession.user.id,
      onlyCount: true,
    },
  })

  return { loading, totalCount: data?.taglineRequests?.totalCount ?? undefined }
}

function normalizeTaglineRequests(requests: TaglineRequestRaw[]): TaglineRequest[] {
  return requests
    .map((request) => ({
      ...request,
      podId: getPodId(request.client.id, FR_POD_COUNT),
      clientId: request.client.id,
      dateToSortBy: dayjs(request.talentAgentReviews[0]?.createdAt),
    }))
    .sort((a, b) => sortByProp(a, b, 'dateToSortBy'))
}

function groupTaglineRequests(
  taglineRequests: TaglineRequest[]
): [GroupedTaglineRequests, GroupedTaglineRequests] {
  return taglineRequests.reduce(
    (
      [clientMap, podMap]: [GroupedTaglineRequests, GroupedTaglineRequests],
      taglineRequest
    ) => {
      // client & pod map keys
      const clientId = taglineRequest.clientId
      const podId = taglineRequest.podId

      // build the client map
      const clientEntry = clientMap[clientId]
      if (clientEntry) {
        clientEntry.push(taglineRequest)
      } else {
        clientMap[clientId] = [taglineRequest]
      }

      // build the pod map
      const podEntry = podMap[podId]
      if (podEntry) {
        podEntry.push(taglineRequest)
      } else {
        podMap[podId] = [taglineRequest]
      }
      return [clientMap, podMap]
    },
    [{}, {}]
  )
}

/**
 * used by talent partners to queue through all of their assigned requests
 */
const GET_TALENT_PARTNER_QUEUE = gql`
  query TalentPartnerQueue($finalReviewerId: ID, $onlyCount: Boolean!) {
    taglineRequests(finalReviewerId: $finalReviewerId, status: PENDING_TP_REVIEW) {
      totalCount @include(if: $onlyCount)
      edges @skip(if: $onlyCount) {
        node {
          id
          due
          status
          client {
            id
          }
          finalReviewer {
            id
          }
          talentAgentReviews {
            id
            createdAt
          }
        }
      }
    }
  }
`
