import { Role } from 'utils/enums'
import { pages } from 'utils/pages'
import ROUTE_PATHS from 'utils/routePaths'

export const assignItems = [
  {
    header: 'Requests',
    items: [
      {
        content: 'Sourcing',
        to: ROUTE_PATHS.AVAILABLE_SOURCING,
        roles: [Role.Coordinator],
      },
      {
        content: 'Taglines',
        to: ROUTE_PATHS.AVAILABLE_TAGLINES,
        roles: [Role.Coordinator],
      },
      {
        content: 'Applications',
        to: ROUTE_PATHS.AVAILABLE_APPLICATIONS,
        roles: [Role.Coordinator],
      },
      {
        content: 'Dossiers',
        to: ROUTE_PATHS.AVAILABLE_DOSSIERS,
        roles: [Role.Coordinator],
      },
      {
        content: 'Expert Requests',
        to: ROUTE_PATHS.EXPERT_REQUESTS,
        roles: [Role.ExpertCoordinator],
      },
      {
        content: 'Final Reviews',
        to: ROUTE_PATHS.AVAILABLE_FINAL_REVIEWS,
        roles: [Role.Coordinator],
      },
      {
        content: 'Interview Preps',
        to: pages.interviewPrepRequestsAssignment.to(),
        roles: pages.interviewPrepRequestsAssignment.permittedRoles,
      },
    ],
  },
  {
    header: 'Allocations',
    items: [
      {
        content: 'Auto Sourcing',
        to: ROUTE_PATHS.AUTO_SOURCING_DASHBOARD,
        roles: [Role.Coordinator],
      },
      {
        content: 'Current Assignments',
        to: ROUTE_PATHS.CURRENT_ASSIGNMENTS,
        roles: [Role.Coordinator],
      },
    ],
  },
  {
    header: 'Stats',
    items: [
      {
        content: 'Writers',
        to: ROUTE_PATHS.REVIEWED_TAGLINES,
        roles: [Role.TalentPartner, Role.Coordinator],
      },
      {
        content: 'TEEs',
        to: pages.talentEnablementEditorsStats.to(),
        roles: pages.talentEnablementEditorsStats.permittedRoles,
      },
      {
        content: 'Sourcers',
        to: ROUTE_PATHS.SOURCER_STATS,
        roles: [Role.Coordinator],
      },
      {
        content: 'Sourcing Stats',
        to: ROUTE_PATHS.SOURCING_STATS,
        roles: [Role.Coordinator],
      },
    ],
  },
]

export const accountsItems = [
  {
    content: 'Clients',
    to: ROUTE_PATHS.CLIENTS,
    roles: [Role.Coordinator],
  },
  {
    content: 'Users',
    to: ROUTE_PATHS.USERS,
    roles: [Role.Coordinator],
  },
]

export const collectionsItems = [
  {
    content: 'Companies',
    to: pages.companies.to(),
    roles: pages.companies.permittedRoles,
  },
  {
    content: 'Contracts',
    to: ROUTE_PATHS.CONTRACTS,
    roles: [Role.SalesCoordinator],
  },
  {
    content: 'Dossier Library',
    to: ROUTE_PATHS.DOSSIER_LIBRARY,
    roles: [Role.Coordinator, Role.TalentAgent, Role.TalentEnablement],
  },
  {
    content: 'Expert Network',
    to: ROUTE_PATHS.EXPERT_NETWORK,
    roles: [Role.ExpertCoordinator, Role.TalentAgent, Role.TalentEnablement],
  },
]
