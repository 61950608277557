import { useCallback, useState } from 'react'
import { ReactSetState } from 'types'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type SetFormField<T extends Record<string, any>> = <K extends keyof T>(
  prop: keyof T,
  value: T[K]
) => void

/**
 * simple hook used to control the state of a form
 *
 * @param initialState - initial form state or function that returns initial form state
 * @returns formState - the current state of the form
 *          setFormField - set a specific fields value
 *          setFormState - set the entire state of the form (useful for resetting)
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function useFormState<T extends Record<string, any>>(
  initialState: T | (() => T)
): {
  formState: T
  setFormField: SetFormField<T>
  setFormState: ReactSetState<T>
} {
  const [formState, setFormState] = useState<T>(initialState)
  const setFormField = useCallback(
    <K extends keyof T>(prop: keyof T, value: T[K]) => {
      setFormState({ ...formState, [prop]: value })
    },
    [formState]
  )
  return { formState, setFormField, setFormState }
}
