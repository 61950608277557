import { gql, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { Prompt } from 'react-router-dom'
import ClientTemplatesFooter from 'views/ClientTemplates/ClientTemplatesFooter'
import ClientTemplatesLeft from 'views/ClientTemplates/ClientTemplatesLeft'
import ClientTemplatesRight from 'views/ClientTemplates/ClientTemplatesRight'
import DualScroll from 'components/DualScroll/DualScroll'
import { ConfigPageEditorChanges } from './ClientTemplates.types'
import { ClientQuery } from 'generated/graphql'

type Props = {
  clientId: string
}

export default function ClientTemplates({ clientId }: Props): JSX.Element {
  // TODO: (@matthewalbrecht) handle error with toast
  const { data } = useQuery<ClientQuery>(GET_CLIENT_BY_ID, {
    variables: { id: clientId },
  })

  const [activeSequence, setActiveSequence] = useState(0)
  const [editorChanges, setEditorChanges] = useState<ConfigPageEditorChanges>({})
  const client = data?.client
  const shouldBlockNavigation = !!Object.keys(editorChanges).length

  return (
    <>
      <Prompt
        when={shouldBlockNavigation}
        message="You have unsaved changes, are you sure you want to leave?"
      />
      <DualScroll
        leftContent={<ClientTemplatesLeft client={client} />}
        rightContent={
          <ClientTemplatesRight
            activeSequence={activeSequence}
            setActiveSequence={setActiveSequence}
            client={client}
            clientId={clientId}
            setEditorChanges={setEditorChanges}
            editorChanges={editorChanges}
          />
        }
      />
      <ClientTemplatesFooter
        editorChanges={editorChanges}
        setEditorChanges={setEditorChanges}
      />
    </>
  )
}

/**
 * used by TA/TP to get all the data on a specific client (for config page)
 */
const GET_CLIENT_BY_ID = gql`
  query Client($id: ID!) {
    client(id: $id) {
      id
      ...ClientTemplatesLeftInfo
      ...ClientTemplatesRightInfo
    }
  }
  ${ClientTemplatesLeft.fragments.freeAgentConfigLeftInfo}
  ${ClientTemplatesRight.fragments.freeAgentConfigRightInfo}
`
