import { gql } from '@apollo/client'
import React from 'react'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import { RectangleSkeletonWrap } from 'components/Skeletons/Skeletons'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { Client } from './ClientTemplates.types'
import { TextArea } from 'components/Inputs'
import VList from 'components/VList'

type Props = {
  client: Client | null | undefined
}

function ClientTemplatesLeft({ client }: Props): JSX.Element {
  const clientExists = Boolean(client)
  return (
    <VList size={4}>
      <RectangleSkeletonWrap
        height={450}
        width="100%"
        withViewBox={false}
        showContent={clientExists}
      >
        {client?.resumeUrl && <PDFViewer url={client.resumeUrl} />}
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap width={180} height={16} showContent={clientExists}>
        <WorkInfoLinks
          resumeUrl={client?.resumeUrl}
          linkedinUrl={client?.linkedinUrl}
        />
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        fullWidth
        width="100%"
        height={120}
        withViewBox={false}
        showContent={clientExists}
      >
        <TextArea
          label="Location Preferences"
          defaultValue={client?.locationPrefs || ''}
          height={80}
          disabled
        />
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        fullWidth
        width="100%"
        height={120}
        withViewBox={false}
        showContent={clientExists}
      >
        <TextArea
          label="Job Preferences"
          defaultValue={client?.jobPrefs || ''}
          height={120}
          disabled
        />
      </RectangleSkeletonWrap>
      <RectangleSkeletonWrap
        fullWidth
        width="100%"
        height={80}
        withViewBox={false}
        showContent={clientExists}
      >
        <TextArea
          defaultValue={client?.bio || ''}
          label={`${client?.name ?? ''} Bio`}
          height={80}
          disabled
        />
      </RectangleSkeletonWrap>
    </VList>
  )
}

ClientTemplatesLeft.fragments = {
  freeAgentConfigLeftInfo: gql`
    fragment ClientTemplatesLeftInfo on Client {
      bio
      id
      jobPrefs
      linkedinUrl
      locationPrefs
      name
      resumeUrl
    }
  `,
}

export default ClientTemplatesLeft
