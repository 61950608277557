import React from 'react'
import Button from 'components/Button'

export default function WriterActivitiesNav(): JSX.Element {
  return (
    <>
      <Button $type="link" active>
        Activity Log
      </Button>
      {/* TODO: add these buttons back when filtering is available */}
      {/* <Button $type="link">Submissions</Button>
      <Button $type="link">Feedback</Button> */}
    </>
  )
}
