// built from https://github.com/jameslnewell/styled-components-spacing

import styled from 'styled-components/macro'
import { Props, _p, _px, _py, _pt, _pr, _pb, _pl } from 'utils/spacing'

const Padding = styled.div<Props>`
  ${({ inline }) => (inline ? 'display: inline-block;' : '')}
  ${({ all, theme }) => (all ? _p(all, theme) : '')}
  ${({ horizontal, theme }) => (horizontal ? _px(horizontal, theme) : '')}
  ${({ vertical, theme }) => (vertical ? _py(vertical, theme) : '')}
  ${({ top, theme }) => (top ? _pt(top, theme) : '')}
  ${({ right, theme }) => (right ? _pr(right, theme) : '')}
  ${({ bottom, theme }) => (bottom ? _pb(bottom, theme) : '')}
  ${({ left, theme }) => (left ? _pl(left, theme) : '')}
`

export default Padding
