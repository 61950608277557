import { generatePath } from 'react-router'
import { QS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { createSearchParams } from 'utils/urls'

/**
 * generates a path to the edit page for application
 *
 * @param id id of item to edit
 * @param pathname current pathname
 */
export default function getEditPath(id: string, pathname: string): string {
  return generatePath(
    `${ROUTE_PATHS.APPLICATION_INFO}/?${createSearchParams({
      [QS.CALLBACK]: pathname,
    })}`,
    {
      applicationId: id,
    }
  )
}
