import dayjs from 'dayjs'
import { KeyValue } from 'types'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import {
  SourcingRequest,
  ClientWithSourcingRequests,
  DueWithSourcingRequests,
  AssigneeWithSourcingRequests,
  SourcingRequestConnection,
  SourcingRequestConnectionDueDate,
  SourcingRequestConnectionAssigned,
} from './SourcerTable.types'

export function buildRowData(
  connection: SourcingRequestConnection
): ClientWithSourcingRequests[] {
  const nodes = getNodes<SourcingRequest>(connection)

  // Grouping the sourcingRequests by client: {clientId: {id, name, sourcingRequests}}
  const groupedData = nodes.reduce(
    (groups: KeyValue<ClientWithSourcingRequests>, sourcingRequest) => {
      const { id: clientId, name: clientName } = sourcingRequest.client
      const group = groups[clientId]

      if (group) {
        group.sourcingRequests.push(sourcingRequest)
      } else {
        groups[clientId] = {
          id: clientId,
          name: clientName,
          sourcingRequests: [sourcingRequest],
        }
      }
      return groups
    },
    {}
  )

  // sorting the groups by client name
  const rowData = Object.values(groupedData).sort((a, b) =>
    sortByProp(a, b, 'clientName')
  )

  return rowData
}

export function buildRowDataByDueDate(
  connection: SourcingRequestConnectionDueDate
): DueWithSourcingRequests[] {
  const nodes = getNodes(connection)

  // Grouping the sourcingRequests by due date -> {due: {due, sourcingRequests}
  const groupedData = nodes.reduce(
    (groups: KeyValue<DueWithSourcingRequests>, sourcingRequest) => {
      const groupId = sourcingRequest.due
      const group = groups[groupId]

      if (group) {
        group.sourcingRequests.push(sourcingRequest)
      } else {
        groups[groupId] = {
          due: sourcingRequest.due,
          sortableDue: dayjs(sourcingRequest.due),
          sourcingRequests: [sourcingRequest],
        }
      }
      return groups
    },
    {}
  )

  // sorting the groups by client name
  const rowData = Object.values(groupedData).sort((a, b) =>
    sortByProp(a, b, 'sortableDue')
  )

  return rowData
}

export function buildRowDataByAssignee(
  connection: SourcingRequestConnectionAssigned
): AssigneeWithSourcingRequests[] {
  const nodes = getNodes(connection)

  // Grouping the sourcingRequests by assignee -> {due: {due, sourcingRequests}}
  const groupedData = nodes.reduce(
    (groups: KeyValue<AssigneeWithSourcingRequests>, sourcingRequest) => {
      // business logic ensures these are non nullable
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      const groupId = sourcingRequest.assignee!.id
      const group = groups[groupId]

      if (group) {
        group.sourcingRequests.push(sourcingRequest)
      } else {
        groups[groupId] = {
          // business logic ensures these are non nullable
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          assigneeId: sourcingRequest.assignee!.id,
          // business logic ensures these are non nullable
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          assigneeName: sourcingRequest.assignee!.name,
          sourcingRequests: [sourcingRequest],
        }
      }
      return groups
    },
    {}
  )

  // sorting the groups by client name
  const rowData = Object.values(groupedData).sort((a, b) =>
    sortByProp(a, b, 'sortableDue')
  )

  return rowData
}

export function getNumJobsSum(sourcingRequests: { numJobs: number }[]): number {
  return sourcingRequests.reduce((total, request) => {
    return total + request.numJobs
  }, 0)
}
