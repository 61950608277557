import { createGlobalStyle } from 'styled-components/macro'
import { reset } from 'styled-reset'

/* stylelint-disable no-duplicate-selectors */
const GlobalStyles = createGlobalStyle`
/* ---------------------------------------------
 Reset CSS
--------------------------------------------- */
${reset}

html {
  overflow-y: scroll;
}

html {
  text-size-adjust: 100%;
}

audio, canvas, video, progress, picture {
  display: inline-block;
}

template {
  display: none;
}

textarea {
  overflow: auto;
  vertical-align: top;
  resize: vertical;
}

::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/* ---------------------------------------------
 Base Element Settings
--------------------------------------------- */

* {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
  width: 100%;
}

html {
  font-size: 10px;
}

body {
  font-size: 1.6rem;
}

button {
  appearance: none;
  background: transparent;
  border: 0;
  border-radius: 0;
  cursor: pointer;
  padding: 0;
}

a {
  text-decoration: none;
}


/* ---------------------------------------------
 FOUC
--------------------------------------------- */

a,
button,
h1,
h2,
h3,
h4,
h5,
h6,
label,
input,
textarea,
p {
  opacity: 0;
  font-family: ${({ theme }) => theme.font.primary};
  transition: opacity 0.1s ease-out;
}

.wf-active a,
.wf-active button,
.wf-active h1,
.wf-active h2,
.wf-active h3,
.wf-active h4,
.wf-active h5,
.wf-active h6,
.wf-active label,
.wf-active input,
.wf-active textarea,
.wf-active p {
  opacity: 1;
}
`
/* stylelint-enable no-duplicate-selectors */

export default GlobalStyles
