import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { DATE_FORMAT, TZ, TZ_OPTIONS } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { DateFilter } from './ReviewEnrichment.constants'
import { groupJobs } from './ReviewEnrichment.helpers'
import ReviewEnrichmentTable from './ReviewEnrichmentTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { DatePicker, Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetEnrichmentSubmissionsForReviewQuery,
  GetEnrichmentSubmissionsForReviewQueryVariables,
} from 'generated/graphql'

export default function ReviewEnrichment(): React.ReactElement {
  const [dateFilter, setDateFilter] = useState<DateFilter>({
    timezone: TZ.MANILA,
    date: dayjs().tz(TZ.MANILA).format(DATE_FORMAT.ONLY_DATE),
  })

  // TODO (matthewalbrecht) paginate these results use size of ~200
  const { data, loading, error, refetch } = useQuery<
    GetEnrichmentSubmissionsForReviewQuery,
    GetEnrichmentSubmissionsForReviewQueryVariables
  >(GET_SOURCED_JOBS, {
    variables: {
      dateFilter: dateFilter,
    },
  })

  const tableData = useMemo(
    () => data?.enrichmentSubmissions && groupJobs(data.enrichmentSubmissions),
    [data?.enrichmentSubmissions]
  )

  if (loading) {
    return <Message vertical message="Loading erniched jobs..." />
  }

  if (error) {
    return <RefreshMessage message="There was an error loading erniched jobs" />
  }

  if (!tableData) {
    return <Message vertical message="No erniched jobs found" />
  }

  function handleDateFilterChange(value: string, prop: keyof DateFilter) {
    if (!value) {
      return
    }
    setDateFilter({ ...dateFilter, [prop]: value })
    void refetch({ dateFilter })
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Txt size={24} bold>
            Review Enrichment
          </Txt>
        </Padding>
        <Padding top={4}>
          <HList size={2}>
            <Dropdown
              value={dateFilter.timezone}
              onValueChange={(value) => handleDateFilterChange(value, 'timezone')}
              options={TZ_OPTIONS}
              withEmptyOption={false}
              label="Timezone"
            />
            <DatePicker
              label="Day"
              value={dateFilter.date}
              onValueChange={(value) => handleDateFilterChange(value, 'date')}
            />
          </HList>
        </Padding>
      </Container>
      <Padding top={4}>
        <ReviewEnrichmentTable tableData={tableData} dateFilter={dateFilter} />
      </Padding>
    </ViewBox>
  )
}

ReviewEnrichment.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REDACT_ENRICHMENT}
    key={ROUTE_PATHS.REDACT_ENRICHMENT}
  >
    <ReviewEnrichment />
  </PrivateRoute>,
]

const GET_SOURCED_JOBS = gql`
  query GetEnrichmentSubmissionsForReview($dateFilter: DateFilter) {
    enrichmentSubmissions(dateFilter: $dateFilter) {
      edges {
        node {
          id
          targetLinkedinUrl
          targetName
          targetTitle
          targetEmail
          createdAt
          createdBy {
            id
            name
          }
          enrichmentRequest {
            id
            huntrUrl
            jobTitle
            employerName
            client {
              id
              name
            }
          }
        }
      }
    }
  }
`
