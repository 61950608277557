import { ApolloCache, gql, Reference } from '@apollo/client'
import { Maybe, MutationResult } from 'types'
import { CACHE_STRING } from 'utils/cacheString'
import { TexFragment } from './Users.constants'
import { State, User } from './Users.types'
import {
  CreateTexUserMutation,
  CreateTexUserMutationVariables,
  ModifyTexUserMutationVariables,
} from 'generated/graphql'

export function getCreateUserVariables(
  state: State
): CreateTexUserMutationVariables {
  return {
    CreateTexUserInput: {
      //  HTML validation requires these to exist before submitting
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      name: state.name!,
      //  HTML validation requires these to exist before submitting
      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      email: state.email!,
      isWriter: Boolean(state.roles?.isWriter),
      isDossierWriter: Boolean(state.roles?.isDossierWriter),
      isSourcer: Boolean(state.roles?.isSourcer),
      isTea: Boolean(state.roles?.isTea),
      isTee: Boolean(state.roles?.isTee),
      isEnricher: Boolean(state.roles?.isEnricher),
      isApplier: Boolean(state.roles?.isApplier),
      isInactive: !state.isActive,
    },
  }
}
export function getModifyUserVariables(
  state: State,
  userId: string
): ModifyTexUserMutationVariables {
  return {
    ModifyTexUserInput: {
      userId,
      isWriter: Boolean(state.roles?.isWriter),
      isDossierWriter: Boolean(state.roles?.isDossierWriter),
      isSourcer: Boolean(state.roles?.isSourcer),
      isTea: Boolean(state.roles?.isTea),
      isTee: Boolean(state.roles?.isTee),
      isEnricher: Boolean(state.roles?.isEnricher),
      isApplier: Boolean(state.roles?.isApplier),
      isInactive: !state.isActive,
    },
  }
}

export function getDefaultState(user: Maybe<User>): State {
  if (!user) {
    return { isActive: true }
  }
  const { name, email, isInactive, ...roles } = user
  return {
    name,
    email,
    roles,
    isActive: !isInactive,
  }
}

export function addNewUserToCache(
  cache: ApolloCache<CreateTexUserMutation>,
  { data }: MutationResult<CreateTexUserMutation>
): void {
  if (data?.createTexUser) {
    cache.modify({
      fields: {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        [CACHE_STRING.TEX_USER_LIST]: (existingUsers: Reference[] = []) => {
          const newUserRef = cache.writeFragment({
            data: data.createTexUser.user,
            fragmentName: 'NewUser',
            fragment: gql`
              fragment NewUser on User {
                ...TexInfo
              }
              ${TexFragment}
            `,
          })
          return [...existingUsers, newUserRef]
        },
      },
    })
  }
}
