import { gql, useMutation } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { ReactFormEvent, ReactSetState } from 'types'
import { INTERVIEW_TIMEZONE_OPTIONS, UNKNOWN } from 'utils/constants'
import { getScheduledFor } from 'views/AddAndEditInterview/AddAndEditInterview.helpers'
import { COPY, DEFAULT_STATE, ID } from './ReportInterviewTime.constants'
import { getDefaultState } from './ReportInterviewTime.helpers'
import { Interview, Params, State, Status } from './ReportInterviewTime.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import { DatePicker, RadioGroup } from 'components/Inputs'
import { CenteredBox } from 'components/MiscStyles'
import Padding from 'components/Padding'
import Txt, { Bold } from 'components/Txt'
import VList from 'components/VList'
import {
  ReportInterviewTimeMutation,
  ReportInterviewTimeMutationVariables,
} from 'generated/graphql'

type Props = {
  interview: Interview
  setStatus: ReactSetState<Status>
}

export default function ReportInterviewTimeForm({
  interview,
  setStatus,
}: Props): React.ReactElement {
  const { interviewPublicId } = useParams<Params>()
  const [state, setState] = useState<State>(() => getDefaultState(interview))

  const [reportTime] = useMutation<
    ReportInterviewTimeMutation,
    ReportInterviewTimeMutationVariables
  >(REPORT_INTERVIEW_TIME, {
    onCompleted() {
      toast.success(COPY.SUBMIT_SUCCESS)
      /* TODO (matthewalbrecht): check success value */
      setStatus(Status.Success)
    },
    onError() {
      toast.error(COPY.SUBMIT_ERROR)
    },
  })

  /**
   * updates state with new input value
   * @param value input value
   * @param prop state to change
   */
  function handleInputChange<T = string>(value: T, prop: keyof State) {
    setState({ ...state, [prop]: value })
  }

  function handleUnschedule() {
    setState(DEFAULT_STATE)
    submitTime(true)
  }

  function handleSubmit(event: ReactFormEvent) {
    event.preventDefault()
    submitTime(false)
  }

  function submitTime(isUnscheduling: boolean) {
    const variables = {
      ScheduleInterviewPublicInput: {
        interviewPublicId,
        scheduledFor: isUnscheduling ? null : getScheduledFor(state),
      },
    }

    void reportTime({ variables })
  }
  return (
    <CenteredBox>
      <form id={ID.FORM} onSubmit={handleSubmit}>
        <Padding top={4} bottom={4}>
          <Txt bold as="h2" size={30}>
            Report Scheduled Interview Time
          </Txt>
          <Padding top={1.5}>
            <Txt lineHeight={1.3} color="faGrey4">
              We see that
              <Bold>
                &nbsp;
                {interview.employerName || UNKNOWN.EMPLOYER_NAME}
                &nbsp;
              </Bold>
              has requested an interview with you for the
              <Bold>
                &nbsp;
                {interview.jobTitle || UNKNOWN.JOB_TITLE}
                &nbsp;
              </Bold>
              role. Please let us know once that interview is scheduled.
            </Txt>
          </Padding>
        </Padding>
        <VList size={4}>
          <RadioGroup
            label="Timezone"
            name="timezone"
            options={INTERVIEW_TIMEZONE_OPTIONS}
            value={state.timezone || ''}
            onValueChange={(value) => handleInputChange(value, 'timezone')}
          />
          <DatePicker
            label="Scheduled at"
            type="datetime-local"
            value={state.datetime}
            onValueChange={(value) => handleInputChange(value, 'datetime')}
          />
        </VList>
        <Padding top={6}>
          <Flex justify="flex-end">
            <HList size={1}>
              {interview.scheduledFor && (
                <Button $type="primary" type="button" onClick={handleUnschedule}>
                  No Longer Scheduled
                </Button>
              )}
              <Button $type="accept">Submit</Button>
            </HList>
          </Flex>
        </Padding>
      </form>
    </CenteredBox>
  )
}

const REPORT_INTERVIEW_TIME = gql`
  mutation ReportInterviewTime(
    $ScheduleInterviewPublicInput: ScheduleInterviewPublicInput!
  ) {
    scheduleInterviewPublic(input: $ScheduleInterviewPublicInput) {
      success
    }
  }
`
