import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import FullWidthTable from 'components/FullWidthTable/FullWidthTable'
import { COLUMNS } from './ClientStatus.constants'
import ClientStatusRow from './ClientStatusRow'
import {
  GetAllClientsStatusUpdatesQuery,
  GetAllClientsStatusUpdatesQueryVariables,
} from 'generated/graphql'

export default function ClientStatusTable(): React.ReactElement {
  const { data, loading, error } = useQuery<
    GetAllClientsStatusUpdatesQuery,
    GetAllClientsStatusUpdatesQueryVariables
  >(GET_ALL_CLIENTS_STATUS_UPDATES)

  const clients = data?.clients
    ? getNodes(data.clients)
        .map((client) => ({
          ...client,
          dateToSortBy: client.statusUpdates.edges[0]
            ? dayjs(client.statusUpdates.edges[0].node.createdAt)
            : null,
        }))
        .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))
    : []

  return (
    <FullWidthTable
      errorMessage="There was an error fetching client statuses"
      isError={Boolean(error)}
      loadingMessage="Loading client statuses..."
      isLoading={loading}
      isEmpty={Boolean(!loading && !clients.length)}
      emptyMessage="No client statuses found."
      headers={Object.values(COLUMNS)}
    >
      {clients.map((client) => (
        <ClientStatusRow key={client.id} client={client} />
      ))}
    </FullWidthTable>
  )
}

const GET_ALL_CLIENTS_STATUS_UPDATES = gql`
  query getAllClientsStatusUpdates {
    clients {
      edges {
        node {
          id
          ...ClientStatusRowInfo
        }
      }
    }
  }

  ${ClientStatusRow.fragments.clientRowInfo}
`
