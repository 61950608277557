import React from 'react'
import Editor from 'components/Editor/Editor'
import { SourcedJob } from './SourcedJobsReview.types'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'

type Props = {
  sourcedJob: SourcedJob
}

export default function SourcedJobsReviewLeft({ sourcedJob }: Props): JSX.Element {
  return (
    <>
      <Padding bottom={4}>
        <TextArea
          defaultValue={sourcedJob.company.companyDescription ?? 'No summary found.'}
          label={`${sourcedJob.company.name} Description`}
          disabled
          rows={4}
        />
      </Padding>
      <Padding bottom={4}>
        <TextArea
          defaultValue={
            sourcedJob.notes.length ? sourcedJob.notes : 'No sourcer notes found.'
          }
          label="Sourcer Notes"
          disabled
          rows={4}
        />
      </Padding>
      <Editor
        label="Job Description"
        defaultValue={sourcedJob.jobDescription}
        disabled
      />
    </>
  )
}
