import * as Sentry from '@sentry/react'
import React, { useCallback, useState } from 'react'
import AutoSuggest, {
  SuggestionsFetchRequestedParams,
  InputProps,
  SuggestionSelectedEventData,
  SuggestionHighlightedParams,
} from 'react-autosuggest'
import { toast } from 'react-toastify'
import debounce from 'utils/helpers/debounce'
import { TOAST } from './CompanySuggestion.constants'
import {
  getCompanies,
  getSuggestionValue,
  shouldRenderSuggestions,
} from './CompanySuggestion.helpers'
import { AutoSuggestStyles, Relative } from './CompanySuggestion.styles'
import { DebounceFunctionType, Suggestion } from './CompanySuggestion.types'
import { CompanySuggestionItem } from './CompanySuggestionItem'
import Label from 'components/Label'

type Props = {
  value: string
  onValueChange: (value: string) => void
  label?: string
  selection: Suggestion | null
  onSelectionValueChange(value: Suggestion | null): void
  required: boolean
  onBlur?: () => void
}

export default function CompanySuggestion({
  value,
  onValueChange,
  selection,
  onSelectionValueChange,
  label,
  required,
  onBlur,
}: Props): React.ReactElement {
  const [suggestions, setSuggestions] = useState<Suggestion[]>([])
  const [isSuggestionHighlighted, setIsSuggestedHighlighted] = useState(false)
  /**
   * fetches companies from clearbit Autocomplete API
   */
  const onSuggestionsFetchRequested = useCallback(
    async ({ value }: SuggestionsFetchRequestedParams) => {
      try {
        const newSuggestions = await getCompanies(value)
        if ('message' in newSuggestions) {
          throw new Error(newSuggestions.message)
        }
        setSuggestions([
          ...newSuggestions,
          { name: value, logo: null, domain: null },
        ])
      } catch (error) {
        toast.error(TOAST.FETCH_ERROR)
        Sentry.captureException(error)
      }
    },
    [setSuggestions]
  )

  /**
   * debounced onSuggestionsFetchRequested function
   */
  /* TODO (matthewalbrecht): rewrite this to pass linting */
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedFetch = useCallback(
    debounce<DebounceFunctionType>(onSuggestionsFetchRequested, 200),
    [onSuggestionsFetchRequested]
  )

  /**
   * clear suggestions
   */
  function onSuggestionsClearRequested() {
    setSuggestions([])
  }

  function handleSuggestionSelected(
    e: React.FormEvent<unknown>,
    { suggestion }: SuggestionSelectedEventData<Suggestion>
  ) {
    e.stopPropagation()
    onSelectionValueChange(suggestion)
  }

  function handleSuggestionHighlighted({ suggestion }: SuggestionHighlightedParams) {
    setIsSuggestedHighlighted(suggestion != null)
  }

  const allInputProps: InputProps<Suggestion> = {
    value,
    onChange(_, { newValue }) {
      onValueChange(newValue)

      if (!newValue) {
        onSelectionValueChange(null)
      }
    },
    onBlur,
    onKeyDown,
    type: 'search',
    placeholder: 'Enter company name',
    required: required,
  }

  function onKeyDown(event: React.KeyboardEvent<HTMLInputElement>) {
    if (event.key === 'Enter') {
      event.preventDefault()
      if (!isSuggestionHighlighted) {
        // if user presses enter while no suggestions are selected we
        // send the current value of the input as the name of the company
        onSelectionValueChange({ name: value, domain: '', logo: '' })
      }
    }
  }

  // render individual element in suggestion list
  const renderSuggestion = (suggestion: Suggestion) => (
    <CompanySuggestionItem suggestion={suggestion} />
  )

  return (
    <AutoSuggestStyles logoActive={Boolean(selection?.logo)}>
      {label && <Label content={label} />}
      <Relative>
        <AutoSuggest<Suggestion>
          suggestions={suggestions}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          onSuggestionsFetchRequested={debouncedFetch}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          inputProps={allInputProps}
          shouldRenderSuggestions={shouldRenderSuggestions}
          onSuggestionSelected={handleSuggestionSelected}
          onSuggestionHighlighted={handleSuggestionHighlighted}
        />
        {selection?.logo && <img src={selection.logo} alt={selection.name} />}
      </Relative>
    </AutoSuggestStyles>
  )
}
