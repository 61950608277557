import styled from 'styled-components/macro'
import { Undefinable } from 'types'
import { NavStatus } from 'utils/enums'

type StyledMainProps = {
  status: Undefinable<NavStatus>
}

export const StyledMain = styled.main<StyledMainProps>`
  padding-left: ${({ theme, status = NavStatus.Collapsed }) =>
    status === NavStatus.Collapsed ? theme.toRems(56) : theme.toRems(240)};
  transition: padding-left 0.3s ease-out;
`
