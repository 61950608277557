import { ColumnHeader } from 'types'

export const HEADERS: ColumnHeader[] = [
  { label: 'Company', id: 'company' },
  { label: 'Job Title', id: 'jobTitle' },
  { label: 'Free Agent', id: 'freeAgent' },
  { label: 'Submitted', id: 'submitted' },
  { label: 'Writer', id: 'writer' },
  { label: 'Type', id: 'type' },
  { label: 'Link', id: 'link' },
]
