import React from 'react'
import getSafeExternalLink from 'utils/helpers/getSafeExternalLink'
import Txt, { TxtProps } from 'components/Txt'

type Props = {
  url: string
  label?: string
  inheritStyles?: boolean
  children?: React.ReactNode
  txtProps?: TxtProps
}

const DEFAULT_TXT_PROPS = {
  color: 'link',
  underline: true,
}

// TODO: Rethink this component to not enforce sizing, etc
export default function ExternalLink({
  url,
  label,
  inheritStyles = false,
  txtProps = {},
  children,
}: Props): JSX.Element {
  const safeUrl = getSafeExternalLink(url)
  const allTxtProps = {
    ...DEFAULT_TXT_PROPS,
    ...txtProps,
    href: safeUrl,
    target: '_blank',
    rel: 'noreferrer',
  }
  return (
    <Txt as="a" inheritStyles={inheritStyles} {...allTxtProps}>
      {label ?? children}
    </Txt>
  )
}
