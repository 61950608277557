import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import { DropdownOption } from 'types'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import groupAssignItems, {
  GenericGroupedRowData,
} from 'utils/helpers/groupAssigItems'
import { sortByProp } from 'utils/sort'
import {
  ApplicationStatus,
  GetApplicationsForAssigningQuery,
  GetApplicationsForAssigningQueryVariables,
  GetAppliersQuery,
} from 'generated/graphql'

type ReturnType = {
  groupedRowData: GenericGroupedRowData[]
  assigneeOptions: DropdownOption[]
}

export default function useAssignApplicationsData(
  isReassigning: boolean
): ReturnType {
  // TODO: (matthewalbrecht) avoid making multiple network requests on status changes
  const status = isReassigning
    ? ApplicationStatus.PENDING_APPLICATION
    : ApplicationStatus.UNASSIGNED
  const { data: applicationData } = useQuery<
    GetApplicationsForAssigningQuery,
    GetApplicationsForAssigningQueryVariables
  >(GET_APPLICATIONS_FOR_ASSIGNING, {
    variables: {
      status,
    },
  })

  const { data: applierData } = useQuery<GetAppliersQuery>(GET_APPLIERS)

  const applications = applicationData?.applications?.edges.map((edge) => ({
    ...edge.node,
    sortableDue: edge.node.due ? dayjs(edge.node.due) : null,
    sortableCreatedAt: dayjs(edge.node.createdAt),
    clientName: edge.node.client.name,
    assigneeId: edge.node.assignee?.id,
  }))

  // this restructures the data to fit our nested table row structure
  // we show client or Applier and then all of their applications
  const groupedRowData = applications
    ? groupAssignItems(applications, isReassigning)
    : []

  // sort the requests by clientName-due
  groupedRowData.forEach((group) => {
    group.items.sort((a, b) => sortByProp(a, b, 'clientName', 'sortableDue'))
  })

  // sort groups by soonest due
  groupedRowData.sort((a, b) => sortByProp(a.items[0], b.items[0], 'sortableDue'))

  const assigneeOptions: DropdownOption[] = buildDropdownOptions(
    applierData?.users || []
  )
  return { groupedRowData, assigneeOptions }
}

/**
 * Use to query all applications
 */
const GET_APPLICATIONS_FOR_ASSIGNING = gql`
  query GetApplicationsForAssigning($status: ApplicationStatus!) {
    applications(status: $status, inAirtable: false) {
      edges {
        node {
          id
          createdAt
          client {
            id
            name
            roleCategory
            roleExperience
            tagsForTEs
          }
          due
          assignee {
            id
            name
          }
          assignedAt
          huntrUrl
          huntrHtmlDescription
          jobTitle
          employerName
          jobUrl
          isImportant
        }
      }
    }
  }
`

/**
 * used to get all appliers
 */
const GET_APPLIERS = gql`
  query GetAppliers {
    users(anyRole: { isApplier: true }) {
      id
      name
    }
  }
`
