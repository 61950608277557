import { ApolloCache, gql, useMutation } from '@apollo/client'
import React from 'react'
import { useHistory } from 'react-router'
import { Link } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components/macro'
import { KeyValue, ReactSetState, TemplateMap } from 'types'
import { useAuthContext } from 'context/auth'
import { CACHE_STRING } from 'utils/cacheString'
import { getNextRequestIdInQueue } from 'utils/helpers/getNextRequestIdInQueue'
import getQueueLabel from 'utils/helpers/getQueueLabel'
import { removeItemFromCacheConnections } from 'utils/helpers/removeItemFromCacheConnections'
import ROUTE_PATHS from 'utils/routePaths'
import RejectionButton from 'components/RejectionButton/RejectionButton'
import { getNextRouteUrl } from './WriterSubmission.helpers'
import { TaglineRequest } from './WriterSubmission.types'
import Button from 'components/Button'
import Flex from 'components/Flex'
import HList from 'components/HList'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  RejectTaglineRequestByWriterMutation,
  RejectTaglineRequestByWriterMutationVariables,
  SubmitTaglineWorkMutation,
  SubmitTaglineWorkMutationVariables,
  TaglineRequestRejectionReasonByWriter,
} from 'generated/graphql'

type Props = {
  editorChanges: KeyValue
  setEditorChanges: ReactSetState<KeyValue>
  timeSpentSec: number
  queue: string[]
  requestId: string
  writerId: string | undefined
  freeAgentId: string | undefined
  taglineRequest: TaglineRequest
  templateMap: TemplateMap | null
  setTemplateMap: ReactSetState<TemplateMap | null>
  areTemplatesFilled: boolean
  isTemplateFillingTest: boolean
}

const WriterSubmissionFooterBox = styled(Padding)`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

function WriterSubmissionFooter({
  editorChanges,
  setEditorChanges,
  taglineRequest,
  timeSpentSec,
  queue,
  requestId,
  writerId,
  freeAgentId,
  templateMap,
  setTemplateMap,
  areTemplatesFilled,
  isTemplateFillingTest,
}: Props): JSX.Element {
  const {
    userSession: {
      user: { id: assigneeId },
    },
  } = useAuthContext()
  const history = useHistory()
  const nextRequestId = getNextRequestIdInQueue(queue, requestId)
  const nextRouteUrl = getNextRouteUrl(nextRequestId, { writerId, freeAgentId })

  const [submitTaglineWork, { loading: loadingSubmitTaglineWork }] = useMutation<
    SubmitTaglineWorkMutation,
    SubmitTaglineWorkMutationVariables
  >(WRITER_SUBMIT_TAGLINE_ASSIGNMENT, {
    // TODO: (matthewalbrecht) replace this with a modify function
    refetchQueries: ['TalentAgentQueue'],
    update: updateCacheAfterSuccess,
  })

  const [submitTaglineRequestRejection, { loading: loadingTaglineRequestRejected }] =
    useMutation<
      RejectTaglineRequestByWriterMutation,
      RejectTaglineRequestByWriterMutationVariables
    >(WRITER_TAGLINE_REQUEST_REJECTION, {
      update: updateCacheAfterSuccess,
    })

  function updateCacheAfterSuccess(
    cache: ApolloCache<
      SubmitTaglineWorkMutation | RejectTaglineRequestByWriterMutation
    >
  ) {
    removeItemFromCacheConnections(cache, requestId, [
      CACHE_STRING.TAGLINE_WRITING_QUEUE(assigneeId),
    ])
  }

  /**
   *
   * @param data data returned from mutation
   */
  function handleCompletion(isRejected: boolean) {
    const taglineInfo = `${taglineRequest.jobTitle ?? ''} @ ${
      taglineRequest.employerName ?? ''
    } for ${taglineRequest.client.name}`
    const taglineAction = isRejected ? 'rejected' : 'submitted'

    toast.success(`Tagline Request: ${taglineInfo} was ${taglineAction}`)

    if (!nextRequestId) {
      toast('End of queue')
    }

    setEditorChanges({})
    setTemplateMap(null)

    history.replace(nextRouteUrl)
  }

  async function handleSubmit() {
    // Validate that there are equal amount of templates in the sequence and edited templates
    if (!validateSubmissionInput()) {
      toast.error('First template must be edited')
      return
    }

    // mapping over the original templates will preserve template ordering
    // when submitting the items
    const items = taglineRequest.templateSequence.templates.map((template) => ({
      templateId: template.id,
      copy: editorChanges[template.id] || template.copy,
    }))

    // changing data structure to array of {key: 'keyname', value: 'valuename'}
    const templateVariables = templateMap
      ? Object.entries(templateMap).reduce<{ key: string; value: string }[]>(
          (final, [key, value]) => {
            if (!key || !value) {
              return final
            }
            final.push({ key, value })
            return final
          },
          []
        )
      : undefined

    const variables: SubmitTaglineWorkMutationVariables = {
      SubmitTaglineWorkInput: {
        taglineRequestId: requestId,
        items,
        timeSpentSec,
      },
    }

    if (isTemplateFillingTest) {
      variables.SubmitTaglineWorkInput.templateVariables = areTemplatesFilled
        ? templateVariables
        : null
    }

    try {
      await submitTaglineWork({ variables })
      handleCompletion(false)
    } catch (error) {
      toast.error('There was an error submitting your work')
    }
  }

  /**
   * build variables and execute mutation for rejecting tagline by TA
   * @param rejectionReason reason TA has for rejecting the tagline request
   */
  async function handleRejectTaglineRequest(
    rejectionReason: TaglineRequestRejectionReasonByWriter
  ) {
    const variables = {
      RejectTaglineRequestByWriterInput: {
        taglineRequestId: taglineRequest.id,
        rejectionReason,
      },
    }

    try {
      await submitTaglineRequestRejection({ variables })
      handleCompletion(true)
    } catch (error) {
      toast.error('There was an error rejecting your work')
    }
  }

  // make sure we have submissions for all of the needed templates
  function validateSubmissionInput() {
    const firstTemplateId = taglineRequest.templateSequence.templates[0]?.id
    if (!firstTemplateId) {
      return false
    }

    return Boolean(editorChanges[firstTemplateId])
  }

  function handleSkipClick() {
    if (!nextRequestId) {
      toast('End of queue')
    }

    setEditorChanges({})

    history.push(nextRouteUrl)
  }

  const queueLabel = getQueueLabel(queue, requestId)

  const rejectionOptions = [
    {
      rejectionReason: TaglineRequestRejectionReasonByWriter.JOB_POSTING_CLOSED,
      callback: handleRejectTaglineRequest,
      label: 'Job Posting Closed',
    },
  ]

  return (
    <WriterSubmissionFooterBox vertical={2} horizontal={5}>
      <Flex justify="space-between" align="center">
        <HList as={Flex} align="center" size={2}>
          <Button as={Link} to={ROUTE_PATHS.HOME} $type="secondary">
            Cancel
          </Button>
        </HList>
        <HList as={Flex} size={2} align="center">
          <Txt>{queueLabel}</Txt>
          <Button onClick={handleSkipClick} $type="secondary">
            Skip
          </Button>
          <RejectionButton<TaglineRequestRejectionReasonByWriter>
            rejectionOptions={rejectionOptions}
          />
          {/* TODO: (mattthewalbrecht) disabled submit button if no changes have been made yet */}
          <Button
            $type="accept"
            onClick={handleSubmit}
            disabled={loadingSubmitTaglineWork || loadingTaglineRequestRejected}
          >
            Submit
          </Button>
        </HList>
      </Flex>
    </WriterSubmissionFooterBox>
  )
}

WriterSubmissionFooter.fragments = {
  taglineRequestInfo: gql`
    fragment WriterSubmissionFooterInfo on TaglineRequest {
      id
      jobTitle
      employerName
      due
      client {
        id
        name
      }
      templateSequence {
        id
        templates {
          id
          copy
        }
      }
    }
  `,
}

export default WriterSubmissionFooter

/**
 * Used by writers to submit their tagline assignment work
 */
const WRITER_SUBMIT_TAGLINE_ASSIGNMENT = gql`
  mutation SubmitTaglineWork($SubmitTaglineWorkInput: SubmitTaglineWorkInput!) {
    submitTaglineWork(input: $SubmitTaglineWorkInput) {
      taglineSubmission {
        id
        # Enable Apollo to update tagline request status in cache
        taglineRequest {
          id
          status
        }
        items {
          id
          copy
        }
      }
    }
  }
`

/**
 * Used by TP to submit their rejections of Tagline Requests
 */
const WRITER_TAGLINE_REQUEST_REJECTION = gql`
  mutation RejectTaglineRequestByWriter(
    $RejectTaglineRequestByWriterInput: RejectTaglineRequestByWriterInput!
  ) {
    rejectTaglineRequestByWriter(input: $RejectTaglineRequestByWriterInput) {
      taglineRequest {
        id
        status
      }
    }
  }
`
