import React, { useState } from 'react'
import AddCalendar from './AddCalender'
import { User } from './Users.types'
import Button from 'components/Button'

type Props = {
  users: User[]
}

export default function UserAddCalendarButton({ users }: Props): React.ReactElement {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  function handleClose() {
    setIsOpen(false)
  }

  return (
    <>
      <Button $type="primary" small onClick={() => setIsOpen(true)}>
        Create Calander
      </Button>
      {isOpen && (
        <AddCalendar handleClose={handleClose} isOpen={isOpen} users={users} />
      )}
    </>
  )
}
