import React from 'react'
import Button from 'components/Button'

export default function FinalReviewTableNav(): JSX.Element {
  return (
    <Button $type="link" active>
      My Final Reviews
    </Button>
  )
}
