import { gql, useQuery } from '@apollo/client'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import { KeyValue } from 'types'
import { DATE_FORMAT } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import ROUTE_PATHS from 'utils/routePaths'
import { WRITERS_PAGE_DAYS_AGO } from 'utils/settings'
import { sortByProp } from 'utils/sort'
import {
  HeaderData,
  HeaderRow,
  Table,
  TableBox,
  TableWrapper,
} from 'components/FullWidthTable/FullWidthTableStyles'
import {
  CLIENT_COLUMNS,
  FILTER_TYPES,
  GROUP_BY_OPTIONS,
  GROUP_BY_TYPES,
  TALENT_AGENT_COLUMNS,
  WRITER_COLUMNS,
} from './ReviewedTaglines.constants'
import {
  buildExtendedSubmissions,
  buildTableData,
  filterRequests,
  groupRequests,
} from './ReviewedTaglines.helpers'
import { Filter, TableRowData, TaglineSubmission } from './ReviewedTaglines.types'
import ReviewedTaglinesRow from './ReviewedTaglinesRow'
import Container from 'components/Container'
import HList from 'components/HList'
import { DatePicker, Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import { GetReviewedTaglinesClientsAndWritersQuery } from 'generated/graphql'

export default function ReviewedTaglines(): JSX.Element {
  /**
   *  sets the new filter object
   * @param value value of filter changed
   * @param filterType type of filter being updated
   */
  function handleFilterValueChange(value: string, filterType: keyof Filter) {
    setFilter({ ...filter, [filterType]: value })
  }

  /**
   *  sets the new fromDate filter
   * @param value value of DatePicker change
   */
  function handlefromDateFilterValueChange(value: string) {
    if (!value) {
      return
    }
    const newValue = dayjs(value)
    const newFilter = { ...filter, fromDate: newValue }

    setFilter(newFilter)
  }
  const earliestDate = dayjs().subtract(WRITERS_PAGE_DAYS_AGO, 'days')
  const [filter, setFilter] = useState<Filter>({
    groupBy: 'writerId',
    fromDate: earliestDate,
  })

  const { data, loading, error } =
    useQuery<GetReviewedTaglinesClientsAndWritersQuery>(GET_TAGLINES_FA_WRITERS, {
      variables: {
        createdAtStart: filter.fromDate.format(),
      },
      errorPolicy: 'all',
    })

  // build options for dropdowns
  const freeAgentsOptions = buildDropdownOptions(data?.clients || [])
  const writerOptions = buildDropdownOptions(data?.writers || [])
  const talentAgentOptions = buildDropdownOptions(data?.talentAgents || [])

  // build our tagline requests so that they are easy to sort/group/filter
  const taglineSubmissions = useMemo(
    () =>
      (data?.taglineSubmissions &&
        buildExtendedSubmissions(data.taglineSubmissions)) ||
      [],
    [data]
  )

  // filter the tagline requests
  const filteredSubmissions = useMemo(
    () => taglineSubmissions.filter((request) => filterRequests(request, filter)),
    [filter, taglineSubmissions]
  )

  // group the tagline requests by filter.groupBy prop
  const groupedRequests: KeyValue<TaglineSubmission[]> = useMemo(() => {
    return filteredSubmissions.reduce(
      (groups, request) => groupRequests(groups, request, filter.groupBy),
      {}
    )
  }, [filteredSubmissions, filter.groupBy])

  /**
   * create all grouping variables need to render the view properly
   * primarySort - property to sort by
   * secondarySort - property to sort by
   * tableColumns - columns to show on table
   */
  const { primarySort, secondarySort, tableColumns } = useMemo(() => {
    switch (filter.groupBy) {
      case GROUP_BY_TYPES.WRITER_ID:
        return {
          primarySort: 'writerName',
          secondarySort: undefined,
          tableColumns: WRITER_COLUMNS,
        }
      case GROUP_BY_TYPES.TALENT_AGENT_ID:
        return {
          primarySort: 'talentAgentName',
          secondarySort: 'writerName',
          tableColumns: TALENT_AGENT_COLUMNS,
        }
      default:
        return {
          primarySort: 'clientName',
          secondarySort: '-total',
          tableColumns: CLIENT_COLUMNS,
        }
    }
  }, [filter.groupBy])

  // build our data for the rows
  const tableData = useMemo(
    () =>
      Object.values(groupedRequests)
        .reduce(buildTableData, [])
        .sort((a, b) => {
          return sortByProp(a, b, primarySort, secondarySort)
        }),
    [groupedRequests, primarySort, secondarySort]
  )

  if (loading) {
    return <Message vertical message="Loading reviewed work..." />
  }

  if (error) {
    return <RefreshMessage message="There was an error loading reviewed work" />
  }

  return (
    <ViewBox>
      <Padding top={6}>
        <Container noMax>
          <Txt as="h2" size={24} bold>
            Reviewed Taglines
          </Txt>
          <Padding top={4}>
            <HList size={2}>
              <Dropdown
                label="Group By"
                defaultValue={filter.groupBy}
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.GROUP_BY)
                }
                options={GROUP_BY_OPTIONS}
                withEmptyOption={false}
              />
              <Dropdown
                label="Writer"
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.WRITER_ID)
                }
                options={writerOptions}
              />
              <Dropdown
                label="Talent Agent"
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.TALENT_AGENT_ID)
                }
                options={talentAgentOptions}
              />
              <Dropdown
                label="Free Agent"
                onValueChange={(value) =>
                  handleFilterValueChange(value, FILTER_TYPES.CLIENT_ID)
                }
                options={freeAgentsOptions}
              />
              <DatePicker
                label="Date Since"
                onValueChange={handlefromDateFilterValueChange}
                name="fromDate"
                min={earliestDate.format(DATE_FORMAT.ONLY_DATE)}
                value={filter.fromDate.format(DATE_FORMAT.ONLY_DATE)}
              />
            </HList>
          </Padding>
        </Container>
        <Padding top={6}>
          <TableBox>
            <TableWrapper>
              <Table>
                <thead>
                  <HeaderRow>
                    {tableColumns.map((column) => (
                      <HeaderData key={column.id}>
                        <Txt size={12} bold>
                          {column.name}
                        </Txt>
                      </HeaderData>
                    ))}
                  </HeaderRow>
                </thead>
                <tbody>
                  {tableData.map((rowData: TableRowData) => {
                    return (
                      <ReviewedTaglinesRow
                        key={`${rowData.writerId}:${rowData.clientId}`}
                        rowData={rowData}
                        groupBy={filter.groupBy}
                      />
                    )
                  })}
                </tbody>
              </Table>
            </TableWrapper>
          </TableBox>
          {!tableData.length && <Message message="No work to show" vertical />}
        </Padding>
      </Padding>
    </ViewBox>
  )
}

ReviewedTaglines.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REVIEWED_TAGLINES}
    key={ROUTE_PATHS.REVIEWED_TAGLINES}
  >
    <ReviewedTaglines />
  </PrivateRoute>,
]

// TODO: Move stat calculations to server
const GET_TAGLINES_FA_WRITERS = gql`
  query GetReviewedTaglinesClientsAndWriters($createdAtStart: Datetime!) {
    taglineSubmissions: taglineSubmissionsConn(
      hasTalentPartnerReviews: true
      createdAtRangeFilter: { start: $createdAtStart }
    ) {
      edges {
        node {
          id
          timeSpentSec
          createdAt
          createdBy {
            id
            name
          }
          talentAgentReviews {
            id
            rating
          }
          talentPartnerReviews {
            id
            ratingWriter
            taglineRejectionReason
          }
          taglineRequest {
            id
            client {
              id
              name
              talentAgent {
                id
                name
              }
            }
          }
        }
      }
    }
    clients: allClients {
      id
      name
    }
    writers: users(anyRole: { isWriter: true }) {
      id
      name
    }
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
    }
  }
`
