import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { generatePath } from 'react-router'
import { useAuthContext } from 'context/auth'
import {
  INITIAL,
  TAGLINE_DUE_HOUR,
  TAGLINE_DUE_MINUTE,
  TAGLINE_DUE_TZ,
} from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import DueDateTableItem from 'components/DueDateTableItem/DueDateTableItem'
import TableBody from 'components/Table/TableBody'
import TableBodyAction from 'components/Table/TableBodyAction'
import TableBodyActionList from 'components/Table/TableBodyActionList'
import TableBodyItem from 'components/Table/TableBodyItem'
import TableEmpty from 'components/Table/TableEmpty'
import TableHeaderItem from 'components/Table/TableHeaderItem'
import TableHeaderRow from 'components/Table/TableHeaderRow'
import { COL_HEADERS } from 'components/WriterTable/WriterTable.constants'
import { buildTableData } from 'components/WriterTable/WriterTable.helpers'
import { GetWriterFinalReviewsQuery } from 'generated/graphql'

export default function FinalReviewTable(): JSX.Element {
  const { userSession } = useAuthContext()
  const { data } = useQuery<GetWriterFinalReviewsQuery>(GET_WRITER_FINAL_REVIEWS, {
    variables: { finalReviewerId: userSession.user.id },
  })

  // normalizing rawTaglineRequests will group them by free agent
  const rawTaglineRequests = data?.taglineRequests && getNodes(data.taglineRequests)
  const clientsWithTaglineRequests =
    rawTaglineRequests && buildTableData(rawTaglineRequests)

  return (
    <table>
      <thead>
        <TableHeaderRow>
          {COL_HEADERS.map((header) => (
            <TableHeaderItem key={header} content={header} />
          ))}
          <th />
        </TableHeaderRow>
      </thead>
      <TableBody>
        {clientsWithTaglineRequests?.map((client) => (
          <tr key={client.name}>
            <TableBodyItem first content={client.name} />
            <TableBodyItem content={client.taglineRequests.length} />
            <TableBodyItem>
              <DueDateTableItem
                items={client.taglineRequests}
                dueHour={TAGLINE_DUE_HOUR}
                dueMinute={TAGLINE_DUE_MINUTE}
                dueTz={TAGLINE_DUE_TZ}
                fromNow
              />
            </TableBodyItem>
            <TableBodyActionList>
              {client.taglineRequests.length && (
                <TableBodyAction
                  to={generatePath(ROUTE_PATHS.PARTNER_QUEUE_BY_FREE_AGENT, {
                    freeAgentId: client.id,
                    requestId: INITIAL,
                  })}
                  content="Queue"
                />
              )}
            </TableBodyActionList>
          </tr>
        ))}
      </TableBody>
      {clientsWithTaglineRequests && !clientsWithTaglineRequests.length && (
        <TableEmpty message="No Assignments Found" />
      )}
    </table>
  )
}

const GET_WRITER_FINAL_REVIEWS = gql`
  query GetWriterFinalReviews($finalReviewerId: ID) {
    taglineRequests(finalReviewerId: $finalReviewerId, status: PENDING_TP_REVIEW) {
      edges {
        node {
          id
          due
          client {
            id
            name
          }
        }
      }
    }
  }
`
