import { gql } from '@apollo/client'
import { ArrayElement, Maybe } from 'types'
import {
  ApplicationRejectionReasonMap,
  LocalApplicationStatusMap,
  MissingApplicationInfoDeclineReasonMap,
} from 'utils/constants'
import { ApplicationRejectionReason, LocalApplicationStatus } from 'utils/enums'
import { ApplicationStatusCopyFragment } from 'generated/graphql'

function getLocalApplicationStatus(
  application: ApplicationStatusCopyFragment
): LocalApplicationStatus {
  if (application.airtableId) {
    return LocalApplicationStatus.IN_AIRTABLE
  }

  if (application.missingApplicationInfos[0]) {
    return LocalApplicationStatus.YELLOW_CARDED
  }

  return LocalApplicationStatus[application.status]
}

export function getApplicationStatusCopy(
  application: ApplicationStatusCopyFragment
): string {
  const localStatus = getLocalApplicationStatus(application)
  const status = LocalApplicationStatusMap[localStatus]
  let additionalInfo = ''

  const missingApplicationInfo = application.missingApplicationInfos[0]
  if (
    localStatus === LocalApplicationStatus.YELLOW_CARDED &&
    missingApplicationInfo
  ) {
    additionalInfo = `(${getApplicationYellowCardDetails(missingApplicationInfo)})`
  }

  const rejectionReason = application.applicationSubmissions[0]
    ?.rejectionReason as Maybe<ApplicationRejectionReason>
  if (localStatus === LocalApplicationStatus.REJECTED && rejectionReason) {
    additionalInfo = `(${ApplicationRejectionReasonMap[rejectionReason]})`
  }
  return `${status} ${additionalInfo}`
}

function getApplicationYellowCardDetails(
  missingApplicationInfo: ArrayElement<
    ApplicationStatusCopyFragment['missingApplicationInfos']
  >
) {
  if (missingApplicationInfo.answeredAt) {
    if (missingApplicationInfo.declineReason) {
      return `Declined - ${
        MissingApplicationInfoDeclineReasonMap[missingApplicationInfo.declineReason]
      }`
    }
    return !missingApplicationInfo.answers ? 'Declined to answer' : 'Answered'
  }
  return missingApplicationInfo.askClient ? 'Sent to Client' : 'Sent to Staff'
}

getApplicationStatusCopy.fragments = {
  applicationStatusCopyInfo: gql`
    fragment ApplicationStatusCopy on Application {
      id
      airtableId
      status
      applicationSubmissions {
        rejectionReason
      }
      missingApplicationInfos {
        id
        askClient
        answeredAt
        # TODO (@MatthewAlbrecht) stop fetching all answers after decline reason backfill
        answers
        declineReason
      }
    }
  `,
}
