import React from 'react'
import { ColumnHeader } from 'types'
import Icon from 'components/Icon/Icon'
import { Tooltip } from 'components/WrappedTooltip/WrappedTooltip'
import { ColumnSortState } from './FullWidthTable.types'
import {
  TableBox,
  TableWrapper,
  Table,
  HeaderRow,
  HeaderData,
} from './FullWidthTableStyles'
import HeaderLabelSortable from './HeaderLabelSortable'
import Message from 'components/Message'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  headers: ColumnHeader[]
  children: React.ReactNode
  isEmpty: boolean
  emptyMessage: string
  isLoading: boolean
  loadingMessage: string
  isError: boolean
  errorMessage: string
  columnSortState?: ColumnSortState
  onColumnSortChange?: (id: string, value: ColumnSortState) => void
}

export default function FullWidthTable({
  headers,
  children,
  isEmpty,
  emptyMessage,
  isLoading,
  loadingMessage,
  isError,
  errorMessage,
  columnSortState,
  onColumnSortChange,
}: Props): React.ReactElement {
  function renderMessage() {
    if (isEmpty && emptyMessage) {
      return <Message message={emptyMessage} vertical />
    }
    if (isLoading && loadingMessage) {
      return <Message message={loadingMessage} vertical />
    }
    if (isError && errorMessage) {
      return <Message message={errorMessage} vertical />
    }
  }

  return (
    <TableBox>
      <TableWrapper>
        <Table>
          <thead>
            <HeaderRow>
              {headers.map((header) => (
                <HeaderData key={header.id} collapse={header.collapse}>
                  <HeaderInfo
                    header={header}
                    columnSortState={columnSortState}
                    onColumnSortChange={onColumnSortChange}
                  />
                </HeaderData>
              ))}
            </HeaderRow>
          </thead>
          <tbody>{children}</tbody>
        </Table>
      </TableWrapper>
      {renderMessage()}
    </TableBox>
  )
}

type HeaderInfoProps = {
  header: ColumnHeader
  columnSortState?: ColumnSortState
  onColumnSortChange?: (id: string, value: ColumnSortState) => void
}

function HeaderInfo({
  header,
  columnSortState,
  onColumnSortChange,
}: HeaderInfoProps) {
  if (header.tooltip) {
    return <HeaderLabelWithTooltip label={header.label} tooltip={header.tooltip} />
  }
  if (header.sortable && columnSortState && onColumnSortChange) {
    return (
      <HeaderLabelSortable
        id={header.id}
        label={header.label}
        columnSortState={columnSortState}
        onColumnSortChange={onColumnSortChange}
      />
    )
  }
  return (
    <Txt size={12} bold>
      {header.label}
    </Txt>
  )
}

type HeaderLabelWithTooltipProps = {
  label: string
  tooltip: string
}

function HeaderLabelWithTooltip({ label, tooltip }: HeaderLabelWithTooltipProps) {
  return (
    <div>
      <Tooltip label={tooltip}>
        <Txt size={12} bold as="span">
          {label}
          <Padding inline left={0.75}>
            <Icon
              name="Info"
              width={12}
              height={12}
              primaryFill="text"
              nudge={{ down: 0.25 }}
            />
          </Padding>
        </Txt>
      </Tooltip>
    </div>
  )
}
