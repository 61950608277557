import React, { ReactElement } from 'react'
import styled, { css } from 'styled-components/macro'
import { Colors } from 'types'
import { bp, breakpoints } from 'theme/breakpoint'
import Flex from './Flex'
import Padding from './Padding'
import Txt, { TxtProps } from './Txt'

export const GreyBackground = styled.div`
  background-color: ${({ theme }) => theme.color.faGrey1};
`

export const FooterBar = styled.footer`
  background-color: ${({ theme }) => theme.color.faGrey1};
  padding: ${({ theme }) => theme.rhythm(2)} ${({ theme }) => theme.rhythm(5)};
`

export const showScrollbar = css`
  &::-webkit-scrollbar {
    appearance: none;
    width: ${({ theme }) => theme.toRems(5)};
  }
  &::-webkit-scrollbar-thumb {
    border-radius: ${({ theme }) => theme.rhythm(0.5)};
    background-color: ${({ theme }) => theme.color.lightGray};
    box-shadow: 0 0 ${({ theme }) => theme.toRems(1)} rgba(255, 255, 255, 0.5);
  }
`

export const hideScrollbar = css`
  /* Hide scrollbar for Chrome, Safari and Opera */
  &::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll;
`

export const CenteredBox = styled.div`
  width: 100%;
  padding: 0 ${({ theme }) => theme.rhythm(2)};
  ${bp(breakpoints.md)} {
    margin: 0 auto;
    width: 75%;
  }
  ${bp(breakpoints.lg)} {
    width: 50%;
  }
`

export const FullPageForm = styled.form`
  margin: 0 auto;
  width: 50%;
  padding-bottom: ${({ theme }) => theme.rhythm(4)};
`

type TagProps = {
  color: keyof Colors
  colorHover?: keyof Colors
  small?: boolean
}

export const Tag = styled.span<TagProps>`
  display: inline-block;
  padding: ${({ theme }) => theme.rhythm(1)} ${({ theme }) => theme.rhythm(3)};
  background: ${({ theme, color }) => theme.color[color]};
  border-radius: ${({ theme }) => theme.borderRadius};

  ${({ colorHover }) =>
    colorHover &&
    css`
      &:hover {
        background: ${({ theme }) => theme.color[colorHover]};
      }
    `}

  ${({ small }) =>
    small &&
    css`
      padding: ${({ theme }) => theme.rhythm(0.75)}
        ${({ theme }) => theme.rhythm(1.5)};
    `}
`

type LineProps = {
  height?: number
  color?: keyof Colors
}

export const Line = styled.div<LineProps>`
  width: 100%;
  height: ${({ theme, height = 1 }) => theme.toRems(height)};
  background-color: ${({ color = 'faGrey2', theme }) => theme.color[color]};
`

type BackgroundColorProps = {
  color: keyof Colors
  rounded?: boolean
}

export const BackgroundColor = styled.div<BackgroundColorProps>`
  background-color: ${({ color, theme }) => theme.color[color]};
  ${({ theme, rounded }) =>
    rounded &&
    css`
      border-radius: ${theme.borderRadius};
    `}
`

type SectionHeaderProps = {
  headerCopy: string
  lineProps?: LineProps
  txtProps?: TxtProps
  icon?: ReactElement
}

export function SectionHeader({
  headerCopy,
  lineProps = {},
  txtProps = {},
  icon,
}: SectionHeaderProps): React.ReactElement {
  const newTxtProps = { size: 18, ...txtProps }
  const newLineProps = { ...lineProps }

  return (
    <>
      {icon ? (
        <Flex align="center" justify="flex-start">
          <Txt {...newTxtProps}>{headerCopy}</Txt>
          <Padding left={2}>{icon}</Padding>
        </Flex>
      ) : (
        <Txt {...newTxtProps}>{headerCopy}</Txt>
      )}
      <Padding top={1} bottom={3}>
        <Line {...newLineProps} />
      </Padding>
    </>
  )
}

type WidthContainerProps = {
  width: number
}

export const WidthContainer = styled.div<WidthContainerProps>`
  width: ${({ width, theme }) => theme.toRems(width)};
`
