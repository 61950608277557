import dayjs from 'dayjs'
import React from 'react'
import { generatePath } from 'react-router'
import { Link } from 'react-router-dom'
import { DATE_FORMAT, INTERVIEW_PREP_TYPE_MAP } from 'utils/constants'
import getJobInfoLabel from 'utils/helpers/getJobInfoLabel'
import ROUTE_PATHS from 'utils/routePaths'
import { BodyData, BodyRow } from 'components/FullWidthTable/FullWidthTableStyles'
import { getInterviewPrepStatusTagInfo } from './ClientInterviewPreps.helpers'
import {
  InterviewCycle,
  InterviewPrepRequestSortable,
} from './ClientInterviewPreps.types'
import { Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'

type Props = {
  interviewPrepRequest: InterviewPrepRequestSortable
  clientId: string
}

export default function ClientInterviewPrepRequestsRow({
  interviewPrepRequest,
  clientId,
}: Props): React.ReactElement {
  const { statusColor, statusCopy } =
    getInterviewPrepStatusTagInfo(interviewPrepRequest)

  return (
    <BodyRow>
      <BodyData>
        <Txt as="span" size={14}>
          {dayjs(interviewPrepRequest.createdAt).format(DATE_FORMAT.ONLY_DATE)}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {interviewPrepRequest.due
            ? dayjs(interviewPrepRequest.due).format(DATE_FORMAT.ONLY_DATE)
            : 'Not Available'}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt as="span" size={14}>
          {INTERVIEW_PREP_TYPE_MAP[interviewPrepRequest.interviewPrepType]}
        </Txt>
      </BodyData>
      <BodyData>
        <Tag color={statusColor} small>
          <Txt size={13} alignment="center">
            {statusCopy}
          </Txt>
        </Tag>
      </BodyData>
      <BodyData>
        {interviewPrepRequest.interviewCycle ? (
          <InterviewCycleLink
            interviewCycle={interviewPrepRequest.interviewCycle}
            clientId={clientId}
          />
        ) : (
          <Txt size={14}>Not Available</Txt>
        )}
      </BodyData>
    </BodyRow>
  )
}

type InterviewCycleLinkProps = {
  interviewCycle: NonNullable<InterviewCycle>
  clientId: string
}

function InterviewCycleLink({
  interviewCycle,
  clientId,
}: InterviewCycleLinkProps): React.ReactElement {
  return (
    <Link
      to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_INTERVIEW_CYCLE_DETAIL, {
        clientId,
        interviewCycleId: interviewCycle.id,
      })}
    >
      <Txt underline color="text" hoverColor="black" size={14}>
        {getJobInfoLabel(interviewCycle)}
      </Txt>
    </Link>
  )
}
