import React, { useMemo } from 'react'
import { RectangleSkeleton } from 'components/Skeletons/Skeletons'
import Txt, { Bold, TxtProps } from 'components/Txt'

type Props = {
  label: string
  value: string | number | undefined | null
  maxWidth?: number
  txtProps?: TxtProps
  showContent?: boolean
}

function getRandomWidth(min: number, max: number): number {
  return Math.random() * (max - min) + min
}

const defaultTxtProps: TxtProps = {
  size: 14,
  lineHeight: 1.3,
}

/**
 *
 * @param value
 * @param label
 * @param maxWidth defaults to 300
 * @param txtProps defaults to {}
 * @param showContent boolean, show the content if true; show the loader if false
 * @returns RectangleSkeleton if value === undefined else, label w/ prop and value
 */
export default function InlineValueProp({
  value,
  label,
  maxWidth = 300,
  txtProps = {},
  showContent,
}: Props): React.ReactElement {
  const skeletonWidth = useMemo(
    () => getRandomWidth(maxWidth / 2, maxWidth),
    [maxWidth]
  )

  // if showContent is given, use that for show/hiding loader else use value
  const showLoader = showContent !== undefined ? !showContent : value === undefined

  if (showLoader) {
    return (
      <div>
        <RectangleSkeleton height={16} width={skeletonWidth} />
      </div>
    )
  }

  const newTxtProps = {
    ...defaultTxtProps,
    ...txtProps,
  }

  return (
    <Txt {...newTxtProps}>
      {label}:&nbsp;<Bold>{value ?? 'Unknown'}</Bold>
    </Txt>
  )
}
