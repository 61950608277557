import { gql } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { generatePath, Link } from 'react-router-dom'
import { DATE_FORMAT, ROLE_GROUPS } from 'utils/constants'
import checkRole from 'utils/helpers/checkRole'
import ROUTE_PATHS from 'utils/routePaths'
import Editor from 'components/Editor/Editor'
import ImportantIndicator from 'components/ImportantIndicator/ImportantIndicator'
import PDFViewer from 'components/PDFViewer/PDFViewer'
import WorkInfoLinks from 'components/WorkInfoLinks/WorkInfoLinks'
import { TaglineRequest } from './FinalReview.types'
import { TextArea } from 'components/Inputs'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import VList from 'components/VList'

type Props = {
  taglineRequest: TaglineRequest
}

function FinalReviewLeft({ taglineRequest }: Props): JSX.Element {
  const dueDateLabel = `Writer Due Date: ${dayjs(taglineRequest.due).format(
    DATE_FORMAT.ONLY_DATE
  )}`
  const taReviewDateLabel = taglineRequest.talentAgentReviews[0]
    ? `Initial Review Date: ${dayjs(
        taglineRequest.talentAgentReviews[0]?.createdAt
      ).format(DATE_FORMAT.ONLY_DATE)}`
    : `Initial Submission Date: ${dayjs(
        taglineRequest.writerSubmissions[0]?.createdAt
      ).format(DATE_FORMAT.ONLY_DATE)}`
  const showClientLink = checkRole(ROLE_GROUPS.CORE_TEAM)

  return (
    <>
      <Padding bottom={4}>
        {taglineRequest.isImportant && (
          <Padding bottom={2.5}>
            <ImportantIndicator />
          </Padding>
        )}
        <Padding bottom={2}>
          {showClientLink ? (
            <Link
              to={generatePath(ROUTE_PATHS.CLIENT_CONFIG_DEFAULT, {
                clientId: taglineRequest.client.id,
              })}
            >
              <Txt as="h2" size={24} bold underline color="text" hoverColor="black">
                {taglineRequest.client.name}
              </Txt>
            </Link>
          ) : (
            <Txt as="h2" size={24} bold>
              {taglineRequest.client.name}
            </Txt>
          )}
        </Padding>
        <VList size={1}>
          <Txt size={14}>{dueDateLabel}</Txt>
          <Txt size={14}>{taReviewDateLabel}</Txt>
        </VList>
      </Padding>
      <Padding bottom={4}>
        <TextArea
          defaultValue={taglineRequest.notes}
          label="Assignment notes"
          disabled
          rows={4}
        />
      </Padding>
      {taglineRequest.client.resumeUrl && (
        <Padding bottom={4}>
          <PDFViewer url={taglineRequest.client.resumeUrl} />
        </Padding>
      )}
      <VList size={4}>
        <WorkInfoLinks
          resumeUrl={taglineRequest.client.resumeUrl}
          linkedinUrl={taglineRequest.client.linkedinUrl}
        />

        {taglineRequest.huntrHtmlDescription && (
          <Editor
            defaultValue={taglineRequest.huntrHtmlDescription}
            label="Notes from Huntr"
            height={80}
            disabled
          />
        )}
        {taglineRequest.client.bio && (
          <TextArea
            defaultValue={taglineRequest.client.bio}
            label={`${taglineRequest.client.name} Bio`}
            height={80}
            disabled
          />
        )}
      </VList>
    </>
  )
}

FinalReviewLeft.fragments = {
  taglineRequestInfo: gql`
    fragment PartnerReviewLeftInfo on TaglineRequest {
      id
      huntrHtmlDescription
      notes
      due
      isImportant
      client {
        id
        name
        linkedinUrl
        resumeUrl
        bio
      }
      talentAgentReviews {
        id
        rating
        feedback
        createdAt
      }
      writerSubmissions {
        createdAt
      }
    }
  `,
}

export default FinalReviewLeft
