import { gql } from '@apollo/client'
import React from 'react'
import { ReactSetState } from 'types'
import JobHeading from 'components/JobHeading/JobHeading'
import EnrichmentRequestForm from './EnrichmentRequestForm'
import { EnrichmentRequest, State } from './EnrichmentSubmission.types'
import ExternalLink from 'components/ExternalLink'
import Padding from 'components/Padding'
import Txt from 'components/Txt'

type Props = {
  enrichmentRequest: EnrichmentRequest
  state: State
  setState: ReactSetState<State>
}

function EnrichmentSubmissionRight({
  enrichmentRequest,
  state,
  setState,
}: Props): React.ReactElement {
  return (
    <>
      <Padding bottom={8}>
        <Txt bold size={20} lineHeight={1.3}>
          <JobHeading
            jobTitle={enrichmentRequest.jobTitle}
            huntrUrl={enrichmentRequest.huntrUrl}
            company={
              enrichmentRequest.company ?? { name: enrichmentRequest.employerName }
            }
          />
        </Txt>
        <Padding top={1}>
          {enrichmentRequest.jobUrl && (
            <ExternalLink url={enrichmentRequest.jobUrl} label="Job Description" />
          )}
        </Padding>
      </Padding>
      <EnrichmentRequestForm state={state} setState={setState} />
    </>
  )
}

EnrichmentSubmissionRight.fragments = {
  enrichmentRequestInfo: gql`
    fragment EnrichmentSubmissionRightInfo on EnrichmentRequest {
      jobUrl
      ...JobHeadingInfoEnrichment
    }
    ${JobHeading.fragments.jobHeadingInfoEnrichment}
  `,
}

export default EnrichmentSubmissionRight
