import { gql, useQuery } from '@apollo/client'
import React from 'react'
import { DATE_FORMAT } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import DashboardCardTitle from 'components/DashboardCard/DashboardCardTitle'
import {
  getClientsInterviewsToday,
  getClientsInterviewNextWeek,
} from './DashboardCard.helpers'
import { DashboardCard, DashboardContent } from './DashboardCard.styles'
import DashboardInterviewCard from './DashboardInterviewCard'
import LoadingIndicator from 'components/LoadingIndicator'
import Padding from 'components/Padding'
import Txt from 'components/Txt'
import {
  GetClientsInterviewsQuery,
  GetClientsInterviewsQueryVariables,
} from 'generated/graphql'

type Props = {
  talentAgentId: string
}

export default function DashboardCardInterviewsToday({
  talentAgentId,
}: Props): React.ReactElement {
  const { data, loading } = useQuery<
    GetClientsInterviewsQuery,
    GetClientsInterviewsQueryVariables
  >(GET_CLIENT_INTERVIEWS, {
    variables: { talentAgentId },
  })
  const clients = data?.clients && getNodes(data.clients)
  const interviewsToday =
    clients && clients.length ? getClientsInterviewsToday(clients) : []
  return (
    <DashboardCard>
      <DashboardCardTitle title="Interviews today" />
      <DashboardContent>
        {loading && <LoadingIndicator />}
        {!interviewsToday.length && !loading && (
          <Padding vertical={2.5} horizontal={3}>
            <Txt>There are no interviews today.</Txt>
          </Padding>
        )}
        {interviewsToday.map((interviewTodayInfo) => (
          <DashboardInterviewCard
            key={interviewTodayInfo.id}
            interviewInfo={interviewTodayInfo}
            formatString={DATE_FORMAT.HOUR_AND_MINUTE}
          />
        ))}
      </DashboardContent>
      <Padding vertical={0.5} />
    </DashboardCard>
  )
}

const GET_CLIENT_INTERVIEWS = gql`
  query GetClientsInterviews($talentAgentId: ID!) {
    clients(talentAgentId: $talentAgentId) {
      edges {
        node {
          ...ClientsInterviews
        }
      }
    }
  }
  ${getClientsInterviewNextWeek.fragments.clientsInterviews}
`
