import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { toast } from 'react-toastify'
import { Typename } from 'utils/enums'
import Icon from 'components/Icon/Icon'

type Props = {
  itemId: string
}

export default function AssignApplicationsDeleteButton({
  itemId,
}: Props): JSX.Element {
  /**
   * execute the mutation for voiding the application
   */
  function handleClick() {
    const message = 'Are you sure you want to delete this application?'

    if (window.confirm(message)) {
      void voidApplication({
        variables: {
          VoidApplicationInput: {
            applicationId: itemId,
          },
        },
      })
    }
  }

  const [voidApplication, { loading }] = useMutation(VOID_APPLICATION, {
    update(cache) {
      // Remove the selected application from the cache
      cache.evict({
        id: cache.identify({
          id: itemId,
          __typename: Typename.Application,
        }),
      })
    },
    onCompleted() {
      toast.success(`Application voided`)
    },
    onError() {
      toast.error('There was an error deleting the application')
    },
  })

  return (
    <button onClick={handleClick} disabled={loading}>
      <Icon name="Trash" width={16} height={16} primaryFill="faGrey3" />
    </button>
  )
}

/**
 * Used by coordinator to void (soft delete) an application
 */
const VOID_APPLICATION = gql`
  mutation VoidApplication($VoidApplicationInput: VoidApplicationInput!) {
    voidApplication(input: $VoidApplicationInput) {
      applicationSubmission {
        id
      }
    }
  }
`
