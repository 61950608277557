import { gql, useQuery } from '@apollo/client'
import React, { useMemo, useState } from 'react'
import { useAuthContext } from 'context/auth'
import { QS } from 'utils/constants'
import buildDropdownOptions from 'utils/helpers/buildDropdownOptions'
import getNodes from 'utils/helpers/getNodes'
import ROUTE_PATHS from 'utils/routePaths'
import { useQueryString } from 'utils/urls'
import { groupJobs } from './ReviewSourcedJobs.helpers'
import ReviewSourcedJobsTable from './ReviewSourcedJobsTable'
import Container from 'components/Container'
import HList from 'components/HList'
import { Dropdown } from 'components/Inputs'
import Message from 'components/Message'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import RefreshMessage from 'components/RefreshMessage'
import Txt from 'components/Txt'
import ViewBox from 'components/ViewBox'
import {
  GetSourcedJobsForReviewQuery,
  GetSourcedJobsForReviewQueryVariables,
} from 'generated/graphql'

export default function ReviewSourcedJobs(): React.ReactElement {
  const {
    userSession: {
      user: { id: viewerId, isTalentAgent },
    },
  } = useAuthContext()
  const queryString = useQueryString()
  const qsClientId = queryString.get(QS.CLIENT_ID) ?? ''
  const defaultTalentAgentId = isTalentAgent ? viewerId : ''
  const [talentAgentId, setTalentAgentId] = useState<string>(defaultTalentAgentId)
  const [clientId, setClientId] = useState<string>(qsClientId)

  // TODO (matthewalbrecht) paginate these results use size of ~200
  const { data, loading, error, refetch } = useQuery<
    GetSourcedJobsForReviewQuery,
    GetSourcedJobsForReviewQueryVariables
  >(GET_SOURCED_JOBS, {
    variables: {
      talentAgentId: isTalentAgent ? viewerId : undefined,
      clientId: clientId ? clientId : undefined,
    },
  })

  const tableData = useMemo(
    () => data?.sourcedJobs && groupJobs(data.sourcedJobs),
    [data?.sourcedJobs]
  )
  const filteredTableData = useMemo(() => {
    const shouldFilter = talentAgentId
    if (tableData && shouldFilter) {
      return tableData.filter((item) => item.talentAgentId === talentAgentId)
    } else {
      return tableData
    }
  }, [tableData, talentAgentId])
  const talentAgentOptions = buildDropdownOptions(data?.talentAgents || [])
  const clientOptions = buildDropdownOptions(
    data?.clients ? getNodes(data.clients) : []
  )

  if (loading) {
    return <Message vertical message="Loading sourced jobs..." />
  }

  if (error) {
    return <RefreshMessage message="There was an error loading sourced jobs" />
  }

  if (!filteredTableData) {
    return <Message vertical message="No sourced jobs found" />
  }

  function handleTalentAgentChange(value: string) {
    setTalentAgentId(value)
    void refetch({ talentAgentId: value || undefined })
  }

  function handleClientChange(value: string) {
    setClientId(value)
    void refetch({ clientId: value || undefined })
  }

  return (
    <ViewBox>
      <Container noMax>
        <Padding top={6}>
          <Txt size={24} bold>
            Review Sourced Jobs
          </Txt>
        </Padding>
        <Padding top={4}>
          <HList size={2}>
            <Dropdown
              value={talentAgentId}
              onValueChange={handleTalentAgentChange}
              options={talentAgentOptions}
              label="Talent Agent"
            />
            <Dropdown
              value={clientId}
              onValueChange={handleClientChange}
              options={clientOptions}
              label="Free Agent"
            />
          </HList>
        </Padding>
      </Container>
      <Padding top={4}>
        <ReviewSourcedJobsTable
          tableData={filteredTableData}
          talentAgentId={talentAgentId}
          clientId={clientId}
        />
      </Padding>
    </ViewBox>
  )
}

ReviewSourcedJobs.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.REVIEW_SOURCED_JOBS}
    key={ROUTE_PATHS.REVIEW_SOURCED_JOBS}
  >
    <ReviewSourcedJobs />
  </PrivateRoute>,
]

/**
 * NOTE: If this query's params are updated make sure to update the queryStrings used to update the cache after mutations
 */
const GET_SOURCED_JOBS = gql`
  query GetSourcedJobsForReview($talentAgentId: ID, $clientId: ID) {
    sourcedJobs(
      isRedacted: false
      isReviewed: false
      onlyActiveClients: true
      talentAgentId: $talentAgentId
      clientId: $clientId
    ) {
      edges {
        node {
          id
          title
          company: companyEntity {
            id
            name
          }
          url
          notes
          createdBy {
            id
            name
          }
          createdAt
          datePosted
          sourcingRequest {
            id
            client {
              id
              name
              talentAgent {
                id
              }
            }
          }
        }
      }
    }
    talentAgents: users(anyRole: { isTalentAgent: true }) {
      id
      name
    }
    clients {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`
