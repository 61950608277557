import React from 'react'
import ROUTE_PATHS from 'utils/routePaths'
import { ChangesProvider } from 'views/Assign/Assign.context'
import AssignHeader from 'views/Assign/AssignHeader'
import AssignTable from 'views/Assign/AssignTable'
import { ASSIGN_DATA, COLUMNS, REASSIGN_DATA } from './AssignDossiers.constants'
import getEditPath from './AssignDossiers.helpers'
import AssignDossiersDatetimePicker from './AssignDossiersDatetimePicker'
import AssignDossiersDeleteButton from './AssignDossiersDeleteButton'
import AssignDossiersFooter from './AssignDossiersFooter'
import AssignDossiersRowItems from './AssignDossiersRowItems'
import useAssignDossiersData from './useAssignDossiersData'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

type Props = {
  isReassigning: boolean
}

function AssignDossiers({ isReassigning }: Props): JSX.Element {
  const { groupedRowData, assigneeOptions } = useAssignDossiersData(isReassigning)
  const totalDossiers = groupedRowData.reduce(
    (total, groupedRow) => (total += groupedRow.items.length),
    0
  )
  return (
    <ChangesProvider>
      <ViewBox footer={<AssignDossiersFooter />}>
        <Padding top={6}>
          <Container noMax>
            <AssignHeader
              isReassigning={isReassigning}
              assignData={ASSIGN_DATA}
              reassignData={REASSIGN_DATA}
              appendedHeaderCopy={`(${totalDossiers})`}
            />
          </Container>
          <Padding top={6}>
            <AssignTable
              isReassigning={isReassigning}
              groupedRowData={groupedRowData}
              assigneeOptions={assigneeOptions}
              columns={COLUMNS}
              workerType="Dossier Writer"
              getEditPath={getEditPath}
              OverrideColumns={AssignDossiersRowItems}
              DeleteButtonComponent={AssignDossiersDeleteButton}
              DueDatePicker={AssignDossiersDatetimePicker}
            />
          </Padding>
        </Padding>
      </ViewBox>
    </ChangesProvider>
  )
}

AssignDossiers.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ASSIGNED_DOSSIERS}
    key={ROUTE_PATHS.ASSIGNED_DOSSIERS}
  >
    <AssignDossiers isReassigning />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AVAILABLE_DOSSIERS}
    key={ROUTE_PATHS.AVAILABLE_DOSSIERS}
  >
    <AssignDossiers isReassigning={false} />
  </PrivateRoute>,
]

export default AssignDossiers
