import React from 'react'
import { DOSSIER_TYPE_MAP } from 'utils/constants'
import { BodyData } from 'components/FullWidthTable/FullWidthTableStyles'
import { DossierRequest } from './AssignDossiers.types'
import Txt from 'components/Txt'

type Props = {
  item: DossierRequest
}

export default function AssignSourcingRowItems({ item }: Props): React.ReactElement {
  const isUpdateRequest = item.priorDossierSubmission?.fileUrl
  return (
    <>
      <BodyData>
        <Txt size={14}>{item.interviewCycle.jobTitle}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{item.interviewCycle.employerName}</Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>
          {DOSSIER_TYPE_MAP[item.dossierType]} {isUpdateRequest && '(Update)'}
        </Txt>
      </BodyData>
      <BodyData>
        <Txt size={14}>{item.priorDossierSubmission?.createdBy.name ?? ''}</Txt>
      </BodyData>
    </>
  )
}
