import { DropdownOption } from 'types'
import {
  SourcedJobRejectionReason,
  Role,
  TZ_NAMES,
  LocalApplicationStatus,
  InactiveCycleReasons,
  EnrichmentRejectionReason,
} from './enums'
import {
  AccountPersona,
  ApplicationRejectionReason,
  DossierType,
  ExpertFunctionalArea,
  ExpertRequestStatus,
  ExpertSeniorityLevel,
  InterviewCycleSource,
  InterviewPrepType,
  MissingApplicationInfoDeclineReason,
  TaglineRequestRejectionRejecterRole,
  Timezone,
  PdlCompanySizes,
} from 'generated/graphql'

/**
 * CONSTANTS --------------------------------------------------------------------
 */

export const TAGLINE_REQUEST_STATUS = {
  UNASSIGNED: 'Unassigned',
  PENDING_WRITING: 'Pending Writing',
  PENDING_TA_REVIEW: 'Pending IR Review',
  PENDING_TP_REVIEW: 'Pending FR Review',
  REVIEWED: 'Reviewed',
  REJECTED: 'Rejected',
}

export const LocalApplicationStatusMap = {
  [LocalApplicationStatus.UNASSIGNED]: 'Unassigned',
  [LocalApplicationStatus.PENDING_APPLICATION]: 'Pending Application',
  [LocalApplicationStatus.APPLIED]: 'Applied',
  [LocalApplicationStatus.REJECTED]: 'Rejected',
  [LocalApplicationStatus.IN_AIRTABLE]: 'In Airtable',
  [LocalApplicationStatus.YELLOW_CARDED]: 'Yellow Carded',
}

export const TaglineRejectionReasonMap = {
  BAD_JOB_FIT: 'Bad job fit',
  UNUSABLE_TAGLINE: 'Unusable tagline',
  JOB_POSTING_CLOSED: 'Job posting closed',
  FA_ALREADY_PLACED: 'FA already placed',
  VOIDED: 'Coordinator void',
}

export const HuntrListNameMap = {
  'Interested?': 'Interested',
  Wishlist: 'Wishlist',
  Applied: 'Applied',
  Interview: 'Interview',
  Offer: 'Offer',
  Passed: 'Passed',
  'Not interested': 'Not interested',
}

export const EnrichmentRejectionReasonMap = {
  [EnrichmentRejectionReason.JOB_POSTING_CLOSED]: 'Job posting closed',
  [EnrichmentRejectionReason.OTHER]: 'Other',
  [EnrichmentRejectionReason.ALREADY_ENRICHED]: 'Already enriched',
}

export const ApplicationRejectionReasonMap = {
  [ApplicationRejectionReason.JOB_POSTING_CLOSED]: 'Job posting closed',
  [ApplicationRejectionReason.ADDITIONAL_INFORMATION_NEEDED]: 'Yellow carded',
  [ApplicationRejectionReason.LOGIN_INFORMATION_NEEDED]: 'Login information needed',
  [ApplicationRejectionReason.OTHER]: 'Other',
  [ApplicationRejectionReason.COORDINATOR_VOID]: 'Coordinator void',
}

export const INTERVIEW_CYCLE_SOURCE_MAP = {
  [InterviewCycleSource.STANDARD]: 'Standard App',
  [InterviewCycleSource.REVERSE_RECRUIT]: 'Reverse Recruit',
  [InterviewCycleSource.INVESTOR_BLAST]: 'Investor Blast',
  [InterviewCycleSource.COLD_COVER]: 'Cold Cover',
  [InterviewCycleSource.FREE_AGENT_SOURCED]: 'Free Agent Sourced',
  [InterviewCycleSource.FREE_AGENT_REFERRAL]: 'Free Agent Referral',
  [InterviewCycleSource.RECRUITER_INBOUNDED]: 'Recruiter Inbounded',
}

export const SOURCED_JOB_REJECTION_MAP = {
  [SourcedJobRejectionReason.JOB_CLOSED]: 'Job Closed',
  [SourcedJobRejectionReason.CONTRACT_WORK]: 'Contract Work',
  [SourcedJobRejectionReason.WRONG_LOCATION]: 'Wrong Location',
  [SourcedJobRejectionReason.TOO_SENIOR]: 'Too Senior',
  [SourcedJobRejectionReason.TOO_JUNIOR]: 'Too Junior',
  [SourcedJobRejectionReason.WRONG_POSITION]: 'Wrong Position',
  [SourcedJobRejectionReason.ALREADY_SOURCED_APPLIED]: 'Already Sourced / Applied',
  [SourcedJobRejectionReason.COMPANY_FIT]: 'Company Fit',
  [SourcedJobRejectionReason.COMPANY_FIT_OTHER]: 'Company Fit - Other',
  [SourcedJobRejectionReason.COMPANY_BRAND]: 'Company Brand / Reputation',
  [SourcedJobRejectionReason.COMPANY_INDUSTRY]: 'Company Industry / Vertical',
  [SourcedJobRejectionReason.COMPANY_WRONG_STAGE]: 'Company Wrong Stage / Maturity',
  [SourcedJobRejectionReason.COORDINATOR_VOID]: 'Coordinator Void',
  [SourcedJobRejectionReason.OTHER]: 'Other',
}

export const INTERVIEW_PREP_TYPE_MAP = {
  [InterviewPrepType.MOCK_INTERVIEW]: 'Mock Interview',
  [InterviewPrepType.DEEP_DIVE_INTERVIEW_PREP]: 'Deep Dive Interview Prep',
}

export const DOSSIER_TYPE_MAP = {
  [DossierType.INTRO]: 'Intro',
  [DossierType.ADVANCED]: 'Advanced',
  [DossierType.CHEAT_SHEET]: 'Cheat Sheet',
}

export const TaglineRejecterMap = {
  [TaglineRequestRejectionRejecterRole.COORDINATOR]: 'Coordinator',
  [TaglineRequestRejectionRejecterRole.TALENT_AGENT]: 'Initial Reviewer',
  [TaglineRequestRejectionRejecterRole.TALENT_PARTNER]: 'Final Reviewer',
  [TaglineRequestRejectionRejecterRole.WRITER]: 'Writer',
}

export const ExpertSeniorityLevelMap = {
  [ExpertSeniorityLevel.ANALYST]: 'Analyst',
  [ExpertSeniorityLevel.MANAGER]: 'Manager',
  [ExpertSeniorityLevel.DIRECTOR]: 'Director',
  [ExpertSeniorityLevel.VP]: 'VP',
  [ExpertSeniorityLevel.C_LEVEL]: 'C-Level',
}

export const ExpertFunctionalAreaMap = {
  [ExpertFunctionalArea.DESIGN]: 'Design',
  [ExpertFunctionalArea.ENGINEERING]: 'Engineering',
  [ExpertFunctionalArea.FINANCE]: 'Finance',
  [ExpertFunctionalArea.MARKETING]: 'Marketing',
  [ExpertFunctionalArea.OPERATIONS]: 'Operations',
  [ExpertFunctionalArea.PRODUCT]: 'Product',
  [ExpertFunctionalArea.SALES]: 'Sales',
  [ExpertFunctionalArea.LEGAL]: 'Legal',
}

export const ExpertRequestStatusMap = {
  [ExpertRequestStatus.REQUESTED]: 'Requested',
  [ExpertRequestStatus.MESSAGE_SENT]: 'Message Sent',
  [ExpertRequestStatus.IN_TOUCH]: 'In Touch',
  [ExpertRequestStatus.CALL_SCHEDULED]: 'Call Scheduled',
  [ExpertRequestStatus.PAYMENT_NEEDED]: 'Payment Needed',
  [ExpertRequestStatus.PAYMENT_COMPLETE]: 'Payment Complete',
}

export const AccountPersonaMap = {
  [AccountPersona.EXECUTIVE]: 'Executive',
  [AccountPersona.HARD_TO_FIT]: 'Hard to fit',
  [AccountPersona.RESCUE]: 'Rescue',
  [AccountPersona.STANDARD]: 'Standard',
  [AccountPersona.TIME_SAVER]: 'Time Saver',
}

export const InterviewCycleInactiveReasonMap = {
  [InactiveCycleReasons.CLIENT_NOT_INTERESTED]: 'Client Not Interested',
  [InactiveCycleReasons.REJECTED_AFTER_SCREEN]: 'Rejected After Screen',
  [InactiveCycleReasons.REJECTED_AFTER_ON_SITE]: 'Rejected After On-site',
  [InactiveCycleReasons.GHOSTED]: 'Ghosted',
  [InactiveCycleReasons.JOB_CLOSED]: 'Job Closed',
  [InactiveCycleReasons.INTERNAL_CANDIDATE]: 'Internal Candidate',
  [InactiveCycleReasons.ACCEPTED_THIS_JOB]: 'Accepted This Job',
  [InactiveCycleReasons.ACCEPTED_ANOTHER_JOB]: 'Accepted Another Job',
  [InactiveCycleReasons.OTHER]: 'Other',
}

export const MissingApplicationInfoDeclineReasonMap = {
  [MissingApplicationInfoDeclineReason.NO_LONGER_INTERESTED_IN_JOB]:
    'No Longer Interested',
  [MissingApplicationInfoDeclineReason.ALREADY_RESPONDED]: 'Already Responded',
  [MissingApplicationInfoDeclineReason.ALREADY_APPLIED]: 'Already Applied',
}

export const CompanySizeMap = {
  [PdlCompanySizes.SIZE_1_10]: '1-10',
  [PdlCompanySizes.SIZE_11_50]: '11-50',
  [PdlCompanySizes.SIZE_51_200]: '51-200',
  [PdlCompanySizes.SIZE_201_500]: '201-500',
  [PdlCompanySizes.SIZE_501_1000]: '501-1000',
  [PdlCompanySizes.SIZE_1001_5000]: '1001-5000',
  [PdlCompanySizes.SIZE_5001_10000]: '5001-10000',
  [PdlCompanySizes.SIZE_10001]: '10001+',
}

export const COLUMN_HEADERS_DASHBOARD = [
  'Free Agent',
  'Sourced Jobs',
  'Unassigned',
  'Writer Queue',
  'Initial Review',
  'Final Review',
]

export const QS = {
  CLIENT_ID: 'clientId',
  HUNTR_URL: 'huntrUrl',
  CALLBACK: 'cb',
  INTERVIEW_CYCLE_ID: 'interviewCycleId',
  USER_ID: 'userId',
  USER_ROLE: 'userRole',
  SOURCED_JOB_ID: 'sourcedJobId',
  TAGLINE_REQUEST_ID: 'taglineRequestId',
}

export const ERRORS = {
  FOREIGN_KEY_VIOLATION: 'ForeignKeyViolation',
  UNIQUE_VIOLATION: 'UniqueViolation',
  NOT_NULL_VIOLATION: 'NotNullViolation',
}

export const ROLE_GROUPS = {
  CORE_TEAM: [
    Role.Coordinator,
    Role.TalentPartner,
    Role.TalentAgent,
    Role.TalentEnablement,
  ],
  NON_WRITER: [
    Role.Coordinator,
    Role.TalentPartner,
    Role.TalentAgent,
    Role.TalentEnablement,
    Role.Applier,
  ],
  NON_TEA: [
    Role.Coordinator,
    Role.TalentPartner,
    Role.TalentAgent,
    Role.EnablementEditor,
    Role.Writer,
  ],
  ENABLEMENT_TEAM: [Role.Coordinator, Role.TalentEnablement],
}

export const INITIAL = 'initial'

export const EMPTY_USER_SESSION = {
  accessToken: '',
  expiresAt: 0,
  user: {
    id: '',
    name: '',
    email: '',
    isWriter: false,
    isAdmin: false,
    isTalentAgent: false,
    isTalentPartner: false,
    isCoordinator: false,
    isTea: false,
    isTee: false,
    isApplier: false,
    isEnricher: false,
    isSourcer: false,
    isDossierWriter: false,
    isExpertCoordinator: false,
    isDataCleaner: false,
    isSalesCoordinator: false,
  },
}

// from: https://stackoverflow.com/a/13653180
const UUID_VALIDATION_STRING =
  '^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$'
export const UUID_VALIDATION_REGEX = RegExp(UUID_VALIDATION_STRING, 'i')
const TIME_TRACKER_STRING = /^\d*:\d*$/
export const TIME_TRACKER_REGEX = RegExp(TIME_TRACKER_STRING)
export const getTemplateVariableRegex = (templateVariable: string): RegExp =>
  RegExp(`[_]{2,}${templateVariable}[_]{2,}`, 'gi')
const TEMPLATE_VARIABLE_STRING = /[_]{2,}[a-zA-Z0-9_]+[_]{2,}/
export const TEMPLATE_VARIABLE_REGEX = RegExp(TEMPLATE_VARIABLE_STRING, 'gi')
export const LINKEDIN_VALIDATION_STRING =
  '^(https://)?([a-z]{2,3}[.])?linkedin.com/.*$'
export const LINKEDIN_REGEX = RegExp(LINKEDIN_VALIDATION_STRING)
export const HUNTR_REGEX_STRING = '^https://huntr.co/boards/.*$'
export const HUNTR_REGEX = RegExp(HUNTR_REGEX_STRING)
export const NOT_EMPTY_STRING_REGEX = RegExp('^(?!s*$).+', 'i')

export const DEFUALT_ENRICHMENT_POD_COUNT = 5

export const TZ = {
  MANILA: 'Asia/Manila',
  EASTERN: 'America/New_York',
}

export const TZ_OPTIONS = [
  {
    label: 'Eastern',
    value: TZ.EASTERN,
  },
  {
    label: 'Manila',
    value: TZ.MANILA,
  },
]

export const TIMEZONE_MAP = {
  [Timezone.AMERICA_NEW_YORK]: TZ_NAMES.AMERICA_NEW_YORK,
  [Timezone.AMERICA_CHICAGO]: TZ_NAMES.AMERICA_CHICAGO,
  [Timezone.AMERICA_DENVER]: TZ_NAMES.AMERICA_DENVER,
  [Timezone.AMERICA_LOS_ANGELES]: TZ_NAMES.AMERICA_LOS_ANGELES,
}

export const INTERVIEW_TIMEZONE_OPTIONS: DropdownOption<Timezone>[] = [
  {
    label: 'Eastern (ET)',
    value: Timezone.AMERICA_NEW_YORK,
  },
  {
    label: 'Central (CT)',
    value: Timezone.AMERICA_CHICAGO,
  },
  {
    label: 'Mountain (MT)',
    value: Timezone.AMERICA_DENVER,
  },
  {
    label: 'Pacific (PT)',
    value: Timezone.AMERICA_LOS_ANGELES,
  },
]

// number between 1-24
export const TAGLINE_DUE_HOUR = 12
export const ENRICHMENT_DUE_HOUR = 6
export const SOD_HOUR = 0
export const EOD_HOUR = 23

// number between 0-59
export const TAGLINE_DUE_MINUTE = 0
export const ENRICHMENT_DUE_MINUTE = 0
export const SOD_MINUTE = 0
export const EOD_MINUTE = 59

export const TAGLINE_DUE_TZ = TZ.EASTERN
export const APPLICATION_DUE_TZ = TZ.EASTERN
export const SOURCING_DUE_TZ = TZ.EASTERN
export const ENRICHMENT_DUE_TZ = TZ.MANILA
export const DOSSIER_DUE_TZ = TZ.EASTERN

export const DATE_FORMAT = {
  ONLY_DATE: 'YYYY-MM-DD',
  FRIENDLY_DATE: 'MMMM DD, YYYY',
  FRIENDLY_DATE_AND_TIME: 'MMMM DD, YYYY @ h:mma',
  FRIENDLY_DATE_SHORT_MONTH: 'MMM DD, YYYY',
  FRIENDLY_DATE_NO_YEAR: 'MMM DD',
  FRIENDLY_DATE_NO_YEAR_AND_TIME: 'MMM DD @ h:mma',
  DATE_AND_TIME: 'YYYY-MM-DD @ h:mma ',
  DATE_AND_TIME_AND_TZ: 'YYYY-MM-DD @ h:mma z',
  DATETIME_LOCAL_INPUT: 'YYYY-MM-DDTHH:mm',
  HOUR_AND_MINUTE: 'h:mma',
  HOUR_12: 'ha ',
}

export const UNKNOWN = {
  SKU: 'Unknown SKU',
  RATE: 'Unknown Rate',
  UNKNOWN: 'Unknown',
  JOB_TITLE: 'Unknown Title',
  EMPLOYER_NAME: 'Unknown Company',
  TIME: 'Unknown Time',
  DATE: 'Unknown Date',
  REJECTION_REASON: 'Unknown Reason',
}
export const EMPTY_CELL_PLACEHOLEDER = '–'
export const EMPTY_INPUT = ''
export const NOT_AVAILABLE = 'N/A'

export const GLOBAL_COPY = {
  NO_QUEUE_FOUND: 'No queue found.',
  EN_DASH: '–',
}

export const AIRTABLE_WRITING_REQUEST_URL = 'https://airtable.com/shrAOKmnXZXM3TVm5'
export const AIRTABLE_COMP_REPORT_URL = 'https://airtable.com/shrmOlVgsTaMIo3p1'
export const AIRTABLE_OFFER_FORM_URL = 'https://airtable.com/shrAyhs7IYiurkjjt'

export const QUICK_REVIEW_FOR_WRITER_OPTIONS = [
  {
    label: 'Great Tag',
    value: 'Great tag! Covered both the company and FA’s experience really well.',
  },
  {
    value:
      'Did a good job talking about FA, but would’ve liked more on the company.',
    label: 'Great FA, more company',
  },

  {
    value:
      'Did a good job talking about the company, but be more specific about the FA.',
    label: 'Great company, more FA',
  },

  {
    value:
      'Need to dig in more into both the company and the FA’s relevant background',
    label: 'More FA, more company',
  },

  {
    value: 'Too much restating what the company did – they already know!',
    label: 'Restated company mission',
  },

  {
    value: 'Information about the FA is too redundant to their other paragraphs.',
    label: 'Redundant to cover letter',
  },

  {
    value: 'Company name spelled wrong.',
    label: 'Company misspelled',
  },

  {
    value: 'Need to correct grammar and/or punctuation',
    label: 'Grammar/punctuation',
  },

  {
    value: 'Missing custom fields.',
    label: 'Missing fields',
  },

  {
    value: 'Awkward sentence structure/somewhat difficult to read',
    label: 'Awkward structure',
  },
]

export const QUICK_REVIEW_FOR_IR_OPTIONS = [
  {
    value: 'Great revision, thank you!',
    label: 'Great revision',
  },

  {
    value: 'Great addition about the company.',
    label: 'Great company add',
  },

  {
    value: 'Great addition about the FA.',
    label: 'Great FA add',
  },

  {
    value: 'Thank you for making it shorter.',
    label: 'Shorter',
  },

  {
    value: 'Thank you for making it longer.',
    label: 'Longer',
  },

  {
    value: 'Company name is still spelled wrong.',
    label: 'Misspelled still',
  },

  {
    value: 'Still missing custom fields.',
    label: 'Missing fields',
  },

  {
    value: 'Agree, no changes needed!',
    label: 'Agree, no change',
  },
]
