import { ArrayElement, ConnectionNode } from 'types'
import { GetInterviewCycleByIdQuery } from 'generated/graphql'

export type InterviewCycle = NonNullable<
  GetInterviewCycleByIdQuery['interviewCycle']
>
export type Interview = ConnectionNode<InterviewCycle['interviews']>
export type ExpertRequest = ConnectionNode<InterviewCycle['expertRequests']>
export type InterviewPrepRequest = ConnectionNode<
  InterviewCycle['interviewPrepRequests']
>
export type DossierRequest = ConnectionNode<InterviewCycle['dossierRequests']>
export type DossierSubmission = ConnectionNode<DossierRequest['dossierSubmissions']>
export type InterviewDebrief = ArrayElement<Interview['debriefs']>

export enum InterviewStatus {
  Canceled = 'Canceled',
  Unscheduled = 'Unscheduled',
  Scheduled = 'Scheduled',
  PendingDebrief = 'Pending Debrief',
  DebriefSubmitted = 'Debrief Submitted',
}

export enum DossierStatus {
  Requested = 'Requested',
  Voided = 'Voided',
  Submitted = 'Submitted',
}

export type Params = {
  interviewCycleId: string
  clientId: string
  dossierSubmissionId?: string
}
