import { generatePath } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'

type RouteValues = {
  writerId: string | undefined
  freeAgentId: string | undefined
}
/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextRequestId: string | null,
  { writerId, freeAgentId }: RouteValues
): string {
  const typeId = writerId || freeAgentId
  if (nextRequestId && typeId) {
    return generatePath(ROUTE_PATHS.WRITER_ASSIGNMENT_QUEUES, {
      requestId: nextRequestId,
      typeId,
      type: writerId ? 'writer' : 'free-agent',
    })
  }
  return ROUTE_PATHS.HOME
}
