import React from 'react'
import { ClientCompanyLogoContainer } from './DashboardCard.styles'
import DashboardCardCompanyLogo from './DashboardCardCompanyLogo'

type Props = {
  src?: string | null
  alt?: string
}

export default function DashboardCardClientCompanyLogo({
  src,
  alt,
}: Props): React.ReactElement {
  return (
    <ClientCompanyLogoContainer>
      <DashboardCardCompanyLogo src={src} alt={alt} />
    </ClientCompanyLogoContainer>
  )
}
