import { generatePath } from 'react-router'
import ROUTE_PATHS from 'utils/routePaths'

/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextRequestId: string | null,
  podId: string | undefined
): string {
  if (nextRequestId) {
    if (podId) {
      return generatePath(ROUTE_PATHS.ENRICHER_QUEUE_BY_POD, {
        requestId: nextRequestId,
        podId,
      })
    } else {
      return generatePath(ROUTE_PATHS.ENRICHER_QUEUE, {
        requestId: nextRequestId,
      })
    }
  }

  return ROUTE_PATHS.HOME
}
