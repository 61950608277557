import React from 'react'
import { useLocation } from 'react-router'
import { DATE_FORMAT } from 'utils/constants'
import ROUTE_PATHS from 'utils/routePaths'
import { ChangesProvider } from 'views/Assign/Assign.context'
import AssignHeader from 'views/Assign/AssignHeader'
import AssignTable from 'views/Assign/AssignTable'
import { ASSIGN_DATA, COLUMNS, REASSIGN_DATA } from './AssignApplications.constants'
import getEditPath from './AssignApplications.helpers'
import AssignApplicationsDeleteButton from './AssignApplicationsDeleteButton'
import AssignApplicationsFooter from './AssignApplicationsFooter'
import AssignApplicationsHighlightedClientRow from './AssignApplicationsHighlightedClientRow'
import AssignApplicationsOverrideColumns from './AssignApplicationsOverrideColumns'
import useAssignApplicationsData from './useAssignApplicationsData'
import Container from 'components/Container'
import Padding from 'components/Padding'
import PrivateRoute from 'components/PrivateRoute'
import ViewBox from 'components/ViewBox'

export default function AssignApplications(): JSX.Element {
  const { pathname } = useLocation()
  const isReassigning = pathname === ROUTE_PATHS.ASSIGNED_APPLICATIONS
  const { groupedRowData, assigneeOptions } =
    useAssignApplicationsData(isReassigning)
  const totalApplications = groupedRowData.reduce(
    (total, groupedRow) => (total += groupedRow.items.length),
    0
  )
  return (
    <ChangesProvider>
      <ViewBox footer={<AssignApplicationsFooter />}>
        <Padding top={6}>
          <Container noMax>
            <AssignHeader
              isReassigning={isReassigning}
              assignData={ASSIGN_DATA}
              reassignData={REASSIGN_DATA}
              appendedHeaderCopy={`(${totalApplications})`}
            />
          </Container>
          <Padding top={6}>
            <AssignTable
              isReassigning={isReassigning}
              groupedRowData={groupedRowData}
              assigneeOptions={assigneeOptions}
              columns={COLUMNS}
              workerType="Applier"
              getEditPath={getEditPath}
              DeleteButtonComponent={AssignApplicationsDeleteButton}
              ClientRowComponent={AssignApplicationsHighlightedClientRow}
              dueDateFormat={DATE_FORMAT.ONLY_DATE}
              OverrideColumns={AssignApplicationsOverrideColumns}
            />
          </Padding>
        </Padding>
      </ViewBox>
    </ChangesProvider>
  )
}

AssignApplications.Routes = [
  <PrivateRoute
    exact
    path={ROUTE_PATHS.AVAILABLE_APPLICATIONS}
    key={ROUTE_PATHS.AVAILABLE_APPLICATIONS}
  >
    <AssignApplications />
  </PrivateRoute>,
  <PrivateRoute
    exact
    path={ROUTE_PATHS.ASSIGNED_APPLICATIONS}
    key={ROUTE_PATHS.ASSIGNED_APPLICATIONS}
  >
    <AssignApplications />
  </PrivateRoute>,
]
