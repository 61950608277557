import { gql, useMutation } from '@apollo/client'
import dayjs from 'dayjs'
import React from 'react'
import { toast } from 'react-toastify'
import { Maybe } from 'types'
import { DATE_FORMAT, EMPTY_CELL_PLACEHOLEDER } from 'utils/constants'
import getNodes from 'utils/helpers/getNodes'
import { sortByProp } from 'utils/sort'
import { CANCEL_EXPERT_REQUEST } from 'views/ExpertRequests/EditExpertRequestStatus'
import { getStatusInfo } from 'views/ExpertRequests/ExpertRequests.helpers'
import Icon from 'components/Icon/Icon'
import { COPY, EXPERT_COLUMN_INFO } from './InterviewCycleDetail.constants'
import { ExpertRequest, InterviewCycle } from './InterviewCycleDetail.types'
import { Grid, GridHeaders } from './interviewCycleDetail.styles'
import ExternalLink from 'components/ExternalLink'
import { SectionHeader, Tag } from 'components/MiscStyles'
import Txt from 'components/Txt'
import {
  CancelExpertRequestMutation,
  CancelExpertRequestMutationVariables,
} from 'generated/graphql'

type Props = {
  interviewCycle: Maybe<InterviewCycle>
  loading: boolean
}

export default function InterviewCycleDetailExpertRequests({
  interviewCycle,
  loading,
}: Props): React.ReactElement {
  const expertRequests = interviewCycle
    ? getNodes<ExpertRequest>(interviewCycle.expertRequests)
        .map((request) => ({ ...request, dateToSortBy: dayjs(request.createdAt) }))
        .sort((a, b) => sortByProp(a, b, '-dateToSortBy'))
    : []

  const hasExpertRequests = Boolean(expertRequests.length)

  return (
    <div>
      <SectionHeader headerCopy="Expert Requests" />
      {loading && <Txt alignment="center">Loading...</Txt>}
      {!hasExpertRequests && !loading && (
        <Txt alignment="center">No Expert Requests Found.</Txt>
      )}
      {hasExpertRequests && (
        <Grid
          gridTemplateColumns={EXPERT_COLUMN_INFO.GRID_TEMPLATE_COLUMNS}
          rowGapSize={2}
          colGapSize={1.5}
        >
          <GridHeaders headers={EXPERT_COLUMN_INFO.COLUMN_LABELS} />
          {expertRequests.map((expertRequest, index) => (
            <ExpertRequestRow
              key={expertRequest.id}
              expertRequest={expertRequest}
              number={expertRequests.length - index}
            />
          ))}
        </Grid>
      )}
    </div>
  )
}

function ExpertRequestRow({
  expertRequest,
  number,
}: {
  expertRequest: ExpertRequest
  number: number
}): React.ReactElement {
  // mutations
  const [cancelExpertRequest] = useMutation<
    CancelExpertRequestMutation,
    CancelExpertRequestMutationVariables
  >(CANCEL_EXPERT_REQUEST, {
    onCompleted() {
      toast.success(COPY.CANCEL_EXPERT_REQUEST_SUCCESS)
    },
    onError() {
      toast.error(COPY.CANCEL_EXPERT_REQUEST_ERROR)
    },
  })

  function handleCancel() {
    // are you sure
    if (!window.confirm(COPY.CONFIRM_CANCEL_EXPERT_REQUEST)) {
      return
    }

    void cancelExpertRequest({
      variables: { input: { expertRequestId: expertRequest.id } },
    })
  }

  const showCancelButton = !expertRequest.airtableId && !expertRequest.canceledAt
  const [statusCopy, statusColor] = getStatusInfo(expertRequest)
  return (
    <>
      <Txt size={13}>{number}</Txt>
      <Txt size={13} noWrap>
        {dayjs(expertRequest.createdAt).format(DATE_FORMAT.ONLY_DATE)}
      </Txt>
      <Txt size={13} noWrap>
        {dayjs(expertRequest.due).format(DATE_FORMAT.ONLY_DATE)}
      </Txt>
      <Txt size={13} noWrap>
        {expertRequest.expert ? (
          <ExternalLink
            url={expertRequest.expert.linkedinUrl}
            inheritStyles
            label={expertRequest.expert.name}
          />
        ) : (
          EMPTY_CELL_PLACEHOLEDER
        )}
      </Txt>
      <div />
      <Tag color={statusColor} small>
        <Txt size={13} noWrap alignment="center">
          {statusCopy}
        </Txt>
      </Tag>
      {showCancelButton ? (
        <button onClick={handleCancel}>
          <Icon
            name="Cancel"
            width={14}
            height={14}
            primaryFill="text"
            primaryFillHover="black"
          />
        </button>
      ) : (
        <div />
      )}
    </>
  )
}

InterviewCycleDetailExpertRequests.fragments = {
  expertRequestInfo: gql`
    fragment ExpertRequestInfo on InterviewCycle {
      expertRequests {
        edges {
          node {
            id
            createdAt
            canceledAt
            due
            ...StatusInfo
            expert {
              id
              name
              linkedinUrl
            }
          }
        }
      }
    }
    ${getStatusInfo.fragments.statusInfo}
  `,
}
