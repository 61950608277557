import { ApolloCache } from '@apollo/client'
import { captureException } from '@sentry/react'
import { generatePath } from 'react-router'
import { CacheConnection } from 'types'
import { CACHE_STRING } from 'utils/cacheString'
import { Typename } from 'utils/enums'
import getNextTotalCount from 'utils/helpers/getNextTotalCount'
import removeCacheEdgeById from 'utils/helpers/removeCacheEdgeById'
import ROUTE_PATHS from 'utils/routePaths'
import { TaglineRequest } from './InitialReview.types'
import {
  RejectTaglineRequestByTalentAgentMutation,
  SubmitAgentReviewWorkMutation,
} from 'generated/graphql'

type RouteValues = {
  talentAgentId: string | undefined
  freeAgentId: string | undefined
  podId: string | undefined
}

/**
 * returns the route for the next request or '/' if the queue is finished
 */
export function getNextRouteUrl(
  nextRequestId: string | null,
  { talentAgentId, freeAgentId, podId }: RouteValues
): string {
  const typeId = talentAgentId || freeAgentId
  if (nextRequestId && podId) {
    return generatePath(ROUTE_PATHS.AGENT_QUEUE_BY_POD, {
      podId,
      requestId: nextRequestId,
    })
  }
  if (nextRequestId && typeId) {
    return generatePath(ROUTE_PATHS.AGENT_REVIEW_QUEUES, {
      requestId: nextRequestId,
      typeId,
      type: talentAgentId ? 'talent-agent' : 'free-agent',
    })
  }
  return ROUTE_PATHS.HOME
}

export function updateCacheOnSubmission(
  cache: ApolloCache<SubmitAgentReviewWorkMutation>,
  taglineRequest: TaglineRequest
): void {
  const clientCacheId = cache.identify({
    id: taglineRequest.client.id,
    __typename: Typename.Client,
  })
  try {
    clientCacheId &&
      cache.modify({
        id: clientCacheId,
        fields: {
          [CACHE_STRING.CLIENT_PENDING_TA](
            existing: CacheConnection = { edges: [] }
          ) {
            return {
              ...existing,
              totalCount: getNextTotalCount(existing, -1),
            }
          },
          [CACHE_STRING.CLIENT_PENDING_FR](
            existing: CacheConnection = { edges: [] }
          ) {
            return {
              ...existing,
              totalCount: getNextTotalCount(existing, 1),
            }
          },
        },
      })
  } catch (error) {
    captureException(error, {
      tags: {
        cacheModified: 'clients pending ir & fr tagline requests',
      },
    })
  }

  const cacheStringReviewQueue = CACHE_STRING.TA_REVIEW_QUEUE(
    taglineRequest.client.talentAgent.id
  )
  try {
    cache.modify({
      fields: {
        [cacheStringReviewQueue](
          existing: CacheConnection = { edges: [] },
          { readField }
        ) {
          return {
            ...removeCacheEdgeById(taglineRequest.id, existing, readField),
            totalCount: getNextTotalCount(existing, -1),
          }
        },
      },
    })
  } catch (error) {
    captureException(error, {
      extra: { cacheStringReviewQueue },
      tags: {
        cacheModified: 'IR queue',
      },
    })
  }
}

export function updateCacheOnRejection(
  cache: ApolloCache<RejectTaglineRequestByTalentAgentMutation>,
  taglineRequest: TaglineRequest
): void {
  const clientCacheId = cache.identify({
    id: taglineRequest.client.id,
    __typename: Typename.Client,
  })
  clientCacheId &&
    cache.modify({
      id: clientCacheId,
      fields: {
        [CACHE_STRING.CLIENT_PENDING_TA](existing: CacheConnection = { edges: [] }) {
          const newTotalCount = existing.totalCount
            ? existing.totalCount - 1
            : existing.edges?.length
            ? existing.edges.length - 1
            : 0
          return {
            ...existing,
            totalCount: newTotalCount,
          }
        },
      },
    })
  cache.modify({
    fields: {
      [CACHE_STRING.TA_REVIEW_QUEUE(taglineRequest.client.talentAgent.id)](
        existing: CacheConnection = { edges: [] },
        { readField }
      ) {
        const newTotalCount = existing.totalCount
          ? existing.totalCount - 1
          : existing.edges?.length
          ? existing.edges.length - 1
          : 0
        return {
          ...removeCacheEdgeById(taglineRequest.id, existing, readField),
          totalCount: newTotalCount,
        }
      },
    },
  })
}
