import { gql, useMutation } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { toast } from 'react-toastify'
import { ERRORS } from 'utils/constants'
import EditableLabel from 'components/EditableLabel/EditableLabel'
import EditorLabelWithAction from 'components/EditorLabelWithAction/EditorLabelWithAction'
import { Sequence } from './ClientTemplates.types'
import {
  DeleteTemplateSequenceMutation,
  DeleteTemplateSequenceMutationVariables,
  RenameTemplateSequenceMutation,
  RenameTemplateSequenceMutationVariables,
  TemplateSequence,
} from 'generated/graphql'

type Props = {
  sequence: Sequence
  setActiveSequence: React.Dispatch<React.SetStateAction<number>>
}

export default function ClientTemplatesSequenceHeader({
  sequence,
  setActiveSequence,
}: Props): JSX.Element {
  const { id: freeAgentId } = useParams<{ id: string }>()
  const [templateSequenceName, setTemplateSequenceName] = useState(sequence.name)
  const [renameTemplateSequence, { error: renameError }] = useMutation<
    RenameTemplateSequenceMutation,
    RenameTemplateSequenceMutationVariables
  >(RENAME_SEQUENCE, {
    errorPolicy: 'all',
  })
  // TODO: (matthewalbrecht) investigate why data property is undefined on success of mutation
  const [deleteTemplateSequence, { error: deleteError }] = useMutation<
    DeleteTemplateSequenceMutation,
    DeleteTemplateSequenceMutationVariables
  >(DELETE_SEQUENCE, {
    errorPolicy: 'all',
    onCompleted({ deleteTemplateSequence }) {
      if (deleteTemplateSequence) {
        setActiveSequence(0)
        toast.success(`Successfully deleted template "${sequence.name}"`)
      }
    },
    update(cache, { data }) {
      if (data?.deleteTemplateSequence) {
        // If we find no errors we know remove the template from the cache
        cache.modify({
          id: cache.identify({
            id: freeAgentId,
            __typename: 'Client',
          }),
          fields: {
            templateSequences(templateSequences: TemplateSequence[], { readField }) {
              const newTemplateSequences = templateSequences.filter(
                (templateSequenceElement: { id: string }) =>
                  readField('id', templateSequenceElement) !== sequence.id
              )
              return newTemplateSequences
            },
          },
        })
      }
    },
  })

  useEffect(() => {
    if (deleteError?.message.includes(ERRORS.NOT_NULL_VIOLATION)) {
      toast.error(
        'This template sequence is being used in Tagline Requests, it cannot be deleted'
      )
    } else if (deleteError) {
      toast.error('There was an error deleting the template sequence')
    }
  }, [deleteError])

  useEffect(() => {
    if (renameError?.message.includes(ERRORS.UNIQUE_VIOLATION)) {
      toast.error('Template sequence names must be unique')
    } else if (renameError) {
      toast.error('There was an error renaming the template sequence')
    }
  }, [renameError])

  function handleRenameSubmit(
    event: React.FormEvent<HTMLFormElement>,
    setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
  ) {
    event.preventDefault()
    // mutation for creating new template
    void renameTemplateSequence({
      variables: {
        RenameTemplateSequenceInput: {
          id: sequence.id,
          name: templateSequenceName,
        },
      },
    })
    // This will flip what is rendered by this component back to a button
    setIsEditing(false)
    // resets the inputs value to empty
    event.currentTarget.reset()
  }

  function handleDeleteClick() {
    void deleteTemplateSequence({
      variables: {
        DeleteTemplateSequenceInput: {
          id: sequence.id,
        },
      },
    })
  }

  return (
    <div>
      <EditorLabelWithAction
        onActionClick={handleDeleteClick}
        ActionIconName="Trash"
        showActionButton={!sequence.templates.length}
        label={
          <EditableLabel
            label={sequence.name}
            onSubmit={handleRenameSubmit}
            txtProperties={{ size: 20, bold: true }}
            value={templateSequenceName}
            onValueChange={(value) => setTemplateSequenceName(value)}
          />
        }
      />
    </div>
  )
}

const DELETE_SEQUENCE = gql`
  mutation DeleteTemplateSequence(
    $DeleteTemplateSequenceInput: DeleteTemplateSequenceInput!
  ) {
    deleteTemplateSequence(input: $DeleteTemplateSequenceInput) {
      success
    }
  }
`

const RENAME_SEQUENCE = gql`
  mutation RenameTemplateSequence(
    $RenameTemplateSequenceInput: RenameTemplateSequenceInput!
  ) {
    renameTemplateSequence(input: $RenameTemplateSequenceInput) {
      templateSequence {
        id
        name
      }
    }
  }
`
